import { YupHelper } from "c4u-web-components";
import * as Yup from "yup";
import { setLocale } from "yup";

setLocale({
  mixed: {
    default: (err) => ({ key: "field_invalid", field: err.path }),
    required: (err) => ({ key: "field_required", field: err.path }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: "field_too_small", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
  string: {
    email: (err) => ({ key: "field_invalid_email", field: err.path }),
    max: (err) => ({
      key: "field_max_size",
      data: err,
      field: err.path,
      values: { max: err.max },
    }),
  },
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "isMobilePhoneValid",
  YupHelper.isMobilePhoneValid
);
Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "isPhoneValid",
  YupHelper.isPhoneValid
);
Yup.addMethod<Yup.StringSchema>(Yup.string, "isCpfValid", YupHelper.isCpfValid);
Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "isCnpjValid",
  YupHelper.isCnpjValid
);

export const CreateDealershipFormValidationSchema = Yup.object()
  .shape({
    legal_representative_or_owner: Yup.string().required(),
    legal_representative_name: Yup.string().required().max(100),
    legal_representative_email: Yup.string().required().trim().email().max(320), //according to RFC 3696
    legal_representative_cpf: Yup.string().required().isCpfValid("cpf_invalid"),
    legal_representative_birth_date: Yup.string().required().max(10),
    legal_representative_cellphone: Yup.string()
      .required()
      .isMobilePhoneValid("field_invalid_mobile_phone"),

    dealership_id: Yup.string().optional(),
    dealership_cnpj: Yup.string().required().isCnpjValid("cnpj_invalid"),
    dealership_name: Yup.string().required().max(150),
    dealership_legal_name: Yup.string().required().max(150),
    dealership_email: Yup.string().trim().required().email().max(320),
    dealership_phone_primary: Yup.string()
      .required()
      .isMobilePhoneValid("field_invalid_mobile_phone"),
    dealership_phone_second: Yup.string()
      .notRequired()
      .isPhoneValid("field_invalid_phone"),
    no_group: Yup.string().notRequired(),

    dealership_group: Yup.string()
      .max(150)
      .when("no_group", {
        is: (val) => val && val.includes("yes"),
        then: Yup.string().notRequired().max(150),
        otherwise: Yup.string().required().max(150),
      }),
    dealership_zip_code: Yup.string().required(),
    dealership_address_street: Yup.string().required(),
    dealership_address_number: Yup.string().required().max(10),
    dealership_address_complement: Yup.string().max(50),
    dealership_address_neighborhood: Yup.string().required(),
    dealership_address_city: Yup.string().required(),
    dealership_address_state: Yup.string().required(),

    created_by_id: Yup.string(),
  })
  .defined();

export type CreateDealershipForm = Yup.InferType<
  typeof CreateDealershipFormValidationSchema
>;
