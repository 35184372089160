import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AdOfferStatusEnum, IEditAdOfferProps } from "../../../../../models";
import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  MaskHelper,
  ModalDefault,
  YupHelper,
} from "c4u-web-components";
import { ComboOptionsFromEnumAtom } from "../../../../atoms";
import {
  ModalContentWrapper,
  ModalButtonsWrapper,
} from "../../../shared/styles/form-modal.molecule.style";

interface IProps {
  showModal: boolean;
  initialValues: IEditAdOfferProps;
  onSubmit: (values: IEditAdOfferProps) => void;
  onCloseModal: () => void;
}

export const EditOfferModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const msgRequired = t("field_required");

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDateTimePtBrValid",
    YupHelper.isDateTimePtBrValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPhoneValid",
    YupHelper.isPhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isMobilePhoneValid",
    YupHelper.isMobilePhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isEmailValid",
    YupHelper.isEmailValid
  );

  const validations = Yup.object<IEditAdOfferProps>({
    id: Yup.number(),
    offerDate: Yup.string()
      .isDateTimePtBrValid(t("field_invalid_datetime"))
      .required(msgRequired),
    value: Yup.number().required(msgRequired),
    status: Yup.number().required(msgRequired),
    entity: Yup.string().nullable().required(msgRequired),
    bidder: Yup.string().required(msgRequired),
    phoneNumber: Yup.string()
      .nullable()
      .required(msgRequired)
      .isPhoneValid(t("field_invalid_phone")),
    cellPhone: Yup.string()
      .nullable()
      .required(msgRequired)
      .isMobilePhoneValid(t("field_invalid_mobile_phone")),
    email: Yup.string()
      .nullable()
      .required(msgRequired)
      .isEmailValid(t("field_invalid_email")),
    cnpj: Yup.string()
      .nullable()
      .required(msgRequired)
      .isCnpjValid(t("cnpj_invalid")),
  });

  const formik = useFormik<IEditAdOfferProps>({
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleSubmit = useCallback(
    (e: any) => formik.handleSubmit(e),
    [formik]
  );

  return (
    <ModalDefault
      show={props.showModal}
      onHide={props.onCloseModal}
      title={t("Edit Offer #{{id}}", { id: props.initialValues.id })}
      animation={true}
      size="lg"
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary sizex="md" onClick={props.onCloseModal}>
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={handleSubmit}
            loading={formik.isSubmitting}
          >
            {t("Save")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.DateTime}
              formik={formik}
              property="offerDate"
              label={t("OfferDateModalEdit")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              type="money"
              formik={formik}
              property="value"
              label={t("Value")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              as="select"
              formik={formik}
              property="status"
              label={t("Status")}
              md={4}
            >
              <ComboOptionsFromEnumAtom
                propertyKey="status"
                optionsEnum={AdOfferStatusEnum}
                includeSelectPlaceholder
                hideOptionAll
                notSorted
              />
            </FormikControlAtom>
          </Form.Row>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="entity"
              label={t("Dealer")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="bidder"
              label={t("Name")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Phone}
              formik={formik}
              property="phoneNumber"
              label={t("PhoneNumber")}
              md={4}
            />
          </Form.Row>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Cellphone}
              formik={formik}
              property="cellPhone"
              label={t("CellPhone")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              type="email"
              formik={formik}
              property="email"
              label={t("Email")}
              md={4}
            />
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Cnpj}
              formik={formik}
              property="cnpj"
              label={t("Cnpj")}
              md={4}
            />
          </Form.Row>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
