import styled from "styled-components";

import Form from "react-bootstrap/Form";

const AddUserAction = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
`;

const AlertBox = styled.div`
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
`;

const Checkbox = styled(Form.Check.Input)`
  background: #ffffff;
  border: 1px solid #a8aaac;
  box-sizing: border-box;
  border-radius: 4px;
`;

const CheckboxLabel = styled(Form.Check.Label)`
  white-space: nowrap;
`;

const LoginAction = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const LoginReturnButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: left;
  }
`;
const LoginActionButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: right;
  }
`;

const TrashButton = styled.button`
  position: absolute;
  right: 22px;
  bottom: 22px;
  margin: 2px;
  border: 0;
`;

const DeleteActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export {
  AddUserAction,
  AlertBox,
  LoginAction,
  LoginActionButton,
  LoginReturnButton,
  TrashButton,
  DeleteActionContainer,
  Checkbox,
  CheckboxLabel,
};
