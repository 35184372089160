import styled from "styled-components";

interface ListItemProps {
  textColor?: string;
  strongTextColor?: string;
  italic?: boolean;
  semiBold?: boolean;
}

export const BaseTextStyle = styled.div`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const Title = styled(BaseTextStyle)`
  margin-bottom: 50px;

  color: #00204f;

  font-weight: bold;
  font-size: 30px;
`;

export const Content = styled(BaseTextStyle)`
  strong {
    color: #00aeef;

    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`;

export const List = styled.ul`
  padding-left: 28px;

  margin: 40px 0;
`;

export const ListItem = styled.li<ListItemProps>`
  color: ${({ textColor }) => (textColor ? textColor : "#4a4a4a")};

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  font-weight: ${({ semiBold }) => (semiBold ? "600" : "normal")};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;

  strong {
    color: ${({ strongTextColor }) =>
      strongTextColor ? strongTextColor : "#00204F"};
  }

  &:before {
    content: "·";
    position: absolute;

    margin-left: -20px;

    font-size: 30px;
  }
`;

export const HighlightText = styled(BaseTextStyle)`
  color: #f3bc2a;

  font-weight: bold;
`;
