import { ButtonSecondary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  onClick: () => void;
  buttonText?: string;
  buttonSizeX?: "lg" | "sm" | "md";
}

export const TitleWithBackButtonAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between">
      <h3>{props.title}</h3>
      <div>
        <ButtonSecondary
          sizex={props.buttonSizeX ? props.buttonSizeX : "lg"}
          onClick={props.onClick}
        >
          {props.buttonText ? props.buttonText : t("ReturnToList")}
        </ButtonSecondary>
      </div>
    </div>
  );
};
