import { InsuranceCompanyEnum, TypesHelper, TrackerPartnerEnum } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  PagedRequestParamsBaseModel,
  OriginEnum,
} from "..";

export interface IGetLogReportParams extends IPagedRequestParamsBaseModel {
  search?: string;
  origin?: OriginEnum | string;
  partnerEnum?: InsuranceCompanyEnum | TrackerPartnerEnum | string;
  startDate?: Date | string;
  endDate?: Date | string;
}

export class GetLogReportParams extends PagedRequestParamsBaseModel {
  search: string | null;
  origin: number | null;
  partnerEnum: number | null;
  startDate: string | null;
  endDate: string | null;

  constructor({
    search,
    origin,
    partnerEnum,
    startDate,
    endDate,
    ...baseParams
  }: IGetLogReportParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.origin = TypesHelper.toNumberNull(origin === "" ? undefined : origin);
    this.partnerEnum = TypesHelper.toNumberNull(
      partnerEnum === "" ? undefined : partnerEnum
    );
    this.startDate = TypesHelper.toDateNull(startDate)?.toISOString() ?? null;
    this.endDate = TypesHelper.toDateNull(endDate)?.toISOString() ?? null;
  }
}
