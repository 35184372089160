import React from "react";
import { Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PaginationControlAtom } from "../../../atoms";
import {
  LoadingDiv,
  LoadingText,
  NoEntriesText,
} from "./paginatable-table-list.molecule.style";

interface IProps {
  thead: string[];
  tbody?: any[][];
  dataIsLoading?: boolean;
  tableName: string;
  pagination?: {
    currentPage: number;
    lastPage: number;
    getPageCallback: (page: number) => void;
  };
  needOtherMessage?: boolean;
}

export const PaginatableTableList: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            {props.thead.map((m, i) => (
              <th key={`${props.tableName}-th${i}`}>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.tbody?.map((m, i) => (
            <tr key={`${props.tableName}-tr${i}`}>
              {m.map((n, j) => {
                n = n === undefined || n === null ? "-" : n;
                return <td key={`${props.tableName}-tr${i}td${j}`}>{n}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {props.dataIsLoading ? (
        <>
          <LoadingDiv>
            <Spinner animation="border" />
            <LoadingText>{t("Loading") + "..."}</LoadingText>
          </LoadingDiv>
        </>
      ) : !props.tbody?.length ? (
        <NoEntriesText>
          {props.needOtherMessage
            ? t("ThereIsNoDataInTheRangeProvided")
            : t("NoEntriesFound")}
        </NoEntriesText>
      ) : (
        props.pagination && (
          <PaginationControlAtom
            currentPage={props.pagination.currentPage}
            lastPage={props.pagination.lastPage}
            getPageCallback={props.pagination.getPageCallback}
          />
        )
      )}
    </>
  );
};
