import { TypesHelper } from "c4u-web-components";
import { IDetailedAdOffer } from "../..";

export interface IEditAdOfferProps
  extends Omit<IDetailedAdOffer, "dealershipMessage" | "offerDate"> {
  offerDate: string;
}

export class EditAdOfferRequest {
  id: number | null;
  offerDate: Date | null;
  value: number | null;
  status: number | null;
  entity: string | null;
  bidder: string | null;
  phoneNumber: string | null;
  cellPhone: string | null;
  email: string | null;
  cnpj: string | null;
  constructor({
    id,
    offerDate,
    value,
    status,
    entity,
    bidder,
    phoneNumber,
    cellPhone,
    email,
    cnpj,
  }: IEditAdOfferProps) {
    this.id = TypesHelper.toNumberNull(id);
    this.offerDate = TypesHelper.toDateTimeNull(offerDate);
    this.value = TypesHelper.toNumberNull(value);
    this.status = TypesHelper.toNumberNull(status);
    this.entity = TypesHelper.toStringNull(entity);
    this.bidder = TypesHelper.toStringNull(bidder);
    this.phoneNumber = TypesHelper.toStringOnlyNumbersNull(phoneNumber);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
    this.email = TypesHelper.toStringNull(email);
    this.cnpj = TypesHelper.toStringOnlyNumbersNull(cnpj);
  }
}
