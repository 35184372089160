import React from "react";
import { useTranslation } from "react-i18next";
import { AdsList, AdVehicleStatusTableOrganism } from "../../../organisms";

export const AdListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <AdVehicleStatusTableOrganism />

      <h3>{t("TitleAdsListPage")}</h3>
      <AdsList />
    </>
  );
};
