import { MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikControlAtom } from "../../../atoms";
import { FormUsersMoleculeValidation } from "./form-users.molecule.validation";

interface IProps {
  count: number;
  index: number;
  formId: string;
  onSubmit: (props: IFormUsersMolecule, errors: any) => Promise<void>;
  setSubmitEvent?: (eventFunction: () => void) => void;
  values: IFormUsersMolecule;
  onChangeValues: (values: IFormUsersMoleculeItems) => void;
}

export interface IFormUsersMoleculeItems extends IFormUsersMolecule {
  index: number;
}
export interface IFormUsersMolecule {
  id?: number;
  name: string;
  email: string;
  cellPhone: string;
}

export const FormUsersMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormUsersMolecule>({
    initialValues: props.values,
    onSubmit: async (values, { setErrors }) => {
      if (formik.dirty) await props.onSubmit(values, setErrors);
      formik.setSubmitting(false);
    },
    validationSchema: FormUsersMoleculeValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setValues(props.values);
    // eslint-disable-next-line
  }, [props.count]);

  useEffect(() => {
    if (props.onChangeValues)
      props.onChangeValues({ index: props.index, ...formik.values });
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if (props.setSubmitEvent && formik.submitForm) {
      props.setSubmitEvent(formik.submitForm);
    }
    // eslint-disable-next-line
  }, [formik.dirty]);

  useEffect(() => {
    if (props.values.id) formik.setFieldValue("id", props.values.id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.id]);

  return (
    <Form
      id={props.formId + props.index}
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <Col>
          <label className="form-label mb-4">
            {t("Seller")} {props.index + 1}
          </label>
        </Col>
      </Form.Row>
      <Form.Row>
        <FormikControlAtom formik={formik} md={6} property={"name"} />
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"email"}
          disabled={!!props.values.id}
          func={(value: string) => value && value.toLowerCase()}
        />
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"cellPhone"}
          mask={MaskHelper.Cellphone}
        />
      </Form.Row>
    </Form>
  );
};
