import { TypesHelper } from "c4u-web-components";

export interface IResetPasswordRequest {
  actualPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export class ResetPasswordRequest {
  actualPassword: string | null;
  newPassword: string | null;
  confirmPassword: string | null;

  constructor({
    actualPassword,
    newPassword,
    confirmPassword,
  }: IResetPasswordRequest) {
    this.actualPassword = TypesHelper.toStringNull(actualPassword);
    this.newPassword = TypesHelper.toStringNull(newPassword);
    this.confirmPassword = TypesHelper.toStringNull(confirmPassword);
  }
}
