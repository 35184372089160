import { CoxIcon } from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { IHistoricsRF } from "../../../../models";
import { Wrapper, WrapperDetails, Details } from "./details.molecule.style";
import { useTranslation } from "react-i18next";

interface IProps {
  data: IHistoricsRF;
}

export const HistoricsRFMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [seeMore, setSeemore] = useState(false);

  const renderView = (
    key: string,
    label: string,
    size?: number,
    func?: (attr: any) => string
  ) => {
    const values = props.data as any;

    const valueFormated = func ? func(values[key]) : values[key];

    return (
      <Form.Group as={Col} md={size ? size : 4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated ?? "-"} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <Wrapper>
      <>
        <h5>
          {t("Occurrence")}: {props.data.occurrence}
        </h5>
        <WrapperDetails
          onClick={() => setSeemore(!seeMore)}
          data-element="dropdown-more-details"
        >
          <Details close={seeMore}>{t("More details")}</Details>
          <CoxIcon name={!seeMore ? "arrow-circle" : "arrow-circle-closed"} />
        </WrapperDetails>
        {seeMore && (
          <>
            <hr />
            <Form.Row className="p-2">
              {renderView("code", t("Code"))}
              {renderView("hasError", t("HasError"))}
              {renderView("returnHistoricRF", t("ReturnHistoricRF"))}
              {renderView("occurrence", t("Occurrence"))}
              {renderView("chassi", t("Vin"))}
              {renderView("ufVehicle", t("UfVehicle"))}
              {renderView("securityAgency", t("SecurityAgency"))}
              {renderView("ufBO", t("UfBO"))}
              {renderView("numberBO", t("NumberBO"))}
              {renderView("yearBO", t("YearBO"))}
              {renderView("plate", t("Plate"))}
              {renderView("modelYear", t("ModelYear"))}
              {renderView("manufactureYear", t("ManufactureYear"))}
              {renderView("color", t("Color"))}
              {renderView("brandModel", t("BrandModel"))}
              {renderView("informant", t("Informant"))}
              {renderView("typeInformantDocument", t("TypeInformantDocument"))}
              {renderView(
                "numberDocumentInformant",
                t("NumberDocumentInformant")
              )}
              {renderView("phoneInformant", t("PhoneInformant"))}
              {renderView("date", t("Date"))}
              {renderView("state", t("Municipio"))}
              {renderView("message", t("Message"))}
            </Form.Row>
          </>
        )}
      </>
    </Wrapper>
  );
};
