import {
  ButtonSecondary,
  SpinnerCenteredAtom,
  PermissionEnum,
} from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { paths } from "../../../../../constants";
import { useManhein, useSessionContext } from "../../../../../hooks";
import {
  ICarDeliverySellerModel,
  ICarDeliveryBuyerModel,
} from "../../../../../models";
import {
  AdCarSellerDeliveryMolecule,
  AdCarBuyerDeliveryMolecule,
  DetailsPageSection,
} from "../../../../molecules";

export const CarDelivery: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<any>();

  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();

  const { getDeliveryBuyer, getDeliverySeller } = useManhein();

  const [seller, setSeller] = useState<ICarDeliverySellerModel | null>();
  const [buyer, setBuyer] = useState<ICarDeliveryBuyerModel | null>();

  useEffect(() => {
    if (seller === undefined) {
      getDeliverySeller(id)
        .then((v) => setSeller(v))
        .catch(() => setSeller(null));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (buyer === undefined) {
      getDeliveryBuyer(id)
        .then((v) => setBuyer(v))
        .catch(() => setBuyer(null));
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <DetailsPageSection title={t("Seller")} classNames={["seller"]}>
        {seller === null ? (
          <i>{t("No information")}</i>
        ) : seller === undefined ? (
          <SpinnerCenteredAtom />
        ) : (
          <AdCarSellerDeliveryMolecule data={seller} />
        )}
      </DetailsPageSection>

      <DetailsPageSection title={t("Buyer")} classNames={["buyer mt-5 mb-3"]}>
        {buyer === null ? (
          <i>{t("No information")}</i>
        ) : buyer === undefined ? (
          <SpinnerCenteredAtom />
        ) : (
          <AdCarBuyerDeliveryMolecule
            data={buyer}
            enable={validateUserAuth0Permission(
              userAuth0Context,
              PermissionEnum.BackofficeDelivery
            )}
          />
        )}
      </DetailsPageSection>

      <Row className={"py-4"}>
        <Col>
          <Link to={paths.backofficeAdListPage()}>
            <ButtonSecondary sizex={"md"}>{t("Return")}</ButtonSecondary>
          </Link>
        </Col>
      </Row>
    </>
  );
};
