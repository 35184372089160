import {
  ButtonPrimary,
  ButtonSecondary,
  Combo,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  CoxUserIsActiveBooleanEnum,
  ILenderOperatorRequest,
  LenderOperatorRequest,
} from "../../../../../models";
import { ComboOptionsFromEnumAtom } from "../../../../atoms";
import {
  LenderOperatorDetailsHR,
  WrapperButtonsLenderOperatorDetails,
  WrapperLenderOperatorDetails,
} from "./lender-operator-detail.molecule.style";
import { LenderOperatorValidationSchema } from "./lender-operator-detail.molecule.validation";

interface IProps {
  setShowErrorModal: (v: boolean) => void;
  setShowSuccessModal: (v: boolean) => void;
  setModalMessage: React.Dispatch<any>;
  onSubmit: (request: LenderOperatorRequest) => Promise<void>;
  initialValues?: ILenderOperatorRequest | null;
  userId: number;
  lenders: Combo[];
}

export const LenderOperatorDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const initialValues =
    props.initialValues ??
    ({
      id: 0,
      cpf: "",
      name: "",
      email: "",
      collectionOfficeCnpj: "",
      collectionOfficeName: "",
      isActive: "1",
    } as ILenderOperatorRequest);

  const isCreateMode = useMemo(() => !props.userId, [props.userId]);

  const formik = useFormik<ILenderOperatorRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await props.onSubmit(new LenderOperatorRequest(values));

          if (isCreateMode) {
            formik.setValues(initialValues);
            props.setModalMessage(t("CreateUserSuccessMsg"));
          } else {
            props.setModalMessage(t("UpdateUserSuccessMsg"));
          }

          props.setShowSuccessModal(true);
        } catch (e: any) {
          if (e?.errors) {
            setErrors(formikHandleErrorHelper(e.errors));
          }
          if (e?.message) {
            props.setModalMessage(e.message);
          } else {
            props.setModalMessage(JSON.stringify(e));
          }
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: LenderOperatorValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <WrapperLenderOperatorDetails>
      <LenderOperatorDetailsHR />

      <Form onSubmit={(e: any) => formik.handleSubmit(e)} autoComplete="off">
        <input type="hidden" value="disable-autocomplete-chrome" />

        <Form.Row>
          <FormikControlAtom
            md={5}
            formik={formik}
            property={"name"}
            label={t("Name")}
            maxLength={100}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={4}
            formik={formik}
            property={"email"}
            label={t("Email")}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={3}
            formik={formik}
            mask={MaskHelper.Cpf}
            property="cpf"
            label={t("Cpf")}
            translate={t}
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            sm={6}
            md={5}
            formik={formik}
            property="collectionOfficeName"
            label={t("CollectionOfficeName")}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={4}
            formik={formik}
            mask={MaskHelper.Cnpj}
            property="collectionOfficeCnpj"
            label={t("CollectionOfficeCnpj")}
            translate={t}
          />

          <FormikControlAtom
            type="autocomplete"
            md={3}
            formik={formik}
            data={props.lenders}
            property={"lenderId"}
            label={t("Bank")}
            placeholder={t("Tab here")}
            disabled={!!props.initialValues?.lenderId}
            translate={t}
            className="overwrite-input-css-attrs"
            borderColor="#ced4da"
            backgroundColor="#fff"
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            as={"select"}
            sm={6}
            md={2}
            formik={formik}
            property={"isActive"}
            label={t("Status")}
            translate={t}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="active"
              optionsEnum={CoxUserIsActiveBooleanEnum}
              hideOptionAll
            />
          </FormikControlAtom>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <WrapperButtonsLenderOperatorDetails>
              <div>
                <ButtonSecondary
                  sizex="md"
                  onClick={() => formik.setValues(initialValues)}
                  disabled={formik.isSubmitting}
                >
                  {t("ClearForm")}
                </ButtonSecondary>
              </div>

              <div>
                <ButtonPrimary
                  sizex="md"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {t("Save")}
                </ButtonPrimary>
              </div>
            </WrapperButtonsLenderOperatorDetails>
          </Form.Group>
        </Form.Row>
      </Form>
    </WrapperLenderOperatorDetails>
  );
};
