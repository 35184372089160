import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../helper";
import {
  PaginatableTableList,
  PaginatedTableSearchForm,
} from "../../molecules";
import { StdEnum } from "../../../models";

interface IProps {
  getDataCallback: (values: any) => any;
  statusEnum: StdEnum;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
  tableName?: string;
}

export const PaginatedTableSearchFormOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object({
    initialDate: Yup.date(),
    finalDate: Yup.date(),
    status: Yup.number()
      .nullable(true)
      .transform((v) => (v === "" ? null : v)),
    pageSize: Yup.number(),
    page: Yup.number().required(),
    orderBy: Yup.string(),
    textSearch: Yup.string(),
  });

  const [listData, setListData] = useState<any[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const formik = useFormik({
    initialValues: { pageSize: 100, page: 1 },
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          values
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback({
      ...formik.values,
      page: targetPage,
    });
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PaginatedTableSearchForm
        formik={formik}
        statusEnum={props.statusEnum}
        errorMessage={errorMessage}
      />
      <PaginatableTableList
        tableName={props.tableName ?? "generic-table-list"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
