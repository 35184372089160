import React from "react";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../../constants";
import { useProducts } from "../../../../../hooks";
import { DateHelper, StringHelper } from "../../../../../helper";
import {
  IGetTrackerListResponse,
  TrackerStepEnum,
} from "../../../../../models";
import { Link } from "react-router-dom";
import { TrackerListTableSearchOrganism } from "../tracker/tracker-list-table-search.organism";

export const KbbTrackerListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getTrackerListKbb } = useProducts();

  return (
    <>
      <TrackerListTableSearchOrganism
        getDataCallback={getTrackerListKbb}
        statusOptionsEnum={TrackerStepEnum}
        tableHeaders={[
          t("QuoteId"),
          t("CustomerId"),
          t("Name"),
          t("CpfCnpj"),
          t("Vehicle"),
          t("Plate"),
          t("PartnerQuoteItemId"),
          t("DealershipCnpj"),
          t("Date"),
          t("Step"),
          "",
        ]}
        mappingFunction={(m: IGetTrackerListResponse, i) => [
          m.id,
          m.customerId,
          m.name,
          StringHelper.formatCpfCnpj(m.document),
          m.vehicleName,
          m.plate?.toUpperCase(),
          m.partnerQuoteItemId,
          StringHelper.formatCpfCnpj(m.businessNumber),
          DateHelper.dateHourFormat(m.createdAt),
          `${m.step + 1} - ${t(TrackerStepEnum[m.step])}`,
          <Link key={`link${i}`} to={paths.backofficeTrackerDetailPage(m.id)}>
            {t("Details")}
          </Link>,
        ]}
      />
    </>
  );
};
