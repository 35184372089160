import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import { paths } from "../../../../constants/routes.constant";
import { useC4u } from "../../../../hooks";

export const AuthenticatePage = () => {
  const history = useHistory();
  const { Authenticate } = useC4u();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated) {
      Authenticate();
      history.push(paths.termsOfUse());
    }
  }, [isAuthenticated, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};
