import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  Combo,
  EnumHelper,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CopyDealershipsModalMolecule } from "../../..";
import { formikHandleErrorHelper, StringHelper } from "../../../../../helper";
import { useC4u } from "../../../../../hooks";
import {
  CorporateUserTypeEnum,
  CoxUserIsActiveBooleanEnum,
  CreateCorporateUserRequest,
  ICreateCorporateUserRequest,
  IDealershipComboData,
  ListDealershipsPagedParams,
} from "../../../../../models";
import { ComboOptionsFromEnumAtom, ManageItemsAtom } from "../../../../atoms";
import { WrapperCorporateUserDetails } from "./corporate-user-details-form.molecule.style";
import { CorporateUserDetailsValidationSchema } from "./corporate-user-details-form.molecule.validations";

interface IProps {
  setShow: (v: boolean) => void;
  setException: React.Dispatch<any>;
  initialValues: ICreateCorporateUserRequest;
}

export const CorporateUserDetailsFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postCreateCorporateUser, getListDealerships } = useC4u();

  const errorMessageDisplayTimeMilliSeconds = 2000;

  const [selectedDealerships, setSelectedDealerships] =
    useState<IDealershipComboData[]>();
  const [allDealershipsCombo, setAllDealershipsCombo] = useState<Combo[]>();
  const [allDealershipsData, setAllDealershipsData] =
    useState<IDealershipComboData[]>();
  const [timeOutState, setTimeoutState] = useState<NodeJS.Timeout>();
  const [showCopyModal, setShowCopyModal] = useState(false);

  const formik = useFormik<ICreateCorporateUserRequest>({
    initialValues: props.initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await postCreateCorporateUser(new CreateCorporateUserRequest(values));
          window.location.reload();
        } catch (e: any) {
          props.setShow(true);
          if (e.message) {
            props.setException(e.message);
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else {
            props.setException(JSON.stringify(e));
          }
        }
      }
    },
    validationSchema: CorporateUserDetailsValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    setSelectedDealerships(props.initialValues.corporateDealerships);
  }, [props.initialValues.corporateDealerships]);

  const searchAndSetDealershipsListAsync = useCallback(
    async (searchTerm: string) => {
      const { data: items } = await getListDealerships(
        new ListDealershipsPagedParams({
          pageSize: 50,
          search: searchTerm,
        })
      );
      const itemsCombo = items?.map(
        (m) =>
          new Combo({
            title: `${m.name} (${StringHelper.formatCpfCnpj(m.cnpj)})`,
            value: m.id,
          })
      );

      setAllDealershipsData(items);
      setAllDealershipsCombo(itemsCombo);
    },
    [getListDealerships]
  );

  const isManager = useMemo(() => {
    return (
      formik.values.corporateUserType?.toString() ===
      CorporateUserTypeEnum.Manager.toString()
    );
  }, [formik.values.corporateUserType]);

  const updateSelectedDealershipsFromCopy = useCallback(
    (newDealerships: IDealershipComboData[]) => {
      if (selectedDealerships === undefined)
        setSelectedDealerships(newDealerships);
      else {
        const selectedDealershipsIds = selectedDealerships.map((m) => m.id);
        const filteredDealerships = newDealerships.filter(
          (f) => !selectedDealershipsIds?.includes(f.id)
        );
        setSelectedDealerships([
          ...selectedDealerships,
          ...filteredDealerships,
        ]);
      }
    },
    [selectedDealerships]
  );

  useEffect(() => {
    const alreadyIncluded = selectedDealerships?.find(
      (f) => f.id === formik.values.currentDealershipSelection
    );
    if (alreadyIncluded) {
      formik.setFieldValue("currentDealershipSelection", "");
      formik.setFieldError(
        "currentDealershipSelection",
        t("Dealership already selected")
      );
      if (timeOutState) clearTimeout(timeOutState);
      const timeOut = setTimeout(
        () => formik.setFieldError("currentDealershipSelection", undefined),
        errorMessageDisplayTimeMilliSeconds
      );
      setTimeoutState(timeOut);
    } else {
      const newEntry = allDealershipsData?.find((f) => {
        return f.id === formik.values.currentDealershipSelection;
      });
      if (newEntry) {
        const localSelectedDealerships = selectedDealerships ?? [];
        formik.setFieldError("currentDealershipSelection", undefined);
        if (timeOutState) {
          clearTimeout(timeOutState);
          setTimeoutState(undefined);
        }

        setSelectedDealerships([...localSelectedDealerships, newEntry]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.currentDealershipSelection]);

  useEffect(() => {
    formik.setFieldValue("corporateDealerships", selectedDealerships);
    formik.setFieldValue("currentDealershipSelection", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDealerships]);

  return (
    <>
      <WrapperCorporateUserDetails>
        <hr />
        <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
          <Form.Row>
            <FormikControlAtom
              md={4}
              formik={formik}
              property={"name"}
              label={t("Name")}
              maxLength={100}
              translate={t}
            />

            <FormikControlAtom
              md={4}
              formik={formik}
              property="email"
              label={t("Email")}
              maxLength={100}
              translate={t}
            />

            <FormikControlAtom
              md={3}
              formik={formik}
              mask={MaskHelper.Phone}
              property={"cellPhone"}
              label={t("CellPhone")}
              translate={t}
            />

            <FormikControlAtom
              md={3}
              formik={formik}
              mask={MaskHelper.Cpf}
              property="cpf"
              label={t("CPF")}
              translate={t}
            />

            <FormikControlAtom
              type="select"
              data={EnumHelper.getComboFromEnum(CorporateUserTypeEnum, t)}
              md={3}
              formik={formik}
              property="corporateUserType"
              label={t("TypeOfUser")}
              translate={t}
            />

            <FormikControlAtom
              as={"select"}
              md={2}
              formik={formik}
              property={"active"}
              label={t("Status")}
              translate={t}
            >
              <ComboOptionsFromEnumAtom
                propertyKey="active"
                optionsEnum={CoxUserIsActiveBooleanEnum}
                hideOptionAll
                includeSelectPlaceholder
              />
            </FormikControlAtom>
          </Form.Row>

          <Form.Group as={Col} md className={"text-right"}>
            <ButtonPrimary
              sizex="md"
              type="submit"
              loading={formik.isSubmitting}
            >
              {t("Save")}
            </ButtonPrimary>
          </Form.Group>

          {!isManager && (
            <Form.Row className="d-flex justify-content-center">
              <FormikControlAtom
                type="autocomplete-async"
                md={6}
                xl={4}
                formik={formik}
                data={allDealershipsCombo}
                property={"currentDealershipSelection"}
                label={t("SelectDealerships")}
                placeholder={t("Start typing to search for dealerships")}
                translate={t}
                className="overwrite-input-css-attrs"
                borderColor="#ced4da"
                backgroundColor="#fff"
                onSearchAsync={searchAndSetDealershipsListAsync}
                filterBy={() => true}
                useCache={false}
                maxHeight="185px"
                maxResults={5}
                delay={300}
              />

              <Col sm={6} md={3} xl={2} className="text-center pt-4">
                <ButtonTertiary
                  sizex="md"
                  onClick={() => setShowCopyModal(true)}
                >
                  {t("Copy dealerships")}
                </ButtonTertiary>
              </Col>

              <Col sm={6} md={3} xl={2} className="text-center pt-4">
                <ButtonSecondary
                  sizex="md"
                  onClick={() => setSelectedDealerships(undefined)}
                >
                  {t("Delete all")}
                </ButtonSecondary>
              </Col>
            </Form.Row>
          )}

          <Form.Row className="mb-4">
            <Col>
              {isManager ? (
                <div>{t("Managers have access to all dealerships")}</div>
              ) : (
                <ManageItemsAtom
                  fieldsetTitle={t("Assigned Dealerships")}
                  removeItemCallback={(id: number) => {
                    const aux = selectedDealerships?.filter((f) => f.id !== id);
                    if (aux) setSelectedDealerships(aux);
                  }}
                  items={selectedDealerships?.map((m) => ({
                    id: m.id,
                    line1: m.name,
                    line2: `(${StringHelper.formatCpfCnpj(m.cnpj)})`,
                  }))}
                />
              )}
            </Col>
          </Form.Row>
        </Form>
      </WrapperCorporateUserDetails>

      <CopyDealershipsModalMolecule
        show={showCopyModal}
        onHide={() => setShowCopyModal(false)}
        onApplyCopy={updateSelectedDealershipsFromCopy}
      />
    </>
  );
};
