import React from "react";
import { Col, Form } from "react-bootstrap";
import { IFindHistoryReturn } from "../../../../models";
import { Wrapper } from "./details.molecule.style";
import { useTranslation } from "react-i18next";

interface IProps {
  data: IFindHistoryReturn;
}

export const BinReturnMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderView = (
    key: string,
    label: string,
    size?: number,
    func?: (attr: any) => string
  ) => {
    const values = props.data as any;

    const valueFormated = func ? func(values[key]) : values[key];

    return (
      <Form.Group as={Col} md={size ? size : 4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <>
      <Wrapper>
        <Form.Row className="p-2">
          {renderView("id", t("ID"))}
          {renderView("hasError", t("HasError"))}
          {renderView("code", t("Code"))}
          {renderView("message", t("Message"))}
          {renderView("parameter", t("Parameter"))}
        </Form.Row>
      </Wrapper>
    </>
  );
};
