import { TypesHelper } from "c4u-web-components";

export class PostInspectionReportRequest {
  refunded?: boolean;
  uniqueCode?: string | null;
  sellerOwner?: boolean;
  financingFreeVehicle?: boolean;
  vehicleFreeLegalRestrictions?: boolean;
  freeVehicleAuction?: boolean;
  inspectionStatus?: string | null;
  inspectionReportComplete?: string | Blob | null;
  inspectionReportCustom?: string | Blob | null;
  constructor({
    refunded,
    uniqueCode,
    sellerOwner,
    financingFreeVehicle,
    vehicleFreeLegalRestrictions,
    freeVehicleAuction,
    inspectionStatus,
    inspectionReportComplete,
    inspectionReportCustom,
  }: PostInspectionReportRequest) {
    this.refunded = !!refunded;
    this.uniqueCode = TypesHelper.toStringNull(uniqueCode);
    this.sellerOwner = !!sellerOwner;
    this.financingFreeVehicle = !!financingFreeVehicle;
    this.vehicleFreeLegalRestrictions = !!vehicleFreeLegalRestrictions;
    this.freeVehicleAuction = !!freeVehicleAuction;
    this.inspectionStatus = TypesHelper.toStringNull(inspectionStatus);
    this.inspectionReportComplete = inspectionReportComplete;
    this.inspectionReportCustom = inspectionReportCustom;
  }
}

export interface IInspectionReport {
  refunded?: boolean;
  uniqueCode?: string;
  sellerOwner?: boolean;
  financingFreeVehicle?: boolean;
  vehicleFreeLegalRestrictions?: boolean;
  freeVehicleAuction?: boolean;
  inspectionStatus?: string;
  inspectionReportComplete?: string | Blob;
  inspectionReportCustom?: string | Blob;
}

export interface IInspectionModel {
  success: boolean;
  message: string;
}
