import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../..";
import { renderViewHelper, StringHelper } from "../../../../../helper";
import { IInsurancePepDetails } from "../../../../../models";

interface IProps {
  pepDetails?: IInsurancePepDetails;
}

export const InsurancePepDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewPep = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.pepDetails, key, label, func);

  return !props.pepDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>{renderViewPep("politicallyExposedPerson", "")}</Form.Row>
      {props.pepDetails.isCloseRelative && (
        <>
          <DetailsPageSubsection title={t("InsurancePepRelativeDetails")}>
            <Form.Row>
              {renderViewPep("politicallyExposedRelativeName", t("Name"))}
              {renderViewPep(
                "politicallyExposedRelativeCpf",
                t("Cpf"),
                StringHelper.formatCpfCnpj
              )}
              {renderViewPep(
                "politicallyExposedRelativeRelationshipType",
                t("PepRelativeRelationship")
              )}
              {renderViewPep("politicallyExposedRelativeEmail", t("Email"))}
              {renderViewPep(
                "politicallyExposedRelativePhoneNumber",
                t("PhoneNumber")
              )}
            </Form.Row>
          </DetailsPageSubsection>
          <DetailsPageSubsection title={t("InsurancePepRelativeAddress")}>
            <Form.Row>
              {renderViewPep(
                "politicallyExposedRelativeAddressType",
                t("AddressType")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressStreet",
                t("Street")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressNumber",
                t("Number")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressComplement",
                t("Complement")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressNeighborhood",
                t("Neighborhood")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressZipCode",
                t("ZipCode"),
                StringHelper.formatZipCodeBR
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressCity",
                t("City")
              )}
              {renderViewPep(
                "politicallyExposedRelativeAddressState",
                t("State")
              )}
            </Form.Row>
          </DetailsPageSubsection>
        </>
      )}
    </>
  );
};
