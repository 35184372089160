import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { StringHelper } from "../../../../../helper";
import { useC4u } from "../../../../../hooks";
import {
  ApprovalStatusEnum,
  CoxUserIsActiveBooleanEnum,
  IListUserResponse,
} from "../../../../../models";
import { UserListTableSearchOrganism } from "./user-list-table-search.organism";

export const UserListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { GetAllUsers } = useC4u();

  return (
    <UserListTableSearchOrganism
      getDataCallback={GetAllUsers}
      DealershipStatusEnum={ApprovalStatusEnum}
      CoxUserIsActiveBooleanEnum={CoxUserIsActiveBooleanEnum}
      tableHeaders={[
        "#",
        t("DealershipStatus"),
        t("UserStatus"),
        t("TypeOfUser"),
        t("Cnpj"),
        t("Dealership"),
        t("CoxUserEmail"),
        t("CoxUserName"),
        "",
      ]}
      mappingFunction={(m: IListUserResponse, i) => [
        m.coxUserId,
        t(ApprovalStatusEnum[m.dealershipStatus]),
        m.coxUserActive ? t("Active") : t("Inactive"),
        m.coxUserIsMaster ? t("Master") : t("Seller"),
        StringHelper.formatCpfCnpj(m.dealershipCnpj),
        m.dealershipName,
        m.coxUserEmail,
        m.coxUserName,
        <Link
          key={`link${i}`}
          to={paths.backofficeUserPage(m.coxUserId)}
          className="d-flex"
        >
          <span className="pr-2">{t("Edit")}</span>
          <CoxIcon
            name="pencil-edit"
            width={16}
            height={16}
            fillColor="#1F3E74"
          />
        </Link>,
      ]}
    />
  );
};
