import {
  ButtonPrimary,
  DateHelper,
  FormikControlAtom,
  PermissionEnum,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FeedbackModal } from "../../..";
import {
  formikHandleErrorHelper,
  renderViewHelper,
} from "../../../../../helper";
import { useProducts, useSessionContext } from "../../../../../hooks";
import {
  IUpdateVehicleDebtRequest,
  IZapayTransactionData,
  PaymentStatusEnum,
  UpdateVehicleDebtRequest,
} from "../../../../../models";
import { ComboOptionsFromEnumAtom } from "../../../../atoms";

interface IProps {
  id: number;
  transactionDetails?: IZapayTransactionData;
}

export const ZapayTransactionDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { putVehicleDebtStatus } = useProducts();
  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exception, setException] = useState<any>();

  const enableEdit = useMemo(
    () =>
      validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeProdutcsDebtsWrite
      ),
    [userAuth0Context, validateUserAuth0Permission]
  );

  const renderViewTransaction = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.transactionDetails, key, label, func);

  const validations = Yup.object<IUpdateVehicleDebtRequest>({
    id: Yup.number().notOneOf([NaN]).required(),
    status: Yup.number<PaymentStatusEnum>().notOneOf([NaN]).required(),
  });

  const formik = useFormik({
    initialValues: {
      id: props.id,
      status: props.transactionDetails?.status,
    } as IUpdateVehicleDebtRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmNewStatus"))) {
        try {
          await putVehicleDebtStatus(new UpdateVehicleDebtRequest(values));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
            console.log("caiu no if");
          } else {
            if (e?.message) {
              setException(e.message);
            } else {
              setException(e);
            }
            setShowErrorModal(true);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

  useEffect(() => {
    setDisableSaveButton(
      !(
        formik.values.status !== undefined &&
        formik.values.status.toString() !== ""
      )
    );
  }, [formik.values.status]);

  useEffect(() => {
    if (props.transactionDetails?.status)
      formik.setFieldValue("status", props.transactionDetails?.status);
  }, [props.transactionDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return !props.transactionDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          {renderViewTransaction("debitsProtocol", t("DebitsProtocol"))}
          {!enableEdit ? (
            renderViewTransaction(
              "status",
              t("Status"),
              (v: PaymentStatusEnum) => t(PaymentStatusEnum[v])
            )
          ) : (
            <FormikControlAtom
              translate={t}
              formik={formik}
              as={"select"}
              md={4}
              property={"status"}
              label={t("Status")}
              disabled={!enableEdit}
            >
              <ComboOptionsFromEnumAtom
                propertyKey="status"
                optionsEnum={PaymentStatusEnum}
                includeSelectPlaceholder
                hideOptionAll
                notSorted
              />
            </FormikControlAtom>
          )}
          {renderViewTransaction(
            "paymentDate",
            t("PaymentDate"),
            DateHelper.dateHourFormat
          )}
        </Form.Row>

        {enableEdit && (
          <Form.Row>
            <Form.Group as={Col} className={"text-right pt-4"}>
              <ButtonPrimary
                type={"submit"}
                sizex={"md"}
                loading={formik.isSubmitting}
                disabled={!enableEdit || disableSaveButton}
              >
                {t("SetStep")}
              </ButtonPrimary>
            </Form.Group>
          </Form.Row>
        )}
      </Form>

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setException(undefined);
        }}
        content={exception ? exception : t("api_error")}
      />
    </>
  );
};
