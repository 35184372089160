import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled(Row)`
  align-items: center;
  display: flex;

  background: #ffffff;

  box-shadow: 0px -3px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;

  position: fixed;

  left: 0px;
  z-index: 4;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  width: 100%;
  overflow: visible;
  clear: both;
  margin: 0px;
  bottom: 0px;

  a {
    text-decoration: none !important;
  }

  .notifications-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 576px) {
    top: 0px;
    box-shadow: 0px 2px 6px rgba(204, 204, 204, 0.3);
  }
`;

export const C4UTitle = styled.a`
  position: absolute;

  margin: 0px;
  left: 12px;

  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.15px;
  z-index: 1;

  color: #1f3e74;

  @media (max-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 575px) {
    display: none !important;
  }
`;
