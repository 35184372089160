export const phoneNumberMask = [
  "(",/\d/,/\d/,")",
  " ",
  /\d/,/\d/,/\d/,/\d/,
  "-",
  /\d/,/\d/,/\d/,/\d/
];

export const cellPhoneNumberMask = [
  "(",/\d/,/\d/,")",
  " ",
  /\d/,/\d/,/\d/,/\d/,/\d/,
  "-",
  /\d/,/\d/,/\d/,/\d/
];

export const cpfMask = [
  /\d/,/\d/,/\d/,
  ".",
  /\d/,/\d/,/\d/,
  ".",
  /\d/,/\d/,/\d/,
  "-",
  /\d/,/\d/,
];

export const cnpjMask = [
  /\d/,/\d/,
  ".",
  /\d/,/\d/,/\d/,
  ".",
  /\d/,/\d/,/\d/,
  "/",
  /\d/,/\d/,/\d/,/\d/,
  "-",
  /\d/,/\d/,
];

export const dateBRMask = [
  /\d/,/\d/,
  "/",
  /\d/,/\d/,
  "/",
  /\d/,/\d/,/\d/,/\d/
];

export const zipCodeMask = [
  /\d/,/\d/,/\d/,/\d/,/\d/,
  "-",
  /\d/,/\d/,/\d/
];
