import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useManhein } from "../../../../hooks";
import {
  AdCustomersReportSearchForm,
  PaginatableTableList,
} from "../../../molecules";
import {
  ICustomerReport,
  IPagedRequestParamsBaseModel,
  PagedRequestParamsBaseModel,
} from "../../../../models";
import { DateHelper, formikHandleErrorHelper } from "../../../../helper";

export const AdCustomersReport: React.FC = () => {
  const { getCustomerReport } = useManhein();

  const { t } = useTranslation();

  const [listData, setListData] = useState<ICustomerReport[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();

  const initialValues: IPagedRequestParamsBaseModel = {
    pageSize: 2000,
    page: 1,
    orderBy: "",
  };

  const formik = useFormik<IPagedRequestParamsBaseModel>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setListData(undefined);
        const { data, currentPage, totalPages } = await getCustomerReport(
          new PagedRequestParamsBaseModel(values)
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => formik.setValues(initialValues);

  const getNewPage = async (targetPage: number) => {
    const param = new PagedRequestParamsBaseModel(formik.values);
    param.page = targetPage;
    const { data, currentPage, totalPages } = await getCustomerReport(param);
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AdCustomersReportSearchForm
        formik={formik}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />

      <PaginatableTableList
        tableName={"api-usage-report"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={formik.isSubmitting}
        thead={[
          "#",
          t("Created By"),
          t("Created At"),
          t("IdAuth0"),
          t("Name"),
          t("Document"),
          t("Birthdate"),
          t("E-mail"),
          t("CellPhone"),
          t("PhoneNumber"),
          t("AcceptedReceiveSms"),
        ]}
        tbody={listData?.map((m, i) => [
          m.id,
          m.createdBy,
          DateHelper.dateHourFormat(m.createdAt),
          m.idAuth0,
          m.name,
          m.document,
          DateHelper.dateFormat(m.birthDate),
          m.email,
          m.mobile,
          m.phoneNumber,
          m.acceptedReceiveSms ? t("Yes") : t("No"),
        ])}
      />
    </>
  );
};
