import * as Yup from "yup";
import { setLocale } from "yup";

setLocale({
  mixed: {
    default: (err) => ({ key: "field_invalid", field: err.path }),
    required: (err) => ({ key: "field_required", field: err.path }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: "field_too_small", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
  string: {
    email: (err) => ({ key: "field_invalid_email", field: err.path }),
  },
});

export const CreateUserFormValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required().max(100),
    email: Yup.string().required().trim().email().max(320), //according to RFC 3696
  })
  .defined();

export type CreateUserForm = Yup.InferType<
  typeof CreateUserFormValidationSchema
>;
