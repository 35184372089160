import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { FieldMetaProps, FormikErrors, useFormik } from "formik";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonLink,
  CoxIcon,
  PermissionEnum,
} from "c4u-web-components";

import { UserAccountDetailsValidationSchema } from "./user-account-details.organism.validations";
import { formikHandleErrorHelper } from "../../../../helper";
import { useC4u, useSessionContext } from "../../../../hooks";
import {
  IOnboardingCompletion,
  IDealership,
  IntegrationRecord,
  IRetryIntegration,
  IRetryDealertrackIntegration,
  IUserViewModel,
} from "../../../../hooks/apis/use-c4u/interfaces/interfaces";
import { paths } from "../../../../constants";
import { FeedbackModal, TableList } from "../../../molecules";
import { GeneralError } from "../../../atoms";

export const UserAccountDetailsOrganism: React.FC = () => {
  const {
    GetDealer,
    GetOnboarding,
    ApproveOnboarding,
    DeclineOnboarding,
    RetryWithAutoAvaliar,
    RetryWithIdp,
    RetryWithDealertrack,
    GetByDealershipId,
    InactivateDealer,
  } = useC4u();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [currentDealership, setCurrentDealership] = useState({} as IDealership);
  const [publicId, setPublicId] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [techErrorDetail, setTechErrorDetail] = useState(undefined as any);
  const [integrationRecords, setIntegrationRecords] = useState(
    [] as Array<IntegrationRecord> | undefined
  );
  const [dealershipUsers, setDealershipUsers] = useState<IUserViewModel[]>();

  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();

  const formik = useFormik<IOnboardingCompletion>({
    initialValues: {
      hasBackofficeRegistrationAccess: false,
      hasBackofficeAdsAccess: false,
      hasDealertrackAccess: false,
      hasKbbAccess: false,
      hasManheimAccess: false,
      hasProductsB3Access: false,
      hasProductsDebtsAccess: false,
      hasProductsInspectionAccess: false,
      hasProductsInsuranceAccess: false,
    } as IOnboardingCompletion,
    onSubmit: async (values, { setErrors }) => {
      try {
        values.approvalStatus = 1;
        values.createdById = currentDealership.createdById;
        await ApproveOnboarding(values);
        history.push(paths.backofficeUserAccountListPage());
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          alert(t("api_error"));
        }
      }
    },
    validationSchema: UserAccountDetailsValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const decline = async (message?: string) => {
    const onboarding = {
      approvalStatus: 2,
      createdById: currentDealership.createdById,
      message: message,
    } as IOnboardingCompletion;
    await DeclineOnboarding(onboarding);
    history.push(paths.backofficeUserAccountListPage());
  };

  const inactivate = async () => {
    await InactivateDealer(
      Number(currentDealership.id ?? 0),
      !currentDealership.active
    );
    history.push(paths.backofficeUserAccountListPage());
  };

  const doRetryWithAutoAvaliar = (userEmail: string | null | undefined) => {
    if (!userEmail) return;

    const request = { userEmail } as IRetryIntegration;
    RetryWithAutoAvaliar(request)
      .then(() => {
        getDealerAsync();
      })
      .catch((res) => {
        console.error(res);
        setTechErrorDetail(res);
        setShowMessageModal(true);
      });
  };

  const doRetryWithIdp = (userEmail: string | null | undefined) => {
    if (!userEmail) return;

    const request = { userEmail } as IRetryIntegration;
    RetryWithIdp(request)
      .then(() => {
        getDealerAsync();
      })
      .catch((res) => {
        console.error(res);
        setTechErrorDetail(res);
        setShowMessageModal(true);
      });
  };

  const doRetryWithDealertrack = (publicIdParam?: string) => {
    const request = {
      createdByPublicId: publicIdParam ?? publicId,
    } as IRetryDealertrackIntegration;
    RetryWithDealertrack(request)
      .then(() => {
        getDealerAsync();
      })
      .catch((res) => {
        console.error(res);
        setTechErrorDetail(res);
        setShowMessageModal(true);
      });
  };

  const getOnboardingAsync = async (
    dealership: IDealership
  ): Promise<IOnboardingCompletion> => {
    const onboarding = await GetOnboarding(dealership.createdById ?? 0);
    return onboarding;
  };

  const getDealerAsync = useCallback(async () => {
    const dealerResult = await GetDealer(Number(id));
    const onboarding = await getOnboardingAsync(dealerResult);
    setCurrentDealership(dealerResult);
    setPublicId(onboarding.createdByPublicId ?? "");
    setIntegrationRecords(onboarding.integrationRecords);
    const {
      hasBackofficeAdsAccess,
      hasBackofficeRegistrationAccess,
      hasDealertrackAccess,
      hasKbbAccess,
      hasManheimAccess,
      hasProductsB3Access,
      hasProductsDebtsAccess,
      hasProductsInspectionAccess,
      hasProductsInsuranceAccess,
    } = onboarding;

    formik.setValues(
      {
        hasBackofficeAdsAccess,
        hasBackofficeRegistrationAccess,
        hasDealertrackAccess,
        hasKbbAccess,
        hasManheimAccess,
        hasProductsB3Access,
        hasProductsDebtsAccess,
        hasProductsInspectionAccess,
        hasProductsInsuranceAccess,
        ...onboarding,
        dealership: dealerResult,
      },
      false
    );
    // eslint-disable-next-line
  }, [GetDealer, formik, id]);

  useEffect(() => {
    getDealerAsync();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!dealershipUsers)
      GetByDealershipId(Number(id))
        .then((t) => setDealershipUsers(t))
        .catch(() => setDealershipUsers([]));
    // eslint-disable-next-line
  }, [dealershipUsers]);

  const renderCheck = (key: string) => {
    const valueFormated = formik.values
      ? (formik.values as any)[key]
      : undefined;
    const error = formik.errors ? (formik.errors as any)[key] : undefined;
    return (
      <Form.Group
        as={Col}
        md={4}
        controlId={key}
        style={{ display: "flex", gap: "3px" }}
      >
        <Form.Check
          value={valueFormated}
          checked={valueFormated}
          onChange={formik.handleChange}
          isInvalid={!!error}
          feedback={<>{t("field_required")}</>}
          feedbackTooltip={true}
        />
        <Form.Label>{t(key)}</Form.Label>
      </Form.Group>
    );
  };

  const getControl = (labelT: string, key: string, value: any, error: any) => {
    return (
      <Form.Group as={Col} md={4} controlId={key}>
        <Form.Label>{t(labelT)}</Form.Label>
        <Form.Control
          onChange={formik.handleChange}
          value={value ?? ""}
          isInvalid={!!error}
          disabled={true}
        />
        <Form.Control.Feedback type="invalid">
          {error ?? ""}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const {
    isSubmitting,
    getFieldMeta,
    setErrors,
  }: {
    isSubmitting: boolean;
    getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
    setErrors: (errors: FormikErrors<any>) => void;
  } = formik;

  const copy = (id: string) => {
    var copyText = document.getElementById(id) as any;

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <>
      <FeedbackModal
        type={"error"}
        show={showMessageModal}
        onHide={() => setShowMessageModal(false)}
        content={t("api_error")}
      >
        <>{JSON.stringify(techErrorDetail)}</>
      </FeedbackModal>

      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <br />
        <GeneralError
          errorProp={getFieldMeta("general")}
          errorCode={getFieldMeta("errorCode")}
          setErrors={setErrors}
        />
        <br />
        <Form.Row>
          {getControl(
            "Dealership Name",
            "name",
            formik.values.dealership?.name,
            formik.errors.dealership
          )}
          {getControl(
            "Dealership Legal Name",
            "legalName",
            formik.values.dealership?.legalName,
            formik.errors.dealership
          )}
          {getControl(
            "CNPJ",
            "cnpj",
            formik.values.dealership?.cnpj,
            formik.errors.dealership
          )}
        </Form.Row>

        <Form.Row>
          {getControl(
            "Dealership Email",
            "email",
            formik.values.dealership?.email,
            formik.errors.dealership
          )}
          {getControl(
            "Dealership Phone Primary",
            "primaryPhone",
            formik.values.dealership?.primaryPhone,
            formik.errors.dealership
          )}
          {getControl(
            "Dealership Phone Secondary",
            "secondaryPhone",
            formik.values.dealership?.secondaryPhone,
            formik.errors.dealership
          )}
        </Form.Row>

        <hr />

        <Form.Row>
          <Form.Group as={Col} md={4} controlId={"approved"}>
            <Form.Label>{t("Approved")}</Form.Label>
            <Form.Control
              as="select"
              onChange={formik.handleChange}
              value={currentDealership.approvalStatus}
              isInvalid={!!formik.errors.approvalStatus}
              disabled={true}
            >
              <option value={"0"}>{t("Pending")}</option>
              <option value={"1"}>{t("Approved")}</option>
              <option value={"2"}>{t("Disapproved")}</option>
              <option value={"3"}>{t("Incomplete")}</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.approvalStatus ?? ""}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="message">
            <Form.Label>{t("Message")}</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              value={formik.values.message ?? ""}
              isInvalid={!!formik.errors.message}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.message ?? ""}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          {renderCheck("hasBackofficeRegistrationAccess")}
          {renderCheck("hasBackofficeAdsAccess")}
          {renderCheck("hasDealertrackAccess")}
          {renderCheck("hasKbbAccess")}
          {renderCheck("hasManheimAccess")}
          {renderCheck("hasProductsB3Access")}
          {renderCheck("hasProductsDebtsAccess")}
          {renderCheck("hasProductsInspectionAccess")}
          {renderCheck("hasProductsInsuranceAccess")}
        </Form.Row>

        <hr />

        <Form.Row className={"mt-5"}>
          <Form.Group as={Col} md controlId="name">
            <Link to={paths.backofficeUserAccountListPage()}>
              <ButtonSecondary sizex={"md"}>{t("Return")}</ButtonSecondary>
            </Link>
          </Form.Group>

          <Form.Group as={Col} md controlId="goToDealership">
            <Link target="blank" to={`/${publicId}/dealership/create`}>
              <ButtonSecondary sizex={"md"}>
                {t("See Complete Info")}
              </ButtonSecondary>
            </Link>
          </Form.Group>

          {!!validateUserAuth0Permission(
            userAuth0Context,
            PermissionEnum.BackofficeAdsApprove
          ) && (
            <>
              <Form.Group as={Col} md controlId="goToDealership">
                <ButtonSecondary sizex={"md"} onClick={() => inactivate()}>
                  {currentDealership.active ? t("Inactivate") : t("Activate")}
                </ButtonSecondary>
              </Form.Group>

              <Form.Group as={Col} md controlId="goToDealership">
                <ButtonSecondary
                  sizex={"md"}
                  onClick={() => decline(formik.values.message)}
                  profile="danger"
                  disabled={currentDealership.approvalStatus !== 0}
                >
                  {t("Decline")}
                </ButtonSecondary>
              </Form.Group>

              <Form.Group
                as={Col}
                md
                controlId="legalName"
                className={"text-right"}
              >
                <ButtonPrimary
                  type={"submit"}
                  sizex={"md"}
                  loading={isSubmitting}
                  disabled={
                    isSubmitting || currentDealership.approvalStatus !== 0
                  }
                >
                  {t("Approve")}
                </ButtonPrimary>
              </Form.Group>
            </>
          )}
        </Form.Row>
        <hr />
        <hr />
        <h1>{t("Integration Summary")}</h1>
        <h3>Identidade</h3>
        <TableList
          thead={[
            t("Status"),
            t("Created By"),
            t("ActiveUser"),
            t("Details"),
            "",
          ]}
        >
          {integrationRecords
            ?.filter((x) => x.type === 0)
            .map((irec, i) => {
              var record = new IntegrationRecord();
              record.status = irec.status;
              record.createdBy = irec.createdBy;
              record.targetUser = irec.targetUser;
              record.details = irec.details;

              return (
                <tr key={`tr${i}`}>
                  <td>{record.getStatus()}</td>
                  <td>{record.createdBy?.email}</td>
                  <td>{record.targetUser?.email}</td>
                  <td>{record.details}</td>
                  <td>
                    <ButtonLink
                      key={`retry${i}`}
                      onClick={() => doRetryWithIdp(record.targetUser?.email)}
                      sizex="md"
                      sizey="thin"
                    >
                      {t("Retry")}
                    </ButtonLink>
                  </td>
                </tr>
              );
            })}
        </TableList>

        <h3>Auto Avaliar</h3>
        <TableList
          thead={[
            t("Status"),
            t("Created By"),
            t("ActiveUser"),
            t("Details"),
            "",
          ]}
        >
          {integrationRecords
            ?.filter((x) => x.type === 1)
            .map((irec, i) => {
              var record = new IntegrationRecord();
              record.status = irec.status;
              record.createdBy = irec.createdBy;
              record.targetUser = irec.targetUser;
              record.details = irec.details;

              return (
                <tr key={`tr${i}`}>
                  <td>{record.getStatus()}</td>
                  <td>{record.createdBy?.email}</td>
                  <td>{record.targetUser?.email}</td>
                  <td>{record.details}</td>
                  <td>
                    <ButtonLink
                      key={`retry${i}`}
                      onClick={() =>
                        doRetryWithAutoAvaliar(record.targetUser?.email)
                      }
                      sizex="md"
                      sizey="thin"
                    >
                      {t("Retry")}
                    </ButtonLink>
                  </td>
                </tr>
              );
            })}
        </TableList>

        <h3>Dealertrack</h3>
        <TableList
          thead={[
            t("Status"),
            t("Created By"),
            t("ActiveUser"),
            t("Details"),
            "",
          ]}
        >
          {integrationRecords
            ?.filter((x) => x.type === 2)
            .map((irec, i) => {
              var record = new IntegrationRecord();
              record.status = irec.status;
              record.createdBy = irec.createdBy;
              record.targetUser = irec.targetUser;
              record.details = irec.details;

              return (
                <tr key={`tr${i}`}>
                  <td>{record.getStatus()}</td>
                  <td>{record.createdBy?.email}</td>
                  <td>{record.targetUser?.email}</td>
                  <td>{record.details}</td>
                  <td>
                    <ButtonLink
                      key={`retry${i}`}
                      onClick={() => doRetryWithDealertrack()}
                      sizex="md"
                      sizey="thin"
                    >
                      {t("Retry")}
                    </ButtonLink>
                  </td>
                </tr>
              );
            })}
        </TableList>
      </Form>
      {!!validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeAdsApprove
      ) && (
        <div className={"pb-4"}>
          <hr />
          <h1>{t("Users")}</h1>
          <TableList
            thead={[
              t("Id"),
              t("Name"),
              t("Email"),
              t("IsDt"),
              t("IsMaster"),
              t("PublicId"),
              t("Token"),
              t("Active"),
            ]}
          >
            {dealershipUsers?.map((dealer, i) => (
              <tr key={`dealers-tr${i}`}>
                <td>
                  {dealer.id}
                  {validateUserAuth0Permission(
                    userAuth0Context,
                    PermissionEnum.BackofficeWebhook
                  ) && (
                    <>
                      &nbsp;
                      <button onClick={() => doRetryWithIdp(dealer.email)}>
                        ID
                      </button>
                    </>
                  )}
                </td>
                <td>{dealer.name}</td>
                <td>{dealer.email}</td>
                <td>
                  {dealer.isDt ? t("Yes") : t("No")}
                  {dealer.isMaster &&
                    validateUserAuth0Permission(
                      userAuth0Context,
                      PermissionEnum.BackofficeWebhook
                    ) && (
                      <>
                        &nbsp;
                        <button
                          onClick={() =>
                            doRetryWithDealertrack(dealer.publicId)
                          }
                        >
                          DT
                        </button>
                      </>
                    )}
                </td>
                <td>
                  {dealer.isMaster ? t("Yes") : t("No")}
                  {dealer.isMaster &&
                    validateUserAuth0Permission(
                      userAuth0Context,
                      PermissionEnum.BackofficeWebhook
                    ) && (
                      <>
                        &nbsp;
                        <button
                          onClick={() => doRetryWithAutoAvaliar(dealer.email)}
                        >
                          AA
                        </button>
                      </>
                    )}
                </td>
                <td>{dealer.publicId}</td>
                <td>
                  {!!dealer.token && (
                    <InputGroup className="mb-3">
                      <FormControl
                        id={`dealers-tr${i}`}
                        value={`${window.location.origin}#/reset-password/${dealer.token}`}
                      />
                      <InputGroup.Text onClick={() => copy(`dealers-tr${i}`)}>
                        <div
                          onClick={() => copy(`dealers-tr${i}`)}
                          style={{
                            cursor: "pointer",
                            background: "black",
                            borderRadius: "3px",
                            width: "20px",
                          }}
                        >
                          <CoxIcon name={"copy"} width={20} height={20} />
                        </div>
                      </InputGroup.Text>
                    </InputGroup>
                  )}
                </td>
                <td>{dealer.active ? t("Yes") : t("No")}</td>
              </tr>
            ))}
          </TableList>
        </div>
      )}
    </>
  );
};
