import { ButtonPrimary } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useC4u,
  useSessionContext,
  useZipcodeAddress,
} from "../../../../hooks";
import {
  FeedbackModal,
  FormDealershipMolecule,
  FormPersonalDataMolecule,
  FormResetPassword,
  GroupButtonsMenuMolecule,
} from "../../../molecules";
import { IFormPersonalDataMolecule } from "../../../molecules/profile/form-personal-data/form-personal-data.molecule";
import { EditProfileUsersOrganism } from "../..";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  DealershipUpdateByUserRequest,
  IDealershipUpdateByUserRequest,
  ResetPasswordRequest,
  UpdateDataUserRequest,
} from "../../../../models";
import { IFormResetPassword } from "../../../molecules/profile/form-reset-password/form-reset-password.molecule";
import { IFormDealershipMolecule } from "../../../molecules/profile/form-dealership/form-dealership.molecule";

export const EditProfileOrganism: React.FC = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState<"success" | "error" | "warning">();
  const [showMsg, setShowMsg] = useState<string>();

  const {
    GetUser,
    UpdateDataUser,
    ResetPassword,
    GetDealer,
    UpdateDealershipByUser,
  } = useC4u();

  const { getAddressByZipcode } = useZipcodeAddress();

  const {
    setUserContext,
    setSubmitingContext,
    submitingContext,
    formContext,
    setFormContext,
  } = useSessionContext();

  const [idDealer, setIdDealer] = useState<number>();
  const [userForm, setUserForm] = useState<IFormPersonalDataMolecule>();
  const [dealershipForm, setDealershipForm] =
    useState<IFormDealershipMolecule>();
  const load = async () => {
    const user = await GetUser();

    setUserContext(user);

    setUserForm({
      cpf: user.cpf,
      name: user.name,
      email: user.email,
      cellPhone: user.cellPhone,
    });

    if (user?.dealershipId && user?.isMaster) {
      setIdDealer(user.dealershipId);
      const dealer = await GetDealer(user.dealershipId);

      const dealership = {
        ...dealer,
        ...dealer.address,
      } as any;

      setDealershipForm(dealership);
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  const spinner = () => {
    return (
      <div className={"h-100 d-flex align-items-center justify-content-center"}>
        <Spinner animation="border" />
      </div>
    );
  };

  const handleSubmitPersonal = async (
    values: IFormPersonalDataMolecule,
    errors: any
  ) => {
    setSubmitingContext(true);
    UpdateDataUser(
      new UpdateDataUserRequest({
        ...values,
      })
    )
      .then((response) => {
        setUserForm({
          cpf: response.cpf,
          name: response.name,
          email: response.email,
          cellPhone: response.cellPhone,
        });

        setShow("success");
        setShowMsg(t("TitleSaveSuccess"));
      })
      .catch((e) => {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          errors(errorFormik);
        } else {
          setShow("error");
          setShowMsg(t("api_error"));
          console.log(e);
        }
      })
      .finally(() => {
        setSubmitingContext(false);
      });
  };

  const handleSubmitResetPassword = async (
    values: IFormResetPassword,
    errors: any
  ) => {
    setSubmitingContext(true);

    ResetPassword(
      new ResetPasswordRequest({
        ...values,
      })
    )
      .then((response) => {
        if (response.success) {
          setShow("success");
          setShowMsg(t("TitlePasswordSavedSuccess"));
        } else {
          throw new Error("Reset password failed");
        }
      })
      .catch((e) => {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          errors(errorFormik);
        } else {
          setShow("error");
          setShowMsg(
            `${t("change_password_problema")}\n${t("field_invalid_pwd_weak")}`
          );
          console.log(e);
        }
      })
      .finally(() => {
        setSubmitingContext(false);
      });
  };
  const handleSubmitDealership = async (
    values: IFormDealershipMolecule,
    errors: any
  ) => {
    const request = {
      email: values.email,
      id: idDealer,
      legalName: values.legalName,
      name: values.name,
      primaryPhone: values.primaryPhone,
      secondaryPhone: values.secondaryPhone,
      address: {
        city: values.city,
        neighborhood: values.neighborhood,
        number: values.number,
        state: values.state,
        street: values.street,
        zipCode: values.zipCode,
        complement: values.complement,
      },
    } as IDealershipUpdateByUserRequest;

    setSubmitingContext(true);
    UpdateDealershipByUser(new DealershipUpdateByUserRequest(request))
      .then((response) => {
        const dealership = {
          ...response,
          ...response.address,
        } as any;

        setDealershipForm(dealership);

        setShow("success");
        setShowMsg(t("TitleSaveSuccess"));
      })
      .catch((e) => {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          errors(errorFormik);
        } else {
          setShow("error");
          setShowMsg(t("api_error"));
          console.log(e);
        }
      })
      .finally(() => {
        setSubmitingContext(false);
      });
  };

  return (
    <>
      <FeedbackModal
        type={show ?? "warning"}
        show={show !== undefined}
        onHide={() => setShow(undefined)}
        content={showMsg}
      />
      <Row className={"my-5"}>
        <Col md={"auto"}>
          <h1>{t("Change register data")}</h1>
        </Col>
      </Row>
      <Row>
        {userForm && (
          <Col md={4}>
            <GroupButtonsMenuMolecule
              active={formContext}
              personalClick={() => setFormContext("personal")}
              passwordClick={() => setFormContext("password")}
              sellersClick={() => setFormContext("sellers")}
              storeClick={() => setFormContext("store")}
            />
          </Col>
        )}
        <Col md={userForm ? 8 : 12}>
          {formContext === "personal" && (
            <>
              {userForm ? (
                <FormPersonalDataMolecule
                  formId={"personal"}
                  onSubmit={handleSubmitPersonal}
                  values={userForm}
                />
              ) : (
                spinner()
              )}
            </>
          )}
          {formContext === "password" && (
            <>
              {userForm ? (
                <FormResetPassword
                  formId={"password"}
                  onSubmit={handleSubmitResetPassword}
                />
              ) : (
                spinner()
              )}
            </>
          )}
          {formContext === "store" && (
            <>
              {dealershipForm && !!idDealer ? (
                <FormDealershipMolecule
                  formId={"store"}
                  onSubmit={handleSubmitDealership}
                  values={dealershipForm}
                  getAddress={getAddressByZipcode}
                />
              ) : (
                <>{t("NoPermissionAccessArea")}</>
              )}
            </>
          )}
          {formContext === "sellers" && (
            <EditProfileUsersOrganism formId={"sellers"} />
          )}
        </Col>
      </Row>
      <hr className={"mt-4"} />
      <Row>
        <Col className={"text-right"}>
          <ButtonPrimary
            type={"submit"}
            form={formContext.toString()}
            sizex={"md"}
            loading={submitingContext}
          >
            {t("Save")}
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  );
};
