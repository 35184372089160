import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { renderViewHelper } from "../../../../../helper/render-view.helper";
import { IInspectionTransactionData } from "../../../../../models";

interface IProps {
  transactionDetails?: IInspectionTransactionData;
}

export const InspectionTransactionDetailsMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  const renderViewTransaction = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.transactionDetails, key, label, func);

  return !props.transactionDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <Form.Row>
      {renderViewTransaction("protocol", t("TransactionProtocol"))}
      {renderViewTransaction("status", t("TransactionStatus"))}
      {renderViewTransaction("dateTime", t("TransactionDateTime"))}
    </Form.Row>
  );
};
