import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { paths } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import { PermissionEnum } from "c4u-web-components";

interface IProps {
  isLoading: boolean;
  isAuthenticated: boolean;
  permission: PermissionEnum | PermissionEnum[];
}
export const BackofficeBasePage: React.FC<IProps> = (props) => {
  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { isLoading, isAuthenticated } = props;

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      if (!userAuth0Context && paths.backoffice() !== location.pathname) {
        history.replace({
          pathname: paths.backoffice(),
          state: { referrer: location.pathname },
        });
      }
    }
    // eslint-disable-next-line
  }, [userAuth0Context, isLoading, isAuthenticated]);

  const stepsAuth = () => {
    if (isLoading) return "Login process...";
    if (!isLoading && !isAuthenticated) return "Not logged...";
    if (!isLoading && isAuthenticated && !userAuth0Context)
      return "Claims needed...";
    if (
      !isLoading &&
      isAuthenticated &&
      !!userAuth0Context &&
      !validateUserAuth0Permission(userAuth0Context, props.permission)
    )
      return t("NoPermissionAccessArea");
    if (
      !!userAuth0Context &&
      validateUserAuth0Permission(userAuth0Context, props.permission)
    )
      return props.children;
  };

  return <>{stepsAuth()}</>;
};
