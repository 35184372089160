import React from "react";
import { useTranslation } from "react-i18next";
import { KbbInsuranceListOrganism } from "../../../../organisms";

export const KbbInsuranceListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleInsuranceListPage")}</h3>
      <KbbInsuranceListOrganism />
    </>
  );
};
