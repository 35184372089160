import { ButtonPrimary, DateHelper } from "c4u-web-components";
import { addDays } from "date-fns/esm";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useInsurance } from "../../../../hooks";
import {
  IDtInformation,
  PostDtInformationSyncRequest,
} from "../../../../models";
import {
  DetailsPageSection,
  DtMockSyncCreditAppMolecule,
  DtMockSyncCustomerMolecule,
  DtMockSyncDealershipMolecule,
  DtMockSyncVehicleMolecule,
  FeedbackModal,
} from "../../../molecules";
import { WrapperDetails } from "../../shared/shared.style";
import { DtMockSyncValidationSchema } from "./dt-mock-sync.organism.validations";

export const DtMockSyncOrganism: React.FC = () => {
  const { t } = useTranslation();

  const { postDtInformation } = useInsurance();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const initialValues: IDtInformation = {
    creditApp: {
      publicPersonId: "",
      encodeLenderCode: "",
      step: "",
      expiryDate: DateHelper.dateHourCustomFormat(
        addDays(new Date(), 10),
        "yyyy-MM-dd"
      ),
    },
    customer: {
      completeName: "Adenor Silva Teste",
      cpf: "30030030030",
      typeId: "RG",
      idNumber: "21382657",
      expeditorCode: "SSP",
      expeditionDate: DateHelper.dateHourCustomFormat(new Date(), "yyyy-MM-dd"),
      birthDate: "1980-10-20",
      gender: "M",
      email: "adenor@teste.com",
      maritalStatus: "single",
      revenue: 30000.0,
      professionalActivity: "Analista",
      profession: "Analista de Mercado",
      countryOfBorn: "Brasileiro",
      phonenumber: "(11) 25522409",
      cellphonenumber: "(11) 999999999",
      address: {
        zipCode: "01122000",
        address: "Rua Ribeiro de Lima",
        homeNro: "13",
        district: "Bom Retiro",
        complement: "Apto 123",
        city: "São Paulo",
        state: "SP",
      },
    },
    dealership: {
      businessNumber: "02215837000183",
      legalName: "Loja CPV",
      name: "Loja CPV",
    },
    vehicle: {
      manufactureYear: "2019",
      modelYear: "2020",
      licensePlate: "KOO5870",
      vinNmber: "1gX11jyfAlKX86044",
      renavam: "93279725400",
      color: "Preto",
      price: 80000.0,
      isNew: "U",
      molicarId: "01503188-3",
      licensePlateUF: "SP",
    },
  };

  const formik = useFormik<IDtInformation>({
    initialValues: initialValues ?? ({} as IDtInformation),
    onSubmit: async (values, { setErrors }) => {
      try {
        await postDtInformation(
          new PostDtInformationSyncRequest({ data: values })
        );
        setShowSuccessModal(true);
      } catch (e) {
        console.log(e);
        setShowErrorModal(true);
      }
    },
    validationSchema: DtMockSyncValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <>
      <WrapperDetails>
        <Form onSubmit={formik.handleSubmit}>
          <div className="text-right mb-3">
            <ButtonPrimary
              sizex="md"
              type="submit"
              loading={formik.isSubmitting}
            >
              {t("Send")}
            </ButtonPrimary>
          </div>

          <DetailsPageSection
            title={t("DtMockSyncInformarionCreditApp")}
            classNames={["insurance-credit-app"]}
          >
            <DtMockSyncCreditAppMolecule formik={formik} />
          </DetailsPageSection>

          <DetailsPageSection
            title={t("DtMockSyncInformarionVehicle")}
            classNames={["insurance-cred"]}
          >
            <DtMockSyncVehicleMolecule formik={formik} />
          </DetailsPageSection>

          <DetailsPageSection
            title={t("InsuranceCustomerDetails")}
            classNames={["insurance-cred"]}
          >
            <DtMockSyncCustomerMolecule formik={formik} />
          </DetailsPageSection>

          <DetailsPageSection
            title={t("DealershipDetails")}
            classNames={["insurance-cred"]}
          >
            <DtMockSyncDealershipMolecule formik={formik} />
          </DetailsPageSection>
        </Form>
      </WrapperDetails>

      <FeedbackModal
        type="success"
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        content={t("Sync data sent successfully")}
      />

      <FeedbackModal
        type="error"
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        content={t("Error sending sync data")}
      />
    </>
  );
};
