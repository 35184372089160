import { TypesHelper } from "c4u-web-components";

export interface IUploadDocumentRequest {
  plate: string;
  fipeId: string;
  ituranId: number;
  kbbId: number;
  molicarId: string;
  brandName: string;
  modelName: string;
  versionName: string;
  modelYear: number;
  cc: number;

  observation: string;
  decoderReviewStatus: number;
  file: Blob | null;
}

export class UploadDocumentRequest {
  plate: string | null;
  fipeId: string | null;
  ituranId: number | null;
  kbbId: number | null;
  molicarId: string | null;
  brandName: string | null;
  modelName: string | null;
  versionName: string | null;
  modelYear: number | null;
  cc: number | null;
  observation: string | null;
  decoderReviewStatus: number | null;
  file: Blob | null;

  constructor({
    plate,
    fipeId,
    ituranId,
    kbbId,
    molicarId,
    brandName,
    modelName,
    versionName,
    modelYear,
    cc,
    observation,
    decoderReviewStatus,
    file,
  }: IUploadDocumentRequest) {
    this.plate = TypesHelper.toStringNull(plate.replace("-", ""));
    this.fipeId = TypesHelper.toStringNull(fipeId);
    this.ituranId = TypesHelper.toNumberNull(ituranId);
    this.kbbId = TypesHelper.toNumberNull(kbbId);
    this.molicarId = TypesHelper.toStringNull(molicarId);
    this.brandName = TypesHelper.toStringNull(brandName);
    this.modelName = TypesHelper.toStringNull(modelName);
    this.versionName = TypesHelper.toStringNull(versionName);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
    this.cc = TypesHelper.toNumberNull(cc);
    this.observation = TypesHelper.toStringNull(observation);
    this.decoderReviewStatus = TypesHelper.toNumberNull(decoderReviewStatus);
    this.file = file;
  }
}
