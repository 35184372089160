import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const GoDealertrackPage = () => {
  const { user } = useAuth0();

  useEffect(() => {
    window.location.href = "https://www.dealertrackbrasil.com.br/#/customers"
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <span> Going DT { user?.name } </span>;
};
