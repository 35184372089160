import { NumberHelper } from "c4u-web-components";

export interface IAdBidding {
  adId?: number;
  minimumValue?: number;
  minimumStepValue?: number;
  durationHours?: number;
}

export class AdBidding {
  adId: number | null;
  minimumValue: number | null;
  minimumStepValue: number | null;
  durationHours: number | null;
  constructor({
    adId,
    minimumValue,
    minimumStepValue,
    durationHours,
  }: IAdBidding) {
    this.adId = NumberHelper.toNumber(adId);
    this.minimumValue = NumberHelper.toNumber(minimumValue);
    this.minimumStepValue = NumberHelper.toNumber(minimumStepValue);
    this.durationHours = NumberHelper.toNumber(durationHours);
  }
}
