import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-liberty.organism.style";

export const AboutLiberty = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("Liberty")}</Title>
      <Content>
        <Trans>
          {t("TheLibertySeguros")}
          {t("TheInsurer")}
          <List>
            <ListItem>{t("ExclusiveAutoInsurance")}</ListItem>
          </List>
          <List>
            <ListItem>{t("UnlimitedMileageWrecker")}</ListItem>
            <ListItem>{t("ServiceThattakes")}</ListItem>
            <ListItem>{t("DaysOfReserveVehicle")}</ListItem>
            <ListItem>{t("GuaranteesTheCoverage")}</ListItem>
            <ListItem>{t("AlsoGuarantees")}</ListItem>
          </List>
          <List>
            <ListItem strongTextColor='#F3BC2A'>
              {t("LibertyAutoProfile")}
            </ListItem>
          </List>
          <List>
            <ListItem>{t("LibertyAutoconscious")}</ListItem>
            <ListItem>{t("DifferentiatedOption")}</ListItem>
            <ListItem>{t("GuaranteesTheFacility")}</ListItem>
            <ListItem>{t("ThePayment")}</ListItem>
          </List>
          <HighlightText>{t("Commissioning")}:</HighlightText>
          <List>
            <ListItem>{t("LibertyEveryInsuranceSale")}</ListItem>
            <ListItem>{t("BesidesSurprising")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem textColor='#00204F' italic semiBold>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
