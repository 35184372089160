import {
  ActiveItemTypeConstant,
  IAuth0User,
  ICoxUser,
  PermissionEnum,
} from "c4u-web-components";
import React, { createContext, useState } from "react";
import {
  IFoundVehicles,
  IGetEmailTemplateViewModel,
  IUserAuth0,
  RouterParam,
} from "../models";
import { IDocumentModel } from "../models/shared/document.model";
import { ITermsOfUseResponse } from "../models/apis/c4u/terms-of-use.request";

const SessionContext = createContext({} as any);

declare const window: Window &
  typeof globalThis & {
    registration: any[];
  };

const SessionProvider = (props: any) => {
  const validateUserAuth0Permission = (
    user: IUserAuth0,
    permission: PermissionEnum | PermissionEnum[]
  ): boolean => {
    const permissions =
      typeof permission === "string"
        ? [permission.toString()]
        : (permission as PermissionEnum[]).map((m) => m.toString());
    const valid = user?.permissions.find((f) => permissions.includes(f));

    return !!valid;
  };

  const loadUserAuth0Context = (user: IAuth0User) => {
    if (!user) return;

    const permissions = Object.keys(user.appMetadata)
      .filter((f) => /app:.+$/g.test(f) && user.appMetadata[f] === "True")
      .map((m) => m.match(/app:.+$/)?.toString());

    const userAuth0 = {
      email: user.email,
      id: user.userId,
      name: user.name,
      permissions: permissions,
    } as IUserAuth0;
    setUserAuth0Context(userAuth0);
  };

  const handleEventGTM = (eventData: object) => {
    try {
      console.log(eventData);
      window.registration.push(eventData);
    } catch (e) {
      if (process.env.REACT_APP_GTMID)
        console.log("Error when trying to send data to GTM", eventData);
    }
  };

  const [actualPageContext, setActualPageContext] = useState<
    ActiveItemTypeConstant | string
  >("null");
  const [routerParamContext, setRouterParamContext] = useState<RouterParam[]>(
    []
  );
  const [userContext, setUserContext] = useState<ICoxUser>();
  const [userAuth0Context, setUserAuth0Context] = useState<IUserAuth0>();
  const [submitingContext, setSubmitingContext] = useState<boolean>();
  const [formContext, setFormContext] = useState<
    "personal" | "password" | "store" | "sellers"
  >("personal");

  const addRouterParamContext = (keyParam: string, value: string) => {
    const key = keyParam.startsWith(":") ? keyParam : `:${keyParam}`;
    const itemsRouterParamContext = routerParamContext.filter(
      (f) => f.param.trim() !== key.trim()
    );
    itemsRouterParamContext.push({ param: key, value: value } as RouterParam);
    setRouterParamContext(itemsRouterParamContext);
  };

  const [emailTemplatesContext, setEmailTemplatesContext] =
    useState<IGetEmailTemplateViewModel[]>();

  const [auth0UserContext, setAuth0UserContext] = useState<IAuth0User>();
  const [vehicleContext, setVehicleContext] = useState<IFoundVehicles>();

  const [documentContext, setDocumentContext] = useState<IDocumentModel>();
  const [termsOfUse, setTermsOfUse] = useState<ITermsOfUseResponse>();

  return (
    <SessionContext.Provider
      value={{
        routerParamContext,
        addRouterParamContext,
        userContext,
        setUserContext,
        submitingContext,
        setSubmitingContext,
        formContext,
        setFormContext,
        userAuth0Context,
        loadUserAuth0Context,
        handleEventGTM,
        validateUserAuth0Permission,
        actualPageContext,
        setActualPageContext,
        emailTemplatesContext,
        setEmailTemplatesContext,
        auth0UserContext,
        setAuth0UserContext,
        vehicleContext,
        setVehicleContext,
        documentContext,
        setDocumentContext,
        termsOfUse,
        setTermsOfUse,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
