import styled from "styled-components";

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TrackerItemDescription = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.Dark};
`;

export const TrackerItemValue = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const ComingSoonDiv = styled.div`
  margin-left: 10px;
  padding: 4px;

  color: ${({ theme }) => theme.colors.BlueNavy};
  background: ${({ theme }) => theme.colors.ManheimYellow};

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;

  text-align: center;
`;
