import React from "react";

const LogoNewManheim = () => (
  <svg
    width="84"
    height="27"
    viewBox="0 0 84 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M34.147 0.961838V26.0398" stroke="#BCBEC0" strokeWidth="0.5" />
      <path
        d="M0.893555 13.6414C0.893555 20.6603 6.65531 26.351 13.7597 26.351C20.8641 26.351 26.6242 20.6619 26.6242 13.6414C26.6242 6.62079 20.8658 0.928391 13.7614 0.928391C6.65531 0.928391 0.893555 6.62079 0.893555 13.6414Z"
        fill="#6E420B"
      />
      <path
        d="M0.609375 13.357C0.609375 20.3759 6.36774 26.0666 13.4721 26.0666C20.5766 26.0666 26.3417 20.3759 26.3417 13.357C26.3417 6.3381 20.5783 0.647369 13.4721 0.647369C6.36943 0.647369 0.609375 6.3381 0.609375 13.357Z"
        fill="#FFFDE9"
      />
      <path
        d="M0.800781 13.4975C0.800781 20.5181 6.56084 26.2088 13.6652 26.2088C20.7714 26.2088 26.5314 20.5181 26.5314 13.4975C26.5314 6.48029 20.773 0.787888 13.6652 0.787888C6.55914 0.78956 0.800781 6.48196 0.800781 13.4975Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M1.37793 13.4975C1.37793 20.2036 6.87894 25.635 13.665 25.635C20.4528 25.635 25.9538 20.2036 25.9538 13.4975C25.9538 6.79644 20.4528 1.36165 13.665 1.36165C6.87894 1.36165 1.37793 6.79644 1.37793 13.4975Z"
        fill="url(#paint1_linear)"
        stroke="#FED301"
        strokeWidth="0.1133"
      />
      <path
        d="M13.6499 2.50076C7.50045 2.50076 2.51416 7.42536 2.51416 13.4975C2.51416 19.5763 7.50045 24.5009 13.6499 24.5009C19.7994 24.5009 24.7823 19.5746 24.7823 13.4975C24.7823 7.42536 19.7994 2.50076 13.6499 2.50076ZM13.6499 23.2647C8.19126 23.2647 3.7637 18.8938 3.7637 13.4975C3.7637 8.10785 8.19126 3.73526 13.6499 3.73526C18.7141 3.73526 23.5345 8.30356 23.5345 13.4975C23.5362 18.8955 19.1086 23.2647 13.6499 23.2647Z"
        fill="black"
        stroke="#F1E9B6"
        strokeWidth="0.1823"
      />
      <path
        d="M13.6659 0.010788C6.12297 0.010788 0.0107422 6.04945 0.0107422 13.4999C0.0107422 20.9504 6.12297 26.9924 13.6659 26.9924C21.2105 26.9924 27.3211 20.9504 27.3211 13.4999C27.3194 6.04945 21.2088 0.010788 13.6659 0.010788ZM13.6659 25.3715C7.0305 25.3715 1.65139 20.0555 1.65139 13.4999C1.65139 6.94605 7.0305 1.62835 13.6659 1.62835C20.3013 1.62835 25.6821 6.94605 25.6821 13.4999C25.6821 20.0555 20.3013 25.3715 13.6659 25.3715Z"
        fill="url(#paint2_linear)"
        stroke="#9E6606"
        strokeWidth="0.1179"
      />
      <path
        d="M20.7613 6.71449C22.5272 8.46085 23.8851 10.7877 23.8851 13.454C23.8851 15.182 22.8083 17.0321 22.0074 18.4372C22.8083 17.0321 23.6548 15.1201 23.6548 13.3938C23.6548 10.7308 22.2935 8.40565 20.5293 6.65594L20.7613 6.71449Z"
        fill="#FFFCDB"
      />
      <path
        d="M19.3047 21.4063C19.3047 21.4063 19.005 21.5803 19.0101 21.6372C17.4507 22.6676 15.7863 23.0674 13.7715 23.0674C16.4534 23.3099 18.2803 22.3648 18.7459 22.0202C19.2132 21.6756 19.3047 21.4063 19.3047 21.4063Z"
        fill="#FFFCDB"
      />
      <path
        d="M13.6466 22.4484C8.58242 22.4484 4.47656 18.3953 4.47656 13.3921C4.47656 8.39224 8.58242 4.33914 13.6466 4.33914C18.2316 4.33914 22.8979 8.62642 22.8979 13.3921C22.8979 19.1732 17.6763 22.4484 13.6466 22.4484Z"
        fill="url(#paint3_radial)"
      />
      <path
        d="M22.515 11.1055C22.2018 9.92785 21.66 8.84725 20.9303 7.90548C19.8619 6.52044 18.399 5.45155 16.7059 4.85772C15.7493 4.52317 14.7164 4.34084 13.6464 4.34084C12.9877 4.34084 12.3494 4.40942 11.7331 4.53488C10.277 4.84099 8.95469 5.485 7.84229 6.38327C5.79021 8.04098 4.47803 10.5652 4.47803 13.3921C4.47803 14.5698 4.70491 15.6938 5.11973 16.7226C4.92502 15.9916 4.82173 15.2238 4.82173 14.4309C4.82173 11.895 5.87656 9.60501 7.57647 7.96236C7.1735 10.4364 6.56905 14.5831 6.25074 18.7466C7.07869 19.864 8.16738 20.7874 9.4203 21.4331V21.4682C9.53543 19.6031 9.80464 15.6821 10.316 10.699C10.316 10.699 11.7484 17.6577 13.2299 22.2126H15.2599C17.1546 16.7092 18.4075 10.8178 18.4075 10.8178C18.8494 15.0883 18.9391 18.6513 19.0746 20.7372C20.3173 19.7771 21.8276 17.7982 22.4372 16.3395C22.1409 13.27 21.892 11.4735 21.5669 9.41264C22.0037 10.06 22.1798 10.3611 22.515 11.1055ZM14.3456 13.6698C14.3456 13.6698 13.2739 10.3527 12.0785 5.59373C12.7219 5.45322 13.2993 5.37795 13.9867 5.37795C14.8536 5.37795 15.6951 5.49838 16.4908 5.72253C15.3073 10.4096 14.3456 13.6698 14.3456 13.6698Z"
        fill="#003265"
      />
      <path
        d="M13.6465 1.46703C6.91794 1.46703 1.46265 6.85498 1.46265 13.4992C1.46265 20.1484 6.91794 25.5347 13.6465 25.5347C20.3699 25.5347 25.8269 20.1484 25.8269 13.4992C25.8269 7.08917 19.8942 1.46703 13.6465 1.46703ZM13.6465 22.4484C8.56536 22.4484 4.47643 18.4121 4.47643 13.3921C4.47643 8.37385 8.56536 4.33915 13.6465 4.33915C18.2467 4.33915 22.8639 8.32199 22.8978 13.3921C22.9418 19.1899 17.6914 22.4484 13.6465 22.4484Z"
        fill="url(#paint4_linear)"
        stroke="#65501B"
        strokeWidth="0.2143"
      />
      <path
        d="M14.9638 21.8563C16.4199 17.6142 18.3298 10.6321 18.3298 10.6321C19.1052 17.6727 19.3626 22.5923 19.3626 22.5923L22.0073 18.4388C22.0377 17.8316 22.1088 17.3097 22.1088 17.3097C21.7956 14.4041 21.374 10.2256 21.0388 8.25674C21.0388 8.25674 20.5596 7.01054 20.4309 6.62747C20.1888 5.89815 20.5935 5.51174 20.5935 5.51174L16.9922 3.47098C15.5344 9.242 14.1562 13.4005 14.1562 13.4005C14.1562 13.4005 12.9033 9.75888 11.5572 4.63689L11.0374 4.10997L7.37515 5.42476C7.37515 5.42476 5.99863 12.2111 5.4873 18.9975L8.33008 21.4398L8.91422 21.1905C9.03951 19.2852 9.32904 15.4128 9.86745 10.515C9.86745 10.515 11.3964 17.5857 12.8541 21.8262L14.9638 21.8563Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M13.7735 1.80324C7.29386 1.80324 2.04175 6.99046 2.04175 13.3938C2.04175 19.7988 7.29386 24.986 13.7735 24.986C20.2565 24.986 25.5086 19.7988 25.5086 13.3938C25.5086 7.22967 19.7774 1.80324 13.7735 1.80324ZM23.6547 13.3938C23.6547 15.1217 22.8081 17.032 22.0072 18.4372C21.9988 18.3686 21.9175 18.6897 21.9124 18.6228C21.5247 14.1499 20.7391 8.74853 20.3429 6.49198C20.3243 6.36987 20.5478 6.76464 20.5291 6.65591C22.2951 8.40562 23.6547 10.7308 23.6547 13.3938ZM16.5079 4.10663L16.7636 4.19361C15.1653 9.34571 13.8395 13.9341 13.8395 13.9341C13.8395 13.9341 12.4325 9.50295 11.0374 4.10663C11.9043 3.85739 12.822 3.72357 13.7735 3.72357C14.725 3.72357 15.6393 3.85739 16.5079 4.10663ZM6.83841 6.56726L7.111 6.37823C6.7165 8.67828 5.83269 14.0378 5.44326 18.5977C4.64241 17.1926 3.98208 15.1201 3.98208 13.3938C3.98208 10.7308 5.07416 8.31696 6.83841 6.56726ZM8.33006 21.4347C8.43673 19.7269 8.77367 15.1268 9.35441 9.83917C9.35441 9.83917 11.5673 17.2595 13.0268 21.5H14.7217C16.5841 15.7306 18.2535 9.93954 18.2535 9.93954C18.7903 14.8106 18.8309 18.9005 18.9562 20.8141C18.9613 20.9128 19.305 21.4063 19.305 21.4063C19.305 21.4063 19.0053 21.5803 19.0104 21.6371C17.451 22.6676 15.7866 23.0673 13.7718 23.0673C11.7587 23.0673 9.88606 22.4652 8.33006 21.4347Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M5.44346 18.5977C5.93616 13.648 6.83522 8.24169 7.1112 6.37823L6.66082 7.56255C6.26971 9.79401 5.68896 13.4356 5.32324 17.2545L5.44346 18.5977Z"
        fill="#FFE694"
      />
      <path
        d="M9.35254 9.83749C9.35254 9.83749 11.5655 17.2579 13.025 21.4983L12.8539 21.8211C11.8008 18.7583 10.4209 14.1315 9.73349 11.8013C9.5845 11.2108 9.47783 10.6605 9.47783 10.6605L9.35254 9.83749Z"
        fill="#F7EAB1"
      />
      <path
        d="M13.8394 13.9341C13.8394 13.9341 15.2988 9.49962 16.7634 4.1953L16.3435 4.74396C15.331 8.598 14.3693 11.5989 13.9697 12.8117L13.8394 13.9341Z"
        fill="#FFE694"
      />
      <path
        d="M18.2518 9.93954C18.8342 15.2322 19.1982 19.6984 19.3049 21.4063L18.9561 20.8142C18.8291 18.9306 18.5718 15.5951 18.1282 11.435L18.2518 9.93954Z"
        fill="#FFE694"
      />
      <path
        d="M16.7638 4.19361C16.1272 3.89586 15.0791 3.55629 13.6467 3.60313C12.6952 3.63324 12.0433 3.70851 11.0376 4.10496C11.9045 3.85572 12.8222 3.72189 13.7737 3.72189C14.7253 3.72357 15.8935 3.94604 16.7638 4.19361Z"
        fill="#FFFCD7"
      />
      <path
        d="M5.66202 18.0875L5.4436 18.5994C5.93631 13.6497 6.83536 8.24337 7.11134 6.37991C7.11134 6.37824 6.7016 10.4046 5.66202 18.0875Z"
        fill="#FFFCDB"
      />
      <path
        d="M9.78101 11.3882L12.9167 21.1972C12.2208 17.4686 9.78101 11.3882 9.78101 11.3882Z"
        fill="#FFFCDB"
      />
      <path
        d="M18.4565 11.4116C18.8409 15.3609 19.2693 20.8744 19.2693 20.8744C19.4995 16.112 18.4565 11.4116 18.4565 11.4116Z"
        fill="#FFFCDB"
      />
      <path
        d="M2.39535 10.3209C2.39535 10.3209 2.44614 10.3661 2.39535 10.3209C3.63134 7.37187 4.5541 6.22771 6.29973 4.55495L5.81549 4.93634C3.28425 6.95536 2.50371 9.63511 2.39535 10.3209C2.28529 11.0051 2.39535 10.3209 2.39535 10.3209Z"
        fill="#FFFCDB"
      />
      <path
        d="M16.7448 4.55827C16.396 7.42371 13.8394 13.9341 13.8394 13.9341L16.7448 4.55827Z"
        fill="#FFFCDB"
      />
      <path
        d="M8.38959 20.8995C8.62325 17.3432 9.25987 10.8612 9.25987 10.8612C8.21689 17.2278 8.38959 20.8995 8.38959 20.8995Z"
        fill="#B38F19"
      />
      <path
        d="M21.8275 18.4372C21.2688 11.5236 20.7456 9.058 20.7456 9.058C20.7456 9.058 21.3264 15.8829 21.4432 16.6272C21.56 17.375 21.8275 18.4372 21.8275 18.4372Z"
        fill="#B38F19"
      />
      <path
        d="M14.7202 21.4983C15.472 19.2969 17.9084 11.4367 17.9084 11.4367C15.7632 17.3448 14.7202 21.4983 14.7202 21.4983Z"
        fill="#B38F19"
      />
      <path
        d="M13.6668 0.349625C6.35245 0.349625 0.421387 6.24777 0.421387 13.5226C0.421387 20.7974 6.35076 26.6939 13.6668 26.6939C20.9829 26.6939 26.9156 20.7958 26.9156 13.5226C26.9139 6.24945 20.9812 0.349625 13.6668 0.349625ZM13.6668 25.8425C6.82484 25.8425 1.27642 20.3274 1.27642 13.5226C1.27642 6.71615 6.82484 1.20106 13.6668 1.20106C20.5105 1.20106 26.0572 6.71615 26.0572 13.5226C26.0572 20.3274 20.5105 25.8425 13.6668 25.8425Z"
        fill="url(#paint7_linear)"
        stroke="#FED301"
        strokeWidth="0.1124"
      />
      <path
        d="M12.9844 0.255934C9.38813 0.480084 6.09159 2.08593 3.70765 4.78242C1.32202 7.47723 0.162222 10.8228 0.38741 14.4108C0.389103 14.4493 0.444976 14.8206 0.488998 14.8173C0.531326 14.814 0.512702 14.558 0.509316 14.5145C0.287514 10.97 1.46425 7.54916 3.82279 4.88446C6.17964 2.21975 9.43046 0.540303 12.9877 0.319499C13.0301 0.316153 13.2129 0.322845 13.2095 0.281026C13.2079 0.237534 13.0267 0.252589 12.9844 0.255934Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M14.6774 25.799C17.9333 25.5966 20.9115 24.1429 23.0669 21.7091C25.2239 19.2719 26.2703 16.2475 26.0671 13.0024C26.0671 12.9639 26.0146 12.6327 25.9774 12.6343C25.9384 12.636 25.9571 12.8702 25.9588 12.907C26.1602 16.1137 25.0953 19.2066 22.9636 21.6154C20.8319 24.0242 17.8943 25.543 14.6791 25.7438C14.6401 25.7471 14.4725 25.7404 14.4759 25.7789C14.4742 25.819 14.6401 25.8023 14.6774 25.799Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M26.8211 12.7096C26.7753 12.713 26.7703 12.8167 26.7753 12.8568C26.9921 16.3111 25.8323 19.6415 23.5178 22.2377C21.1982 24.8338 18.0286 26.3945 14.5323 26.6086C14.4899 26.6119 14.4273 26.6337 14.429 26.6755C14.4307 26.7156 14.4815 26.719 14.5221 26.7156C18.0608 26.4982 21.2845 24.9609 23.6312 22.3347C25.9779 19.7101 27.0886 16.3094 26.8702 12.815C26.8668 12.7732 26.8651 12.7046 26.8211 12.7096Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M0.363972 13.5845C0.401221 17.1474 1.85393 20.4829 4.4529 22.9753C7.05187 25.4711 10.3738 26.7892 14.0107 26.7574C14.053 26.7558 14.4289 26.719 14.4289 26.6771C14.4289 26.6353 14.1664 26.6403 14.1258 26.6403C10.5296 26.6738 7.13314 25.3323 4.56126 22.8683C1.99277 20.401 0.465561 17.1073 0.428312 13.5895C0.428312 13.546 0.440164 13.3637 0.397835 13.3637C0.355507 13.3653 0.363972 13.546 0.363972 13.5845Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M26.0726 13.5226C26.1251 10.7157 25.1786 7.99411 23.3551 5.7476C21.2726 3.18493 18.3959 1.59414 15.0689 1.18598C15.0283 1.18264 14.6829 1.17093 14.6795 1.20773C14.6761 1.24453 14.9131 1.27129 14.9521 1.27631C18.2435 1.68112 21.1862 3.29868 23.2451 5.83123C25.0737 8.08109 26.0709 10.8194 26.0083 13.6313C26.0015 13.9876 26.0675 13.9157 26.0726 13.5226Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M1.37832 13.4975C1.23949 16.7176 2.3468 19.6666 4.57158 22.0436C6.79637 24.419 9.73058 25.7672 13.017 25.9111C13.0559 25.9127 13.3945 25.896 13.3945 25.8575C13.3979 25.8207 13.1643 25.814 13.1236 25.8124C9.87619 25.6702 6.87425 24.3002 4.67486 21.9516C2.47548 19.6031 1.24118 16.6791 1.37832 13.4975C1.38002 13.4607 1.38002 13.4607 1.37832 13.4975Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M13.0166 0.311136C13.0183 0.34961 13.1267 0.357973 13.1656 0.357973C16.667 0.220807 20.0127 1.43858 22.5846 3.78713C25.1581 6.13568 26.6667 9.30221 26.8055 12.7598C26.8089 12.8016 26.8276 12.8669 26.8699 12.8652C26.9122 12.8635 26.9173 12.815 26.9156 12.7715C26.7785 9.27545 25.2919 6.05372 22.6895 3.67673C20.0855 1.30308 16.6687 0.12546 13.1267 0.262626C13.0844 0.264299 13.0149 0.267644 13.0166 0.311136Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M11.7774 25.6785C5.21984 24.6816 0.697467 18.1394 1.70488 11.6591C2.714 5.17888 9.01078 0.324536 15.5683 1.3215C8.8076 0.292754 2.47526 4.91291 1.4289 11.6391C0.38085 18.3636 5.01497 24.6481 11.7774 25.6785Z"
        fill="#9E6606"
      />
      <path
        d="M25.9272 15.3844C26.9752 8.65987 22.3428 2.37364 15.577 1.34489C22.1363 2.34353 26.6654 8.8422 25.6546 15.3208C24.6472 21.7994 18.3436 26.6989 11.7827 25.7019C18.5502 26.7324 24.8791 22.1105 25.9272 15.3844Z"
        fill="#FFFBCA"
      />
      <path
        d="M15.7103 1.33151C12.5069 0.736006 9.25609 1.41682 6.55892 3.25351C3.85836 5.08853 2.08564 7.76494 1.47273 10.9566C1.46596 10.9917 1.43209 11.3262 1.46934 11.3346C1.50659 11.343 1.54892 11.1121 1.55569 11.0753C2.16184 7.92051 3.96672 5.1822 6.63511 3.36726C9.30349 1.55231 12.5306 0.797898 15.6968 1.39006C15.734 1.39675 15.8949 1.44191 15.9017 1.40511C15.9101 1.36664 15.7493 1.3382 15.7103 1.33151Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M40.9739 24.5243V14.8624H44.7513V15.9079H42.1557V19.1514H44.394V20.1969H42.1557V23.4805H44.7513V24.5259H40.9739V24.5243Z"
        fill="#005BA8"
      />
      <path
        d="M49.7881 24.5243L48.5115 20.697L47.2484 24.5243H45.9565L47.8241 19.5027L46.1072 14.8624H47.3991L48.5115 18.3903L49.6374 14.8624H50.9293L49.1583 19.5027L51.0817 24.5243H49.7881Z"
        fill="#005BA8"
      />
      <path
        d="M54.6237 20.4946H53.6078V24.5243H52.426V14.8624H54.6237C56.1069 14.8624 56.945 15.5416 56.945 17.6844C56.945 19.8021 56.0798 20.4946 54.6237 20.4946ZM54.5814 15.9079H53.6061V19.4491H54.5814C55.3789 19.4491 55.7632 18.9741 55.7632 17.6844C55.7632 16.383 55.3789 15.9079 54.5814 15.9079Z"
        fill="#005BA8"
      />
      <path
        d="M62.0436 24.5243L60.6975 20.3725H59.6393V24.5243H58.4575V14.8624H60.6688C62.1519 14.8624 62.9765 15.4997 62.9765 17.6175C62.9765 18.9607 62.6463 19.7486 61.7947 20.1416L63.2914 24.5243H62.0436ZM60.684 15.9079H59.6393V19.3404H60.684C61.5898 19.3404 61.7964 18.6479 61.7964 17.6175C61.7964 16.587 61.5898 15.9079 60.684 15.9079Z"
        fill="#005BA8"
      />
      <path
        d="M64.7881 24.5243V14.8624H68.5655V15.9079H65.9699V19.1514H68.2082V20.1969H65.9699V23.4805H68.5655V24.5259H64.7881V24.5243Z"
        fill="#005BA8"
      />
      <path
        d="M73.88 24.0359C73.5093 24.3888 72.9319 24.6196 72.1497 24.6196C71.3945 24.6196 70.8172 24.4156 70.4328 24.0359C69.9791 23.5876 69.8284 23.0322 69.8284 22.0001H71.0102C71.0102 22.6374 71.0796 22.9904 71.3403 23.2614C71.5046 23.437 71.7806 23.5591 72.1514 23.5591C72.5492 23.5591 72.81 23.437 72.9895 23.2463C73.2502 22.9619 73.3061 22.5956 73.3061 21.9566C73.3061 20.8442 73.1418 20.5047 72.3444 20.1785L71.232 19.7302C70.2296 19.3237 69.8995 18.78 69.8995 17.3147C69.8995 16.4733 70.1467 15.7808 70.6411 15.3342C71.0254 14.9812 71.5469 14.7771 72.1937 14.7771C72.9082 14.7771 73.4432 14.9812 73.814 15.3342C74.3084 15.8092 74.4591 16.4332 74.4591 17.3147H73.2773C73.2773 16.7995 73.2231 16.4465 72.9759 16.1471C72.8117 15.943 72.5509 15.8209 72.1784 15.8209C71.8347 15.8209 71.5875 15.943 71.4233 16.1187C71.1896 16.3763 71.0796 16.7694 71.0796 17.2729C71.0796 18.223 71.2303 18.5074 72.0142 18.8336L73.1131 19.2819C74.2255 19.7302 74.4862 20.4076 74.4862 21.9282C74.4845 22.9368 74.3474 23.5758 73.88 24.0359Z"
        fill="#005BA8"
      />
      <path
        d="M79.8954 24.0359C79.5246 24.3888 78.9473 24.6196 78.165 24.6196C77.4099 24.6196 76.8325 24.4156 76.4482 24.0359C75.9944 23.5876 75.8438 23.0322 75.8438 22.0001H77.0256C77.0256 22.6374 77.095 22.9904 77.3557 23.2614C77.52 23.437 77.7959 23.5591 78.1667 23.5591C78.5646 23.5591 78.8254 23.437 79.0048 23.2463C79.2656 22.9619 79.3215 22.5956 79.3215 21.9566C79.3215 20.8442 79.1572 20.5047 78.3598 20.1785L77.2474 19.7302C76.245 19.3237 75.9149 18.78 75.9149 17.3147C75.9149 16.4733 76.1621 15.7808 76.6565 15.3342C77.0408 14.9812 77.5623 14.7771 78.2091 14.7771C78.9236 14.7771 79.4586 14.9812 79.8294 15.3342C80.3238 15.8092 80.4745 16.4332 80.4745 17.3147H79.2927C79.2927 16.7995 79.2385 16.4465 78.9913 16.1471C78.8271 15.943 78.5663 15.8209 78.1955 15.8209C77.8518 15.8209 77.6046 15.943 77.4404 16.1187C77.2067 16.3763 77.0967 16.7694 77.0967 17.2729C77.0967 18.223 77.2474 18.5074 78.0313 18.8336L79.1301 19.2819C80.2425 19.7302 80.5033 20.4076 80.5033 21.9282C80.4999 22.9368 80.3627 23.5758 79.8954 24.0359Z"
        fill="#005BA8"
      />
      <path
        d="M82.3984 17.0555C82.329 17.1224 82.2206 17.1642 82.0733 17.1642C81.9311 17.1642 81.8227 17.1257 81.7516 17.0555C81.667 16.9718 81.6382 16.8664 81.6382 16.6741H81.86C81.86 16.7928 81.8735 16.8597 81.9226 16.9099C81.9531 16.9434 82.0056 16.9651 82.075 16.9651C82.1495 16.9651 82.1986 16.9417 82.2325 16.9066C82.2816 16.853 82.2917 16.7845 82.2917 16.664C82.2917 16.4549 82.2613 16.3914 82.1123 16.3311L81.904 16.2475C81.7161 16.1706 81.6534 16.0685 81.6534 15.7942C81.6534 15.6369 81.6991 15.5065 81.7923 15.4228C81.8651 15.3559 81.9616 15.3191 82.0835 15.3191C82.2172 15.3191 82.3171 15.3576 82.3883 15.4228C82.4814 15.5115 82.5085 15.6286 82.5085 15.7942H82.2867C82.2867 15.6972 82.2765 15.6319 82.2308 15.5751C82.2003 15.5366 82.1512 15.5148 82.0818 15.5148C82.0174 15.5148 81.9717 15.5383 81.9396 15.57C81.8955 15.6185 81.8752 15.6921 81.8752 15.7858C81.8752 15.9648 81.904 16.0167 82.0496 16.0786L82.2562 16.1622C82.4644 16.2458 82.5135 16.373 82.5135 16.659C82.5118 16.8497 82.4848 16.9701 82.3984 17.0555Z"
        fill="#005BA8"
      />
      <path
        d="M83.7784 17.1474V15.8577L83.4871 16.7058H83.35L83.0638 15.8795V17.1491H82.842V15.3358H83.0503L83.4211 16.3445L83.7902 15.3358H83.9985V17.1474H83.7784Z"
        fill="#005BA8"
      />
      <path
        d="M45.9602 12.0422V5.16212L44.4076 9.68025H43.6795L42.1557 5.27085V12.0422H40.9739V2.38033H42.0863L44.0639 7.75323L46.0279 2.38033H47.1403V12.0422H45.9602Z"
        fill="#005BA8"
      />
      <path
        d="M52.8769 12.0422L52.4096 9.76221H50.1425L49.6752 12.0422H48.4392L50.7199 2.38033H51.8594L54.1265 12.0422H52.8769ZM51.2837 4.2672L50.3626 8.71841H52.1895L51.2837 4.2672Z"
        fill="#005BA8"
      />
      <path
        d="M59.1196 12.0422L56.5917 5.43311V12.0422H55.4099V2.38033H56.5088L59.0078 8.9894V2.38033H60.1896V12.0422H59.1196Z"
        fill="#005BA8"
      />
      <path
        d="M65.3058 12.0422V7.64617H63.1352V12.0422H61.9534V2.38033H63.1352V6.6007H65.3058V2.38033H66.4876V12.0422H65.3058Z"
        fill="#005BA8"
      />
      <path
        d="M68.2454 12.0422V2.38033H72.0228V3.4258H69.4272V6.66928H71.6655V7.71475H69.4272V10.9984H72.0228V12.0438H68.2454V12.0422Z"
        fill="#005BA8"
      />
      <path
        d="M73.5298 12.0422V2.38033H74.7116V12.0422H73.5298Z"
        fill="#005BA8"
      />
      <path
        d="M81.4467 12.0422V5.16212L79.8941 9.68025H79.1661L77.6423 5.27085V12.0422H76.4604V2.38033H77.5728L79.5504 7.75323L81.5145 2.38033H82.6269V12.0422H81.4467Z"
        fill="#005BA8"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.20072"
        y1="4.96204"
        x2="22.5776"
        y2="21.9453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEEA2" />
        <stop offset="0.3539" stopColor="#EFB733" />
        <stop offset="0.5033" stopColor="#F0BA2F" />
        <stop offset="0.6302" stopColor="#F5C322" />
        <stop offset="0.7303" stopColor="#FED301" />
        <stop offset="1" stopColor="#93540B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="5.09557"
        y1="0.705275"
        x2="23.9058"
        y2="29.4749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB962F" />
        <stop offset="0.0955" stopColor="#F2DF8A" />
        <stop offset="0.4663" stopColor="#EDAD1F" />
        <stop offset="0.7416" stopColor="#F6C76D" />
        <stop offset="1" stopColor="#CE8A2D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="17.2955"
        y1="0.982627"
        x2="10.219"
        y2="25.9811"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005618" stopColor="#916E18" />
        <stop offset="0.0402" stopColor="#AE8E30" />
        <stop offset="0.0801" stopColor="#C9AE4A" />
        <stop offset="0.1243" stopColor="#E0C962" />
        <stop offset="0.1747" stopColor="#F2DF73" />
        <stop offset="0.2365" stopColor="#FFEE7E" />
        <stop offset="0.3427" stopColor="#FFF682" />
        <stop offset="0.3883" stopColor="#FEEB7D" />
        <stop offset="0.4353" stopColor="#EFD871" />
        <stop offset="0.483" stopColor="#DABD5F" />
        <stop offset="0.5311" stopColor="#C09A46" />
        <stop offset="0.5796" stopColor="#A1732B" />
        <stop offset="0.6277" stopColor="#7F4B0F" />
        <stop offset="0.6348" stopColor="#7A460B" />
        <stop offset="0.8315" stopColor="#EAC35C" />
        <stop offset="1" stopColor="#A2761D" />
      </linearGradient>
      <radialGradient
        id="paint3_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.6452 21.5117) scale(16.8577 16.6542)"
      >
        <stop stopColor="#003265" />
        <stop offset="0.1038" stopColor="#003568" />
        <stop offset="0.2421" stopColor="#003D6F" />
        <stop offset="0.3999" stopColor="#1F4C7C" />
        <stop offset="0.5722" stopColor="#43628F" />
        <stop offset="0.7562" stopColor="#6F85AC" />
        <stop offset="0.9473" stopColor="#AEB9D2" />
        <stop offset="1" stopColor="#C4CBDE" />
      </radialGradient>
      <linearGradient
        id="paint4_linear"
        x1="5.69795"
        y1="21.5728"
        x2="20.592"
        y2="6.07143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBD47A" />
        <stop offset="0.0955" stopColor="#FFFDE7" />
        <stop offset="0.4663" stopColor="#E6903B" />
        <stop offset="0.7416" stopColor="#FFF4C1" />
        <stop offset="1" stopColor="#B56323" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="13.798"
        y1="20.9747"
        x2="13.798"
        y2="12.1346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF8718" />
        <stop offset="0.2416" stopColor="#DBC142" />
        <stop offset="0.5787" stopColor="#F4D793" />
        <stop offset="1" stopColor="#9A8016" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="11.6365"
        y1="0.905535"
        x2="16.1468"
        y2="28.0386"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0562" stopColor="#EFBF5F" />
        <stop offset="0.2528" stopColor="#F4D767" />
        <stop offset="0.4663" stopColor="#DFAD3F" />
        <stop offset="0.6461" stopColor="#BF851A" />
        <stop offset="0.8876" stopColor="#F6D459" />
        <stop offset="1" stopColor="#C28031" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="0.611865"
        y1="6.24239"
        x2="27.9571"
        y2="21.8657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D09F43" />
        <stop offset="0.2079" stopColor="#FFFABE" />
        <stop offset="0.4663" stopColor="#F0B944" />
        <stop offset="0.7416" stopColor="#FFF9BA" />
        <stop offset="1" stopColor="#D39542" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="0.360032"
        y1="7.53426"
        x2="13.2096"
        y2="7.53426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3DF80" />
        <stop offset="0.0183" stopColor="#F3E086" />
        <stop offset="0.1328" stopColor="#F7E9A9" />
        <stop offset="0.2434" stopColor="#FAF0C5" />
        <stop offset="0.3479" stopColor="#FDF7DA" />
        <stop offset="0.4436" stopColor="#FFFCE8" />
        <stop offset="0.5225" stopColor="#FFFDED" />
        <stop offset="0.5871" stopColor="#FFFAE7" />
        <stop offset="0.6683" stopColor="#FEF3D8" />
        <stop offset="0.7585" stopColor="#FCEAC2" />
        <stop offset="0.8551" stopColor="#FADDA3" />
        <stop offset="0.9557" stopColor="#F7CE7F" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="26.092"
        y1="19.2195"
        x2="14.4747"
        y2="19.2195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3DF80" />
        <stop offset="0.0183" stopColor="#F3E086" />
        <stop offset="0.1328" stopColor="#F7E9A9" />
        <stop offset="0.2434" stopColor="#FAF0C5" />
        <stop offset="0.3479" stopColor="#FDF7DA" />
        <stop offset="0.4436" stopColor="#FFFCE8" />
        <stop offset="0.5225" stopColor="#FFFDED" />
        <stop offset="0.5871" stopColor="#FFFAE7" />
        <stop offset="0.6683" stopColor="#FEF3D8" />
        <stop offset="0.7585" stopColor="#FCEAC2" />
        <stop offset="0.8551" stopColor="#FADDA3" />
        <stop offset="0.9557" stopColor="#F7CE7F" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="14.4302"
        y1="19.7138"
        x2="26.8968"
        y2="19.7138"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0955" stopColor="#F2DF8A" />
        <stop offset="0.4663" stopColor="#A26611" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="7.0612"
        y1="26.4244"
        x2="7.7162"
        y2="13.7415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3DF80" />
        <stop offset="0.0183" stopColor="#F3E086" />
        <stop offset="0.1328" stopColor="#F7E9A9" />
        <stop offset="0.2434" stopColor="#FAF0C5" />
        <stop offset="0.3479" stopColor="#FDF7DA" />
        <stop offset="0.4436" stopColor="#FFFCE8" />
        <stop offset="0.5225" stopColor="#FFFDED" />
        <stop offset="0.5871" stopColor="#FFFAE7" />
        <stop offset="0.6683" stopColor="#FEF3D8" />
        <stop offset="0.7585" stopColor="#FCEAC2" />
        <stop offset="0.8551" stopColor="#FADDA3" />
        <stop offset="0.9557" stopColor="#F7CE7F" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="21.3363"
        y1="2.30461"
        x2="19.4487"
        y2="12.6627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3DF80" />
        <stop offset="0.0183" stopColor="#F3E086" />
        <stop offset="0.1328" stopColor="#F7E9A9" />
        <stop offset="0.2434" stopColor="#FAF0C5" />
        <stop offset="0.3479" stopColor="#FDF7DA" />
        <stop offset="0.4436" stopColor="#FFFCE8" />
        <stop offset="0.5225" stopColor="#FFFDED" />
        <stop offset="0.5871" stopColor="#FFFAE7" />
        <stop offset="0.6683" stopColor="#FEF3D8" />
        <stop offset="0.7585" stopColor="#FCEAC2" />
        <stop offset="0.8551" stopColor="#FADDA3" />
        <stop offset="0.9557" stopColor="#F7CE7F" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="6.78409"
        y1="25.2831"
        x2="7.95014"
        y2="14.1585"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0955" stopColor="#F2DF8A" />
        <stop offset="0.4663" stopColor="#A26611" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="19.8238"
        y1="12.7068"
        x2="20.1013"
        y2="0.39282"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0955" stopColor="#F2DF8A" />
        <stop offset="0.4663" stopColor="#A26611" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="3.003"
        y1="4.92423"
        x2="14.2466"
        y2="7.824"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0955" stopColor="#F2DF8A" />
        <stop offset="0.4663" stopColor="#A26611" />
        <stop offset="1" stopColor="#F6C76D" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="84" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const LogoNewManheimHome = () => (
  <svg
    width="144"
    height="47"
    viewBox="0 0 144 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M58.6016 1.74316V45.4978" stroke="#025CA8" strokeWidth="0.5" />
    <path
      d="M70.2959 42.8555V25.998H76.7666V27.8221H72.3204V33.4812H76.1546V35.3052H72.3204V41.0343H76.7666V42.8584H70.2959V42.8555Z"
      fill="#025CA8"
    />
    <path
      d="M85.3956 42.8555L83.2087 36.1779L81.045 42.8555H78.832L82.0311 34.094L79.0902 25.998H81.3031L83.2087 32.1532L85.1374 25.998H87.3504L84.3166 34.094L87.6114 42.8555H85.3956Z"
      fill="#025CA8"
    />
    <path
      d="M93.6787 35.8247H91.9385V42.8555H89.9141V25.998H93.6787C96.2194 25.998 97.6551 27.183 97.6551 30.9216C97.6551 34.6165 96.173 35.8247 93.6787 35.8247ZM93.6091 27.8221H91.9385V34.0007H93.6091C94.9752 34.0007 95.6336 33.1718 95.6336 30.9216C95.6307 28.651 94.9723 27.8221 93.6091 27.8221Z"
      fill="#025CA8"
    />
    <path
      d="M106.388 42.8555L104.082 35.6117H102.27V42.8555H100.245V25.998H104.033C106.574 25.998 107.986 27.11 107.986 30.8049C107.986 33.1484 107.421 34.5231 105.962 35.2089L108.526 42.8555H106.388ZM104.059 27.8221H102.27V33.8109H104.059C105.611 33.8109 105.965 32.6027 105.965 30.8049C105.965 29.0041 105.614 27.8221 104.059 27.8221Z"
      fill="#025CA8"
    />
    <path
      d="M111.089 42.8555V25.998H117.56V27.8221H113.113V33.4812H116.948V35.3052H113.113V41.0343H117.56V42.8584H111.089V42.8555Z"
      fill="#025CA8"
    />
    <path
      d="M126.668 42.0027C126.033 42.6185 125.044 43.0213 123.704 43.0213C122.41 43.0213 121.421 42.6652 120.763 42.0027C119.986 41.2205 119.728 40.2516 119.728 38.4509H121.752C121.752 39.5628 121.871 40.1786 122.318 40.6514C122.599 40.9579 123.072 41.1709 123.707 41.1709C124.388 41.1709 124.835 40.9579 125.143 40.6252C125.589 40.129 125.685 39.4899 125.685 38.375C125.685 36.4341 125.404 35.8417 124.037 35.2726L122.132 34.4904C120.415 33.7812 119.849 32.8327 119.849 30.276C119.849 28.808 120.273 27.5998 121.12 26.8205C121.778 26.2047 122.671 25.8486 123.779 25.8486C125.003 25.8486 125.92 26.2047 126.555 26.8205C127.402 27.6494 127.66 28.738 127.66 30.276H125.636C125.636 29.3771 125.543 28.7613 125.119 28.2389C124.838 27.8829 124.391 27.6698 123.753 27.6698C123.164 27.6698 122.741 27.8828 122.46 28.1893C122.059 28.6387 121.871 29.3246 121.871 30.2031C121.871 31.8608 122.129 32.357 123.472 32.9261L125.354 33.7082C127.26 34.4904 127.706 35.6724 127.706 38.3254C127.704 40.0852 127.466 41.1972 126.668 42.0027Z"
      fill="#025CA8"
    />
    <path
      d="M136.969 42.0027C136.334 42.6185 135.345 43.0213 134.005 43.0213C132.711 43.0213 131.722 42.6652 131.064 42.0027C130.286 41.2205 130.028 40.2516 130.028 38.4509H132.053C132.053 39.5628 132.172 40.1786 132.618 40.6514C132.9 40.9579 133.372 41.1709 134.008 41.1709C134.689 41.1709 135.136 40.9579 135.443 40.6252C135.89 40.129 135.986 39.4899 135.986 38.375C135.986 36.4341 135.704 35.8417 134.338 35.2726L132.433 34.4904C130.716 33.7812 130.15 32.8327 130.15 30.276C130.15 28.808 130.574 27.5998 131.42 26.8205C132.079 26.2047 132.972 25.8486 134.08 25.8486C135.304 25.8486 136.221 26.2047 136.856 26.8205C137.703 27.6494 137.961 28.738 137.961 30.276H135.936C135.936 29.3771 135.844 28.7613 135.42 28.2389C135.139 27.8829 134.692 27.6698 134.054 27.6698C133.465 27.6698 133.042 27.8828 132.76 28.1893C132.36 28.6387 132.172 29.3246 132.172 30.2031C132.172 31.8608 132.43 32.357 133.773 32.9261L135.655 33.7082C137.561 34.4904 138.007 35.6724 138.007 38.3254C138.004 40.0852 137.769 41.1972 136.969 42.0027Z"
      fill="#025CA8"
    />
    <path
      d="M141.256 29.8234C141.137 29.9401 140.952 30.0131 140.699 30.0131C140.456 30.0131 140.27 29.946 140.148 29.8234C140.003 29.6775 139.954 29.4936 139.954 29.158H140.334C140.334 29.3652 140.357 29.4819 140.441 29.5695C140.494 29.6278 140.583 29.6658 140.702 29.6658C140.83 29.6658 140.914 29.6249 140.972 29.5636C141.056 29.4702 141.074 29.3506 141.074 29.1405C141.074 28.7756 141.021 28.6647 140.766 28.5597L140.409 28.4137C140.088 28.2795 139.98 28.1015 139.98 27.6228C139.98 27.3485 140.059 27.1208 140.218 26.9749C140.343 26.8582 140.508 26.7939 140.717 26.7939C140.946 26.7939 141.117 26.8611 141.239 26.9749C141.398 27.1296 141.445 27.3339 141.445 27.6228H141.065C141.065 27.4535 141.048 27.3397 140.969 27.2405C140.917 27.1734 140.833 27.1354 140.714 27.1354C140.604 27.1354 140.525 27.1763 140.47 27.2317C140.395 27.3164 140.36 27.4448 140.36 27.6082C140.36 27.9205 140.409 28.011 140.659 28.119L141.013 28.2649C141.369 28.4108 141.454 28.6326 141.454 29.1317C141.451 29.4644 141.407 29.6716 141.256 29.8234Z"
      fill="#025CA8"
    />
    <path
      d="M143.62 29.984V27.7338L143.121 29.2135H142.886L142.396 27.7718V29.9869H142.016V26.8232H142.372L143.008 28.5831L143.64 26.8232H143.997V29.984H143.62Z"
      fill="#025CA8"
    />
    <path
      d="M78.8375 21.0772V9.07324L76.1778 16.9562H74.9307L72.3204 9.26295V21.0772H70.2959V4.21973H72.2014L75.5891 13.594L78.9535 4.21973H80.859V21.0772H78.8375Z"
      fill="#025CA8"
    />
    <path
      d="M90.6858 21.0772L89.8853 17.0992H86.0017L85.2013 21.0772H83.084L86.9908 4.21973H88.9427L92.8263 21.0772H90.6858ZM87.9566 7.50891L86.3788 15.2751H89.5083L87.9566 7.50891Z"
      fill="#025CA8"
    />
    <path
      d="M101.379 21.0772L97.0489 9.54604V21.0772H95.0244V4.21973H96.9067L101.188 15.7508V4.21973H103.212V21.0772H101.379Z"
      fill="#025CA8"
    />
    <path
      d="M111.977 21.0772V13.4043H108.259V21.0742H106.234V4.21973H108.259V11.5832H111.977V4.21973H114.002V21.0772H111.977Z"
      fill="#025CA8"
    />
    <path
      d="M117.012 21.0772V4.21973H123.482V6.04381H119.036V11.7028H122.87V13.5269H119.036V19.256H123.482V21.0801H117.012V21.0772Z"
      fill="#025CA8"
    />
    <path
      d="M126.063 21.0772V4.21973H128.088V21.0772H126.063Z"
      fill="#025CA8"
    />
    <path
      d="M139.629 21.0772V9.07324L136.97 16.9562H135.723L133.112 9.26295V21.0772H131.088V4.21973H132.993L136.381 13.594L139.745 4.21973H141.651V21.0772H139.629Z"
      fill="#025CA8"
    />
    <path
      d="M23.3537 47C10.4558 47 0 36.4787 0 23.5C0 10.5213 10.4558 0 23.3537 0C36.2516 0 46.7074 10.5213 46.7074 23.5C46.7074 36.4787 36.2516 47 23.3537 47ZM44.0507 23.5C44.0507 11.9981 34.784 2.67337 23.3537 2.67337C11.9234 2.67337 2.65383 11.9981 2.65383 23.5C2.65383 35.0019 11.9205 44.3266 23.3508 44.3266C34.7811 44.3266 44.0507 35.0019 44.0507 23.5Z"
      fill="#025CA8"
    />
    <path
      d="M31.5937 37.7652C29.1719 39.1836 26.3585 39.9979 23.3537 39.9979C20.349 39.9979 17.5356 39.1836 15.1138 37.7652L16.2479 20.7648L21.6744 37.7681H25.0302L30.4567 20.7648L31.5937 37.7652ZM38.1166 30.679C39.1607 28.5076 39.7466 26.0707 39.7466 23.4994C39.7466 19.5594 38.3747 15.9434 36.0834 13.1065L38.1166 30.679ZM6.95801 23.4994C6.95801 26.0619 7.53808 28.4872 8.57351 30.6527L10.6183 13.1065C8.32988 15.9404 6.95801 19.5594 6.95801 23.4994ZM23.3537 22.44L28.0407 7.68391C26.5557 7.23738 24.9808 7.00098 23.3537 7.00098C21.7237 7.00098 20.1517 7.2403 18.6668 7.68391L23.3537 22.44Z"
      fill="#025CA8"
    />
  </svg>
);

export { LogoNewManheim, LogoNewManheimHome };
