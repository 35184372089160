import styled from 'styled-components';

const Container = styled.div`
  width: calc(100%);
  height: calc(100%);
  overflow: hidden;

  background-color: ${props => props.theme.colors.DarkBlue};
`;

const LeftLogo = styled.div`
  padding-left: 38px;
  padding-top: 26px;
  width: 100px;
  display: inline-block;
`;

const ActionButton = styled.div`
  margin-left: calc(50% - 100px);
  margin-bottom: 30px;
  display: inline-flex;
  flex-direction: row;
`;

const LabelWhite = styled.label`
  margin-left: 10px;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #F8F8F8;
`;

const Ellipse = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors.DarkBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
`;

const Bell = styled.div`
  
`;

const Persona = styled.div`
  
`;

const ConfigurationButton = styled.div`
  float: right;
  margin-top: 30px;
  margin-right:30px;
  display: inline-flex;
  flex-direction: row;
`;

export { Container, LeftLogo, ActionButton, LabelWhite, Ellipse, Bell, Persona, ConfigurationButton };