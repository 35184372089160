import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useFormik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikProvider,
  FieldMetaProps,
} from "formik";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { ButtonPrimary, MaskHelper, PasswordLevel } from "c4u-web-components";

import { useC4u } from "../../../../hooks/";
import { IUserOnboarding } from "../../../../hooks/apis/use-c4u/interfaces/interfaces";

import { GeneralError } from "../../../atoms/";
import { inputErrorHelper } from "../../../../helper";
import { Input } from "../../../molecules/forms/input/input.molecule";

import {
  OnboardingForm,
  OnboardingFormValidationSchema,
} from "./onboarding-form.organism.validations";

import {
  FontDefined,
  LoginAction,
  LoginActionButton,
} from "./onboarding-form.organism.style";
import { InputWithMask } from "../../../molecules";

const OnboardingFormRender = (
  formikBag: FormikProps<OnboardingForm>,
  cpf?: string
) => {
  const {
    isSubmitting,
    getFieldMeta,
    setErrors,
    handleChange,
    errors,
  }: {
    isSubmitting: boolean;
    getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
    setErrors: (errors: FormikErrors<any>) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    errors: any;
  } = formikBag;

  const { t } = useTranslation();

  return (
    <>
      <FormikProvider value={formikBag}>
        <Form
          noValidate
          className={"form-default"}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            formikBag.handleSubmit(e);
          }}
        >
          <GeneralError
            errorProp={getFieldMeta("general")}
            errorCode={getFieldMeta("errorCode")}
            setErrors={setErrors}
          />

          <Form.Group as={Row} controlId="onboardingForm">
            <Col md={"3"}>
              <InputWithMask
                mask={MaskHelper.Cpf}
                type="text"
                name="cpf"
                autoComplete={"off"}
                placeholder={t("Tab here")}
                label={t("CPF")}
                onChange={handleChange}
                required={true}
                disabled={!!cpf}
                value={formikBag.values?.cpf as string}
                isInvalid={errors.cpf !== null && errors.cpf !== undefined}
                errors={() =>
                  inputErrorHelper(
                    !errors.cpf?.key
                      ? { key: errors.cpf, field: "cpf" }
                      : errors.cpf,
                    t
                  )
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="onboardingForm">
            <Col md={"3"}>
              <Input
                autocomplete={"off"}
                type="password"
                name="password"
                placeholder={t("Tab here")}
                label={t("Password")}
                onChange={handleChange}
                required={true}
                value={formikBag.values?.password}
                isInvalid={
                  errors.password !== null && errors.password !== undefined
                }
                errors={() =>
                  inputErrorHelper(
                    errors.password?.key === "field_invalid_pwd_weak"
                      ? null
                      : errors.password,
                    t
                  )
                }
              />
            </Col>
            <Col md={"3"}>
              <Input
                autocomplete={"off"}
                type="password"
                name="passwordValidation"
                placeholder={t("Tab here")}
                label={t("Password Validation")}
                onChange={handleChange}
                required={true}
                value={formikBag.values?.passwordValidation}
                isInvalid={
                  errors.passwordValidation !== null &&
                  errors.passwordValidation !== undefined
                }
                errors={() => inputErrorHelper(errors.passwordValidation, t)}
              />
            </Col>
          </Form.Group>

          <FontDefined>
            <PasswordLevel
              translate={t}
              content={formikBag.values?.password ?? ""}
              error={errors.password?.key === "field_invalid_pwd_weak"}
            />
          </FontDefined>

          <br />
          <hr />
          <br />

          <LoginAction>
            <Row>
              <Col sm={12}>
                <LoginActionButton>
                  <ButtonPrimary
                    type="submit"
                    sizex="md"
                    disabled={!formikBag.values?.id || isSubmitting}
                  >
                    {t("Save")}
                  </ButtonPrimary>
                </LoginActionButton>
              </Col>
            </Row>
          </LoginAction>
        </Form>
      </FormikProvider>
    </>
  );
};

const OnboardingFormOnSubmit = (
  values: OnboardingForm,
  actions: FormikHelpers<OnboardingForm>,
  onSubmit: (
    request: IUserOnboarding,
    actions: FormikHelpers<OnboardingForm>
  ) => void
) => {
  actions.setSubmitting(true);
  const onboarding = {
    id: values.id,
    cpf: (values.cpf as string).replace(/[^\d]/g, ""),
    password: values.password,
    passwordValidation: values.passwordValidation,
  } as IUserOnboarding;
  onSubmit(onboarding, actions);
};

const OnboardingFormFields: React.FC = () => {
  const { token } = useParams() as any;

  const { StartUserOnboarding, SubmitUserOnboarding } = useC4u();

  const initialValues: OnboardingForm = {
    id: "",
    cpf: "",
    password: "",
    passwordValidation: "",
  };

  const submitForm = (
    request: IUserOnboarding,
    actions: FormikHelpers<OnboardingForm>
  ) => {
    actions.setSubmitting(true);

    SubmitUserOnboarding(request)
      .then((result: any) => {
        console.log("onboarding is done, welcome to c4u");
        const returnUrl =
          process.env.REACT_APP_MASTER_PAGE ?? window.location.origin;
        window.location.href = returnUrl;
      })
      .catch((err: any) => {
        console.log("catch ", err);
        if (err.errors) {
          console.error(err.errors);
          actions.setErrors(err.errors);
        }

        if (err.message) {
          console.log("setting general handled error");
          actions.setFieldError("general", err.message);
          actions.setFieldError("errorCode", err.code);
        }

        if (err.generalError) {
          console.log("setting general unhandled error");
          actions.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik<OnboardingForm>({
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      OnboardingFormOnSubmit(values, actions, submitForm);
    },
    validationSchema: OnboardingFormValidationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [cpf, setCpf] = useState<string>();

  useEffect(() => {
    StartUserOnboarding(token)
      .then((onboardingStart: IUserOnboarding) => {
        console.log(onboardingStart);
        if (onboardingStart && onboardingStart.id) {
          setCpf(onboardingStart.cpf ?? "");
          formik.setValues(
            {
              id: onboardingStart.id,
              cpf: onboardingStart.cpf ?? "",
              password: "",
              passwordValidation: "",
            },
            false
          );
        }
      })
      .catch((err: any) => {
        if (err.message) {
          formik.setFieldError("general", err.message);
          formik.setFieldError("errorCode", err.code);
        }

        if (err.generalError) {
          formik.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        console.log("This suppose to be a single validation...");
      });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return OnboardingFormRender(formik, cpf);
};

export { OnboardingFormFields };
