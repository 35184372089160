import { TypesHelper } from "c4u-web-components";
import { IPagedRequestParamsBaseModel, IPagedResponseBaseModel, PagedRequestParamsBaseModel } from "../..";

export interface IGetListEmailTemplateParams
  extends IPagedRequestParamsBaseModel {}

export class GetListEmailTemplateParams extends PagedRequestParamsBaseModel {}

export interface IGetEmailTemplateViewModel {
  template: number;
  title?: string;
  body?: string;
  nameMessage?: string;
  isTrigger: boolean;
  activeWhatsapp: boolean;
  activeEmail: boolean;
  timeInHours: number;
  bodyWhatsapp?: string;
  referenceDateName?: string;
}

export interface IGetListEmailTemplatePagedResponseModel
  extends IPagedResponseBaseModel {
  data?: IGetEmailTemplateViewModel[];
}

export interface IPostEmailTemplateRequest
  extends Omit<IGetEmailTemplateViewModel, "nameMessage" | "isTrigger"> {}

export interface IFormEmailTemplate extends IPostEmailTemplateRequest {
  activeWhatsappToggle?: string;
  activeEmailToggle?: string;
}

export class PostEmailTemplateRequest {
  template: number | null;
  title?: string | null;
  body?: string | null;
  activeWhatsapp: boolean | null;
  activeEmail: boolean | null;
  timeInHours: number | null;
  bodyWhatsapp?: string | null;
  referenceDateName?: string | null;
  constructor({
    template,
    title,
    body,
    activeWhatsapp,
    activeEmail,
    timeInHours,
    bodyWhatsapp,
    referenceDateName,
  }: IPostEmailTemplateRequest) {
    this.template = TypesHelper.toNumberNull(template);
    this.title = TypesHelper.toStringNull(title);
    this.body = TypesHelper.toStringNull(body);
    this.activeWhatsapp = TypesHelper.toBooleanNull(activeWhatsapp);
    this.activeEmail = TypesHelper.toBooleanNull(activeEmail);
    this.timeInHours = TypesHelper.toNumberNull(timeInHours);
    this.bodyWhatsapp = TypesHelper.toStringNull(bodyWhatsapp);
    this.referenceDateName = TypesHelper.toStringNull(referenceDateName);
  }
}
