import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StatusHelper } from "../../../../../helper";
import { AdModel } from "../../../../../models";

interface IProps {
  ad: AdModel;
}
export const AdMainDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Form.Row>
      <Form.Group as={Col} md={4}>
        <Form.Label>{t("Step")}</Form.Label>
        <Form.Control
          value={StatusHelper.getStepName(props.ad?.step)}
          disabled={true}
        />
      </Form.Group>
      <Form.Group as={Col} md={4}>
        <Form.Label>{t("SubStep")}</Form.Label>
        <Form.Control
          value={StatusHelper.getStepStatusName(
            props.ad?.step,
            props.ad.stepStatus
          )}
          disabled={true}
        />
      </Form.Group>
      <Form.Group as={Col} md={4}>
        <Form.Label>{t("Status")}</Form.Label>
        <Form.Control
          value={StatusHelper.getStatus(props.ad?.step, props.ad.stepStatus, t)}
          disabled={true}
        />
      </Form.Group>
    </Form.Row>
  );
};
