import React from "react";
import { ICpvHomeSmallCardData } from "../../../models";
import {
  WrapperRegistrationHomeSmallCard,
  CardLeft,
  CardRight,
  Badge,
  ArrowWrapper,
  SmallCardTitle,
} from "./registration-card-small.atom.style";
import { ArrowAround } from "../../atoms";

export interface IProps {
  onClick?: (e: React.MouseEvent) => void;
  data: ICpvHomeSmallCardData;
  comingSoonText: string;
  dataElement: string;
  dataProduct: string;
  dataPosition: number;
}

export const RegistrationHomeSmallCardAtom: React.FC<IProps> = (props) => {
  const { data } = props;

  return (
    <WrapperRegistrationHomeSmallCard
      onClick={props.onClick}
      data-element={props.dataElement}
      data-product={props.dataProduct}
      data-position={props.dataPosition}
    >
      <CardLeft backgroundColor={data.backgroundColor}>
        <img alt={data.logoAltText} src={data.logoSrc} />
      </CardLeft>
      <CardRight>
        <SmallCardTitle>
          {data.title}
          {data.showComingSoon && <Badge>{props.comingSoonText}</Badge>}
        </SmallCardTitle>
        <div>
          <p>{data.description}</p>
          {data.descriptionLine2 && <p>{data.descriptionLine2}</p>}
        </div>
        <ArrowWrapper disabled={data.disabled}>
          <ArrowAround />
        </ArrowWrapper>
      </CardRight>
    </WrapperRegistrationHomeSmallCard>
  );
};
