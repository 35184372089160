import { TypesHelper } from "c4u-web-components";

export interface IUpdateAdInspection {
  id: number;
  status?: number;
  inspectionPlaceName?: string;
  inspectionPlaceAddress?: string;
}

export class UpdateAdInspectionRequest {
  id: number | null;
  status?: number | null;
  inspectionPlaceName?: string | null;
  inspectionPlaceAddress?: string | null;
  constructor({
    id,
    status,
    inspectionPlaceName,
    inspectionPlaceAddress,
  }: UpdateAdInspectionRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.status = TypesHelper.toNumberNull(status);
    this.inspectionPlaceAddress = TypesHelper.toStringNull(
      inspectionPlaceAddress
    );
    this.inspectionPlaceName = TypesHelper.toStringNull(inspectionPlaceName);
  }
}
