import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IUpdateDutValuesModel,
  UpdateDutValuesRequest,
} from "../../../../../models";
import {
  ButtonPrimary,
  FormikControlAtom,
  MaskHelper,
  YupHelper,
} from "c4u-web-components";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";

interface IProps {
  data: IUpdateDutValuesModel;
  enable?: boolean;
}

export const DutCrvMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { updateDutValues } = useManhein();

  const msgRequired = t("field_required");
  const msgInvalidDocument = t("field_invalid_document");

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  const validations = Yup.object<IUpdateDutValuesModel>({
    id: Yup.number(),
    buyerName: Yup.string().required(msgRequired),
    buyerDocument: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(msgInvalidDocument),
    buyerAddress: Yup.string().required(msgRequired),
  });

  const formik = useFormik<IUpdateDutValuesModel>({
    initialValues: props.data,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await updateDutValues(new UpdateDutValuesRequest(values));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else {
            console.log(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          property="buyerName"
          label={t("BuyerName")}
          md={4}
          disabled={!props.enable}
        />
        <FormikControlAtom
          translate={t}
          mask={MaskHelper.CpfCnpj}
          formik={formik}
          property="buyerDocument"
          label={t("BuyerDocument")}
          md={3}
          disabled={!props.enable}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property="buyerAddress"
          label={t("BuyerAddress")}
          md={5}
          disabled={!props.enable}
        />
      </Form.Row>

      <Form.Group as={Col} md className={"text-right pt-4"}>
        <ButtonPrimary
          sizex="md"
          type="submit"
          loading={formik.isSubmitting}
          disabled={!props.enable}
        >
          {t("Save")}
        </ButtonPrimary>
      </Form.Group>
    </Form>
  );
};
