import React from "react";

const LogoKbb = () => (
  <>
    <svg
      width="137"
      height="48"
      viewBox="0 0 137 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5757 27.0125V2.43497H32.969V27.0125"
        fill="url(#paint0_radial)"
      />
      <path
        d="M17.5669 22.9697V41.6525L25.2732 35.7721L32.9763 41.6525V22.9697H17.5669Z"
        fill="url(#paint1_radial)"
      />
      <path
        d="M32.0614 40.9594V22.9688H31.3247L31.3295 40.3996"
        fill="url(#paint2_linear)"
      />
      <path
        d="M19.2066 40.4082V16.1321H18.4699L18.4683 40.9744"
        fill="url(#paint3_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint4_radial)"
      />
      <path
        d="M32.0639 27.3108V2.43503H31.3271V27.3108"
        fill="url(#paint5_linear)"
      />
      <path
        d="M19.2118 27.3108V2.43501H18.4751V27.3108"
        fill="url(#paint6_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint7_radial)"
      />
      <path
        d="M32.0639 27.3108V2.43503H31.3271V27.3108"
        fill="url(#paint8_linear)"
      />
      <path
        d="M19.2118 27.3108V2.43501H18.4751V27.3108"
        fill="url(#paint9_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint10_radial)"
      />
      <path
        d="M32.0639 27.3108V2.43503H31.3271V27.3108"
        fill="url(#paint11_linear)"
      />
      <path
        d="M19.2118 27.3108V2.43501H18.4751V27.3108"
        fill="url(#paint12_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint13_radial)"
      />
      <path
        d="M32.0619 27.3104V2.43457H31.3252V27.3104"
        fill="url(#paint14_linear)"
      />
      <path
        d="M19.2123 27.3104V2.43457H18.4756V27.3104"
        fill="url(#paint15_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint16_radial)"
      />
      <path
        d="M32.0619 27.3104V2.43457H31.3252V27.3104"
        fill="url(#paint17_linear)"
      />
      <path
        d="M19.2123 27.3104V2.43457H18.4756V27.3104"
        fill="url(#paint18_linear)"
      />
      <path
        d="M17.5757 21.1177V2.43497H32.969V21.1177"
        fill="url(#paint19_radial)"
      />
      <path
        d="M32.0619 27.3104V2.43457H31.3252V27.3104"
        fill="url(#paint20_linear)"
      />
      <path
        d="M19.2123 27.3104V2.43457H18.4756V27.3104"
        fill="url(#paint21_linear)"
      />
      <path
        d="M32.9706 2.43497H17.5757V25.8259H32.9706V2.43497Z"
        fill="url(#paint22_radial)"
      />
      <path
        d="M32.0619 27.3104V2.43457H31.3252V27.3104"
        fill="url(#paint23_linear)"
      />
      <path
        d="M19.2123 27.3104V2.43457H18.4756V27.3104"
        fill="url(#paint24_linear)"
      />
      <path
        d="M32.0619 27.3104V2.43457H31.3252V27.3104"
        fill="url(#paint25_linear)"
      />
      <path
        d="M19.2123 27.3104V2.43457H18.4756V27.3104"
        fill="url(#paint26_linear)"
      />
      <path
        d="M35.5773 30.5168L33.323 30.024L33.368 32.3174L31.2549 31.3972L30.8489 33.6556L28.9556 32.343L28.1149 34.4801L26.5168 32.8262L25.2718 34.7577L24.0283 32.8246L22.4286 34.477L21.5895 32.3414L19.6962 33.6524L19.2903 31.394L17.1756 32.3127L17.2237 30.0192L14.9678 30.5104L15.4636 28.2712L13.158 28.3158L14.0822 26.2153L11.8118 25.8102L13.1307 23.9298L10.9823 23.0941L12.6461 21.504L10.7031 20.2679L12.6478 19.0319L10.9839 17.4417L13.1323 16.606L11.8134 14.724L14.0854 14.3221L13.1612 12.22L15.4684 12.2662L14.9742 10.0254L17.2269 10.5182L17.182 8.22475L19.2967 9.14342L19.701 6.88662L21.5943 8.19923L22.4367 6.06205L24.0347 7.71757L25.2798 5.78613L26.5233 7.71757L28.1229 6.06365L28.9621 8.20083L30.8553 6.88981L31.2597 9.1482L33.3744 8.22794L33.3278 10.5214L35.5821 10.0302L35.0863 12.271L37.3936 12.2248L36.4678 14.3269L38.7397 14.7304L37.4209 16.6124L39.5693 17.4481L37.9038 19.0366L39.8484 20.2743L37.9038 21.5103L39.5676 23.1005L37.4177 23.9346L38.7365 25.8166L36.4646 26.2201L37.3904 28.3206L35.0831 28.2759L35.5773 30.5168Z"
        fill="#213A78"
      />
      <path
        d="M35.5021 30.4451L33.2639 29.957L33.3088 32.2329L31.2101 31.3207L30.8074 33.5615L28.927 32.2601L28.0926 34.3813L26.5058 32.7385L25.2704 34.6556L24.0365 32.7369L22.4481 34.3781L21.6138 32.2569L19.7349 33.5599L19.3322 31.3175L17.2335 32.2298L17.2801 29.9522L15.0418 30.4403L15.5328 28.217L13.2432 28.2616L14.161 26.1755L11.9067 25.7736L13.2175 23.9075L11.0836 23.0766L12.7362 21.4992L10.8076 20.2711L12.7378 19.0446L11.0852 17.4657L13.2191 16.6363L11.9099 14.7671L14.1642 14.3684L13.2464 12.2806L15.5376 12.3269L15.0466 10.1036L17.2833 10.5932L17.24 8.31569L19.3386 9.22798L19.7397 6.98553L21.6202 8.29017L22.4545 6.16894L24.0429 7.8117L25.2784 5.89302L26.5122 7.8117L28.1007 6.17053L28.935 8.29176L30.8138 6.98872L31.2149 9.23117L33.3152 8.31888L33.2687 10.5948L35.5069 10.1084L35.0143 12.3317L37.3055 12.287L36.3862 14.3732L38.6421 14.7735L37.3312 16.6427L39.4652 17.4721L37.8126 19.0494L39.7411 20.2775L37.811 21.504L39.4636 23.083L37.3296 23.9123L38.6389 25.7815L36.383 26.1803L37.3007 28.2664L35.0095 28.2218L35.5021 30.4451Z"
        fill="url(#paint27_linear)"
      />
      <path
        d="M25.2702 8.32689C18.6229 8.32689 13.2383 13.6842 13.2383 20.2887C13.2383 26.8884 18.6229 32.2362 25.2702 32.2362C31.9144 32.2362 37.3102 26.8884 37.3102 20.2887C37.3102 13.6842 31.916 8.32689 25.2702 8.32689Z"
        fill="url(#paint28_radial)"
        stroke="#F8E579"
        strokeWidth="0.125"
        strokeMiterlimit="10"
      />
      <path
        d="M32.5243 15.6316L31.3626 16.4371L30.9775 15.8932L32.1392 15.0878L33.2126 13.0862L33.6329 13.6811L32.8628 14.9969L34.3694 14.7209L34.7898 15.3158L32.5243 15.6316Z"
        fill="url(#paint29_linear)"
      />
      <path
        d="M28.8223 13.9449L30.9161 11.2207L32.7276 12.586L32.3649 13.0597L31.0894 12.0995L30.5984 12.7391L31.6846 13.5573L31.3188 14.0326L30.2326 13.2144L29.724 13.8763L30.9979 14.8364L30.6337 15.3117L28.8223 13.9449Z"
        fill="url(#paint30_linear)"
      />
      <path
        d="M26.3975 13.1269L27.3236 9.82706L27.9737 10.0057L27.2096 12.7298L28.7216 13.1461L28.5595 13.7202L26.3975 13.1269Z"
        fill="url(#paint31_linear)"
      />
      <path
        d="M23.7875 13.2042L23.4761 9.79426L24.1486 9.73366L24.4054 12.5503L25.9688 12.4115L26.0233 13.0048L23.7875 13.2042Z"
        fill="url(#paint32_linear)"
      />
      <path
        d="M21.2645 14.2113L19.8237 11.0981L21.8951 10.1587L22.1454 10.7009L20.6885 11.3628L21.0255 12.0933L22.2657 11.5303L22.516 12.0726L21.2758 12.6356L21.6256 13.3916L23.0824 12.7313L23.3327 13.2735L21.2645 14.2113Z"
        fill="url(#paint33_linear)"
      />
      <path
        d="M20.4755 14.9481L18.709 14.5749L18.8133 15.2559L19.5915 15.9369L19.1438 16.4377L16.5542 14.173L17.0018 13.6722L18.1298 14.6594L17.8089 12.7678L18.3528 12.1586L18.6224 13.9098L20.9954 14.3643L20.4755 14.9481Z"
        fill="url(#paint34_linear)"
      />
      <path
        d="M35.3858 22.1775L34.5656 20.455L34.153 21.0387V22.1759H33.5303V18.3928H34.153V20.0403L35.2782 18.3928H36.0358L34.9781 19.8968L36.1113 22.1759H35.3858V22.1775Z"
        fill="url(#paint35_linear)"
      />
      <path
        d="M32.8041 21.797C32.5794 22.0618 32.3065 22.2117 31.9438 22.2117C31.581 22.2117 31.3034 22.0634 31.0786 21.797C30.756 21.4142 30.7657 20.9421 30.7657 20.2882C30.7657 19.6343 30.756 19.1622 31.0786 18.7794C31.3034 18.5147 31.581 18.3648 31.9438 18.3648C32.3065 18.3648 32.581 18.5147 32.8041 18.7794C33.1267 19.1622 33.1219 19.6343 33.1219 20.2882C33.1219 20.9421 33.1267 21.4142 32.8041 21.797ZM32.3435 19.226C32.2536 19.1048 32.1155 19.0251 31.9454 19.0251C31.7753 19.0251 31.6308 19.1048 31.5425 19.226C31.4221 19.3855 31.39 19.5609 31.39 20.2882C31.39 21.0155 31.4205 21.1909 31.5425 21.3504C31.6324 21.4732 31.7753 21.553 31.9454 21.553C32.1155 21.553 32.2552 21.4732 32.3435 21.3504C32.4638 21.1909 32.5008 21.0155 32.5008 20.2882C32.4992 19.5609 32.4638 19.3855 32.3435 19.226Z"
        fill="url(#paint36_linear)"
      />
      <path
        d="M30.1459 21.797C29.9212 22.0618 29.6483 22.2117 29.2856 22.2117C28.9228 22.2117 28.6452 22.0634 28.4204 21.797C28.0978 21.4142 28.1075 20.9421 28.1075 20.2882C28.1075 19.6343 28.0978 19.1622 28.4204 18.7794C28.6452 18.5147 28.9228 18.3648 29.2856 18.3648C29.6483 18.3648 29.9212 18.5147 30.1459 18.7794C30.4685 19.1622 30.4637 19.6343 30.4637 20.2882C30.4653 20.9421 30.4701 21.4142 30.1459 21.797ZM29.6853 19.226C29.5954 19.1048 29.4573 19.0251 29.2872 19.0251C29.1171 19.0251 28.9726 19.1048 28.8843 19.226C28.7639 19.3855 28.7318 19.5609 28.7318 20.2882C28.7318 21.0155 28.7623 21.1909 28.8843 21.3504C28.9742 21.4732 29.1171 21.553 29.2872 21.553C29.4573 21.553 29.597 21.4732 29.6853 21.3504C29.8056 21.1909 29.8425 21.0155 29.8425 20.2882C29.8425 19.5609 29.8056 19.3855 29.6853 19.226Z"
        fill="url(#paint37_linear)"
      />
      <path
        d="M26.833 22.1787H25.4976V18.3956H26.7784C27.4012 18.3956 27.7639 18.8103 27.7639 19.4578C27.7639 19.8773 27.5312 20.1484 27.3707 20.2393C27.5633 20.3462 27.8105 20.5838 27.8105 21.0894C27.8105 21.7959 27.4028 22.1787 26.833 22.1787ZM26.7303 19.0543H26.1203V19.9251H26.7303C26.9951 19.9251 27.1428 19.7497 27.1428 19.4897C27.1428 19.2297 26.9935 19.0543 26.7303 19.0543ZM26.7704 20.5902H26.1203V21.52H26.7704C27.0529 21.52 27.1877 21.3079 27.1877 21.0527C27.1877 20.7975 27.0529 20.5902 26.7704 20.5902Z"
        fill="url(#paint38_linear)"
      />
      <path
        d="M22.2061 22.1787V18.3956H24.3071V19.0543H22.8288V19.9411H24.0888V20.5998H22.8288V21.5184H24.3071V22.1771H22.2061V22.1787Z"
        fill="url(#paint39_linear)"
      />
      <path
        d="M20.5646 22.2106C19.9097 22.2106 19.3896 21.6843 19.3896 20.8821V18.3956H20.0124V20.855C20.0124 21.2904 20.2275 21.5503 20.5646 21.5503C20.9 21.5503 21.1199 21.2904 21.1199 20.855V18.3956H21.7379V20.8821C21.7379 21.6843 21.2178 22.2106 20.5646 22.2106Z"
        fill="url(#paint40_linear)"
      />
      <path
        d="M17.1489 22.1787V18.3956H17.7717V21.52H19.2195V22.1787H17.1489Z"
        fill="url(#paint41_linear)"
      />
      <path
        d="M15.7641 22.1787H14.4287V18.3956H15.7096C16.3323 18.3956 16.6951 18.8103 16.6951 19.4578C16.6951 19.8773 16.4624 20.1484 16.3002 20.2393C16.4929 20.3462 16.74 20.5838 16.74 21.0894C16.7416 21.7959 16.3323 22.1787 15.7641 22.1787ZM15.6614 19.0543H15.0515V19.9251H15.6614C15.9263 19.9251 16.0739 19.7497 16.0739 19.4897C16.0739 19.2297 15.9247 19.0543 15.6614 19.0543ZM15.7015 20.5902H15.0515V21.52H15.7015C15.984 21.52 16.1189 21.3079 16.1189 21.0527C16.1189 20.7975 15.984 20.5902 15.7015 20.5902Z"
        fill="url(#paint42_linear)"
      />
      <path
        d="M17.2416 25.7831C17.0474 25.7496 16.8869 25.6555 16.7617 25.4705C16.6349 25.2855 16.6028 25.0973 16.6413 24.9027C16.6959 24.6204 16.9061 24.4801 17.1902 24.2807C17.4759 24.0814 17.6798 23.9314 17.9591 23.9793C18.1533 24.0128 18.3154 24.1085 18.4422 24.2935C18.569 24.4785 18.5995 24.6635 18.561 24.8597C18.5064 25.1404 18.2977 25.2839 18.012 25.4833C17.7263 25.6842 17.5209 25.831 17.2416 25.7831ZM18.203 24.7608C18.2255 24.6779 18.2127 24.5822 18.1517 24.496C18.0923 24.4083 18.0072 24.3605 17.9221 24.3525C17.8098 24.3381 17.7231 24.3764 17.4053 24.5997C17.0875 24.8214 17.0217 24.8916 16.9944 25.0016C16.9719 25.0846 16.988 25.1834 17.0458 25.2696C17.1051 25.3557 17.1886 25.4035 17.2737 25.4115C17.386 25.4243 17.4743 25.3892 17.7921 25.1659C18.1115 24.9442 18.1757 24.8724 18.203 24.7608Z"
        fill="url(#paint43_linear)"
      />
      <path
        d="M18.9604 25.6452L18.6025 25.9913L19.1354 26.5575L18.8818 26.8015L18.3489 26.2369L17.7598 26.8079L17.4966 26.5288L18.9492 25.1221L19.8368 26.0647L19.5832 26.3103L18.9604 25.6452Z"
        fill="url(#paint44_linear)"
      />
      <path
        d="M20.1545 26.7696L19.8623 27.1748L20.4851 27.6357L20.2781 27.9228L19.6553 27.4618L19.1754 28.1285L18.8672 27.9004L20.0533 26.2561L21.0918 27.0248L20.8864 27.3119L20.1545 26.7696Z"
        fill="url(#paint45_linear)"
      />
      <path
        d="M20.4087 28.9353L21.33 27.1267L21.6687 27.3037L20.7458 29.1123L20.4087 28.9353Z"
        fill="url(#paint46_linear)"
      />
      <path
        d="M21.8364 29.5834C21.6358 29.5085 21.4977 29.3713 21.4175 29.1895C21.3019 28.9263 21.3934 28.6903 21.5122 28.3585C21.631 28.0284 21.7128 27.7876 21.968 27.6616C22.1446 27.5754 22.3372 27.5611 22.5378 27.6344C22.8813 27.762 23.0756 28.0651 22.9953 28.4893L22.6325 28.3553C22.6518 28.1879 22.602 28.0364 22.4207 27.9694C22.3228 27.9327 22.2297 27.9407 22.1558 27.9837C22.0563 28.0396 22.0034 28.1225 21.8701 28.4909C21.7369 28.8593 21.7257 28.9582 21.7658 29.0651C21.7947 29.1464 21.8621 29.2134 21.96 29.2501C22.1414 29.3171 22.2762 29.2325 22.3677 29.0922L22.7321 29.2262C22.5202 29.6042 22.1767 29.7078 21.8364 29.5834Z"
        fill="url(#paint47_linear)"
      />
      <path
        d="M22.9624 29.9264L23.3749 27.9328L23.7473 28.0125L23.3348 30.0062L22.9624 29.9264Z"
        fill="url(#paint48_linear)"
      />
      <path
        d="M24.8893 30.1941L24.8027 29.824L24.1109 29.7682L23.9648 30.1175L23.5684 30.0856L24.4383 28.1159L24.7369 28.1398L25.2858 30.226L24.8893 30.1941ZM24.5491 28.7251L24.2441 29.4429L24.7288 29.4827L24.5491 28.7251Z"
        fill="url(#paint49_linear)"
      />
      <path
        d="M25.537 30.1944L25.4214 28.1625L25.8018 28.1402L25.8981 29.818L26.7825 29.767L26.8034 30.121L25.537 30.1944Z"
        fill="url(#paint50_linear)"
      />
      <path
        d="M28.7575 29.306C28.6677 29.5181 28.5136 29.6425 28.3161 29.7143C28.1155 29.7876 27.9229 29.7733 27.7463 29.6856C27.4911 29.5596 27.4125 29.3187 27.2937 28.987C27.1749 28.6568 27.085 28.4192 27.2022 28.156C27.2825 27.9742 27.4173 27.8403 27.6228 27.7653C28.0481 27.609 28.3691 27.8195 28.5328 28.1257L28.1717 28.2581C28.0658 28.0938 27.9245 28.0332 27.7415 28.1002C27.6436 28.1369 27.5778 28.2055 27.5489 28.2868C27.5072 28.3937 27.5216 28.4878 27.6532 28.8578C27.7849 29.2262 27.8346 29.3108 27.9341 29.3666C28.008 29.4096 28.1011 29.4176 28.199 29.3809C28.3065 29.3411 28.3804 29.2693 28.4157 29.18C28.4494 29.0875 28.443 28.9934 28.4076 28.8945L28.382 28.8211L28.0433 28.9455L27.9325 28.6329L28.6259 28.3777L28.7254 28.6568C28.8234 28.9344 28.8378 29.1194 28.7575 29.306Z"
        fill="url(#paint51_linear)"
      />
      <path
        d="M30.1079 28.954C29.758 29.1517 29.3439 29.0608 29.1368 28.6844L28.4932 27.5153L28.8254 27.3271L29.4626 28.4835C29.5766 28.6876 29.758 28.7466 29.9377 28.6445C30.1175 28.5425 30.1673 28.3543 30.0549 28.1501L29.4177 26.9938L29.7483 26.8072L30.3904 27.9763C30.6006 28.3511 30.4594 28.7562 30.1079 28.954Z"
        fill="url(#paint52_linear)"
      />
      <path
        d="M31.2491 28.2236L29.9795 26.643L30.2764 26.399L31.5445 27.978L31.2491 28.2236Z"
        fill="url(#paint53_linear)"
      />
      <path
        d="M32.6685 26.6774C32.6701 26.864 32.5818 27.0379 32.4373 27.183L31.9365 27.6854L30.5225 26.2372L31.0232 25.7348C31.1677 25.5897 31.3394 25.502 31.5256 25.5052C31.8402 25.5115 32.02 25.7268 32.2319 25.9437C32.4437 26.1623 32.6652 26.3584 32.6685 26.6774ZM31.5337 25.8991C31.4293 25.8879 31.3378 25.9182 31.2447 26.0123L31.0393 26.2181L31.9606 27.1623L32.1645 26.9565C32.2592 26.8624 32.2913 26.7699 32.28 26.6631C32.2672 26.5466 32.1741 26.435 31.959 26.2149C31.7455 25.9964 31.6476 25.9134 31.5337 25.8991Z"
        fill="url(#paint54_linear)"
      />
      <path
        d="M33.2179 26.2569L31.5454 25.1293L32.2581 24.0432L32.5486 24.2394L32.0478 25.0033L32.4394 25.2681L32.8664 24.6174L33.1569 24.8135L32.73 25.4643L33.1361 25.7386L33.6384 24.9746L33.9306 25.1708L33.2179 26.2569Z"
        fill="url(#paint55_linear)"
      />
      <path
        d="M25.2424 14.521C27.6154 14.521 29.6483 15.9452 30.5372 17.9787H30.8918C29.9836 15.7602 27.7999 14.194 25.2408 14.194C22.6817 14.194 20.498 15.7602 19.5898 17.9787H19.9444C20.8365 15.9452 22.8694 14.521 25.2424 14.521Z"
        fill="url(#paint56_linear)"
      />
      <path
        d="M25.2412 26.0047C22.8859 26.0047 20.8642 24.6012 19.9657 22.5916H19.6079C20.5273 24.7862 22.6997 26.3317 25.2412 26.3317C27.7827 26.3317 29.9552 24.7862 30.8745 22.5916H30.5183C29.6182 24.6012 27.5966 26.0047 25.2412 26.0047Z"
        fill="url(#paint57_linear)"
      />
      <path
        d="M34.2513 41.1087C34.2513 41.3543 34.057 41.5489 33.8034 41.5489C33.553 41.5489 33.354 41.3559 33.354 41.1087C33.354 40.8679 33.553 40.6749 33.8034 40.6749C34.057 40.6733 34.2513 40.8679 34.2513 41.1087ZM33.4664 41.1087C33.4664 41.3017 33.6108 41.4548 33.8066 41.4548C33.9976 41.4548 34.1389 41.3017 34.1389 41.1103C34.1389 40.9173 33.9976 40.761 33.8034 40.761C33.6092 40.761 33.4664 40.9173 33.4664 41.1087ZM33.7344 41.3352H33.6333V40.9014C33.6734 40.8934 33.7296 40.8886 33.8018 40.8886C33.8837 40.8886 33.9222 40.9014 33.9543 40.9205C33.9784 40.9397 33.9976 40.9732 33.9976 41.0162C33.9976 41.0641 33.9607 41.1007 33.9078 41.1167V41.1215C33.9495 41.1374 33.9752 41.1693 33.988 41.2267C34.0009 41.2937 34.0089 41.3192 34.0201 41.3352H33.911C33.8981 41.3192 33.8901 41.2794 33.8757 41.2299C33.8676 41.1821 33.8403 41.1614 33.7858 41.1614H33.7376V41.3352H33.7344ZM33.7376 41.0896H33.7858C33.842 41.0896 33.8869 41.072 33.8869 41.0258C33.8869 40.9859 33.858 40.9588 33.7938 40.9588C33.7665 40.9588 33.7489 40.962 33.7376 40.9636V41.0896Z"
        fill="#213A78"
      />
      <path
        d="M55.4509 30.4854L53.6458 27.2908L52.9864 28.0452V30.4838H51.0835V22.6959H52.9864V25.4519L55.2535 22.6959H57.6089L54.9342 25.8458L57.6955 30.4838H55.4509V30.4854Z"
        fill="#213A78"
      />
      <path
        d="M61.9018 30.4854H58.4907V22.6974H61.7366C63.3651 22.6974 64.2347 23.6049 64.2347 24.951C64.2347 25.8378 63.6186 26.329 63.3764 26.4933C63.6956 26.6911 64.3439 27.0962 64.3439 28.1887C64.3439 29.6768 63.2993 30.4854 61.9018 30.4854ZM61.6162 24.4152H60.4177V25.6847H61.6162C62.1441 25.6847 62.3094 25.3561 62.3094 25.0499C62.3094 24.7437 62.1441 24.4152 61.6162 24.4152ZM61.7141 27.4024H60.4161V28.7693H61.7141C62.242 28.7693 62.4185 28.4088 62.4185 28.0803C62.4185 27.7517 62.2436 27.4024 61.7141 27.4024Z"
        fill="#213A78"
      />
      <path
        d="M69.0898 30.4854H65.6787V22.6974H68.9246C70.5531 22.6974 71.4227 23.6049 71.4227 24.951C71.4227 25.8378 70.8066 26.329 70.5644 26.4933C70.8836 26.6911 71.5319 27.0962 71.5319 28.1887C71.5319 29.6768 70.4873 30.4854 69.0898 30.4854ZM68.8042 24.4152H67.6057V25.6847H68.8042C69.3321 25.6847 69.4974 25.3561 69.4974 25.0499C69.4974 24.7437 69.3321 24.4152 68.8042 24.4152ZM68.9021 27.4024H67.6041V28.7693H68.9021C69.43 28.7693 69.6065 28.4088 69.6065 28.0803C69.6065 27.7517 69.4316 27.4024 68.9021 27.4024Z"
        fill="#213A78"
      />
      <path
        d="M72.6997 30.4847V28.515H74.682V30.4847H72.6997Z"
        fill="#213A78"
      />
      <path
        d="M78.8309 30.5524C77.873 30.5524 77.2136 30.2573 76.652 29.6991C75.8481 28.9 75.8481 27.9048 75.8481 26.5922C75.8481 25.2796 75.8481 24.2844 76.652 23.4853C77.2136 22.9271 77.873 22.632 78.8309 22.632C80.3166 22.632 81.5055 23.4518 81.7911 25.1696H79.8321C79.7117 24.7325 79.4695 24.3386 78.8309 24.3386C78.4779 24.3386 78.2147 24.4805 78.0719 24.6672C77.8955 24.8968 77.7751 25.1696 77.7751 26.5922C77.7751 28.0149 77.8955 28.2876 78.0719 28.5173C78.2147 28.7039 78.4779 28.8458 78.8309 28.8458C79.4695 28.8458 79.7117 28.4519 79.8321 28.0149H81.7911C81.5039 29.7326 80.315 30.5524 78.8309 30.5524Z"
        fill="#213A78"
      />
      <path
        d="M87.769 29.701C87.2074 30.2592 86.548 30.5542 85.5901 30.5542C84.6323 30.5542 83.9728 30.2592 83.4113 29.701C82.6074 28.9019 82.6074 27.9067 82.6074 26.5941C82.6074 25.2815 82.6074 24.2862 83.4113 23.4872C83.9728 22.929 84.6323 22.6339 85.5901 22.6339C86.548 22.6339 87.2074 22.929 87.769 23.4872C88.5729 24.2862 88.5729 25.2815 88.5729 26.5941C88.5712 27.9067 88.5712 28.9019 87.769 29.701ZM86.3603 24.6802C86.2175 24.4936 85.9415 24.3405 85.5901 24.3405C85.2388 24.3405 84.9628 24.4936 84.82 24.6802C84.6435 24.9098 84.5328 25.1714 84.5328 26.5941C84.5328 28.0167 84.6435 28.2687 84.82 28.4984C84.9628 28.6834 85.2388 28.8477 85.5901 28.8477C85.9415 28.8477 86.2175 28.6834 86.3603 28.4984C86.5368 28.2687 86.6475 28.0167 86.6475 26.5941C86.6459 25.1714 86.5368 24.9098 86.3603 24.6802Z"
        fill="#213A78"
      />
      <path
        d="M95.2852 30.4854V26.7549L94.1733 28.6799H92.9411L91.8292 26.7549V30.4854H89.9038V22.6974H91.7971L93.5572 26.1313L95.3189 22.6974H97.2106V30.4854H95.2852Z"
        fill="#213A78"
      />
      <path
        d="M98.6333 30.4847V28.515H100.616V30.4847H98.6333Z"
        fill="#213A78"
      />
      <path
        d="M105.447 30.4854H102.036V22.6974H105.282C106.911 22.6974 107.78 23.6049 107.78 24.951C107.78 25.8378 107.164 26.329 106.922 26.4933C107.241 26.6911 107.891 27.0962 107.891 28.1887C107.891 29.6768 106.845 30.4854 105.447 30.4854ZM105.162 24.4152H103.962V25.6847H105.162C105.69 25.6847 105.855 25.3561 105.855 25.0499C105.855 24.7437 105.69 24.4152 105.162 24.4152ZM105.261 27.4024H103.962V28.7693H105.261C105.789 28.7693 105.966 28.4088 105.966 28.0803C105.966 27.7517 105.789 27.4024 105.261 27.4024Z"
        fill="#213A78"
      />
      <path
        d="M113.184 30.4854L111.797 27.5874H111.148V30.4854H109.222V22.6974H112.325C114.064 22.6974 115 23.9223 115 25.203C115 26.2637 114.339 26.9319 113.723 27.2381L115.417 30.487H113.184V30.4854ZM112.237 24.4152H111.148V25.9909H112.237C112.766 25.9909 113.075 25.6081 113.075 25.203C113.075 24.7979 112.766 24.4152 112.237 24.4152Z"
        fill="#213A78"
      />
      <path
        d="M47.1026 18.573L44.9414 14.8027L43.645 16.3497V18.573H42.4785V10.927H43.645V14.7724L46.7897 10.927H48.2161L45.7196 13.9239L48.4744 18.5746H47.1026V18.573Z"
        fill="#213A78"
      />
      <path
        d="M50.3025 16.1674C50.3025 17.102 50.7999 17.6921 51.6968 17.6921C52.3129 17.6921 52.637 17.5199 53.0253 17.1339L53.728 17.7894C53.1665 18.3477 52.637 18.6379 51.6743 18.6379C50.3025 18.6379 49.2114 17.9186 49.2114 15.8452C49.2114 14.0845 50.1292 13.0637 51.5347 13.0637C53.0044 13.0637 53.858 14.1371 53.858 15.6842V16.1674H50.3025ZM52.6161 14.6634C52.4428 14.2551 52.0546 13.976 51.5347 13.976C51.0165 13.976 50.6169 14.2551 50.4437 14.6634C50.3362 14.9106 50.3137 15.0717 50.3025 15.405H52.7653C52.7557 15.0717 52.7236 14.9106 52.6161 14.6634Z"
        fill="#213A78"
      />
      <path
        d="M56.8183 18.5725C55.7702 18.5725 55.3159 17.8739 55.3159 17.0685V10.9265H56.4186V17.0063C56.4186 17.4258 56.5711 17.6395 57.0125 17.6395H57.4988V18.5741H56.8183V18.5725Z"
        fill="#213A78"
      />
      <path
        d="M60.2128 18.5725C59.1647 18.5725 58.7104 17.8739 58.7104 17.0685V10.9265H59.8131V17.0063C59.8131 17.4258 59.9656 17.6395 60.4086 17.6395H60.895V18.5741H60.2128V18.5725Z"
        fill="#213A78"
      />
      <path
        d="M62.6858 16.1674C62.6858 17.102 63.1832 17.6921 64.0801 17.6921C64.6962 17.6921 65.0203 17.5199 65.4086 17.1339L66.1113 17.7894C65.5498 18.3477 65.0203 18.6379 64.0576 18.6379C62.6858 18.6379 61.5947 17.9186 61.5947 15.8452C61.5947 14.0845 62.5125 13.0637 63.918 13.0637C65.3877 13.0637 66.2413 14.1371 66.2413 15.6842V16.1674H62.6858ZM64.9978 14.6634C64.8245 14.2551 64.4363 13.976 63.918 13.976C63.3982 13.976 62.9986 14.2551 62.827 14.6634C62.7195 14.9106 62.697 15.0717 62.6858 15.405H65.1486C65.1374 15.0717 65.1053 14.9106 64.9978 14.6634Z"
        fill="#213A78"
      />
      <path
        d="M69.3514 19.6612C69.2439 19.9626 69.114 20.1764 68.9519 20.3374C68.6503 20.6389 68.2491 20.7346 67.8288 20.7346H67.3859V19.7457H67.6667C68.132 19.7457 68.2941 19.6054 68.4561 19.1652L68.7578 18.3167L66.8452 13.1301H68.0117L69.3193 16.9642L70.5949 13.1301H71.7726L69.3514 19.6612Z"
        fill="#213A78"
      />
      <path
        d="M78.5883 18.5725H75.4756V10.9265H78.4679C79.8943 10.9265 80.7912 11.7319 80.7912 13.0206C80.7912 13.8484 80.273 14.4385 79.7547 14.6426C80.3484 14.8787 80.91 15.4481 80.91 16.4146C80.9116 17.8213 79.9505 18.5725 78.5883 18.5725ZM78.3733 11.968H76.6436V14.1594H78.3733C79.1194 14.1594 79.6264 13.7734 79.6264 13.0637C79.6264 12.3539 79.1178 11.968 78.3733 11.968ZM78.4808 15.2009H76.6436V17.531H78.4808C79.291 17.531 79.7451 17.0366 79.7451 16.3604C79.7451 15.6841 79.291 15.2009 78.4808 15.2009Z"
        fill="#213A78"
      />
      <path
        d="M84.0038 18.5725C82.9557 18.5725 82.5015 17.8739 82.5015 17.0685V10.9265H83.6042V17.0063C83.6042 17.4258 83.755 17.6395 84.1981 17.6395H84.6844V18.5741H84.0038V18.5725Z"
        fill="#213A78"
      />
      <path
        d="M89.2328 18.5751V18.028C88.8542 18.4363 88.3247 18.6405 87.7744 18.6405C87.2128 18.6405 86.7475 18.4586 86.4234 18.146C86.003 17.7377 85.873 17.2226 85.873 16.6101V13.1301H86.9753V16.4491C86.9753 17.2975 87.4615 17.6628 88.0776 17.6628C88.6937 17.6628 89.212 17.2864 89.212 16.4491V13.1301H90.3142V18.5751H89.2328Z"
        fill="#213A78"
      />
      <path
        d="M92.9045 16.1674C92.9045 17.102 93.4019 17.6921 94.2988 17.6921C94.915 17.6921 95.2391 17.5199 95.6273 17.1339L96.3301 17.7894C95.7685 18.3477 95.2391 18.6379 94.2764 18.6379C92.9045 18.6379 91.8135 17.9186 91.8135 15.8452C91.8135 14.0845 92.7312 13.0637 94.1368 13.0637C95.6065 13.0637 96.4601 14.1371 96.4601 15.6842V16.1674H92.9045ZM95.2166 14.6634C95.0433 14.2551 94.655 13.976 94.1368 13.976C93.6185 13.976 93.2174 14.2551 93.0457 14.6634C92.9382 14.9106 92.9158 15.0717 92.9045 15.405H95.3674C95.3578 15.0717 95.3257 14.9106 95.2166 14.6634Z"
        fill="#213A78"
      />
      <path
        d="M103.819 18.5725H100.707V10.9265H103.699C105.125 10.9265 106.022 11.7319 106.022 13.0206C106.022 13.8484 105.504 14.4385 104.986 14.6426C105.579 14.8787 106.141 15.4481 106.141 16.4146C106.141 17.8213 105.18 18.5725 103.819 18.5725ZM103.603 11.968H101.873V14.1594H103.603C104.349 14.1594 104.856 13.7734 104.856 13.0637C104.856 12.3539 104.347 11.968 103.603 11.968ZM103.71 15.2009H101.873V17.531H103.71C104.52 17.531 104.974 17.0366 104.974 16.3604C104.974 15.6841 104.52 15.2009 103.71 15.2009Z"
        fill="#213A78"
      />
      <path
        d="M111.435 17.9936C111.078 18.3588 110.538 18.6379 109.792 18.6379C109.046 18.6379 108.518 18.3588 108.16 17.9936C107.642 17.4561 107.512 16.7687 107.512 15.8452C107.512 14.9314 107.642 14.244 108.16 13.7081C108.516 13.3428 109.046 13.0637 109.792 13.0637C110.538 13.0637 111.077 13.3428 111.435 13.7081C111.953 14.2455 112.083 14.933 112.083 15.8452C112.083 16.7687 111.953 17.4561 111.435 17.9936ZM110.602 14.3524C110.397 14.1483 110.116 14.0414 109.792 14.0414C109.468 14.0414 109.198 14.1483 108.993 14.3524C108.657 14.6857 108.614 15.2551 108.614 15.8452C108.614 16.4354 108.657 17.0047 108.993 17.3381C109.198 17.5422 109.468 17.6603 109.792 17.6603C110.116 17.6603 110.397 17.5422 110.602 17.3381C110.937 17.0047 110.981 16.4354 110.981 15.8452C110.981 15.2535 110.937 14.6857 110.602 14.3524Z"
        fill="#213A78"
      />
      <path
        d="M117.27 17.9936C116.914 18.3588 116.373 18.6379 115.629 18.6379C114.883 18.6379 114.353 18.3588 113.997 17.9936C113.479 17.4561 113.349 16.7687 113.349 15.8452C113.349 14.9314 113.479 14.244 113.997 13.7081C114.353 13.3428 114.883 13.0637 115.629 13.0637C116.375 13.0637 116.914 13.3428 117.27 13.7081C117.79 14.2455 117.918 14.933 117.918 15.8452C117.92 16.7687 117.79 17.4561 117.27 17.9936ZM116.439 14.3524C116.233 14.1483 115.953 14.0414 115.629 14.0414C115.304 14.0414 115.035 14.1483 114.83 14.3524C114.494 14.6857 114.451 15.2551 114.451 15.8452C114.451 16.4354 114.494 17.0047 114.83 17.3381C115.035 17.5422 115.304 17.6603 115.629 17.6603C115.953 17.6603 116.233 17.5422 116.439 17.3381C116.773 17.0047 116.818 16.4354 116.818 15.8452C116.818 15.2535 116.774 14.6857 116.439 14.3524Z"
        fill="#213A78"
      />
      <path
        d="M122.779 18.5725L121.246 16.0589L120.435 16.9712V18.5709H119.333V10.9265H120.435V15.641L122.564 13.1275H123.904L122.003 15.2647L124.142 18.5725H122.779Z"
        fill="#213A78"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -7.96709) scale(17.0622 22.3115)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.9878 23.1842) scale(16.0241 15.9286)"
        >
          <stop offset="0.00495866" stopColor="#DBE0ED" />
          <stop offset="0.4172" stopColor="#18659C" />
          <stop offset="0.7669" stopColor="#094B7F" />
          <stop offset="1" stopColor="#223669" />
        </radialGradient>
        <linearGradient
          id="paint2_linear"
          x1="24.5508"
          y1="21.8925"
          x2="39.1613"
          y2="42.1737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2353" stopColor="#F5D36D" />
          <stop offset="0.4133" stopColor="#E6B741" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5283" stopColor="#B37418" />
          <stop offset="0.5694" stopColor="#F4D96C" />
          <stop offset="0.6796" stopColor="#B37418" />
          <stop offset="0.7733" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="14.8966"
          y1="23.865"
          x2="27.8147"
          y2="39.4264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint5_linear"
          x1="25.2493"
          y1="-4.12207"
          x2="28.6323"
          y2="5.97447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="13.7103"
          y1="-0.254339"
          x2="17.0934"
          y2="9.84228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint7_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint8_linear"
          x1="25.2493"
          y1="-4.12207"
          x2="28.6323"
          y2="5.97447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="13.7103"
          y1="-0.254339"
          x2="17.0934"
          y2="9.84228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint10_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint11_linear"
          x1="25.2493"
          y1="-4.12207"
          x2="28.6323"
          y2="5.97447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="13.7103"
          y1="-0.254339"
          x2="17.0934"
          y2="9.84228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint13_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint14_linear"
          x1="25.2473"
          y1="-4.12253"
          x2="28.6303"
          y2="5.97402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="13.7108"
          y1="-0.254781"
          x2="17.0938"
          y2="9.84184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint16_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint17_linear"
          x1="25.2473"
          y1="-4.12253"
          x2="28.6303"
          y2="5.97402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="13.7108"
          y1="-0.254781"
          x2="17.0938"
          y2="9.84184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint19_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -5.47243) scale(17.0623 16.9606)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.9432" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint20_linear"
          x1="25.2473"
          y1="-4.12253"
          x2="28.6303"
          y2="5.97402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="13.7108"
          y1="-0.254781"
          x2="17.0938"
          y2="9.84184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <radialGradient
          id="paint22_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.9484 -7.46497) scale(20.4137 20.292)"
        >
          <stop stopColor="#3665AC" />
          <stop offset="0.5124" stopColor="#3665AC" />
          <stop offset="0.8344" stopColor="#233971" />
        </radialGradient>
        <linearGradient
          id="paint23_linear"
          x1="25.2473"
          y1="-4.12253"
          x2="28.6303"
          y2="5.97402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="13.7108"
          y1="-0.254781"
          x2="17.0938"
          y2="9.84184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="25.2473"
          y1="-4.12253"
          x2="28.6303"
          y2="5.97402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="13.7108"
          y1="-0.254781"
          x2="17.0938"
          y2="9.84184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#B37418" />
          <stop offset="0.6528" stopColor="#B37418" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="15.9458"
          y1="1.89835"
          x2="29.9348"
          y2="29.7857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8E579" />
          <stop offset="0.3763" stopColor="#F5D36D" />
          <stop offset="0.4657" stopColor="#EEC450" />
          <stop offset="0.5676" stopColor="#E6B741" />
          <stop offset="0.6237" stopColor="#E39B25" />
          <stop offset="0.6528" stopColor="#D8970C" />
          <stop offset="0.7152" stopColor="#F4D96C" />
          <stop offset="0.7921" stopColor="#F8E579" />
          <stop offset="0.8441" stopColor="#FBF3AA" />
          <stop offset="0.8919" stopColor="#FFF4CE" />
          <stop offset="0.9376" stopColor="#E4AE00" />
          <stop offset="0.9439" stopColor="#E4AE00" />
          <stop offset="1" stopColor="#F5BE3E" />
        </linearGradient>
        <radialGradient
          id="paint28_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.7662 5.43189) scale(22.9628 22.8259)"
        >
          <stop offset="0.1413" stopColor="#497FC1" />
          <stop offset="0.2888" stopColor="#457BBE" />
          <stop offset="0.49" stopColor="#3C6EB4" />
          <stop offset="0.6128" stopColor="#3665AC" />
          <stop offset="0.6854" stopColor="#335DA1" />
          <stop offset="0.8093" stopColor="#2C4C85" />
          <stop offset="0.9687" stopColor="#21345E" />
          <stop offset="1" stopColor="#1E3057" />
        </radialGradient>
        <linearGradient
          id="paint29_linear"
          x1="24.2667"
          y1="2.69575"
          x2="39.9848"
          y2="26.5237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="23.799"
          y1="3.10229"
          x2="39.8007"
          y2="26.6929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="21.6289"
          y1="4.60162"
          x2="39.1159"
          y2="26.8052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="19.548"
          y1="5.87748"
          x2="37.1284"
          y2="28.4025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="17.6197"
          y1="7.37682"
          x2="35.6682"
          y2="29.5507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="14.7905"
          y1="9.86575"
          x2="33.8199"
          y2="31.1487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="23.8414"
          y1="5.05123"
          x2="38.8698"
          y2="26.2452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="21.8793"
          y1="6.27643"
          x2="37.2117"
          y2="27.8991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="20.2477"
          y1="7.70572"
          x2="35.5808"
          y2="29.3293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="18.5626"
          y1="9.37138"
          x2="33.7261"
          y2="30.7558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint39_linear"
          x1="16.6322"
          y1="11.0631"
          x2="31.7797"
          y2="32.4251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint40_linear"
          x1="15.1511"
          y1="12.3529"
          x2="30.3346"
          y2="33.7656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint41_linear"
          x1="13.014"
          y1="14.0963"
          x2="28.1709"
          y2="35.4715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint42_linear"
          x1="11.7248"
          y1="15.263"
          x2="26.8885"
          y2="36.6477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint43_linear"
          x1="9.87615"
          y1="10.4364"
          x2="24.1038"
          y2="37.3853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint44_linear"
          x1="10.0278"
          y1="10.4353"
          x2="24.4837"
          y2="37.3154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint45_linear"
          x1="10.1"
          y1="10.6697"
          x2="25.4026"
          y2="37.0163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint46_linear"
          x1="9.84898"
          y1="10.8646"
          x2="26.3782"
          y2="36.6776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint47_linear"
          x1="10.2935"
          y1="10.7951"
          x2="27.0405"
          y2="36.1926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint48_linear"
          x1="10.3959"
          y1="10.717"
          x2="27.9236"
          y2="35.7197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint49_linear"
          x1="10.7192"
          y1="10.5765"
          x2="28.4074"
          y2="35.2956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint50_linear"
          x1="11.9505"
          y1="9.70489"
          x2="29.2768"
          y2="34.5999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint51_linear"
          x1="11.972"
          y1="9.64081"
          x2="31.306"
          y2="33.0041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint52_linear"
          x1="13.5393"
          y1="8.51898"
          x2="32.6624"
          y2="31.9453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint53_linear"
          x1="13.4649"
          y1="8.6384"
          x2="33.8806"
          y2="30.9609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint54_linear"
          x1="14.6843"
          y1="7.40746"
          x2="34.7867"
          y2="30.1872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint55_linear"
          x1="14.9226"
          y1="7.13177"
          x2="36.1418"
          y2="28.8658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1CA" />
          <stop offset="0.1767" stopColor="#F8ECA3" />
          <stop offset="0.2933" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint56_linear"
          x1="18.0694"
          y1="9.41206"
          x2="33.8343"
          y2="27.9333"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1656" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
        <linearGradient
          id="paint57_linear"
          x1="15.543"
          y1="11.5613"
          x2="31.3084"
          y2="30.0831"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1656" stopColor="#F5D36D" />
          <stop offset="0.4" stopColor="#FBF3AA" />
          <stop offset="0.4311" stopColor="#EEC450" />
          <stop offset="0.5289" stopColor="#E6B741" />
          <stop offset="0.6044" stopColor="#F4D96C" />
          <stop offset="0.6489" stopColor="#F8E579" />
          <stop offset="0.8089" stopColor="#E4AE00" />
          <stop offset="0.8889" stopColor="#E6B300" />
          <stop offset="0.9511" stopColor="#FFF4CE" />
          <stop offset="1" stopColor="#FBCB40" />
        </linearGradient>
      </defs>
    </svg>
  </>
);

const LogoKbbHome = () => (
  <svg
    width="205"
    height="72"
    viewBox="0 0 205 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.3857 44.6902V0H40.138V44.6902" fill="url(#paint0_radial)" />
    <path
      d="M12.3809 37.3389V71.3104L26.2744 60.6178L40.1621 71.3104V37.3389H12.3809Z"
      fill="url(#paint1_radial)"
    />
    <path
      d="M38.5104 70.0459V37.333H37.1826L37.1913 69.028"
      fill="url(#paint2_linear)"
    />
    <path
      d="M15.3404 69.0454V24.9033H14.0127L14.0098 70.0749"
      fill="url(#paint3_linear)"
    />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint4_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint5_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint6_linear)" />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint7_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint8_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint9_linear)" />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint10_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint11_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint12_linear)" />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint13_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint14_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint15_linear)" />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint16_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint17_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint18_linear)" />
    <path d="M12.3857 33.9715V0H40.138V33.9715" fill="url(#paint19_radial)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint20_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint21_linear)" />
    <path
      d="M40.1409 0H12.3857V42.5325H40.1409V0Z"
      fill="url(#paint22_radial)"
    />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint23_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint24_linear)" />
    <path d="M38.5104 45.2325V0H37.1826V45.2325" fill="url(#paint25_linear)" />
    <path d="M15.3404 45.2325V0H14.0127V45.2325" fill="url(#paint26_linear)" />
    <path
      d="M44.8451 51.0653L40.7808 50.1692L40.8618 54.3395L37.0522 52.6661L36.3204 56.7726L32.907 54.3859L31.3912 58.272L28.5101 55.2646L26.2654 58.7766L24.0236 55.2617L21.1396 58.2662L19.6268 54.383L16.2134 56.7668L15.4816 52.6603L11.669 54.3308L11.7558 50.1605L7.68871 51.0537L8.58255 46.982L4.42578 47.0632L6.09196 43.2438L1.99883 42.5071L4.37661 39.088L0.503324 37.5683L3.50302 34.6769L0 32.4294L3.50591 30.1818L0.506217 27.2904L4.3795 25.7708L2.00173 22.3487L6.09775 21.6179L4.43157 17.7956L8.59123 17.8797L7.70029 13.8051L11.7616 14.7012L11.6806 10.5309L15.4931 12.2013L16.2221 8.09773L19.6354 10.4845L21.1541 6.59839L24.0352 9.60867L26.2799 6.09668L28.5217 9.60867L31.4057 6.60129L32.9186 10.4874L36.3319 8.10353L37.0609 12.21L40.8734 10.5367L40.7895 14.707L44.8537 13.8138L43.9599 17.8884L48.1196 17.8043L46.4505 21.6266L50.5465 22.3603L48.1687 25.7824L52.042 27.302L49.0394 30.1905L52.5453 32.441L49.0394 34.6885L52.0391 37.5799L48.1629 39.0967L50.5407 42.5187L46.4447 43.2525L48.1138 47.0719L43.9541 46.9907L44.8451 51.0653Z"
      fill="#213A78"
    />
    <path
      d="M44.7135 50.9289L40.6782 50.0415L40.7592 54.1799L36.9756 52.5211L36.2495 56.5957L32.8593 54.2292L31.3551 58.0863L28.4943 55.0993L26.2669 58.5851L24.0424 55.0963L21.1787 58.0805L19.6745 54.2234L16.2872 56.5928L15.5611 52.5153L11.7775 54.1741L11.8614 50.0328L7.82614 50.9202L8.71129 46.8775L4.58345 46.9587L6.23806 43.1654L2.17386 42.4346L4.53717 39.0415L0.689922 37.5306L3.66937 34.6624L0.192383 32.4293L3.67226 30.1992L0.692815 27.3281L4.54006 25.8201L2.17965 22.4212L6.24385 21.6962L4.58924 17.9L8.71997 17.9841L7.83481 13.9414L11.8672 14.8317L11.7891 10.6904L15.5727 12.3492L16.2959 8.27171L19.6861 10.644L21.1903 6.78687L24.054 9.77395L26.2814 6.28516L28.5058 9.77395L31.3696 6.78977L32.8738 10.6469L36.2611 8.27751L36.9842 12.355L40.7707 10.6962L40.6869 14.8346L44.7221 13.9501L43.8341 17.9928L47.9648 17.9116L46.3073 21.7049L50.3744 22.4328L48.0111 25.8317L51.8583 27.3397L48.8789 30.2079L52.3559 32.441L48.876 34.6711L51.8554 37.5422L48.0082 39.0502L50.3686 42.4491L46.3015 43.1741L47.9561 46.9674L43.8254 46.8862L44.7135 50.9289Z"
      fill="url(#paint27_linear)"
    />
    <path
      d="M26.2595 10.7158C14.2752 10.7158 4.56738 20.4572 4.56738 32.4664C4.56738 44.4669 14.2752 54.1909 26.2595 54.1909C38.2381 54.1909 47.9661 44.4669 47.9661 32.4664C47.9661 20.4572 38.241 10.7158 26.2595 10.7158Z"
      fill="url(#paint28_radial)"
      stroke="#F8E579"
      strokeWidth="0.125"
      strokeMiterlimit="10"
    />
    <path
      d="M39.3432 23.9947L37.2489 25.4593L36.5547 24.4703L38.649 23.0058L40.5842 19.3662L41.3421 20.4479L39.9536 22.8405L42.6698 22.3388L43.4277 23.4205L39.3432 23.9947Z"
      fill="url(#paint29_linear)"
    />
    <path
      d="M32.6641 20.927L36.439 15.9736L39.7048 18.4561L39.0511 19.3174L36.7514 17.5716L35.8662 18.7345L37.8246 20.2222L37.1651 21.0865L35.2067 19.5987L34.2897 20.8023L36.5865 22.5481L35.9299 23.4123L32.6641 20.927Z"
      fill="url(#paint30_linear)"
    />
    <path
      d="M28.2959 19.4426L29.965 13.4424L31.1365 13.7672L29.7596 18.7205L32.4845 19.4774L32.1923 20.5215L28.2959 19.4426Z"
      fill="url(#paint31_linear)"
    />
    <path
      d="M23.5924 19.585L23.0312 13.3846L24.2433 13.2744L24.7061 18.396L27.5236 18.1436L27.6219 19.2225L23.5924 19.585Z"
      fill="url(#paint32_linear)"
    />
    <path
      d="M19.04 21.4169L16.4424 15.756L20.1768 14.0479L20.6281 15.0339L18.0015 16.2374L18.609 17.5656L20.845 16.5419L21.2963 17.5279L19.0602 18.5517L19.6908 19.9263L22.3174 18.7257L22.7686 19.7117L19.04 21.4169Z"
      fill="url(#paint33_linear)"
    />
    <path
      d="M17.6195 22.7539L14.4347 22.0753L14.6227 23.3136L16.0256 24.5519L15.2186 25.4625L10.5498 21.3444L11.3569 20.4338L13.3904 22.229L12.8119 18.7895L13.7925 17.6816L14.2785 20.8659L18.5567 21.6924L17.6195 22.7539Z"
      fill="url(#paint34_linear)"
    />
    <path
      d="M44.4982 35.9024L43.0201 32.7703L42.2766 33.8317V35.8995H41.1543V29.0205H42.2766V32.0163L44.3044 29.0205H45.6698L43.7635 31.7553L45.8057 35.8995H44.4982V35.9024Z"
      fill="url(#paint35_linear)"
    />
    <path
      d="M39.8437 35.2068C39.4387 35.6882 38.9469 35.9608 38.2932 35.9608C37.6395 35.9608 37.139 35.6911 36.734 35.2068C36.1526 34.5108 36.17 33.6524 36.17 32.4633C36.17 31.2743 36.1526 30.4159 36.734 29.7198C37.139 29.2384 37.6395 28.9658 38.2932 28.9658C38.9469 28.9658 39.4416 29.2384 39.8437 29.7198C40.4251 30.4159 40.4164 31.2743 40.4164 32.4633C40.4164 33.6524 40.4251 34.5108 39.8437 35.2068ZM39.0135 30.5319C38.8515 30.3115 38.6027 30.1665 38.2961 30.1665C37.9895 30.1665 37.7291 30.3115 37.57 30.5319C37.3531 30.8219 37.2952 31.1409 37.2952 32.4633C37.2952 33.7858 37.3502 34.1048 37.57 34.3948C37.732 34.6181 37.9895 34.7631 38.2961 34.7631C38.6027 34.7631 38.8544 34.6181 39.0135 34.3948C39.2304 34.1048 39.297 33.7858 39.297 32.4633C39.2941 31.1409 39.2304 30.8219 39.0135 30.5319Z"
      fill="url(#paint36_linear)"
    />
    <path
      d="M35.0536 35.2068C34.6486 35.6882 34.1569 35.9608 33.5032 35.9608C32.8494 35.9608 32.349 35.6911 31.944 35.2068C31.3626 34.5108 31.3799 33.6524 31.3799 32.4633C31.3799 31.2743 31.3626 30.4159 31.944 29.7198C32.349 29.2384 32.8494 28.9658 33.5032 28.9658C34.1569 28.9658 34.6486 29.2384 35.0536 29.7198C35.635 30.4159 35.6264 31.2743 35.6264 32.4633C35.6293 33.6524 35.6379 34.5108 35.0536 35.2068ZM34.2234 30.5319C34.0614 30.3115 33.8127 30.1665 33.506 30.1665C33.1994 30.1665 32.9391 30.3115 32.78 30.5319C32.563 30.8219 32.5052 31.1409 32.5052 32.4633C32.5052 33.7858 32.5601 34.1048 32.78 34.3948C32.942 34.6181 33.1994 34.7631 33.506 34.7631C33.8127 34.7631 34.0643 34.6181 34.2234 34.3948C34.4404 34.1048 34.5069 33.7858 34.5069 32.4633C34.5069 31.1409 34.4404 30.8219 34.2234 30.5319Z"
      fill="url(#paint37_linear)"
    />
    <path
      d="M29.0766 35.9024H26.6699V29.0234H28.9783C30.1006 29.0234 30.7544 29.7775 30.7544 30.9549C30.7544 31.7176 30.3349 32.2106 30.0457 32.3759C30.3928 32.5702 30.8383 33.0023 30.8383 33.9217C30.8383 35.2064 30.1035 35.9024 29.0766 35.9024ZM28.8915 30.2212H27.7923V31.8046H28.8915C29.3688 31.8046 29.6349 31.4856 29.6349 31.0129C29.6349 30.5402 29.3659 30.2212 28.8915 30.2212ZM28.9638 33.0139H27.7923V34.7047H28.9638C29.4729 34.7047 29.7159 34.319 29.7159 33.855C29.7159 33.3909 29.4729 33.0139 28.9638 33.0139Z"
      fill="url(#paint38_linear)"
    />
    <path
      d="M20.7354 35.9024V29.0234H24.5219V30.2212H21.8577V31.8336H24.1285V33.0313H21.8577V34.7018H24.5219V35.8995H20.7354V35.9024Z"
      fill="url(#paint39_linear)"
    />
    <path
      d="M17.7786 35.9604C16.5984 35.9604 15.6611 35.0034 15.6611 33.5447V29.0234H16.7835V33.4954C16.7835 34.2871 17.1711 34.7598 17.7786 34.7598C18.3831 34.7598 18.7794 34.2871 18.7794 33.4954V29.0234H19.8931V33.5447C19.8931 35.0034 18.9559 35.9604 17.7786 35.9604Z"
      fill="url(#paint40_linear)"
    />
    <path
      d="M11.6172 35.9024V29.0234H12.7395V34.7047H15.3487V35.9024H11.6172Z"
      fill="url(#paint41_linear)"
    />
    <path
      d="M9.12057 35.9024H6.71387V29.0234H9.02222C10.1446 29.0234 10.7983 29.7775 10.7983 30.9549C10.7983 31.7176 10.3789 32.2106 10.0867 32.3759C10.4338 32.5702 10.8793 33.0023 10.8793 33.9217C10.8822 35.2064 10.1446 35.9024 9.12057 35.9024ZM8.93544 30.2212H7.83622V31.8046H8.93544C9.41273 31.8046 9.67885 31.4856 9.67885 31.0129C9.67885 30.5402 9.40983 30.2212 8.93544 30.2212ZM9.00775 33.0139H7.83622V34.7047H9.00775C9.51686 34.7047 9.75985 34.319 9.75985 33.855C9.75985 33.3909 9.51686 33.0139 9.00775 33.0139Z"
      fill="url(#paint42_linear)"
    />
    <path
      d="M11.7901 42.4545C11.4401 42.3936 11.1508 42.2225 10.9252 41.8861C10.6967 41.5497 10.6388 41.2075 10.7082 40.8536C10.8066 40.3403 11.1855 40.0851 11.6975 39.7226C12.2124 39.3601 12.5798 39.0875 13.0831 39.1745C13.4331 39.2354 13.7253 39.4094 13.9538 39.7458C14.1823 40.0822 14.2373 40.4186 14.1679 40.7753C14.0695 41.2858 13.6935 41.5468 13.1786 41.9093C12.6637 42.2747 12.2934 42.5415 11.7901 42.4545ZM13.5228 40.5955C13.5633 40.4447 13.5402 40.2707 13.4302 40.1141C13.3232 39.9546 13.1699 39.8676 13.0166 39.8531C12.8141 39.827 12.6579 39.8966 12.0852 40.3026C11.5124 40.7057 11.3938 40.8334 11.3446 41.0335C11.3041 41.1843 11.3331 41.3641 11.4372 41.5207C11.5442 41.6773 11.6946 41.7643 11.848 41.7788C12.0504 41.802 12.2095 41.7382 12.7823 41.3322C13.3579 40.9291 13.4736 40.7985 13.5228 40.5955Z"
      fill="url(#paint43_linear)"
    />
    <path
      d="M14.8823 42.2022L14.2372 42.8315L15.1976 43.861L14.7405 44.3048L13.7801 43.2781L12.7185 44.3164L12.2441 43.8088L14.862 41.251L16.4617 42.9649L16.0046 43.4115L14.8823 42.2022Z"
      fill="url(#paint44_linear)"
    />
    <path
      d="M17.0348 44.2502L16.5083 44.9868L17.6307 45.825L17.2575 46.347L16.1351 45.5089L15.2702 46.7211L14.7148 46.3064L16.8525 43.3164L18.7241 44.7142L18.3538 45.2363L17.0348 44.2502Z"
      fill="url(#paint45_linear)"
    />
    <path
      d="M17.4971 48.1881L19.1575 44.8994L19.7678 45.2213L18.1045 48.51L17.4971 48.1881Z"
      fill="url(#paint46_linear)"
    />
    <path
      d="M20.0687 49.3691C19.7071 49.2328 19.4584 48.9834 19.3137 48.6528C19.1054 48.1743 19.2703 47.745 19.4844 47.1418C19.6984 46.5415 19.846 46.1036 20.3059 45.8745C20.6241 45.7179 20.9712 45.6918 21.3328 45.8252C21.9518 46.0572 22.3018 46.6082 22.1572 47.3796L21.5035 47.136C21.5382 46.8315 21.4485 46.556 21.1216 46.4342C20.9452 46.3675 20.7774 46.382 20.6443 46.4603C20.465 46.5618 20.3695 46.7126 20.1295 47.3825C19.8894 48.0525 19.8691 48.2323 19.9414 48.4266C19.9935 48.5745 20.115 48.6963 20.2914 48.763C20.6183 48.8848 20.8613 48.7311 21.0262 48.4759L21.6828 48.7195C21.301 49.4068 20.682 49.5953 20.0687 49.3691Z"
      fill="url(#paint47_linear)"
    />
    <path
      d="M22.0996 49.9913L22.843 46.3662L23.5141 46.5112L22.7707 50.1363L22.0996 49.9913Z"
      fill="url(#paint48_linear)"
    />
    <path
      d="M25.574 50.479L25.4178 49.8062L24.1711 49.7047L23.9078 50.3398L23.1934 50.2818L24.7612 46.7002L25.2992 46.7437L26.2885 50.537L25.574 50.479ZM24.9608 47.808L24.4112 49.1131L25.2848 49.1856L24.9608 47.808Z"
      fill="url(#paint49_linear)"
    />
    <path
      d="M26.7415 50.4755L26.5332 46.7808L27.2188 46.7402L27.3923 49.7911L28.9862 49.6983L29.0238 50.3421L26.7415 50.4755Z"
      fill="url(#paint50_linear)"
    />
    <path
      d="M32.5511 48.8606C32.3891 49.2463 32.1114 49.4725 31.7556 49.603C31.394 49.7364 31.0469 49.7103 30.7287 49.5508C30.2688 49.3217 30.127 48.8838 29.913 48.2806C29.6989 47.6803 29.5369 47.2482 29.7481 46.7697C29.8927 46.4391 30.1357 46.1955 30.506 46.0592C31.2725 45.7749 31.8511 46.1578 32.1461 46.7146L31.4953 46.9553C31.3044 46.6566 31.0498 46.5464 30.72 46.6682C30.5436 46.7349 30.425 46.8596 30.3729 47.0075C30.2977 47.2018 30.3237 47.3729 30.5609 48.0457C30.7981 48.7156 30.8878 48.8693 31.0672 48.9708C31.2002 49.0491 31.368 49.0636 31.5445 48.9969C31.7383 48.9244 31.8713 48.7939 31.935 48.6315C31.9957 48.4633 31.9841 48.2922 31.9205 48.1124L31.8742 47.979L31.2639 48.2052L31.0643 47.6368L32.3139 47.1728L32.4932 47.6803C32.6697 48.1849 32.6957 48.5213 32.5511 48.8606Z"
      fill="url(#paint51_linear)"
    />
    <path
      d="M34.9862 48.2199C34.3556 48.5795 33.6093 48.4142 33.2361 47.7298L32.0762 45.604L32.675 45.2618L33.8233 47.3644C34.0287 47.7356 34.3556 47.8429 34.6796 47.6573C35.0036 47.4717 35.0932 47.1295 34.8907 46.7583L33.7424 44.6557L34.3382 44.3164L35.4953 46.4422C35.8742 47.1237 35.6197 47.8603 34.9862 48.2199Z"
      fill="url(#paint52_linear)"
    />
    <path
      d="M37.0401 46.8948L34.752 44.0209L35.2871 43.5771L37.5723 46.4482L37.0401 46.8948Z"
      fill="url(#paint53_linear)"
    />
    <path
      d="M39.5999 44.0809C39.6028 44.4202 39.4437 44.7363 39.1834 45.0002L38.2809 45.9138L35.7324 43.2805L36.6349 42.367C36.8953 42.1031 37.2048 41.9436 37.5403 41.9494C38.1073 41.961 38.4313 42.3525 38.8131 42.7469C39.1949 43.1442 39.5941 43.5009 39.5999 44.0809ZM37.5548 42.6657C37.3668 42.6454 37.2019 42.7005 37.0341 42.8716L36.6639 43.2457L38.3242 44.9625L38.6916 44.5884C38.8623 44.4173 38.9201 44.2491 38.8999 44.0548C38.8767 43.8431 38.709 43.6401 38.3214 43.2399C37.9366 42.8426 37.7602 42.6918 37.5548 42.6657Z"
      fill="url(#paint54_linear)"
    />
    <path
      d="M40.5894 43.3163L37.5752 41.266L38.8595 39.291L39.3831 39.6477L38.4806 41.0369L39.1864 41.5183L39.9559 40.335L40.4794 40.6918L39.71 41.875L40.4418 42.3738L41.3472 40.9847L41.8737 41.3414L40.5894 43.3163Z"
      fill="url(#paint55_linear)"
    />
    <path
      d="M26.2104 21.9764C30.4887 21.9764 34.1537 24.5661 35.7562 28.2637H36.3955C34.7582 24.2297 30.8213 21.3818 26.2075 21.3818C21.5937 21.3818 17.6568 24.2297 16.0195 28.2637H16.6588C18.2671 24.5661 21.9321 21.9764 26.2104 21.9764Z"
      fill="url(#paint56_linear)"
    />
    <path
      d="M26.2109 42.8566C21.9644 42.8566 18.3196 40.3045 16.6998 36.6504H16.0547C17.7122 40.6409 21.6289 43.4511 26.2109 43.4511C30.7928 43.4511 34.7095 40.6409 36.367 36.6504H35.7248C34.1021 40.3045 30.4573 42.8566 26.2109 42.8566Z"
      fill="url(#paint57_linear)"
    />
    <path
      d="M42.4559 70.3211C42.4559 70.7677 42.1059 71.1215 41.6488 71.1215C41.1976 71.1215 40.8389 70.7706 40.8389 70.3211C40.8389 69.8832 41.1976 69.5322 41.6488 69.5322C42.1059 69.5293 42.4559 69.8832 42.4559 70.3211ZM41.0414 70.3211C41.0414 70.672 41.3017 70.9504 41.6546 70.9504C41.9988 70.9504 42.2534 70.672 42.2534 70.324C42.2534 69.9731 41.9988 69.6889 41.6488 69.6889C41.2988 69.6889 41.0414 69.9731 41.0414 70.3211ZM41.5244 70.7329H41.3422V69.9441C41.4145 69.9296 41.5158 69.9209 41.6459 69.9209C41.7935 69.9209 41.8629 69.9441 41.9207 69.9789C41.9641 70.0137 41.9988 70.0746 41.9988 70.1529C41.9988 70.2399 41.9323 70.3066 41.8368 70.3356V70.3443C41.9121 70.3733 41.9583 70.4313 41.9815 70.5357C42.0046 70.6575 42.0191 70.7039 42.0393 70.7329H41.8426C41.8195 70.7039 41.805 70.6314 41.779 70.5415C41.7645 70.4545 41.7153 70.4168 41.617 70.4168H41.5302V70.7329H41.5244ZM41.5302 70.2863H41.617C41.7182 70.2863 41.7992 70.2544 41.7992 70.1703C41.7992 70.0978 41.7472 70.0485 41.6315 70.0485C41.5823 70.0485 41.5505 70.0543 41.5302 70.0572V70.2863Z"
      fill="#213A78"
    />
    <path
      d="M80.6707 51.0087L77.4165 45.1998L76.2276 46.5716V51.0058H72.7969V36.8447H76.2276V41.8561L80.3149 36.8447H84.5613L79.7393 42.5724L84.7176 51.0058H80.6707V51.0087Z"
      fill="white"
    />
    <path
      d="M92.2992 51.0097H86.1494V36.8486H92.0013C94.9374 36.8486 96.5052 38.4988 96.5052 40.9464C96.5052 42.5589 95.3944 43.4521 94.9576 43.7508C95.5332 44.1104 96.7019 44.8471 96.7019 46.8336C96.7019 49.5394 94.8188 51.0097 92.2992 51.0097ZM91.7843 39.972H89.6235V42.2805H91.7843C92.736 42.2805 93.034 41.6831 93.034 41.1263C93.034 40.5694 92.736 39.972 91.7843 39.972ZM91.9608 45.4039H89.6206V47.8892H91.9608C92.9125 47.8892 93.2307 47.2338 93.2307 46.6364C93.2307 46.039 92.9154 45.4039 91.9608 45.4039Z"
      fill="white"
    />
    <path
      d="M105.258 51.0097H99.1084V36.8486H104.96C107.896 36.8486 109.464 38.4988 109.464 40.9464C109.464 42.5589 108.353 43.4521 107.917 43.7508C108.492 44.1104 109.661 44.8471 109.661 46.8336C109.661 49.5394 107.778 51.0097 105.258 51.0097ZM104.743 39.972H102.583V42.2805H104.743C105.695 42.2805 105.993 41.6831 105.993 41.1263C105.993 40.5694 105.695 39.972 104.743 39.972ZM104.92 45.4039H102.58V47.8892H104.92C105.871 47.8892 106.19 47.2338 106.19 46.6364C106.19 46.039 105.874 45.4039 104.92 45.4039Z"
      fill="white"
    />
    <path d="M111.77 51.0093V47.4277H115.342V51.0093H111.77Z" fill="white" />
    <path
      d="M122.823 51.1283C121.096 51.1283 119.907 50.5918 118.895 49.5768C117.445 48.1239 117.445 46.3142 117.445 43.9275C117.445 41.5407 117.445 39.731 118.895 38.2781C119.907 37.2631 121.096 36.7266 122.823 36.7266C125.501 36.7266 127.645 38.2172 128.16 41.3406H124.628C124.411 40.546 123.974 39.8296 122.823 39.8296C122.186 39.8296 121.712 40.0877 121.455 40.4271C121.136 40.8447 120.919 41.3406 120.919 43.9275C120.919 46.5143 121.136 47.0102 121.455 47.4278C121.712 47.7672 122.186 48.0253 122.823 48.0253C123.974 48.0253 124.411 47.3089 124.628 46.5143H128.16C127.642 49.6377 125.499 51.1283 122.823 51.1283Z"
      fill="white"
    />
    <path
      d="M138.935 49.5797C137.922 50.5948 136.733 51.1313 135.006 51.1313C133.279 51.1313 132.091 50.5948 131.078 49.5797C129.629 48.1268 129.629 46.3171 129.629 43.9304C129.629 41.5436 129.629 39.734 131.078 38.281C132.091 37.266 133.279 36.7295 135.006 36.7295C136.733 36.7295 137.922 37.266 138.935 38.281C140.384 39.734 140.384 41.5436 140.384 43.9304C140.381 46.3171 140.381 48.1268 138.935 49.5797ZM136.395 40.4503C136.137 40.111 135.64 39.8326 135.006 39.8326C134.373 39.8326 133.875 40.111 133.618 40.4503C133.3 40.8679 133.1 41.3435 133.1 43.9304C133.1 46.5172 133.3 46.9755 133.618 47.3931C133.875 47.7295 134.373 48.0282 135.006 48.0282C135.64 48.0282 136.137 47.7295 136.395 47.3931C136.713 46.9755 136.913 46.5172 136.913 43.9304C136.91 41.3435 136.713 40.8679 136.395 40.4503Z"
      fill="white"
    />
    <path
      d="M152.487 51.0097V44.2264L150.483 47.7268H148.261L146.256 44.2264V51.0097H142.785V36.8486H146.199L149.372 43.0925L152.548 36.8486H155.958V51.0097H152.487Z"
      fill="white"
    />
    <path d="M158.523 51.0093V47.4277H162.096V51.0093H158.523Z" fill="white" />
    <path
      d="M170.807 51.0097H164.657V36.8486H170.509C173.445 36.8486 175.013 38.4988 175.013 40.9464C175.013 42.5589 173.902 43.4521 173.465 43.7508C174.041 44.1104 175.213 44.8471 175.213 46.8336C175.213 49.5394 173.327 51.0097 170.807 51.0097ZM170.292 39.972H168.128V42.2805H170.292C171.244 42.2805 171.542 41.6831 171.542 41.1263C171.542 40.5694 171.244 39.972 170.292 39.972ZM170.472 45.4039H168.128V47.8892H170.472C171.423 47.8892 171.741 47.2338 171.741 46.6364C171.741 46.039 171.423 45.4039 170.472 45.4039Z"
      fill="white"
    />
    <path
      d="M184.757 51.0097L182.258 45.7403H181.086V51.0097H177.615V36.8486H183.21C186.345 36.8486 188.032 39.0759 188.032 41.4047C188.032 43.3332 186.84 44.5483 185.729 45.1052L188.784 51.0126H184.757V51.0097ZM183.051 39.972H181.086V42.8373H183.051C184.005 42.8373 184.561 42.1413 184.561 41.4047C184.561 40.668 184.005 39.972 183.051 39.972Z"
      fill="white"
    />
    <path
      d="M65.6228 29.3454L61.7264 22.4896L59.3891 25.3026V29.3454H57.2861V15.4424H59.3891V22.4345L65.0587 15.4424H67.6303L63.1293 20.8916L68.096 29.3483H65.6228V29.3454Z"
      fill="white"
    />
    <path
      d="M71.3908 24.9717C71.3908 26.6711 72.2876 27.7441 73.9046 27.7441C75.0154 27.7441 75.5997 27.4309 76.2997 26.7291L77.5667 27.9211C76.5542 28.9361 75.5997 29.4639 73.8641 29.4639C71.3908 29.4639 69.4238 28.156 69.4238 24.3859C69.4238 21.1842 71.0784 19.3281 73.6124 19.3281C76.2621 19.3281 77.801 21.2799 77.801 24.093V24.9717H71.3908ZM75.5621 22.2369C75.2497 21.4945 74.5496 20.987 73.6124 20.987C72.6781 20.987 71.9578 21.4945 71.6454 22.2369C71.4516 22.6864 71.4111 22.9793 71.3908 23.5854H75.8311C75.8137 22.9793 75.7559 22.6864 75.5621 22.2369Z"
      fill="white"
    />
    <path
      d="M83.1382 29.3454C81.2493 29.3454 80.4307 28.0751 80.4307 26.6106V15.4424H82.4179V26.4975C82.4179 27.2602 82.6927 27.6488 83.4882 27.6488H84.3647V29.3483H83.1382V29.3454Z"
      fill="white"
    />
    <path
      d="M89.2554 29.3454C87.3665 29.3454 86.5479 28.0751 86.5479 26.6106V15.4424H88.5351V26.4975C88.5351 27.2602 88.8099 27.6488 89.6083 27.6488H90.4848V29.3483H89.2554V29.3454Z"
      fill="white"
    />
    <path
      d="M93.717 24.9717C93.717 26.6711 94.6137 27.7441 96.2307 27.7441C97.3415 27.7441 97.9258 27.4309 98.6259 26.7291L99.8928 27.9211C98.8804 28.9361 97.9258 29.4639 96.1902 29.4639C93.717 29.4639 91.75 28.156 91.75 24.3859C91.75 21.1842 93.4046 19.3281 95.9386 19.3281C98.5883 19.3281 100.127 21.2799 100.127 24.093V24.9717H93.717ZM97.8853 22.2369C97.5729 21.4945 96.8729 20.987 95.9386 20.987C95.0014 20.987 94.2811 21.4945 93.9716 22.2369C93.7778 22.6864 93.7373 22.9793 93.717 23.5854H98.1572C98.137 22.9793 98.0792 22.6864 97.8853 22.2369Z"
      fill="white"
    />
    <path
      d="M105.732 31.3202C105.538 31.8683 105.304 32.2569 105.012 32.5498C104.468 33.0979 103.745 33.2719 102.987 33.2719H102.189V31.4739H102.695C103.534 31.4739 103.826 31.2186 104.118 30.4182L104.662 28.8754L101.214 19.4443H103.317L105.674 26.4161L107.974 19.4443H110.097L105.732 31.3202Z"
      fill="white"
    />
    <path
      d="M122.388 29.3454H116.776V15.4424H122.171C124.743 15.4424 126.36 16.9069 126.36 19.2502C126.36 20.7553 125.425 21.8284 124.491 22.1996C125.561 22.6288 126.574 23.6641 126.574 25.4216C126.577 27.9794 124.844 29.3454 122.388 29.3454ZM122.001 17.3361H118.882V21.3208H122.001C123.346 21.3208 124.26 20.619 124.26 19.3285C124.26 18.038 123.343 17.3361 122.001 17.3361ZM122.194 23.2146H118.882V27.4516H122.194C123.655 27.4516 124.474 26.5526 124.474 25.3229C124.474 24.0933 123.655 23.2146 122.194 23.2146Z"
      fill="white"
    />
    <path
      d="M132.148 29.3454C130.259 29.3454 129.44 28.0751 129.44 26.6106V15.4424H131.428V26.4975C131.428 27.2602 131.7 27.6488 132.498 27.6488H133.374V29.3483H132.148V29.3454Z"
      fill="white"
    />
    <path
      d="M141.576 29.3452V28.3505C140.893 29.0929 139.939 29.4641 138.946 29.4641C137.934 29.4641 137.095 29.1335 136.511 28.5651C135.753 27.8227 135.519 26.8859 135.519 25.7723V19.4443H137.506V25.4794C137.506 27.0222 138.382 27.6864 139.493 27.6864C140.604 27.6864 141.538 27.0019 141.538 25.4794V19.4443H143.525V29.3452H141.576Z"
      fill="white"
    />
    <path
      d="M148.199 24.9717C148.199 26.6711 149.096 27.7441 150.713 27.7441C151.824 27.7441 152.408 27.4309 153.108 26.7291L154.375 27.9211C153.363 28.9361 152.408 29.4639 150.673 29.4639C148.199 29.4639 146.232 28.156 146.232 24.3859C146.232 21.1842 147.887 19.3281 150.421 19.3281C153.071 19.3281 154.61 21.2799 154.61 24.093V24.9717H148.199ZM152.368 22.2369C152.055 21.4945 151.355 20.987 150.421 20.987C149.487 20.987 148.764 21.4945 148.454 22.2369C148.26 22.6864 148.22 22.9793 148.199 23.5854H152.64C152.622 22.9793 152.564 22.6864 152.368 22.2369Z"
      fill="white"
    />
    <path
      d="M167.875 29.3454H162.264V15.4424H167.658C170.23 15.4424 171.847 16.9069 171.847 19.2502C171.847 20.7553 170.913 21.8284 169.978 22.1996C171.049 22.6288 172.061 23.6641 172.061 25.4216C172.061 27.9794 170.328 29.3454 167.875 29.3454ZM167.485 17.3361H164.367V21.3208H167.485C168.83 21.3208 169.744 20.619 169.744 19.3285C169.744 18.038 168.827 17.3361 167.485 17.3361ZM167.679 23.2146H164.367V27.4516H167.679C169.14 27.4516 169.958 26.5526 169.958 25.3229C169.958 24.0933 169.14 23.2146 167.679 23.2146Z"
      fill="white"
    />
    <path
      d="M181.608 28.2923C180.966 28.9564 179.991 29.4639 178.646 29.4639C177.301 29.4639 176.349 28.9564 175.704 28.2923C174.769 27.3149 174.535 26.065 174.535 24.3859C174.535 22.7241 174.769 21.4742 175.704 20.4998C176.346 19.8356 177.301 19.3281 178.646 19.3281C179.991 19.3281 180.963 19.8356 181.608 20.4998C182.542 21.4771 182.776 22.727 182.776 24.3859C182.776 26.065 182.542 27.3149 181.608 28.2923ZM180.106 21.6714C179.736 21.3002 179.23 21.1059 178.646 21.1059C178.061 21.1059 177.575 21.3002 177.205 21.6714C176.601 22.2775 176.522 23.3128 176.522 24.3859C176.522 25.4589 176.601 26.4942 177.205 27.1003C177.575 27.4715 178.061 27.6861 178.646 27.6861C179.23 27.6861 179.736 27.4715 180.106 27.1003C180.711 26.4942 180.789 25.4589 180.789 24.3859C180.789 23.3099 180.711 22.2775 180.106 21.6714Z"
      fill="white"
    />
    <path
      d="M192.124 28.2923C191.482 28.9564 190.507 29.4639 189.165 29.4639C187.82 29.4639 186.866 28.9564 186.223 28.2923C185.289 27.3149 185.055 26.065 185.055 24.3859C185.055 22.7241 185.289 21.4742 186.223 20.4998C186.866 19.8356 187.82 19.3281 189.165 19.3281C190.51 19.3281 191.482 19.8356 192.124 20.4998C193.062 21.4771 193.293 22.727 193.293 24.3859C193.296 26.065 193.062 27.3149 192.124 28.2923ZM190.626 21.6714C190.256 21.3002 189.75 21.1059 189.165 21.1059C188.581 21.1059 188.095 21.3002 187.725 21.6714C187.12 22.2775 187.042 23.3128 187.042 24.3859C187.042 25.4589 187.12 26.4942 187.725 27.1003C188.095 27.4715 188.581 27.6861 189.165 27.6861C189.75 27.6861 190.256 27.4715 190.626 27.1003C191.228 26.4942 191.309 25.4589 191.309 24.3859C191.309 23.3099 191.231 22.2775 190.626 21.6714Z"
      fill="white"
    />
    <path
      d="M202.055 29.3454L199.29 24.7748L197.829 26.4337V29.3425H195.842V15.4424H197.829V24.015L201.668 19.4445H204.083L200.655 23.3306L204.511 29.3454H202.055Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -18.9144) scale(30.7609 40.5697)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.9425 37.7288) scale(28.8895 28.9634)"
      >
        <stop offset="0.00495866" stopColor="#DBE0ED" />
        <stop offset="0.4172" stopColor="#18659C" />
        <stop offset="0.7669" stopColor="#094B7F" />
        <stop offset="1" stopColor="#223669" />
      </radialGradient>
      <linearGradient
        id="paint2_linear"
        x1="24.9747"
        y1="35.376"
        x2="51.6158"
        y2="72.0284"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2353" stopColor="#F5D36D" />
        <stop offset="0.4133" stopColor="#E6B741" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5283" stopColor="#B37418" />
        <stop offset="0.5694" stopColor="#F4D96C" />
        <stop offset="0.6796" stopColor="#B37418" />
        <stop offset="0.7733" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="7.5729"
        y1="38.9644"
        x2="31.1001"
        y2="67.0538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint4_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint5_linear"
        x1="26.229"
        y1="-11.923"
        x2="32.4244"
        y2="6.40263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint7_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint8_linear"
        x1="26.229"
        y1="-11.923"
        x2="32.4244"
        y2="6.40263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint10_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint11_linear"
        x1="26.229"
        y1="-11.923"
        x2="32.4244"
        y2="6.40263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint13_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint14_linear"
        x1="26.2291"
        y1="-11.923"
        x2="32.4245"
        y2="6.40259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint16_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint17_linear"
        x1="26.2291"
        y1="-11.923"
        x2="32.4245"
        y2="6.40259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint19_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -14.3783) scale(30.7612 30.84)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.9432" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint20_linear"
        x1="26.2291"
        y1="-11.923"
        x2="32.4245"
        y2="6.40259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <radialGradient
        id="paint22_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.0577 -18.0014) scale(36.8034 36.8977)"
      >
        <stop stopColor="#3665AC" />
        <stop offset="0.5124" stopColor="#3665AC" />
        <stop offset="0.8344" stopColor="#233971" />
      </radialGradient>
      <linearGradient
        id="paint23_linear"
        x1="26.2291"
        y1="-11.923"
        x2="32.4245"
        y2="6.40259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="26.2291"
        y1="-11.923"
        x2="32.4245"
        y2="6.40259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="5.42558"
        y1="-4.89014"
        x2="11.621"
        y2="13.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#B37418" />
        <stop offset="0.6528" stopColor="#B37418" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="9.45592"
        y1="-0.978485"
        x2="35.0221"
        y2="49.5551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8E579" />
        <stop offset="0.3763" stopColor="#F5D36D" />
        <stop offset="0.4657" stopColor="#EEC450" />
        <stop offset="0.5676" stopColor="#E6B741" />
        <stop offset="0.6237" stopColor="#E39B25" />
        <stop offset="0.6528" stopColor="#D8970C" />
        <stop offset="0.7152" stopColor="#F4D96C" />
        <stop offset="0.7921" stopColor="#F8E579" />
        <stop offset="0.8441" stopColor="#FBF3AA" />
        <stop offset="0.8919" stopColor="#FFF4CE" />
        <stop offset="0.9376" stopColor="#E4AE00" />
        <stop offset="0.9439" stopColor="#E4AE00" />
        <stop offset="1" stopColor="#F5BE3E" />
      </linearGradient>
      <radialGradient
        id="paint28_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.51923 5.45174) scale(41.399 41.505)"
      >
        <stop offset="0.1413" stopColor="#497FC1" />
        <stop offset="0.2888" stopColor="#457BBE" />
        <stop offset="0.49" stopColor="#3C6EB4" />
        <stop offset="0.6128" stopColor="#3665AC" />
        <stop offset="0.6854" stopColor="#335DA1" />
        <stop offset="0.8093" stopColor="#2C4C85" />
        <stop offset="0.9687" stopColor="#21345E" />
        <stop offset="1" stopColor="#1E3057" />
      </radialGradient>
      <linearGradient
        id="paint29_linear"
        x1="24.4559"
        y1="0.47301"
        x2="53.132"
        y2="43.5751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint30_linear"
        x1="23.6078"
        y1="1.21155"
        x2="52.7953"
        y2="43.8757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint31_linear"
        x1="19.702"
        y1="3.9408"
        x2="51.5646"
        y2="44.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint32_linear"
        x1="15.9521"
        y1="6.26258"
        x2="47.9873"
        y2="46.9434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint33_linear"
        x1="12.4688"
        y1="8.98952"
        x2="45.3428"
        y2="49.0341"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint34_linear"
        x1="7.37008"
        y1="13.5125"
        x2="42.0036"
        y2="51.9181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint35_linear"
        x1="23.6931"
        y1="4.76112"
        x2="51.0995"
        y2="43.0681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint36_linear"
        x1="20.1551"
        y1="6.98517"
        x2="48.1159"
        y2="46.0671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint37_linear"
        x1="17.2152"
        y1="9.58411"
        x2="45.1773"
        y2="48.6676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint38_linear"
        x1="14.1719"
        y1="12.6144"
        x2="41.8246"
        y2="51.2658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint39_linear"
        x1="10.6902"
        y1="15.6905"
        x2="38.3139"
        y2="54.3012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint40_linear"
        x1="8.0224"
        y1="18.0358"
        x2="35.7117"
        y2="56.7381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint41_linear"
        x1="4.16532"
        y1="21.2059"
        x2="31.806"
        y2="59.8404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint42_linear"
        x1="1.84096"
        y1="23.3273"
        x2="29.494"
        y2="61.979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint43_linear"
        x1="-1.48379"
        y1="14.549"
        x2="24.5165"
        y2="63.3597"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint44_linear"
        x1="-1.21602"
        y1="14.5455"
        x2="25.1985"
        y2="63.226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint45_linear"
        x1="-1.08527"
        y1="14.9751"
        x2="26.8623"
        y2="62.6655"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint46_linear"
        x1="-1.53356"
        y1="15.3295"
        x2="28.6334"
        y2="62.0219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint47_linear"
        x1="-0.733857"
        y1="15.2057"
        x2="29.824"
        y2="61.1362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint48_linear"
        x1="-0.547639"
        y1="15.062"
        x2="31.4195"
        y2="60.2573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint49_linear"
        x1="0.0367157"
        y1="14.8078"
        x2="32.2913"
        y2="59.483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint50_linear"
        x1="2.25608"
        y1="13.2188"
        x2="33.8578"
        y2="58.2219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint51_linear"
        x1="2.30027"
        y1="13.1028"
        x2="37.5134"
        y2="55.2766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint52_linear"
        x1="5.12658"
        y1="11.0623"
        x2="39.9596"
        y2="53.3547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint53_linear"
        x1="4.98948"
        y1="11.2824"
        x2="42.1402"
        y2="51.5422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint54_linear"
        x1="7.18919"
        y1="9.04176"
        x2="43.7812"
        y2="50.1391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint55_linear"
        x1="7.61771"
        y1="8.54041"
        x2="46.208"
        y2="47.716"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1CA" />
        <stop offset="0.1767" stopColor="#F8ECA3" />
        <stop offset="0.2933" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint56_linear"
        x1="13.2783"
        y1="12.6867"
        x2="41.9823"
        y2="46.1225"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1656" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
      <linearGradient
        id="paint57_linear"
        x1="8.72616"
        y1="16.5935"
        x2="37.4311"
        y2="50.0304"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1656" stopColor="#F5D36D" />
        <stop offset="0.4" stopColor="#FBF3AA" />
        <stop offset="0.4311" stopColor="#EEC450" />
        <stop offset="0.5289" stopColor="#E6B741" />
        <stop offset="0.6044" stopColor="#F4D96C" />
        <stop offset="0.6489" stopColor="#F8E579" />
        <stop offset="0.8089" stopColor="#E4AE00" />
        <stop offset="0.8889" stopColor="#E6B300" />
        <stop offset="0.9511" stopColor="#FFF4CE" />
        <stop offset="1" stopColor="#FBCB40" />
      </linearGradient>
    </defs>
  </svg>
);

export { LogoKbb, LogoKbbHome };
