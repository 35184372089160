import React from "react";

import { WrapperPartnerCard } from "./my-earnings-partner-card.atom.style";

export interface IProps {
  logo: string;
  selected: boolean;
  onClick: (e: React.MouseEvent) => void;
  altText?: string;
}

export const MyEargingsPartnerCardAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperPartnerCard
      className={props.selected ? "selected" : ""}
      onClick={props.onClick}
    >
      <img src={props.logo} alt={props.altText ?? ""} />
    </WrapperPartnerCard>
  );
};
