import { TypesHelper } from "c4u-web-components";

export interface ICustomerChangePasswordRequest {
  email: string;
}

export class CustomerChangePasswordRequest {
  email: string | null;

  constructor({ email }: ICustomerChangePasswordRequest) {
    this.email = TypesHelper.toStringNull(email);
  }
}
