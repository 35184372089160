import React from "react";
import { useTranslation } from "react-i18next";

import { StringHelper } from "../../../../../../helper";
import { useManhein } from "../../../../../../hooks";
import { IGetLenderUserResponse } from "../../../../../../models";

import { LenderUsersTableSearchOrganism } from "./lender-user-list-table-search.organism";

export const LenderUserListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getCustomersFideliza } = useManhein();

  return (
    <>
      <LenderUsersTableSearchOrganism
        getDataCallback={getCustomersFideliza}
        tableName="api-user-list"
        tableHeaders={["#", t("Name"), t("Bank"), t("Cpf"), t("Email")]}
        mappingFunction={(m: IGetLenderUserResponse, i) => [
          m.id,
          m.name,
          m.lender.name,
          StringHelper.formatCpfCnpj(m.document),
          m.email,
        ]}
      />
    </>
  );
};
