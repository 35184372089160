import { TypesHelper } from "c4u-web-components";

export interface ISplit {
  adId?: number;
  finalTrafficPenaltyValue?: number;
  otherPenaltyValue?: number;
  reasonSplitValue?: string;
}
export class SplitRequest {
  adId: number | null;
  finalTrafficPenaltyValue: number | null;
  otherPenaltyValue: number | null;
  reasonSplitValue: string | null;

  constructor({
    adId,
    finalTrafficPenaltyValue,
    otherPenaltyValue,
    reasonSplitValue,
  }: ISplit) {
    this.adId = TypesHelper.toNumberNull(adId);
    this.finalTrafficPenaltyValue = TypesHelper.toNumberNull(
      finalTrafficPenaltyValue,
      2,
      "en-US"
    );
    this.otherPenaltyValue = TypesHelper.toNumberNull(
      otherPenaltyValue,
      2,
      "en-US"
    );
    this.reasonSplitValue = TypesHelper.toStringNull(reasonSplitValue);
  }
}
