import { HttpHelper } from "c4u-web-components";
import {
  CancelRequest,
  DeleteDiscoverMockRequest,
  GetCustomerReportParams,
  GetLogReportParams,
  IGetCustomerReportDetailResponse,
  IGetCustomerReportPagedResponseModel,
  IGetDiscoverMockResponse,
  IGetInsuranceQuotesErrorsLogResponse,
  IGetInsuranceQuotesLogResponse,
  IGetInsuranceStepsLogResponse,
  IPagedResponseGenericModel,
  PostDiscoverMockRequest,
} from "../../../models";
import { PostDtInformationSyncRequest } from "../../../models/apis/insurance/insurance-dt-information.request";
import { C4uClientConfig, insuranceHttp, useRequest } from "../config";

export const useInsurance = () => {
  const { get, post, del } = useRequest(
    insuranceHttp,
    "Backoffice",
    C4uClientConfig
  );

  const postCancelInsuranceProposal = async (
    request: CancelRequest
  ): Promise<void> => {
    await post(`cancel`, request);
  };

  const getInsuranceReport = async (
    params: GetCustomerReportParams
  ): Promise<IGetCustomerReportPagedResponseModel> => {
    const { data } = await get(`report${HttpHelper.BuildQueryString(params)}`);
    return data;
  };

  const getInsuranceReportKbb = async (
    params: GetCustomerReportParams
  ): Promise<IGetCustomerReportPagedResponseModel> => {
    const kbbInsuranceParams = {
      ...params,
      passwordless: true,
    };
    const { data } = await get(
      `report${HttpHelper.BuildQueryString(kbbInsuranceParams)}`
    );
    return data;
  };

  const getInsuranceReportDetail = async (
    id: string
  ): Promise<IGetCustomerReportDetailResponse> => {
    const { data } = await get(`reportDetail/${id}`);
    return data;
  };

  const getInsuranceProposalStatus = async (
    customerId: string
  ): Promise<string> => {
    const { data } = await get(`proposalStatus/${customerId}`);
    return data;
  };

  const postDtInformation = async (
    request: PostDtInformationSyncRequest
  ): Promise<void> => {
    await post("dtInformation", request);
  };

  const postDiscoverMock = async (
    request: PostDiscoverMockRequest
  ): Promise<void> => {
    await post("discoverMock", request);
  };

  const deleteDiscoverMock = async (
    request: DeleteDiscoverMockRequest
  ): Promise<void> => {
    await del("discoverMock", request);
  };

  const getDiscoverMock = async (): Promise<IGetDiscoverMockResponse> => {
    const response = await get("discoverMock");
    return response.data;
  };

  const getInsuranceQuotesLogs = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetInsuranceQuotesLogResponse>
    >(`getQuotesLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const getInsuranceQuotesErrorsLog = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetInsuranceQuotesErrorsLogResponse>
    >(`getQuotesErrorLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const getInsuranceStepsLog = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetInsuranceStepsLogResponse>
    >(`getInsuranceStepsLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const downloadQuotesLogReport = async (request: GetLogReportParams) => {
    const response = await post<BlobPart>("downloadQuotesLogReport", request);
    return response;
  };

  const downloadQuotesErrorLogReport = async (request: GetLogReportParams) => {
    const response = await post<BlobPart>(
      "downloadQuotesErrorLogReport",
      request
    );
    return response;
  };

  const downloadInsuranceStepLogReport = async (
    request: GetLogReportParams
  ) => {
    const response = await post<BlobPart>(
      "downloadInsuranceStepLogReport",
      request
    );
    return response;
  };

  return {
    getInsuranceProposalStatus,
    getInsuranceReport,
    getInsuranceReportKbb,
    getInsuranceReportDetail,
    postCancelInsuranceProposal,
    postDtInformation,
    postDiscoverMock,
    deleteDiscoverMock,
    getDiscoverMock,
    getInsuranceStepsLog,
    getInsuranceQuotesLogs,
    getInsuranceQuotesErrorsLog,
    downloadQuotesLogReport,
    downloadQuotesErrorLogReport,
    downloadInsuranceStepLogReport,
  };
};
