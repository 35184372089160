import styled from "styled-components";

export const WrapperInputting = styled.div``;
export const ContainerSearch = styled.div``;

export const ApiUserDetailsSectionTitle = styled.h4`
  margin: 8px 0 0;
`;

export const RequestingInputtingHR = styled.hr`
  margin: 0.5rem 0;
`;

export const WrapperFormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 0;
`;
