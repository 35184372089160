import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";

interface IProps {
  enabled: boolean;
  adId: number;
  setException: React.Dispatch<any>;
}

export const AdCancelMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { cancelAd } = useManhein();

  const validations = Yup.object({
    status: Yup.number().notOneOf([NaN]).required(),
  });

  const formik = useFormik({
    initialValues: { adId: props.adId, status: undefined },
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmCalcellation"))) {
        try {
          await cancelAd(values.adId, Number(values.status));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const renderCombo = (key: string, label: string) => {
    const valueFormated = formik.values
      ? (formik.values as any)[key]
      : undefined;
    const error = formik.errors ? (formik.errors as any)[key] : undefined;
    return (
      <Form.Group as={Col} md={4} controlId={key}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={valueFormated}
          disabled={!props.enabled}
          onChange={formik.handleChange}
          isInvalid={!!error}
          as="select"
        >
          <option>{t("Select")}...</option>
          <option value={0}>{t("Expired")}</option>
          <option value={1}>{t("ExpiredReport")}</option>
          <option value={2}>{t("BySeller")}</option>
          <option value={3}>{t("ReversedInspectionPayment")}</option>
          <option value={4}>{t("RefusedReport")}</option>
        </Form.Control>
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        {renderCombo("status", t("Status"))}
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >{t("Cancel ad")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
