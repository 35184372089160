import React from "react";
import { Col, Form } from "react-bootstrap";
import { Wrapper } from "./details.molecule.style";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../helper";

interface IProps {
  data: {
    isCache: string;
    createdAt: string;
  };
}

export const IsCacheMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderView = (
    key: string,
    label: string,
    size?: number,
    func?: (attr: any) => string
  ) => {
    const values = props.data as any;

    const valueFormated = func ? func(values[key]) : values[key];

    return (
      <Form.Group as={Col} md={size ? size : 4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated ?? "-"} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <Wrapper>
      <>
        <h4>{t("CacheInformation")}</h4>
        <>
          <hr />
          <Form.Row className="p-2">
            {renderView("isCache", t("isCache"), 1)}
            {renderView(
              "createdAt",
              t("ConsultationDate"),
              3,
              DateHelper.dateHourFormat
            )}
          </Form.Row>
        </>
      </>
    </Wrapper>
  );
};
