import { ButtonTertiary } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  ApprovalStatusEnum,
  IListUserPagedResponse,
  IListUserResponse,
  IListUsersParams,
  ListUsersParams,
  StdEnum,
} from "../../../../../models";
import {
  PaginatableTableList,
  UserListSearchForm,
} from "../../../../molecules";

interface IProps {
  getDataCallback: (values: ListUsersParams) => Promise<IListUserPagedResponse>;
  DealershipStatusEnum: StdEnum;
  CoxUserIsActiveBooleanEnum: StdEnum;
  tableHeaders: string[];
  mappingFunction: (m: IListUserResponse, i: number) => any[];
}

export const UserListTableSearchOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [listData, setListData] = useState<IListUserResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const initialValues: IListUsersParams = {
    pageSize: 50,
    page: 1,
    approvalStatus: ApprovalStatusEnum.Approved,
    search: "",
    active: "",
  };

  const formik = useFormik<IListUsersParams>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new ListUsersParams(values)
        );
        setListData(data);

        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => {
    formik.setValues({
      ...initialValues,
      approvalStatus: "",
    });
    formik.submitForm();
  };

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new ListUsersParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <UserListSearchForm
        formik={formik}
        DaelershipStatusOptions={props.DealershipStatusEnum}
        CoxUserStatusOptions={props.CoxUserIsActiveBooleanEnum}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />

      <div className="w-100 m-2 d-flex justify-content-center">
        <Link to={paths.backofficeUserPage(0)}>
          <ButtonTertiary sizex="lg">{t("NewUser")}</ButtonTertiary>
        </Link>
      </div>

      <PaginatableTableList
        tableName={"user-list"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
