import { conformToMask } from "react-text-mask-legacy";
import { MaskHelper } from "c4u-web-components";

export class StringHelper {
  static formatCpfCnpj = (document?: string) => {
    document = document?.replace(/[^\d]+/g, "");

    if (document?.length === 11) {
      return conformToMask(document, MaskHelper.Cpf, { guide: false })
        .conformedValue;
    }

    if (document?.length === 14) {
      return conformToMask(document, MaskHelper.Cnpj, { guide: false })
        .conformedValue;
    }

    return "";
  };

  static formatZipCodeBR = (zipcode: string) => {
    zipcode = zipcode.replace(/\.|-| /g, "");
    return conformToMask(zipcode, MaskHelper.Cep, { guide: false })
      .conformedValue;
  };

  static formatPhone = (phoneNumber?: string) => {
    phoneNumber = phoneNumber?.replace(/\(|\)| |-|\./g, "");

    if (phoneNumber?.length && [8, 10].includes(phoneNumber.length)) {
      return conformToMask(phoneNumber, MaskHelper.Landline, { guide: false })
        .conformedValue;
    }

    if (phoneNumber?.length && [9, 11].includes(phoneNumber.length)) {
      return conformToMask(phoneNumber, MaskHelper.Phone, { guide: false })
        .conformedValue;
    }

    return "";
  };

  static getYesNoStringValues(value: string | boolean | undefined) {
    if (value === undefined) return "";
    return value ? "1" : "0";
  }
}
