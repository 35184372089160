import styled from "styled-components";

export const AdOfferActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AdOfferActionButtonDiv = styled.div`
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};
`;

export const DealerMessageSpan = styled.span`
  padding: 2px 4px;
  font-size: 13px;
  background: #ff6e00;
  color: #fff;
`;
