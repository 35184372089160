import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-azul-seguros.organism.style";

export const AboutAzulSeguros = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("Azul")}</Title>
      <Content>
        <Trans>
          {t("TheAzulSeguros")}
          {t("InAddition")}
          {t("IfYourCustomer")}
          {t("ComprehensiveCoverage")}
          <List>
            <ListItem>{t("MaterialDamages")}</ListItem>
            <ListItem>{t("BodilyInjury")}</ListItem>
            <ListItem>{t("APP")}</ListItem>
            <ListItem>{t("Assistance")}</ListItem>
            <ListItem>{t("CoverageOf")}</ListItem>
            <ListItem>{t("MandatoryExcess")}</ListItem>
          </List>
          {t("IndispensableInCar")}
          <List>
            <ListItem>{t("BasicCoverage")}</ListItem>
            <ListItem>{t("AssistanceWithWrecker")}</ListItem>
            <ListItem>{t("CostXBenefit")}</ListItem>
            <ListItem>{t("InsuranceInstallment")}</ListItem>
            <ListItem>{t("ThreeDeductibleOptions")}</ListItem>
            <ListItem>{t("OffersOption")}</ListItem>
          </List>
          <HighlightText>{t("Commissioning")}:</HighlightText>
          <List>
            <ListItem>{t("AzulSegurosEveryInsuranceSale")}</ListItem>
            <ListItem>{t("BesidesSurprising")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem textColor='#00459A' italic semiBold>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
