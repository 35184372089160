import styled from "styled-components";
import { darken } from "polished";

export const StyledA = styled.a`
  color: #1155cc;
  text-decoration: underline;

  :hover {
    color: ${() => darken(0.2, "#1155cc")} !important;
    text-decoration: underline !important;
  }
`;
