import { YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ILenderOperatorRequest } from "../../../../../models";

export const LenderOperatorValidationSchema = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isEmailValid",
    YupHelper.isEmailValid
  );

  const msgRequired = t("field_required");

  const validations = Yup.object<ILenderOperatorRequest>({
    id: Yup.number().required(),
    cpf: Yup.string()
      .nullable()
      .required(msgRequired)
      .isCpfValid(t("cpf_invalid")),
    name: Yup.string().required(msgRequired),
    email: Yup.string()
      .nullable()
      .required(msgRequired)
      .isEmailValid(t("field_invalid_email")),
    lenderId: Yup.number().nullable().required(msgRequired),
    collectionOfficeCnpj: Yup.string()
      .nullable()
      .required(msgRequired)
      .isCnpjValid(t("cnpj_invalid")),
    collectionOfficeName: Yup.string().required(msgRequired),
    isActive: Yup.string().required(msgRequired),
  });

  return validations;
};
