import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  CalendarCheckedIcon,
  DollarCheckedIcon,
  EightyPercentIcon,
  MoneyIcon,
} from "../../../../assets";
import {
  BenefitsContent,
  BenefitsItem,
  Content,
  Link,
  Title,
} from "./about-daycoval.organism.style";

export const AboutDaycoval = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("AboutDaycoval")}</Title>
      <Content>
        <Trans>
          {t("FinancingOfAutomobiles")}
          {t("KnowTheAdvantages")}
          <BenefitsContent>
            <BenefitsItem>
              <EightyPercentIcon />
              <p>{t("FinancingUpTo")}</p>
            </BenefitsItem>
            <BenefitsItem>
              <CalendarCheckedIcon />
              <p>{t("PartnerUpTo")}</p>
            </BenefitsItem>
            <BenefitsItem>
              <DollarCheckedIcon />
              <p>{t("DirectCredit")}</p>
            </BenefitsItem>
            <BenefitsItem>
              <MoneyIcon />
              <p>{t("OneOfTheRates")}</p>
            </BenefitsItem>
          </BenefitsContent>

          {t("VisitOurSite")}
          <Link href='https://www.daycoval.com.br/'>www.daycoval.com.br</Link>
        </Trans>
        {/* <ButtonContent>
          <ButtonPrimary>
            {t("ClickToDownloadMaterialForDaycoval")}
          </ButtonPrimary>
        </ButtonContent> */}
      </Content>
    </React.Fragment>
  );
};
