import { ButtonPrimary } from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { INotifyPaymentToSellerRequest } from "../../../../../models";
import * as Yup from "yup";
import { useManheinWebhook } from "../../../../../hooks";
import { formikHandleErrorHelper, handleFileRead } from "../../../../../helper";

interface IProps {
  enabled: boolean;
  unicCode: string;
  setException: React.Dispatch<any>;
}
export const AdNotifyPaymentToSellerMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { webhookNotifyPaymentToSeller } = useManheinWebhook();

  const validations = Yup.object<INotifyPaymentToSellerRequest>({
    vehicleId: Yup.string().required(),
    paymentVoucherBase64: Yup.string().required(),
  });

  const [file, setFile] = useState<string>();

  const formik = useFormik<INotifyPaymentToSellerRequest>({
    initialValues: {
      vehicleId: props.unicCode,
    } as INotifyPaymentToSellerRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await webhookNotifyPaymentToSeller({
            ...values,
            paymentVoucherBase64: file,
          } as INotifyPaymentToSellerRequest);

          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const renderUpload = (key: string, label: string | undefined) => {
    const valueFormated = formik.values
      ? (formik.values as any)[key]
      : undefined;

    const error = formik.errors ? (formik.errors as any)[key] : undefined;

    return (
      <Form.Group as={Col} md={4} controlId={key}>
        <Form.Label>{label}</Form.Label>
        <Form.File
          value={valueFormated}
          disabled={!props.enabled}
          onChange={(e: any) => handleFileRead(e, formik, setFile)}
          isInvalid={!!error}
          feedback={<>{t("field_required")}</>}
          feedbackTooltip={true}
        />
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        {renderUpload("paymentVoucherBase64", "Comprovante de pagamento")}
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Send payment receipt")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
