import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useSessionContext } from "../../../../../hooks";
import {
  GetRequestingVehicleParams,
  GetSearchList,
  IGetRequestingVehicleChangesResponse,
  ISearchListParams,
} from "../../../../../models";

import {
  ListRequestSearchFormMolecule,
  PaginatableTableList,
} from "../../../../molecules";
import { formikHandleErrorHelper } from "../../../../../helper";
import { DateHelper } from "c4u-web-components";

interface IProps {
  getDataCallback: (values: any) => any;
  tableName: string;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
}

export const ListRequestsVehicleChangesOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { setEmailTemplatesContext } = useSessionContext();

  const validations = Yup.object<GetRequestingVehicleParams>({
    pageSize: Yup.number().required(),
    page: Yup.number().required(),
    orderBy: Yup.string(),
  });

  const [listData, setListData] =
    useState<IGetRequestingVehicleChangesResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const formInitialValues = {
    pageSize: 50,
    page: 1,
  };
  const dateNow = new Date();

  const initialSearchValues = useMemo<ISearchListParams>(() => {
    return {
      ...formInitialValues,
      createdAt: DateHelper.dateHourCustomFormat(dateNow, "yyyy-MM-dd"),
      status: "",
      search: "",
      orderBy: "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<ISearchListParams>({
    initialValues: initialSearchValues as ISearchListParams,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new GetSearchList(values)
        );
        setListData(data);
        setEmailTemplatesContext(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () =>
    formik.setValues({
      ...formInitialValues,
      createdAt: DateHelper.dateHourCustomFormat(dateNow, "yyyy-MM-dd"),
      status: "",
      search: "",
      orderBy: "",
    } as ISearchListParams);

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new GetSearchList({
        ...formik.values,
        page: targetPage,
      })
    );

    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, [formik.values.pageSize]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ListRequestSearchFormMolecule
        cleanFiltersCallback={resetFormValues}
        formik={formik}
      />
      <PaginatableTableList
        tableName={props.tableName}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
        needOtherMessage={true}
      />
    </>
  );
};
