import { DateHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { PaginatableTableList } from "../../..";
import {
  IHistoricStepReportDetail,
  ProposalStepEnum,
} from "../../../../../models";

interface IProps {
  stepsHistoryDetails?: IHistoricStepReportDetail[];
}

export const InsuranceStepsHistoryDetailsMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  return !props.stepsHistoryDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <PaginatableTableList
      tableName={"coverage-details"}
      dataIsLoading={false}
      thead={[t("InsuranceStepNumber"), t("InsuranceStepName"), t("Date")]}
      tbody={props.stepsHistoryDetails?.map((m, i) => [
        m.step,
        t(ProposalStepEnum[m.step]),
        DateHelper.dateHourFormat(m.date),
      ])}
    />
  );
};
