import React from "react";
import { useTranslation } from "react-i18next";
import {
  PageControlItem,
  PageControls,
  PageNumber
} from "./pagination-control.atom.styles"

interface IProps {
  currentPage: number;
  lastPage: number;
  getPageCallback: (page: number) => void;
}

export const PaginationControlAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
      <PageControls>
        <PageControlItem
          title={t("First page")}
          onClick={() => props.getPageCallback(1)}
          disabled={props.currentPage <= 1}
        >
          {"<<"}
        </PageControlItem>
        <PageControlItem
          title={t("Previous page")}
          onClick={() => props.getPageCallback(props.currentPage - 1)}
          disabled={props.currentPage <= 1}
        >
          {"<"}
        </PageControlItem>
        <PageNumber
          title={t("Current page")}
          >
            {props.currentPage} / {props.lastPage}
          </PageNumber>
        <PageControlItem
          title={t("Next page")}
          onClick={() => props.getPageCallback(props.currentPage + 1)}
          disabled={props.currentPage >= props.lastPage}
        >
          {">"}
        </PageControlItem>
        <PageControlItem
          title={t("Last page")}
          onClick={() => props.getPageCallback(props.lastPage)}
          disabled={props.currentPage >= props.lastPage}
        >
          {">>"}
        </PageControlItem>
      </PageControls>
  );
};
