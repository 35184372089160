import {
  ButtonPrimary,
  ButtonSecondary,
  Combo,
  FormikControlAtom,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { Form, Col } from "react-bootstrap";
import { ComboOptionsFromEnumAtom, OptionsOrderByAtom } from "../../../atoms";
import { StdEnum } from "../../../../models";
import { SearchButtonsDiv } from "./api-usage-report-search-form.molecule.style";

interface IProps {
  formik: any;
  kbbApiOptionEnum: StdEnum;
  monthNamesEnum: StdEnum;
  orderByOptions: string[];
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const ApiUsageReportSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  const yearsComboOptions = useMemo(() => {
    const startYear = 2022;
    const endYear = new Date().getFullYear();
    const yearsCombo = [];
    for (let y = startYear; y <= endYear; y++) {
      yearsCombo.push(new Combo({ title: y, value: y }));
    }
    return yearsCombo;
  }, []);

  const orderByOptionsCombo = useMemo(
    () => props.orderByOptions.map((m) => new Combo({ title: t(m), value: m })),
    [props.orderByOptions, t]
  );

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <Col xl={10}>
            <Form.Row>
              <FormikControlAtom
                translate={t}
                formik={formik}
                md={6}
                property={"search"}
                label={t("TextSearch")}
              />

              <FormikControlAtom
                translate={t}
                formik={formik}
                as={"select"}
                md={3}
                property={"pageSize"}
                label={t("RecordsPerPage")}
              >
                <OptionsOrderByAtom
                  options={[
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                  ]}
                />
              </FormikControlAtom>

              <FormikControlAtom
                type="select"
                translate={t}
                formik={formik}
                data={orderByOptionsCombo}
                md={3}
                property={"orderBy"}
                label={t("OrderBy")}
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                translate={t}
                formik={formik}
                as={"select"}
                md={2}
                property={"kbbApi"}
                label={t("KbbApi")}
              >
                <ComboOptionsFromEnumAtom
                  propertyKey="kbbApi"
                  optionsEnum={props.kbbApiOptionEnum}
                />
              </FormikControlAtom>

              <Col md={1} className="d-none d-md-flex"></Col>
              <FormikControlAtom
                translate={t}
                formik={formik}
                as={"select"}
                md={2}
                property={"startMonth"}
                label={t("StartMonth")}
              >
                <ComboOptionsFromEnumAtom
                  propertyKey="startMonth"
                  optionsEnum={props.monthNamesEnum}
                  hideOptionAll
                  notSorted
                  includeSelectPlaceholder
                />
              </FormikControlAtom>

              <FormikControlAtom
                type="select"
                translate={t}
                formik={formik}
                data={yearsComboOptions}
                md={2}
                property={"startYear"}
                label={t("StartYear")}
              />

              <Col md={1} className="d-none d-md-flex"></Col>
              <FormikControlAtom
                translate={t}
                formik={formik}
                as={"select"}
                md={2}
                property={"finalMonth"}
                label={t("FinalMonth")}
              >
                <ComboOptionsFromEnumAtom
                  propertyKey="finalMonth"
                  optionsEnum={props.monthNamesEnum}
                  hideOptionAll
                  notSorted
                  includeSelectPlaceholder
                />
              </FormikControlAtom>

              <FormikControlAtom
                type="select"
                translate={t}
                formik={formik}
                data={yearsComboOptions}
                md={2}
                property={"finalYear"}
                label={t("FinalYear")}
              />
            </Form.Row>
          </Col>

          <Col xl={2} className="pt-1 pt-xl-4">
            <SearchButtonsDiv>
              <div>
                <ButtonSecondary
                  sizex="md"
                  disabled={formik.isSubmitting}
                  onClick={props.cleanFiltersCallback}
                >
                  {t("CleanFilters")}
                </ButtonSecondary>
              </div>
              <div>
                <ButtonPrimary
                  type="submit"
                  sizex="md"
                  loading={formik.isSubmitting}
                >
                  {t("Search")}
                </ButtonPrimary>
              </div>
            </SearchButtonsDiv>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
