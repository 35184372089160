import React from "react";
import {
  CardRouteLabelTitle,
  CardRouteItemImage,
  CardRoutesCampaignLogo,
  CardRoutesItemTitle,
  ICardRoutesProfileTypes,
  WrapperCardRoutesProgram,
} from "./card-routes-program.molecule.style";

interface IProps {
  image: string;
  imageTitle: string;
  cardLabel: string;
  campaignLogo?: string;
  campaignTitle?: string;
  profile?: ICardRoutesProfileTypes;
  campaignLogoWidth?: string;
}

export type { IProps as ICardRouteItem };

export const CardRoutesMolecule: React.FC<IProps> = (props) => {
  return (
    <>
      <WrapperCardRoutesProgram>
        <CardRouteItemImage>
          <img alt={props.imageTitle} src={props.image} />
        </CardRouteItemImage>
        <CardRoutesItemTitle>{props.imageTitle}</CardRoutesItemTitle>
        <CardRouteLabelTitle profile={props.profile}>
          {props.cardLabel}
        </CardRouteLabelTitle>

        {props.campaignLogo && (
          <CardRoutesCampaignLogo>
            <img
              alt={props.campaignTitle ?? "logo da campanha"}
              src={props.campaignLogo}
              width={props.campaignLogoWidth ?? ""}
            />
          </CardRoutesCampaignLogo>
        )}
      </WrapperCardRoutesProgram>
    </>
  );
};
