import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  GetConsuptionReportParams,
  IGetConsuptionReportParams,
  IListUserResponse,
  StdEnum,
} from "../../../../../models";
import {
  ApiUsageReportSearchForm,
  PaginatableTableList,
} from "../../../../molecules";

interface IProps {
  getDataCallback: (values: any) => any;
  parametersEnum: StdEnum;
  monthNamesEnum: StdEnum;
  orderByOptions: string[];
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
}

export const ApiUsageReportTableSearchOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [listData, setListData] = useState<IListUserResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const initialValues: IGetConsuptionReportParams = {
    startMonth: new Date().getMonth() + 1,
    startYear: new Date().getFullYear(),
    finalMonth: new Date().getMonth() + 1,
    finalYear: new Date().getFullYear(),
    search: "",
    kbbApi: 0,
    pageSize: 50,
    page: 1,
    orderBy: "",
  };

  const formik = useFormik<IGetConsuptionReportParams>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new GetConsuptionReportParams(values)
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      } finally {
        setDataIsLoading(false);
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => {
    formik.setValues(initialValues);
    formik.submitForm();
  };

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new GetConsuptionReportParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    if (formik.values.finalMonth === 0 && formik.values.startMonth !== 0)
      formik.setFieldValue("finalMonth", formik.values.startMonth);
  }, [formik.values.startMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formik.values.finalYear === 0 && formik.values.startYear !== 0)
      formik.setFieldValue("finalYear", formik.values.startYear);
  }, [formik.values.startYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ApiUsageReportSearchForm
        formik={formik}
        kbbApiOptionEnum={props.parametersEnum}
        monthNamesEnum={props.monthNamesEnum}
        orderByOptions={props.orderByOptions}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />

      <PaginatableTableList
        tableName={"api-usage-report"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
