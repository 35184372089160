import { ButtonSecondary, Combo, PermissionEnum } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { paths } from "./../../../../../constants";
import { useKbb, useManhein, useSessionContext } from "../../../../../hooks";
import {
  IAdInspectionModel,
  AdModel,
  ISplit,
  AdStepEnum,
  AdSubStepInspectionEnum,
  AdSubStepAcceptedOfferEnum,
  AdSubStepAuctionEnum,
  IAdEventHistory,
} from "../../../../../models";
import {
  AdCancelMolecule,
  AdCustomerMolecule,
  AdDetailsMolecule,
  AdDetailsOptionalsMolecule,
  AdDetailsPhotosMolecule,
  AdEventMolecule,
  AdInspectionMolecule,
  AdInspectionReportMolecule,
  AdInspectionReportHistoryMolecule,
  AdMainDetailsMolecule,
  AdNotifyBuyerPaymentMolecule,
  AdNotifyReleasePaymentMolecule,
  AdNotifyPaymentToSellerMolecule,
  AdNotifyBuyerNoPaymentMolecule,
  AdOfferMolecule,
  AdSplitMolecule,
  AdStatusesMolecule,
  FeedbackModal,
  AdOffersListMolecule,
  AdDetailsChecklistMolecule,
  AdSetStepMolecule,
  DetailsPageSubsection,
  DutCrvMolecule,
  AdEventHistoryMolecule,
} from "../../../../molecules";
import {
  FeedbackModalContent,
  HorizontalScrollDiv,
} from "./details-ad.organism.style";
import { InspectionScheduleOrganism } from "../../..";
import { WrapperDetails } from "../../../shared/shared.style";

export const DetailsAd: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();
  const { getAdInspectionByAdId, getAd, getToken, getEventHistoryById } =
    useManhein();
  const { getAllVehicleGrade } = useKbb();

  const [inspection, setInspection] = useState<IAdInspectionModel>();
  const [ad, setAd] = useState<AdModel>();
  const [show, setShow] = useState<boolean>();
  const [exception, setException] = useState<any>();
  const [message, setMessage] = useState<string>();
  const [completeInfoUrl, setCompleteInfoUrl] = useState<string>("");
  const [eventHistory, setEventHistory] = useState<IAdEventHistory[]>([]);
  const [vehicleGrades, setVehicleGrades] = useState<Combo[]>([]);

  const hasBackofficeWebhookPermission = useMemo(
    () =>
      validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeWebhook
      ),
    [userAuth0Context, validateUserAuth0Permission]
  );

  const hasBackofficeImpersonatePermission = useMemo(
    () =>
      validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeImpersonate
      ),
    [userAuth0Context, validateUserAuth0Permission]
  );

  const getAdAsync = useCallback(() => {
    getAdInspectionByAdId(Number(id)).then((adInspection) =>
      setInspection(adInspection)
    );
    getAd(Number(id)).then((adDetail) => setAd(adDetail));
  }, [getAdInspectionByAdId, id, getAd]);

  const getEventHistoryByIdAsync = useCallback(() => {
    getEventHistoryById(Number(id)).then(setEventHistory);
  }, [getEventHistoryById, id]);

  const getVehicleGradesAsync = useCallback(() => {
    getAllVehicleGrade().then((data) =>
      setVehicleGrades(
        data?.map((m) => new Combo({ title: t(m.name), value: m.id }))
      )
    );
  }, [getAllVehicleGrade, t]);

  useEffect(() => {
    getAdAsync();
    getVehicleGradesAsync();
    getEventHistoryByIdAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendEvent = useMemo(() => {
    return ad?.step === 2 && ad?.stepStatus === 0;
  }, [ad]);

  const sendEventFideliza = useMemo(() => {
    return (
      ad?.step === 2 && ad?.stepStatus === 0 && !!ad?.lenderAcceptanceValue
    );
  }, [ad]);

  useEffect(() => {
    if (!ad) return;
    if (!hasBackofficeImpersonatePermission) return;
    getToken().then((token) =>
      setCompleteInfoUrl(
        `${process.env.REACT_APP_NEWMANHEIN_URL}backoffice/login/${token}/${ad.id}`
      )
    );
  }, [ad, getToken, hasBackofficeImpersonatePermission]);

  const getWebhookSections = useCallback((): React.FC | JSX.Element => {
    if (!hasBackofficeWebhookPermission) return <></>;

    return (
      <>
        <div className={"section inspection webhooks"}>
          <Row>
            <Col>
              <h3>
                {ad && ad.step < AdStepEnum.AcceptedOffer
                  ? t("SendInspectionReport")
                  : t("SendSecondInspectionReport")}
              </h3>
              <hr />
            </Col>
          </Row>
          {!!ad?.partnerCatalogId ? (
            <AdInspectionMolecule
              enabled={
                (ad.step === AdStepEnum.Inspection &&
                  ad.stepStatus === AdSubStepInspectionEnum.PaidOut) ||
                (ad.step === AdStepEnum.Auction &&
                  ad.stepStatus === AdSubStepAuctionEnum.WaitingBackoffice) ||
                ad.step === AdStepEnum.AcceptedOffer
              }
              unicCode={ad.partnerCatalogId}
              setException={setException}
            />
          ) : (
            <i>{t("No information")}</i>
          )}
        </div>

        <div className={"section buyer-payment webhooks"}>
          <Row>
            <Col>
              <h3>{t("BuyerPaymentNotification")}</h3>
              <hr />
            </Col>
          </Row>
          {!!ad?.partnerCatalogId ? (
            <AdNotifyBuyerPaymentMolecule
              enabled={ad.step === AdStepEnum.AcceptedOffer}
              unicCode={ad.partnerCatalogId}
              setException={setException}
              adData={ad}
            />
          ) : (
            <i>{t("No information")}</i>
          )}
        </div>

        <div className={"section buyer-no-payment webhooks"}>
          <Row>
            <Col>
              <h3>{t("BuyerNoPaymentNotification")}</h3>
              <hr />
            </Col>
          </Row>
          {!!ad?.partnerCatalogId ? (
            <AdNotifyBuyerNoPaymentMolecule
              enabled={
                ad.step === AdStepEnum.AcceptedOffer &&
                ad.stepStatus === AdSubStepAcceptedOfferEnum.PendingPayment
              }
              unicCode={ad.partnerCatalogId}
              setException={setException}
            />
          ) : (
            <i>{t("No information")}</i>
          )}
        </div>

        <div className={"section release-payment webhooks"}>
          <Row>
            <Col>
              <h3>{t("ReleasePaymentNotification")}</h3>
              <hr />
            </Col>
          </Row>
          {!!ad?.partnerCatalogId ? (
            <AdNotifyReleasePaymentMolecule
              enabled={
                ad.step === AdStepEnum.AcceptedOffer &&
                ad.stepStatus === AdSubStepAcceptedOfferEnum.ScheduledDelivery
              }
              unicCode={ad.partnerCatalogId}
              setException={setException}
            />
          ) : (
            <i>{t("No information")}</i>
          )}
        </div>

        <div className={"section payment-to-seller webhooks"}>
          <Row>
            <Col>
              <h3>{t("PaymentToSellerNotification")}</h3>
              <hr />
            </Col>
          </Row>
          {!!ad?.partnerCatalogId ? (
            <AdNotifyPaymentToSellerMolecule
              enabled={
                ad.step === AdStepEnum.AcceptedOffer &&
                ad.stepStatus === AdSubStepAcceptedOfferEnum.CarDelivered
              }
              unicCode={ad.partnerCatalogId}
              setException={setException}
            />
          ) : (
            <i>{t("No information")}</i>
          )}
        </div>
      </>
    );
  }, [ad, hasBackofficeWebhookPermission, t]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (exception) {
      const formatMessage = JSON.stringify(exception)
        ?.replace("\n", "<br />")
        .replaceAll('"', "");
      setMessage(formatMessage);
      setShow(false);
    }
  }, [exception]);

  return (
    <WrapperDetails>
      <FeedbackModal
        type={show ? "success" : "error"}
        show={show !== undefined}
        onHide={() => setShow(undefined)}
        content={show ? t("TitleShareVehicleSuccess") : t("api_error")}
      >
        <FeedbackModalContent>{message}</FeedbackModalContent>
      </FeedbackModal>

      <div className={"section main"}>
        <Row>
          <Col>
            <h3>{t("Ad")}</h3>
            <hr />
          </Col>
        </Row>
        {ad ? <AdMainDetailsMolecule ad={ad} /> : <i>{t("No information")}</i>}
      </div>

      <div className={"section customer"}>
        <Row>
          <Col>
            <h3>{t("Client")}</h3>
            <hr />
          </Col>
        </Row>
        {ad?.customer ? (
          <AdCustomerMolecule
            customer={ad.customer}
            enableEdit={
              hasBackofficeWebhookPermission &&
              (ad.step !== AdStepEnum.Auction ||
                ad.stepStatus !== AdSubStepAuctionEnum.Published)
            }
            setException={setException}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section vehicle"}>
        <Row>
          <Col>
            <h3>{t("VehicleData")}</h3>
            <hr />
          </Col>
        </Row>
        {ad ? (
          <AdDetailsMolecule ad={ad} vehicleGrades={vehicleGrades} />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section vehicle-optionals"}>
        <Row>
          <Col>
            <h3>{t("VehicleOptionals")}</h3>
            <hr />
          </Col>
        </Row>
        {ad ? (
          <AdDetailsOptionalsMolecule ad={ad} />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section vehicle-optionals"}>
        <Row>
          <Col>
            <h3>{t("VehicleChecklist")}</h3>
            <hr />
          </Col>
        </Row>
        {ad?.adVehicleRatedItems?.length ? (
          <AdDetailsChecklistMolecule items={ad.adVehicleRatedItems} />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section vehicle-photos"}>
        <Row>
          <Col>
            <h3>{t("VehiclePhotos")}</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          {ad?.adVehiclePictures?.length ? (
            <Col
              xs={12}
              sm={9}
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              {
                <AdDetailsPhotosMolecule
                  ad={ad}
                  adInspectionId={inspection?.id}
                  showReportPhotos={ad.step > AdStepEnum.Registered}
                />
              }
            </Col>
          ) : (
            <Col>{<i>{t("No information")}</i>}</Col>
          )}
        </Row>
      </div>

      <div className={"section inspection-report"}>
        <Row>
          <Col>
            <h3>{t("InspectionTitle")}</h3>
            <hr />
          </Col>
        </Row>
        {inspection ? (
          <AdInspectionReportMolecule
            inspection={inspection}
            permission={hasBackofficeWebhookPermission}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section inspection-report-history"}>
        <Row>
          <Col>
            <h3>{t("InspectionHistory")}</h3>
            <hr />
          </Col>
        </Row>
        {inspection?.reportLogs?.length ? (
          <HorizontalScrollDiv>
            <AdInspectionReportHistoryMolecule
              reportLogs={inspection.reportLogs}
            />
          </HorizontalScrollDiv>
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section inspection-schedule"}>
        <Row>
          <Col>
            <h3>{t("InspectionSchedule")}</h3>
            <hr />
          </Col>
        </Row>
        {inspection && ad ? (
          <InspectionScheduleOrganism
            ad={ad}
            adInspection={inspection}
            setException={setException}
          />
        ) : (
          // <AdInspectionScheduleMolecule ad={ad} adInspection={inspection} />
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section status-history"}>
        <Row>
          <Col>
            <h3>{t("StatusHistory")}</h3>
            <hr />
          </Col>
        </Row>
        {ad?.adStatuses ? (
          <>
            <AdStatusesMolecule statuses={ad?.adStatuses} />
            <DetailsPageSubsection title={t("SetAdStepSubsection")}>
              <AdSetStepMolecule
                enabled={hasBackofficeWebhookPermission}
                adId={ad.id}
                setException={setException}
              />
            </DetailsPageSubsection>
          </>
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section event"}>
        <Row>
          <Col>
            <h3>{t("BiddingEvent")}</h3>
            <hr />
          </Col>
        </Row>
        {ad ? (
          <AdEventMolecule
            ad={ad}
            enabled={!ad.lenderOperatorId ? sendEvent : sendEventFideliza}
            setException={setException}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section event-history"}>
        <Row>
          <Col>
            <h3>{t("BiddingEventHistory")}</h3>
            <hr />
          </Col>
        </Row>
        {eventHistory.length > 0 ? (
          <>
            <AdEventHistoryMolecule
              step={ad?.step}
              stepStatus={ad?.stepStatus}
              eventHistory={eventHistory}
            />
          </>
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section offer"}>
        <Row>
          <Col>
            <h3>{t("LastOffer")}</h3>
            <hr />
          </Col>
        </Row>
        {ad?.lastOffer ? (
          <AdOfferMolecule offer={ad.lastOffer} />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section edit-dut-crv"}>
        <Row>
          <Col>
            <h3>{t("DutCrvData")}</h3>
            <hr />
          </Col>
        </Row>
        {!!ad?.id ? (
          <DutCrvMolecule
            data={{
              id: ad.id,
              buyerName: ad.buyerName ?? "",
              buyerDocument: ad.buyerDocument ?? "",
              buyerAddress: ad.buyerAddress ?? "",
            }}
            enable={hasBackofficeWebhookPermission}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section bidding-history"}>
        <Row>
          <Col>
            <h3>{t("BiddingHistory")}</h3>
            <hr />
          </Col>
        </Row>
        {ad?.allOffers?.length ? (
          <AdOffersListMolecule
            allOffers={ad?.allOffers}
            hasEditPermission={hasBackofficeWebhookPermission}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      {getWebhookSections()}

      <div className={"section split"}>
        <Row>
          <Col>
            <h3>{t("Split")}</h3>
            <hr />
          </Col>
        </Row>
        {!!ad ? (
          <AdSplitMolecule
            enabled={
              ad.step === AdStepEnum.AcceptedOffer &&
              ad.stepStatus === AdSubStepAcceptedOfferEnum.ReceivedPayment
            }
            data={
              {
                adId: ad.id,
                finalTrafficPenaltyValue: ad.finalTrafficPenaltyValue,
                otherPenaltyValue: ad.otherPenaltyValue,
                reasonSplitValue: ad.reasonSplitValue,
              } as ISplit
            }
            setException={setException}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <div className={"section cancel"}>
        <Row>
          <Col>
            <h3>{t("Cancellation")}</h3>
            <hr />
          </Col>
        </Row>
        {!!ad ? (
          <AdCancelMolecule
            enabled={true}
            adId={ad.id}
            setException={setException}
          />
        ) : (
          <i>{t("No information")}</i>
        )}
      </div>

      <hr />
      <Form.Row className={"mt-5"}>
        <Form.Group as={Col} md={6}>
          <Link to={paths.backofficeAdListPage()}>
            <ButtonSecondary sizex={"md"}>{t("Return")}</ButtonSecondary>
          </Link>
        </Form.Group>

        {completeInfoUrl !== "" && (
          <Form.Group as={Col} md={3}>
            <a target="blank" href={completeInfoUrl}>
              <ButtonSecondary sizex={"md"}>
                {t("See Complete Info")}
              </ButtonSecondary>
            </a>
          </Form.Group>
        )}
      </Form.Row>
    </WrapperDetails>
  );
};
