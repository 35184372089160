import styled from "styled-components";

const BrandsContainer = styled.div`
  position: absolute;
  height: 61px;

  @media (max-width: 575.98px) {
    width: calc(76.5%);
    left: calc(10%);
    top: calc(7%);
  }
  @media screen and (min-width: 575.99px) {
    left: calc(10%);
    top: calc(7%);
    width: calc(81.5%);
  }
  @media screen and (min-width: 767.98px) {
    width: 487.81px;
    left: calc(30%);
    top: calc(15%);
  }
  @media screen and (min-width: 991.98px) {
    left: calc(47%);
    top: calc(15%);
  }
  @media screen and (min-width: 1199.98px) {
    left: calc(56.5%);
    top: calc(15%);
  }
  @media screen and (min-width: 1366px) {
    left: calc(58.6%);
    top: calc(15%);
  }
`;

const FooterContainer = styled.div`
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  bottom: calc(5%);
`;

const FormContainer = styled.div`
  position: relative;

  @media screen and (max-width: 575.98px) {
    display: inline-block;
    width: calc(76.5%);
    left: calc(10%);
  }
  @media screen and (min-width: 575.98px) {
    width: calc(81.5%);
    left: calc(9.66%);
  }
  @media screen and (min-width: 991.98px) {
    width: calc(85%);
    left: calc(9.66%);
  }
  @media screen and (min-width: 1199.98px) {
    width: calc(86.3%);
    left: calc(9.66%);
  }
  @media screen and (min-width: 1366px) {
    width: calc(83%);
  }
  @media screen and (min-width: 1500px) {
    width: calc(83%);
  }
`;

const FormFields = styled.div`
  padding: 70px 20px 40px 20px;
`;

const FullBG = styled.div`
  background-color: ${(props) => props.theme.colors.BlueNavy};
  background-size: auto;
  width: 100%;

  @media screen and (max-width: 575.98px) {
    padding-top: calc(35%);
    padding-bottom: calc(5%);
  }
  @media screen and (min-width: 575.98px) {
    padding-top: calc(21%);
    padding-bottom: calc(5%);
  }
  @media screen and (min-width: 1500.98px) {
    padding-top: calc(15.5%);
    padding-bottom: calc(5%);
  }

`;

const LogoC4uContainer = styled.div`
  position: absolute;
  z-index: 100;

  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 767.98px) {
    display: block;
    left: calc(3%);
    top: calc(10%);
  }
  @media screen and (min-width: 991.98px) {
    left: calc(03%);
    top: calc(15%);
  }
  @media screen and (min-width: 1199.98px) {
    left: calc(10%);
    top: calc(15%);
  }
  @media screen and (min-width: 1366px) {
    left: calc(12%);
    top: calc(30% / 1.9);
  } ;
`;

const VectorContainer = styled.div`
  position: absolute;
  width: 471.82px;
  height: 472px;

  @media screen and (max-width: 1199.98px) {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    left: calc(60%);
    bottom: calc(6%);
  }
  @media screen and (min-width: 1366px) {
    left: calc(62%);
    bottom: calc(5%);
  } ;
`;

export {
  BrandsContainer,
  FooterContainer,
  FormContainer,
  FormFields,
  FullBG,
  LogoC4uContainer,
  VectorContainer,
};
