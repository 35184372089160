import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  LogoPortoSeguro_110_35,
  LogoLiberty_110_57,
  LogoAzulSeguros_159_95,
  LogoIturan_99_51,
  LogoSuperVisao_97_92,
  LogoZapay_108_46,
  PartnerHQ,
} from "../../../assets";
import {
  AboutAzulSeguros,
  AboutIturan,
  AboutLiberty,
  AboutPortoSeguro,
  AboutSupervisao,
  AboutZapay,
} from "../../../organisms";
import {
  CardPartners,
  CardPartnersWrapper,
  ContentWrapper,
  SlideWrapper,
  Title,
} from "./about-partners.page.style";

import { Container } from "react-bootstrap";
import { useSessionContext } from "../../../../hooks";

export const AboutPartnersPage = () => {
  const [partnerSelected, setPartnerSelected] = useState<string>("");

  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("NotActive");
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();

  function handlePartnerSelected() {
    if (partnerSelected) {
      switch (partnerSelected) {
        case "liberty": {
          return <AboutLiberty />;
        }
        case "azulSeguros": {
          return <AboutAzulSeguros />;
        }
        case "ituran": {
          return <AboutIturan />;
        }
        case "superVisao": {
          return <AboutSupervisao />;
        }
        case "zapay": {
          return <AboutZapay />;
        }

        default: {
          return <AboutPortoSeguro />;
        }
      }
    }
  }

  return (
    <>
      <Container>
        <ContentWrapper className="px-md-5 px-xl-0">
          <Title>{t("partners")}:</Title>
          <CardPartnersWrapper className="d-flex d-lg-none">
            <SlideWrapper>
              <CardPartners
                selected={partnerSelected === "portoSeguro"}
                onClick={() => setPartnerSelected("portoSeguro")}
              >
                <img alt="Porto Seguro Logo" src={LogoPortoSeguro_110_35} />
              </CardPartners>
              <CardPartners
                selected={partnerSelected === "liberty"}
                onClick={() => setPartnerSelected("liberty")}
              >
                <img alt="Liberty Seguros Logo" src={LogoLiberty_110_57} />
              </CardPartners>
              <CardPartners
                selected={partnerSelected === "azulSeguros"}
                onClick={() => setPartnerSelected("azulSeguros")}
              >
                <img alt="Azul Seguros Logo" src={LogoAzulSeguros_159_95} />
              </CardPartners>
              <CardPartners
                selected={partnerSelected === "ituran"}
                onClick={() => setPartnerSelected("ituran")}
              >
                <img alt="Ituran Logo" src={LogoIturan_99_51} />
              </CardPartners>
              <CardPartners
                selected={partnerSelected === "superVisao"}
                onClick={() => setPartnerSelected("superVisao")}
              >
                <img alt="SuperVisao Logo" src={LogoSuperVisao_97_92} />
              </CardPartners>
              <CardPartners
                selected={partnerSelected === "zapay"}
                onClick={() => setPartnerSelected("zapay")}
              >
                <img alt="Zapay Logo" src={LogoZapay_108_46} />
              </CardPartners>
            </SlideWrapper>
          </CardPartnersWrapper>

          <CardPartnersWrapper className="d-none d-lg-flex">
            <CardPartners
              selected={partnerSelected === "portoSeguro"}
              onClick={() => setPartnerSelected("portoSeguro")}
            >
              <img alt="Porto Seguro Logo" src={LogoPortoSeguro_110_35} />
            </CardPartners>
            <CardPartners
              selected={partnerSelected === "liberty"}
              onClick={() => setPartnerSelected("liberty")}
            >
              <img alt="Liberty Seguros Logo" src={LogoLiberty_110_57} />
            </CardPartners>
            <CardPartners
              selected={partnerSelected === "azulSeguros"}
              onClick={() => setPartnerSelected("azulSeguros")}
            >
              <img alt="Azul Seguros Logo" src={LogoAzulSeguros_159_95} />
            </CardPartners>
            <CardPartners
              selected={partnerSelected === "ituran"}
              onClick={() => setPartnerSelected("ituran")}
            >
              <img alt="Ituran Logo" src={LogoIturan_99_51} />
            </CardPartners>
            <CardPartners
              selected={partnerSelected === "superVisao"}
              onClick={() => setPartnerSelected("superVisao")}
            >
              <img alt="SuperVisao Logo" src={LogoSuperVisao_97_92} />
            </CardPartners>
            <CardPartners
              selected={partnerSelected === "zapay"}
              onClick={() => setPartnerSelected("zapay")}
            >
              <img alt="Zapay Logo" src={LogoZapay_108_46} />
            </CardPartners>
          </CardPartnersWrapper>

          {handlePartnerSelected()}
          {partnerSelected === "" && (
            <img alt="Partner HQ" src={PartnerHQ} className={"img-hq"} />
          )}
        </ContentWrapper>
      </Container>
    </>
  );
};
