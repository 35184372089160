import styled from "styled-components";

export const BaseTextStyle = styled.div`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const Title = styled(BaseTextStyle)`
  margin-bottom: 50px;

  color: #ff6e00;

  font-weight: bold;
  font-size: 30px;
`;

export const Content = styled(BaseTextStyle)`
  strong {
    color: #4a4a4a;

    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.15px;
  }
`;

export const BenefitsContent = styled.div`
  margin: 80px 0 40px;
`;

export const SubTitle = styled(BaseTextStyle)`
  color: #072f77;

  font-weight: bold;
  letter-spacing: -0.15px;
`;

export const DifferentialsList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 50px 0;

  @media (max-width: 1199px) {
    justify-content: center;
  }
`;

export const DifferentialsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 260px;
  margin: 0 20px 20px 20px;

  svg {
    margin-bottom: 15px;
  }

  p {
    color: #ff6e00;

    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.15px;
  }
`;

export const FinancingList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;
  }

  margin: 20px 0;
`;

export const ListItem = styled.li`
  width: 500px;
  margin: 50px 0;

  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;

  &:before {
    content: "·";
    position: absolute;

    margin-left: -20px;

    font-size: 30px;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const BenefitsItem = styled(BaseTextStyle)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 220px;

  text-align: center;

  svg {
    margin-bottom: 15px;
  }

  p,
  strong {
    color: #4a4a4a;

    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`;

export const Link = styled.a`
  color: #ff6e00;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  text-decoration-line: underline;

  :hover {
    color: #ff6e00 !important;

    text-decoration-line: underline !important;
  }
`;

export const ButtonContent = styled.div`
  margin-top: 100px;

  button {
    color: #ffffff;
    background-color: #ff6e00;
    transition: all 0.4s;

    :hover {
      color: #ffffff;
      background-color: #ff6e00;
    }
  }
`;
