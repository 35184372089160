import { CoxIcon, DateHelper, TypesHelper } from "c4u-web-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../../constants";
import { StringHelper } from "../../../../../../helper";
import { useKbbBackoffice } from "../../../../../../hooks";
import {
  CoxUserIsActiveBooleanEnum,
  IGetApiUserResponse,
} from "../../../../../../models";
import { ApiUsersListTableSearchOrganism } from "./api-users-list-table-search.organism";

export const ApiUsersListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getApiUsers } = useKbbBackoffice();

  const getEditButton = useCallback(
    (id: string | number, i: number) => (
      <Link
        key={`link${i}`}
        to={paths.backofficeApiUserDetailsPage(id)}
        title={t("Edit")}
      >
        <CoxIcon
          name="pencil-edit"
          width={16}
          height={16}
          fillColor="#1F3E74"
        />
      </Link>
    ),
    [t]
  );

  return (
    <>
      <ApiUsersListTableSearchOrganism
        getDataCallback={getApiUsers}
        UserActive={CoxUserIsActiveBooleanEnum}
        tableName="api-user-list"
        tableHeaders={[
          "#",
          t("Name"),
          t("Cnpj"),
          t("Email"),
          t("Active"),
          t("CreatedAt"),
          t("ExpirationDate"),
          t("PartnerConsultation"),
          "",
        ]}
        mappingFunction={(m: IGetApiUserResponse, i) => [
          m.id,
          m.name,
          StringHelper.formatCpfCnpj(m.cnpj),
          m.email,
          TypesHelper.toBooleanNull(m.active) ? t("Yes") : t("No"),
          DateHelper.dateHourFormat(m.createdAt),
          DateHelper.dateFormat(m.expirationDate),
          TypesHelper.toBooleanNull(m.canCallPartner) ? t("Yes") : t("No"),
          getEditButton(m.id, i),
        ]}
      />
    </>
  );
};
