import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../../..";
import {
  DateHelper,
  renderViewHelper,
  StringHelper,
} from "../../../../../../helper";
import {
  ITrackerProposalDetails,
  TrackerStepEnum,
} from "../../../../../../models";

interface IProps {
  proposalDetails?: ITrackerProposalDetails;
}

export const TrackerProposalDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewProposal = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.proposalDetails, key, label, func);

  return !props.proposalDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewProposal("createdAt", t("Date"), DateHelper.dateHourFormat)}
        {renderViewProposal(
          "step",
          t("Step"),
          (v) => `${v + 1} - ${t(TrackerStepEnum[v])}`
        )}
        {renderViewProposal("partnerQuoteItemId", t("PartnerQuoteItemId"))}
      </Form.Row>

      <DetailsPageSubsection title={t("DealershipDetails")}>
        <Form.Row>
          {renderViewProposal(
            "businessNumber",
            t("DealershipCnpj"),
            StringHelper.formatCpfCnpj
          )}
        </Form.Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("ScheduledInspectionDetails")}>
        <Form.Row>
          {renderViewProposal("partnerOrderId", t("PartnerOrderId"))}
          {renderViewProposal("partnerShopId", t("PartnerShopId"))}
          {renderViewProposal("schedulingDate", t("SchedulingDate"))}
          {renderViewProposal("partnerPeriodNumber", t("PartnerPeriodNumber"))}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
