import { CoxIcon } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form } from "react-bootstrap";
import { IFoundVehicles } from "../../../../models";
import { Wrapper, WrapperDetails, Details } from "./details.molecule.style";

interface IProps {
  data: IFoundVehicles;
  showInitial: boolean;
}

export const VehicleDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [seeMore, setSeemore] = useState(false);

  const renderView = (
    key: string,
    label: string,
    size?: number,
    func?: (attr: any) => string
  ) => {
    const values = props.data as any;

    const valueFormated = func ? func(values[key]) : values[key];

    return (
      <Form.Group as={Col} md={size ? size : 4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated ?? "-"} disabled={true} />
      </Form.Group>
    );
  };

  useEffect(() => {
    if (props.showInitial) setSeemore(true);
  }, [props.showInitial]);

  return (
    <Wrapper>
      <>
        <h5>
          {t("Model")}: {props.data.version}
        </h5>
        <WrapperDetails
          onClick={() => setSeemore(!seeMore)}
          data-element="dropdown-more-details"
        >
          <Details close={seeMore}>{t("More details")}</Details>
          <CoxIcon name={!seeMore ? "arrow-circle" : "arrow-circle-closed"} />
        </WrapperDetails>
        {seeMore && (
          <>
            <hr />
            <Form.Row className="p-2">
              {renderView("fipeId", "Fipe ID", 3)}
              {renderView("ituranId", "Ituran ID", 3)}
              {renderView("kbbId", "KBB ID", 3)}
              {renderView("molicarId", "Molicar ID", 3)}
              {renderView("brand", t("Brand"))}
              {renderView("modelYear", t("ModelYear"))}
              {renderView("version", t("Version"))}
              {renderView("model", t("Model"))}
              {renderView("cc", t("CC"))}
            </Form.Row>
          </>
        )}
      </>
    </Wrapper>
  );
};
