import { useAuth0 } from "@auth0/auth0-react";
import {
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IDealershipComboData,
  IUser,
  StorageConstant,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { LoginHelper } from "../../../helper";
import { useC4u, useSessionContext } from "../../../hooks";
import { ApplicationTermEnum, TypeTermEnum } from "../../../models";
import { Container } from "./main.template.style";

export const MainTemplate: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const {
    actualPageContext,
    handleEventGTM,
    auth0UserContext,
    setAuth0UserContext,
    userContext,
    setUserContext,
    termsOfUse,
    setTermsOfUse,
  } = useSessionContext();

  const {
    GetAuth0User,
    GetUser,
    postSelectDealership,
    getListMyDealerships,
    getCustomerTerms,
  } = useC4u();

  const [isEvent, setIsEvent] = useState(true);
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);
  const [selectedDealership, setSelectedDealership] =
    useState<IDealershipComboData>();

  useEffect(() => {
    LoginHelper.setCookieLogin();
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
      sessionStorage.setItem(StorageConstant.redirectedFromLogin, "true");
    } else if (!isLoading && isAuthenticated) {
      GetAuth0User().then((t) => setAuth0UserContext(t));
      GetUser().then((t) => {
        setUserContext(t);
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const getCustomerTermsAsync = useCallback(async () => {
    const items = await getCustomerTerms(
      ApplicationTermEnum.CoxPraVoce,
      TypeTermEnum.TermOfUse,
      userContext.email
    );
    if (items.accepted === false) {
      history.push(paths.termsOfUse());
    }
    setTermsOfUse(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerTerms, userContext]);

  useEffect(() => {
    if (userContext && auth0UserContext) {
      setUser(AuthHelper.GetCoxUser(userContext, auth0UserContext));
      const { dealershipId, dealershipName, dealershipCnpj } = userContext;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealership({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
  }, [auth0UserContext, userContext]);

  useEffect(() => {
    if (isAuthenticated && userContext && isEvent) {
      setIsEvent(false);
      handleEventGTM({
        event: "userInformation",
        userId: userContext.id,
        userStatus: "logged-in",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userContext]);

  const handleLogout = () => {
    sessionStorage.removeItem("dismiss-headline");
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    if (isAuthenticated && userContext) {
      getCustomerTermsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userContext]);

  return (
    <>
      {termsOfUse?.accepted && (
        <Container fluid className={"p-0"}>
          <C4UMenu
            handleLogout={handleLogout}
            activeMenu={actualPageContext.toString()}
            environment={process.env.REACT_APP_BUILD}
            user={user}
            selectDealershipProps={
              !user
                ? undefined
                : {
                    userId: userContext?.id,
                    userIsManager:
                      userContext?.corporateUserType ===
                      CorporateUserTypeEnum.Manager,
                    userIsCorporate: userContext?.isCorporate,
                    dealership: selectedDealership,
                    onSubmitCallback: postSelectDealership,
                    getDealershipsPagedListCallback: getListMyDealerships,
                    setSelectedDealership: setSelectedDealership,
                    onHideCallback: clearKeysFromStorage,
                    forceShowModal: redirectedFromLogin,
                  }
            }
            translate={t}
          />
          {props.children}
        </Container>
      )}
    </>
  );
};
