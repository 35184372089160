import { DateHelper, NumberHelper, TypesHelper } from "c4u-web-components";
import { addHours, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TableList } from "../../..";
import {
  AdStepEnum,
  AdSubStepAuctionEnum,
  IAdEventHistory,
} from "../../../../../models";

interface IProps {
  step?: number;
  stepStatus?: number;
  eventHistory: IAdEventHistory[];
}

export const AdEventHistoryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [currentTimeFinalEvent, setcurrentTimeFinalEvent] =
    useState<string>("N/D");

  useEffect(() => {
    if (props.eventHistory.length > 0 && props.step && props.stepStatus) {
      const dateNow = new Date();

      const maxEvent = props?.eventHistory?.reduce(function (a, b) {
        return a.createdAt > b.createdAt ? a : b;
      });

      const maxDateDuration = addHours(
        new Date(maxEvent.createdAt),
        maxEvent.durationHours
      );

      if (
        maxDateDuration >= dateNow &&
        props.step === AdStepEnum.Auction &&
        props.stepStatus === AdSubStepAuctionEnum.Published
      ) {
        setcurrentTimeFinalEvent(
          DateHelper.getCounterTime(dateNow, maxDateDuration) ?? "N/D"
        );
      }
    }
  }, [props.eventHistory, props.step, props.stepStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} md={2}>
          <Form.Label>{t("CurrentEndTime")}</Form.Label>
          <Form.Control value={currentTimeFinalEvent} disabled={true} />
        </Form.Group>
      </Form.Row>

      <TableList
        thead={[
          t("MinimumValue"),
          t("MinimumStepValue"),
          t("DurationHours"),
          t("Date"),
        ]}
      >
        {props.eventHistory?.map((m, i) => (
          <tr key={`tr${i}`}>
            <td>{`R$ ${NumberHelper.toFormatString(m.minimumValue, 2)}`}</td>
            <td>{`R$ ${NumberHelper.toFormatString(
              m.minimumStepValue,
              2
            )}`}</td>
            <td>{m.durationHours}</td>
            <td>
              {format(
                TypesHelper.toDateTimeNull(m.createdAt) ?? new Date(1, 1, 1),
                "dd/MM/yyyy HH:mm:ss"
              )}
            </td>
          </tr>
        ))}
      </TableList>
    </>
  );
};
