import {
  DateHelper,
  EnumHelper,
  FormikControlAtom,
  NumberHelper,
  TrackerPartnerEnum,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GenericTableSearchOrganism } from "../..";
import { StringHelper } from "../../../../helper";
import { useProducts } from "../../../../hooks";
import {
  GetLogReportParams,
  IGetLogReportParams,
  IGetTrackerQuotesErrorsLogResponse,
  IGetTrackerQuotesLogResponse,
  IGetTrackerStepsLogResponse,
  IPagedResponseGenericModel,
  LogReportTypeEnum,
  NoReplyReasonEnum,
  OriginEnum,
  TrackerStepEnum,
} from "../../../../models";
import {
  LogReportSearchFormMolecule,
  LogReportSearchFormValidation,
} from "../../../molecules";
import { addDays } from "date-fns";

interface IFormTeste {
  reportType: LogReportTypeEnum;
}

export const TrackerLogReportsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const {
    getTrackerQuotesLogs,
    getTrackerQuotesErrorsLog,
    getTrackerStepsLog,
    downloadTrackerStepsLog,
    downloadTrackerQuotesLog,
    downloadTrackerQuotesErrorsLog,
  } = useProducts();

  const [firstLoad, setFirstLoad] = useState(true);
  const [goResetAndUpdate, setGoResetAndUpdate] = useState(false);

  const formik = useFormik<IFormTeste>({
    initialValues: { reportType: LogReportTypeEnum.QuotesReturned },
    onSubmit: () => {},
    validateOnBlur: false,
    validateOnChange: false,
  });

  const initialSearchValues = useMemo<IGetLogReportParams>(() => {
    const dateNow = new Date();
    return {
      pageSize: 50,
      page: 1,
      search: "",
      origin: "",
      startDate: DateHelper.dateHourCustomFormat(
        addDays(dateNow, -1),
        "yyyy-MM-dd"
      ),
      endDate: DateHelper.dateHourCustomFormat(dateNow, "yyyy-MM-dd"),
      partnerEnum: "",
    };
  }, []);

  const headersQuotesReturned = useMemo(
    () => [
      t("TrackerId"),
      t("InsuranceId"),
      t("ProductName"),
      t("DealershipCNPJ"),
      t("DealershipName"),
      t("TrackerBeneficiaryCpf"),
      t("ProductUserId"),
      t("ProductUserName"),
      t("ProductUserEmail"),
      t("VehiclePlateChassi"),
      t("PartnerName"),
      t("PartnerDuration"),
      t("AnnualValue"),
      t("Commission"),
      t("TrackerOrigin"),
      t("CpvStartDate"),
    ],
    [t]
  );

  const mappingQuotesReturned = useCallback(
    (m: IGetTrackerQuotesLogResponse) => [
      m.trackerId,
      m.insuranceId,
      m.productName,
      StringHelper.formatCpfCnpj(m.dealershipCNPJ),
      m.dealershipName,
      StringHelper.formatCpfCnpj(m.customerCpf),
      m.productUserId,
      m.productUserName,
      m.productUserEmail,
      m.vehicle,
      !!m.company ? TrackerPartnerEnum[m.company] : undefined,
      m.partnerDuration,
      NumberHelper.toFormatCurrency(m.annualValue),
      NumberHelper.toFormatCurrency(m.commission),
      !!m.trackerOrigin || m.trackerOrigin === 0
        ? t(OriginEnum[m.trackerOrigin])
        : undefined,
      DateHelper.dateHourFormat(m.cpvStartDate),
    ],
    [t]
  );

  const headersQuotesErrors = useMemo(
    () => [
      t("TrackerId"),
      t("InsuranceId"),
      t("ProductName"),
      t("DealershipCNPJ"),
      t("DealershipName"),
      t("TrackerBeneficiaryCpf"),
      t("ProductUserId"),
      t("ProductUserName"),
      t("ProductUserEmail"),
      t("VehiclePlateChassi"),
      t("PartnerName"),
      t("PartnerDuration"),
      t("Commission"),
      t("TrackerOrigin"),
      t("CpvStartDate"),
      t("NoReplyReasonEnum"),
      t("PartnerErrorMessage"),
    ],
    [t]
  );

  const mappingQuotesErrors = useCallback(
    (m: IGetTrackerQuotesErrorsLogResponse) => [
      m.trackerId,
      m.insuranceId,
      m.productName,
      StringHelper.formatCpfCnpj(m.dealershipCNPJ),
      m.dealershipName,
      StringHelper.formatCpfCnpj(m.customerCpf),
      m.productUserId,
      m.productUserName,
      m.productUserEmail,
      m.vehicle,
      !!m.company ? TrackerPartnerEnum[m.company] : undefined,
      m.partnerDuration,
      NumberHelper.toFormatCurrency(m.commission),
      !!m.trackerOrigin || m.trackerOrigin === 0
        ? t(OriginEnum[m.trackerOrigin])
        : undefined,
      DateHelper.dateHourFormat(m.cpvStartDate),
      !!m.noReplyReason || m.noReplyReason === 0
        ? t(NoReplyReasonEnum[m.noReplyReason])
        : undefined,
      m.partnerErrorMessage,
    ],
    [t]
  );

  const headerSteps = useMemo(
    () => [
      t("TrackerId"),
      t("InsuranceId"),
      t("Step"),
      t("DealershipCNPJ"),
      t("DealershipName"),
      t("TrackerBeneficiaryCpf"),
      t("ProductUserId"),
      t("ProductUserName"),
      t("ProductUserEmail"),
      t("VehiclePlateChassi"),
      t("Commission"),
      t("TrackerOrigin"),
      t("DateTime"),
    ],
    [t]
  );

  const mappingSteps = useCallback(
    (m: IGetTrackerStepsLogResponse) => [
      m.trackerId,
      m.insuranceId,
      !!m.step || m.step === 0 ? t(TrackerStepEnum[m.step]) : undefined,
      StringHelper.formatCpfCnpj(m.dealershipCNPJ),
      m.dealershipName,
      StringHelper.formatCpfCnpj(m.customerCpf),
      m.productUserId,
      m.productUserName,
      m.productUserEmail,
      m.vehicle,
      NumberHelper.toFormatCurrency(m.commission),
      !!m.trackerOrigin || m.trackerOrigin === 0
        ? t(OriginEnum[m.trackerOrigin])
        : undefined,
      DateHelper.dateHourFormat(m.dateTime),
    ],
    [t]
  );

  const commomProps = {
    RequestClass: GetLogReportParams,
    FormComponent: LogReportSearchFormMolecule,
    validationSchema: LogReportSearchFormValidation(),
    initialValues: initialSearchValues,
    goResetAndUpdate: goResetAndUpdate,
    reportType: "Tracker",
  };

  const reportType = Number(formik.values.reportType);
  let trackerTableSearchOrganism: React.ReactElement;
  switch (reportType) {
    case LogReportTypeEnum.StepsMapping:
      trackerTableSearchOrganism = (
        <GenericTableSearchOrganism<
          IGetLogReportParams,
          GetLogReportParams,
          IPagedResponseGenericModel<IGetTrackerStepsLogResponse>,
          IGetTrackerStepsLogResponse
        >
          {...commomProps}
          tableName="log-reports"
          getDataCallback={getTrackerStepsLog}
          tableHeaders={headerSteps}
          mappingFunction={mappingSteps}
          downloadAllResultsCallback={downloadTrackerStepsLog}
        />
      );
      break;

    case LogReportTypeEnum.NoQuotesReturned:
      trackerTableSearchOrganism = (
        <GenericTableSearchOrganism<
          IGetLogReportParams,
          GetLogReportParams,
          IPagedResponseGenericModel<IGetTrackerQuotesErrorsLogResponse>,
          IGetTrackerQuotesErrorsLogResponse
        >
          {...commomProps}
          tableName="log-reports"
          getDataCallback={getTrackerQuotesErrorsLog}
          tableHeaders={headersQuotesErrors}
          mappingFunction={mappingQuotesErrors}
          downloadAllResultsCallback={downloadTrackerQuotesErrorsLog}
        />
      );
      break;

    case LogReportTypeEnum.QuotesReturned:
    default:
      trackerTableSearchOrganism = (
        <GenericTableSearchOrganism<
          IGetLogReportParams,
          GetLogReportParams,
          IPagedResponseGenericModel<IGetTrackerQuotesLogResponse>,
          IGetTrackerQuotesLogResponse
        >
          {...commomProps}
          tableName="log-reports"
          getDataCallback={getTrackerQuotesLogs}
          tableHeaders={headersQuotesReturned}
          mappingFunction={mappingQuotesReturned}
          downloadAllResultsCallback={downloadTrackerQuotesLog}
        />
      );
      break;
  }

  useEffect(() => {
    if (!firstLoad) setGoResetAndUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.reportType]);

  useEffect(() => {
    if (goResetAndUpdate) setGoResetAndUpdate(false);
  }, [goResetAndUpdate]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <>
      <Row>
        <FormikControlAtom
          formik={formik}
          sm={4}
          md={3}
          type={"select"}
          data={EnumHelper.getComboFromEnum(LogReportTypeEnum, t)}
          property={"reportType"}
          label={t("ReportType")}
          translate={t}
          hideSelectOption
        />
      </Row>
      {trackerTableSearchOrganism}
    </>
  );
};
