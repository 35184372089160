import { ButtonSecondary, CoxIcon, SortHelper } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { FeedbackModal, FormUsersMolecule } from "../../../molecules";
import {
  IconTrash,
  WrapperBackground,
  WrapperUsers,
} from "./edit-profile-users.organism.style";
import {
  IFormUsersMolecule,
  IFormUsersMoleculeItems,
} from "../../../molecules/profile/form-users/form-users.molecule";
import { useTranslation } from "react-i18next";
import { useC4u, useSessionContext } from "../../../../hooks";
import { UpdateUserRequest } from "../../../../models";
import { formikHandleErrorHelper } from "../../../../helper";

interface IProps {
  formId: string;
}

export const EditProfileUsersOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userContext, setSubmitingContext, submitingContext } =
    useSessionContext();

  const { GetUsersCreatedBy, UpdateUser, RemoveUser } = useC4u();

  const [users, setUsers] = useState<IFormUsersMoleculeItems[]>();
  const load = async () => {
    const users = await GetUsersCreatedBy(userContext.publicId);

    setUsers(
      users.map((m, i) => {
        return {
          index: i,
          id: m.id,
          email: m.email,
          name: m.name,
          cellPhone: m.cellPhone,
        } as IFormUsersMoleculeItems;
      })
    );
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  const spinner = () => {
    return (
      <div className={"h-100 d-flex align-items-center justify-content-center"}>
        <Spinner animation="border" />
      </div>
    );
  };

  const [showConfirmModal, setShowConfirmModal] = useState<number>();

  const [show, setShow] = useState<"success" | "error" | "warning">();
  const [showMsg, setShowMsg] = useState<string>();
  const handleSubmitUsers = async (values: IFormUsersMolecule, errors: any) => {
    const emails = users?.filter(
      (f) => f.email.trim() === values.email.trim()
    )?.length;
    if (emails && emails > 1) {
      errors({ email: t("duplicated_emails") });
      return;
    }

    setSubmitingContext(true);

    UpdateUser(new UpdateUserRequest(values))
      .then((response) => {
        load();
        setShow("success");
        setShowMsg(t("TitleSaveSuccess"));
      })
      .catch((e) => {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          errors(errorFormik);
        } else if (e?.code === "409") {
          setShow("warning");
          setShowMsg(e?.message);
        } else {
          setShow("error");
          setShowMsg(t("api_error"));
          console.log(e);
        }
      })
      .finally(() => {
        setSubmitingContext(false);
      });
  };

  const addItem = () => {
    if (users)
      setUsers([
        ...users,
        {
          index: users.length,
          id: 0,
          cellPhone: "",
          email: "",
          name: "",
        } as IFormUsersMoleculeItems,
      ]);
  };

  const removeAsync = async (id?: number): Promise<void> => {
    if (id) return RemoveUser(id);
    return new Promise<void>((r) => r());
  };

  const [loadConfirmModal, setLoadConfirmModal] = useState(false);
  const remove = async (index: number) => {
    if (users && !submitingContext) {
      setLoadConfirmModal(true);

      removeAsync(users.find((f) => f.index === index)?.id)
        .then((t) => {
          const usersLocal = users?.filter((f) => f.index !== index);
          let i = 0;
          usersLocal.forEach((f) => (f.index = i++));
          setUsers(usersLocal);
        })
        .catch((c) => {
          setShow("error");
          setShowMsg(t("api_error"));
        })
        .finally(() => {
          setShowConfirmModal(undefined);
          setLoadConfirmModal(false);
        });
    }
  };

  const [submitEvents, setSubmitEvents] = useState<(() => void)[]>([]);
  const submitEvent = useCallback(
    (i: number, func: () => void) => {
      const items = [...submitEvents];
      items[i] = func;
      setSubmitEvents(items);
    },
    [submitEvents]
  );

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    if (!users) return;

    for (let i = 0; i < users.length; i++) {
      if (submitEvents[i]) submitEvents[i]();
    }
    event.preventDefault();
  };

  const handleChangeValues = useCallback(
    (value: IFormUsersMoleculeItems) => {
      const newValues = users?.filter((f) => f.index !== value.index) ?? [];
      newValues?.push(value);
      newValues?.sort((a, b) => SortHelper.ascProp(a, b, "index"));
      setUsers(newValues);
    },
    [users]
  );

  return (
    <WrapperUsers>
      <FeedbackModal
        type={show ?? "warning"}
        show={show !== undefined}
        onHide={() => setShow(undefined)}
        content={showMsg}
      />

      <FeedbackModal
        type={"confirm"}
        show={showConfirmModal !== undefined}
        onHide={() => setShowConfirmModal(undefined)}
        onClickButton={() => remove(showConfirmModal ?? 0)}
        content={t("Are you sure to remove this user?")}
        loading={loadConfirmModal}
      />

      <form id="sellers" onSubmit={submit}></form>

      {!userContext?.isMaster ? (
        <>{t("NoPermissionAccessArea")}</>
      ) : (
        <>
          {users === undefined ? (
            spinner()
          ) : (
            <>
              {users
                ?.filter((f) => f.id !== userContext.id)
                ?.map((m, i) => (
                  <Row key={i}>
                    <Col md={12} className={"spaces"}>
                      <WrapperBackground>
                        <FormUsersMolecule
                          count={users?.length}
                          index={i}
                          formId={"sellers"}
                          onSubmit={handleSubmitUsers}
                          values={m}
                          setSubmitEvent={(eventFunc) =>
                            submitEvent(i, eventFunc)
                          }
                          onChangeValues={handleChangeValues}
                        />
                        <IconTrash onClick={() => setShowConfirmModal(m.index)}>
                          <CoxIcon
                            name={"trash"}
                            fillColor={"#1F3E74"}
                            height={18}
                          />
                        </IconTrash>
                      </WrapperBackground>
                    </Col>
                  </Row>
                ))}
              <Row>
                <Col md={12} className={"text-center"}>
                  <ButtonSecondary
                    sizex={"lg"}
                    onClick={addItem}
                    disabled={submitingContext}
                  >
                    {t("Add Sellers")}
                  </ButtonSecondary>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </WrapperUsers>
  );
};
