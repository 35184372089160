import { TypesHelper } from "c4u-web-components";

export interface IUpdateDutValuesModel {
  id: number;
  buyerName: string;
  buyerDocument: string;
  buyerAddress: string;
}

export class UpdateDutValuesRequest {
  id: number | null;
  buyerName: string | null;
  buyerDocument: string | null;
  buyerAddress: string | null;
  constructor({
    id,
    buyerName,
    buyerDocument,
    buyerAddress,
  }: IUpdateDutValuesModel) {
    this.id = TypesHelper.toNumberNull(id);
    this.buyerName = TypesHelper.toStringNull(buyerName);
    this.buyerDocument = TypesHelper.toStringOnlyNumbersNull(buyerDocument);
    this.buyerAddress = TypesHelper.toStringNull(buyerAddress);
  }
}
