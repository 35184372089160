import React from "react";
import { useTranslation } from "react-i18next";
import { InsuranceListOrganism } from "../../../../organisms";

export const C4uInsuranceListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleInsuranceListPage")}</h3>
      <InsuranceListOrganism />
    </>
  );
};
