import React from "react";
import { Col, Row } from "react-bootstrap";

interface IProps {
  title: string;
}

export const DetailsPageSubsection: React.FC<IProps> = (props) => {
  return (
    <div className={"subsection py-3"}>
      <Row>
        <Col>
          <h5>{props.title}</h5>
          <hr />
        </Col>
      </Row>
      {props.children}
    </div>
  )
  ;
};
