import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { renderViewHelper } from "../../../../../../helper";
import { ITrackerVehicleDetails } from "../../../../../../models";

interface IProps {
  vehicleDetails?: ITrackerVehicleDetails;
}

export const TrackerVehicleDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewVehicle = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.vehicleDetails, key, label, func);

  const convertYesNo = (v: boolean | string) => {
    if (typeof v === "string") v = Boolean(parseInt(v));
    return v ? t("Yes") : t("No");
  };
  return !props.vehicleDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <Form.Row>
      {renderViewVehicle("vehicleName", t("VehicleName"))}
      {renderViewVehicle("plate", t("Plate"))}
      {renderViewVehicle("vin", t("Vin"))}
      {renderViewVehicle("kbbId", t("KbbId"))}
      {renderViewVehicle("isZeroKm", t("IsZeroKm"), convertYesNo)}
      {renderViewVehicle("dealershipExit", t("DealershipExit"))}
      {renderViewVehicle("gasKit", t("GasKit"), convertYesNo)}
      {renderViewVehicle("vehicleUse", t("VehicleUse"))}
      {renderViewVehicle("taxFree", t("TaxFree"), convertYesNo)}
      {renderViewVehicle("taxFreeReason", t("TaxFreeReason"))}
      {renderViewVehicle("isTunning", t("IsTunning"), convertYesNo)}
    </Form.Row>
  );
};
