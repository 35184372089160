import { YupHelper, PermissionEnum } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ICreateUserRequest } from "../../../../../models";

export const UserDetailsValidationSchema = (
  hasBackofficeUsersPermissions: boolean
) => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isMobilePhoneValid",
    YupHelper.isMobilePhoneValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isNameValid",
    YupHelper.isNameValid
  );

  const msgRequired = t("field_required");

  const permissionsValidation = function (
    values: PermissionEnum[] | null | undefined
  ): boolean {
    if (!hasBackofficeUsersPermissions) {
      return true;
    }
    return (values?.length ?? 0) > 0;
  };

  const validations = Yup.object<ICreateUserRequest>({
    id: Yup.number(),
    name: Yup.string()
      .required(msgRequired)
      .isNameValid(t("field_invalid_name")),
    email: Yup.string()
      .required(msgRequired)
      .email(t("field_invalid_email"))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("field_invalid_email")),
    cpf: Yup.string()
      .nullable()
      .required(msgRequired)
      .isCpfValid(t("cpf_invalid")),
    cellPhone: Yup.string()
      .required(msgRequired)
      .isPhoneValid(t("field_invalid_mobile_phone")),
    isMaster: Yup.boolean().required(msgRequired),
    active: Yup.boolean().required(msgRequired),
    dealershipId: Yup.number().nullable().required(msgRequired),
    permissions: Yup.array()
      .of(Yup.string<PermissionEnum>())
      .test(
        "hasBackofficeUsersPermissions",
        t("Select at least one permission"),
        permissionsValidation
      ),
  });

  return validations;
};
