import { TypesHelper } from "c4u-web-components";

export interface IPostDiscoverMockRequest {
  publicPersonId?: string;
  encodeLenderCode?: string;
  step?: string;
}

export class PostDiscoverMockRequest {
  publicPersonId: string | null;
  encodeLenderCode: string | null;
  step: string | null;

  constructor({
    publicPersonId,
    encodeLenderCode,
    step,
  }: IPostDiscoverMockRequest) {
    this.publicPersonId = TypesHelper.toStringNull(publicPersonId);
    this.encodeLenderCode = TypesHelper.toStringNull(encodeLenderCode);
    this.step = TypesHelper.toStringNull(step);
  }
}

export type IDeleteDiscoverMockRequest = Omit<IPostDiscoverMockRequest, "step">;

export class DeleteDiscoverMockRequest {
  publicPersonId: string | null;
  encodeLenderCode: string | null;

  constructor({
    publicPersonId,
    encodeLenderCode,
  }: IDeleteDiscoverMockRequest) {
    this.publicPersonId = TypesHelper.toStringNull(publicPersonId);
    this.encodeLenderCode = TypesHelper.toStringNull(encodeLenderCode);
  }
}

export type IGetDiscoverMockResponse = IPostDiscoverMockRequest[];
