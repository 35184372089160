import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Content, Title } from "./about-creditas.organism.style";

export const AboutCreditas = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("AboutCreditas")}</Title>
      <Content>
        <Trans>
          {t("CreditasIsTheDigitalPlatform")}
          {t("BornIn")}
          {t("ButItIsNotOnly")}
          {t("WeAreMoreThan")}
        </Trans>
        {/* <ButtonContent>
          <ButtonPrimary>
            {t("ClickToDownloadMaterialForCreditas")}
          </ButtonPrimary>
        </ButtonContent> */}
      </Content>
    </React.Fragment>
  );
};
