import styled from "styled-components";

export const BaseTextStyle = styled.div`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const Title = styled(BaseTextStyle)`
  margin-bottom: 50px;

  color: #001c55;

  font-weight: bold;
  font-size: 30px;
`;

export const Content = styled(BaseTextStyle)``;

export const BenefitsContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  margin: 100px 0;
`;

export const BenefitsItem = styled(BaseTextStyle)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  width: 220px;

  text-align: center;

  svg {
    margin-bottom: 15px;
  }

  p,
  strong {
    color: #4a4a4a;

    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`;

export const Link = styled.a`
  color: #001c55;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  text-decoration-line: underline;

  :hover {
    color: #001c55 !important;

    text-decoration-line: underline !important;
  }
`;

export const ButtonContent = styled.div`
  margin-top: 100px;

  button {
    color: #ffffff;
    background-color: #001c55;
    transition: all 0.4s;

    :hover {
      color: #ffffff;
      background-color: #001c55;
    }
  }
`;
