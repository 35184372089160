import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { routes, RouteDeclaration, paths } from "../constants";

const Routes: React.FC<any> = () => {
  const mapRoutes = function (
    predicate: (
      value: RouteDeclaration,
      index: number,
      array: Array<RouteDeclaration>
    ) => any
  ): Array<JSX.Element> {
    return routes.filter(predicate).map((route: any, key) => (
      <Route
        key={key}
        path={route.path}
        exact={true}
        render={(props) => {
          return (
            <route.template {...route}>
              <route.component {...props} />
            </route.template>
          );
        }}
      />
    ));
  };

  return (
    <HashRouter>
      <Switch>
        <Auth0Provider
          connection={process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION ?? ""}
          domain={process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN ?? ""}
          clientId={process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID ?? ""}
          audience={process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE ?? ""}
        >
          {mapRoutes((x) => x.tenant === "manheim")}
        </Auth0Provider>
      </Switch>
      <Switch>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_C4U_DOMAIN ?? ""}
          clientId={process.env.REACT_APP_AUTH0_C4U_CLIENTID ?? ""}
          audience={process.env.REACT_APP_AUTH0_C4U_AUDIENCE ?? ""}
          scope={process.env.REACT_APP_AUTH0_C4U_SCOPE_OPENID ?? ""}
          redirectUri={`${window.location.origin}/#${paths.authenticate()}`}
        >
          {mapRoutes(
            (x) =>
              x.tenant === null || x.tenant === undefined || x.tenant === ""
          )}
        </Auth0Provider>
      </Switch>
    </HashRouter>
  );
};

export { Routes };
