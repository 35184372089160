import { ButtonPrimary, Combo, FormikControlAtom } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";
import {
  DataLoadBorrowersRequest,
  IDataLoadBorrowersRequest,
} from "../../../../../models";

import { FeedbackModal } from "../../../../molecules";

interface IProps {
  setReloadList: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadFileBorrowerFormOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postDataLoadBorrowers, getLenders } = useManhein();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<string | undefined>();

  const [file, setFile] = useState<string>();
  const [lenders, setLenders] = useState<Combo[]>();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const formik = useFormik<IDataLoadBorrowersRequest>({
    initialValues: {} as IDataLoadBorrowersRequest,
    onSubmit: async (values, { setErrors }) => {
      if (file === undefined) {
        window.alert(t("SendFileReport"));
      } else {
        if (window.confirm(t("ConfirmAction"))) {
          try {
            await postDataLoadBorrowers(
              new DataLoadBorrowersRequest({
                file: file,
                lenderId: values.lenderId,
              })
            );
            window.location.reload();
          } catch (e: any) {
            if (e) {
              setModalMessage(e?.message ? e.message : t("FileInvalidMessage"));
            }
            setShowWarningModal(true);
          }
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const selectFile = (event: any, setFile: (v: any) => void) => {
    const files = event.target.files;
    setFile(files[0]);
  };

  const getLendersAsync = async () => {
    try {
      const response = await getLenders();

      const itemsCombo = response?.map(
        (m) =>
          new Combo({
            title: m.name,
            value: m.id,
          })
      );

      setLenders(itemsCombo);
    } catch (e) {
      setModalMessage(t("GenericApiError"));
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    getLendersAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Row>
          <Form.Group as={Col} md={3} controlId="lenderId">
            <FormikControlAtom
              type="autocomplete"
              md={12}
              formik={formik}
              data={lenders}
              property={"lenderId"}
              label={t("Bank")}
              placeholder={t("Tab here")}
              translate={t}
              className="overwrite-input-css-attrs"
              borderColor="#ced4da"
              backgroundColor="#fff"
            />
          </Form.Group>

          <Form.Group as={Col} md={4} controlId="file">
            <Form.Label>{t("File")}</Form.Label>
            <Form.File
              onChange={(ev: any) => selectFile(ev, setFile)}
              feedback={<>{t("field_required")}</>}
              feedbackTooltip={true}
              title={t("UploadInspectionReportHelpMsg")}
              accept={".csv"}
              style={{ paddingTop: "10px" }}
            />
          </Form.Group>

          <Form.Group as={Col} md className={"text-right pt-4"}>
            <ButtonPrimary
              type={"submit"}
              sizex={"md"}
              loading={formik.isSubmitting}
            >
              {t("SendFile")}
            </ButtonPrimary>
          </Form.Group>
        </Row>
      </Form>
      <FeedbackModal
        type={"warning"}
        show={showWarningModal}
        onHide={() => {
          setShowWarningModal(false);
          window.location.reload();
        }}
        content={modalMessage ? modalMessage : t("Try again error message")}
      />

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("api_error")}
      />
    </>
  );
};
