import { TypesHelper } from "c4u-web-components";

export class PostDeliveryPersonRequest {
  id?: number | null;
  deliveryName?: string | null;
  shippingCompanyName?: string | null;
  deliveryPersonPhone?: string | null;
  deliveryPersonCPF?: string | null;

  constructor({
    id,
    deliveryName,
    shippingCompanyName,
    deliveryPersonPhone,
    deliveryPersonCPF,
  }: IDeliveryPerson) {
    this.id = TypesHelper.toNumberNull(id);
    this.deliveryName = TypesHelper.toStringNull(deliveryName);
    this.shippingCompanyName = TypesHelper.toStringNull(shippingCompanyName);
    this.deliveryPersonPhone = TypesHelper.toStringNull(deliveryPersonPhone);
    this.deliveryPersonCPF =
      TypesHelper.toStringOnlyNumbersNull(deliveryPersonCPF);
  }
}

export class PostDeliveryPersonPhotoRequest {
  id?: number | null;
  typePhoto?: number | null;
  base64?: string | null;

  constructor({ id, typePhoto, base64 }: IDeliveryPersonPhoto) {
    this.id = TypesHelper.toNumberNull(id);
    this.typePhoto = TypesHelper.toNumberNull(typePhoto);
    this.base64 = TypesHelper.toStringNull(base64);
  }
}

export interface IDeliveryPerson {
  id?: number;
  deliveryName?: string;
  shippingCompanyName?: string;
  deliveryPersonPhone?: string;
  deliveryPersonCPF?: string;
}

export interface IDeliveryPersonPhoto {
  id?: number;
  typePhoto?: number;
  base64?: string;
}
