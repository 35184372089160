import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";
import { Link } from "react-router-dom";
import { CopyLinkAtom, CoxIcon, TypesHelper } from "c4u-web-components";
import { paths } from "../../../../../constants";
import { LenderOperatorTableSearchOrganism } from "./lender-operator-list-table-search.organism";
import { IGetLenderOperatorResponse } from "../../../../../models";
import { StringHelper } from "../../../../../helper";

export const LenderOperatorListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getLenderOperator } = useManhein();

  const getEditButton = useCallback(
    (id: string | number, i: number) => (
      <Link
        key={`link${i}`}
        to={paths.backofficeLenderOperator(id)}
        title={t("Edit")}
      >
        <CoxIcon
          name="pencil-edit"
          width={16}
          height={16}
          fillColor="#1F3E74"
        />
      </Link>
    ),
    [t]
  );

  return (
    <>
      <LenderOperatorTableSearchOrganism
        getDataCallback={getLenderOperator}
        tableName="api-user-list"
        tableHeaders={[
          "#",
          t("Name"),
          t("Cpf"),
          t("Email"),
          t("Cnpj"),
          t("CollectionOfficeName"),
          t("Bank"),
          t("Link"),
          t("CopyLink"),
          t("Active"),
          "",
        ]}
        mappingFunction={(m: IGetLenderOperatorResponse, i) => {
          const lenderFormatedName = m.lender.name
            .replaceAll(" ", "")
            .replace("ú", "u")
            .toLowerCase();

          return [
            m.id,
            m.name,
            StringHelper.formatCpfCnpj(m.cpf),
            m.email,
            StringHelper.formatCpfCnpj(m.collectionOfficeCnpj),
            m.collectionOfficeName,
            m.lender.name,
            <div className="text-center">
              {`${process.env.REACT_APP_NEWMANHEIN_URL}${lenderFormatedName}/fideliza/${m.id}`}
            </div>,

            <CopyLinkAtom
              link={`${process.env.REACT_APP_NEWMANHEIN_URL}${lenderFormatedName}/fideliza/${m.id}`}
            />,
            TypesHelper.toBooleanNull(m.isActive) ? t("Yes") : t("No"),
            getEditButton(m.id, i),
          ];
        }}
      />
    </>
  );
};
