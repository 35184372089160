import { DateHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { LeadsAdsTableOrganism } from "../../..";
import { StatusHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import { AdStepEnum, IGetLinksAdsResponse } from "../../../../../models";

export const LeadsAdsListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getLinkReport } = useManhein();

  return (
    <>
      <LeadsAdsTableOrganism
        getDataCallback={getLinkReport}
        tableName="link-leads-ads"
        tableHeaders={[
          t("AdId"),
          t("LenderID"),
          t("LenderName"),
          t("LenderOperatorID"),
          t("OfficeCnpj"),
          t("GenerationDateLink"),
          t("LastStatusDate"),
          t("Step"),
          t("SubStep"),
        ]}
        mappingFunction={(m: IGetLinksAdsResponse, i) => [
          m.id,
          m.lenderId,
          m.lenderName,
          m.lenderOperatorId,
          m.collectionOfficeCnpj,
          DateHelper.dateHourFormat(m.createdAt),
          DateHelper.dateHourFormat(m.lastStatusDate),
          `${m.step} - ${t(AdStepEnum[m.step])}`,
          `${m.stepStatus} - ${t(
            StatusHelper.getStepStatusName(m.step, m.stepStatus)
          )}`,
        ]}
      />
    </>
  );
};
