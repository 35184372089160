import { TypesHelper } from "c4u-web-components";
import {
  CorporateUserTypeEnum,
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";

export interface IListCorporateUsersParams
  extends IPagedRequestParamsBaseModel {
  search?: string;
  active?: string;
}

export class ListCorporateUsersParams extends PagedRequestParamsBaseModel {
  search: string | null;
  active: boolean | null;

  constructor({ search, active, ...baseParams }: IListCorporateUsersParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.active = TypesHelper.toBooleanNull(active, [""]);
  }
}

export interface IListCorporateUserResponse {
  coxUserId: number;
  coxUserActive?: boolean;
  corporateUserType?: CorporateUserTypeEnum;
  coxUserEmail?: string;
  coxUserName?: string;
  coxUserCpf?: string;
}

export interface IListCorporateUserPagedResponse
  extends IPagedResponseBaseModel {
  data: IListCorporateUserResponse[];
}
