import React from "react";

import { KbbZapayList } from "../../../../organisms";

import { useTranslation } from "react-i18next";

export const KbbZapayListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleKbbZapayListPage")}</h3>
      <KbbZapayList />
    </>
  );
};
