import React from "react";
import { useTranslation } from "react-i18next";
import { FieldMetaProps } from "formik";
import Alert from "react-bootstrap/Alert";

interface GeneralErrorProps {
  errorProp: FieldMetaProps<string>;
  errorCode: FieldMetaProps<string>;
  setErrors: (errorList: any) => void;
}

export const GeneralError = (props: GeneralErrorProps) => {
  const { t } = useTranslation();

  if (props.errorProp.error)
    return (
      <Alert variant="danger" onClose={() => props.setErrors({})} dismissible>
        <ul>
          <li>
            <small>{t(props.errorProp.error.toString())}</small>
          </li>
          <li>
            <small>
              {props.errorCode &&
                props.errorCode.error &&
                t(props.errorCode.error.toString())}
            </small>
          </li>
        </ul>
      </Alert>
    );

  return null;
};
