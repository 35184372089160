import { TypesHelper } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  ParametersEnum,
} from "../..";
import { PagedRequestParamsBaseModel } from "../..";

export interface IGetConsuptionReportParams
  extends IPagedRequestParamsBaseModel {
  startYear: number;
  startMonth: number;
  finalYear: number;
  finalMonth: number;
  search: string;
  kbbApi: ParametersEnum | number;
}

export class GetConsuptionReportParams extends PagedRequestParamsBaseModel {
  startYear: number | null;
  startMonth: number | null;
  finalYear: number | null;
  finalMonth: number | null;
  search: string | null;
  kbbApi: ParametersEnum | null;
  constructor({
    startYear,
    startMonth,
    finalYear,
    finalMonth,
    search,
    kbbApi,
    ...baseParams
  }: IGetConsuptionReportParams) {
    super(baseParams);
    this.startYear = TypesHelper.toNumberNotZeroNull(startYear);
    this.startMonth = TypesHelper.toNumberNotZeroNull(startMonth);
    this.finalYear = TypesHelper.toNumberNotZeroNull(finalYear);
    this.finalMonth = TypesHelper.toNumberNotZeroNull(finalMonth);
    this.search = TypesHelper.toStringNull(search);
    this.kbbApi = TypesHelper.toNumberNotZeroNull(kbbApi);
  }
}

export interface IGetConsumptionReportResponse {
  year: number;
  month: number;
  name?: string;
  cnpj?: string;
  calls: number;
  remainingCalls: number;
  monthlyCallsLimit: number;
  serviceUsed: ParametersEnum;
  apiUserId: number;
}

export interface IGetConsumptionReportResponsePagedResponseModel
  extends IPagedResponseBaseModel {
  data?: IGetConsumptionReportResponse[];
}
