import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
`;

export const ImageContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 80px 0px 0px 0px;

  img {
    width: 167px;
  }
`;

export const PDFContainer = styled.div`
  width: 80%;
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    width: 80%;
    height: 100%;
    zoom: 0.75;
  }
`;

export const AcceptedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const CheckedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    white-space: nowrap;
    margin: auto;
  }
`;

export const ContentChecked = styled.div`
  padding: 10px 0px 5px 0px;
`;

export const ConfirmedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 150px;
    background-color: #3a5693;
    color: white;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  gap: 10px;
  position: absolute;
`;

export const LoadingText = styled.p`
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;
