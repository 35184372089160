import { VehicleEquipment } from "c4u-web-components";
import { useKbb } from "../../../../../hooks";
import React, { useCallback, useEffect, useState } from "react";
import { AdModel } from "../../../../../models";
import { TableList } from "../../..";
import { useTranslation } from "react-i18next";
interface IProps {
  ad: AdModel;
}

interface IOptionals {
  tag: number;
  name: string;
  category: string;
  subcategory: string;
}

export const AdDetailsOptionalsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [vehicleId, setVehicleId] = useState<number>();

  const [vehicleEquipment, setVehicleEquipment] = useState<VehicleEquipment[]>();

  const [vehicleOptionals, setVehicleOptionals] = useState<IOptionals[]>();

  const { getVehicle, getAllEquipments } = useKbb();

  const getEquipmentsAsync = useCallback(async () => {
    if (props.ad) {
      const vehicle = await getVehicle(props.ad?.vehicleId);
      const equipmentsService = await getAllEquipments(
        props.ad?.vehicleId,
        vehicle.year
      );
      let equipsColor = equipmentsService.filter((f) => f.categoryID === 0);
      const equipsAll = equipmentsService.filter((f) => f.categoryID !== 0);
      setVehicleEquipment(equipsColor.concat(equipsAll));
      setVehicleId(props.ad?.vehicleId);
    }
  }, [getAllEquipments, getVehicle, props]);

  const getOptionals = useCallback(() => {
    let colorID = props.ad.equipmentColorId;
    let adOptionals = props.ad.adVehicleOptions;
    let displayAdOptionals: IOptionals[] = [];
    if (colorID) {
      let colors = vehicleEquipment?.filter((f) => f.categoryID === 0)[0];
      let color = colors?.subCategory[0].equipments.filter((f) => f.equipmentID === colorID)[0];

      if (color) {
        displayAdOptionals.push({
          tag: parseInt(color.tag),
          name: color.name,
          category: color.equipmentCategoryName,
          subcategory: color.equipmentSubCategoryName? color.equipmentSubCategoryName : '-'
        });
      };
    };

    let equipment, equipments;
    for (let adOptional of adOptionals){
      equipments = vehicleEquipment?.filter((f) => f.categoryID !== 0);
      equipment = equipments?.filter(
        (f) => f.subCategory[0].equipments[0].tag === adOptional.tag.toString()
      )[0];
      if (equipment) {
        displayAdOptionals.push({
          tag: adOptional.tag,
          name: adOptional.name,
          category: equipment.categoryName,
          subcategory: equipment.subCategory[0].subCategoryName
        });
      };
    };
    setVehicleOptionals(displayAdOptionals);
  }, [vehicleEquipment, props.ad]);

  useEffect(() => {
    if (!vehicleId) getEquipmentsAsync();
  }, [getEquipmentsAsync, vehicleId]);

  useEffect(() => {
    if (vehicleEquipment && props.ad) getOptionals();
  }, [getOptionals, vehicleEquipment, props.ad])

  return vehicleOptionals?.length ?
  (
    <TableList thead={[t("Categoria"), t("Subcategoria"), t("Nome")]}>
      {vehicleOptionals?.map((optional, i) => (
        <tr key={`optionals-tr${i}`}>
          <td>{optional.category}</td>
          <td>{optional.subcategory}</td>
          <td>{optional.name}</td>
        </tr>
      ))}
    </TableList>
  ) : (
    <i>{t("No information")}</i>
  )
};
