import {
  ButtonPrimary,
  ButtonSecondary,
  Combo,
  MaskHelper,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Col } from "react-bootstrap";
import { ComboOptionsFromEnumAtom, FormikControlAtom, OptionsOrderByAtom } from "../../../atoms";
import { StdEnum } from "../../../../models";

interface IProps {
  formik: any;
  statusOptionsEnum: StdEnum;
  dealershipOptions?: Combo[];
  originOptionsEnum?: StdEnum;
  insuranceCompanyOptionsEnum: StdEnum;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const InsuranceSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <Col md={10}>
            <Form.Row>
              <FormikControlAtom
                formik={formik}
                md={6}
                property={"search"}
                label={t("TextSearch")}
              />
              <FormikControlAtom
                formik={formik}
                md={3}
                property={"startDate"}
                label={t("InitialDate")}
                mask={MaskHelper.Date}
              />
              <FormikControlAtom
                formik={formik}
                md={3}
                property={"finalDate"}
                label={t("FinalDate")}
                mask={MaskHelper.Date}
              />
            </Form.Row>
            <Form.Row>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                md={3}
                property={"status"}
                label={t("Status")}
              >
                <ComboOptionsFromEnumAtom
                  propertyKey="status"
                  optionsEnum={props.statusOptionsEnum}
                />
              </FormikControlAtom>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                md={3}
                property={"partner"}
                label={t("InsuranceCompany")}
              >
                <ComboOptionsFromEnumAtom
                  propertyKey="partner"
                  optionsEnum={props.insuranceCompanyOptionsEnum}
                />
              </FormikControlAtom>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                md={3}
                property={"dealership"}
                label={t("Dealership")}
              >
                <option value={""}>{t("All")}</option>
                {props.dealershipOptions
                  ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                  ?.map((m, i) => (
                    <option key={`dealership-${i}`} value={m.value}>
                      {m.title}
                    </option>
                  ))}
              </FormikControlAtom>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                md={3}
                property={"pageSize"}
                label={t("RecordsPerPage")}
              >
                <OptionsOrderByAtom
                  options={[
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                  ]}
                />
              </FormikControlAtom>
            </Form.Row>
          </Col>
          <Col md={2}>
            <Form.Row className="pt-2 mt-3">
              <ButtonSecondary
                sizex="md"
                disabled={formik.isSubmitting}
                onClick={props.cleanFiltersCallback}
              >
                {t("CleanFilters")}
              </ButtonSecondary>
            </Form.Row>
            <Form.Row className="pt-3 mt-4">
              <ButtonPrimary
                type="submit"
                sizex="md"
                loading={formik.isSubmitting}
              >
                {t("Search")}
              </ButtonPrimary>
            </Form.Row>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
