import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { IFormEmailTemplate } from "../../../../../models";

export const EmailTemplateDetailsValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t("field_required");

  return Yup.object<IFormEmailTemplate>({
    template: Yup.number().required(msgRequired),
    timeInHours: Yup.number().required(msgRequired),
    activeEmail: Yup.boolean().required(msgRequired),
    title: Yup.string().when("activeEmail", {
      is: true,
      then: Yup.string().required(msgRequired),
      otherwise: Yup.string(),
    }),
    body: Yup.string().when("activeEmail", {
      is: true,
      then: Yup.string().required(msgRequired),
      otherwise: Yup.string(),
    }),
    activeWhatsapp: Yup.boolean().required(msgRequired),
    bodyWhatsapp: Yup.string().when("activeWhatsapp", {
      is: true,
      then: Yup.string().required(msgRequired),
      otherwise: Yup.string(),
    }),
  });
};
