import { CoxIcon } from "c4u-web-components";
import React from "react";
import {
  ItemCardText,
  ItemCardWrapper,
  ManageAtomFieldset,
  RemoveItemButton,
} from "./manage-items.atom.style";

interface IProps {
  fieldsetTitle: string;
  removeItemCallback?: (id: number) => void;
  items?: {
    id: number;
    line1: string;
    line2?: string;
  }[];
}

export const ManageItemsAtom: React.FC<IProps> = (props) => {
  return (
    <ManageAtomFieldset className="d-flex flex-wrap">
      <legend className="w-auto px-2">{props.fieldsetTitle}</legend>

      {props.items &&
        props.items.length > 0 &&
        props.items.map((m, i) => (
          <ItemCardWrapper key={`field-set-item-${i}`}>
            <ItemCardText>
              <div>{m.line1}</div>
              <div>{m.line2}</div>
            </ItemCardText>
            {props.removeItemCallback && (
              <RemoveItemButton
                onClick={() =>
                  props.removeItemCallback && props.removeItemCallback(m.id)
                }
              >
                <CoxIcon
                  name="trash"
                  height={24}
                  width={24}
                  fillColor="#4a4a4a"
                />
              </RemoveItemButton>
            )}
          </ItemCardWrapper>
        ))}
    </ManageAtomFieldset>
  );
};
