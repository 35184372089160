import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useManhein } from "../../../../../hooks";

export const InspectionReportLinkPage = () => {
  const { filename } = useParams<{ filename: string }>();
  const { isAuthenticated } = useAuth0();

  const { getDownloadLink } = useManhein();

  useEffect(() => {
    if (filename && isAuthenticated) {
      getDownloadLink(filename)
        .then((link) => {
          window.location.href = link;
        })
        .catch((error) => {
          if (error?.message) alert(JSON.stringify(error?.message));
          else alert(JSON.stringify(error));
        });
    }
  }, [filename, isAuthenticated]); //eslint-disable-line react-hooks/exhaustive-deps

  return <div>Downloading file...</div>;
};
