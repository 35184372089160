import {
  Combo,
  FormControlMask,
  FormControlMoney,
  Translator,
} from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface IProps {
  formik: any;
  func?: (attr: any) => string | boolean;
  property: string;
  mask?: any;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "money"
    | "select";
  label?: string;
  disabled?: boolean;
  sm?: string | number;
  md?: string | number;
  decimalScale?: number;
  moneyPrefix?: string;
  as?: any;
  translate?: Translator;
  data?: Combo[];
  id?: string;
  name?: string;
  isScreenTerms?: boolean;
  resetPadding?: boolean;
}

export const FormikControlAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const values = props.formik.values as any;
  const errors = props.formik.errors as any;

  const valueFormated = props.func
    ? props.func(values[props.property])
    : values[props.property];
  const error = errors ? errors[props.property] : "";

  return (
    <Form.Group
      as={Col}
      sm={props.sm}
      md={props.md}
      controlId={props.property}
      isScreenTerms={props.isScreenTerms}
      resetPadding={props.resetPadding}
    >
      <Form.Label>
        {props.label
          ? t(props.label)
          : t(
              props.property.substring(0, 1).toUpperCase() +
                props.property.substring(1)
            )}
      </Form.Label>
      {props.type === "money" ? (
        <FormControlMoney
          id={props.property}
          as={props.as}
          maxLength={11 + (props.moneyPrefix ?? "R$ ").length}
          decimalScale={props.decimalScale}
          placeholder={t("Tab here")}
          onChange={props.formik.handleChange}
          disabled={props.disabled}
          value={valueFormated === null ? "" : valueFormated}
          isInvalid={!!error}
          prefix={props.moneyPrefix ?? "R$ "}
          autoComplete={"off"}
        />
      ) : (
        <>
          {props.mask ? (
            <FormControlMask
              type={props.type}
              mask={props.mask}
              placeholder={t("Tab here")}
              onChange={props.formik.handleChange}
              disabled={props.disabled}
              value={valueFormated === null ? "" : valueFormated}
              isInvalid={!!error}
            />
          ) : (
            <Form.Control
              as={props.as}
              type={props.type}
              placeholder={t("Tab here")}
              onChange={props.formik.handleChange}
              disabled={props.disabled}
              value={valueFormated === null ? "" : valueFormated}
              isInvalid={!!error}
              autoComplete={"off"}
              id={props.id}
              name={props.name}
            >
              {props.children}
            </Form.Control>
          )}
        </>
      )}
      {typeof error === "string" ? (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      ) : (
        error?.key && (
          <Form.Control.Feedback type="invalid">
            {t(error.key)}
          </Form.Control.Feedback>
        )
      )}
    </Form.Group>
  );
};
