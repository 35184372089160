import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../helper";

import { useC4u } from "../../../../hooks";
import { TableList } from "../../../molecules";

export const OnboardingsReport: React.FC = () => {
  const { GetOnboardingsReport } = useC4u();

  const { t } = useTranslation();

  const [list, setList] = useState<any[]>();

  const GetOnboardingsReportAsync = useCallback(async () => {
    var onboardings = await GetOnboardingsReport();
    setList(onboardings);
  }, [GetOnboardingsReport]);

  useEffect(() => {
    GetOnboardingsReportAsync();
    // eslint-disable-next-line
  }, []);

  return (
    <TableList
      thead={[
        "#",
        t("PublicId"),
        t("Created At"),
        t("Updated At"),
        t("Active"),
        t("CreatedById"),
        t("UpdatedById"),
        t("DealershipId"),
        t("Step"),
        t("Message"),
      ]}
    >
      {list?.map((m, i) => (
        <tr key={`tr${i}`}>
          <td>{m.id}</td>
          <td>{m.publicId}</td>
          <td>{DateHelper.dateHourFormat(m.createdAt)}</td>
          <td>{DateHelper.dateHourFormat(m.updatedAt)}</td>
          <td>{m.active ? t("Yes") : t("No")}</td>
          <td>{m.createdById}</td>
          <td>{m.updatedById}</td>
          <td>{m.dealershipId}</td>
          <td>{m.step}</td>
          <td>{m.message}</td>
        </tr>
      ))}
    </TableList>
  );
};
