import React from "react";
import { ContentText } from "./my-earnings-text-section.molecule.style";
import {
  MyEarningsSectionTitleDiv,
  MyEarningsMoleculeWrapper,
  MyEarningsWhiteBackgroundCard,
} from "../my-earnings-shared.style";

interface IProps {
  text: string;
  title: string;
  titleBackgroundColor?: string;
  titleColor?: string;
}

export const MyEarningsTextSectionMolecule: React.FC<IProps> = (props) => {
  return (
    <MyEarningsMoleculeWrapper>
      <MyEarningsSectionTitleDiv
        background={props.titleBackgroundColor}
        color={props.titleColor}
      >
        {props.title}
      </MyEarningsSectionTitleDiv>
      <MyEarningsWhiteBackgroundCard>
        <ContentText>{props.text}</ContentText>
      </MyEarningsWhiteBackgroundCard>
    </MyEarningsMoleculeWrapper>
  );
};
