import styled from "styled-components";

export const InstallmentTermText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: ${(props) => props.theme.colors.Dark};
`;

export const PercentageText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  height: 110px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;
