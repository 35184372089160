import React from "react";
import { Container } from "./register.template.style";

export const RegisterTemplate: React.FC = (props) => {
  return (
    <Container fluid className={"p-0"}>
      {props.children}
    </Container>
  );
};
