import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LabelDefault, RoundedCard } from "c4u-web-components";

import {
  BrandsContainer,
  FooterContainer,
  FormContainer,
  FormFields,
  FullBG,
  LogoC4uContainer,
  VectorContainer,
} from "./create-dealership.page.style";

import { RegistrationStepsProvider } from "../../../../contexts"

import { CreateDealershipStepper } from "../../../organisms/home/create-dealership-stepper/create-dealership-stepper.organism";
import { C4uBrands, Footer } from "../../../molecules";
import { LogoCox4u, HomeVector, DealershipCarBg } from "../../../atoms";

export const CreateDealershipPage = () => {
  const { t } = useTranslation();
  
  useEffect(() => {}, []);

  return (
    <FullBG>
      <LogoC4uContainer>
        <LogoCox4u />
      </LogoC4uContainer>
      <VectorContainer>
        <HomeVector />
      </VectorContainer>
      <DealershipCarBg />
      <BrandsContainer>
        <C4uBrands />
      </BrandsContainer>

      <FormContainer>
        <RoundedCard>
          <FormFields>
            <LabelDefault lblColor="blue" lblWeight="heading">
              {t("Finalize Register")}
            </LabelDefault>
            <br />
            <RegistrationStepsProvider>
              <CreateDealershipStepper />
            </RegistrationStepsProvider>
          </FormFields>
        </RoundedCard>
      </FormContainer>
      <br />
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </FullBG>
  );
};
