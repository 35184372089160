import { useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { AxiosInstance, AxiosResponse } from "axios";

import {
  GeneralRequestError,
  GeneralRequestMessageError,
  RequestError,
} from "./use-request.model";

export const useRequest = (
  http: AxiosInstance,
  path: string,
  accessTokenConfig: GetTokenSilentlyOptions | null = null
) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const withAuthorization = async (headers: any) => {
    if (!accessTokenConfig) return headers;

    if (isLoading) return headers;

    if (!isAuthenticated) return headers;

    headers["Authorization"] = `Bearer ${await getAccessTokenSilently(
      accessTokenConfig
    )}`;

    return headers;
  };

  const buildUrl = (url: string) => {
    return url ? `${path}/${url}` : path;
  };

  const buildHeaders = async (forcedHeaders: any) => {
    const headers = forcedHeaders ?? {
      "Accept-Language": "pt-BR,pt;q=0.9",
      "Content-Type": "application/json",
    };

    return await withAuthorization(headers);
  };

  const getError = (
    e: any
  ): RequestError | GeneralRequestMessageError | GeneralRequestError => {
    if (
      e.response &&
      e.response.status === 412 &&
      e.response.headers["content-type"]?.includes("json")
    ) {
      console.error("Request", e.response);
      return new RequestError(e.response.data);
    }

    if (e.response?.status?.toString() === "409") {
      console.error("General with data", e.response.data);
      return new GeneralRequestMessageError({
        code: "409",
        message: e.response.data,
      } as RequestError);
    }

    if (e.response && e.response.data) {
      console.error("General with data", e.response.data);
      return new GeneralRequestMessageError(e.response.data);
    }

    console.error("General", e);
    return new GeneralRequestError(e);
  };

  const callApi = async (
    url: string,
    method: string,
    config: any,
    data: any = null
  ) => {
    const requestConfig = config;
    requestConfig.url = buildUrl(url);
    requestConfig.headers = await buildHeaders(requestConfig.headers);
    requestConfig.data = data;
    requestConfig.method = method;
    try {
      return await http.request(requestConfig);
    } catch (e: any) {
      throw getError(e);
    }
  };

  const getCurrentToken = async (): Promise<string> => {
    if (!accessTokenConfig) return "";
    return await getAccessTokenSilently(accessTokenConfig);
  };

  return {
    get: async <T = any>(url: string, config = {}): Promise<AxiosResponse<T>> =>
      callApi(url, "GET", config),
    del: async <T = any>(
      url: string,
      data: any = null,
      config = {}
    ): Promise<AxiosResponse<T>> => callApi(url, "DELETE", config, data),
    put: async <T = any>(
      url: string,
      data: any,
      config = {}
    ): Promise<AxiosResponse<T>> => callApi(url, "PUT", config, data),
    post: async <T = any>(
      url: string,
      data: any,
      config = {}
    ): Promise<AxiosResponse<T>> => callApi(url, "POST", config, data),
    getCurrentToken,
  };
};
