export enum ApplicationTermEnum {
  CoxPraVoce = 1,
  CreditApp,
  Dealertrack,
  Drs,
  Dtconnecta,
  Kbb,
}

export enum TypeTermEnum {
  TermOfUse = 1,
  PrivacyPolicy,
  DealerContract,
}
