export enum TrackerStepEnum {
  Vehicle,
  Driver,
  Quote,
  Order,
  Scheduled,
  Canceled,
  TrackerFinished,
  Scheduling,
}
