import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { YupHelper } from "c4u-web-components";
import { IFormUsersMolecule } from "./form-users.molecule";

export const FormUsersMoleculeValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  const msgRequired = t("field_required");

  return Yup.object<IFormUsersMolecule>({
    name: Yup.string().nullable().required(msgRequired),
    email: Yup.string().nullable().required(msgRequired).email(t("field_invalid_email")),
    cellPhone: Yup.string().nullable().required(msgRequired),
  });
};
