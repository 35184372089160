import React from "react";
import { Col, Row } from "react-bootstrap";

interface IProps {
  title: string;
  classNames?: string[];
}

export const DetailsPageSection: React.FC<IProps> = (props) => {
  return (
    <div className={`section ${props.classNames?.join(" ")}`}>
      <Row>
        <Col>
          <h3>{props.title}</h3>
          <hr />
        </Col>
      </Row>
      {props.children}
    </div>
  );
};
