import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { ICpvHomeBigCardData } from "../../../models";
import {
  CardBottom,
  CardHeader,
  CardText,
  CardTitle,
  ImageWrapper,
  LogoWrapper,
  RegistrationHomeCardStyled
} from "./registration-card.atom.style";

interface IProps {
  profile?: string;
  borderColor: string;
  onClick?: (e: React.MouseEvent) => void;
  data: ICpvHomeBigCardData;
  dataElement?: string;
  dataProduct?: string;
}

export interface RegistrationHomeCardStyleProps extends Omit<IProps, "data"> {}

export const RegistrationHomeCardAtom: React.FC<IProps> = (props) => {
  const { data, dataElement, dataProduct, ...otherProps } = props;

  return (
    <RegistrationHomeCardStyled
      {...otherProps}
      data-element={dataElement}
      data-product={dataProduct}
    >
      <CardHeader>
        <div>
          {data.logoSrc && (
            <LogoWrapper>
              <img alt={data.logoAltText} src={data.logoSrc} />
            </LogoWrapper>
          )}
          {data.cardTitle && <CardTitle>{data.cardTitle}</CardTitle>}
          <CardText
            id="big-card-description-text"
            className={!data.cardImgSrc ? "pr-2" : ""}
          >
            {Array.isArray(data.cardText)
              ? data.cardText.map((m, i) => (
                  <div key={`${props.profile}-${i}`}>{m}</div>
                ))
              : data.cardText}
          </CardText>
        </div>
        {data.cardImgSrc && (
          <ImageWrapper id="big-card-image-wrapper">
            <img alt={data.cardImgAltText} src={data.cardImgSrc} />
          </ImageWrapper>
        )}
      </CardHeader>
      <hr />
      <CardBottom>
        <ButtonPrimary
          profile={data.buttonProfile}
          disabled={data.buttonDisabled}
          onClick={data.onClick}
        >
          {data.buttonText}
        </ButtonPrimary>
      </CardBottom>
    </RegistrationHomeCardStyled>
  );
};
