import {
  ButtonPrimary,
  Combo,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  ILenderUserRequest,
  LenderUserRequest,
  IGetLenderUserResponse,
  CustomerChangePasswordRequest,
} from "../../../../../models";

import {
  LenderOperatorDetailsHR,
  WrapperButtonsLenderOperatorDetails,
  WrapperLenderOperatorDetails,
} from "./lender-create-user.molecule.style";
import { LenderCreateUserValidationSchema } from "./lender-create-user.molecule.validation";

interface IProps {
  setShowErrorModal: (v: boolean) => void;
  setShowSuccessModal: (v: boolean) => void;
  setModalMessage: React.Dispatch<any>;
  onSubmit: (request: LenderUserRequest) => Promise<IGetLenderUserResponse>;
  onChangePassword: (email: CustomerChangePasswordRequest) => Promise<void>;
  userId: number;
  lenders: Combo[];
}

export const LenderCreateUserMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<ILenderUserRequest>({
    initialValues: {} as ILenderUserRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await props.onSubmit(
            new LenderUserRequest({
              ...values,
              id: 0,
            })
          );

          props.onChangePassword(
            new CustomerChangePasswordRequest({ email: values.email })
          );
          props.setShowSuccessModal(true);
        } catch (e: any) {
          if (e?.errors) {
            setErrors(formikHandleErrorHelper(e.errors));
          }
          if (e?.message) {
            props.setModalMessage(e.message);
          } else {
            props.setModalMessage(JSON.stringify(e));
          }
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: LenderCreateUserValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <WrapperLenderOperatorDetails>
      <LenderOperatorDetailsHR />

      <Form onSubmit={(e: any) => formik.handleSubmit(e)} autoComplete="off">
        <input type="hidden" value="disable-autocomplete-chrome" />

        <Form.Row>
          <FormikControlAtom
            md={5}
            formik={formik}
            property={"name"}
            label={t("Name")}
            maxLength={100}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={4}
            formik={formik}
            property={"email"}
            label={t("Email")}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={3}
            formik={formik}
            mask={MaskHelper.Cpf}
            property="document"
            label={t("Cpf")}
            translate={t}
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            sm={4}
            md={3}
            formik={formik}
            mask={MaskHelper.Cellphone}
            property="mobile"
            label={t("Phone")}
            translate={t}
          />

          <FormikControlAtom
            sm={4}
            md={3}
            formik={formik}
            mask={MaskHelper.Date}
            property="birthDate"
            label={t("Birthdate")}
            translate={t}
          />

          <FormikControlAtom
            type="autocomplete"
            md={3}
            formik={formik}
            data={props.lenders}
            property={"lenderId"}
            label={t("Bank")}
            placeholder={t("Tab here")}
            translate={t}
            className="overwrite-input-css-attrs"
            borderColor="#ced4da"
            backgroundColor="#fff"
          />
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <WrapperButtonsLenderOperatorDetails>
              <div>
                <ButtonPrimary
                  sizex="md"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {t("Save")}
                </ButtonPrimary>
              </div>
            </WrapperButtonsLenderOperatorDetails>
          </Form.Group>
        </Form.Row>
      </Form>
    </WrapperLenderOperatorDetails>
  );
};
