import { Combo, NumberHelper } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { AdModel } from "../../../../../models";

interface IProps {
  ad: AdModel;
  vehicleGrades: Combo[];
}
export const AdDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => {
    const values = props.ad as any;
    const valueFormated = func ? func(values[key]) : values[key];

    return (
      <Form.Group as={Col} md={4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <Form.Row>
      {renderView("id", t("AdId"))}
      {renderView("includedDate", t("IncludedDate"), DateHelper.dateFormat)}
      {renderView("vehicleId", t("VehicleId"))}
      {renderView("vehicleName", t("VehicleName"))}
      {renderView("mileage", t("Mileage"))}
      {renderView("licenseUf", t("LicenseUf"))}
      {renderView("renavam", t("Renavam"))}
      {renderView("plate", t("Plate"))}
      {renderView(
        "trafficPenaltyValue",
        t("TrafficPenaltyValue"),
        (value) => "R$ " + NumberHelper.toFormatString(value, 2)
      )}
      {renderView("observation", t("Observation"))}
      {renderView("partnerCatalogId", "PartnerCatalogId")}
      {renderView("partnerValuationId", "PartnerValuationId")}
      {renderView(
        "sellValue",
        t("SellValue"),
        (value) => "R$ " + NumberHelper.toFormatString(value, 2)
      )}
      {props.ad.lenderOperatorId &&
        renderView(
          "lenderAcceptanceValue",
          t("LenderAcceptanceValue"),
          (value) => "R$ " + NumberHelper.toFormatString(value, 2)
        )}

      {renderView(
        "maxKbbValue",
        t("MaxKbbValue"),
        (value) => "R$ " + NumberHelper.toFormatString(value, 2)
      )}
      {renderView(
        "minKbbValue",
        t("MinKbbValue"),
        (value) => "R$ " + NumberHelper.toFormatString(value, 2)
      )}
      {renderView("kbbValueDate", t("KbbValueDate"), DateHelper.dateFormat)}
      {renderView("backupKey", t("BackupKey"), (backupKey) =>
        backupKey ? t("Yes") : t("No")
      )}
      {renderView("vehicleManual", t("VehicleManual"), (vehicleManual) =>
        vehicleManual ? t("Yes") : t("No")
      )}
      {renderView(
        "vehicleGradeId",
        t("Vehicle Conditions"),
        (vehicleGradeId) =>
          props.vehicleGrades.find((f) => f.value === vehicleGradeId)?.title ??
          ""
      )}
      {renderView("bank", t("Bank"))}
      {renderView("branch", t("Branch"))}
      {renderView("account", t("Account"))}
      {renderView("accountVerifyingDigit", t("AccountVerifyingDigit"))}
    </Form.Row>
  );
};
