import React from "react";

import { AdCustomersReport } from "../../../organisms";

import { useTranslation } from "react-i18next";

export const AdCustomersReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleExpressClientsReport")}</h3>
      <AdCustomersReport />
    </>
  );
};
