import React from "react";

import { C4uZapayList } from "../../../../organisms";

import { useTranslation } from "react-i18next";

export const C4uZapayListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleC4uZapayListPage")}</h3>
      <C4uZapayList />
    </>
  );
};
