import React from "react";
import { useTranslation } from "react-i18next";
import { InsuranceLogReportsOrganism } from "../../../organisms";

export const InsuranceLogReports: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("InsuranceLogReports")}</h3>
      <InsuranceLogReportsOrganism />
    </>
  );
};
