import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { conformToMask } from "react-text-mask-legacy";

import {
  Field,
  FieldArray,
  FieldMetaProps,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikProvider,
  getIn,
  useFormik,
} from "formik";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  ButtonLink,
  ButtonPrimary,
  ButtonSecondary,
  ContextCard,
  CoxIcon,
  LabelDefault,
  LabelIcon,
  MaskHelper,
  ModalDefault,
  ScrollHelper,
} from "c4u-web-components";

import { v4 as uuid } from "uuid";

import { useC4u, useSessionContext } from "../../../../hooks/";
import { ICompleteUser } from "../../../../hooks/apis/use-c4u/interfaces/interfaces";

import { inputErrorHelper } from "../../../../helper";
import { GeneralError } from "../../../atoms/";
import { Input } from "../../../molecules/forms/input/input.molecule";
import { cellPhoneNumberMask } from "../../../molecules/forms/masked-input/masked-input-templates";
import { InputWithMask } from "../../../molecules/forms/masked-input/masked-input.molecule";

import { useRegistrationStepsContext } from "../../../../hooks/contexts/use-registration-steps-context.hook";
import {
  AddUserAction,
  AlertBox,
  Checkbox,
  CheckboxLabel,
  DeleteActionContainer,
  LoginAction,
  LoginActionButton,
  LoginReturnButton,
  TrashButton,
} from "./create-users-form.organism.style";
import {
  CreateCompleteUserForm,
  CreateCompleteUserFormFormValidationSchema,
} from "./create-users-form.organism.validations";

interface IDeleteUserAction {
  active: boolean;
  action?: Function;
}

const CreateCompleteUserFormRender = (
  formikBag: FormikProps<CreateCompleteUserForm>,
  showEndingNotice: boolean,
  handleCloseEndingNotice: () => void
) => {
  const {
    isSubmitting,
    getFieldMeta,
    setErrors,
    values,
    errors,
  }: {
    isSubmitting: boolean;
    getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
    setErrors: (errors: FormikErrors<any>) => void;
    values: any;
    errors: any;
  } = formikBag;
  const { t } = useTranslation();
  const { setCurrentStep } = useRegistrationStepsContext();
  const { handleEventGTM } = useSessionContext();
  const [deletingUser, setDeletingUser] = useState({
    active: false,
  } as IDeleteUserAction);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      formikBag.handleSubmit(e);
      setTimeout(() => {
        ScrollHelper.scrollToError(document);
      }, 300);
    },
    // eslint-disable-next-line
    [formikBag]
  );

  return (
    <>
      <FormikProvider value={formikBag}>
        <Form noValidate onSubmit={handleSubmit}>
          <br />
          <GeneralError
            errorProp={getFieldMeta("general")}
            errorCode={getFieldMeta("errorCode")}
            setErrors={setErrors}
          />
          <br />
          <LabelDefault lblColor="black" lblWeight="small">
            {t("Mandatory.Fields.Notice")}
          </LabelDefault>
          <br />
          <FieldArray
            name="users"
            render={(arrayHelpers) => {
              const toggleSelection = (index: number) => {
                if (values.users.length < 2) {
                  console.log("skipping isMaster selection");
                  return;
                }

                values.users.forEach((u: ICompleteUser, idx: number) => {
                  if (idx === index) {
                    formikBag.setFieldValue(
                      `users[${idx}].isMaster`,
                      !formikBag.getFieldProps(`users[${idx}].isMaster`).checked
                    );
                  } else {
                    formikBag.setFieldValue(`users[${idx}].isMaster`, false);
                  }
                });
              };

              return (
                <>
                  {values &&
                    values.users &&
                    values.users.length &&
                    values.users.map((user: ICompleteUser, index: number) => (
                      <React.Fragment key={`userId-${user.id}-index-${index}`}>
                        <ContextCard
                          titleControl={() => (
                            <LabelIcon
                              name="user-soft"
                              fillColor="#F3BC2A"
                              lblColor="black"
                              lblWeight="regular"
                            >
                              <span>Usu&aacute;rio&nbsp;{index + 1}</span>
                            </LabelIcon>
                          )}
                          contextControl={() => (
                            <Form.Check type="checkbox">
                              <Checkbox
                                name={`users[${index}].isMaster`}
                                onChange={() => toggleSelection(index)}
                                checked={user.isMaster}
                                dataCyid={`isMaster-${index}`}
                              />
                              <CheckboxLabel>{`${t("User")} ${t(
                                "MASTER"
                              )}`}</CheckboxLabel>
                            </Form.Check>
                          )}
                        >
                          <>
                            <Field
                              type="hidden"
                              name={`users[${index}].id`}
                              onChange={formikBag.handleChange}
                              value={user.id?.toString()}
                            />
                            <Form.Group
                              as={Row}
                              controlId={`formUserMain${index}`}
                            >
                              <Col sm={12} md={6}>
                                <Input
                                  type="text"
                                  name={`users[${index}].name`}
                                  placeholder={t("Full Name")}
                                  label={t("Full Name")}
                                  onChange={formikBag.handleChange}
                                  value={user.name}
                                  required={true}
                                  dataCyid={`${user.name.replace(
                                    " ",
                                    "-"
                                  )}-${index}`}
                                  isInvalid={
                                    getIn(errors, `users[${index}].name`) !==
                                      null &&
                                    getIn(errors, `users[${index}].name`) !==
                                      undefined
                                  }
                                  errors={() =>
                                    inputErrorHelper(
                                      getIn(errors, `users[${index}].name`),
                                      t
                                    )
                                  }
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <Input
                                  type="text"
                                  name={`users[${index}].email`}
                                  placeholder={t("Email")}
                                  label={t("Email")}
                                  onChange={formikBag.handleChange}
                                  required={true}
                                  value={user.email}
                                  dataCyid={`${user.email}-${index}`}
                                  isInvalid={
                                    getIn(errors, `users[${index}].email`) !==
                                      null &&
                                    getIn(errors, `users[${index}].email`) !==
                                      undefined
                                  }
                                  errors={() =>
                                    inputErrorHelper(
                                      getIn(errors, `users[${index}].email`),
                                      t
                                    )
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              controlId={`formUserPhone${index}`}
                            >
                              <Col sm={10} md={6}>
                                <InputWithMask
                                  mask={cellPhoneNumberMask}
                                  type="text"
                                  name={`users[${index}].cellPhone`}
                                  placeholder={t("Cell Phone")}
                                  label={t("Cell Phone")}
                                  onChange={formikBag.handleChange}
                                  value={user.cellPhone}
                                  dataCyid={`cellphone-${index}`}
                                  required={true}
                                  isInvalid={
                                    getIn(
                                      errors,
                                      `users[${index}].cellPhone`
                                    ) !== null &&
                                    getIn(
                                      errors,
                                      `users[${index}].cellPhone`
                                    ) !== undefined
                                  }
                                  errors={() =>
                                    inputErrorHelper(
                                      getIn(
                                        errors,
                                        `users[${index}].cellPhone`
                                      ),
                                      t
                                    )
                                  }
                                />
                              </Col>
                              <Col xs={1}>&nbsp;</Col>
                              <br />
                              <br />
                            </Form.Group>
                            {index > 0 && (
                              <TrashButton
                                type="button"
                                onClick={() =>
                                  setDeletingUser({
                                    active: true,
                                    action: () => arrayHelpers.remove(index),
                                  })
                                }
                              >
                                <CoxIcon
                                  name="trash"
                                  width={24}
                                  height={24}
                                  fillColor="#4A4A4A"
                                />
                              </TrashButton>
                            )}
                          </>
                        </ContextCard>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  <br />
                  <AddUserAction>
                    <ButtonSecondary
                      type="button"
                      sizex="md"
                      sizey="normal"
                      onClick={() =>
                        arrayHelpers.push({
                          id: `${values.users.length + 1}`,
                          publicId: uuid(),
                          name: "",
                          email: "",
                          cellPhone: "",
                          isMaster: false,
                        })
                      }
                    >
                      {t("Add Users")}
                    </ButtonSecondary>
                  </AddUserAction>
                </>
              );
            }}
          />

          <br />
          <hr />
          <br />

          <LoginAction>
            <LoginReturnButton>
              <ButtonLink
                sizex="sm"
                onClick={() => setCurrentStep("dealership")}
              >
                {t("Return")}
              </ButtonLink>
            </LoginReturnButton>
            <LoginActionButton>
              <ButtonPrimary type="submit" sizex="md" disabled={isSubmitting}>
                {t("Next")}
              </ButtonPrimary>
            </LoginActionButton>
          </LoginAction>
        </Form>
      </FormikProvider>
      <ModalDefault
        show={showEndingNotice}
        onHide={handleCloseEndingNotice}
        title={t("Finished")}
        animation={true}
        size="lg"
        data-event="sign_up"
        footer={
          <ButtonPrimary
            sizex="sm"
            sizey="normal"
            onClick={handleCloseEndingNotice}
          >
            {t("Ok")}
          </ButtonPrimary>
        }
      >
        <AlertBox>
          <LabelDefault lblColor="blue" lblWeight="title">
            {t("Approval Pending")}
          </LabelDefault>
          <br />
          <div>
            <LabelDefault lblColor="black" lblWeight="regular">
              {t("Registration.Pending.P1")}
            </LabelDefault>
          </div>
        </AlertBox>
      </ModalDefault>
      <ModalDefault
        show={deletingUser.active}
        onHide={() => setDeletingUser({ active: false })}
        title={t("Warning")}
        animation={true}
        size="sm"
        footer={
          <DeleteActionContainer>
            <ButtonSecondary
              sizex="sm"
              sizey="normal"
              onClick={() => {
                handleEventGTM({
                  event: "alertInformation",
                  alertMessage: "[mensagem]",
                });
                setDeletingUser({ active: false });
              }}
            >
              {t("No")}
            </ButtonSecondary>
            <ButtonPrimary
              sizex="sm"
              sizey="normal"
              onClick={() => {
                handleEventGTM({
                  event: "alertInformation",
                  alertMessage: "[mensagem]",
                });
                if (deletingUser.action) deletingUser.action();
                setDeletingUser({ active: false });
              }}
            >
              {t("Yes")}
            </ButtonPrimary>
          </DeleteActionContainer>
        }
      >
        <AlertBox>
          <CoxIcon
            name="info"
            width={32}
            height={32}
            viewBox="0 0 24 24"
            fillColor="#fdba4b"
          />
          <br />
          <br />
          <LabelDefault lblColor="blue" lblWeight="title">
            {t("Are you sure to remove this user?")}
          </LabelDefault>
          <br />
          <br />
        </AlertBox>
      </ModalDefault>
    </>
  );
};

const buildRequest = (
  createdByPublicId: string,
  request: Array<ICompleteUser>
): Array<ICompleteUser> => {
  return request.map((usr: ICompleteUser) => {
    return {
      publicId: usr.publicId,
      isMaster: usr.isMaster,
      name: usr.name,
      email: usr.email.trim(),
      cellPhone: usr.cellPhone
        .replace("(", "")
        .replace(")", "")
        .replace(/-/g, "")
        .replace(" ", ""),
    } as ICompleteUser;
  });
};

const buildRequestFromAPI = (
  data: Array<ICompleteUser>
): CreateCompleteUserForm => {
  const formattedData = data.map((usr) => {
    const { cellPhone, ...usrData } = usr;

    return {
      cellPhone: cellPhone
        ? conformToMask(cellPhone, MaskHelper.Phone, {}).conformedValue
        : "",
      ...usrData,
    } as ICompleteUser;
  });

  return {
    users: formattedData,
  };
};

const CreateCompleteUserFormOnSubmit = (
  createdByPublicId: string,
  values: CreateCompleteUserForm,
  actions: FormikHelpers<CreateCompleteUserForm>,
  onSubmit: (
    request: Array<ICompleteUser>,
    actions: FormikHelpers<CreateCompleteUserForm>
  ) => void
) => {
  actions.setSubmitting(true);
  onSubmit(
    buildRequest(createdByPublicId, values.users as Array<ICompleteUser>),
    actions
  );
};

const CreateCompleteUserFormFields: React.FC = () => {
  const { createdByPublicId } = useParams() as any;
  const { isAuthenticated, logout } = useAuth0();
  const { GetUsersCreatedBy, CreateCompleteUser } = useC4u();
  const { currentStep } = useRegistrationStepsContext();
  const { handleEventGTM } = useSessionContext();

  const [masterUserId, setMasterUserId] = useState<number>();
  const [showEndingNotice, setShowEndingNotice] = useState(false);
  const handleShowEndingNotice = () => setShowEndingNotice(true);
  const handleCloseEndingNotice = () => {
    setShowEndingNotice(false);
    handleEventGTM({
      userId: masterUserId,
      event: "accountCreationSuccess",
    });
    const returnUrl =
      process.env.REACT_APP_MASTER_PAGE ?? window.location.origin;
    if (isAuthenticated) {
      logout({ returnTo: returnUrl });
    } else {
      window.location.href = returnUrl;
    }
  };

  const initialValues: CreateCompleteUserForm = {
    users: [
      {
        publicId: createdByPublicId,
        name: "",
        email: "",
        cellPhone: "",
        isMaster: true,
      },
    ],
  };

  const submitForm = (
    request: Array<ICompleteUser>,
    actions: FormikHelpers<CreateCompleteUserForm>
  ) => {
    CreateCompleteUser(createdByPublicId, request)
      .then((result: any) => {
        handleShowEndingNotice();
      })
      .catch((err: any) => {
        console.log("catch ", err);
        if (err.errors) {
          console.error(err.errors);
          actions.setErrors(err.errors);
        }

        if (err.message) {
          console.log("setting general handled error");
          actions.setFieldError("general", err.message);
        }

        if (err.generalError) {
          console.log("setting general unhandled error");
          actions.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik<CreateCompleteUserForm>({
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      CreateCompleteUserFormOnSubmit(
        createdByPublicId,
        values,
        actions,
        submitForm
      );
    },
    validationSchema: CreateCompleteUserFormFormValidationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (currentStep !== "users") return;

    GetUsersCreatedBy(createdByPublicId)
      .then((users: Array<ICompleteUser>) => {
        if (users && users.length > 0) {
          const masterUser = users.find((user) => user.isMaster === true);
          if (masterUser?.id) setMasterUserId(masterUser.id);
          const usrListForm = buildRequestFromAPI(users);
          formik.setValues(usrListForm, false);
        }
      })
      .catch((err: any) => {
        if (err.message) {
          formik.setFieldError("general", err.message);
          formik.setFieldError("errorCode", err.code);
        }

        if (err.generalError) {
          formik.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        console.log(
          "This suppose to be the last loading time for users list flow..."
        );
      });
  }, [createdByPublicId, currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return CreateCompleteUserFormRender(
    formik,
    showEndingNotice,
    handleCloseEndingNotice
  );
};

export { CreateCompleteUserFormFields };
