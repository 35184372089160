import React from "react";

const HomeVector = () => (
  <svg
      width="477"
      height="500"
      viewBox="0 0 477 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" filter="url(#filter666_d)">
        <path
          d="M218.515 21.518L16.2042 453.517C9.9935 466.779 19.6723 481.999 34.3164 481.999L234.062 482C241.735 482 248.732 477.61 252.07 470.701L460.817 38.7017C467.235 25.4212 457.559 10.0001 442.809 10.0001L236.627 10.0002C228.867 10.0002 221.807 14.4898 218.515 21.518Z"
          fill="#005CB0"
        />
      </g>
      <defs>
        <filter
          id="filter666_d"
          x="0.29248"
          y="3.05176e-05"
          width="476.542"
          height="500"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
);

export { HomeVector };
