import React from "react";

import { useTranslation } from "react-i18next";
import { SearchByPlateOrganism } from "../../../../organisms";

export const SearchByPlatePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("SearchByPlate")}</h3>
      <hr />
      <SearchByPlateOrganism />
    </>
  );
};
