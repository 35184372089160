import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  CarOutlineIcon,
  MotorcycleOutlineIcon,
  TruckOutlineIcon,
} from "../../../../assets";
import {
  BenefitsContent,
  Content,
  DifferentialsList,
  DifferentialsItem,
  FinancingList,
  Link,
  ListItem,
  SubTitle,
  Title,
} from "./about-omni.organism.style";

export const AboutOmni = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("AboutOmni")}</Title>
      <Content>
        <Trans>
          {t("WithSolutions")}
          {t("OfferYourCustomers")}
          <BenefitsContent>
            <SubTitle>{t("SomeOfOur")}</SubTitle>
            <DifferentialsList>
              <DifferentialsItem>
                <CarOutlineIcon />
                <p>{t("SingleCompany")}</p>
              </DifferentialsItem>
              <DifferentialsItem>
                <TruckOutlineIcon />
                <p>{t("TruckDriverCreditSpecialists")}</p>
              </DifferentialsItem>
              <DifferentialsItem>
                <MotorcycleOutlineIcon />
                <p>{t("TheMostCompleteBank")}</p>
              </DifferentialsItem>
            </DifferentialsList>
            <SubTitle>{t("OurFinancingOperationOffers")}</SubTitle>
            <FinancingList>
              <ul>
                <ListItem>{t("FastCredit")}</ListItem>
                <ListItem>{t("DigitalSignatureOfTheContract")}</ListItem>
                <ListItem>{t("ProposalAnalyzed")}</ListItem>
              </ul>
              <ul>
                <ListItem>{t("CreditForSelfEmployed")}</ListItem>
                <ListItem>{t("ExpertiseInNewAndUsed")}</ListItem>
                <ListItem>{t("IssueOf2nd")}</ListItem>
              </ul>
            </FinancingList>
          </BenefitsContent>

          {t("VisitOurWebsite")}
          <Link href='https://www.omni.com.br/'>www.omni.com.br</Link>
        </Trans>
        {/* <ButtonContent>
          <ButtonPrimary>{t("ClickToDownloadMaterialForOmni")}</ButtonPrimary>
        </ButtonContent> */}
      </Content>
    </React.Fragment>
  );
};
