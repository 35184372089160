import styled from "styled-components";

export const ManageAtomFieldset = styled.fieldset`
  border: solid 1px black;
  border-radius: 4px;
  padding: 8px;

  gap: 6px;
`;

export const ItemCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;

  padding: 4px 12px;

  border: 1px solid ${(props) => props.theme.colors.Dark};

  border-radius: 6px;
  background: ${(props) => props.theme.colors.GhostWhite};
`;

export const ItemCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  div:first-child {
    font-weight: 700;
  }
`;

export const RemoveItemButton = styled.div`
  cursor: pointer;
`;
