import React from "react";
import Form from "react-bootstrap/Form";
import { MaskedInputProps } from "react-text-mask-legacy";
import { LabelDefault } from "c4u-web-components";

import { FormControlMaskStyled } from "./masked-input.molecule.style";

interface C4UMaskedInputProps<T extends string | number | string[] | undefined>
  extends MaskedInputProps {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  value?: T;
  isInvalid?: boolean;
  errors?: () => JSX.Element | null;
  dataCyid?: string;
}

function InputWithMask<T extends string | number | string[] | undefined>(
  props: C4UMaskedInputProps<T>
) {
  return (
    <>
      <LabelDefault lblWeight="regular" lblColor="black">
        {props.label} {props.required ? "*" : null}
      </LabelDefault>
      <FormControlMaskStyled
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        isInvalid={props.isInvalid}
        mask={props.mask}
        data-cyid={props.dataCyid}
      />
      {props.errors && (
        <Form.Control.Feedback type="invalid">
          {props.errors()}
        </Form.Control.Feedback>
      )}
    </>
  );
}

export { InputWithMask };
