import styled from "styled-components";

const LoginAction = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const LoginReturnButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: left;
  }
`;
const LoginActionButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: right;
  }
`;
const FontDefined = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
`;

export { LoginAction, LoginActionButton, LoginReturnButton, FontDefined };
