import { YupHelper } from "c4u-web-components";
import * as Yup from "yup";
import { setLocale } from "yup";

declare module "yup" {
  interface StringSchema {
    isCpfValid(msg: string): any;
  }
}

setLocale({
  mixed: {
    default: (err) => ({ key: "field_invalid", field: err.path }),
    required: (err) => ({ key: "field_required", field: err.path }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: "field_too_small", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
  string: {
    email: (err) => ({ key: "field_invalid_email", field: err.path }),
    min: ({ min }) => ({ key: "field_min_size", values: { min } }),
    matches: (err) => ({ key: "field_invalid_pwd_weak", field: err.path }),
  },
  array: {
    min: ({ min }) => ({ key: "at_least_n_users", values: { min } }),
  },
});

Yup.addMethod<Yup.StringSchema>(Yup.string, "isCpfValid", YupHelper.isCpfValid);

export const OnboardingFormValidationSchema = Yup.object()
  .shape({
    id: Yup.string().required(),
    cpf: Yup.string().required().isCpfValid("cpf_invalid"),
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*?=+\-.,;:()~^´`\\/|])[0-9a-zA-Z!@#$%&*?=+\-.,;:()~^´`\\/|]{8,}$/
      ),
    passwordValidation: Yup.string().required().min(8).max(50),
  })
  .test("samePassword", "the passwords must match", (obj) => {
    if (obj?.password === obj?.passwordValidation) {
      return true; // everything is fine...
    }

    return new Yup.ValidationError("passwords_must_match", null, "general");
  })
  .defined();

export type OnboardingForm = Yup.InferType<
  typeof OnboardingFormValidationSchema
>;
