import React from "react";
import { useTranslation } from "react-i18next";
import { DateHelper, StatusHelper } from "../../../../../helper";

import { useC4u } from "../../../../../hooks";
import { IDealershipReport } from "../../../../../models";

import { DealershipsReportListTable } from "./daelerships-report-list-table.organism";

export const DealershipsReport: React.FC = () => {
  const { GetDealershipsReport } = useC4u();

  const { t } = useTranslation();

  return (
    <DealershipsReportListTable
      getDataCallback={GetDealershipsReport}
      tableHeaders={[
        "#",
        t("PublicId"),
        t("Created At"),
        t("Updated At"),
        t("Active"),
        t("IsProcuratorOrOwner"),
        t("Name"),
        t("Cpf"),
        t("Birthdate"),
        t("Email"),
        t("CellPhone"),
        t("Street"),
        t("Number"),
        t("Complement"),
        t("Zip Code"),
        t("Neighborhood"),
        t("City"),
        t("State"),
        t("Cnpj"),
        t("Dealership Name"),
        t("Dealership Legal Name"),
        t("Dealership Email"),
        t("Dealership Phone Primary"),
        t("Dealership Phone Secondary"),
        t("Dealership Group"),
        t("ApprovalStatus"),
        t("CreatedById"),
        t("UpdatedById"),
        t("HasBackofficeRegistrationAccess"),
        t("HasBackofficeAdsAccess"),
        t("HasDealertrackAccess"),
        t("HasKbbAccess"),
        t("HasManheimAccess"),
        t("HasProductsB3Access"),
        t("HasProductsDebtsAccess"),
        t("HasProductsInspectionAccess"),
        t("HasProductsInsuranceAccess"),
        t("RegisteredUsers"),
        t("ActiveUsers"),
      ]}
      mappingFunction={(m: IDealershipReport, i) => [
        m.id,
        m.publicId,
        DateHelper.dateHourFormat(m.createdAt),
        DateHelper.dateHourFormat(m.updatedAt),
        m.active,
        m.procurator_IsProcuratorOrOwner,
        m.procurator_Name,
        m.procurator_Cpf,
        DateHelper.dateFormat(m.procurator_BirthDate),
        m.procurator_Email,
        m.procurator_CellPhone,
        m.address_Street,
        m.address_Number,
        m.address_Complement,
        m.address_ZipCode,
        m.address_Neighborhood,
        m.address_City,
        m.address_State,
        m.cnpj,
        m.name,
        m.legalName,
        m.email,
        m.primaryPhone,
        m.secondaryPhone,
        m.group,
        StatusHelper.getApprovalStatus(m.approvalStatus, t),
        m.createdById,
        m.updatedById,
        m.hasBackofficeRegistrationAccess,
        m.hasBackofficeAdsAccess,
        m.hasDealertrackAccess,
        m.hasKbbAccess,
        m.hasManheimAccess,
        m.hasProductsB3Access,
        m.hasProductsDebtsAccess,
        m.hasProductsInspectionAccess,
        m.hasProductsInsuranceAccess,
        m.registeredUsers,
        m.activeUsers,
      ]}
    />
  );
};
