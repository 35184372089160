import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { TitleWithBackButtonAtom } from "../../../atoms";
import { UserDetailsOrganism } from "../../../organisms";

export const UserPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("User")}
        onClick={() => push(paths.backofficeUserListPage())}
      />
      <UserDetailsOrganism />
    </>
  );
};
