import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../../../hooks";

interface IProps {
  active: "personal" | "password" | "store" | "sellers";
  personalClick: () => void;
  passwordClick: () => void;
  storeClick: () => void;
  sellersClick: () => void;
}
export const GroupButtonsMenuMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userContext } = useSessionContext();

  return (
    <>
      <Row>
        <Col className={"mb-4"}>
          {props.active === "personal" ? (
            <ButtonTertiary sizex={"md"} onClick={props.personalClick}>
              {t("Personal data")}
            </ButtonTertiary>
          ) : (
            <ButtonSecondary sizex={"md"} onClick={props.personalClick}>
              {t("Personal data")}
            </ButtonSecondary>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={"mb-4"}>
          {props.active === "password" ? (
            <ButtonTertiary sizex={"md"} onClick={props.passwordClick}>
              {t("Personal password")}
            </ButtonTertiary>
          ) : (
            <ButtonSecondary sizex={"md"} onClick={props.passwordClick}>
              {t("Personal password")}
            </ButtonSecondary>
          )}
        </Col>
      </Row>
      {userContext?.isMaster && (
        <>
          <Row>
            <Col className={"mb-4"}>
              {props.active === "store" ? (
                <ButtonTertiary sizex={"md"} onClick={props.storeClick}>
                  {t("Data store")}
                </ButtonTertiary>
              ) : (
                <ButtonSecondary sizex={"md"} onClick={props.storeClick}>
                  {t("Data store")}
                </ButtonSecondary>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={"mb-4"}>
              {props.active === "sellers" ? (
                <ButtonTertiary sizex={"md"} onClick={props.sellersClick}>
                  {t("Sellers")}
                </ButtonTertiary>
              ) : (
                <ButtonSecondary sizex={"md"} onClick={props.sellersClick}>
                  {t("Sellers")}
                </ButtonSecondary>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
