import { MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { FormikControlAtom } from "../../../atoms";
import { FormPersonalDataMoleculeValidation } from "./form-personal-data.molecule.validation";

interface IProps {
  formId: string;
  onSubmit: (props: IFormPersonalDataMolecule, errors: any) => Promise<void>;
  values: IFormPersonalDataMolecule;
}

export interface IFormPersonalDataMolecule {
  name: string;
  email: string;
  cpf: string;
  cellPhone: string;
}

export const FormPersonalDataMolecule: React.FC<IProps> = (props) => {
  const formik = useFormik<IFormPersonalDataMolecule>({
    initialValues: props.values,
    onSubmit: async (values, { setErrors }) => {
      await props.onSubmit(values, setErrors);
      formik.setSubmitting(false);
    },
    validationSchema: FormPersonalDataMoleculeValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Form
      id={props.formId}
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <FormikControlAtom formik={formik} md={6} property={"name"} />
        <FormikControlAtom formik={formik} md={6} property={"email"} disabled />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"cpf"}
          disabled={!!props.values?.cpf}
          mask={MaskHelper.Cpf}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"cellPhone"}
          mask={MaskHelper.Cellphone}
        />
      </Form.Row>
    </Form>
  );
};
