import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { conformToMask } from "react-text-mask-legacy";

import { UserAccountNewDetailsValidationSchema } from "./user-account-new-details.organism.validations";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  useC4u,
  useSessionContext,
  useZipcodeAddress,
} from "../../../../hooks";

import { UpdateDealershipRequest } from "../../../../models";
import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  MaskHelper,
  PermissionEnum,
} from "c4u-web-components";
import { paths } from "../../../../constants";

export interface IFormUpdateDealershipModel {
  procurator_isProcuratorOrOwner?: string;
  procurator_name: string;
  procurator_email: string;
  procurator_cpf: string;
  procurator_birthDate: string;
  procurator_cellPhone: string;

  address_zipCode: string;
  address_street: string;
  address_number: number;
  address_complement?: string;
  address_neighborhood: string;
  address_city: string;
  address_state: string;

  name?: string;
  legalName?: string;
  email?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  group?: string | null;
}

export const UserAccountNewDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { updateDealership, getDealership } = useC4u();
  const { validateUserAuth0Permission, userAuth0Context } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useHistory();
  const localtion = useLocation();

  const hasBackofficeWebhookPermission = useMemo(
    () =>
      validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeWebhook
      ),
    [userAuth0Context, validateUserAuth0Permission]
  );

  const formik = useFormik<IFormUpdateDealershipModel>({
    initialValues: {} as IFormUpdateDealershipModel,
    onSubmit: async (values, { setErrors }) => {
      if (!hasBackofficeWebhookPermission) return;
      try {
        await updateDealership(
          id,
          new UpdateDealershipRequest({
            address: {
              city: values.address_city,
              neighborhood: values.address_neighborhood,
              number: values.address_number.toString(),
              state: values.address_state,
              street: values.address_street,
              zipCode: values.address_zipCode,
              complement: values.address_complement,
            },
            procurator: {
              birthDate: values.procurator_birthDate,
              cellPhone: values.procurator_cellPhone,
              cpf: values.procurator_cpf,
              email: values.procurator_email,
              isProcuratorOrOwner:
                values.procurator_isProcuratorOrOwner === "1",
              name: values.procurator_name,
            },
            email: values.email,
            group: values.group,
            legalName: values.legalName,
            name: values.name,
            primaryPhone: values.primaryPhone,
            secondaryPhone: values.secondaryPhone,
          })
        );
        window.location.reload();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          alert(t("api_error"));
        }
      }
    },
    validationSchema: UserAccountNewDetailsValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });
  const forceMask = (value: any, mask: any) => {
    return value
      ? conformToMask(value, mask, { guide: false }).conformedValue
      : value;
  };

  useEffect(() => {
    getDealership(id).then((dealership) => {
      setIsLoading(false);
      formik.setValues({
        address_city: dealership?.address?.city,
        address_neighborhood: dealership?.address?.neighborhood,
        address_number: Number(dealership?.address?.number ?? "0"),
        address_state: dealership?.address?.state,
        address_street: dealership?.address?.street,
        address_zipCode: forceMask(
          dealership?.address?.zipCode,
          MaskHelper.Cep
        ),
        address_complement: dealership?.address?.complement,
        procurator_birthDate: forceMask(
          dealership?.procurator?.birthDate,
          MaskHelper.Date
        ),
        procurator_cellPhone: forceMask(
          dealership?.procurator?.cellPhone,
          MaskHelper.Cellphone
        ),
        procurator_cpf: forceMask(dealership?.procurator?.cpf, MaskHelper.Cpf),
        procurator_email: dealership?.procurator?.email,
        procurator_isProcuratorOrOwner: dealership?.procurator
          ?.isProcuratorOrOwner
          ? "1"
          : "",
        procurator_name: dealership?.procurator?.name,
        email: dealership?.email,
        group: dealership?.group,
        legalName: dealership?.legalName,
        name: dealership?.name,
        primaryPhone: forceMask(dealership?.primaryPhone, MaskHelper.Phone),
        secondaryPhone: forceMask(dealership?.secondaryPhone, MaskHelper.Phone),
      });
    });
    // eslint-disable-next-line
  }, []);

  const { getAddressByZipcode } = useZipcodeAddress();

  const ZipcodeHandler = async (cep: string) => {
    if (getAddressByZipcode) {
      const address = await getAddressByZipcode(cep);
      if (address) {
        formik.setValues({
          ...formik.values,
          address_city: address.localidade,
          address_state: address.uf,
          address_street:
            address.logradouro === ""
              ? formik.values.address_street
              : address.logradouro,
          address_neighborhood:
            address.bairro === ""
              ? formik.values.address_neighborhood
              : address.bairro,
        });
      }
    }
  };

  useEffect(() => {
    if (
      formik?.values?.address_zipCode &&
      formik.values.address_zipCode.replace(/[^\d]/g, "").length === 8
    ) {
      ZipcodeHandler(formik.values.address_zipCode);
    }
  }, [formik?.values?.address_zipCode]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && (
        <div
          className={
            "mt-4 h-100 d-flex align-items-center justify-content-center"
          }
        >
          <Spinner animation="border" />
        </div>
      )}
      {!isLoading && (
        <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
          <Row>
            <Col>
              <h3>Lojista</h3>
              <hr />
            </Col>
          </Row>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="name"
              label={t("Dealership Name")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="legalName"
              label={t("Dealership Legal Name")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="email"
              label={t("Email")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              mask={MaskHelper.Phone}
              property="primaryPhone"
              label={t("Dealership Phone Primary")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Phone}
              formik={formik}
              property="secondaryPhone"
              label={t("Dealership Phone Secondary")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
          </Form.Row>

          <Row className="mt-4">
            <Col>
              <h3>Procurador</h3>
              <hr />
            </Col>
          </Row>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="procurator_name"
              label={t("Name")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="procurator_email"
              label={t("Email")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              mask={MaskHelper.Date}
              property="procurator_birthDate"
              label={t("Birthdate")}
              md={2}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Cpf}
              formik={formik}
              property="procurator_cpf"
              label={t("Cpf")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              mask={MaskHelper.Phone}
              formik={formik}
              property="procurator_cellPhone"
              label={t("CellPhone")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
          </Form.Row>

          <Row className="mt-4">
            <Col>
              <h3>Endereço</h3>
              <hr />
            </Col>
          </Row>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              mask={MaskHelper.Cep}
              property="address_zipCode"
              label={t("ZipCode")}
              md={2}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_street"
              label={t("Street")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_number"
              label={t("Number")}
              md={2}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_complement"
              label={t("Complement")}
              md={4}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_neighborhood"
              label={t("Neighborhood")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_city"
              label={t("City")}
              md={3}
              disabled={!hasBackofficeWebhookPermission}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="address_state"
              label={t("State")}
              md={1}
              maxLength={2}
              disabled={!hasBackofficeWebhookPermission}
            />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md className={"py-4"}>
              <ButtonSecondary
                sizex="md"
                loading={formik.isSubmitting}
                onClick={() =>
                  push({
                    pathname: paths.backofficeUserAccountListPage(),
                    state: localtion.state,
                  })
                }
              >
                {t("Return")}
              </ButtonSecondary>
            </Form.Group>
            <Form.Group as={Col} md className={"text-right py-4"}>
              <ButtonPrimary
                sizex="md"
                type="submit"
                loading={formik.isSubmitting}
                disabled={!hasBackofficeWebhookPermission}
              >
                {t("Save")}
              </ButtonPrimary>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </>
  );
};
