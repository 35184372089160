import axios from "axios";

const termsOfUseHttp = axios.create({
  baseURL: process.env.REACT_APP_TERMS_OF_USE,
});

const TermsOfUseConfig = {
  url: process.env.REACT_APP_TERMS_OF_USE,
};

export { termsOfUseHttp, TermsOfUseConfig };
