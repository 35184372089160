import React from "react";
import { useTranslation } from "react-i18next";
import { CorporateUserListOrganism } from "../../../organisms";

export const CorporateUserListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("CpvCorporateUserListing")}</h3>
      <CorporateUserListOrganism />
    </>
  );
};
