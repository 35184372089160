import { termsOfUseHttp, useRequest } from "../config";
import { TermsOfUseConfig } from "../config/axios-instance/terms-of-use-http";
import { AxiosRequestConfig } from "axios";

export const useTermsOfUse = () => {
  const { get } = useRequest(termsOfUseHttp, "agreements", TermsOfUseConfig);

  const getDocument = async (file: string) => {
    const configData: AxiosRequestConfig = {
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    };
    try {
      const response = await get(`v2/coxpravoce/documents/${file}`, configData);
      return new Blob([response.data], { type: "application/pdf" });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getDocument,
  };
};
