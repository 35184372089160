import React from "react";
import { Form } from "react-bootstrap";
import {
  ContainerDecoder,
  FormDecoder,
  Wrapper,
} from "./checkbox-user-decoder.atom.style";

interface IProps {
  propertyKey: string;
  name: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  formik?: any;
}

export const CheckBoxUserDecoderAtom: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      <ContainerDecoder>
        <FormDecoder controlId={props.propertyKey}>
          <Form.Check
            type="checkbox"
            label={props.name}
            onChange={props.formik.handleChange}
            checked={props.formik.values.propertyKey}
            data-cyid={props.propertyKey}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled}
          />
        </FormDecoder>
      </ContainerDecoder>
    </Wrapper>
  );
};
