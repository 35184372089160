import styled from "styled-components";

const AlertBox = styled.div`
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
`;

const LoginAction = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const LoginReturnButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: left;
  }
`;
const LoginActionButton = styled.div`
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    float: right;
  }
`;

export { AlertBox, LoginAction, LoginActionButton, LoginReturnButton };
