import {
  ActiveItemTypeConstant,
  ICoxUser,
  PermissionEnum,
} from "c4u-web-components";
import { useContext } from "react";
import { SessionContext } from "../../contexts";
import {
  IAuth0User,
  IFoundVehicles,
  IGetEmailTemplateViewModel,
  IUserAuth0,
  RouterParam,
  IDocumentModel,
} from "../../models";
import { ITermsOfUseResponse } from "../../models/apis/c4u/terms-of-use.request";

interface SessionProviderState {
  routerParamContext: RouterParam[];
  addRouterParamContext: (key: string, value: string) => void;
  userContext: ICoxUser;
  setUserContext: React.Dispatch<React.SetStateAction<ICoxUser>>;
  actualPageContext: ActiveItemTypeConstant;
  setActualPageContext: React.Dispatch<
    React.SetStateAction<ActiveItemTypeConstant>
  >;
  submitingContext: boolean;
  setSubmitingContext: React.Dispatch<React.SetStateAction<boolean>>;
  userAuth0Context: IUserAuth0;
  loadUserAuth0Context: (user: IAuth0User) => void;
  validateUserAuth0Permission: (
    user: IUserAuth0,
    permission: PermissionEnum | PermissionEnum[]
  ) => boolean;
  formContext: "personal" | "password" | "store" | "sellers";
  setFormContext: React.Dispatch<
    React.SetStateAction<"personal" | "password" | "store" | "sellers">
  >;
  emailTemplatesContext: IGetEmailTemplateViewModel[];
  setEmailTemplatesContext: React.Dispatch<
    React.SetStateAction<IGetEmailTemplateViewModel[]>
  >;
  handleEventGTM: (eventData: object) => void;
  auth0UserContext: IAuth0User | undefined;
  setAuth0UserContext: React.Dispatch<
    React.SetStateAction<IAuth0User | undefined>
  >;
  vehicleContext: IFoundVehicles;
  setVehicleContext: React.Dispatch<
    React.SetStateAction<IFoundVehicles | undefined>
  >;

  documentContext: IDocumentModel;
  setDocumentContext: React.Dispatch<IDocumentModel | undefined>;

  termsOfUse: ITermsOfUseResponse;
  setTermsOfUse: React.Dispatch<ITermsOfUseResponse | undefined>;
}

export const useSessionContext = () => {
  const context = useContext<SessionProviderState>(SessionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
