import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import { InsuranceSearchForm, PaginatableTableList } from "../../../molecules";
import {
  IGetCustomerReportResponse,
  IGetCustomerReportParams,
  StdEnum,
  GetCustomerReportParams,
} from "../../../../models";
import { Combo } from "c4u-web-components";
import { isValid, parse } from "date-fns";

interface IProps {
  getDataCallback: (values: any) => any;
  statusOptionsEnum: StdEnum;
  dealershipOptions: Combo[];
  originOptionsEnum?: StdEnum;
  insuranceCompanyOptionsEnum: StdEnum;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
}

const isDatePtBrValid = (value: string | null | undefined) => {
  if (value?.match(/^\d{2}\/\d{2}\/\d{4}$/i)) {
    try {
      const date = parse(value, "dd/MM/yyyy", new Date());
      return isValid(date);
    } catch (error: any) {
      console.log(error);
    }
  }
  return false;
};

const greaterThanDatePtBr = (
  value: string | null | undefined,
  other: string
) => {
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/i;
  if (value?.match(datePattern) && other?.match(datePattern)) {
    try {
      const date = parse(value, "dd/MM/yyyy", new Date());
      const otherDate = parse(other, "dd/MM/yyyy", new Date());
      if (!isValid(date) || !isValid(otherDate)) return false;
      else return date >= otherDate;
    } catch (error: any) {
      console.log(error);
    }
  }
  return false;
};

export const InsuranceListTableSearchOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object<IGetCustomerReportParams>({
    search: Yup.string(),
    partner: Yup.number()
      .nullable()
      .transform((v) => (v === "" ? null : v)),
    status: Yup.number()
      .nullable()
      .transform((v) => (v === "" ? null : v)),
    dealership: Yup.number()
      .nullable()
      .transform((v) => (v === "" ? null : v)),
    startDate: Yup.string()
      .optional()
      .test("invalid", t("Invalid"), function (value) {
        const finalDate = this.parent.finalDate;
        if (value === undefined) return finalDate === undefined;
        return isDatePtBrValid(value);
      }),
    finalDate: Yup.string()
      .optional()
      .test("invalid", t("Invalid"), function (value) {
        const startDate = this.parent.startDate;
        if (value === undefined) return startDate === undefined;
        if (!isDatePtBrValid(value)) return false;
        return greaterThanDatePtBr(value, startDate);
      }),
    origin: Yup.number()
      .nullable()
      .transform((v) => (v === "" ? null : v)),
    pageSize: Yup.number(),
    page: Yup.number().required(),
    orderBy: Yup.string(),
  });

  const [listData, setListData] = useState<IGetCustomerReportResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const formInitialValues = { pageSize: 50, page: 1 };
  const formik = useFormik<IGetCustomerReportParams>({
    initialValues: formInitialValues as IGetCustomerReportParams,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new GetCustomerReportParams(values)
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => {
    formik.setValues({
      ...formInitialValues,
      search: "",
      partner: null,
      status: null,
      dealership: null,
      startDate: "",
      finalDate: "",
      origin: null,
    } as IGetCustomerReportParams);
    formik.submitForm();
  };

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new GetCustomerReportParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <InsuranceSearchForm
        formik={formik}
        statusOptionsEnum={props.statusOptionsEnum}
        dealershipOptions={props.dealershipOptions}
        originOptionsEnum={props.originOptionsEnum}
        insuranceCompanyOptionsEnum={props.insuranceCompanyOptionsEnum}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />
      <PaginatableTableList
        tableName={"insurance-list"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
