import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../../helper";
import { CancelRequest, ICancelRequest } from "../../../../../models";
import { FormikControlAtom } from "../../../../atoms";
import { useInsurance } from "../../../../../hooks";

interface IProps {
  customerId: string;
  disabled: boolean;
}

export const InsuranceCancelProposalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postCancelInsuranceProposal } = useInsurance();

  const validations = Yup.object<ICancelRequest>({
    customerId: Yup.string().required(),
    cancellationReason: Yup.string().required(),
  });

  const formik = useFormik<ICancelRequest>({
    initialValues: { customerId: props.customerId } as ICancelRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmInsuranceProposalCancellation"))) {
        try {
          await postCancelInsuranceProposal(new CancelRequest(values));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else {
            console.log(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  return !props.customerId ? (
    <i>{t("No information")}</i>
  ) : (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <Form.Group as={Col} md={8}>
          <FormikControlAtom
            formik={formik}
            as={"textarea"}
            property={"cancellationReason"}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} md className={"text-right pt-5"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={props.disabled}
          >
            {t("CancelProposal")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
