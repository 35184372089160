import React from "react";
import { Stepper } from "c4u-web-components";

import { useRegistrationStepsContext } from "../../../../hooks/contexts/use-registration-steps-context.hook";

export const CreateDealershipStepper = () => {
  const { stepsContext, currentStep, setCurrentStep } = useRegistrationStepsContext();

  return (
    <>
      <Stepper
        id="dealershipRegistration"
        defaultActiveKey="dealership"
        activeKey={currentStep}
        steps={stepsContext}
        onSelect={(k) => { if(k) setCurrentStep(k) }}
      />
    </>
  );
};
