import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../../helper";
import { useSessionContext } from "../../../../../hooks";
import {
  GetListEmailTemplateParams,
  IGetEmailTemplateViewModel,
  IGetListEmailTemplateParams,
} from "../../../../../models";
import {
  BasicSearchForm,
  PaginatableTableList,
} from "../../../../molecules";

interface IProps {
  getDataCallback: (values: any) => any;
  tableName: string;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
}

export const EmailTemplatesListTableSearchOrganism: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  const { setEmailTemplatesContext } = useSessionContext();

  const validations = Yup.object<IGetListEmailTemplateParams>({
    pageSize: Yup.number().required(),
    page: Yup.number().required(),
    orderBy: Yup.string(),
  });

  const [listData, setListData] = useState<IGetEmailTemplateViewModel[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const formInitialValues = { pageSize: 50, page: 1 };
  const formik = useFormik<IGetListEmailTemplateParams>({
    initialValues: formInitialValues as IGetListEmailTemplateParams,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new GetListEmailTemplateParams(values)
        );
        setListData(data);
        setEmailTemplatesContext(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => formik.setValues(formInitialValues);

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new GetListEmailTemplateParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, [formik.values.pageSize]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BasicSearchForm
        formik={formik}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />
      <PaginatableTableList
        tableName={props.tableName}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
