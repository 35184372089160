import { FormikControlAtom, MaskHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  formik: any;
}

export const DtMockSyncVehicleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { formik } = props;

  const defaultProps = {
    translate: t,
    formik,
    sm: 4,
  };

  return (
    <Form.Row>
      <FormikControlAtom
        {...defaultProps}
        label={t("ManufactureYear")}
        property={"vehicle.manufactureYear"}
        func={(v: string) => v?.replace(/\D/g, "")}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("ModelYear")}
        property={"vehicle.modelYear"}
        func={(v: string) => v?.replace(/\D/g, "")}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("VehiclePlate")}
        property={"vehicle.licensePlate"}
        mask={MaskHelper.PlateBr}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Vin")}
        property={"vehicle.vinNmber"}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Renavam")}
        property={"vehicle.renavam"}
        mask={MaskHelper.Renavam}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Color")}
        property={"vehicle.color"}
      />
      <FormikControlAtom
        {...defaultProps}
        type="money"
        decimalScale={2}
        label={t("Price")}
        property={"vehicle.price"}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("IsNew")}
        property={"vehicle.isNew"}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("MolicarCode")}
        property={"vehicle.molicarId"}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Estado da Placa")}
        property={"vehicle.licensePlateUF"}
      />
    </Form.Row>
  );
};
