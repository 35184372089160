export interface ICancelRequest {
  customerId: string;
  cancellationReason: string;
}

export class CancelRequest {
  customerId: number;
  cancellationReason: string;
  constructor({ customerId, cancellationReason }: ICancelRequest) {
    this.customerId = parseInt(customerId);
    this.cancellationReason = cancellationReason;
  }
}
