import {
  ButtonPrimary,
  ButtonSecondary,
  EnumHelper,
  FormikControlAtom,
  ModalDefault,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  EncodeLenderCodeEnum,
  IPostDiscoverMockRequest,
  PostDiscoverMockRequest,
  StepDtEnum,
} from "../../../../models";
import {
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  id?: number;
  show: boolean;
  data?: IPostDiscoverMockRequest;
  onHide: () => void;
  onSaveCallback: (request: PostDiscoverMockRequest) => Promise<void>;
  onSaveSuccess: () => void;
  setException: (v: any) => void;
  setShowErrorModal: (v: boolean) => void;
  newEntry?: boolean;
}

export const DtMockDiscoverEditModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const initialValues = {
    publicPersonId: "",
    encodeLenderCode: "",
    step: "",
  };

  const validations = Yup.object<IPostDiscoverMockRequest>({
    publicPersonId: Yup.string().required(),
    encodeLenderCode: Yup.string().required(),
    step: Yup.string().required(),
  });

  const formik = useFormik<IPostDiscoverMockRequest>({
    initialValues: props.data ?? initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onSaveCallback(new PostDiscoverMockRequest(values));
        formik.setValues(initialValues);
        props.onSaveSuccess();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          props.onHide();
          props.setException(t("GenericApiError"));
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (props.data) formik.setValues(props.data);
    else formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <ModalDefault
      show={props.show}
      onHide={props.onHide}
      title={
        !!props.newEntry
          ? t("Create new Credit Application")
          : t("Edit Credit Application #{id}", { id: props.id })
      }
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={props.onHide}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={() => {
              const bt = document.getElementById("submit-edit");
              if (bt) bt.click();
            }}
            loading={formik.isSubmitting}
          >
            {t("Send")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="publicPersonId"
              label={t("PublicPersonId")}
              disabled={!props.newEntry}
            />
          </Form.Row>

          <Form.Row>
            <FormikControlAtom
              type="select"
              data={EnumHelper.getComboFromEnum(
                EncodeLenderCodeEnum,
                t,
                (k, v) => `${k} (${v})`
              )}
              translate={t}
              formik={formik}
              property="encodeLenderCode"
              label={t("LenderCode")}
              disabled={!props.newEntry}
            />
          </Form.Row>

          <Form.Row>
            <FormikControlAtom
              type="select"
              data={EnumHelper.getComboFromEnum(StepDtEnum, t)}
              translate={t}
              formik={formik}
              property="step"
              label={t("StepDt")}
            />
          </Form.Row>

          <button type="submit" id="submit-edit" className="d-none"></button>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
