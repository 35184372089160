import { TypesHelper } from "c4u-web-components";
import { IAddress } from "./../../../hooks/apis/use-c4u/interfaces/interfaces";

export class AddressRequest {
  zipCode: string | null;
  street: string | null;
  number: string | null;
  complement?: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;

  constructor({
    zipCode,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
  }: IAddress) {
    this.zipCode = TypesHelper.toStringNull(zipCode);
    this.street = TypesHelper.toStringNull(street);
    this.number = TypesHelper.toStringNull(number);
    this.neighborhood = TypesHelper.toStringNull(neighborhood);
    this.complement = TypesHelper.toStringNull(complement);
    this.city = TypesHelper.toStringNull(city);
    this.state = TypesHelper.toStringNull(state);
  }
}
