import { TypesHelper } from "c4u-web-components";
import {
  AdModel,
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";
import { AdStepEnum } from "../..";

export interface IGetAllAdsParams extends IPagedRequestParamsBaseModel {
  search?: string;
  steps?: AdStepEnum | null;
  stepsStatus?: number | null;
  adOrigin?: number | null;
}

export class GetAllAdsParams extends PagedRequestParamsBaseModel {
  search: string | null;
  steps?: string | null;
  stepsStatus?: string | null;
  adOrigin?: string | null;
  constructor({
    search,
    steps,
    stepsStatus,
    adOrigin,
    ...baseParams
  }: IGetAllAdsParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.steps = TypesHelper.toStringNull(steps);
    this.stepsStatus = TypesHelper.toStringNull(stepsStatus);
    this.adOrigin = TypesHelper.toStringNull(adOrigin);
  }
}

export interface IGetAllAdsPagedResponseModel extends IPagedResponseBaseModel {
  data?: AdModel[];
}
