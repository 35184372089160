import {
  ButtonPrimary,
  DateHelper,
  FormikControlAtom,
  MaskHelper,
  YupHelper,
  DeliveryPhotoEnum,
} from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection, PaginatableTableList } from "../../..";
import {
  renderViewHelper,
  convertBase64,
  formikHandleErrorHelper,
} from "../../../../../helper";
import {
  PostDeliveryPersonRequest,
  PostDeliveryPersonPhotoRequest,
  ICarDeliveryBuyerModel,
  IDeliveryPerson,
  IDeliveryPersonPhoto,
} from "../../../../../models";
import { useManhein } from "../../../../../hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { LabelAsButton } from "./ad-car-delivery.molecule.style";

interface IProps {
  data: ICarDeliveryBuyerModel;
  enable?: boolean;
}
export const AdCarBuyerDeliveryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { postDeliveryPerson, postDeliveryPhoto } = useManhein();

  const [buyerCnhIsSubmitting, setBuyerCnhIsSubmitting] = useState(false);
  const [driverCnhIsSubmitting, setDriverCnhIsSubmitting] = useState(false);
  const [buyerCnhPhoto, setBuyerCnhPhoto] = useState<string>(
    props.data.buyerCNHPhoto
  );
  const [driverCnhPhoto, setDriverCnhPhoto] = useState<string>(
    props.data.deliveryPersonCNHPhoto
  );

  const writeBoolean = (bool: boolean | undefined) => {
    return bool ? t("Yes") : t("No");
  };

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string,
    enable?: boolean
  ) => renderViewHelper(props.data, key, label, func, 3, undefined, enable);

  const stepBuyer = (value: any) => {
    switch (value) {
      case 0:
        return t("Login");
      case 1:
        return t("BuyerData");
      case 2:
        return t("TimeUpdate");
      case 3:
        return t("Checklist");
      case 4:
        return t("Finished");

      default:
        return value;
    }
  };

  const msgRequired = t("field_required");
  const msgInvalidDocument = t("field_invalid_document");

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );

  const validations = Yup.object<IDeliveryPerson>({
    deliveryName: Yup.string().required(msgRequired),
    shippingCompanyName: Yup.string().required(msgRequired),
    deliveryPersonPhone: Yup.string().required(msgRequired),
    deliveryPersonCPF: Yup.string()
      .required(msgRequired)
      .isCpfValid(msgInvalidDocument),
  });

  const {
    deliveryName,
    shippingCompanyName,
    deliveryPersonPhone,
    deliveryPersonCPF,
  } = props.data;

  const formik = useFormik<IDeliveryPerson>({
    initialValues: {
      deliveryName: deliveryName ?? "",
      shippingCompanyName: shippingCompanyName ?? "",
      deliveryPersonPhone: deliveryPersonPhone ?? "",
      deliveryPersonCPF: deliveryPersonCPF ?? "",
    },
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await postDeliveryPerson(
            new PostDeliveryPersonRequest({
              ...values,
              id: Number(id),
            })
          );
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else {
            console.log(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleFileRead = async (
    event: any,
    typePhoto: DeliveryPhotoEnum,
    setIsSubmitting: (v: boolean) => void,
    setPhoto: (v: string) => void
  ) => {
    setIsSubmitting(true);
    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (base64 && typeof base64 === "string") {
      postDeliveryPhoto(
        new PostDeliveryPersonPhotoRequest({
          id: Number(id),
          typePhoto: typePhoto,
          base64: base64.replace(/data:[/\w]+;\w+,/, ""),
        } as IDeliveryPersonPhoto)
      )
        .then(() => setPhoto(base64))
        .catch(() => alert("Ocorreu um erro ao submeter o arquivo!"))
        .finally(() => setIsSubmitting(false));
    }
  };

  const renderUpload = (
    key: string,
    typePhoto: DeliveryPhotoEnum,
    isSubmitting: boolean,
    setIsSubmitting: (v: boolean) => void,
    setPhoto: (v: string) => void,
    label?: string
  ) => {
    return (
      <Form.Group as={Col} xs={12} controlId={key}>
        <LabelAsButton disabled={isSubmitting || !props.enable}>
          <Form.Label htmlFor={key}>
            {label}
            {isSubmitting && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"ml-2"}
              />
            )}
          </Form.Label>
        </LabelAsButton>
        <Form.File
          id={key}
          disabled={isSubmitting || !props.enable}
          onChange={(e: any) =>
            handleFileRead(e, typePhoto, setIsSubmitting, setPhoto)
          }
          feedbackTooltip={true}
          accept="image/*,.pdf"
          hidden
        />
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        {renderView("buyerId", t("Id"))}
        {renderView("buyerDeliveryStep", t("Status"), stepBuyer)}
        {renderView("buyerName", t("Name"))}
        {renderView("buyerAddress", t("Address"), writeBoolean)}
        {renderView("buyerDocument", t("Document"), writeBoolean)}
        {renderView("buyerEmail", t("Email"), writeBoolean)}
      </Form.Row>

      <DetailsPageSubsection title={t("DeliveryTransportCompanyDetails")}>
        <Form.Row>
          <FormikControlAtom
            translate={t}
            formik={formik}
            property={"deliveryName"}
            label={t("DeliveryName")}
            disabled={!props.enable}
            md={4}
          />

          <FormikControlAtom
            translate={t}
            formik={formik}
            property={"shippingCompanyName"}
            label={t("CompanyName")}
            disabled={!props.enable}
            md={4}
          />

          <FormikControlAtom
            translate={t}
            formik={formik}
            property={"deliveryPersonCPF"}
            label={t("DeliveryPersonCPF")}
            disabled={!props.enable}
            md={4}
            mask={MaskHelper.Cpf}
          />

          <FormikControlAtom
            translate={t}
            formik={formik}
            property={"deliveryPersonPhone"}
            label={t("DeliveryPersonPhone")}
            disabled={!props.enable}
            md={4}
            mask={MaskHelper.Phone}
          />

          {renderView("buyerCheckListObservation", t("Observation"))}

          <Form.Group as={Col} className={"text-center pt-4"}>
            <ButtonPrimary
              sizex={"md"}
              type={"submit"}
              loading={formik.isSubmitting}
              disabled={!props.enable}
            >
              {t("Save")}
            </ButtonPrimary>
          </Form.Group>
        </Form.Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliveryBuyerChecklistDetails")}>
        <Form.Row>
          {renderView("buyerCheckListDUTATPV", t("DUT/ATPV"), writeBoolean)}
          {renderView("buyerCheckListCRLV", t("CRLV"), writeBoolean)}
          {renderView("buyerCheckListBackupKey", t("BackupKey"), writeBoolean)}
          {renderView(
            "buyerCheckListVehicleManual",
            t("VehicleManual"),
            writeBoolean
          )}
        </Form.Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliveryBuyerRescheduleDetails")}>
        <Form.Row>
          {renderView(
            "rescheduleReinspectionDate",
            t("DeliveryBuyerRescheduleDate"),
            DateHelper.dateFormat
          )}
          {renderView(
            "rescheduleReinspectionPeriod",
            t("DeliveryBuyerReschedulePeriod"),
            DateHelper.hourFormat
          )}
        </Form.Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("CNHs")}>
        <Row>
          <Col md={5} sm={6} className={"mr-5"}>
            <h5>{t("Buyer CNH")}</h5>
            {buyerCnhPhoto && (
              <img
                src={buyerCnhPhoto}
                alt={t("Buyer CNH")}
                style={{ width: "100%" }}
              />
            )}
            <Row className="mt-2">
              {renderUpload(
                "buyerCnh",
                DeliveryPhotoEnum.BuyerCNH,
                buyerCnhIsSubmitting,
                setBuyerCnhIsSubmitting,
                setBuyerCnhPhoto,
                t("UpdateBuyerCnh")
              )}
            </Row>
          </Col>

          <Col md={5} sm={6}>
            <h5>{t("Delivery CNH")}</h5>
            {driverCnhPhoto && (
              <img
                src={driverCnhPhoto}
                alt={t("Delivery CNH")}
                style={{ width: "100%" }}
              />
            )}
            <Row className="mt-2">
              {renderUpload(
                "driverCnh",
                DeliveryPhotoEnum.DeliveryPersonCNH,
                driverCnhIsSubmitting,
                setDriverCnhIsSubmitting,
                setDriverCnhPhoto,
                t("UpdateDriverCnh")
              )}
            </Row>
          </Col>
        </Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliveryBuyerJourneyStepDetails")}>
        {!props.data.adDeliverySteps.length ? (
          <i>{t("No information")}</i>
        ) : (
          <PaginatableTableList
            tableName={"buyer-steps-history"}
            dataIsLoading={false}
            thead={[t("DeliveryStepNumber"), t("DeliveryStepName"), t("Date")]}
            tbody={props.data.adDeliverySteps?.map((m, i) => [
              m.step,
              stepBuyer(m.step),
              DateHelper.dateHourFormat(m.date),
            ])}
          />
        )}
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliveryBuyerTripUpdateDetails")}>
        {!props.data.adDeliverySchedules.length ? (
          <i>{t("No information")}</i>
        ) : (
          <PaginatableTableList
            tableName={"buyer-steps-history"}
            dataIsLoading={false}
            thead={[
              t("DeliveryInitialDate"),
              t("DeliveryEstimatedDate"),
              t("DeliveryCancelledSchedule"),
            ]}
            tbody={props.data.adDeliverySchedules?.map((m, i) => [
              DateHelper.dateHourFormat(m.initialDate),
              DateHelper.dateHourFormat(m.estimateDate),
              writeBoolean(m.cancelSchedule),
            ])}
          />
        )}
      </DetailsPageSubsection>
    </Form>
  );
};
