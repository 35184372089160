import React from "react";

const CarOutlineIcon = () => (
  <svg
    width="77"
    height="40"
    viewBox="0 0 77 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.6245 35.1592H24.9434"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M51.9754 32.3078C51.9754 33.6182 52.3596 34.8992 53.0794 35.9888C53.7991 37.0784 54.8221 37.9276 56.019 38.4291C57.2159 38.9306 58.5329 39.0618 59.8035 38.8061C61.0741 38.5505 62.2413 37.9195 63.1573 36.9928C64.0734 36.0662 64.6972 34.8856 64.9499 33.6004C65.2027 32.3151 65.073 30.983 64.5773 29.7723C64.0815 28.5616 63.2419 27.5268 62.1648 26.7988C61.0876 26.0708 59.8212 25.6821 58.5257 25.6821C56.7885 25.6821 55.1223 26.3802 53.8939 27.6227C52.6655 28.8653 51.9754 30.5506 51.9754 32.3078V32.3078Z"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M11.843 32.3078C11.843 33.6182 12.2272 34.8992 12.947 35.9888C13.6667 37.0784 14.6897 37.9276 15.8866 38.4291C17.0835 38.9306 18.4005 39.0618 19.6711 38.8061C20.9417 38.5505 22.1088 37.9195 23.0249 36.9928C23.941 36.0662 24.5648 34.8856 24.8175 33.6004C25.0703 32.3151 24.9405 30.983 24.4447 29.7723C23.949 28.5616 23.1094 27.5268 22.0322 26.7988C20.9551 26.0708 19.6887 25.6821 18.3932 25.6821C16.6559 25.6821 14.9899 26.3802 13.7615 27.6227C12.5331 28.8653 11.843 30.5506 11.843 32.3078V32.3078Z"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M64.4472 35.1591H69.0709C70.4115 35.1594 71.7076 34.673 72.7236 33.7883C73.7396 32.9037 74.4079 31.6796 74.6071 30.3386L75.8644 22.1334C76.1547 20.2667 75.7461 18.3574 74.7185 16.7793C73.6908 15.2011 72.1181 14.0675 70.3079 13.6001C64.6883 12.2661 58.9217 11.6731 53.1517 11.836C49.7899 8.85807 46.0697 6.32176 42.0793 4.28727C37.9877 2.21354 33.4743 1.13379 28.8979 1.13379C24.3214 1.13379 19.808 2.21354 15.7164 4.28727L7.30051 8.57444C6.11101 9.16522 4.87682 9.65909 3.60969 10.0514C2.86667 10.3025 2.22129 10.7851 1.76595 11.4299C1.3106 12.0747 1.06866 12.8487 1.07479 13.6411V29.5591C1.07212 30.3043 1.21492 31.0427 1.495 31.7319C1.77509 32.4212 2.187 33.0477 2.707 33.5756C3.227 34.1035 3.84485 34.5224 4.52524 34.8082C5.20563 35.094 5.93514 35.2411 6.67186 35.2411H12.5123"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CarOutlineIcon;
