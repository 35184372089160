import { TypesHelper } from "c4u-web-components";

export interface IUpdateDataUserRequest {
  name?: string;
  cellPhone?: string;
  cpf?: string;
}

export class UpdateDataUserRequest {
  name: string | null;
  cellPhone: string | null;
  cpf: string | null;

  constructor({ name, cellPhone, cpf }: IUpdateDataUserRequest) {
    this.name = TypesHelper.toStringNull(name);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
  }
}
