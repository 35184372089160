import React from "react";
import { useTranslation } from "react-i18next";
import { UserListOrganism } from "../../../organisms";

export const UserListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("CpvUserListing")}</h3>
      <UserListOrganism />
    </>
  );
};
