import { ButtonPrimary } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";
import { ISendEmailModel } from "../../../../../models";
import { FormikControlAtom } from "../../../../atoms";
import { FeedbackModal } from "../../..";

interface IProps {
  template?: number;
}
export const SendEmailTemplateMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { sendEmail } = useManhein();

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>();

  const validations = Yup.object<ISendEmailModel>({
    template: Yup.number().required(),
    name: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string(),
  });

  const formik = useFormik<ISendEmailModel>({
    initialValues: {
      template: props.template,
      name: "",
      email: "",
      phoneNumber: "",
    } as ISendEmailModel,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmActionTemplateSaved"))) {
        try {
          await sendEmail({ ...values, template: Number(values.template) });
          setShowConfirmationModal(true);
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            alert(e.message);
          } else {
            alert(JSON.stringify(e));
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setFieldValue("template", props.template?.toString() ?? "");
    // eslint-disable-next-line
  }, [props.template]);

  return (
    <>
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            property={"name"}
            disabled={!formik.values.template}
          />
        </Form.Row>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            property={"email"}
            disabled={!formik.values.template}
          />
        </Form.Row>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            property={"phoneNumber"}
            disabled={!formik.values.template}
          />
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md className={"text-right pt-4"}>
            <ButtonPrimary
              type={"submit"}
              sizex={"md"}
              loading={formik.isSubmitting}
              disabled={!formik.values.template}
            >
              {t("Send")}
            </ButtonPrimary>
          </Form.Group>
        </Form.Row>
      </Form>

      <FeedbackModal
        type="success"
        show={!!showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        content={t("TestMessageSent")}
      />
    </>
  );
};
