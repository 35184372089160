import { Container } from "react-bootstrap";
import styled from "styled-components";

export const BackofficeHeaderDiv = styled.div`
  position: fixed;
  z-index: 20;
  width: 100%;
  min-height: 72px;
  top: 0;

  background-color: ${({ theme }) => theme.colors.BlueManhein};
  display: flex;
  align-items: center;
`;

export const BackofficeContentContainer = styled(Container)`
  margin-top: 100px;
`;
