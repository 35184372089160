import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../../constants";
import { TitleWithBackButtonAtom } from "../../../../atoms";
import { ApiUserDetailsOrganism } from "../../../../organisms";

export const ApiUserDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("ApiUser")}
        onClick={() => push(paths.backofficeApiUsersListPage())}
      />
      <ApiUserDetailsOrganism />
    </>
  );
};
