export enum StepDtEnum {
  lead = "lead",
  pending = "pending",
  submitted = "submitted",
  awaiting_decision = "awaiting_decision",
  approved = "approved",
  conditional_approved = "conditional_approved",
  declined = "declined",
  formalization = "formalization",
  bank_processing = "bank_processing",
  documents_processing = "documents_processing",
  documents_pending = "documents_pending",
  booked = "booked",
}

export enum EncodeLenderCodeEnum {
  Creditas = "Q1JFRElUQVM=",
  Daycoval = "REFZQ09WQUw=",
  Itau = "SXRhdQ==",
}

export enum GenderStringEnum {
  Female = "F",
  Male = "M",
}
