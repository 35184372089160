import React, { useMemo } from "react";
import {
  MyEarningsDebitsDataMolecule,
  MyEarningsObservationMolecule,
} from "../../molecules";
import {
  DocumentV3_27_27,
  DollarSign_31_31,
  Lightbulb_21_29,
} from "../../assets";
import { IObservation } from "../../molecules/my-earnings/observation/my-earnings-observation.molecule";
import { MyEarningsOrganismWrapper } from "./my-earnings.organism.style";

export const MyEarningsZapayOrganism: React.FC = () => {
  const observationData = useMemo<IObservation[]>(
    () => [
      {
        icon: Lightbulb_21_29,
        iconAltText: "lampada",
        title: "Objeto de Comissão",
        description: "Parcelamento dos débitos do veículo (via Zapay)",
      },
      {
        icon: DollarSign_31_31,
        iconAltText: "cifrão",
        title: "Forma de pagamento da comissão",
        description:
          "Apuração da comissão é feita até o 5º dia útil do mês subsequente e o pagamento em até 20 dias após o recebimento da Nota Fiscal",
      },
      {
        icon: DocumentV3_27_27,
        iconAltText: "documento",
        title: "Emitir NF em nome de",
        description: [
          "Dealertrack Brazil Soluções de Tecnologia LTDA",
          "Av. Doutor Cardoso de Melo, 1.450, Conjunto 302",
          "Vila Olímpia - São Paulo - SP",
          "CEP: 04548-005 - CNPJ: 28.387.780/0001-93",
        ],
      },
    ],
    []
  );

  const titleBackgroundColor = "#FF006066";

  return (
    <MyEarningsOrganismWrapper>
      <MyEarningsDebitsDataMolecule
        installmentTerms={[10, 11, 12]}
        percentageOverDebit={1}
        titleBackgroundColor={titleBackgroundColor}
      />
      <MyEarningsObservationMolecule
        observations={observationData}
        titleBackgroundColor={titleBackgroundColor}
      />
    </MyEarningsOrganismWrapper>
  );
};
