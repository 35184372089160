import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IC4uUserData } from "../../../../../models";
import {
  DateHelper,
  renderViewHelper,
  StringHelper,
} from "../../../../../helper";
import { DetailsPageSubsection } from "../../../../molecules";

interface IProps {
  userDetails?: IC4uUserData;
}

export const C4uUserDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewUser = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.userDetails, key, label, func);

  const renderViewProcurator = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.userDetails?.procurator, key, label, func);

  const renderViewAddress = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.userDetails?.address, key, label, func);

  const customInputDateFormat = (value: string) =>
    DateHelper.customInputDateFormat(value, "dd/MM/yyyy HH:mm:ss");

  return !props.userDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewUser("id", t("Id"))}
        {renderViewUser("name", t("Dealership Name"))}
        {renderViewUser("legalName", t("Dealership Legal Name"))}
        {renderViewUser("email", t("Email"))}
        {renderViewUser("cnpj", t("Cnpj"), StringHelper.formatCpfCnpj)}
        {renderViewUser(
          "primaryPhone",
          t("Dealership Phone Primary"),
          StringHelper.formatPhone
        )}
        {renderViewUser(
          "secondaryPhone",
          t("Dealership Phone Secondary"),
          StringHelper.formatPhone
        )}
        {renderViewUser("group", t("Dealership Group"))}
      </Form.Row>
      <DetailsPageSubsection title={t("DealershipAddress")}>
        <Form.Row>
          {renderViewAddress("street", t("Street"))}
          {renderViewAddress("number", t("Number"))}
          {renderViewAddress("complement", t("Complement"))}
          {renderViewAddress(
            "zipCode",
            t("Zip Code"),
            StringHelper.formatZipCodeBR
          )}
          {renderViewAddress("street", t("Street"))}
          {renderViewAddress("city", t("City"))}
          {renderViewAddress("state", t("State"))}
        </Form.Row>
      </DetailsPageSubsection>
      <DetailsPageSubsection title={t("ProcuratorInformation")}>
        <Form.Row>
          {renderViewProcurator("name", t("Name"))}
          {renderViewProcurator("email", t("Email"))}
          {renderViewProcurator("cpf", t("Cpf"), StringHelper.formatCpfCnpj)}
          {renderViewProcurator(
            "birthDate",
            t("Birthdate"),
            customInputDateFormat
          )}
          {renderViewProcurator(
            "cellPhone",
            t("Cell Phone"),
            StringHelper.formatPhone
          )}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
