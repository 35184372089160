import { VehicleEquipment } from "c4u-web-components";
import { IBaseResponseKbb } from "c4u-web-components/src/components/models";
import { Vehicle } from "../../../models";
import { KbbHttp, useRequest } from "../config";
export const useKbb = () => {
  const { get } = useRequest(KbbHttp, "Kbb");

  const getVehicle = async (id: number): Promise<Vehicle> => {
    const { data } = await get(`GetVehicle/${id}`);
    return data;
  };

  const getAllEquipments = async (
    id: number | null = null,
    year: number | null = null
  ): Promise<VehicleEquipment[]> => {
    const { data } = await get(`GetAllEquipments${id ? `/${id}/${year}` : ""}`);
    return data;
  };

  const getAllVehicleGrade = async (): Promise<IBaseResponseKbb[]> => {
    try {
      const { data } = await get(`GetAllVehicleGrade`);
      return data;
    } catch (error: any) {
      return [];
    }
  };

  return {
    getVehicle,
    getAllEquipments,
    getAllVehicleGrade,
  };
};
