import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProducts } from "../../../../../hooks";
import {
  DetailsPageSection,
  TrackerCustomerDetailsMolecule,
  TrackerDriverAndUserDetailsMolecule,
  TrackerEmergencyContactDetailsMolecule,
  TrackerProposalDetailsMolecule,
  TrackerStepsHistoryDetailsMolecule,
  TrackerVehicleDetailsMolecule,
} from "../../../../molecules";
import { WrapperDetails } from "../../../shared/shared.style";
import {
  ITrackerCustomerDetails,
  ITrackerProposalDetails,
  ITrackerVehicleDetails,
  IGetTrackerDetailEmergencyContact,
  IGetTrackerDetailStepsLog,
  ITrackerDriverAndUsageDetails,
} from "../../../../../models";

export const TrackerDetailOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getTrackerDetail } = useProducts();

  const [customerDetails, setCustomerDetails] =
    useState<ITrackerCustomerDetails>();
  const [vehicleDetails, setVehicleDetails] =
    useState<ITrackerVehicleDetails>();
  const [proposalDetails, setProposalDetails] =
    useState<ITrackerProposalDetails>();
  const [driverAndUsageDetails, setDriverAndUsageDetails] =
    useState<ITrackerDriverAndUsageDetails>();
  const [stepsHistoryDetails, setStepsHistoryDetails] =
    useState<IGetTrackerDetailStepsLog[]>();
  const [emergencyContactDetails, setEmergencyContactDetails] =
    useState<IGetTrackerDetailEmergencyContact[]>();

  const getTrackerDetailsAsync = useCallback(() => {
    getTrackerDetail(id).then((data) => {
      const {
        // id,

        plate,
        vin,
        dealershipExit,
        gasKit,
        vehicleUse,
        taxFree,
        taxFreeReason,
        isTunning,
        isZeroKm,
        kbbId,
        vehicleName,

        step,
        createdAt,
        customerId,
        businessNumber,
        // coxUserId,

        name,
        email,
        cellphone,
        document,
        birthdate,
        gender,
        maritalStatus,
        licenseTime,
        street,
        complement,
        neighborhood,
        zipCode,
        number,

        // legalVehicleOwn,

        driversBetween18and26,
        vehicleUseToWork,
        storedClosedGarage,
        storedClosedGarageAtHome,
        storedClosedGarageAtWork,
        storedClosedGarageAtSchool,
        overnightZipCode,
        mainDriver,
        driverPersonalDocument,
        driverName,
        driverBirthdate,
        driverGender,
        driverMaritalStatus,
        driverLink,

        // partnerLeadId,
        // partnerQuoteGuid,
        partnerQuoteItemId,
        partnerOrderId,
        partnerShopId,
        schedulingDate,
        partnerPeriodNumber,

        stepsLog,
        emergencyContacts,
      } = data;

      setCustomerDetails({
        customerId,
        name,
        email,
        cellphone,
        document,
        birthdate,
        gender,
        maritalStatus,
        street,
        complement,
        neighborhood,
        zipCode,
        number,
      });

      setVehicleDetails({
        plate,
        vin,
        dealershipExit,
        gasKit,
        vehicleUse,
        taxFree,
        taxFreeReason,
        isTunning,
        isZeroKm,
        kbbId,
        vehicleName,
      });

      setProposalDetails({
        step,
        createdAt,
        partnerQuoteItemId,
        businessNumber,
        partnerOrderId,
        partnerShopId,
        schedulingDate,
        partnerPeriodNumber,
      });

      setDriverAndUsageDetails({
        driversBetween18and26,
        vehicleUseToWork,
        storedClosedGarage,
        storedClosedGarageAtHome,
        storedClosedGarageAtWork,
        storedClosedGarageAtSchool,
        overnightZipCode,
        mainDriver,
        licenseTime,
        driverPersonalDocument,
        driverName,
        driverBirthdate,
        driverGender,
        driverMaritalStatus,
        driverLink,
      });

      setStepsHistoryDetails(stepsLog);
      setEmergencyContactDetails(emergencyContacts);
    });
  }, [getTrackerDetail, id]);

  useEffect(() => {
    getTrackerDetailsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperDetails>
      <DetailsPageSection
        title={t("TrackerCustomerDetails")}
        classNames={["tracker-customer-data"]}
      >
        <TrackerCustomerDetailsMolecule customerDetails={customerDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TrackerVehicleDetails")}
        classNames={["tracker-vehicle-data"]}
      >
        <TrackerVehicleDetailsMolecule vehicleDetails={vehicleDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TrackerDriverAndUsageDetails")}
        classNames={["tracker-driver-and-usage-data"]}
      >
        <TrackerDriverAndUserDetailsMolecule
          driverAndUsageDetails={driverAndUsageDetails}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TrackerProposalDetails")}
        classNames={["tracker-dealership-data "]}
      >
        <TrackerProposalDetailsMolecule proposalDetails={proposalDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TrackerStepsHistory")}
        classNames={["tracker-steps-history-data"]}
      >
        <TrackerStepsHistoryDetailsMolecule
          stepsHistoryDetails={stepsHistoryDetails}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TrackerEmergencyContacts")}
        classNames={["tracker-emergency-contacts-data"]}
      >
        <TrackerEmergencyContactDetailsMolecule
          emergencyContactDetails={emergencyContactDetails}
        />
      </DetailsPageSection>

      {/* <DetailsPageSection
        title={t("CalcelTrackerProposal")}
        classNames={["cancel-tracker-proposal-data"]}
      >
        <TrackerCancelProposalMolecule
          customerId={id}
          disabled={[
            undefined,
            ProposalStepEnum.Cancelled,
            ProposalStepEnum.PaidOutAfterExpired,
          ].includes(currentStep)}
        />
      </DetailsPageSection> */}
    </WrapperDetails>
  );
};
