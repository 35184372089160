import React, { useEffect } from "react";

import { ButtonSecondary } from "c4u-web-components";

import { useSessionContext } from "../../../hooks";

import { BoxTitle, BoxWhiteShadow, ContentWrapper } from "./renave.page.style";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DiagramRenave } from "../../assets";
import { useHistory } from "react-router";
import { paths } from "../../../constants";

export const RenavePage = () => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("RENAVE");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentWrapper>
        <Container className={"mb-4"}>
          <Row className={"mb-4 pb-4"}>
            <Col>
              <ButtonSecondary
                sizex={"sm"}
                sizey={"thin"}
                onClick={() => replace(paths.home())}
              >
                {t("Start")}
              </ButtonSecondary>
            </Col>
          </Row>

          <Row className={"my-4"}>
            <Col>
              <BoxTitle>{t("RENAVE")}</BoxTitle>
            </Col>
          </Row>

          <Row className={"my-4"}>
            <Col>
              <BoxWhiteShadow heigthAuto={true}>
                <div className={"description"}>{t("DescriptionRenave")}</div>
              </BoxWhiteShadow>
            </Col>
          </Row>

          <Row className={"my-4 pb-4"}>
            <Col>
              <BoxWhiteShadow heigthAuto={false}>
                <div className={"cardInfo"}>
                  <h6>{t("RenaveTitle1")}</h6>
                  <ul>
                    <li>
                      MS e SC reduziram a taxa de transferência (90% e 77%,
                      respectivamente);
                    </li>
                    <li>Eliminação de custos com cartório;</li>
                    <li>
                      Retirada da exigência da vistoria quando há troca entre
                      estabelecimentos;
                    </li>
                  </ul>
                </div>
              </BoxWhiteShadow>
            </Col>
            <Col>
              <BoxWhiteShadow heigthAuto={false}>
                <div className={"cardInfo"}>
                  <h6>{t("RenaveTitle2")}</h6>
                  <ul>
                    <li>
                      Verificação “online” da situação do veículo para
                      transferência (+ segurança);
                    </li>
                    <li>Simplificação da vistoria de entrada;</li>
                    <li>Redução do tempo com documentação em mais 50%;</li>
                    <li>
                      Processo “online” e acesso à documentação digitalmente,
                      trazendo melhor controle;
                    </li>
                    <li>Eliminação de visitas ao cartório;</li>
                  </ul>
                </div>
              </BoxWhiteShadow>
            </Col>
            <Col>
              <BoxWhiteShadow heigthAuto={false}>
                <div className={"cardInfo"}>
                  <h5>{t("RenaveTitle3")}</h5>
                  <ul>
                    <li>
                      Transforma o comerciante de veículos em empresário (nível
                      de governança);
                    </li>
                    <li>
                      Possibilidade de usar o estoque como forma de garantia;
                    </li>
                  </ul>
                </div>
              </BoxWhiteShadow>
            </Col>
          </Row>

          <Row className={"my-4"}>
            <Col>
              <BoxTitle>{t("Sells flux")}</BoxTitle>
            </Col>
          </Row>

          <Row className={"my-4"}>
            <Col>
              <img src={DiagramRenave} alt={"Renave"} />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    </>
  );
};
