import { useContext } from 'react';

import { IRegistrationStepsProviderProps, RegistrationStepsContext } from '../../contexts'

export const useRegistrationStepsContext = () => {
  const context = useContext<IRegistrationStepsProviderProps>(RegistrationStepsContext);

  if (!context) {
    throw new Error('Empty context');
  }

  return context;
};
