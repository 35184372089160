import styled from "styled-components";

const ContentWrapper = styled.div`
  background: #f0f0f0;
  position: absolute;
  top: 62px;
  margin-left: 74px;
  padding-right: 10px;
  max-width: calc(100% - 74px);
  min-width: calc(100% - 74px);
  overflow-x: hidden;
  padding-top: 50px;
  .breadcrumb {
    background-color: transparent;
    font-size: 10px;
    line-height: 16px;
  }
  h4 {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;

    /* or 150% */
    display: flex;
    align-items: center;
    letter-spacing: -0.15px;

    /* Dark */
    color: #4a4a4a;

    margin: 0;
    padding: 0;
  }
  h6 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    /* or 171% */
    display: flex;
    align-items: center;

    /* Dark */
    color: #4a4a4a;
    margin: 0 0 30px 0;
    padding: 0;
  }
`;

const Title = styled.div`
  /* Headline 4 / 16 bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;

  /* Dark */

  color: #4a4a4a;
`;

export { ContentWrapper, Title };
