import styled from "styled-components";

export const WrapperLenderOperatorDetails = styled.div`
.overwrite-input-css-attrs input {
    height: unset;
  }`;

export const LenderOperatorDetailsHR = styled.hr`
  margin: 0.5rem 0;
`;

export const WrapperButtonsLenderOperatorDetails = styled.div`
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 0;
`;



