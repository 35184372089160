import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IKbbUserData } from "../../../../../models";
import { renderViewHelper, StringHelper } from "../../../../../helper";

interface IProps {
  userDetails?: IKbbUserData;
  userMayHaveCnpj?: boolean;
}

export const KbbUserDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewUser = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.userDetails, key, label, func);

  return !props.userDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <Form.Row>
      {renderViewUser("name", t("Name"))}
      {renderViewUser("email", t("Email"))}
      {renderViewUser(
        "document",
        props.userMayHaveCnpj ? t("CpfCnpj") : t("Cpf"),
        StringHelper.formatCpfCnpj
      )}
      {renderViewUser(
        "phoneNumber",
        t("PhoneNumber"),
        StringHelper.formatPhone
      )}
    </Form.Row>
  );
};
