import axios from "axios";

const ManheinHttp = axios.create({
  baseURL: process.env.REACT_APP_API_MANHEIN_URL,
  timeout: 600000,
});

const manheimClientConfig = {
  connection: process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION,
  domain: process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE,
};

export { ManheinHttp, manheimClientConfig };
