import React, { useEffect } from "react";

import { CoxIcon } from "c4u-web-components";

import { LogoNewManheimWhite, CogsSvg } from "../../atoms";

import {
  Container,
  LeftLogo,
  ActionButton,
  LabelWhite,
  Ellipse,
  ConfigurationButton
} from "./top-bar-menu.page.style";
import { useLocation } from "react-router-dom";

const toggleClientMenu = (target: string) => {
  window.parent.postMessage({ eventName: 'c4u::toggleMenu'}, target);
}

export const TopBarMenu = () => {
  const { search } = useLocation();

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <LeftLogo>
        <LogoNewManheimWhite />
      </LeftLogo>
      <ActionButton>
        <LabelWhite>&Aacute;rea do usu&aacute;rio</LabelWhite>
        <Ellipse onClick={() => toggleClientMenu(new URLSearchParams(search).get("parentId") ?? "")}>
          <CoxIcon name="arrow-opened" fillColor="#FFC20E" />
        </Ellipse>
      </ActionButton>
      <ConfigurationButton>
        <CogsSvg />
        <LabelWhite>Configura&ccedil;&otilde;es</LabelWhite>
      </ConfigurationButton>
    </Container>
  );
};
