import { TypesHelper } from "c4u-web-components";
import { AddressRequest } from "../..";
import {
  IAddress,
  IProcurator,
} from "./../../../hooks/apis/use-c4u/interfaces/interfaces";

export interface IUpdateDealershipModel {
  procurator: IProcurator;
  address: IAddress;
  name?: string;
  legalName?: string;
  email?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  group?: string | null;
}

export class ProcuratorRequest {
  isProcuratorOrOwner: boolean | null;
  name: string | null;
  email: string | null;
  cpf: string | null;
  birthDate: Date | null;
  cellPhone: string | null;
  constructor({
    isProcuratorOrOwner,
    name,
    email,
    cpf,
    birthDate,
    cellPhone,
  }: IProcurator) {
    this.isProcuratorOrOwner = TypesHelper.toBooleanNull(isProcuratorOrOwner);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.birthDate = TypesHelper.toDateNull(birthDate);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
  }
}

export class UpdateDealershipRequest {
  procurator: ProcuratorRequest | null;
  address: AddressRequest | null;
  name: string | null;
  legalName: string | null;
  email: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  group: string | null;

  constructor({
    procurator,
    address,
    name,
    legalName,
    email,
    primaryPhone,
    secondaryPhone,
    group,
  }: IUpdateDealershipModel) {
    this.procurator = new ProcuratorRequest(procurator);
    this.address = new AddressRequest(address);
    this.name = TypesHelper.toStringNull(name);
    this.legalName = TypesHelper.toStringNull(legalName);
    this.email = TypesHelper.toStringNull(email);
    this.primaryPhone = TypesHelper.toStringOnlyNumbersNull(primaryPhone);
    this.secondaryPhone = TypesHelper.toStringOnlyNumbersNull(secondaryPhone);
    this.group = TypesHelper.toStringNull(group);
  }
}
