import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { IAdOffer } from "../../../../../models";

interface IProps {
  offer?: IAdOffer;
}
export const AdOfferMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => {
    const values = props.offer as any;
    const valueFormated = props.offer ? func ? func(values[key]) : values[key] : "";
    return (
      <Form.Group as={Col} md={4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <Form.Row>
      {renderView("value", t("OfferValue"), (value) => "R$ " + NumberHelper.toFormatString(value, 2))}
      {renderView("offerDate", t("OfferDate"), DateHelper.dateFormat)}
    </Form.Row>
  );
};
