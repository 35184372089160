import React from "react";
import {
  ButtonPrimary,
  DateHelper,
  FormikControlAtom,
  MaskHelper,
  YupHelper,
} from "c4u-web-components";
import { Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper, StringHelper } from "../../../../../helper";
import {
  CustomerModel,
  IUpdateCustomerModel,
  UpdateCustomerRequest,
} from "../../../../../models";
import { useManhein } from "../../../../../hooks";

interface IProps {
  customer: CustomerModel;
  enableEdit?: boolean;
  setException: React.Dispatch<any>;
}
export const AdCustomerMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { updateCustomer } = useManhein();

  const msgRequired = t("field_required");

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isMobilePhoneValid",
    YupHelper.isMobilePhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isEmailValid",
    YupHelper.isEmailValid
  );

  const validations = Yup.object<IUpdateCustomerModel>({
    id: Yup.number(),
    mobile: Yup.string()
      .nullable()
      .required(msgRequired)
      .isPhoneValid(t("field_invalid_phone")),
    email: Yup.string()
      .nullable()
      .required(msgRequired)
      .isEmailValid(t("field_invalid_email")),
  });

  const formik = useFormik<IUpdateCustomerModel>({
    initialValues: {
      id: props.customer.id,
      email: props.customer.email,
      mobile: props.customer.mobile,
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        await updateCustomer(new UpdateCustomerRequest(values));
        window.location.reload();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else if (e?.message) {
          props.setException(e.message);
        } else {
          props.setException(e);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const writeBoolean = (bool: boolean | undefined) => {
    return bool ? t("Yes") : t("No");
  };

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => {
    const values = props.customer as any;
    const valueFormated = func ? func(values[key]) : values[key];
    return (
      <Form.Group as={Col} md={4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={valueFormated === null ? "" : valueFormated}
          disabled={true}
        />
      </Form.Group>
    );
  };

  const renderViewAddress = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => {
    const values = props.customer.address as any;
    const valueFormated = func ? func(values[key]) : values[key];
    return (
      <Form.Group as={Col} md={4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={valueFormated === null ? "" : valueFormated}
          disabled={true}
        />
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        {renderView("id", t("Id"))}
        {renderView("name", t("Name"))}

        <FormikControlAtom
          translate={t}
          formik={formik}
          property="email"
          label={t("Email")}
          md={4}
          disabled={!props.enableEdit}
        />

        {renderView("idAuth0", t("IdAuth0"))}
        {renderView("document", t("Document"), StringHelper.formatCpfCnpj)}
        {renderView("birthDate", t("Birthdate"), DateHelper.dateFormat)}

        <FormikControlAtom
          translate={t}
          mask={MaskHelper.Phone}
          formik={formik}
          property="mobile"
          label={t("CellPhone")}
          md={4}
          disabled={!props.enableEdit}
        />

        {renderView("phoneNumber", t("PhoneNumber"), StringHelper.formatPhone)}
        {renderView(
          "acceptedReceiveSms",
          t("AcceptedReceiveSms"),
          writeBoolean
        )}
        {renderViewAddress(
          "zipCode",
          t("Zip Code"),
          StringHelper.formatZipCodeBR
        )}
        {renderViewAddress("street", t("Street"))}
        {renderViewAddress("number", t("Number"))}
        {renderViewAddress("complement", t("Complement"))}
        {renderViewAddress("neighborhood", t("Neighborhood"))}
        {renderViewAddress("city", t("City"))}
        {renderViewAddress("state", t("State"))}

        <Form.Group as={Col} className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enableEdit}
          >
            {t("Save")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
