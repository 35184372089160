import { TypesHelper } from "c4u-web-components";
import { PaymentStatusEnum } from "../..";

export interface IUpdateVehicleDebtRequest {
  id: number;
  status: PaymentStatusEnum;
}

export class UpdateVehicleDebtRequest {
  id: number | null;
  status: PaymentStatusEnum | null;
  constructor({ id, status }: IUpdateVehicleDebtRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.status = TypesHelper.toNumberNull(status);
  }
}
