import { useRequest, viacepHttp } from "../config";
import { ZipcodeAddress } from "../../../models";

export const useZipcodeAddress = () => {
  const { get } = useRequest(viacepHttp, "ws");

  const getAddressByZipcode = async (
    zipcode: string
  ): Promise<ZipcodeAddress> => {
    try {
      const zipcodeNumbers = zipcode?.replace(/[^\d]/g, "");
      const response = await get(`${zipcodeNumbers}/json/`, { headers: {} });
      return response.data;
    } catch (error: any) {
      console.log(error);
    }

    return {} as any;
  };

  return {
    getAddressByZipcode,
  };
};
