import { TypesHelper } from "c4u-web-components";

export interface IUpdateCustomerModel {
  id: number;
  mobile?: string;
  email?: string;
}

export class UpdateCustomerRequest {
  id: number | null;
  mobile: string | null;
  email: string | null;
  constructor({ id, mobile, email }: IUpdateCustomerModel) {
    this.id = TypesHelper.toNumberNull(id);
    this.mobile = TypesHelper.toStringOnlyNumbersNull(mobile);
    this.email = TypesHelper.toStringNull(email);
  }
}
