import styled from "styled-components";

export const WrapperPartnerCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 165px;
  min-width: 150px;
  max-width: 180px;
  height: 108px;

  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &.selected {
    border: 1px solid ${(props) => props.theme.colors.BlueNavy};
  }

  cursor: pointer;
`;
