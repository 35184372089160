const formikHandleErrorHelper = (apiErrors: any) => {
  let newErrors = {} as any;

  Object.keys(apiErrors).forEach(prop => {
    const value = apiErrors[prop];
    const newProp = prop.replace(/^\$\./g,'');
    if (value?.text) {
      newErrors[newProp] = value.text;
    }
  });

  return newErrors;
};

export { formikHandleErrorHelper };