import { EnumHelper, FormikControlAtom, MaskHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GenderStringEnum } from "../../../../models";

interface IProps {
  formik: any;
}

export const DtMockSyncCustomerMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { formik } = props;
  const defaultProps = {
    translate: t,
    formik,
    sm: 4,
  };

  return (
    <Form.Row>
      <FormikControlAtom
        {...defaultProps}
        label={t("Full Name")}
        property="customer.completeName"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Cpf")}
        property="customer.cpf"
        mask={MaskHelper.Cpf}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("IdentityType")}
        property="customer.typeId"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("NDocument")}
        property="customer.idNumber"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("IdentityIssuingAgency")}
        property="customer.expeditorCode"
      />
      <FormikControlAtom
        {...defaultProps}
        type="date"
        label={t("IdentityIssuingDate")}
        property="customer.expeditionDate"
      />
      <FormikControlAtom
        {...defaultProps}
        type="date"
        label={t("Birthdate")}
        property="customer.birthDate"
      />
      <FormikControlAtom
        {...defaultProps}
        type="select"
        data={EnumHelper.getComboFromEnum(GenderStringEnum, t)}
        label={t("Gender")}
        property="customer.gender"
      />
      <FormikControlAtom
        {...defaultProps}
        inputMode="email"
        label={t("Email")}
        property="customer.email"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("MaritalStatus")}
        property="customer.maritalStatus"
      />
      <FormikControlAtom
        {...defaultProps}
        type="money"
        decimalScale={2}
        label={t("Revenue")}
        property="customer.revenue"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Professional Activity")}
        property="customer.professionalActivity"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Profession")}
        property="customer.profession"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("NationalityType")}
        property="customer.countryOfBorn"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("PhoneNumber")}
        property="customer.phonenumber"
        mask={MaskHelper.Phone}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Cell Phone")}
        property="customer.cellphonenumber"
        mask={MaskHelper.Cellphone}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Zip Code")}
        property="customer.address.zipCode"
        mask={MaskHelper.Cep}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Street")}
        property="customer.address.address"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Number")}
        property="customer.address.homeNro"
        func={(v: string) => v?.replace(/\D/g, "")}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Complement")}
        property="customer.address.complement"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Neighborhood")}
        property="customer.address.district"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("City")}
        property="customer.address.city"
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("State")}
        property="customer.address.state"
      />
    </Form.Row>
  );
};
