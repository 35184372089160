import { NumberHelper } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useProducts, useC4u } from "../../../../../hooks";
import {
  IC4uUserData,
  IVehicleDebitDetailsViewModel,
  IZapayTransactionData,
  IZapayVehicleData,
} from "../../../../../models";
import {
  C4uUserDetailsMolecule,
  ZapayDebtsPaidDetailsMolecule,
  DetailsPageSection,
  ZapayTransactionDetailsMolecule,
  ZapayVehicleDetailsMolecule,
} from "../../../../molecules";
import { WrapperDetails } from "../../../shared/shared.style";

export const DetailsC4uZapayOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getDebtsDetails } = useProducts();
  const { GetDealershipByUser } = useC4u();

  const [userDetails, setUserDetails] = useState<IC4uUserData>();
  const [vehicleDetails, setVehicleDetails] = useState<IZapayVehicleData>();
  const [debtsPaidDetails, setDebtsPaidDetails] =
    useState<IVehicleDebitDetailsViewModel[]>();
  const [transactionDetails, setTransactionDetails] =
    useState<IZapayTransactionData>();
  const [totalDebts, setTotalDebts] = useState<string>();

  const getDebtsDetailsAsync = useCallback(() => {
    getDebtsDetails(id)
      .then(async (data) => {
        const customerData = await GetDealershipByUser(data.customer.email);
        return {
          data,
          customerData,
        };
      })
      .then(({ data, customerData }) => {
        const {
          renavam,
          plate,
          detranUf,
          debitsProtocol,
          totalDebts,
          status,
          paymentDate,
          vehicleDebtDetails,
          customer,
        } = data;
        setUserDetails(customerData);
        setVehicleDetails({
          detranUf,
          plate,
          renavam,
          clientDocument: customer.document,
        });
        setDebtsPaidDetails(vehicleDebtDetails);
        setTransactionDetails({
          debitsProtocol,
          status,
          paymentDate,
        });
        setTotalDebts(NumberHelper.toFormatString(totalDebts, 2, "0,00"));
      });
  }, [getDebtsDetails, id, GetDealershipByUser]);

  useEffect(() => {
    getDebtsDetailsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperDetails>
      <DetailsPageSection
        title={t("UserInformationDetails")}
        classNames={["user-data"]}
      >
        <C4uUserDetailsMolecule userDetails={userDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("VehicleInformationDetais")}
        classNames={["vehicle-data"]}
      >
        <ZapayVehicleDetailsMolecule vehicleDetails={vehicleDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("DebtsPaidInformationDetails")}
        classNames={["debts-paid-data"]}
      >
        <ZapayDebtsPaidDetailsMolecule
          debtsPaidDetails={debtsPaidDetails}
          totalDebts={totalDebts}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TransactionInformationDetais")}
        classNames={["transaction-data"]}
      >
        <ZapayTransactionDetailsMolecule
          id={Number(id)}
          transactionDetails={transactionDetails}
        />
      </DetailsPageSection>
    </WrapperDetails>
  );
};
