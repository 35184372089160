import { TypesHelper } from "c4u-web-components";
import { Address } from "./customer.model";

export interface ILenderUserRequest {
  id?: number;
  name?: string;
  email: string;
  document: string;
  mobile?: string;
  lenderId: number;
  birthDate?: string | Date;
  isLender?: boolean;
  acceptedReceiveSms?: boolean;
  withoutInspection?: boolean;
  address?: Address;
}

export class LenderUserRequest {
  id: number | null;
  name: string | null;
  email: string | null;
  document: string | null;
  mobile: string | null;
  lenderId: number | null;
  birthDate?: string | null;
  isLender?: boolean | null;
  acceptedReceiveSms?: boolean | null;
  withoutInspection?: boolean | null;
  address: Address | {};
  constructor({
    id,
    name,
    email,
    document,
    mobile,
    birthDate,
    isLender,
    acceptedReceiveSms,
    withoutInspection,
    lenderId,
  }: ILenderUserRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.lenderId = TypesHelper.toNumberNull(lenderId);
    this.document = TypesHelper.toStringOnlyNumbersNull(document);
    this.mobile = TypesHelper.toStringOnlyNumbersNull(mobile);
    this.birthDate = TypesHelper.toDateNull(birthDate)?.toISOString();
    this.isLender = !!isLender;
    this.acceptedReceiveSms = !!acceptedReceiveSms;
    this.withoutInspection = !!withoutInspection;
    this.address = {};
  }
}
