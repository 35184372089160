import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../..";
import { DateHelper, renderViewHelper } from "../../../../../helper";
import { IInsuranceDocumentDetails } from "../../../../../models";

interface IProps {
  documentDetails?: IInsuranceDocumentDetails;
}

export const InsuranceDocumentDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewDocument = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.documentDetails, key, label, func);

  return !props.documentDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <DetailsPageSubsection title={t("IdentityDocument")}>
        <Form.Row>
          {renderViewDocument("identityType", t("IdentityType"))}
          {renderViewDocument("identityNumber", t("IdentityNumber"))}
        </Form.Row>
        <Form.Row>
          {renderViewDocument(
            "identityIssuingAgency",
            t("IdentityIssuingAgency")
          )}
          {renderViewDocument(
            "identityIssuingDate",
            t("IdentityIssuingDate"),
            DateHelper.dateFormat
          )}
        </Form.Row>
      </DetailsPageSubsection>
      <DetailsPageSubsection title={t("DriverLicense")}>
        <Form.Row>
          {renderViewDocument("cnhNumber", t("CnhNumber"))}
          {renderViewDocument("cnhCategory", t("CnhCategory"))}
        </Form.Row>
        <Form.Row>
        {renderViewDocument("cnhIssuingAgency", t("CnhIssuingAgency"))}
          {renderViewDocument(
            "cnhIssuingDate",
            t("CnhIssuingDate"),
            DateHelper.dateFormat
          )}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
