import React from "react";
import { Col, Form } from "react-bootstrap";
import { INationalBase } from "../../../../models";
import { Wrapper } from "./details.molecule.style";
import { useTranslation } from "react-i18next";

interface IProps {
  data: INationalBase;
}

export const NationalBaseMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewNationalBase = (
    key: string,
    label: string,
    size?: number,
    func?: (attr: any) => string
  ) => {
    const values = props.data as any;
    const valueFormated = func ? func(values[key]) : values[key];
    return (
      <Form.Group as={Col} md={size ? size : 4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated ?? "-"} disabled />
      </Form.Group>
    );
  };

  const renderViewNDocument = () => (
    <Form.Group as={Col} md={4}>
      <Form.Label>{t("NDocument")}</Form.Label>
      <Form.Control value={props.data.nDocument?.space ?? "-"} disabled />
    </Form.Group>
  );

  return (
    <>
      <Wrapper>
        <Form.Row className="p-2">
          {renderViewNationalBase("id", t("ID"))}
          {renderViewNationalBase("hasError", t("HasError"))}
          {renderViewNationalBase(
            "nationalBaseReturn",
            t("NationalBaseReturn")
          )}
          {renderViewNationalBase("rfActive", t("RfActive"))}
          {renderViewNationalBase("plate", t("Plate"))}
          {renderViewNationalBase("chassi", t("Vin"))}
          {renderViewNationalBase("renavam", t("Renavam"))}
          {renderViewNationalBase("status", t("Situation"))}
          {renderViewNationalBase("chassiRescheduled", t("ChassiRescheduled"))}
          {renderViewNationalBase("lastUpdate", t("LastUpdate"))}
          {renderViewNationalBase("state", t("County"))}
          {renderViewNationalBase("uf", t("Uf"))}
          {renderViewNationalBase("provenance", t("Provenance"))}
          {renderViewNationalBase("type", t("Type"))}
          {renderViewNationalBase("specie", t("Specie"))}
          {renderViewNationalBase("typeMounting", t("TypeMounting"))}
          {renderViewNationalBase("brandModel", t("BrandModel"))}
          {renderViewNationalBase("modelYear", t("ModelYear"))}
          {renderViewNationalBase("manufactureYear", t("ManufactureYear"))}
          {renderViewNationalBase("capPassag", t("CapPassag"))}
          {renderViewNationalBase("color", t("Color"))}
          {renderViewNationalBase("potency", t("Potency"))}
          {renderViewNationalBase("engineCapacity", t("EngineCapacity"))}
          {renderViewNationalBase("fuel", t("Fuel"))}
          {renderViewNationalBase("nMotor", t("NMotor"))}
          {renderViewNationalBase("penaltyRENAINF", t("PenaltyRENAINF"))}
          {renderViewNationalBase("salesAnnouncement", t("SalesAnnouncement"))}
          {renderViewNationalBase(
            "pendingIssuanceDocument",
            t("PendingIssuanceDocument")
          )}
          {renderViewNationalBase(
            "restrictionRENAJUD",
            t("RestrictionRENAJUD")
          )}
          {renderViewNationalBase("recall", t("Recall"))}
          {renderViewNationalBase("electronicPlacing", t("ElectronicPlacing"))}
          {renderViewNationalBase("restrictionRFB", t("RestrictionRFB"))}
          {renderViewNationalBase("mercosulPlate", t("MercosulPlate"))}
          {renderViewNationalBase("typeDocumentOwner", t("TypeDocumentOwner"))}
          {renderViewNationalBase("nDocumentOwner", t("NDocumentOwner"))}
          {renderViewNationalBase(
            "typeBillingDocument",
            t("TypeBillingDocument")
          )}
          {renderViewNationalBase("nBillingDocument", t("NBillingDocument"))}
          {renderViewNationalBase("ufBilling", t("UfBilling"))}
          {renderViewNationalBase("lastUpdateDate", t("LastUpdateDate"))}
          {renderViewNationalBase("restriction01", t("Restriction01"))}
          {renderViewNationalBase("restriction02", t("Restriction02"))}
          {renderViewNationalBase("restriction03", t("Restriction03"))}
          {renderViewNationalBase("restriction04", t("Restriction04"))}
          {renderViewNationalBase("transaction", t("Transaction"))}
          {renderViewNationalBase("msgRet", t("MsgRet"))}
          {renderViewNationalBase("plate2", t("Plate2"))}
          {renderViewNationalBase("indRestriction", t("IndRestriction"))}
          {renderViewNationalBase(
            "deadlineTaxRestriction",
            t("DeadlineTaxRestriction")
          )}
          {renderViewNationalBase(
            "typeDocumentBilling2",
            t("TypeDocumentBilling2")
          )}
          {renderViewNDocument()}
          {renderViewNationalBase(
            "organizationCodeSRF",
            t("OrganizationCodeSRF")
          )}
          {renderViewNationalBase("nreda", t("Nreda"))}
          {renderViewNationalBase("ndi", t("Ndi"))}
          {renderViewNationalBase("registerDate", t("RegisterDate"))}
          {renderViewNationalBase("nProcess", t("NProcess"))}
          {renderViewNationalBase("cmt", t("Cmt"))}
          {renderViewNationalBase("pbt", t("Pbt"))}
          {renderViewNationalBase("loadCapacity", t("LoadCapacity"))}
          {renderViewNationalBase("axis", t("Axis"))}
        </Form.Row>
      </Wrapper>
    </>
  );
};
