import { TypesHelper } from "c4u-web-components";

export interface ILenderOperatorRequest {
  id: number;
  cpf: string;
  name: string;
  email: string;
  lenderId?: number;
  collectionOfficeCnpj: string;
  collectionOfficeName: string;
  isActive: string;
}

export class LenderOperatorRequest {
  id: number | null;
  cpf: string | null;
  name: string | null;
  email: string | null;
  lenderId: number | null;
  collectionOfficeCnpj: string | null;
  collectionOfficeName: string | null;
  isActive: boolean | null;

  constructor({
    id,
    cpf,
    name,
    email,
    lenderId,
    collectionOfficeCnpj,
    collectionOfficeName,
    isActive,
  }: ILenderOperatorRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.lenderId = TypesHelper.toNumberNull(lenderId);
    this.collectionOfficeCnpj = TypesHelper.toStringOnlyNumbersNull(collectionOfficeCnpj);
    this.collectionOfficeName = TypesHelper.toStringNull(collectionOfficeName);
    this.isActive = TypesHelper.toBooleanNull(isActive);
  }
}
