import styled from "styled-components";

const CardBase = styled.div`
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  height: 100%;

  display: flex;
  align-items: center;
`;

export const ObservationTitle = styled(CardBase)`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  padding: 16px 12px;

  gap: 12px;
  text-align: center;

  color: #1f3e74;
`;

export const IconDiv = styled.div`
  width: 31px;
  margin: 0;
`;

export const ObservationDescription = styled(CardBase)`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding: 16px 24px;

  color: #4a4a4a;
`;
