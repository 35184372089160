import {
  ButtonPrimary,
  CoxIcon,
  FormikControlAtom,
  SpinnerCenteredAtom,
  TypesHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../../constants";
import { formikHandleErrorHelper } from "../../../../../helper";
import { useManhein, useSessionContext } from "../../../../../hooks";
import {
  IFormEmailTemplate,
  PostEmailTemplateRequest,
} from "../../../../../models";
import { TitleWithBackButtonAtom, ToggleInputAtom } from "../../../../atoms";
import {
  FeedbackModal,
  SendEmailTemplateMolecule,
} from "../../../../molecules";
import {
  EmailTemplatePageSection,
  EmailTemplateDetailsWrapper,
  StyledLabelCol,
  VisibilityIconDiv,
} from "./email-template-details.organism.style";
import { EmailTemplateDetailsValidation } from "./email-template-details.organism.validation";

export const EmailTemplateDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id: templateId } = useParams<{ id: string }>();
  const history = useHistory();

  const { emailTemplatesContext } = useSessionContext();
  const { saveEmailTemplate, getTemplateBodyVariables } = useManhein();

  const [showModalHtml, setShowModalHtml] = useState(false);
  const [templateName, setTemplateName] = useState<string>();
  const [variables, setVariables] = useState<string[]>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>();

  const formik = useFormik<IFormEmailTemplate>({
    initialValues: {
      timeInHours: 0,
      activeEmailToggle: "",
      activeWhatsappToggle: "",
      title: "",
      body: "",
      bodyWhatsapp: "",
    } as IFormEmailTemplate,
    onSubmit: async (values, { setErrors }) => {
      try {
        if (window.confirm(t("ConfirmAction"))) {
          await saveEmailTemplate(new PostEmailTemplateRequest(values));
          setShowConfirmationModal(true);
        }
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else if (e?.message) {
          alert(e.message);
        } else {
          alert(JSON.stringify(e));
        }
      }
    },
    validationSchema: EmailTemplateDetailsValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (!variables) {
      getTemplateBodyVariables()
        .then((data) => setVariables(data))
        .catch(() => setVariables([t("Error fetching template variables")]));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const templateValues = emailTemplatesContext?.find(
      (f) => f.template === Number(templateId)
    );
    if (templateValues === undefined)
      history.push(paths.backofficeListEmailTemplatesPage());
    else {
      formik.setValues({
        ...templateValues,
        activeWhatsappToggle: templateValues.activeWhatsapp ? "1" : "",
        activeEmailToggle: templateValues.activeEmail ? "1" : "",
      });
      setTemplateName(templateValues.nameMessage);
    }
  }, [emailTemplatesContext, templateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formik.values.activeWhatsappToggle !== undefined) {
      formik.setFieldValue(
        "activeWhatsapp",
        !!TypesHelper.toBooleanNull(
          formik.values.activeWhatsappToggle.length.toString()
        )
      );
    }
  }, [formik.values.activeWhatsappToggle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formik.values.activeEmailToggle !== undefined) {
      formik.setFieldValue(
        "activeEmail",
        !!TypesHelper.toBooleanNull(
          formik.values.activeEmailToggle.length.toString()
        )
      );
    }
  }, [formik.values.activeEmailToggle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EmailTemplateDetailsWrapper>
      <TitleWithBackButtonAtom
        title={t("{templateName}DetailsPage", { templateName })}
        onClick={() => history.push(paths.backofficeListEmailTemplatesPage())}
      />
      <hr />
      <Row>
        <Col md={9}>
          <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
            <Form.Row>
              <StyledLabelCol xs={3}>
                {t("ReferenceDateName") + ":"}
              </StyledLabelCol>
              <FormikControlAtom
                formik={formik}
                property={"timeInHours"}
                translate={t}
                xs={1}
              />
              <StyledLabelCol>
                {formik.values.referenceDateName ??
                  t("Warning reference date not configured")}
              </StyledLabelCol>
            </Form.Row>
            <Form.Row>
              <StyledLabelCol xs={3}>
                {t("AvailableVariables") + ":"}
              </StyledLabelCol>
              <Col>
                {variables ? (
                  variables.join(" ")
                ) : (
                  <div className="d-flex h-100 align-items-center">
                    <SpinnerCenteredAtom size="sm" classNames="mr-2" />
                    {t("Loading") + "..."}
                  </div>
                )}
              </Col>
            </Form.Row>
            <hr />
            <Form.Row>
              <Col md>
                <EmailTemplatePageSection className="active-toggle">
                  <ToggleInputAtom
                    id={"activeEmailToggle"}
                    form={formik}
                    checked={formik.values.activeEmail}
                  />
                  <div className="section-title">{t("EmailTemplate")}</div>
                  <VisibilityIconDiv
                    onClick={
                      !formik.values.body
                        ? undefined
                        : () => setShowModalHtml(true)
                    }
                    className="ml-3"
                  >
                    <CoxIcon
                      name="visibility"
                      isEnabled={!!formik.values.body}
                    />
                  </VisibilityIconDiv>
                </EmailTemplatePageSection>
                {/* <hr /> */}
                <Form.Row>
                  <FormikControlAtom
                    formik={formik}
                    property={"title"}
                    translate={t}
                    label={t("Subject")}
                  />
                </Form.Row>
                <Form.Row>
                  <FormikControlAtom
                    as="textarea"
                    formik={formik}
                    property="body"
                    translate={t}
                    label={t("EmailBodyHtml")}
                    className="body-email"
                  />
                </Form.Row>
              </Col>

              <Col md>
                <EmailTemplatePageSection className="active-toggle">
                  <ToggleInputAtom
                    id={"activeWhatsappToggle"}
                    form={formik}
                    checked={formik.values.activeWhatsapp}
                  />
                  <div className="section-title">{t("WhatsappTemplate")}</div>
                </EmailTemplatePageSection>
                {/* <hr /> */}
                <Form.Row>
                  <FormikControlAtom
                    as="textarea"
                    formik={formik}
                    property="bodyWhatsapp"
                    translate={t}
                    label={t("WhatsappMsg")}
                    className="body-whatsapp"
                  />
                </Form.Row>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md className="text-right">
                <ButtonPrimary
                  type={"submit"}
                  sizex={"md"}
                  loading={formik.isSubmitting}
                >
                  {t("Save")}
                </ButtonPrimary>
              </Col>
            </Form.Row>
          </Form>
        </Col>

        <Col md={3}>
          <EmailTemplatePageSection>
            <h4>{t("TestModel")}</h4>
          </EmailTemplatePageSection>
          <hr />
          <SendEmailTemplateMolecule template={formik.values.template} />
        </Col>
      </Row>

      <Modal
        show={showModalHtml}
        centered
        onHide={() => setShowModalHtml(false)}
        size={"lg"}
      >
        <div dangerouslySetInnerHTML={{ __html: formik.values.body ?? "" }} />
      </Modal>

      <FeedbackModal
        type="success"
        show={!!showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        content={t("TemplateSavedSuccessfully")}
      />
    </EmailTemplateDetailsWrapper>
  );
};
