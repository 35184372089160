import { ButtonPrimary } from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  IInspectionReport,
  PostInspectionReportRequest,
} from "../../../../../models";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";

interface IProps {
  enabled: boolean;
  unicCode: string;
  setException: React.Dispatch<any>;
}

export const AdInspectionMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postInspectionReport } = useManhein();

  const validations = Yup.object<IInspectionReport>({
    uniqueCode: Yup.string().required(),
    inspectionStatus: Yup.string().required(),
  });

  const [fileInspectionReportComplete, setFileInspectionReportComplete] =
    useState<string | Blob>();
  const [fileInspectionReportCustom, setFileInspectionReportCustom] = useState<
    string | Blob
  >();

  const selectFile = (event: any, setFileReport: (v: any) => void) => {
    const files = event.target.files;
    setFileReport(files[0]);
  };

  const formik = useFormik<IInspectionReport>({
    initialValues: { uniqueCode: props.unicCode } as IInspectionReport,
    onSubmit: async (values, { setErrors }) => {
      if (
        fileInspectionReportComplete === undefined ||
        fileInspectionReportCustom === undefined
      ) {
        window.alert(t("TheFilesAreMandatory"));
      } else {
        if (window.confirm(t("ConfirmAction"))) {
          try {
            await postInspectionReport(
              new PostInspectionReportRequest({
                ...values,
                inspectionReportComplete: fileInspectionReportComplete,
                inspectionReportCustom: fileInspectionReportCustom,
              } as IInspectionReport)
            );
            window.location.reload();
          } catch (e: any) {
            if (e?.errors) {
              const errorFormik = formikHandleErrorHelper(e?.errors);
              setErrors(errorFormik);
            } else if (e?.message) {
              props.setException(
                `${t("Try again error message")} ${
                  e?.message
                }`
              );
            } else {
              props.setException(t("Try again error message"));
            }
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const renderCheck = (key: string, label: string) => {
    const valueFormated = formik.values
      ? (formik.values as any)[key]
      : undefined;
    const error = formik.errors ? (formik.errors as any)[key] : undefined;
    return (
      <Form.Group
        as={Col}
        md={4}
        controlId={key}
        style={{ display: "flex", gap: "3px" }}
      >
        <Form.Check
          value={valueFormated}
          disabled={!props.enabled}
          onChange={formik.handleChange}
          isInvalid={!!error}
          feedback={<>{t("field_required")}</>}
          feedbackTooltip={true}
        />
        <Form.Label>{label}</Form.Label>
      </Form.Group>
    );
  };

  const renderCombo = (key: string, label: string) => {
    const valueFormated = formik.values
      ? (formik.values as any)[key]
      : undefined;
    const error = formik.errors ? (formik.errors as any)[key] : undefined;
    return (
      <Form.Group as={Col} md={4} controlId={key}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={valueFormated}
          disabled={!props.enabled}
          onChange={formik.handleChange}
          isInvalid={!!error}
          as="select"
        >
          <option>{t("Select")}...</option>
          <option value={"Aprovado"}>{t("Approved")}</option>
          <option value={"Aprovado com apontamentos"}>
            {t("ApprovedAppointments")}
          </option>
          <option value={"Não conforme"}>{t("Not in accordance")}</option>
          <option value={"Estornado"}>{t("Refunded")}</option>
        </Form.Control>
      </Form.Group>
    );
  };

  const renderUpload = (
    key: string,
    label: string,
    setFileReport: (v: any) => void
  ) => {
    return (
      <Form.Group as={Col} md={4} controlId={key}>
        <Form.Label>{label}</Form.Label>
        <Form.File
          disabled={!props.enabled}
          onChange={(ev: any) => selectFile(ev, setFileReport)}
          feedback={<>{t("field_required")}</>}
          feedbackTooltip={true}
          title={t("UploadInspectionReportHelpMsg")}
        />
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        {renderCheck("refunded", t("Refunded"))}
        {renderCheck("sellerOwner", t("SellerOwnsVehicle"))}
        {renderCheck("financingFreeVehicle", t("VehicleIsFinancingFree"))}
        {renderCheck(
          "vehicleFreeLegalRestrictions",
          t("VehicleIsJudicialRestrictionsFree")
        )}
        {renderCheck("freeVehicleAuction", t("VehicleIsAuctionFree"))}
        {renderCombo("inspectionStatus", t("Inspection status"))}
      </Form.Row>
      <Form.Row>
        {renderUpload(
          "inspectionReportComplete",
          t("Inspection report"),
          setFileInspectionReportComplete
        )}
        {renderUpload(
          "inspectionReportCustom",
          t("Inspect decouped"),
          setFileInspectionReportCustom
        )}
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Send report")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
