import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StatusHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import { AdStepEnum, IAdVehicleStatus } from "../../../../../models";
import { ButtonArrow } from "../../../../atoms";
import { TableListGenericListMolecule } from "../../../../molecules";
import {
  ContentWrapper,
  MainWrapper,
  Wrapper,
  Collapse,
} from "./ad-vehicle-status-table.organism.style";

export const AdVehicleStatusTableOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getVehicleStatus } = useManhein();

  const [vehicleStatus, setVehicleStatus] = useState<IAdVehicleStatus[]>();
  const [isOpen, setIsOpen] = useState(false);

  const getVehicleStatusAsync = async () => {
    try {
      const values = await getVehicleStatus();
      if (values) setVehicleStatus(values);
      else setVehicleStatus([]);
    } catch (error: any) {
      setVehicleStatus([]);
    }
  };

  useEffect(() => {
    getVehicleStatusAsync();
    // eslint-disable-next-line
  }, []);

  const tableComponent = useMemo(() => {
    return Object.keys(AdStepEnum)
      .filter((f) => !isNaN(Number(f)))
      .map((step, i) => {
        const numberStep = Number(step);
        const stepStatusEnum = StatusHelper.getStepStatusEnum(numberStep);

        const data = vehicleStatus
          ?.filter((f) => f.step === numberStep)
          .sort((s1, s2) => s1.stepStatus - s2.stepStatus)
          .map((m) => [t(stepStatusEnum[m.stepStatus]), m.total]);

        return data?.length ? (
          <Col key={`ad-status-${numberStep}`} sm={6} lg={4}>
            <TableListGenericListMolecule
              tableName={`ad-status-${numberStep}-summary`}
              tbody={data}
              thead={[t(AdStepEnum[numberStep]), t("Total")]}
            />
          </Col>
        ) : undefined;
      });
  }, [t, vehicleStatus]);

  return (
    <>
      <div className="mb-4">
        <Wrapper>
          <MainWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            <h3>{t("ResumeStatus")}</h3>
            <ButtonArrow collapse isOpen={isOpen} />
          </MainWrapper>

          <ContentWrapper isOpen={isOpen}>
            <Collapse in={isOpen}>
              {vehicleStatus === undefined ? (
                <SpinnerCenteredAtom />
              ) : vehicleStatus.length ? (
                <Row>{tableComponent}</Row>
              ) : (
                <i>{t("No information")}</i>
              )}
            </Collapse>
          </ContentWrapper>
        </Wrapper>
      </div>
    </>
  );
};
