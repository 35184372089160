import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  MyEarningsMoleculeWrapper,
  MyEarningsSectionTitleDiv,
  MyEarningsWhiteBackgroundCard,
} from "../my-earnings-shared.style";
import {
  ComingSoonDiv,
  ContentDiv,
  TrackerItemDescription,
  TrackerItemValue,
} from "./my-earnings-tracker-plans.molecule.style";

export interface IIturanPlanData {
  plan: string;
  sale: number;
  renewal?: number;
}

interface IProps {
  planData: IIturanPlanData[];
  titleBackgroundColor?: string;
  subTitleBackgroundColor?: string;
  titleColor?: string;
  titleText?: boolean;
  commingSoon?: boolean;
}

export const MyEarningsTrackerPlansMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <MyEarningsMoleculeWrapper>
      {props.titleText && (
        <Row>
          <Col>
            <MyEarningsSectionTitleDiv
              className="h-100"
              background={props.titleBackgroundColor}
              color={props.titleColor}
            >
              {props.titleText}
              {!!props.commingSoon && (
                <ComingSoonDiv>{t("ComingSoon")}</ComingSoonDiv>
              )}
            </MyEarningsSectionTitleDiv>
          </Col>
        </Row>
      )}

      <ContentDiv>
        <Row>
          <Col xs={6} sm={4} lg={5}>
            <MyEarningsSectionTitleDiv
              className="h-100 py-2"
              background={
                props.subTitleBackgroundColor ?? props.titleBackgroundColor
              }
              color={props.titleColor}
            >
              {t("Plan")}
            </MyEarningsSectionTitleDiv>
          </Col>
          <Col xs={3} sm={4} lg={3}>
            <MyEarningsSectionTitleDiv
              className="h-100 py-2"
              background={
                props.subTitleBackgroundColor ?? props.titleBackgroundColor
              }
              color={props.titleColor}
            >
              {t("Sale")}
            </MyEarningsSectionTitleDiv>
          </Col>
          <Col xs={3} sm={4} lg={4}>
            <MyEarningsSectionTitleDiv
              className="h-100 py-2"
              background={
                props.subTitleBackgroundColor ?? props.titleBackgroundColor
              }
              color={props.titleColor}
            >
              {t("Renewal")}
            </MyEarningsSectionTitleDiv>
          </Col>
        </Row>

        {props.planData.map((m, i) => (
          <Row
            key={`${props.titleText ?? ""}-plan-data-${i}`}
            className="h-100"
          >
            <Col xs={6} sm={4} lg={5}>
              <MyEarningsWhiteBackgroundCard className="px-3 py-2">
                <TrackerItemDescription>{m.plan}</TrackerItemDescription>
              </MyEarningsWhiteBackgroundCard>
            </Col>
            <Col xs={3} sm={4} lg={3}>
              <MyEarningsWhiteBackgroundCard className="py-2 h-100">
                <TrackerItemValue className="h-100">
                  {NumberHelper.toFormatCurrency(m.sale)}
                </TrackerItemValue>
              </MyEarningsWhiteBackgroundCard>
            </Col>
            <Col xs={3} sm={4} lg={4}>
              <MyEarningsWhiteBackgroundCard className="py-2 h-100">
                <TrackerItemValue className="h-100">
                  {m.renewal ? NumberHelper.toFormatCurrency(m.renewal) : "-"}
                </TrackerItemValue>
              </MyEarningsWhiteBackgroundCard>
            </Col>
          </Row>
        ))}
      </ContentDiv>
    </MyEarningsMoleculeWrapper>
  );
};
