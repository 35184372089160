// import { FormikControlAtom } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EnumHelper, FormikControlAtom } from "c4u-web-components";
import { EncodeLenderCodeEnum, StepDtEnum } from "../../../../models";

interface IProps {
  formik: any;
}

export const DtMockSyncCreditAppMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { formik } = props;
  const defaultProps = {
    translate: t,
    formik,
    sm: 4,
  };

  return (
    <>
      <Form.Row>
        <FormikControlAtom
          {...defaultProps}
          sm={8}
          label={t("PublicPersonId")}
          property={"creditApp.publicPersonId"}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          {...defaultProps}
          type="select"
          data={EnumHelper.getComboFromEnum(
            EncodeLenderCodeEnum,
            t,
            (k, v) => `${k} (${v})`
          )}
          label={t("LenderCode")}
          property={"creditApp.encodeLenderCode"}
        />
        <FormikControlAtom
          {...defaultProps}
          type="select"
          data={EnumHelper.getComboFromEnum(StepDtEnum, t)}
          label={t("StepDt")}
          property={"creditApp.step"}
        />
        <FormikControlAtom
          {...defaultProps}
          type="date"
          label={t("ExpirationDate")}
          property={"creditApp.expiryDate"}
        />
      </Form.Row>
    </>
  );
};
