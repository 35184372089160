import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IOnboardingCompletion } from "../../../../hooks/apis/use-c4u/interfaces/interfaces";

export const UserAccountDetailsValidationSchema = () => {
  const { t } = useTranslation();

  const validations = Yup.object<IOnboardingCompletion>({
    general: Yup.string().test("general", t("at_least_1_claim"), function (value) {
      return !(this.resolve(Yup.ref("hasBackofficeAdsAccess")) !== true &&
        this.resolve(Yup.ref("hasBackofficeRegistrationAccess")) !== true &&
        this.resolve(Yup.ref("hasDealertrackAccess")) !== true &&
        this.resolve(Yup.ref("hasKbbAccess")) !== true &&
        this.resolve(Yup.ref("hasManheimAccess")) !== true &&
        this.resolve(Yup.ref("hasProductsB3Access")) !== true &&
        this.resolve(Yup.ref("hasProductsDebtsAccess")) !== true &&
        this.resolve(Yup.ref("hasProductsInspectionAccess")) !== true &&
        this.resolve(Yup.ref("hasProductsInsuranceAccess")) !== true)
    })
  });

  return validations;
};
