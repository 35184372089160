import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";
import { ISplit, SplitRequest } from "../../../../../models";
import { FormikControlAtom } from "../../../../atoms";

interface IProps {
  enabled: boolean;
  data: ISplit;
  setException: React.Dispatch<any>;
}
export const AdSplitMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { splitValue } = useManhein();

  const validations = Yup.object<ISplit>({
    adId: Yup.number().required(),
    finalTrafficPenaltyValue: Yup.number().required(),
    otherPenaltyValue: Yup.number().required(),
    reasonSplitValue: Yup.string().required(),
  });

  const formik = useFormik<ISplit>({
    initialValues: props.data,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await splitValue(new SplitRequest(values));

          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <Form.Group as={Col} md={4}>
          <FormikControlAtom
            formik={formik}
            property={"finalTrafficPenaltyValue"}
            type={"money"}
            decimalScale={2}
            disabled={!props.enabled}
          />
          <FormikControlAtom
            formik={formik}
            property={"otherPenaltyValue"}
            type={"money"}
            decimalScale={2}
            disabled={!props.enabled}
          />
        </Form.Group>
        <FormikControlAtom
          formik={formik}
          as={"textarea"}
          property={"reasonSplitValue"}
          disabled={!props.enabled}
        />
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Do split")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
