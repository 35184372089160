import { DateHelper } from "c4u-web-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";
import { IGetDataLoadResidualValuesHistoryResponse } from "../../../../../models";
import { DataLoadResidualValuesTableOrganism } from "./data-load-residual-values-list.organism";
import { LoadFileResidualValuesFormOrganism } from "./data-load-residual-values-form.organism";

export const DataLoadResidualValuesOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getDataLoadResidualValuesHistory } = useManhein();
  const [reloadList, setReloadList] = useState(false);

  return (
    <>
      <LoadFileResidualValuesFormOrganism setReloadList={setReloadList} />

      <h3 className="mt-3">{t("LoadHistory")}</h3>
      <hr />

      <DataLoadResidualValuesTableOrganism
        getDataCallback={getDataLoadResidualValuesHistory}
        tableName="borrowers-data-load-history"
        reloadList={reloadList}
        setFinishReload={setReloadList}
        tableHeaders={[t("Id"), t("Filename"), t("CreatedAt"), t("isSuccess")]}
        mappingFunction={(m: IGetDataLoadResidualValuesHistoryResponse, i) => [
          m.id,
          m.fileName,
          DateHelper.dateHourFormat(m.createdAt),
          m.isSuccess ? t("Yes") : t("No"),
        ]}
      />
    </>
  );
};
