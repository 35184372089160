import React from "react";
import { Table } from "react-bootstrap";
interface IProps {
  tbody?: any[][];
  thead: string[];
  tableName: string;
}

export const TableListGenericListMolecule: React.FC<IProps> = (props) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            {props.thead.map((m, i) => (
              <th key={`${props.tableName}-th${i}`}>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.tbody?.map((m, i) => (
            <tr key={`${props.tableName}-tr${i}`}>
              {m.map((n, j) => (
                <td key={`row-${i}-${j}`}>{n}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
