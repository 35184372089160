import { CoxIcon, Tooltip } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { useKbbBackoffice } from "../../../../../hooks";
import { ServiceRobotsEnum } from "../../../../../models";
import { IGetRobotsReportResponse } from "../../../../../models/apis/kbb/api-robots-report.request";
import { RobotStatus } from "./robots-report.organism.style";
import { RobotsTableSearchOrganism } from "./robots-table-search.organism";

export const RobotsReportOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getRobotLogs } = useKbbBackoffice();

  return (
    <RobotsTableSearchOrganism
      getDataCallback={getRobotLogs}
      serviceRobotsEnum={ServiceRobotsEnum}
      tableName={"api-robots"}
      tableHeaders={[
        t("ServiceUsed"),
        t("ExecutionDate"),
        t("ExecutionFileName"),
        t("StatusRobot"),
      ]}
      mappingFunction={(m: IGetRobotsReportResponse, i) => [
        t(ServiceRobotsEnum[m.serviceUpdated]),
        DateHelper.dateHourFormat(m.executionDate),
        m.fileName,
        !m.error ? (
          <RobotStatus>{t("success")}</RobotStatus>
        ) : (
          <RobotStatus>
            {t("error")}
            <Tooltip content={m.message} width="110px" placement="bottom">
              <CoxIcon
                name={"info"}
                width={14}
                height={14}
                fillColor="#F4B600"
              />
            </Tooltip>
          </RobotStatus>
        ),
      ]}
    />
  );
};
