import { Col } from "react-bootstrap";
import styled from "styled-components";

export const DownloadButtonStyledCol = styled(Col)`
  border: 1px solid;
  border-radius: 20px;

  height: 40px;

  :hover {
    background-color: ${(props) => props.theme.colors.DarkGrayOpacity};
  }
`;
