import * as Yup from "yup";
import { IGetLogReportParams } from "../../../../models";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";

export const LogReportSearchFormValidation = () => {
  const { t } = useTranslation();
  const validations = Yup.object<IGetLogReportParams>({
    search: Yup.string().notRequired(),
    origin: Yup.number().notRequired(),
    startDate: Yup.date().required(),
    endDate: Yup.date()
      .required()
      .test("date-start-small-date-final", t("ValidationDate"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays >= 0;
      })
      .test("period-valid", t("ValidationIntervalDates"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays <= 90;
      }),
    pageSize: Yup.number().required(),
    page: Yup.number().notRequired(),
    orderBy: Yup.string().notRequired(),
  });

  return validations;
};
