import { ButtonTertiary } from "c4u-web-components";
import { StdEnum } from "c4u-web-components/src/components/models";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { paths } from "../../../../../../constants";
import { formikHandleErrorHelper } from "../../../../../../helper";
import { useSessionContext } from "../../../../../../hooks";
import {
  GetApiUserRequest,
  IGetApiUserRequestParams,
  IGetApiUserResponse,
} from "../../../../../../models";
import {
  ApiUserListSearchForm,
  PaginatableTableList,
} from "../../../../../molecules";

interface IProps {
  getDataCallback: (values: any) => any;
  UserActive: StdEnum;
  tableName: string;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
}

export const ApiUsersListTableSearchOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { setEmailTemplatesContext } = useSessionContext();

  const validations = Yup.object<IGetApiUserRequestParams>({
    pageSize: Yup.number().required(),
    page: Yup.number().required(),
    orderBy: Yup.string(),
  });

  const [listData, setListData] = useState<IGetApiUserResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const initialValues: IGetApiUserRequestParams = {
    pageSize: 50,
    page: 1,
    search: "",
    active: "",
  };

  const formik = useFormik<IGetApiUserRequestParams>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new GetApiUserRequest(values)
        );
        setListData(data);
        setEmailTemplatesContext(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => {
    formik.setValues({
      ...initialValues,
    });
    formik.submitForm();
  };

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new GetApiUserRequest({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, [formik.values.pageSize]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ApiUserListSearchForm
        formik={formik}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
        UserActive={props.UserActive}
      />

      <div className="w-100 m-2 d-flex justify-content-center">
        <Link to={paths.backofficeApiUserDetailsPage(0)}>
          <ButtonTertiary sizex="lg">{t("NewApiUser")}</ButtonTertiary>
        </Link>
      </div>

      <PaginatableTableList
        tableName={props.tableName}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
