import {
  ButtonSecondary,
  CoxIcon,
  TypeMethodSendingAtpveEnum,
  TypeSignatureEnum,
} from "c4u-web-components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ButtonWrapperFilePreviewPdf,
  DivPdf,
  DivUploadPdf,
  FileInputUploadPdf,
  FilePreviewPdf,
  ImgUploadPdf,
  ParagrafoUploadPdf,
  WrapperUploadPdf,
} from "./upload-document-pdf.atom.style";
import { useKbbBackoffice, useSessionContext } from "../../../hooks";
import { IconPdfUpload } from "../../assets";
import { IDocumentModel } from "../../../models";

interface IProps {
  title: string;
  index: number;
  isSubmitting?: boolean;
  setValidDocument: (v: boolean) => void;
  typeSignature?: TypeSignatureEnum;
  disabled?: boolean;
}

export const UploadDocumentPdfAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { updateDecoderReview } = useKbbBackoffice();
  const { setDocumentContext, documentContext } = useSessionContext();

  const {
    title,
    index,
    setValidDocument,
    isSubmitting,
    disabled: disabledProps,
  } = props;

  const fileInput = useRef(document.createElement("input"));

  const [selectedFile, setSelectedFiles] = useState<any>();
  const [currentFile, setCurrentFile] = useState<string>();
  const [fileName, setFileName] = useState("");

  const validDocument = (img: any) => {
    const allowedSize = 1000000;

    return img && allowedSize >= img.size;
  };

  const selectFile = (event: any) => {
    const files = event.target.files;
    if (validDocument(files[0])) {
      setSelectedFiles(files[0]);
    }
  };

  useEffect(() => {
    const upload = () => {
      const file = selectedFile;

      if (!file) return;

      setFileName(file.name.replace(".", " "));

      setCurrentFile(URL.createObjectURL(file));

      const valuesDocumentContext = {
        typeMethodSending: TypeMethodSendingAtpveEnum.UniqueFile,
        file: [],
      } as IDocumentModel;

      valuesDocumentContext.file.push({
        file: file,
        title: title,
        index: index,
      });

      setCurrentFile(URL.createObjectURL(file));
      setDocumentContext(valuesDocumentContext);
      setValidDocument(false);
      setSelectedFiles(null);
    };

    if (selectedFile) upload();
  }, [selectedFile, updateDecoderReview]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentContext) {
      const item = documentContext.file.find((x) => x.index === index);

      if (item) {
        setCurrentFile(URL.createObjectURL(item.file));
        setFileName(item.title);
      }
    }
  }, [documentContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperUploadPdf>
      {currentFile ? (
        <>
          <DivUploadPdf>
            <FilePreviewPdf>
              <FileInputUploadPdf
                accept="application/pdf"
                onChange={(ev) => selectFile(ev)}
                ref={fileInput}
                type="file"
                disabled={isSubmitting}
              />
            </FilePreviewPdf>

            <ButtonWrapperFilePreviewPdf>
              <CoxIcon name="checked" width={30} height={30} />
              <ParagrafoUploadPdf>{fileName}</ParagrafoUploadPdf>
              {!disabledProps && (
                <ButtonSecondary
                  sizex={"md"}
                  sizey={"thin"}
                  disabled={isSubmitting}
                  onClick={() => fileInput.current.click()}
                >
                  {t("ChangeDocument")}
                </ButtonSecondary>
              )}
            </ButtonWrapperFilePreviewPdf>
          </DivUploadPdf>
        </>
      ) : (
        <>
          <DivPdf>
            <FileInputUploadPdf
              accept="application/pdf"
              onChange={(ev) => selectFile(ev)}
              ref={fileInput}
              type="file"
              disabled={isSubmitting}
              id="input-pdf"
            />
            <ImgUploadPdf src={IconPdfUpload} alt="" />

            <ParagrafoUploadPdf> {t("AddPdfDocument")} </ParagrafoUploadPdf>
          </DivPdf>
        </>
      )}
    </WrapperUploadPdf>
  );
};
