import { useRequest, ManheinHttp, manheimClientConfig } from "../config";
import {
  AdModel,
  IAdInspectionModel,
  AdBidding,
  SplitRequest,
  IPagedCustomerReport,
  AdInspectionScheduleLogRequest,
  IAdInspectionScheduleLogModel,
  ICarDeliveryBuyerModel,
  ICarDeliverySellerModel,
  PostAdStepRequest,
  ISendEmailModel,
  GetAllAdsParams,
  IGetAllAdsPagedResponseModel,
  UpdateAdInspectionRequest,
  EditAdOfferRequest,
  PostInspectionReportRequest,
  IInspectionModel,
  PostDeliveryPersonRequest,
  PostDeliveryPersonPhotoRequest,
  UpdateDutValuesRequest,
  UpdateCustomerRequest,
  GetListEmailTemplateParams,
  IGetListEmailTemplatePagedResponseModel,
  IGetEmailTemplateViewModel,
  PostEmailTemplateRequest,
  IAdVehicleStatus,
  IAdEventHistory,
  PagedRequestParamsBaseModel,
  LenderOperatorRequest,
  IGetLendersResponse,
  GetLenderOperatorRequest,
  IGetLenderOperatorResponsePagedResponseModel,
  IGetLenderOperatorResponse,
  IGetFidelizaLinkAdsResponsePagedResponseModel,
  IGetDataLoadBorrowersHistoryPagedResponseModel,
  IGetLenderUserResponsePagedResponseModel,
  LenderUserRequest,
  IGetLenderUserResponse,
  GetLenderUserRequest,
  CustomerChangePasswordRequest,
  IGetDataLoadResidualValuesHistoryPagedResponseModel,
  IGetReportPicturesResponse,
  PostSelectedRepostPicturesRequest,
  PostRemoveSelectedRepostPicturesRequest,
  DataLoadBorrowersRequest,
} from "../../../models";
import { Combo, HttpHelper } from "c4u-web-components";

export const useManhein = () => {
  const { get, post, put, getCurrentToken } = useRequest(
    ManheinHttp,
    "Backoffice",
    manheimClientConfig
  );

  const getAdInspectionByAdId = async (
    id: number
  ): Promise<IAdInspectionModel> => {
    const { data } = await get(`GetInspectionByAdId/${id}`);
    return data;
  };

  const getAd = async (id: number): Promise<AdModel> => {
    const { data } = await get(`GetAdById/${id}`);
    return data;
  };

  const getAllAds = async (
    params: GetAllAdsParams
  ): Promise<IGetAllAdsPagedResponseModel> => {
    const { data } = await get(
      `GetAllAdsLastest${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const startBidding = async (request: AdBidding): Promise<AdModel[]> => {
    const { data } = await post(`startBidding`, request);
    return data;
  };

  const createFileTemporaryLink = async (
    adInspectionId: number
  ): Promise<string> => {
    const { data } = await post(`createFileTemporaryLink`, {
      adInspectionId: adInspectionId,
    });
    return data?.link ?? "";
  };

  const cancelAd = async (adId: number, status: number): Promise<void> => {
    await post(`cancelAd`, {
      adId: adId,
      canceledStatus: status,
    });
  };

  const splitValue = async (request: SplitRequest): Promise<void> => {
    await post(`splitValue`, request);
  };

  const getToken = async (): Promise<string> => {
    return await getCurrentToken();
  };

  const getCustomerReport = async (
    param: PagedRequestParamsBaseModel
  ): Promise<IPagedCustomerReport> => {
    const { data } = await get(
      `getCustomerReport?PageSize=${param.pageSize}&Page=${param.page}`
    );
    return data;
  };

  const getDownloadLink = async (fileName: string): Promise<string> => {
    const { data } = await post(`downloadLink/${fileName}`, {});
    return data?.link ?? "";
  };

  const getInspectionScheduleLog = async (
    adId: number
  ): Promise<IAdInspectionScheduleLogModel[]> => {
    const { data } = await get(`getInspectionsSchedulesByAdId/${adId}`);
    return data;
  };

  const saveInspectionSchedule = async (
    request: AdInspectionScheduleLogRequest
  ): Promise<IAdInspectionScheduleLogModel> => {
    const { data } = await post(`saveInspectionSchedule`, request);
    return data;
  };

  const getDeliveryBuyer = async (
    id: number
  ): Promise<ICarDeliveryBuyerModel> => {
    const { data } = await get(`GetDeliveryBuyer/${id}`);
    return data;
  };

  const getDeliverySeller = async (
    id: number
  ): Promise<ICarDeliverySellerModel> => {
    const { data } = await get(`GetDeliverySeller/${id}`);
    return data;
  };

  const stampAsDamaged = async (adId: number): Promise<void> => {
    await post(`stampAsDamaged`, { adId });
  };

  const stampAsFleet = async (adId: number): Promise<void> => {
    await post(`stampAsFleet`, { adId });
  };

  const clearStamps = async (adId: number): Promise<void> => {
    await post(`clearStamps`, { adId });
  };

  const postAdStep = async (request: PostAdStepRequest): Promise<void> => {
    await post(`postAdStep`, request);
  };

  const getEmailTemplate = async (): Promise<IGetEmailTemplateViewModel[]> => {
    const { data } = await get(`getEmailTemplate`);
    return data;
  };

  const saveEmailTemplate = async (
    request: PostEmailTemplateRequest
  ): Promise<void> => {
    await post(`postEmailTemplate`, request);
  };

  const sendEmail = async (request: ISendEmailModel): Promise<void> => {
    await post(`postSendEmail`, request);
  };

  const getAllEmailTemplate = async (): Promise<Combo[]> => {
    const { data } = await get(`getAllEmailTemplate`);
    return Object.keys(data).map(
      (m) => new Combo({ value: m, title: data[m] })
    );
  };

  const rejectAdOffer = async (id: number): Promise<void> => {
    await put(`rejectAdOffer/${id}`, {});
  };

  const updateAdOffer = async (request: EditAdOfferRequest): Promise<void> => {
    await put(`updateAdOffer/`, request);
  };

  const updateAdInspection = async (
    request: UpdateAdInspectionRequest
  ): Promise<void> => {
    await put(`updateAdInspection/`, request);
  };

  const postInspectionReport = async (
    request: PostInspectionReportRequest
  ): Promise<IInspectionModel> => {
    const configData = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append("Refunded", request.refunded?.toString() ?? "");
    formData.append("UniqueCode", request.uniqueCode ?? "");
    formData.append("SellerOwner", request.sellerOwner?.toString() ?? "");
    formData.append(
      "FinancingFreeVehicle",
      request.financingFreeVehicle?.toString() ?? ""
    );
    formData.append(
      "VehicleFreeLegalRestrictions",
      request.vehicleFreeLegalRestrictions?.toString() ?? ""
    );
    formData.append(
      "FreeVehicleAuction",
      request.freeVehicleAuction?.toString() ?? ""
    );
    formData.append("InspectionStatus", request.inspectionStatus ?? "");
    formData.append(
      "InspectionReportComplete",
      request.inspectionReportComplete ?? "",
      "inspectionReportComplete"
    );
    formData.append(
      "InspectionReportCustom",
      request.inspectionReportCustom ?? "",
      "InspectionReportCustom"
    );

    const { data } = await post(`InspectionReport/`, formData, configData);
    return data;
  };

  const postDeliveryPerson = async (
    request: PostDeliveryPersonRequest
  ): Promise<void> => {
    await post(`postDeliveryPerson`, request);
  };

  const postDeliveryPhoto = async (
    request: PostDeliveryPersonPhotoRequest
  ): Promise<void> => {
    await post(`postDeliveryPhoto`, request);
  };

  const updateDutValues = async (
    request: UpdateDutValuesRequest
  ): Promise<void> => {
    await put(`updateDutValues/`, request);
  };

  const updateCustomer = async (
    request: UpdateCustomerRequest
  ): Promise<void> => {
    await put(`updateCustomer/`, request);
  };

  const getListEmailTemplate = async (
    request: GetListEmailTemplateParams
  ): Promise<IGetListEmailTemplatePagedResponseModel> => {
    const { data } = await get(
      `getListEmailTemplate${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getTemplateBodyVariables = async (): Promise<string[]> => {
    const { data } = await get(`getVariablesBody`);
    return data;
  };

  const getEventHistoryById = async (
    id: number
  ): Promise<IAdEventHistory[]> => {
    const { data } = await get(`getEventHistoryById/${id}`);
    return data;
  };

  const getVehicleStatus = async (): Promise<IAdVehicleStatus[]> => {
    const { data } = await get(`getVehicleStatus`);
    return data;
  };

  const lenderOperator = async (
    request: LenderOperatorRequest
  ): Promise<void> => {
    await post(`lenderOperator`, request);
  };

  const getLenders = async (): Promise<IGetLendersResponse[]> => {
    const { data } = await get(`lender`);
    return data;
  };

  const getLenderOperator = async (
    request: GetLenderOperatorRequest
  ): Promise<IGetLenderOperatorResponsePagedResponseModel> => {
    const { data } = await get(
      `lenderOperator${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getLinkReport = async (
    request: PagedRequestParamsBaseModel
  ): Promise<IGetFidelizaLinkAdsResponsePagedResponseModel> => {
    const { data } = await get(
      `getLinkReport${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getLenderOperatorDetails = async (
    id: number
  ): Promise<IGetLenderOperatorResponse> => {
    const { data } = await get(`lenderOperator/${id}`);
    return data;
  };

  const postDataLoadBorrowers = async (
    request: DataLoadBorrowersRequest
  ): Promise<void> => {
    const configData = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append("File", request.file ?? "");
    formData.append("LenderId", request?.lenderId?.toString() ?? "");

    await post(`dataLoadBorrowers`, formData, configData);
  };

  const postDataLoadResidualValues = async (
    File: string | Blob
  ): Promise<void> => {
    const configData = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append("File", File ?? "");

    await post(`postDataLoadResidualValueVehicle`, formData, configData);
  };

  const getDataLoadBorrowersHistory = async (
    request: PagedRequestParamsBaseModel
  ): Promise<IGetDataLoadBorrowersHistoryPagedResponseModel> => {
    const { data } = await get(
      `getDataLoadBorrowersHistory${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getDataLoadResidualValuesHistory = async (
    request: PagedRequestParamsBaseModel
  ): Promise<IGetDataLoadResidualValuesHistoryPagedResponseModel> => {
    const { data } = await get(
      `dataLoadResidualValueVehicleHistory${HttpHelper.BuildQueryString(
        request
      )}`
    );
    return data;
  };

  const getCustomersFideliza = async (
    request: GetLenderUserRequest
  ): Promise<IGetLenderUserResponsePagedResponseModel[]> => {
    const { data } = await get(
      `getCustomersFideliza${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const postCustomer = async (
    request: LenderUserRequest
  ): Promise<IGetLenderUserResponse> => {
    const { data } = await post(`customer`, request);
    return data;
  };

  const postChangePasswordCustomer = async (
    request: CustomerChangePasswordRequest
  ): Promise<void> => {
    await post(`changePassowordCustomer`, request);
  };

  const getReportPictures = async (
    adInspectionId: number
  ): Promise<IGetReportPicturesResponse[]> => {
    const { data } = await get(`getReportPictures/${adInspectionId}`);
    return data;
  };

  const postSendSelectedReportPictures = async (
    request: PostSelectedRepostPicturesRequest
  ): Promise<void> => {
    const { data } = await post(`sendSelectedReportPictures`, request);
    return data;
  };

  const postRemoveSelectedReportPictures = async (
    request: PostRemoveSelectedRepostPicturesRequest
  ): Promise<void> => {
    const { data } = await post(`removeSelectedReportPictures`, request);
    return data;
  };

  return {
    getAllEmailTemplate,
    sendEmail,
    saveEmailTemplate,
    getEmailTemplate,
    getDeliveryBuyer,
    getDeliverySeller,
    splitValue,
    cancelAd,
    getAllAds,
    createFileTemporaryLink,
    getAd,
    getAdInspectionByAdId,
    startBidding,
    getToken,
    getCustomerReport,
    getDownloadLink,
    getInspectionScheduleLog,
    saveInspectionSchedule,
    stampAsDamaged,
    stampAsFleet,
    clearStamps,
    postAdStep,
    postDeliveryPerson,
    postDeliveryPhoto,
    postDataLoadBorrowers,
    postCustomer,
    postChangePasswordCustomer,
    postDataLoadResidualValues,
    rejectAdOffer,
    updateAdOffer,
    updateAdInspection,
    postInspectionReport,
    updateDutValues,
    updateCustomer,
    getListEmailTemplate,
    getTemplateBodyVariables,
    getEventHistoryById,
    getVehicleStatus,
    lenderOperator,
    getLenders,
    getLenderOperator,
    getLenderOperatorDetails,
    getLinkReport,
    getDataLoadBorrowersHistory,
    getDataLoadResidualValuesHistory,
    getCustomersFideliza,
    getReportPictures,
    postSendSelectedReportPictures,
    postRemoveSelectedReportPictures,
  };
};
