import {
  AdStepEnum,
  AdSubStepAcceptedOfferEnum,
  AdSubStepAuctionEnum,
  AdSubStepCanceledEnum,
  AdSubStepInspectionEnum,
  AdSubStepRegisteredeEnum,
  StdEnum,
} from "../models";

export class StatusHelper {
  static getStepName(step: AdStepEnum) {
    return AdStepEnum[step];
  }

  static getStepStatusName(step: AdStepEnum, stepStatus: number) {
    try {
      switch (step) {
        case AdStepEnum.Registered:
          return AdSubStepRegisteredeEnum[stepStatus];
        case AdStepEnum.Inspection:
          return AdSubStepInspectionEnum[stepStatus];
        case AdStepEnum.Auction:
          return AdSubStepAuctionEnum[stepStatus];
        case AdStepEnum.AcceptedOffer:
          return AdSubStepAcceptedOfferEnum[stepStatus];
        case AdStepEnum.Canceled:
          return AdSubStepCanceledEnum[stepStatus];
      }
    } catch {
      return "";
    }
  }

  static getStatus(
    step: number,
    stepStatus: number,
    t: (text: string) => string
  ) {
    return `${t(StatusHelper.getStepName(step) ?? "")} - ${t(
      StatusHelper.getStepStatusName(step, stepStatus) ?? ""
    )}`;
  }

  static getApprovalStatusName(status: number): string {
    const enumStatus: Record<number, string> = {
      0: "Pending",
      1: "Approved",
      2: "Disapproved",
      3: "Incomplete",
    };

    return enumStatus[status];
  }

  static getApprovalStatus(status: number, t: (text: string) => string) {
    return `${t(StatusHelper.getApprovalStatusName(status) ?? "")}`;
  }

  static getStepStatusEnum(step: AdStepEnum | undefined | null): StdEnum {
    if (typeof step === "string") step = parseInt(step);
    switch (step) {
      case AdStepEnum.Registered:
        return AdSubStepRegisteredeEnum;
      case AdStepEnum.Inspection:
        return AdSubStepInspectionEnum;
      case AdStepEnum.Auction:
        return AdSubStepAuctionEnum;
      case AdStepEnum.AcceptedOffer:
        return AdSubStepAcceptedOfferEnum;
      case AdStepEnum.Canceled:
        return AdSubStepCanceledEnum;
      default:
        return {};
    }
  }
}
