import { Combo, FormikControlAtom } from "c4u-web-components";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StdEnum } from "../../../models";
import { Wrapper } from "./checkbox-from-enum.atom.style";

interface IProps {
  propertyKey: string;
  optionsEnum: StdEnum;
  formik?: any;
}

export const CheckBoxOptionsFromEnumAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const permissionsStringOptions = useMemo(
    () =>
      Object.entries(props.optionsEnum).map(
        ([k, v]) => new Combo({ title: t(k), value: v })
      ),
    [t, props.optionsEnum]
  );

  return (
    <Form.Row>
      <Wrapper>
        <FormikControlAtom
          translate={t}
          className="checkbox-session"
          type={"checkbox"}
          data={permissionsStringOptions}
          formik={props.formik}
          property={props.propertyKey}
          disabled={false}
        />
      </Wrapper>
    </Form.Row>
  );
};
