import React from "react";
import { useTranslation } from "react-i18next";

import { ListUserAccounts } from "../../../organisms";

export const UserAccountListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleListUserAccountsPage")}</h3>
      <ListUserAccounts />
    </>
  );
};
