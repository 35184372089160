import React from "react";
import { PermissionEnum } from "c4u-web-components";
import { HeaderBackofficeMolecule } from "../../molecules";
import { BackofficeBasePage } from "../../pages";
import {
  BackofficeContentContainer,
  BackofficeHeaderDiv,
} from "./backoffice.template.style";

interface IProps {
  permission?: PermissionEnum | PermissionEnum[];
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const BackofficeBaseTemplate: React.FC<IProps> = (props) => {
  return (
    <>
      <BackofficeHeaderDiv>
        <HeaderBackofficeMolecule />
      </BackofficeHeaderDiv>

      <BackofficeContentContainer>
        {!!props.permission && (
          <BackofficeBasePage
            isAuthenticated={props.isAuthenticated}
            isLoading={props.isLoading}
            permission={props.permission}
          >
            {props.children}
          </BackofficeBasePage>
        )}
      </BackofficeContentContainer>
    </>
  );
};
