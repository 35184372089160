import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { renderViewHelper, StringHelper } from "../../../../../helper";
import { IZapayVehicleData } from "../../../../../models";

interface IProps {
  vehicleDetails?: IZapayVehicleData;
}

export const ZapayVehicleDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewVehicle = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.vehicleDetails, key, label, func);

  return !props.vehicleDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <Form.Row>
      {renderViewVehicle("detranUf", t("LicenseUf"))}
      {renderViewVehicle("plate", t("Plate"))}
      {renderViewVehicle("renavam", t("Renavam"))}
      {renderViewVehicle(
        "clientDocument",
        t("ClientDocument"),
        StringHelper.formatCpfCnpj
      )}
    </Form.Row>
  );
};
