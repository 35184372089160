import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import {
  MyEarningsSectionTitleDiv,
  MyEarningsMoleculeWrapper,
  MyEarningsWhiteBackgroundCard,
} from "../my-earnings-shared.style";
import {
  InstallmentTermText,
  PercentageText,
} from "./my-earnings-debits-data.molecule.style";

interface IProps {
  installmentTerms: number[];
  percentageOverDebit: number;
  titleBackgroundColor?: string;
  titleColor?: string;
}

export const MyEarningsDebitsDataMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <MyEarningsMoleculeWrapper>
      <Row>
        <Col xs={7} sm={6} lg={5}>
          <MyEarningsSectionTitleDiv
            className="h-100"
            background={props.titleBackgroundColor}
            color={props.titleColor}
          >
            {t("InstallmentTermsTitle")}
          </MyEarningsSectionTitleDiv>
        </Col>
        <Col xs={5} sm={6} lg={7}>
          <MyEarningsSectionTitleDiv
            className="h-100"
            background={props.titleBackgroundColor}
            color={props.titleColor}
          >
            {t("PercentageOverDebitTitle")}
          </MyEarningsSectionTitleDiv>
        </Col>
      </Row>

      <Row>
        <Col xs={7} sm={6} lg={5}>
          {props.installmentTerms.map((m, i) => (
            <MyEarningsWhiteBackgroundCard
              className="my-3 py-2"
              key={`installment-term-${i}`}
            >
              <InstallmentTermText>{m}</InstallmentTermText>
            </MyEarningsWhiteBackgroundCard>
          ))}
        </Col>
        <Col xs={5} sm={6} lg={7}>
          <MyEarningsWhiteBackgroundCard className="my-3">
            <PercentageText>{props.percentageOverDebit}%</PercentageText>
          </MyEarningsWhiteBackgroundCard>
        </Col>
      </Row>
    </MyEarningsMoleculeWrapper>
  );
};
