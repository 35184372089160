import { parseISO, format, parse } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";
import {
  AdInspectionScheduleLogRequest,
  AdModel,
  IAdInspectionModel,
  IAdInspectionScheduleLogModel,
} from "../../../../../models";
import { AdInspectionScheduleMolecule, TableList } from "../../../../molecules";
import { IInspectionSchedule } from "../../../../molecules/backoffice/ads/ad-inspection-schedule/ad-inspection-schedule.molecule";
import { Combo } from "c4u-web-components";

interface IProps {
  ad: AdModel;
  adInspection: IAdInspectionModel;
  setException: React.Dispatch<any>;
}
export const InspectionScheduleOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { getInspectionScheduleLog, saveInspectionSchedule } = useManhein();

  const [
    inspectionFirstScheduleLogs,
    setInspectionFirstScheduleLogs,
  ] = useState<IAdInspectionScheduleLogModel[]>();

  const [
    inspectionSecondScheduleLogs,
    setInspectionSecondScheduleLogs,
  ] = useState<IAdInspectionScheduleLogModel[]>();

  const loadInspectionShceduleLogs = async () => {
    const values = await getInspectionScheduleLog(props.ad.id);
    setInspectionFirstScheduleLogs(values.filter((f) => f.adInspectionId));
    setInspectionSecondScheduleLogs(values.filter((f) => f.adId));
  };

  useEffect(() => {
    if (props.ad) loadInspectionShceduleLogs();
    // eslint-disable-next-line
  }, [props.ad]);

  const handleSubmitFirstSchedule = async (values: IInspectionSchedule) => {
    return handleSubmitSchedule(values, undefined, props.adInspection.id);
  };

  const handleSubmitSecondSchedule = async (values: IInspectionSchedule) => {
    return handleSubmitSchedule(values, props.ad.id);
  };

  const handleSubmitSchedule = async (
    values: IInspectionSchedule,
    adId?: number,
    adInspection?: number
  ) => {
    if (!values.inspectionDate) return;

    const iRequest = {
      adId: adId,
      adInspectionId: adInspection,
      inspectionDate: parse(values.inspectionDate, "dd/MM/yyyy", new Date()),
      inspectionPeriod: values.inspectionPeriod,
      justification: values.justification,
    } as IAdInspectionScheduleLogModel;
    const request = new AdInspectionScheduleLogRequest(iRequest);
    const response = await saveInspectionSchedule(request);
    return response?.id;
  };

  const periods = useMemo(() => {
    return [
      new Combo({ value: 9, title: "09:00 - 10:00" }),
      new Combo({ value: 10, title: "10:00 - 11:00" }),
      new Combo({ value: 11, title: "11:00 - 12:00" }),
      new Combo({ value: 12, title: "12:00 - 13:00" }),
      new Combo({ value: 13, title: "13:00 - 14:00" }),
      new Combo({ value: 14, title: "14:00 - 15:00" }),
      new Combo({ value: 15, title: "15:00 - 16:00" }),
      new Combo({ value: 16, title: "16:00 - 17:00" }),
      new Combo({ value: 17, title: "17:00 - 18:00" }),
    ];
  }, []);

  return (
    <Row>
      <Col>
        <h5 className="mb-4">{t("First inspection schedule")}</h5>
        <AdInspectionScheduleMolecule
          disabled={!props.adInspection.inspectionDate || props.ad.step > 2}
          onSubmit={handleSubmitFirstSchedule}
          periods={periods}
          setException={props.setException}
          data={
            {
              inspectionDate: format(
                parseISO(props.adInspection.inspectionDate.toString()),
                "dd/MM/yyyy"
              ),
              inspectionPeriod: props.adInspection.inspectionPeriod,
            } as IInspectionSchedule
          }
        />
        {!!inspectionFirstScheduleLogs?.length && (
          <>
            <hr />
            <TableList
              thead={["#", t("Date"), t("Period"), t("Justification")]}
            >
              {inspectionFirstScheduleLogs?.map((m, i) => (
                <tr key={i}>
                  <td>{m.id}</td>
                  <td>
                    {format(
                      parseISO(m.inspectionDate.toString()),
                      "dd/MM/yyyy"
                    )}
                  </td>
                  <td>
                    {periods.find((f) => f.value === m.inspectionPeriod)?.title}
                  </td>
                  <td>{m.justification}</td>
                </tr>
              ))}
            </TableList>
          </>
        )}
      </Col>
      <Col>
        <h5 className="mb-4">{t("Second inspection schedule")}</h5>
        <AdInspectionScheduleMolecule
          onSubmit={handleSubmitSecondSchedule}
          periods={periods}
          disabled={!props.ad.reinspectionDate || props.ad.step < 3}
          setException={props.setException}
          data={
            {
              inspectionDate: props.ad.reinspectionDate
                ? format(
                    parseISO(props.ad.reinspectionDate?.toString()),
                    "dd/MM/yyyy"
                  )
                : undefined,
              inspectionPeriod: props.ad.reinspectionPeriod,
            } as IInspectionSchedule
          }
        />
        {!!inspectionSecondScheduleLogs?.length && (
          <>
            <hr />
            <TableList
              thead={["#", t("Date"), t("Period"), t("Justification")]}
            >
              {inspectionSecondScheduleLogs?.map((m, i) => (
                <tr key={i}>
                  <td>{m.id}</td>
                  <td>
                    {format(
                      parseISO(m.inspectionDate.toString()),
                      "dd/MM/yyyy"
                    )}
                  </td>
                  <td>
                    {periods.find((f) => f.value === m.inspectionPeriod)?.title}
                  </td>
                  <td>{m.justification}</td>
                </tr>
              ))}
            </TableList>
          </>
        )}
      </Col>
    </Row>
  );
};
