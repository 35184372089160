import { InsuranceApprovalStatusEnum } from "../../../../models";

export interface IUser {
  id?: number | null;
  name: string;
  email: string;
  isDt?: boolean;
  publicId?: string;
}

export interface IDefaultResponse {
  success?: boolean;
}

export interface ICompleteUser extends IUser {
  isMaster: boolean;
  cellPhone: string;
}

export interface IProcurator {
  isProcuratorOrOwner: boolean;
  name: string;
  email: string;
  cpf: string;
  birthDate: string;
  cellPhone: string;
}

export interface IAddress {
  zipCode: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface IDealership {
  id?: string;
  publicId?: string;
  procurator: IProcurator;
  address: IAddress;
  cnpj: string;
  name: string;
  legalName: string;
  email?: string;
  primaryPhone: string;
  secondaryPhone: string | undefined;
  group?: string;
  approvalStatus?: number;
  createdById?: number;
  active?: boolean;
}

export class IntegrationRecord {
  createdBy?: ICompleteUser;
  targetUser?: ICompleteUser;
  status?: number;
  type?: number;
  details?: string;

  public getStatus(): string {
    switch (this.status) {
      case 0:
        return "Succeed";
      case 1:
        return "Failed";

      default:
        return "N/A";
    }
  }

  public getType(): string {
    switch (this.type) {
      case 0:
        return "Auth0";
      case 1:
        return "AutoAvaliar";
      case 2:
        return "Dealertrack";

      default:
        return "N/A";
    }
  }
}

export interface IRetryIntegration {
  whoDidIt: string;
  userEmail: string;
}

export interface IRetryDealertrackIntegration {
  whoDidIt: string;
  createdByPublicId: string;
}

export interface IOnboardingCompletion {
  dealership?: IDealership;
  createdById?: number;
  createdByPublicId?: string;
  approvalStatus?: number;
  message?: string;
  hasBackofficeRegistrationAccess?: boolean;
  hasBackofficeAdsAccess?: boolean;
  hasDealertrackAccess?: boolean;
  hasKbbAccess?: boolean;
  hasManheimAccess?: boolean;
  hasProductsB3Access?: boolean;
  hasProductsDebtsAccess?: boolean;
  hasProductsInspectionAccess?: boolean;
  hasProductsInsuranceAccess?: boolean;
  integrationRecords?: Array<IntegrationRecord>;
  general?: string;
}

export interface IUserOnboarding {
  id?: string;
  cpf?: string;
  password?: string;
  passwordValidation?: string;
}

export interface IGetDealershipsViewModel {
  id: number;
  cnpj?: string;
  name?: string;
  approvalStatus?: InsuranceApprovalStatusEnum;
  active?: boolean;
}

export interface IUserViewModel {
  id: number;
  publicId: string;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
  name: string;
  email: string;
  cpf: string;
  cellPhone: string;
  isMaster: boolean | undefined;
  isDt: boolean;
  token: string;
  createdById: number;
  updatedById: number;
  dealershipId: number;
}
