import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CreateUserFormFields } from "../../../organisms";

import { Wrapper } from "./create-user.page.style";

import {
  BannerBlueBottom,
  BannerBlueMobile,
  BannerBlueTop,
  BannerC4U,
  BannerCar,
  ManheimLogo,
  KBBLogo,
  DealerTrackLogo,
  BannerBottomMobile,
} from "../../../assets";

export const CreateUserPage = () => {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <Wrapper>
      <div className="wrapper">
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="content-banners col-lg-5 .col-md-5 col-sm-5 px-0 height">
                <div className="box-logo">
                  <div className="box-logo-size">
                    <div>
                      <div className="banner-circle">
                        <img
                          className="image-circle-blue"
                          src={BannerBlueTop}
                          alt="Circle Blue"
                        />
                      </div>
                      <div>
                        <img
                          src={BannerC4U}
                          className="cox-logo"
                          alt="Logo Cox"
                        />
                      </div>
                    </div>
                    <div className="banner-bottom">
                      <div>
                        <img
                          src={BannerCar}
                          className="banner-car"
                          alt="banner1"
                        />
                      </div>
                      <div className="banner-car-circle">
                        <img src={BannerBlueBottom} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 .col-md-7 col-sm-7 px-0 height">
                <div className="box-login">
                  <div className="content-circle-mobile">
                    <img src={BannerBlueMobile} alt="Circle Blue" />
                  </div>
                  <div className="header">
                    <div className="header-box-logo">
                      <div className="img-left">
                        <img src={KBBLogo} alt="kbb" />
                      </div>
                      <div className="img-center">
                        <img src={DealerTrackLogo} alt="dealertrack" />
                      </div>
                      <div className="img-right">
                        <img src={ManheimLogo} alt="manhein" />
                      </div>
                    </div>
                  </div>

                  <div className="form-login">
                    <h4>{t("Create account")}</h4>
                    <h6>{t("CreateAccountAdvice")}</h6>

                    <CreateUserFormFields />
                  </div>

                  <div className="banner-bottom-content">
                    <img src={BannerBottomMobile} alt="" />
                  </div>
                </div>
                <div className="div-yellow"></div>
                <div className="div-blue"></div>
              </div>
            </div>
          </div>

          <hr />
          <span className="footer">
            © 2021 Cox Automotive - Cox Pra Você. All Rights Reserved.
          </span>
        </div>
      </div>
      {/* <LogoC4uContainer>
        <LogoCox4u />
      </LogoC4uContainer>
      <HomeVectorContainer>
        <HomeVector />
      </HomeVectorContainer>
      <MainCarBg />
      <BrandsContainer>
        <C4uBrands />
      </BrandsContainer>
      <C4uLoginBox>
        <RoundedCard>
          <C4uLoginForm>
            <LabelDefault lblColor="blue" lblWeight="title">
              {t("Create account")}
            </LabelDefault>
            <LabelDefault lblColor="blue" lblWeight="small" fontFamily="Open Sans">
              {t("Create.Account.Description")}
            </LabelDefault>
            <br />
            <CreateUserFormFields />
          </C4uLoginForm>
        </RoundedCard>
      </C4uLoginBox>
      <C4uSloganBox>
        Transformando a maneira como o mundo
        <br />
        compra, vende, usa e compartilha veículos
      </C4uSloganBox>
      <FooterContainer>
        <Footer />
      </FooterContainer> */}
    </Wrapper>
  );
};
