import { TypesHelper } from "c4u-web-components";
import { IAddress } from "../..";

export interface INotifyBuyerPaymentFormData {
  name: string;
  document: string;
  email: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;
}

export class NotifyBuyerPaymentWinnerRequest {
  bidder: INotifyBuyerPaymentBidderModel;
  email: string;

  constructor({
    name,
    document,
    email,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    zipCode,
  }: INotifyBuyerPaymentFormData) {
    this.bidder = {
      name: name,
      document: TypesHelper.toStringOnlyNumbersNull(document) ?? "",
      address: {
        street: street,
        number: number,
        complement: complement,
        neighborhood: neighborhood,
        city: city,
        state: state,
        zip: zipCode,
      },
    };
    this.email = email;
  }
}

export interface INotifyBuyerPaymentBidderModel {
  name: string;
  document: string;
  address: IAddress;
}

export interface INotifyBuyerPaymentWinnerModel {
  bidder: INotifyBuyerPaymentBidderModel;
  email: string;
}

export interface INotifyBuyerPaymentRequest {
  vehicle_id: string;
  payment_status: number;
  winner: INotifyBuyerPaymentWinnerModel;
}

export interface INotifyReleasePaymentRequest {
  vehicleId: string;
}

export interface INotifyPaymentToSellerRequest {
  vehicleId: string;
  paymentVoucherBase64: string;
}

export interface INotifyBuyerNoPaymentRequest {
  vehicle_id: string;
  payment_status: number;
  timestamp: string;
}
