import styled from "styled-components";
import { RegistrationHomeCardStyleProps } from "./registration-card.atom";

const RegistrationHomeCardStyled = styled.div<RegistrationHomeCardStyleProps>`
  box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.25);
  border-radius: 20px;

  background: linear-gradient(
    to bottom,
    ${({ profile }) => {
        if (profile === "kbb") return "#FFD478";
        if (profile === "dt") return "rgba(168, 170, 172, 0.35)";
        if (profile === "manheim") return "#1F3E74";
        if (profile === "daycoval") return "#050E64";
        if (profile === "creditas") return "#F2F2F3";
        if (profile === "rota21") return "#FFFFFF";

        return "#1F3E74";
      }}
      0%,
    ${({ profile }) => {
        if (profile === "kbb") return "#FFD478";
        if (profile === "dt") return "rgba(168, 170, 172, 0.35)";
        if (profile === "manheim") return "#1F3E74";
        if (profile === "daycoval") return "#050E64";
        if (profile === "creditas") return "#F2F2F3";
        if (profile === "rota21") return "#FFFFFF";

        return "#1F3E74";
      }}
      93%,
    ${({ borderColor }) => borderColor} 93%,
    ${({ borderColor }) => borderColor} 100%
  );

  p {
    color: ${({ profile }) => {
      if (profile === "kbb") return "#4A4A4A";
      if (profile === "dt") return "#1F3E74";
      if (profile === "manheim") return "#FFFFFF";
      if (profile === "daycoval") return "#FFFFFF";
      if (profile === "creditas") return "#53565A";
      if (profile === "rota21") return "#808285";

      return "#FFFFFF";
    }};
  }

  hr {
    border-color: ${({ profile }) => {
      if (profile === "kbb") return "rgba(74, 74, 74, 0.15)";
      if (profile === "dt") return "rgba(157, 155, 155, 0.6)";
      if (profile === "manheim") return "#FFFFFF";
      if (profile === "daycoval") return "rgba(74, 74, 74, 0.15)";
      if (profile === "creditas") return "#FFFFFF00";
      if (profile === "rota21") return "rgba(74, 74, 74, 0.15)";

      return "#FFFFFF";
    }};
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  margin: 14px 22px;

  @media (max-width: 991px) {
    gap: 30px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 43px;
  margin-bottom: 7px;
`;

const CardTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;

  color: ${({ theme }) => theme.colors.BlueNavy};

  @media (min-width: 992px) and (max-width: 1096px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const CardText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  margin: 0;

  @media (min-width: 992px) and (max-width: 1096px) {
    font-size: 13px;
    line-height: 18px;
  }

  @media (max-width: 575px) {
    max-width: 360px;
  }

  @media (max-width: 440px) {
    max-width: 296px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  height: 93px;
  width: 122px;

  @media (min-width: 992px) and (max-width: 1096px) {
    padding-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 440px) {
    display: none;
  }
`;

const CardBottom = styled.div`
  display: flex;

  justify-content: end;
  @media (max-width: 440px) {
    justify-content: start;
  }

  margin: 15px 28px 27px;

  button {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    width: 264px;
    margin: 0;
  }

  @media (max-width: 1096px) {
    button {
      height: 30px;
    }
  }

  @media (max-width: 440px) {
    button {
      width: 198px;
    }
  }
`;

export {
  RegistrationHomeCardStyled,
  CardBottom,
  CardHeader,
  CardTitle,
  CardText,
  LogoWrapper,
  ImageWrapper,
};
