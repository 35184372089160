import React from "react";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../../constants";
import { useProducts } from "../../../../../hooks";
import { DateHelper, StringHelper } from "../../../../../helper";
import {
  InspectionStatusEnum,
  IInspectionViewModel,
} from "../../../../../models";
import { Link } from "react-router-dom";
import { PaginatedTableSearchFormOrganism } from "../../..";

export const C4uInspectionList: React.FC = () => {
  const { t } = useTranslation();
  const { getAllInspectionsC4u } = useProducts();

  return (
    <>
      <PaginatedTableSearchFormOrganism
        tableName={"c4u-inspection-list"}
        getDataCallback={getAllInspectionsC4u}
        statusEnum={InspectionStatusEnum}
        tableHeaders={[
          "#",
          t("VehiclePlate"),
          t("InspectionReportType"),
          t("OwnerDocument"),
          t("TransactionDateTime"),
          t("Status"),
          "",
        ]}
        mappingFunction={(m: IInspectionViewModel, i) => [
          m.id,
          m.plate,
          m.inspectionPlanName,
          StringHelper.formatCpfCnpj(m.customer.document),
          DateHelper.dateHourFormat(m.insertDate),
          t(InspectionStatusEnum[m.status]),
          <Link
            key={`link${i}`}
            to={paths.backofficeC4uInspectionDetailPage(m.id)}
          >
            {t("Details")}
          </Link>,
        ]}
      />
    </>
  );
};
