import { TypesHelper } from "c4u-web-components";
import { EncodeLenderCodeEnum } from "../../enum/insurance-dt.enum";
import {
  IDtInformation,
  IDtInformationAddress,
  IDtInformationCreditApp,
  IDtInformationCustomer,
  IDtInformationDealership,
  IDtInformationVehicle,
} from "./insurance-dt-information.model";

export interface IPostDtInformationSyncRequest {
  data: IDtInformation;
}

export class PostDtInformationSyncRequest {
  data: DtInformation;

  constructor({ data }: IPostDtInformationSyncRequest) {
    this.data = new DtInformation(data);
  }
}

export class DtInformation {
  vehicle: DtInformationVehicle;
  customer: DtInformationCustomer;
  dealership: DtInformationDealership;
  creditApp: DtInformationCreditApp;

  constructor({ vehicle, customer, dealership, creditApp }: IDtInformation) {
    this.vehicle = new DtInformationVehicle(vehicle);
    this.customer = new DtInformationCustomer(customer);
    this.dealership = new DtInformationDealership(dealership);
    this.creditApp = new DtInformationCreditApp(creditApp);
  }
}

export class DtInformationVehicle {
  manufactureYear: number | null;
  modelYear: number | null;
  licensePlate: string | null;
  vinNmber: string | null;
  renavam: string | null;
  color: string | null;
  price: number | null;
  isNew: string | null;
  molicarId: string | null;
  licensePlateUF: string | null;

  constructor({
    manufactureYear,
    modelYear,
    licensePlate,
    vinNmber,
    renavam,
    color,
    price,
    isNew,
    molicarId,
    licensePlateUF,
  }: IDtInformationVehicle) {
    this.manufactureYear = TypesHelper.toNumberNull(manufactureYear);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
    this.licensePlate = TypesHelper.toStringNull(licensePlate);
    this.vinNmber = TypesHelper.toStringNull(vinNmber);
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.color = TypesHelper.toStringNull(color);
    this.price = TypesHelper.toNumberNull(price);
    this.isNew = TypesHelper.toStringNull(isNew);
    this.molicarId = TypesHelper.toStringNull(molicarId);
    this.licensePlateUF = TypesHelper.toStringNull(licensePlateUF);
  }
}

export class DtInformationCustomer {
  completeName: string | null;
  cpf: string | null;
  typeId: string | null;
  idNumber: string | null;
  expeditorCode: string | null;
  expeditionDate: Date | null;
  birthDate: Date | null;
  gender: string | null;
  email: string | null;
  maritalStatus: string | null;
  revenue: number | null;
  professionalActivity: string | null;
  profession: string | null;
  countryOfBorn: string | null;
  phonenumber: string | null;
  cellphonenumber: string | null;
  address: DtInformationAddress | null;

  constructor({
    completeName,
    cpf,
    typeId,
    idNumber,
    expeditorCode,
    expeditionDate,
    birthDate,
    gender,
    email,
    maritalStatus,
    revenue,
    professionalActivity,
    profession,
    countryOfBorn,
    phonenumber,
    cellphonenumber,
    address,
  }: IDtInformationCustomer) {
    this.completeName = TypesHelper.toStringNull(completeName);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.typeId = TypesHelper.toStringNull(typeId);
    this.idNumber = TypesHelper.toStringNull(idNumber);
    this.expeditorCode = TypesHelper.toStringNull(expeditorCode);
    this.expeditionDate = TypesHelper.toDateNull(expeditionDate);
    this.birthDate = TypesHelper.toDateNull(birthDate);
    this.gender = TypesHelper.toStringNull(gender);
    this.email = TypesHelper.toStringNull(email);
    this.maritalStatus = TypesHelper.toStringNull(maritalStatus);
    this.revenue = TypesHelper.toNumberNull(revenue);
    this.professionalActivity = TypesHelper.toStringNull(professionalActivity);
    this.profession = TypesHelper.toStringNull(profession);
    this.countryOfBorn = TypesHelper.toStringNull(countryOfBorn);
    this.phonenumber = TypesHelper.toStringOnlyNumbersNull(phonenumber);
    this.cellphonenumber = TypesHelper.toStringOnlyNumbersNull(cellphonenumber);
    this.address = address ? new DtInformationAddress(address) : null;
  }
}

export class DtInformationAddress {
  zipCode: string | null;
  address: string | null;
  homeNro: string | null;
  district: string | null;
  complement: string | null;
  city: string | null;
  state: string | null;

  constructor({
    zipCode,
    address,
    homeNro,
    district,
    complement,
    city,
    state,
  }: IDtInformationAddress) {
    this.zipCode = TypesHelper.toStringNull(zipCode);
    this.address = TypesHelper.toStringNull(address);
    this.homeNro = TypesHelper.toStringNull(homeNro);
    this.district = TypesHelper.toStringNull(district);
    this.complement = TypesHelper.toStringNull(complement);
    this.city = TypesHelper.toStringNull(city);
    this.state = TypesHelper.toStringNull(state);
  }
}

export class DtInformationCreditApp {
  publicPersonId: string | null;
  lenderCode: string | null;
  encodeLenderCode: string | null;
  step: string | null;
  expiryDate: Date | null;

  constructor({
    publicPersonId,
    encodeLenderCode,
    step,
    expiryDate,
  }: IDtInformationCreditApp) {
    this.publicPersonId = TypesHelper.toStringNull(publicPersonId);
    const lenderCodeEntry = Object.entries(EncodeLenderCodeEnum).find(
      ([_, v]) => v === encodeLenderCode
    );
    this.lenderCode =
      lenderCodeEntry && lenderCodeEntry.length > 0
        ? TypesHelper.toStringNull(lenderCodeEntry[0])
        : null;
    this.encodeLenderCode = TypesHelper.toStringNull(encodeLenderCode);
    this.step = TypesHelper.toStringNull(step);
    this.expiryDate = TypesHelper.toDateNull(expiryDate);
  }
}

export class DtInformationDealership {
  businessNumber: string | null;
  legalName: string | null;
  name: string | null;

  constructor({ businessNumber, legalName, name }: IDtInformationDealership) {
    this.businessNumber = TypesHelper.toStringOnlyNumbersNull(businessNumber);
    this.legalName = TypesHelper.toStringNull(legalName);
    this.name = TypesHelper.toStringNull(name);
  }
}
