import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { INotifyBuyerNoPaymentRequest } from "../../../../../models";
import * as Yup from "yup";
import { useManheinWebhook } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";

interface IProps {
  enabled: boolean;
  unicCode: string;
  setException: React.Dispatch<any>;
}
export const AdNotifyBuyerNoPaymentMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { webhookNotifyBuyerNoPayment } = useManheinWebhook();

  const validations = Yup.object<INotifyBuyerNoPaymentRequest>({
    vehicle_id: Yup.string().required(),
    payment_status: Yup.number().required(),
    timestamp: Yup.string().required(),
  });

  const formik = useFormik<INotifyBuyerNoPaymentRequest>({
    initialValues: {
      vehicle_id: props.unicCode,
      payment_status: 0,
      timestamp: new Date().toISOString(),
    } as INotifyBuyerNoPaymentRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await webhookNotifyBuyerNoPayment(values);

          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Notify no payment")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
