import React from "react";
import { useTranslation } from "react-i18next";
import { LenderOperatorListOrganism } from "../../../organisms";

export const FidelizaUsersListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("FidelizaUserListing")}</h3>

      <LenderOperatorListOrganism />
    </>
  );
};
