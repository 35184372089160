import React from "react";
import { Table } from "react-bootstrap";

interface IProps {
  thead: string[];
}

export const TableList: React.FC<IProps> = (props) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {props.thead.map((m, i) => (
            <th key={`th${i}`}>{m}</th>
          ))}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
};
