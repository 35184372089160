import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #1f3e74 34.06%, #3a5693 95.47%);
  width: 100%;
  height: 100%;

  hr {
    background: #fff;
    opacity: 0.2;
    margin: 76px 0 0 0;
  }

  .footer {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    opacity: 0.7;
    margin: 32px 0;
    justify-content: center;
    text-align: center;
  }

  .wrapper {
    height: 100%;
    min-height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding-top: 12px;
    min-height: 750px;
    background: linear-gradient(
      90deg,
      rgb(31, 62, 116) 34.06%,
      rgb(58, 86, 147) 95.47%
    );
  }

  .form-login {
    width: 280px;
    margin: auto;
  }

  @media screen and (max-height: 738px) {
    .wrapper {
      align-items: baseline;
    }
  }
  .container {
    margin: 0;
  }

  .box-login {
    background-color: #fff;
    height: 100%;
  }

  .box-logo {
    background: rgba(255, 255, 255, 0.54);
    height: 100%;
  }

  .banner-circle {
    position: relative;
  }

  .image-circle-blue {
    position: absolute;
    bottom: -215px;
    right: 27px;
  }

  .cox-logo {
    position: relative;
    width: 248px;
    top: 33px;
    left: -6px;
  }

  .banner-car {
    z-index: 1;

    position: relative;
    top: 170px;
    left: 14px;
  }

  .banner-car-circle {
    position: relative;
    right: 129px;
  }

  .box-logo-size {
    max-width: 420px;
    height: 100%;
    color: #fff;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 28px;
    text-align: center;
    margin: auto;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }

  .header-box-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 5px;
    gap: 30px;
  }

  .content {
    border-radius: 3px 10px 10px 3px;
    height: 574px;
    overflow: hidden;
    max-width: 936px;
    align-items: center;
    margin: auto;
  }

  .content .height {
    height: 574px;
  }

  .header {
    padding: 50px 40px;
    display: flex;
    justify-content: center;
    max-width: 480px;
    margin: auto;
  }
  .header [class*="img"] {
    width: 100%;
    height: 40px;
    display: grid;
    align-items: center;
  }

  .img-center {
    text-align: center;
    justify-content: center;
  }

  .img-right {
    text-align: right;
    justify-content: end;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #293e6b;
    justify-content: center;
    margin: 0 0 15px 0;
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #293e6b;
    margin-bottom: 30px;
  }

  .input-group-text {
    padding: 0 10px;
    background: #fff;
    border: 1px solid rgba(168, 170, 172, 0.35) !important;
    border-right: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
    display: flex;
  }
  input.form-control {
    padding-left: 0;
    border-left: 0 !important;
    border: 1px solid rgba(168, 170, 172, 0.35);
    height: 50px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #4a4a4a;
  }

  .form-control:focus {
    outline: none;
    outline: none !important;
    box-shadow: none;
    border: 1px solid rgba(168, 170, 172, 0.35);
  }

  .form-check-input.is-valid,
  .was-validated .form-check-input:valid {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }

  .form-check-input.is-valid,
  .was-validated .form-check-input:checked {
    background-color: #0d6efd !important;
  }

  .text-logo {
    max-width: 320px;
    margin: auto;
  }

  .div-yellow {
    position: relative;
    background: #ffd478;
    width: 588px;
    height: 22px;
    left: 209px;
    bottom: 303px;
    transform: rotate(270deg);
  }

  .div-yellow:after {
    background: white;
    width: 575px;
    height: 10px;
    vertical-align: top;
    display: inline-block;
    content: "";
    border-radius: 0 0 10px 10px;
  }

  .div-blue {
    position: relative;
    background: #00599a;
    width: 576px;
    height: 44px;
    left: 248px;
    bottom: 331px;
    transform: rotate(270deg);
  }

  .div-blue:before {
    width: 575px;
    height: 11px;
    background-color: #ffd478;
    vertical-align: top;
    display: inline-block;
    content: "";
    border-radius: 0 0 10px 10px;
  }

  @media (max-width: 991px) {
    .header-box-logo {
      img {
        width: 90px;
      }
    }

    .image-circle-blue {
      right: 24px;
      width: 400px;
    }

    .banner-car {
      top: 243px;
      width: 330px;
    }

    .banner-car-circle {
      top: 72px;
    }

    .header {
      max-width: 396px;
    }

    .header-box-logo {
      gap: 10px;
    }

    .div-yellow {
      left: 86px;
      bottom: 303px;
    }

    .div-blue {
      left: 114px;
      bottom: 331px;
    }
  }

  @media (max-width: 767px) {
    form {
      margin-right: 20px;
    }

    .image-circle-blue {
      bottom: -185px;
      right: 69px;
    }

    .banner-car {
      width: 239px;
    }

    .banner-car-circle {
      right: 105px;
    }

    .form-login {
      z-index: 1;
      width: 210px;
    }

    .header-box-logo {
      gap: 10px;
      padding: 0;

      img {
        width: 70px;
      }
    }

    .div-blue {
      left: 9px;
      bottom: 332px;
    }

    .div-yellow {
      left: -17px;
      bottom: 305px;
    }
  }

  @media (min-width: 575px) {
    .banner-bottom-content {
      display: none;
    }

    .content-circle-mobile {
      display: none;
    }
  }

  @media (max-width: 575px) {
    .banner-bottom-content {
      display: flex;
      position: fixed;
      left: -60px;
      bottom: -32px;
    }

    .content-circle-mobile {
      position: fixed;
      top: -111px;
      right: -122px;
    }

    .content-banners {
      display: none;
    }

    .div-yellow {
      display: none;
    }

    .div-blue {
      display: none;
    }

    .box-login {
      z-index: 1;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background: #1f3e74;
    }

    .header {
      width: 100%;

      .img-center {
        padding: 10px 25px 15px 9px;
        img {
          width: 79px;
        }
      }

      .img-right {
        img {
          width: 80px;
        }
      }

      .img-left {
        img {
          width: 115px;
        }
      }
    }

    .header-box-logo {
      position: relative;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 23px 10px 15px;

      gap: 0px;
      max-width: 315px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
      border-radius: 5px;
    }

    form {
      margin: 0 auto;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h4 {
        color: white;
      }

      h6 {
        color: white;
      }

      .form-group {
        width: 280px;
      }

      .container-buttons-mobile {
        background: #1f3e74;
        box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 25%);
        height: 65px;
        position: fixed;
        width: 100vw;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      .content-buttons {
        max-width: 85%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 35px;
        margin: 0 auto;
        padding-top: 8px;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
          max-width: 180px;
        }

        .button-return {
          background: white;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 40px;
          max-width: 180px;
        }
      }
    }
  }
`;
