export enum PicturePosition {
  VehicleFront,
  VehicleBack,
  LeftSide,
  RightSide,
  LeftHeadlight,
  RightHeadlight,
  LeftTailLight,
  RightTailLight,
  Inside,
  Upholstery,
  Dashboard,
  Engine,
}
