import styled from "styled-components";

interface ILabelProps {
  disabled?: boolean;
}

export const LabelAsButton = styled.div<ILabelProps>`
  display: flex;
  justify-content: center;

  .form-label {
    margin: 0;
    padding: 6px 18px;
    border: none;
    font-weight: 600;
    border-radius: 50px;
    background: #ffc20e;
    color: #293e6b;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};;
  }

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;
