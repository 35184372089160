import styled from "styled-components";

const ContentWrapper = styled.div`
  position: absolute;
  top: 80px;
  margin-left: 80px;
  padding-right: 10px;
  max-width: calc(100% - 80px);
  min-width: calc(100% - 80px);
  overflow-x: hidden;
  input {
    max-width: 175px;
  }
  .breadcrumb {
    background-color: transparent;
    font-size: 10px;
    line-height: 16px;
  }
`;

const Title = styled.div`
  /* Headline 4 / 16 bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;

  /* Dark */

  color: #4a4a4a;
`;

export { ContentWrapper, Title };
