import React from "react";
import { FooterLine, C4uTrademark } from "./footer.molecule.style";

export const Footer = () => (
  <>
    <FooterLine />
    <C4uTrademark>
      © {new Date().getFullYear()} Cox Automotive - Cox Pra Você. All Rights
      Reserved.
    </C4uTrademark>
  </>
);
