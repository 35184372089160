import { FormikControlAtom, Combo } from "c4u-web-components";
import React, { useEffect, useMemo } from "react";
import { Wrapper } from "./toggle-input.atom.style";
import { useTranslation } from "react-i18next";

interface IProps {
  id: string;
  title?: string;
  form: any;
  checked: boolean;
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
}

export const ToggleInputAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const element = document.getElementsByName(props.id)[0] as HTMLInputElement;
    element.checked = props.checked;
  }, [props.checked, props.id]);

  const toggleOptions = useMemo(() => {
    return [new Combo({ title: t(""), value: 0 })];
  }, [t]);

  return (
    <Wrapper>
      <FormikControlAtom
        formik={props.form}
        property={props.id}
        translate={t}
        type={"checkbox"}
        data={toggleOptions}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
      />
      <span>{props.title}</span>
    </Wrapper>
  );
};
