import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { LoginHelper } from "../../../helper";
import { useC4u, useSessionContext } from "../../../hooks";
import { PermissionEnum } from "c4u-web-components";
import { BackofficeBaseTemplate } from "./backoffice-base.template";

interface IProps {
  protected?: PermissionEnum | PermissionEnum[];
}

export const BackofficeTemplate: React.FC<IProps> = (props) => {
  const { loadUserAuth0Context, userAuth0Context } = useSessionContext();

  const { isLoading, isAuthenticated, loginWithPopup } = useAuth0();

  const history = useHistory();

  const { GetAuth0User } = useC4u();

  useEffect(() => {
    LoginHelper.setCookieLogin();
    if (!isLoading && isAuthenticated && !userAuth0Context) {
      GetAuth0User().then((claims) => {
        loadUserAuth0Context(claims);
      });
    }

    const referrer = (history?.location?.state as any)?.referrer;
    if (!isLoading && isAuthenticated && userAuth0Context && referrer) {
      history.replace(referrer);
    }

    if (!isLoading && !isAuthenticated) {
      loginWithPopup({
        domain: process.env.REACT_APP_AUTH0_C4U_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_C4U_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_C4U_AUDIENCE ?? "",
        scope: process.env.REACT_APP_AUTH0_C4U_SCOPE ?? "",
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated, userAuth0Context, history?.location?.state]);

  return (
    <BackofficeBaseTemplate
      isAuthenticated={isAuthenticated}
      isLoading={isLoading}
      permission={props.protected}
    >
      {props.children}
    </BackofficeBaseTemplate>
  );
};
