import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { renderViewHelper } from "../../../../../helper";

interface IProps {
  status?: string;
}

export const InsuranceProposalStatusDetailsMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  const renderProposalStaus = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props, key, label, func);

  return !props.status ? (
    <i>{t("No information")}</i>
  ) : (
    <Form.Row>{renderProposalStaus("status", "")}</Form.Row>
  );
};
