import { MaskHelper, FormikControlAtom } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { StatesConstant } from "../../../../constants";
import { ZipcodeAddress } from "../../../../models";
import { FormDealershipMoleculeValidation } from "./form-dealership.molecule.validation";
import { useTranslation } from "react-i18next";
import { OptionFontDefined } from "./form-dealership.molecule.style";

interface IProps {
  formId: string;
  onSubmit: (props: IFormDealershipMolecule, errors: any) => Promise<void>;
  values: IFormDealershipMolecule;
  getAddress: (zipcode: string) => Promise<ZipcodeAddress>;
}

export interface IFormDealershipMolecule {
  cnpj: string;
  name: string;
  legalName: string;
  email: string;
  primaryPhone: string;
  secondaryPhone?: string | null;

  zipCode: string;
  street: string;
  number: string;
  complement?: string | null;
  neighborhood: string;
  city: string;
  state: string;
}

export const FormDealershipMolecule: React.FC<IProps> = (props) => {
  const formik = useFormik<IFormDealershipMolecule>({
    initialValues: props.values,
    onSubmit: async (values, { setErrors }) => {
      await props.onSubmit(values, setErrors);
      // formik.setSubmitting(false);
    },
    validationSchema: FormDealershipMoleculeValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [addressZipcode, setAddressZipcode] = useState<ZipcodeAddress>();

  const ZipcodeHandler = async (cep: string) => {
    const address = await props.getAddress(cep);
    formik.setValues({
      ...formik.values,
      city: address.localidade,
      state: address.uf,
      street: address.logradouro,
      neighborhood: address.bairro,
    });
    setAddressZipcode(address);
  };

  useEffect(() => {
    if (
      formik?.values.zipCode &&
      formik?.values.zipCode.replace(/[^\d]/g, "").length === 8
    ) {
      ZipcodeHandler(formik.values.zipCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.zipCode]);

  useEffect(() => {
    if (addressZipcode?.uf) {
      formik.setFieldValue("street", "");
      formik.setFieldValue("neighborhood", "");
      formik.setFieldValue("number", "");
      formik.setFieldValue("complement", "");
      formik.setFieldValue("city", "");
      formik.setFieldValue("state", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.zipCode]);

  const { t } = useTranslation();

  return (
    <Form
      id={props.formId}
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"cnpj"}
          label={t("Cnpj")}
          mask={MaskHelper.Cnpj}
          disabled
          translate={t}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"name"}
          label={t("Dealership Name")}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"legalName"}
          label={t("LegalNameAsInCnpj")}
          translate={t}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"email"}
          label={t("Email")}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"primaryPhone"}
          mask={MaskHelper.Phone}
          label={t("Dealership Phone Primary")}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"secondaryPhone"}
          mask={MaskHelper.Phone}
          label={t("Dealership Phone Secondary")}
          translate={t}
        />
      </Form.Row>
      <hr />
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"zipCode"}
          label={t("Zip Code")}
          mask={MaskHelper.Cep}
          translate={t}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"street"}
          label={t("Street")}
          disabled={!!addressZipcode?.logradouro}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"number"}
          label={t("Number")}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"complement"}
          label={t("Complement")}
          translate={t}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"neighborhood"}
          label={t("Neighborhood")}
          disabled={!!addressZipcode?.bairro}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"city"}
          label={t("City")}
          disabled={!!addressZipcode?.localidade}
          translate={t}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"state"}
          label={t("State")}
          as={"select"}
          disabled={!!addressZipcode?.uf}
          translate={t}
        >
          {StatesConstant.STATES.map((m, i) => (
            <OptionFontDefined key={i} value={m.value}>
              {m.title}
            </OptionFontDefined>
          ))}
        </FormikControlAtom>
      </Form.Row>
    </Form>
  );
};
