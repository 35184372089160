import React from "react";

const MoneyIcon = () => (
  <svg
    width="62"
    height="53"
    viewBox="0 0 62 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.6947 26.481H24.1794C24.182 27.0412 24.0718 27.5963 23.8552 28.1146C23.6387 28.633 23.32 29.1042 22.9174 29.5012C22.5149 29.8982 22.0364 30.2133 21.5095 30.4283C20.9826 30.6433 20.4176 30.7539 19.847 30.7539V42.5189C20.996 42.5189 22.098 42.967 22.9105 43.7648C23.723 44.5625 24.1794 45.6445 24.1794 46.7726H52.3595C56.2625 46.7726 59.0922 43.7835 60.3217 39.5872"
      stroke="#003063"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.9411 37.1343H32.1807"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.8911 37.1343H55.1306"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.661 32.9384C47.5538 33.9851 48.0381 35.3096 48.0271 36.6749C48.0245 37.4448 47.8675 38.2067 47.565 38.9172C47.2626 39.6276 46.8205 40.2726 46.2642 40.8153C45.7079 41.358 45.0481 41.7876 44.3226 42.08C43.5971 42.3723 42.8201 42.5215 42.0359 42.519C40.4555 42.519 38.9395 41.9039 37.8202 40.8085C36.7008 39.713 36.0694 38.2266 36.0642 36.6749C36.0642 35.1198 36.6934 33.6284 37.8133 32.5288C38.9332 31.4292 40.4521 30.8115 42.0359 30.8115C42.5765 30.8117 43.1146 30.8826 43.6361 31.0223"
      stroke="#003063"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.4356 21.1729H12.2751V52.0991H51.3056C56.9065 52.0991 61.0633 48.7076 61.0633 43.2275V11.0366"
      stroke="#003063"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.7397 0.958008H43.6947V32.9381H50.7397C53.4753 33.0076 56.0767 34.1173 57.9953 36.0332C59.9139 37.9492 60.9999 40.5217 61.0242 43.2085V11.0177C61.0037 8.34927 59.9123 5.79642 57.9869 3.91312C56.0615 2.02983 53.4574 0.96802 50.7397 0.958008V0.958008Z"
      stroke="#003063"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.0242 15.8461C61.0242 10.4427 57.6871 8.18164 53.9012 8.18164C53.827 9.12856 53.4141 10.0192 52.7355 10.696C52.0569 11.3728 51.1564 11.7921 50.1933 11.8797V24.4112C51.1711 24.4587 52.0949 24.8653 52.7817 25.5503C53.4685 26.2353 53.8681 27.1486 53.9012 28.1093C55.6185 28.1093 61.0242 30.9261 61.0242 34.9116V15.8461Z"
      stroke="#003063"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.0844 7.35791H30.678"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.5751 7.35791H25.4089"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.306 7.35791H20.1398"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.01604 26.5H6.24487"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.70382 36.0615H1.61978"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.14196 26.5H0.975769"
      stroke="#4B4A4A"
      strokeWidth="1.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.8118 38.8779C43.4763 39.1955 43.0448 39.3973 42.5823 39.4529V40.3342H41.587V39.4719C40.9393 39.4339 40.3163 39.2146 39.7916 38.8397L40.221 37.9582C40.6501 38.2675 41.1552 38.4592 41.6846 38.5139V37.0768C41.3712 36.9981 41.0646 36.8956 40.7674 36.7702C40.5228 36.6539 40.309 36.4833 40.1429 36.272C39.9668 36.0186 39.8779 35.7164 39.8892 35.4097C39.9014 34.9973 40.0763 34.6057 40.3771 34.3175C40.7224 34.003 41.159 33.8021 41.6261 33.7427V32.938H42.6018V33.8194C42.9015 33.858 43.196 33.9287 43.48 34.0302C43.722 34.1279 43.9462 34.2639 44.1436 34.4326L43.7142 35.314C43.3603 35.0428 42.9454 34.8589 42.5042 34.7775V36.2528C42.8114 36.331 43.1117 36.4336 43.402 36.5595C43.6406 36.6679 43.8484 36.8325 44.0069 37.0385C44.1732 37.2813 44.2553 37.5702 44.2411 37.8624C44.2466 38.0667 44.2077 38.2699 44.1269 38.4582C44.0462 38.6465 43.9254 38.8158 43.7728 38.9547L43.8118 38.8779ZM41.2748 35.7738L41.6846 36.0229V34.7966L41.2943 35.0266L41.1382 35.4289L41.2748 35.7738ZM42.9726 37.9199L42.8555 37.6133L42.4847 37.3834V38.533C42.6276 38.5056 42.7556 38.4285 42.8452 38.3159C42.9348 38.2032 42.98 38.0626 42.9726 37.9199Z"
      fill="#4B4A4A"
    />
  </svg>
);

export default MoneyIcon;
