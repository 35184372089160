import { AxiosResponse } from "axios";
import { PagedRequestParamsBaseModel } from "c4u-web-components";
import { useFormik } from "formik";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  IGenericSearchFormik,
  IPagedRequestParamsFormikValuesBaseModel,
  IPagedResponseGenericModel,
} from "../../../../models";
import { PaginatableTableList } from "../../../molecules";

interface IProps<TIParams, TParams, TIPagedRespose, TIResponse> {
  initialValues: TIParams;
  validationSchema: Yup.ObjectSchema<
    TIParams extends object | null | undefined ? any : any,
    object
  >;
  getDataCallback: (v: TParams) => Promise<TIPagedRespose>;
  tableName: string;
  tableHeaders: string[];
  mappingFunction: (m: TIResponse, i: number) => any[];
  RequestClass: { new (p: TIParams): TParams };
  FormComponent: React.FC<IGenericSearchFormik<TIParams, TParams>>;
  downloadAllResultsCallback?: (p: TParams) => Promise<AxiosResponse<BlobPart>>;
  goResetAndUpdate?: boolean;
  reportType: string;
}

export const GenericTableSearchOrganism = <
  TIParams extends IPagedRequestParamsFormikValuesBaseModel,
  TParams extends PagedRequestParamsBaseModel,
  TIPagedRespose extends IPagedResponseGenericModel<TIResponse>,
  TIResponse
>(
  props: PropsWithChildren<
    IProps<TIParams, TParams, TIPagedRespose, TIResponse>
  >
) => {
  const { t } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [listData, setListData] = useState<TIResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();

  const formik = useFormik<TIParams>({
    initialValues: props.initialValues,
    onSubmit: async (values, { setErrors }) => {
      setErrorMessage(undefined);
      try {
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new props.RequestClass(values)
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validationSchema: props.validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = useCallback(async () => {
    formik.setValues(props.initialValues);
    formik.submitForm();
  }, [formik, props.initialValues]);

  const getNewPage = useCallback(
    async (targetPage: number) => {
      const { data, currentPage, totalPages } = await props.getDataCallback(
        new props.RequestClass({
          ...formik.values,
          page: targetPage,
        })
      );
      setListData(data);
      setTotalTablePages(totalPages);
      setCurrentTablePage(currentPage);
    },
    [formik.values, props]
  );

  useEffect(() => {
    formik.submitForm();
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad && props.goResetAndUpdate) {
      resetFormValues();
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goResetAndUpdate]);

  return (
    <>
      <props.FormComponent
        formik={formik}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
        downloadAllResultsCallback={props.downloadAllResultsCallback}
        tableName={props.tableName}
        reportType={props.reportType}
      />

      <PaginatableTableList
        tableName={props.tableName}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={formik.isSubmitting}
        thead={listData === undefined ? [] : props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
