import React from "react";
import { Container } from "./side-menu.template.style";

export const SideMenuTemplate: React.FC = (props) => {
  return (
    <>
      <Container>{props.children}</Container>
    </>
  );
};
