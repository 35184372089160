import React from "react";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../../constants";
import { useProducts } from "../../../../../hooks";
import { DateHelper } from "../../../../../helper";
import {
  InspectionStatusEnum,
  IInspectionViewModel,
} from "../../../../../models";
import { Link } from "react-router-dom";
import { PaginatedTableSearchFormOrganism } from "../../..";

export const KbbInspectionList: React.FC = () => {
  const { t } = useTranslation();
  const { getAllInspectionsKbb } = useProducts();

  return (
    <>
      <PaginatedTableSearchFormOrganism
        tableName={"kbb-inspection-list"}
        getDataCallback={getAllInspectionsKbb}
        statusEnum={InspectionStatusEnum}
        tableHeaders={[
          "#",
          t("VehiclePlate"),
          t("InspectionReportType"),
          t("ClientEmail"),
          t("TransactionDateTime"),
          t("Status"),
          "",
        ]}
        mappingFunction={(m: IInspectionViewModel, i) => [
          m.id,
          m.plate,
          m.inspectionPlanName,
          m.customer.email,
          DateHelper.dateHourFormat(m.insertDate),
          t(InspectionStatusEnum[m.status]),
          <Link
            key={`link${i}`}
            to={paths.backofficeKbbInspectionDetailPage(m.id)}
          >
            {t("Details")}
          </Link>,
        ]}
      />
    </>
  );
};
