import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #f9f9f9;
  padding: 60px 10px 24px 74px;
  filter: blur(10px);
  opacity: 30%;
  pointer-events: none;
  height: 100vh;
  user-select: none;
  overflow-y: hidden;
`;

export const BoxProgrammeRouteComingSoon = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  img {
    width: 13%;
  }
`;

export const RoutesProgramHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const RoutesProgramDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px 8px;
`;

export const RoutesProgramDescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${({ theme }) => theme.colors.BlueNavy};

  max-width: 470px;

  b {
    font-family: inherit;
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
  }

  b.email {
    color: ${({ theme }) => theme.colors.BlueHigh};
  }
`;

export const RoutesProgramDownloadTerms = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  text-align: center;
  padding: 4px 12px;
  height: fit-content;

  white-space: nowrap;

  color: ${({ theme }) => theme.colors.Error};
  background-color: #ffffff;

  box-sizing: border-box;
  border: 1px dashed ${({ theme }) => theme.colors.BlueNavy};
  border-radius: 4px;

  b {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
`;

export const RoutesProgramCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 44px 19px;
`;

export const CardRoutesDashedHR = styled.hr`
  border: 1px dashed #edc46c;
`;

export const CardRoutesFootnote = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  width: 100%;
  text-align: right;

  color: ${({ theme }) => theme.colors.MediumGray};
`;
