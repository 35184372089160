import React from "react";
import { useTranslation } from "react-i18next";
import { ContentDiv } from "./my-earnings-observation.molecule.style";
import {
  MyEarningsSectionTitleDiv,
  MyEarningsMoleculeWrapper,
} from "../my-earnings-shared.style";
import { MyEarningsObservationEntryAtom } from "../../../atoms";

export interface IObservation {
  icon: string;
  iconAltText?: string;
  title: string;
  description: string | string[];
}

interface IProps {
  observations: IObservation[];
  titleBackgroundColor?: string;
  titleColor?: string;
}

export const MyEarningsObservationMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <MyEarningsMoleculeWrapper>
      <MyEarningsSectionTitleDiv
        background={props.titleBackgroundColor}
        color={props.titleColor}
      >
        {t("Observations")}
      </MyEarningsSectionTitleDiv>
      <ContentDiv>
        {props.observations.map((m, i) => (
          <MyEarningsObservationEntryAtom
            key={`observation-entry-${i}`}
            icon={m.icon}
            iconAltText={m.iconAltText}
            title={m.title}
            description={m.description}
          />
        ))}
      </ContentDiv>
    </MyEarningsMoleculeWrapper>
  );
};
