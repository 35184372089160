import styled from "styled-components";

export const BaseTextStyle = styled.div`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const Title = styled(BaseTextStyle)`
  margin-bottom: 50px;

  color: #228855;

  font-weight: bold;
  font-size: 30px;
`;

export const Content = styled(BaseTextStyle)``;

export const ButtonContent = styled.div`
  margin-top: 50px;

  button {
    color: #ffffff;
    background-color: #228855;

    :hover {
      color: #ffffff;
      background-color: #228855;
    }
  }
`;
