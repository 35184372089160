import { CoxIcon } from "c4u-web-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { useManhein } from "../../../../../hooks";
import { IGetEmailTemplateViewModel } from "../../../../../models";
import { EmailTemplatesListTableSearchOrganism } from "./email-templates-list-table-search.organism";

export const EmailTemplatesListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getListEmailTemplate } = useManhein();

  const boolToActive = useCallback(
    (v: boolean) => (v ? t("Active") : t("Inactive")),
    [t]
  );
  const boolToType = useCallback(
    (v: boolean) => (v ? t("Trigger") : t("Journey")),
    [t]
  );

  return (
    <>
      <EmailTemplatesListTableSearchOrganism
        getDataCallback={getListEmailTemplate}
        tableName="email-templates-list"
        tableHeaders={[
          "#",
          t("NameMessageHeader"),
          t("Subject"),
          t("Type"),
          t("ActiveWhatsapp"),
          t("ActiveEmail"),
          t("TimeInHours"),
          t("ReferenceDateName"),
          "",
        ]}
        mappingFunction={(m: IGetEmailTemplateViewModel, i) => [
          m.template,
          m.nameMessage,
          m.title,
          boolToType(m.isTrigger),
          boolToActive(m.activeWhatsapp),
          boolToActive(m.activeEmail),
          m.isTrigger ? null : m.timeInHours,
          m.referenceDateName,
          <Link
            key={`link${i}`}
            to={paths.backofficeEditEmailTemplatePage(m.template)}
            title={t("Edit")}
          >
            <CoxIcon
              name="pencil-edit"
              width={16}
              height={16}
              fillColor="#1F3E74"
            />
          </Link>,
        ]}
      />
    </>
  );
};
