import styled from "styled-components";
import Collapse from "react-bootstrap/esm/Collapse";

interface IProps {
  isOpen?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

const ContentWrapper = styled.div<IProps>`
  width: 100%;
  padding: 0 24px;
  transition: padding 800ms;
  text-align: left;
  ${({ isOpen }) =>
    isOpen &&
    `
    padding: 23px;
  `};

  @media (max-width: 335px) {
    padding: 10px;
  }
`;

const MainWrapper = styled.div<IProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const CollapseStyled = styled(Collapse)`
  .image-gallery-thumbnails-container * {
    position: relative;

    input {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
`;

export { Wrapper, MainWrapper, ContentWrapper, CollapseStyled as Collapse };
