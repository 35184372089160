import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useC4u, useProducts } from "../../../../../hooks";
import {
  InspectionDetailsMolecule,
  InspectionTransactionDetailsMolecule,
  InspectionVehicleDetailsMolecule,
  C4uUserDetailsMolecule,
  DetailsPageSection,
} from "../../../../molecules";
import { WrapperDetails } from "../../../shared/shared.style";
import {
  IInspectionVehicleData,
  IInspectionDetailsData,
  PaymentStatusEnum,
  IInspectionTransactionData,
  IC4uUserData,
} from "../../../../../models";
import { DateHelper } from "../../../../../helper";
import { InspectionCompanyEnum } from "c4u-web-components";

export const DetailsC4uInspectionOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getInspectionDetails, getInspectionReportLinks } = useProducts();
  const { GetDealershipByUser } = useC4u();

  const [userDetails, setUserDetails] = useState<IC4uUserData>();
  const [vehicleDetails, setVehicleDetails] =
    useState<IInspectionVehicleData>();
  const [inspectionDetails, setInspectionDetails] =
    useState<IInspectionDetailsData>();
  const [transactionDetails, setTransactionDetails] =
    useState<IInspectionTransactionData>();

  const getInspectionDetailsAsync = useCallback(() => {
    getInspectionDetails(id)
      .then(async (data) => {
        const customerData = await GetDealershipByUser(data.customer.email);
        return {
          data,
          customerData,
        };
      })
      .then(({ data, customerData }) => {
        const {
          id,
          insertDate,
          inspectionDate,
          inspectionPlaceAddress,
          inspectionPlaceName,
          inspectionPlanName,
          partnerId,
          plate,
          renavam,
          reportLink,
          status,
          vehicleName,
        } = data;
        setUserDetails(customerData);
        setVehicleDetails({
          name: vehicleName ?? "",
          plate: plate ?? "",
          renavam: renavam ?? "",
        });
        setInspectionDetails({
          inspectionDate: inspectionDate,
          inspectionPlaceAddress: inspectionPlaceAddress ?? "",
          inspectionPlaceName: inspectionPlaceName ?? "",
          reportType: inspectionPlanName ?? "",
          partner: InspectionCompanyEnum[partnerId],
          reportLink: reportLink?.length ? reportLink : [],
        });
        setTransactionDetails({
          protocol: id,
          status: t(PaymentStatusEnum[status]),
          dateTime: DateHelper.dateHourFormat(insertDate),
        });
      });
  }, [getInspectionDetails, GetDealershipByUser, t, id]);

  useEffect(() => {
    getInspectionDetailsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperDetails>
      <DetailsPageSection
        title={t("UserInformationDetails")}
        classNames={["user-data"]}
      >
        <C4uUserDetailsMolecule userDetails={userDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("VehicleInformationDetais")}
        classNames={["vehicle-data"]}
      >
        <InspectionVehicleDetailsMolecule vehicleDetails={vehicleDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("InspectionInformationDetais")}
        classNames={["inspection-data"]}
      >
        <InspectionDetailsMolecule
          inspectionDetails={inspectionDetails}
          getInspectionReportLinks={getInspectionReportLinks}
          id={id}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TransactionInformationDetais")}
        classNames={["transaction-data"]}
      >
        <InspectionTransactionDetailsMolecule
          transactionDetails={transactionDetails}
        />
      </DetailsPageSection>
    </WrapperDetails>
  );
};
