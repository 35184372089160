import React from "react";
import { useTranslation } from "react-i18next";
import { ApiUsersListOrganism } from "../../../../organisms";
import { StyledA } from "./api-users-list.page.style";

export const ApiUsersListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("ApiUserListing")}</h3>

      <div className="mt-3 mb-3">
        <StyledA
          href={process.env.REACT_APP_KBB_URL_SWAGGER}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("LinkSwaggerDocumentation")}
        </StyledA>
      </div>

      <ApiUsersListOrganism />
    </>
  );
};
