import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../../..";
import { renderViewHelper, StringHelper } from "../../../../../../helper";
import { IInsuranceCustomerDetails } from "../../../../../../models";

interface IProps {
  customerDetails?: IInsuranceCustomerDetails;
}

export const TrackerCustomerDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewCustomer = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.customerDetails, key, label, func);

  return !props.customerDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewCustomer("customerId", t("CustomerId"))}
        {renderViewCustomer("name", t("Name"))}
        {renderViewCustomer("document", t("Cpf"), StringHelper.formatCpfCnpj)}
        {renderViewCustomer("gender", t("Gender"))}
        {renderViewCustomer("maritalStatus", t("MaritalStatus"))}
        {renderViewCustomer(
          "cellphone",
          t("PhoneNumber"),
          StringHelper.formatPhone
        )}
        {renderViewCustomer("email", t("Email"))}
        {renderViewCustomer("birthdate", t("Birthdate"))}
      </Form.Row>
      <DetailsPageSubsection title={t("TrackerCustomerAddress")}>
        <Form.Row>
          {renderViewCustomer("street", t("Street"))}
          {renderViewCustomer("number", t("Number"))}
          {renderViewCustomer("complement", t("Complement"))}
          {renderViewCustomer("neighborhood", t("Neighborhood"))}
          {renderViewCustomer(
            "zipCode",
            t("ZipCode"),
            StringHelper.formatZipCodeBR
          )}
          {renderViewCustomer("city", t("City"))}
          {renderViewCustomer("state", t("State"))}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
