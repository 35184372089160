import { ButtonPrimary, MaskHelper } from "c4u-web-components";
import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  AdModel,
  INotifyBuyerPaymentFormData,
  NotifyBuyerPaymentWinnerRequest,
} from "../../../../../models";
import { useManheinWebhook, useZipcodeAddress } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";
import { FormikControlAtom } from "../../../../atoms";
import { AdNotifyBuyerPaymentValidation } from "./ad-notify-buyer-payment.molecule.validation";

interface IProps {
  enabled: boolean;
  unicCode: string;
  setException: React.Dispatch<any>;
  adData?: AdModel;
}
export const AdNotifyBuyerPaymentMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { webhookNotifyBuyerPayment } = useManheinWebhook();

  const initialValues: INotifyBuyerPaymentFormData = {
    name: props.adData?.buyerName ?? "",
    document: props.adData?.buyerDocument ?? "",
    email: props.adData?.buyerEmail ?? "",
    street: props.adData?.buyerStreet ?? "",
    number: props.adData?.buyerNumber ?? "",
    complement: props.adData?.buyerComplement ?? "",
    neighborhood: props.adData?.buyerNeighborhood ?? "",
    city: props.adData?.buyerCity ?? "",
    state: props.adData?.buyerState ?? "",
    zipCode: props.adData?.buyerZipCode ?? "",
  };

  const formik = useFormik<INotifyBuyerPaymentFormData>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await webhookNotifyBuyerPayment(
            props.unicCode,
            new NotifyBuyerPaymentWinnerRequest(values)
          );
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e?.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e?.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: AdNotifyBuyerPaymentValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const { getAddressByZipcode } = useZipcodeAddress();

  const ZipcodeHandler = async (cep: string) => {
    const address = await getAddressByZipcode(cep);
    if (address) {
      formik.setValues({
        ...formik.values,
        city: address.localidade,
        state: address.uf,
        street: address.logradouro,
        neighborhood: address.bairro,
      });
    }
  };

  useEffect(() => {
    if (formik?.values.zipCode?.replace(/[^\d]/g, "").length === 8)
      ZipcodeHandler(formik.values.zipCode);
  }, [formik?.values.zipCode]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={4}
          property={"name"}
          label={"Nome"}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={4}
          property={"document"}
          label={"CNPJ"}
          mask={MaskHelper.Cnpj}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={4}
          property={"email"}
          label={"Email"}
          disabled={!props.enabled}
        />
      </Form.Row>
      <hr />
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"zipCode"}
          label={"Zip Code"}
          mask={MaskHelper.Cep}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"street"}
          label={"Rua"}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"number"}
          label={"Número"}
          disabled={!props.enabled}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"complement"}
          label={"Complemento"}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"neighborhood"}
          label={"Bairro"}
          disabled={!props.enabled}
        />
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"city"}
          label={"Cidade"}
          disabled={!props.enabled}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={3}
          property={"state"}
          label={"Estado"}
          disabled={!props.enabled}
        />

        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Notify payment")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
