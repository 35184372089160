import styled, {css} from "styled-components";
import { opacify } from 'polished';

const MediaQuery = css`
  @media (min-width: 73px) {
    display: initial !important;
  }

`

const Container = styled.div`
  padding-top: 10px;
  height: 100%;
`;

const Profile = styled.div`
  position: relative;
`;

const ProfileImg = styled.img`
  margin: 20px auto 10px;
  display: block;

  width: 40px;
  height: 40px;
`;

const ExpandedProfileImgButton = styled.div`
  display: none;
  ${Container}:hover & {
    display: initial !important;
  }
  ${MediaQuery}
`;

const ProfileCamIcon = styled.button`
  display: block;

  width: 100%;
  margin: 0 auto 26px;

  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  left: 50%;

  background: url("/camera.svg") no-repeat 50% 50%;
  background-color: ${props => props.theme.colors.white};;

  border: 2px solid ${props => props.theme.colors.LapisLazul};
  border-radius: 100px;

  width: 21px;
  height: 21px;
`;

const ProfileButtonImg = styled.img`
  padding-left: 5px;
`;

const ProfileButtonName = styled.button`
  display: block;

  width: 100%;
  margin: 0 auto 26px;

  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.ContextCard.BorderLeft};
`;

const ProfileButtonExit = styled.button`
  display: block;

  width: 100%;
  margin: 0 auto 26px;

  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: ${props => props.theme.colors.white};
`;

const MenuButton = styled.div`
  margin-top: 76px;
`;

const InnerButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.white};;
  text-align: left;

  height: 40px;
  width: calc(100% - 16px);

  margin: 0 8px 24px;
  padding: 0;
  border: 0;

  display: block;

  display: flex;
  align-items: center;

  outline: none;
  border-radius: 4px;

  &:hover {
    background: ${props => opacify(0.4, props.theme.colors.BlueNavy)};
  }
`;

const ImageWrapper = styled.div`
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background-color: ${props => opacify(0.6, props.theme.colors.BlueNavy)};
  }
`;

const ButtonText = styled.span`
  padding-left: 15px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;

  text-transform: capitalize;
`;

const ExpandedButton = styled.div`
  display: none;
  ${Container}:hover & {
    display: initial !important;
  }
  ${MediaQuery}
`;

export {
  Container,
  MenuButton,
  InnerButton,
  ImageWrapper,
  ButtonText,
  ExpandedButton,
  Profile,
  ProfileImg,
  ProfileCamIcon,
  ProfileButtonImg, ProfileButtonName, ProfileButtonExit,
  ExpandedProfileImgButton,
};
