import { YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  CorporateUserTypeEnum,
  ICreateCorporateUserRequest,
} from "../../../../../models";

export const CorporateUserDetailsValidationSchema = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isMobilePhoneValid",
    YupHelper.isMobilePhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isNameValid",
    YupHelper.isNameValid
  );

  const msgRequired = t("field_required");

  const validations = Yup.object<ICreateCorporateUserRequest>({
    id: Yup.number(),
    name: Yup.string()
      .required(msgRequired)
      .isNameValid(t("field_invalid_name")),
    email: Yup.string()
      .required(msgRequired)
      .email(t("field_invalid_email"))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("field_invalid_email")),
    cpf: Yup.string().required(msgRequired).isCpfValid(t("cpf_invalid")),
    cellPhone: Yup.string()
      .required(msgRequired)
      .isPhoneValid(t("field_invalid_mobile_phone")),
    corporateUserType:
      Yup.number<CorporateUserTypeEnum>().required(msgRequired),
    active: Yup.boolean().required(msgRequired),
    corporateDealerships: Yup.array()
      .of(Yup.object<any>())
      .ensure()
      .notRequired(),
    currentDealershipSelection: Yup.number().test(
      "check-at-least-one-dealership",
      t("Assign at least one dealership"),
      function (value) {
        return (
          this.parent.corporateUserType === CorporateUserTypeEnum.Manager ||
          this.parent.corporateDealerships.length > 0
        );
      }
    ),
  });

  return validations;
};
