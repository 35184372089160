import styled from "styled-components";

interface DatailsProps {
  close: boolean;
}

const Wrapper = styled.div`
  border: 1px solid rgba(21, 49, 68, 0.33);
  border-radius: 5px;

  margin: 10px 0;
  padding: 10px;
`;

const WrapperDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  margin: auto 15px 0 15px;
`;

const Details = styled.div<DatailsProps>`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) =>
    props.close ? props.theme.colors.Grey74 : props.theme.colors.DarkBlue};
  text-transform: uppercase;
  margin: 0 8px 0 0;
`;

export { Wrapper, WrapperDetails, Details };
