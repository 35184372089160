import { TypesHelper } from "c4u-web-components";
import { AdStepEnum } from "../..";

export interface IPostAdStepRequest {
  id: number;
  step: AdStepEnum;
  stepStatus: number;
  createdAt: string;
}

export class PostAdStepRequest {
  id: number | null;
  step: AdStepEnum | null;
  stepStatus: number | null;
  createdAt: Date | null;
  constructor({ id, step, stepStatus, createdAt }: IPostAdStepRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.step = TypesHelper.toNumberNull(step);
    this.stepStatus = TypesHelper.toNumberNull(stepStatus);
    this.createdAt = TypesHelper.toDateTimeNull(createdAt);
  }
}
