/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.GhostWhite};
  }

  body, input, button {
    font: 1rem 'Open Sans', sans-serif;
  }

  html, body {
    height: 100%;
  }

  div#root {
    height: 100%;
  }

  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }

  .form-default {
    .form-label {
      font-family: ${(props) => props.theme.fontFamily.OpenSans};
      font-size: 14px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.Dark};
      line-height: 22px;
      min-height: 17px;
    }
    .form-control {
      height: 40px;
      border-radius: 4px;
    }
    .form-control:not(.is-invalid) {
      background-color: ${(props) => props.theme.colors.GhostWhite};
      border: 1px solid ${(props) => props.theme.colors.DarkGray};
    }
    .form-control:disabled {
      background-color: ${(props) => props.theme.colors.DarkGrayOpacity};
    }
  }
`;
