import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  DealershipReportPagedParams,
  IDealershipReport,
  IDealershipReportPagedResponse,
} from "../../../../../models";
import { IDealershipReportResponse } from "../../../../../models/apis/c4u/dealership-report.request";
import { FormikControlAtom } from "../../../../atoms";
import { PaginatableTableList } from "../../../../molecules";

interface IProps {
  getDataCallback: (
    values: DealershipReportPagedParams
  ) => Promise<IDealershipReportPagedResponse>;
  tableHeaders: string[];
  mappingFunction: (m: IDealershipReport, i: number) => any[];
}

export const DealershipsReportListTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [listData, setListData] = useState<IDealershipReport[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [firstFetch, setFirstFetch] = useState(true);

  const initialValues: IDealershipReportResponse = {
    pageSize: 50,
    page: 1,
  };

  const formik = useFormik<IDealershipReportResponse>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new DealershipReportPagedParams(values)
        );
        setListData(data);

        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
        setDataIsLoading(false);
        setFirstFetch(false);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new DealershipReportPagedParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    if (formik.values.page && !firstFetch) {
      getNewPage(formik.values.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.pageSize]);

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <FormikControlAtom
        formik={formik}
        as={"select"}
        sm={5}
        md={3}
        property={"pageSize"}
        label={t("RecordsPerPage")}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </FormikControlAtom>

      <Col>
        <PaginatableTableList
          tableName={"daelership-list"}
          pagination={{
            currentPage: currentTablePage,
            lastPage: totalTablePages,
            getPageCallback: getNewPage,
          }}
          dataIsLoading={dataIsLoading && !errorMessage}
          thead={props.tableHeaders}
          tbody={listData?.map(props.mappingFunction)}
        />
      </Col>
    </Row>
  );
};
