import React from "react";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { useKbbBackoffice } from "../../../../../hooks";
import {
  IGetRequestingVehicleChangesResponse,
  DecoderReviewStatusEnum,
} from "../../../../../models";
import { ListRequestsVehicleChangesOrganism } from "../../../../organisms";

export const ListRequestsVehicleChangesPage: React.FC = () => {
  const { t } = useTranslation();
  const { getListReview } = useKbbBackoffice();

  return (
    <>
      <h3>{"Listagem das solicitações"}</h3>

      <div className="mt-3 mb-3"></div>

      <ListRequestsVehicleChangesOrganism
        getDataCallback={getListReview}
        tableName="api-user-list"
        tableHeaders={[
          "#",
          t("Plate"),
          t("CoxUserEmail"),
          t("DateRequesting"),
          t("LastUpdateReview"),
          t("Status"),
        ]}
        mappingFunction={(m: IGetRequestingVehicleChangesResponse, i) => [
          m.id,
          m.plate,
          m.email,
          DateHelper.dateFormat(m.createdAt),
          m.updatedAt === null ? "-" : DateHelper.dateFormat(m.updatedAt),
          Number(m.status) === DecoderReviewStatusEnum.Pending
            ? t("Pending")
            : t("Done"),
        ]}
      />
    </>
  );
};
