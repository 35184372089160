import { ButtonTertiary, CoxIcon } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInsurance } from "../../../../hooks";
import {
  IGetDiscoverMockResponse,
  IPostDiscoverMockRequest,
} from "../../../../models";
import {
  DtMockDiscoverDeleteModalMolecule,
  DtMockDiscoverEditModalMolecule,
  FeedbackModal,
  PaginatableTableList,
} from "../../../molecules";
import { ClicableDiv } from "../../shared/shared.style";

enum TesteEnum {
  Creditas = "Q1JFRElUQVM=",
  Daycoval = "REFZQ09WQUw=",
  Itau = "SXRhdQ==",
}

export const DtMockDiscoverOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { deleteDiscoverMock, getDiscoverMock, postDiscoverMock } =
    useInsurance();

  const [dataIsLoading, setDataIsLoading] = useState<boolean>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exception, setException] = useState<any>();

  const [creditApplications, setCreditApplications] =
    useState<IGetDiscoverMockResponse>();
  const [selectedCreditApplication, setSelectedCreditApplication] =
    useState<IPostDiscoverMockRequest>();
  const [selectedId, setSelectedId] = useState<number>();

  const reloadList = useCallback(() => {
    setCreditApplications(undefined);
    setDataIsLoading(true);
    getDiscoverMock()
      .then((t) => setCreditApplications(t))
      .catch(() => {
        setCreditApplications([]);
        setException(t("GenericApiErrorFetchingData"));
        setShowErrorModal(true);
      })
      .finally(() => setDataIsLoading(false));
  }, [getDiscoverMock, t]);

  useEffect(() => {
    reloadList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getEditButton = useCallback(
    (i: number) => (
      <ClicableDiv
        className="text-center w-100"
        key={`edit-${i}`}
        onClick={
          !creditApplications
            ? undefined
            : () => {
                setSelectedCreditApplication(creditApplications[i]);
                setSelectedId(i + 1);
                setShowEditModal(true);
              }
        }
        title={t("Edit")}
      >
        <CoxIcon
          name="pencil-edit"
          width={16}
          height={16}
          fillColor="#1F3E74"
        />
      </ClicableDiv>
    ),
    [creditApplications, t]
  );

  const getDeleteButton = useCallback(
    (i: number) => (
      <ClicableDiv
        className="text-center w-100"
        key={`delete-${i}`}
        onClick={
          !creditApplications
            ? undefined
            : () => {
                setSelectedCreditApplication(creditApplications[i]);
                setSelectedId(i + 1);
                setShowDeleteModal(true);
              }
        }
        title={t("Edit")}
      >
        <CoxIcon name="trash" width={16} height={16} fillColor="#C4390A" />
      </ClicableDiv>
    ),
    [creditApplications, t]
  );

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedCreditApplication(undefined);
    setSelectedId(undefined);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
    setSelectedCreditApplication(undefined);
    setSelectedId(undefined);
  }, []);

  const getLenderCodeDescription = useCallback((code?: string) => {
    const entry = Object.entries(TesteEnum).find(([_, v]) => v === code);
    if (entry) return `${entry[0]} (${entry[1]})`;
    return "-";
  }, []);

  return (
    <div className="mt-3 mx-lg-5 px-md-5">
      <div className="text-right mb-3">
        <ButtonTertiary
          sizex="md"
          onClick={() => {
            setSelectedCreditApplication(undefined);
            setShowEditModal(true);
          }}
        >
          {t("NewEntry")}
        </ButtonTertiary>
      </div>
      <PaginatableTableList
        tableName="api-parameters-list"
        dataIsLoading={dataIsLoading}
        thead={["#", t("PublicPersonId"), t("LenderCode"), t("StepDt"), "", ""]}
        tbody={creditApplications?.map((m, i) => [
          i + 1,
          m.publicPersonId,
          getLenderCodeDescription(m.encodeLenderCode),
          m.step,
          getEditButton(i),
          getDeleteButton(i),
        ])}
      />

      <DtMockDiscoverEditModalMolecule
        id={selectedId}
        show={showEditModal}
        data={selectedCreditApplication}
        onHide={onCloseEditModal}
        onSaveCallback={postDiscoverMock}
        onSaveSuccess={() => {
          onCloseEditModal();
          reloadList();
        }}
        setException={setException}
        setShowErrorModal={setShowErrorModal}
        newEntry={selectedCreditApplication === undefined}
      />

      <DtMockDiscoverDeleteModalMolecule
        id={selectedId}
        show={showDeleteModal}
        data={selectedCreditApplication}
        onHide={onCloseDeleteModal}
        onDeleteCallback={deleteDiscoverMock}
        onDeleteSuccess={() => {
          onCloseDeleteModal();
          reloadList();
        }}
        setException={setException}
        setShowErrorModal={setShowErrorModal}
      />

      <FeedbackModal
        type="error"
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setException(undefined);
        }}
        content={exception ? exception : t("api_error")}
      />
    </div>
  );
};
