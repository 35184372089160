import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  BenefitsContent,
  Content,
  HighlightText,
  Link,
  NoteText,
  Title,
} from "./about-itau.organism.style";

export const AboutItau = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Title>{t("AboutItau")}</Title>
      <Content>
        <Trans>
          {t("SpeedItau")}
          {t("InSpeed")}
          {t("HereAtItau")}
          {t("CheckTheBenefits")}
          <BenefitsContent>
            <div>{t("Up2percent")}</div>
            <div>{t("ProgramPoints")}</div>
            <div>{t("PortalAdvantages")}</div>
          </BenefitsContent>
          {t("WantToKnowMore")}
          <Link href='https://programaspeed.itau.com.br/'>
            www.programaspeed.itau.com.br
          </Link>
          <HighlightText>
            {t("ItauVehicles")}
            {t("OurBusiness")}
          </HighlightText>
          <NoteText>{t("theDelivery")}</NoteText>
        </Trans>
        {/* <ButtonContent>
          <ButtonPrimary>{t("ClickToDownloadMaterialForItau")}</ButtonPrimary>
        </ButtonContent> */}
      </Content>
    </React.Fragment>
  );
};
