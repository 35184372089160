import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { TitleWithBackButtonAtom } from "../../../atoms";
import { LenderOperatorDetailsOrganism } from "../../../organisms";

export const LenderOperatorDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("LenderOperatorDetail")}
        onClick={() => push(paths.backofficeLenderOperatorListUsers())}
      />

      <LenderOperatorDetailsOrganism />
    </>
  );
};
