import { Col } from "react-bootstrap";
import styled from "styled-components";

const WrapperAd = styled.div`
  .section {
    margin-top: 20px;
    margin-bottom: 60px;
  }
`;

const HorizontalScrollDiv = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const FeedbackModalContent = styled.div`
  max-width: 450px;
  overflow-x: scroll;
`;

const EmailTemplatePageSection = styled.div`
  margin: 12px 0;

  &.active-toggle {
    display: flex;
    align-items: center;
    .col {
      margin: 0;
      padding: 0;
    }
  }

  .section-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

const EmailTemplateDetailsWrapper = styled.div`
  .body-whatsapp textarea {
    height: 196px;
  }
  .body-email textarea {
    height: 120px;
  }
`;

const StyledLabelCol = styled(Col)`
  height: 33.5px;
  display: flex;
  align-items: center;
`;

const VisibilityIconDiv = styled.div`
  cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
`;

export {
  HorizontalScrollDiv,
  WrapperAd,
  FeedbackModalContent,
  EmailTemplatePageSection,
  EmailTemplateDetailsWrapper,
  StyledLabelCol,
  VisibilityIconDiv,
};
