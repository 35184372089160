import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useC4u, useInsurance } from "../../../../hooks";
import {
  DetailsPageSection,
  InsuranceCancelProposalMolecule,
  InsuranceCustomerDetailsMolecule,
  InsuranceProposalDetailsMolecule,
  InsuranceDocumentDetailsMolecule,
  InsuranceInfoDetailsMolecule,
  InsurancePepDetailsMolecule,
  InsuranceStepsHistoryDetailsMolecule,
  InsuranceVehicleDetailsMolecule,
  InsuranceProposalStatusDetailsMolecule,
} from "../../../molecules";
import { WrapperDetails } from "../../shared/shared.style";
import {
  AddressTypeEnum,
  DocumentTypeEnum,
  GenderEnum,
  IHistoricStepReportDetail,
  IInsuranceCustomerDetails,
  IInsuranceProposalDetails,
  IInsuranceDocumentDetails,
  IInsuranceInfoDetails,
  IInsurancePepDetails,
  InsuranceCompanyEnum,
  IVehicleReportDetail,
  MaritalStatusEnum,
  NationalityEnum,
  PoliticallyExposedPersonEnum,
  RelationshipTypeEnum,
  ProposalStepEnum,
} from "../../../../models";

export const InsuranceDetailOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getInsuranceReportDetail, getInsuranceProposalStatus } =
    useInsurance();
  const { GetDealershipByUser } = useC4u();

  const [customerDetails, setCustomerDetails] =
    useState<IInsuranceCustomerDetails>();
  const [vehicleDetails, setVehicleDetails] = useState<IVehicleReportDetail>();
  const [pepDetails, setPepDetails] = useState<IInsurancePepDetails>();
  const [documentDetails, setDocumentDetails] =
    useState<IInsuranceDocumentDetails>();
  const [insuranceInfoDetails, setInsuranceInfoDetails] =
    useState<IInsuranceInfoDetails>();
  const [proposalDetails, setProposalDetails] =
    useState<IInsuranceProposalDetails>();
  const [stepsHistoryDetails, setStepsHistory] =
    useState<IHistoricStepReportDetail[]>();
  const [currentStep, setCurrentStep] = useState<number>();
  const [proposalStatus, setProposalStatus] = useState<string>();

  const getInsuranceDetailsAsync = useCallback(() => {
    getInsuranceReportDetail(id).then((data) => {
      const {
        customer,
        politicallyExposed,
        document,
        historics,
        ...remainingInfoDetails
      } = data.detail;
      const {
        gender,
        maritalStatus,
        nationalityType,
        addressType,
        ...remainingCustomerData
      } = customer;
      const {
        politicallyExposedPerson,
        politicallyExposedRelativeRelationshipType,
        politicallyExposedRelativeAddressType,
        ...remainingPepDetails
      } = politicallyExposed;
      const { createdAt, policy } = data;
      const { identityType, ...remainingDocDetails } = document;
      const { email: userEmail } = data.user ?? {};

      setInsuranceInfoDetails({
        ...remainingInfoDetails,
        policy,
        company: t(InsuranceCompanyEnum[data.company]),
      });
      setCustomerDetails({
        ...remainingCustomerData,
        gender: t(GenderEnum[gender]),
        maritalStatus: t(MaritalStatusEnum[maritalStatus]),
        nationalityType: t(NationalityEnum[nationalityType]),
        addressType: t(AddressTypeEnum[addressType]),
        name: data?.name ?? "",
        cpfCnpj: data?.cpfCnpj ?? "",
      });
      setVehicleDetails(data.vehicle);
      setPepDetails({
        ...remainingPepDetails,
        politicallyExposedPerson: t(
          PoliticallyExposedPersonEnum[politicallyExposedPerson]
        ),
        politicallyExposedRelativeRelationshipType: t(
          RelationshipTypeEnum[politicallyExposedRelativeRelationshipType]
        ),
        politicallyExposedRelativeAddressType: t(
          AddressTypeEnum[politicallyExposedRelativeAddressType]
        ),
        isCloseRelative:
          politicallyExposedPerson === PoliticallyExposedPersonEnum.Relative,
      });
      setDocumentDetails({
        ...remainingDocDetails,
        identityType: t(DocumentTypeEnum[identityType]),
      });
      setStepsHistory(historics);

      if (historics && historics.length > 0)
        setCurrentStep(historics[historics.length - 1].step);

      GetDealershipByUser(userEmail ?? "").then(({ name }) => {
        setProposalDetails({
          ...data.user,
          createdAt,
          dealershipCnpj: data.dealership.cnpj,
          dealershipName: name,
        });
      });
    });
    getInsuranceProposalStatus(id).then((status) => setProposalStatus(status));
  }, [
    getInsuranceReportDetail,
    getInsuranceProposalStatus,
    GetDealershipByUser,
    t,
    id,
  ]);

  useEffect(() => {
    getInsuranceDetailsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperDetails>
      <DetailsPageSection
        title={t("InsuranceCustomerDetails")}
        classNames={["insurance-customer-data"]}
      >
        <InsuranceCustomerDetailsMolecule customerDetails={customerDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("InsuranceVehicleDetails")}
        classNames={["insurance-vehicle-data"]}
      >
        <InsuranceVehicleDetailsMolecule vehicleDetails={vehicleDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("InsuranceInfoDetails")}
        classNames={["insurance-info-data"]}
      >
        <InsuranceInfoDetailsMolecule infoDetails={insuranceInfoDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("PoliticallyExposedPerson")}
        classNames={["politically-exposed-person-data"]}
      >
        <InsurancePepDetailsMolecule pepDetails={pepDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("DocumentDetails")}
        classNames={["document-data"]}
      >
        <InsuranceDocumentDetailsMolecule documentDetails={documentDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("InsuranceProposalDetails")}
        classNames={["insurance-dealership-data "]}
      >
        <InsuranceProposalDetailsMolecule proposalDetails={proposalDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("InsuranceStepsHistory")}
        classNames={["insurance-steps-history-data"]}
      >
        <InsuranceStepsHistoryDetailsMolecule
          stepsHistoryDetails={stepsHistoryDetails}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={
          t("InsuranceProposalStatus") +
          (insuranceInfoDetails ? ` - ${insuranceInfoDetails.company}` : "")
        }
        classNames={["insurance-proposal-status-data"]}
      >
        <InsuranceProposalStatusDetailsMolecule status={proposalStatus} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("CalcelInsuranceProposal")}
        classNames={["cancel-insurance-proposal-data"]}
      >
        <InsuranceCancelProposalMolecule
          customerId={id}
          disabled={[
            undefined,
            ProposalStepEnum.Cancelled,
            ProposalStepEnum.PaidOutAfterExpired,
          ].includes(currentStep)}
        />
      </DetailsPageSection>
    </WrapperDetails>
  );
};
