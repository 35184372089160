/* eslint-disable jsx-a11y/iframe-has-title */
import { ButtonSecondary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useC4u, useSessionContext, useTermsOfUse } from "../../../../hooks";
import { CoxAutomotive } from "../../../assets";
import {
  AcceptedContainer,
  CheckedContainer,
  ConfirmedContainer,
  Container,
  ContentChecked,
  ImageContainer,
  LoadingContainer,
  LoadingText,
  PDFContainer,
} from "./terms-of-use.molecule.style";

import { useFormik } from "formik";
import { ApplicationTermEnum, TypeTermEnum } from "../../../../models";
import { FormikControlAtom } from "../../../atoms";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export const TermsOfUserMolecule = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { getDocument } = useTermsOfUse();
  const { postAcceptTerms } = useC4u();
  const { userContext, termsOfUse } = useSessionContext();

  const [pdfDocument, setPdfDocument] = useState<string | null>(null);
  const [acceptedPdfDocument, setAcceptedPdfDocument] = useState(false);

  const formik = useFormik({
    initialValues: {
      terms: acceptedPdfDocument,
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        setAcceptedPdfDocument(true);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handlePdfDocument = useCallback(async () => {
    try {
      const documentBlob = await getDocument("termofuse.pdf");
      const documentUrl = URL.createObjectURL(documentBlob!);

      setPdfDocument(documentUrl);
    } catch (error) {
      console.log(error);
    }
  }, [getDocument]);

  const handleAcceptTerms = useCallback(() => {
    postAcceptTerms({
      customerId: userContext?.email,
      applicationId: ApplicationTermEnum.CoxPraVoce,
      documentId: TypeTermEnum.TermOfUse,
      version: termsOfUse.latestVersion,
    }).then(() => {
      history.push(paths.home());
    });
  }, [history, postAcceptTerms, termsOfUse?.latestVersion, userContext?.email]);

  useEffect(() => {
    handlePdfDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <ImageContainer>
          <img src={CoxAutomotive} alt="" />
        </ImageContainer>
        <PDFContainer>
          {pdfDocument ? (
            <iframe src={pdfDocument} />
          ) : (
            <LoadingContainer>
              <Spinner animation="border" />
              <LoadingText>{t("Loading") + "..."}</LoadingText>
            </LoadingContainer>
          )}
        </PDFContainer>
        <AcceptedContainer>
          <CheckedContainer>
            <FormikControlAtom
              formik={formik}
              type="checkbox"
              disabled={acceptedPdfDocument}
              name="terms"
              id="termsOfUser"
              md={3}
              property=""
            />
            <ContentChecked>
              <label>Li e aceito os termos</label>
            </ContentChecked>
          </CheckedContainer>
          <ConfirmedContainer>
            {formik.values.terms && (
              <ButtonSecondary onClick={handleAcceptTerms}>
                Confirmar
              </ButtonSecondary>
            )}
          </ConfirmedContainer>
        </AcceptedContainer>
      </Container>
    </>
  );
};
