import { TypesHelper } from "c4u-web-components";
import { IAddress } from "./../../../hooks/apis/use-c4u/interfaces/interfaces";
import { AddressRequest } from "./address.request";

export interface IDealershipUpdateByUserRequest {
  id: number;
  address: IAddress;
  name: string;
  legalName: string;
  email: string;
  primaryPhone: string;
  secondaryPhone: string;
}

export class DealershipUpdateByUserRequest {
  id: number | null;
  name: string | null;
  legalName: string | null;
  email: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  address: AddressRequest | null;

  constructor({
    id,
    name,
    legalName,
    email,
    primaryPhone,
    secondaryPhone,
    address,
  }: IDealershipUpdateByUserRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.name = TypesHelper.toStringNull(name);
    this.legalName = TypesHelper.toStringNull(legalName);
    this.email = TypesHelper.toStringNull(email);
    this.primaryPhone = TypesHelper.toStringOnlyNumbersNull(primaryPhone);
    this.secondaryPhone = TypesHelper.toStringOnlyNumbersNull(secondaryPhone);
    this.address = new AddressRequest(address);
  }
}
