import { useAuth0 } from "@auth0/auth0-react";
import { StorageConstant } from "c4u-web-components";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useC4u, useSessionContext } from "../../../hooks";
import { TermsOfUserMolecule } from "../../molecules";
import { C4UTitle, Wrapper } from "./terms.template.style";
import { ApplicationTermEnum, TypeTermEnum } from "../../../models";

export const TermsTemplate: React.FC = () => {
  const { setUserContext, termsOfUse, userContext, setTermsOfUse } =
    useSessionContext();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const history = useHistory();

  const { GetUser, getCustomerTerms } = useC4u();

  useEffect(() => {
    if (termsOfUse?.accepted) {
      history.push(paths.home());
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
      sessionStorage.setItem(StorageConstant.redirectedFromLogin, "true");
    } else if (!isLoading && isAuthenticated) {
      GetUser().then((t) => {
        setUserContext(t);
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const getCustomerTermsAsync = useCallback(async () => {
    const items = await getCustomerTerms(
      ApplicationTermEnum.CoxPraVoce,
      TypeTermEnum.TermOfUse,
      userContext.email
    );
    if (items.accepted === false) {
      history.push(paths.termsOfUse());
    }
    setTermsOfUse(items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerTerms, userContext]);

  useEffect(() => {
    if (isAuthenticated && userContext) {
      getCustomerTermsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userContext]);

  return (
    <>
      {isAuthenticated && (
        <>
          <Wrapper data-element={"header-menu"}>
            <C4UTitle>CoxPraVocê</C4UTitle>
          </Wrapper>
          <TermsOfUserMolecule />
        </>
      )}
    </>
  );
};
