import {
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";

export interface IDealershipReportResponse
  extends IPagedRequestParamsBaseModel {}

export class DealershipReportPagedParams extends PagedRequestParamsBaseModel {
  constructor({ ...baseParams }: IDealershipReportResponse) {
    super(baseParams);
  }
}

export interface IDealershipReportPagedResponse
  extends IPagedResponseBaseModel {
  data: IDealershipReport[];
}

export interface IDealershipReport {
  id: number;
  publicId: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  procurator_IsProcuratorOrOwner: boolean;
  procurator_Name: string;
  procurator_Email: string;
  procurator_Cpf: string;
  procurator_BirthDate: string;
  procurator_CellPhone: string;
  address_ZipCode: string;
  address_Street: string;
  address_Number: string;
  address_Complement: string;
  address_Neighborhood: string;
  address_City: string;
  address_State: string;
  cnpj: string;
  name: string;
  legalName: string;
  email: string;
  primaryPhone: string;
  secondaryPhone: string;
  group: string;
  approvalStatus: number;
  createdById: number;
  updatedById: number;
  hasBackofficeRegistrationAccess: boolean;
  hasBackofficeAdsAccess: boolean;
  hasDealertrackAccess: boolean;
  hasKbbAccess: boolean;
  hasManheimAccess: boolean;
  hasProductsB3Access: boolean;
  hasProductsDebtsAccess: boolean;
  hasProductsInspectionAccess: boolean;
  hasProductsInsuranceAccess: boolean;
  registeredUsers: number;
  activeUsers: number;
}
