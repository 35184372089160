import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StringHelper } from "../../../../../helper";
import { useC4u } from "../../../../../hooks";

import {
  CoxUserIsActiveBooleanEnum,
  ICreateUserRequest,
  IDealershipComboData,
} from "../../../../../models";
import { FeedbackModal, UserDetailsFormMolecule } from "../../../../molecules";

export const UserDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>();
  const [exception, setException] = useState<any>();
  const { id } = useParams<{ id: string }>();

  const idNumber = !!isNaN(Number(id)) ? 0 : Number(id);

  const [userDetails, setUserDetails] = useState<ICreateUserRequest>();
  const [dealershipComboData, setDealershipComboData] =
    useState<IDealershipComboData | null>();

  const { GetUserDetail } = useC4u();

  useEffect(() => {
    if (isNaN(Number(id))) {
      setUserDetails({
        id: idNumber,
        active: CoxUserIsActiveBooleanEnum.Active.toString(),
      } as ICreateUserRequest);
    } else {
      GetUserDetail(Number(id)).then((t) => {
        const { dealershipName, isDt: _, isMaster, active, ...other } = t;
        setUserDetails({
          ...other,
          isMaster: StringHelper.getYesNoStringValues(isMaster),
          active: StringHelper.getYesNoStringValues(active),
          id: idNumber,
        });
        if (other.dealershipId && dealershipName)
          setDealershipComboData({
            id: Number(other.dealershipId),
            name: dealershipName,
          });
        else setDealershipComboData(null);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!userDetails ? (
        <SpinnerCenteredAtom />
      ) : (
        <UserDetailsFormMolecule
          setShow={setShow}
          setException={setException}
          initialValues={userDetails}
          dealerShipData={dealershipComboData}
        />
      )}

      <FeedbackModal
        type={"error"}
        show={show !== undefined}
        onHide={() => setShow(undefined)}
        content={show ? t("Data sent successfully") : t("api_error")}
      >
        <ModalBody>{show ? exception : t("Try again error message")}</ModalBody>
      </FeedbackModal>
    </>
  );
};
