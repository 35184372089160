import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableList } from "../../..";
import { StatusHelper } from "../../../../../helper";
import { TypesHelper } from "c4u-web-components";
import { IAdStatuses } from "../../../../../models";

interface IProps {
  statuses: IAdStatuses[];
}
export const AdStatusesMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TableList thead={[t("Status"), t("Step"), t("SubStep"), t("Date")]}>
      {props.statuses?.map((m, i) => (
        <tr key={`tr${i}`}>
          <td>{t(StatusHelper.getStatus(m.step, m.stepStatus, t))}</td>
          <td>{`${m.step} - ${StatusHelper.getStepName(m.step)}`}</td>
          <td>{`${m.stepStatus} - ${StatusHelper.getStepStatusName(m.step, m.stepStatus)}`}</td>
          <td>
            {format(
              TypesHelper.toDateTimeNull(m.date) ?? new Date(1, 1, 1),
              "dd/MM/yyyy HH:mm:ss"
            )}
          </td>
        </tr>
      ))}
    </TableList>
  );
};
