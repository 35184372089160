import styled from "styled-components";

const WrapperDetails = styled.div`
  .section {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .subsection {
    margin-top: 32px;
  }
`;

const ClicableDiv = styled.div`
  cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
`;

export { ClicableDiv, WrapperDetails };
