import {
  ButtonPrimary,
  ButtonSecondary,
  Combo,
  FormikControlAtom,
  ModalDefault,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StringHelper } from "../../../../helper";
import { useC4u } from "../../../../hooks";
import {
  CorporateUserTypeEnum,
  IDealershipComboData,
  ListCorporateUsersParams,
} from "../../../../models";
import { ManageItemsAtom } from "../../../atoms";
import {
  DefaultModalText,
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  show: boolean;
  onHide: () => void;
  onApplyCopy: (dealerships: IDealershipComboData[]) => void;
}

interface ITeste {
  currentUserSelection: number;
}

export const CopyDealershipsModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { getCorporateUsers, getCorporateUserDetail } = useC4u();

  const [allUsersCombo, setAllUsersCombo] = useState<Combo[]>();
  const [dealershipsFound, setDealershipsFound] =
    useState<IDealershipComboData[]>();

  const validations = Yup.object<ITeste>({
    currentUserSelection: Yup.number().required(),
  });

  const formik = useFormik<ITeste>({
    initialValues: {} as ITeste,
    onSubmit: async () => {
      try {
        const data = await getCorporateUserDetail(
          formik.values.currentUserSelection
        );
        setDealershipsFound(data.corporateDealerships ?? []);
      } catch (e) {
        console.log("Error fetching dealerships from user", e);
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const onHideCallback = useCallback(() => {
    setDealershipsFound(undefined);
    formik.resetForm();
    props.onHide();
  }, [formik, props]);

  const searchAndSetUsersListAsync = useCallback(
    async (searchTerm: string) => {
      const { data: items } = await getCorporateUsers(
        new ListCorporateUsersParams({
          pageSize: 50,
          search: searchTerm,
          active: "",
        })
      );

      const consultantUsers = items.filter(
        (f) => f.corporateUserType === CorporateUserTypeEnum.Consultant
      );
      const itemsCombo = consultantUsers?.map(
        (m) =>
          new Combo({
            title: `${m.coxUserName} (${m.coxUserEmail})`,
            value: m.coxUserId,
          })
      );
      setAllUsersCombo(itemsCombo);
    },
    [getCorporateUsers]
  );

  useEffect(() => {
    if (isNaN(parseInt(formik.values.currentUserSelection?.toString()))) {
      setDealershipsFound(undefined);
    } else {
      const bt = document.getElementById("submit-search");
      if (bt) bt.click();
    }
  }, [formik.values.currentUserSelection]);

  return (
    <ModalDefault
      show={props.show}
      size="lg"
      onHide={onHideCallback}
      title={t("Copy dealerships from user")}
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={onHideCallback}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={() => {
              if (dealershipsFound?.length) props.onApplyCopy(dealershipsFound);
              onHideCallback();
            }}
            disabled={!dealershipsFound?.length}
          >
            {t("Assign dealerships")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper style={{ height: "50vh" }}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Row className="d-flex justify-content-center">
            <FormikControlAtom
              type="autocomplete-async"
              xs={10}
              formik={formik}
              data={allUsersCombo}
              property={"currentUserSelection"}
              label={t("Select user to copy dealerships from")}
              placeholder={t("Start typing to search for users")}
              translate={t}
              className="overwrite-input-css-attrs"
              borderColor="#ced4da"
              backgroundColor="#fff"
              onSearchAsync={searchAndSetUsersListAsync}
              maxHeight="185px"
              maxResults={5}
              delay={300}
            />
          </Form.Row>

          <Form.Row className="my-3">
            <Col>
              {formik.isSubmitting ? (
                <SpinnerCenteredAtom />
              ) : (
                dealershipsFound &&
                (dealershipsFound.length > 0 ? (
                  <ManageItemsAtom
                    fieldsetTitle={t("Dealerships Found")}
                    items={dealershipsFound.map((m, i) => ({
                      id: i,
                      line1: m.name,
                      line2: `(${StringHelper.formatCpfCnpj(m.cnpj)})`,
                    }))}
                  />
                ) : (
                  <DefaultModalText>
                    {t("User has no dealerships assigned")}
                  </DefaultModalText>
                ))
              )}
            </Col>
          </Form.Row>

          <button type="submit" id="submit-search" className="d-none"></button>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
