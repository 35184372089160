import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import { StdEnum } from "../../../../../models";
import {
  GetRobotsReportRequest,
  IGetRobotsReportParams,
  IGetRobotsReportResponse,
  IGetRobotsReportResponsePagedResponseModel,
} from "../../../../../models/apis/kbb/api-robots-report.request";
import {
  PaginatableTableList,
  RobotsReportSearchForm,
} from "../../../../molecules";
interface IProps {
  getDataCallback: (
    request: GetRobotsReportRequest
  ) => Promise<IGetRobotsReportResponsePagedResponseModel>;
  serviceRobotsEnum: StdEnum;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
  tableName: string;
}

export const RobotsTableSearchOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [listData, setListData] = useState<IGetRobotsReportResponse[]>();

  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const initialValues: IGetRobotsReportParams = {
    pageSize: 10,
    serviceUpdated: 1,
  };

  const formik = useFormik<IGetRobotsReportParams>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setDataIsLoading(true);
        setListData(undefined);
        const { items } = await props.getDataCallback(
          new GetRobotsReportRequest(values)
        );
        setListData(items);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      } finally {
        setDataIsLoading(false);
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.submitForm();
  }, [formik.values]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <RobotsReportSearchForm
        formik={formik}
        serviceRobotsEnum={props.serviceRobotsEnum}
        errorMessage={errorMessage}
      />

      <PaginatableTableList
        tableName={"api-robots"}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
