import { AxiosResponse } from "axios";
import { TypesHelper } from "c4u-web-components";
import { FormikErrors, FormikProps, FormikValues } from "formik";

export interface IPagedResponseBaseModel {
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
  nextPageId?: number;
  nextPageDate?: string | Date;
}

export interface IPagedResponseGenericModel<T> extends IPagedResponseBaseModel {
  data: T[];
}

export interface IPagedRequestParamsBaseModel {
  pageSize?: number;
  page?: number;
  orderBy?: string;
  nextPageId?: number;
  nextPageDate?: string | Date;
}

export interface IPagedRequestParamsFormikValuesBaseModel
  extends FormikValues,
    IPagedRequestParamsBaseModel {}

export interface IGenericSearchFormik<T, U> {
  formik: FormikProps<T>;
  errorMessage?: string;
  cleanFiltersCallback: () => Promise<void> | Promise<FormikErrors<T>>;
  downloadAllResultsCallback?: (params: U) => Promise<AxiosResponse<BlobPart>>;
  tableName?: string;
  reportType?: string;
}

export class PagedRequestParamsBaseModel {
  pageSize: number | null;
  page: number | null;
  orderBy: string | null;
  nextPageId?: number | null;
  nextPageDate?: string | null;
  constructor({
    pageSize,
    page,
    orderBy,
    nextPageId,
    nextPageDate,
  }: IPagedRequestParamsBaseModel) {
    this.pageSize = TypesHelper.toNumberNull(pageSize);
    this.page = TypesHelper.toNumberNull(page);
    this.orderBy = TypesHelper.toStringNull(orderBy);
    this.nextPageId = TypesHelper.toNumberNull(nextPageId);
    this.nextPageDate = TypesHelper.toStringNull(nextPageDate);
  }
}
