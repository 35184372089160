import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  ModalDefault,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import { IParametersModel, UpdateParametersRequest } from "../../../../models";
import {
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  show: boolean;
  data: IParametersModel;
  onHide: () => void;
  onSaveCallback: (request: UpdateParametersRequest) => Promise<void>;
  onSaveSuccess: () => void;
  setException: (v: any) => void;
  setShowErrorModal: (v: boolean) => void;
}

export const ParameterFormsModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object<IParametersModel>({
    id: Yup.number().required(),
    key: Yup.string().required(),
    value: Yup.string().required(),
  });

  const formik = useFormik<IParametersModel>({
    initialValues: props.data,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onSaveCallback(new UpdateParametersRequest(values));
        props.onSaveSuccess();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          props.onHide();
          props.setException(t("GenericApiError"));
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleSubmit = useCallback(
    (e: any) => formik.handleSubmit(e),
    [formik]
  );

  return (
    <ModalDefault
      show={props.show}
      onHide={props.onHide}
      title={t("Edit Key #{id}", { id: props.data.id })}
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={props.onHide}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={handleSubmit}
            loading={formik.isSubmitting}
          >
            {t("Save")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col className="d-none d-sm-flex" md={1}></Col>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="key"
              label={t("Key")}
              xs={8}
              sm={7}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="value"
              label={t("Value")}
              func={(value: string) => value && value.replace(/\D/g, "")}
              xs={4}
              md={3}
            />
          </Form.Row>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
