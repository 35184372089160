import React from "react";
import { useTranslation } from "react-i18next";
import { PaginatableTableList } from "../../../..";
import { StringHelper } from "../../../../../../helper";
import { IGetTrackerDetailEmergencyContact } from "../../../../../../models";

interface IProps {
  emergencyContactDetails?: IGetTrackerDetailEmergencyContact[];
}

export const TrackerEmergencyContactDetailsMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  return !props.emergencyContactDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <PaginatableTableList
      tableName={"coverage-details"}
      dataIsLoading={false}
      thead={[t("Name"), t("Phone")]}
      tbody={props.emergencyContactDetails?.map((m, i) => [
        m.name,
        m.areaCode &&
          m.phoneNumber &&
          StringHelper.formatPhone(m.areaCode + m.phoneNumber),
      ])}
    />
  );
};
