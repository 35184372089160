import React from "react";
import { useTranslation } from "react-i18next";
import { LenderUserListOrganism } from "../../../organisms";

export const LenderUsersListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("FinancialUsersListing")}</h3>

      <LenderUserListOrganism />
    </>
  );
};
