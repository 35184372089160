import { ProposalStepEnum, TypesHelper } from "c4u-web-components";
import { IDealershipReport, InsuranceCompanyEnum } from "../..";

import { IPagedResponseBaseModel } from "../../../models";
import {
  IPagedRequestParamsBaseModel,
  PagedRequestParamsBaseModel,
} from "../../shared/paged-response-base.model";

export interface IGetCustomerReportParams extends IPagedRequestParamsBaseModel {
  search: string;
  partner?: number | null;
  status?: number | null;
  dealership?: number | null;
  startDate?: string | Date;
  finalDate?: string | Date;
  origin?: number | null;
}

export class GetCustomerReportParams extends PagedRequestParamsBaseModel {
  search: string | null;
  partner?: string | null;
  status?: string | null;
  dealership?: string | null;
  startDate?: string | null;
  finalDate?: string | null;
  origin?: number | null;
  constructor({
    search,
    partner,
    status,
    dealership,
    startDate,
    finalDate,
    origin,
    ...baseParams
  }: IGetCustomerReportParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.partner = TypesHelper.toStringNull(partner);
    this.status = TypesHelper.toStringNull(status);
    this.dealership = TypesHelper.toStringNull(dealership);
    this.startDate = TypesHelper.toDateNull(startDate)?.toISOString();
    this.finalDate = TypesHelper.toDateNull(finalDate)?.toISOString();
    this.origin = TypesHelper.toNumberNull(origin);
  }
}

export interface IVehicleReport {
  name?: string;
  modelYear: number;
  plate?: string;
}

export interface IGetCustomerReportResponse {
  id: number;
  name?: string;
  cpfCnpj?: string;
  vehicle: IVehicleReport;
  policy?: string;
  premium: number;
  dealership: IDealershipReport;
  company: InsuranceCompanyEnum;
  createdAt: string;
  updatedAt: string;
  status: ProposalStepEnum;
  lenderCode: string;
}

export interface IGetCustomerReportPagedResponseModel
  extends IPagedResponseBaseModel {
  data?: IGetCustomerReportResponse;
}
