import { ButtonPrimary, ButtonSecondary } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Col } from "react-bootstrap";
import { FormikControlAtom, OptionsOrderByAtom } from "../../../../atoms";
import { StdEnum } from "../../../../../models";

interface IProps {
  formik: any;
  stepsOptionsEnum: StdEnum;
  stepsStatusOptions: StdEnum;
  adOrigin: StdEnum;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const AdsListSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  const getStepsOptions = (property: string, options: StdEnum) => (
    <>
      <option value={""}>{t("All")}</option>
      {Object.entries(options)
        .filter(([k, _]) => !isNaN(parseInt(k)))
        .map(([k, v], i) => (
          <option value={k} key={`${property}${i}`}>
            {`${k} - ${v} (${t(v.toString())})`}
          </option>
        ))}
    </>
  );

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <Col sm={9} md={10}>
            <Form.Row>
              <FormikControlAtom
                formik={formik}
                sm={7}
                property={"search"}
                label={t("TextSearch")}
              />
              <FormikControlAtom
                formik={formik}
                as={"select"}
                sm={5}
                md={3}
                property={"pageSize"}
                label={t("RecordsPerPage")}
              >
                <OptionsOrderByAtom
                  options={[
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                  ]}
                />
              </FormikControlAtom>
            </Form.Row>
            <Form.Row>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                sm={6}
                md={5}
                property={"steps"}
                label={t("Step")}
              >
                {getStepsOptions("steps", props.stepsOptionsEnum)}
              </FormikControlAtom>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                sm={6}
                md={5}
                property={"stepsStatus"}
                label={t("SubStep")}
              >
                {getStepsOptions("stepsStatus", props.stepsStatusOptions)}
              </FormikControlAtom>
              <FormikControlAtom
                formik={formik}
                as={"select"}
                sm={6}
                md={5}
                property={"adOrigin"}
                label={t("AdOrigin")}
              >
                {getStepsOptions("adOrigin", props.adOrigin)}
              </FormikControlAtom>
            </Form.Row>
          </Col>
          <Col sm={3} md={2}>
            <Form.Row className="pt-2 mt-3">
              <ButtonSecondary
                sizex="md"
                disabled={formik.isSubmitting}
                onClick={props.cleanFiltersCallback}
              >
                {t("CleanFilters")}
              </ButtonSecondary>
            </Form.Row>
            <Form.Row className="pt-3 mt-4">
              <ButtonPrimary
                type="submit"
                sizex="md"
                loading={formik.isSubmitting}
              >
                {t("Search")}
              </ButtonPrimary>
            </Form.Row>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
