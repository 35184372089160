import { useRequest, ManheinHttp, manheimClientConfig } from "../config";
import {
  InspectionRequest,
  InspectionWebhookRequest,
  InspectionWebhookRetorno1,
  InspectionWebhookRetorno2,
  NotifyBuyerPaymentWinnerRequest,
  INotifyPaymentToSellerRequest,
  INotifyBuyerNoPaymentRequest,
  INotifyReleasePaymentRequest,
  INotifyBuyerPaymentRequest,
} from "../../../models";

export const useManheinWebhook = () => {
  const { post } = useRequest(ManheinHttp, "Webhook", manheimClientConfig);

  const webhookInpesctionWebHook = async (
    inspection: InspectionRequest
  ): Promise<string> => {
    const request = {
      retorno: {
        sucesso: true,
        retorno: {
          vistoria: inspection,
        } as InspectionWebhookRetorno2,
      } as InspectionWebhookRetorno1,
    } as InspectionWebhookRequest;

    const { data } = await post(
      `InpesctionWebHook?api-key=${process.env.REACT_APP_MANHEIM_INSPECTION_WEBHOOK_TOKEN}`,
      request
    );
    return data;
  };

  const webhookNotifyBuyerPayment = async (
    vehicleId: string,
    winner: NotifyBuyerPaymentWinnerRequest
  ): Promise<string> => {
    const request = {
      vehicle_id: vehicleId,
      payment_status: 1,
      winner,
    } as INotifyBuyerPaymentRequest;
    const { data } = await post(
      `notifyBuyerPayment?api-key=${process.env.REACT_APP_MANHEIM_NOTIFICATION_WEBHOOK_TOKEN}`,
      request
    );
    return data;
  };

  const webhookNotifyBuyerNoPayment = async (
    request: INotifyBuyerNoPaymentRequest
  ): Promise<string> => {
    const { data } = await post(
      `notifyBuyerNoPayment?api-key=${process.env.REACT_APP_MANHEIM_NOTIFICATION_WEBHOOK_TOKEN}`,
      request
    );
    return data;
  };

  const webhookNotifyReleasePayment = async (
    request: INotifyReleasePaymentRequest
  ): Promise<string> => {
    const { data } = await post(
      `notifyReleasePayment?api-key=${process.env.REACT_APP_MANHEIM_NOTIFICATION_WEBHOOK_TOKEN}`,
      request
    );
    return data;
  };

  const webhookNotifyPaymentToSeller = async (
    request: INotifyPaymentToSellerRequest
  ): Promise<string> => {
    const { data } = await post(
      `notifyPaymentToSeller?api-key=${process.env.REACT_APP_MANHEIM_NOTIFICATION_WEBHOOK_TOKEN}`,
      request
    );
    return data;
  };

  return {
    webhookInpesctionWebHook,
    webhookNotifyBuyerPayment,
    webhookNotifyReleasePayment,
    webhookNotifyPaymentToSeller,
    webhookNotifyBuyerNoPayment,
  };
};
