import React from "react";
import { useTranslation } from "react-i18next";
import { TitleWithBackButtonAtom } from "../../../../atoms";
import { RequestingInputtingOrganism } from "../../../../organisms";
import { paths } from "../../../../../constants";
import { useHistory } from "react-router-dom";

export const RequestingInputtingPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("InformationVehicle")}
        onClick={() =>
          history.push(paths.backofficeListRequestingVehicleChanges())
        }
      />
      <RequestingInputtingOrganism />
    </>
  );
};
