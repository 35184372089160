import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICpvHomeBigCardData } from "../../../models";
import {
  BancoDaycovalLogoHomeAllBanks,
  CreditasLogoHomeAllBanks,
  ItauLogoHomeAllBanks,
  OmniLogoHomeAllBanks,
  RewardCox,
  SafraFinanceiraLogoHomeAllBanks,
} from "../../assets";
import {
  BackgroundLogoAllBanks,
  BackgroundRewardCox,
  ButtonRewardCox,
  CardBottomAllBanks,
  CardHeaderAllBanks,
  CardReward,
  CardRewardCox,
  CardTextAllBanks,
  ComingSoonDiv,
  ContainerReward,
  HeaderExtraCardHomeAllBanks,
  ImageAllReward,
  ImageLogo,
  ImageRewardCox,
  LogoWrapperAllBanks,
  RegistrationAllBanksHomeCardStyled,
  RegistrationRewardsCox,
  TextElements,
  TitleExtraCardHomeAllBanks,
} from "./all-banks-card.atom.style";

interface IProps {
  profile?: string;
  borderColor: string;
  onClick?: (e: React.MouseEvent) => void;
  data: ICpvHomeBigCardData;
  dataElement?: string;
  dataProduct?: string;
  isMaster?: boolean;
}

export interface AllBanksHomeCardStyleProps extends Omit<IProps, "data"> {}

export const AllBanksCardAtom: React.FC<IProps> = (props) => {
  const { data, dataElement, dataProduct, ...otherProps } = props;
  const { t } = useTranslation();
  const urlRewardsCox =
    "https://cloud.coxautomotivebrasil.com.br/CoxRecompensaCadastro";

  const AllBanksCard = useMemo(
    () => (
      <RegistrationAllBanksHomeCardStyled
        {...otherProps}
        data-element={dataElement}
        data-product={dataProduct}
      >
        <CardHeaderAllBanks>
          <div>
            <HeaderExtraCardHomeAllBanks>
              <BackgroundLogoAllBanks>
                <LogoWrapperAllBanks>
                  <img alt={data.logoAltText} src={data.logoSrc} />
                </LogoWrapperAllBanks>
              </BackgroundLogoAllBanks>

              <TitleExtraCardHomeAllBanks>
                {t("AllBanksTitleSafra")}
              </TitleExtraCardHomeAllBanks>
            </HeaderExtraCardHomeAllBanks>
            <CardTextAllBanks>{t("AllBanksCardTextSafra")}</CardTextAllBanks>
          </div>
        </CardHeaderAllBanks>
        <hr />
        <CardBottomAllBanks>
          <img src={ItauLogoHomeAllBanks} alt="" />
          <img src={SafraFinanceiraLogoHomeAllBanks} alt="" />
          <img src={CreditasLogoHomeAllBanks} alt="" />
          <img src={BancoDaycovalLogoHomeAllBanks} alt="" />
          <div className="coming-soon">
            <img src={OmniLogoHomeAllBanks} alt="" />
            <ComingSoonDiv>{t("ComingSoon")}</ComingSoonDiv>
          </div>
        </CardBottomAllBanks>
      </RegistrationAllBanksHomeCardStyled>
    ),
    [data.logoAltText, data.logoSrc, dataElement, dataProduct, otherProps, t]
  );

  const RewardsCard = useMemo(
    () => (
      <RegistrationRewardsCox
        {...otherProps}
        data-element={dataElement}
        data-product={dataProduct}
      >
        <BackgroundRewardCox>
          <CardReward>
            <ContainerReward>
              <ImageRewardCox>
                <ImageLogo>
                  <ImageAllReward src={RewardCox} alt="" />
                </ImageLogo>
              </ImageRewardCox>
            </ContainerReward>
          </CardReward>

          <CardRewardCox>
            <TextElements>
              <b>{t("CoxRewardCampaign")}</b>

              <span>{t("RegisterYourSeller")}</span>
            </TextElements>

            <a href={urlRewardsCox} target="_blank" rel="noreferrer">
              <ButtonRewardCox>CADASTRAR</ButtonRewardCox>
            </a>
          </CardRewardCox>
        </BackgroundRewardCox>
      </RegistrationRewardsCox>
    ),
    [dataElement, dataProduct, otherProps, t]
  );

  return <>{!props.isMaster ? AllBanksCard : RewardsCard}</>;
};
