import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { TitleWithBackButtonAtom } from "../../../atoms";
import { CorporateUserDetailsOrganism } from "../../../organisms";

export const CorporateUserPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("CorporateUser")}
        onClick={() => push(paths.backofficeCorporateUserListPage())}
      />
      <CorporateUserDetailsOrganism />
    </>
  );
};
