import styled from "styled-components";

import Form from "react-bootstrap/Form";

export const InputStyled = styled(Form.Control)`
  height: 40px;
  background-color: ${props => props.theme.colors.GhostWhite};
  border: 1px solid ${props => props.theme.colors.DarkGray};
  border-radius: 4px;
  width: 100%;
  padding: 9px;
`;