import React from "react";
import { StdEnum } from "../../../models";
import { useTranslation } from "react-i18next";

interface IProps {
  propertyKey: string;
  optionsEnum: StdEnum;
  hideOptionAll?: boolean;
  notSorted?: boolean;
  reversedOrder?: boolean;
  includeSelectPlaceholder?: boolean;
  getCustomOptionsText?: (v: string, k: string) => string;
}

export const ComboOptionsFromEnumAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {props.includeSelectPlaceholder && (
        <option value="">{t("Select")}...</option>
      )}
      {!props.hideOptionAll && <option value={""}>{t("All")}</option>}
      {props.notSorted
        ? Object.entries(props.optionsEnum)
            .filter(([k, _]) => !isNaN(parseInt(k)))
            .map(([k, v], i) => (
              <option value={k} key={`${props.propertyKey}${i}`}>
                {!!props.getCustomOptionsText
                  ? props.getCustomOptionsText(v.toString(), k)
                  : t(v.toString())}
              </option>
            ))
        : props.reversedOrder
        ? Object.entries(props.optionsEnum)
            .filter(([k, _]) => !isNaN(parseInt(k)))
            .sort(([_2, v2], [_1, v1]) =>
              t(v1.toString()) > t(v2.toString()) ? 1 : -1
            )
            .map(([k, v], i) => (
              <option value={k} key={`${props.propertyKey}${i}`}>
                {!!props.getCustomOptionsText
                  ? props.getCustomOptionsText(v.toString(), k)
                  : t(v.toString())}
              </option>
            ))
        : Object.entries(props.optionsEnum)
            .filter(([k, _]) => !isNaN(parseInt(k)))
            .sort(([_1, v1], [_2, v2]) =>
              t(v1.toString()) > t(v2.toString()) ? 1 : -1
            )
            .map(([k, v], i) => (
              <option value={k} key={`${props.propertyKey}${i}`}>
                {!!props.getCustomOptionsText
                  ? props.getCustomOptionsText(v.toString(), k)
                  : t(v.toString())}
              </option>
            ))}
    </>
  );
};
