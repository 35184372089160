import styled from "styled-components";

export const EditButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EditButtonDiv = styled.div`
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};
  color: ${(props) => props.color ?? "#000"};
`;
