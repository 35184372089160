import React from "react";
import { useTranslation } from "react-i18next";
import { DtMockDiscoverOrganism } from "../../../../organisms";

export const DtMockDiscoverPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("DiscoverDT")}</h3>
      <DtMockDiscoverOrganism />;
    </>
  );
};
