import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useC4u } from "../../../../hooks";
import { DealerList, DealershipFilterModel } from "../../../../models";
import { TableList } from "../../../molecules";
import { StatusHelper } from "../../../../helper";
import {
  Combo,
  DropdownDefault,
  MaskHelper,
  ButtonPrimary,
  FormControlMask,
  DateHelper,
  InputDefault,
  CoxIcon,
} from "c4u-web-components";
import { Col, Form } from "react-bootstrap";
import { paths } from "../../../../constants";
import {
  EditButtonsWrapper,
  EditButtonDiv,
} from "./list-user-accounts.organism.style";
import { useHistory, useLocation } from "react-router-dom";

export const ListUserAccounts: React.FC = () => {
  const { GetFilteredDealers } = useC4u();
  const { t } = useTranslation();
  const history = useHistory();
  const localtion = useLocation();

  const [list, setList] = useState<DealerList[]>();

  const [filter] = useState<DealershipFilterModel>({
    ApprovalStatus: 0,
    CreationDateSince: undefined,
    CreationDateUntil: undefined,
    IsDealertrack: undefined,
    TextSearch: undefined,
  });

  const [doSearch, setSearch] = useState(false);
  useEffect(() => {
    if (localtion.state) {
      filter.TextSearch = localtion.state as any;
      filter.ApprovalStatus = undefined;
      setSelectedTextSearch(filter.TextSearch);
      setSelectedStatus(new Combo({ title: t("All"), value: undefined }));
    }
    setSearch(true);
    // eslint-disable-next-line
  }, [localtion.state]);
  useEffect(() => {
    if (doSearch) {
      getAllDealersAsync();
    }
    // eslint-disable-next-line
  }, [doSearch]);

  const [comboStatus] = useState<Combo[]>([
    new Combo({ title: t("All"), value: undefined }),
    new Combo({ title: StatusHelper.getApprovalStatus(0, t), value: 0 }),
    new Combo({ title: StatusHelper.getApprovalStatus(1, t), value: 1 }),
    new Combo({ title: StatusHelper.getApprovalStatus(2, t), value: 2 }),
    new Combo({ title: StatusHelper.getApprovalStatus(3, t), value: 3 }),
  ]);
  const [selectedStatus, setSelectedStatus] = useState<Combo>(comboStatus[1]);
  const [selectedCreationDateSince, setSelectedCreationDateSince] =
    useState<string>();
  const [selectedCreationDateUntil, setSelectedCreationDateUntil] =
    useState<string>();
  const [comboIsDealertrack] = useState<Combo[]>([
    new Combo({ title: t("All"), value: undefined }),
    new Combo({ title: t("No"), value: 0 }),
    new Combo({ title: t("Yes"), value: 1 }),
  ]);
  const [selectedIsDealertrack, setSelectedIsDealertrack] = useState<Combo>(
    comboIsDealertrack[0]
  );
  const [selectedTextSearch, setSelectedTextSearch] = useState<string>();

  const getAllDealersAsync = useCallback(async () => {
    const dealers = await GetFilteredDealers(filter);
    setList(dealers);
  }, [filter, GetFilteredDealers]);

  const statusFilterChange = useCallback(
    (selected: Combo) => {
      setSelectedStatus(selected);
      filter.ApprovalStatus = parseInt(selected.value?.toString());
    },
    [filter]
  );

  const creationDateSinceFilterChange = useCallback(
    (value: any) => {
      setSelectedCreationDateSince(value.target.value.toString());
      filter.CreationDateSince = stringToDate(value.target.value.toString());
    },
    [filter]
  );

  const creationDateUntilFilterChange = useCallback(
    (value: any) => {
      setSelectedCreationDateUntil(value.target.value.toString());
      filter.CreationDateUntil = stringToDate(value.target.value.toString());
    },
    [filter]
  );

  const stringToDate = function (textDate: string): Date | undefined {
    try {
      var day = parseInt(textDate.substring(0, 2));
      var month = parseInt(textDate.substring(3, 5));
      var year = parseInt(textDate.substring(6, 10));
      return DateHelper.toDateHourFixed(
        new Date(year, month - 1, day, 0, 0, 0, 0)
      );
    } catch {
      return undefined;
    }
  };

  const isDealertrackFilterChange = useCallback(
    (selected: Combo) => {
      setSelectedIsDealertrack(selected);
      switch (selected.value?.toString()) {
        case "0":
          filter.IsDealertrack = false;
          break;
        case "1":
          filter.IsDealertrack = true;
          break;
        default:
          filter.IsDealertrack = undefined;
          break;
      }
    },
    [filter]
  );

  const textSearchFilterChange = useCallback(
    (selected: React.FocusEvent<HTMLInputElement>) => {
      filter.TextSearch = selected.target.value;
    },
    [filter]
  );

  const submitSearch = useCallback(() => {
    getAllDealersAsync();
  }, [getAllDealersAsync]);

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} md={4} controlId={"Status"}>
          <Form.Label>{t("Status")}</Form.Label>
          <DropdownDefault
            data={comboStatus}
            selectText=""
            value={selectedStatus}
            onChange={statusFilterChange}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} controlId={"CreationDateSince"}>
          <Form.Label>{t("CreationDateSince")}</Form.Label>
          <FormControlMask
            value={selectedCreationDateSince}
            mask={MaskHelper.Date()}
            onBlur={creationDateSinceFilterChange}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} controlId={"CreationDateUntil"}>
          <Form.Label>{t("CreationDateUntil")}</Form.Label>
          <FormControlMask
            value={selectedCreationDateUntil}
            mask={MaskHelper.Date()}
            onBlur={creationDateUntilFilterChange}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} controlId={"IsDealertrack"}>
          <Form.Label>{t("IsDealertrack")}</Form.Label>
          <DropdownDefault
            data={comboIsDealertrack}
            selectText=""
            value={selectedIsDealertrack}
            onChange={isDealertrackFilterChange}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} controlId={"TextSearch"}>
          <Form.Label>{t("TextSearch")}</Form.Label>
          <InputDefault
            value={selectedTextSearch}
            onBlur={textSearchFilterChange}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md
          className={"text-right pt-4"}
          controlId={"approved"}
        >
          <ButtonPrimary sizex={"md"} onClick={submitSearch}>
            {t("Search")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
      <TableList
        thead={[
          "#",
          t("Dealership Name"),
          t("Dealership Legal Name"),
          t("Cnpj"),
          t("Status"),
          t("Edit"),
        ]}
      >
        {list?.map((m, i) => (
          <tr key={`tr${i}`}>
            <td>{m.id}</td>
            <td>{m.name}</td>
            <td>{m.legalName}</td>
            <td>{m.cnpj}</td>
            <td>
              {StatusHelper.getApprovalStatus(parseInt(m.approvalStatus), t)}
            </td>
            <td>
              <EditButtonsWrapper>
                <EditButtonDiv
                  onClick={() =>
                    history.push({
                      pathname: paths.backofficeUserAccountDetailsPage(m.id),
                      state: m.cnpj
                    })
                  }
                  color="#576172"
                >
                  <CoxIcon
                    name="pencil-edit"
                    width={16}
                    height={16}
                    fillColor="#576172"
                  />
                  Gerenciar
                </EditButtonDiv>
                <EditButtonDiv
                  onClick={() =>
                    history.push({
                      pathname: paths.backofficeUserAccountNewDetailsPage(m.id),
                      state: m.cnpj
                    })
                  }
                  color="#1F3E74"
                >
                  <CoxIcon
                    name="pencil-edit"
                    width={16}
                    height={16}
                    fillColor="#1F3E74"
                  />
                  Editar
                </EditButtonDiv>
              </EditButtonsWrapper>
            </td>
          </tr>
        ))}
      </TableList>
    </>
  );
};
