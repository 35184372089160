export const handleFileRead = async (
  event: any,
  formik: any,
  setFile: (value: string) => void
) => {
  formik.handleChange(event);
  const file = event.target.files[0];
  const base64 = await convertBase64(file);
  if (base64 && typeof base64 === "string") {
    setFile(base64.replace(/data:[/\w]+;\w+,/, ""));
  }
};

export const convertBase64 = (
  file: any
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
