import { FormikControlAtom, MaskHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  formik: any;
}

export const DtMockSyncDealershipMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { formik } = props;

  const defaultProps = {
    translate: t,
    formik,
    sm: 4,
  };

  return (
    <Form.Row>
      <FormikControlAtom
        {...defaultProps}
        label={t("Cnpj")}
        property={"dealership.businessNumber"}
        mask={MaskHelper.Cnpj}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Dealership Legal Name")}
        property={"dealership.legalName"}
      />
      <FormikControlAtom
        {...defaultProps}
        label={t("Dealership Name")}
        property={"dealership.name"}
      />
    </Form.Row>
  );
};
