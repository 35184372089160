import React from "react";

import { useTranslation } from "react-i18next";
import { RobotsReportOrganism } from "../../../../organisms";

export const RobotsReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleRobots")}</h3>
      <hr />
      <RobotsReportOrganism />
    </>
  );
};
