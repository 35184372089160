import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../helper";

import { useC4u } from "../../../../hooks";
import { TableList } from "../../../molecules";

export const UsersReport: React.FC = () => {
  const { GetUsersReport } = useC4u();

  const { t } = useTranslation();

  const [list, setList] = useState<any[]>();

  const GetUsersReportAsync = useCallback(async () => {
    var users = await GetUsersReport();
    setList(users);
  }, [GetUsersReport]);

  useEffect(() => {
    GetUsersReportAsync();
    // eslint-disable-next-line
  }, []);

  return (
    <TableList
      thead={[
        "#",
        t("PublicId"),
        t("Created At"),
        t("Updated At"),
        t("Active"),
        t("Name"),
        t("Cpf"),
        t("E-mail"),
        t("CellPhone"),
        t("IsMaster"),
        t("IsDt"),
        t("Token"),
        t("CreatedById"),
        t("UpdatedById"),
        t("DealershipId"),
      ]}
    >
      {list?.sort(
        (a, b) => a.id - b.id
      ).map((m, i) => (
        <tr key={`tr${i}`}>
          <td>{m.id}</td>
          <td>{m.publicId}</td>
          <td>{DateHelper.dateHourFormat(m.createdAt)}</td>
          <td>{DateHelper.dateHourFormat(m.updatedAt)}</td>
          <td>{m.active ? t("Yes") : t("No")}</td>
          <td>{m.name}</td>
          <td>{m.cpf}</td>
          <td>{m.email}</td>
          <td>{m.cellPhone}</td>
          <td>{m.isMaster ? t("Yes") : t("No")}</td>
          <td>{m.isDt ? t("Yes") : t("No")}</td>
          <td>{m.token}</td>
          <td>{m.createdById}</td>
          <td>{m.updatedById}</td>
          <td>{m.dealershipId}</td>
        </tr>
      ))}
    </TableList>
  );
};
