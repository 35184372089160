import React from "react";
import {
  IHistoricsRF,
  INationalBase,
  IFindHistoryReturn,
} from "../../../../../models";
import {
  HistoricsRFMolecule,
  NationalBaseMolecule,
  BinReturnMolecule,
} from "../../../../molecules";
import { useTranslation } from "react-i18next";

interface IProps {
  returnData: IFindHistoryReturn;
  nationalBase: INationalBase;
  hiscoricsRF: IHistoricsRF[];
}

export const VehicleHistoryOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="mt-5 ml-2">{t("NationalBase")}</h4>
      <NationalBaseMolecule data={props.nationalBase} />
      <hr />
      <h4 className="mt-5 ml-2">{t("HistoricRF")}</h4>
      {props.hiscoricsRF.map((m, i) => (
        <HistoricsRFMolecule key={`cardados-history-${i}`} data={m} />
      ))}
      {props.returnData !== null && (
        <>
          <h4 className="mt-5 ml-2">{t("ReturnFetch")}</h4>
          <BinReturnMolecule data={props.returnData} />
        </>
      )}
    </>
  );
};
