import { TypesHelper } from "c4u-web-components";
import {
  ApprovalStatusEnum,
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";

export interface IListUsersParams extends IPagedRequestParamsBaseModel {
  search?: string;
  approvalStatus?: ApprovalStatusEnum | "";
  active?: string;
}

export class ListUsersParams extends PagedRequestParamsBaseModel {
  search: string | null;
  approvalStatus: number | null;
  active: boolean | null;

  constructor({
    search,
    approvalStatus,
    active,
    ...baseParams
  }: IListUsersParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.approvalStatus =
      approvalStatus === "" ? null : TypesHelper.toNumberNull(approvalStatus);
    this.active = TypesHelper.toBooleanNull(active, [""]);
  }
}

export interface ICreateUserRequest {
  id: number;
  name?: string;
  email?: string;
  cpf?: string;
  cellPhone?: string;
  isMaster: boolean | "1" | "0" | "";
  active: boolean | "1" | "0" | "";
  dealershipId: number;
  permissions?: string[];
}

export class CreateUserRequest {
  id: number | null;
  name?: string | null;
  email?: string | null;
  cpf?: string | null;
  cellPhone?: string | null;
  isMaster?: boolean | null;
  isDt?: boolean | null;
  active: boolean | null;
  dealershipId?: number | null;
  permissions?: string[] | null;

  constructor({
    id,
    name,
    email,
    cpf,
    active,
    dealershipId,
    cellPhone,
    isMaster,
    permissions,
  }: ICreateUserRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.active = TypesHelper.toBooleanNull(active);
    this.dealershipId = TypesHelper.toNumberNull(dealershipId);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
    this.isMaster = TypesHelper.toBooleanNull(isMaster);
    this.permissions = permissions;
  }
}

export interface IUserDetailsResponse {
  name?: string;
  email?: string;
  cpf?: string;
  cellPhone?: string;
  isMaster: boolean;
  isDt: boolean;
  active: boolean;
  dealershipId: number;
  dealershipName: string;
  permissions?: string[];
}

export interface IListUserResponse {
  coxUserId: number;
  dealershipStatus: ApprovalStatusEnum;
  coxUserActive?: boolean;
  coxUserIsMaster?: boolean;
  dealershipCnpj?: string;
  dealershipName?: string;
  coxUserEmail?: string;
  coxUserName?: string;
}

export interface IListUserPagedResponse extends IPagedResponseBaseModel {
  data: IListUserResponse[];
}

export interface IDealershipComboData {
  id: number;
  name: string;
  cnpj?: string;
}
