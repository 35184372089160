import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-porto-seguro.organism.style";

export const AboutPortoSeguro = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("PortoSeguro")}</Title>
      <Content>
        <Trans>
          {t("ThePortoSeguro")}
          {t("TheCompany")}
          {t("AllSolutions")}
          {t("GetToKnowBelow")}
          <List>
            <ListItem>{t("PortoSeguroAuto")}</ListItem>
          </List>
          {t("ComprehensiveCoverage")}
          <List>
            <ListItem>{t("MaterialDamages")}</ListItem>
            <ListItem>{t("BodilyInjury")}</ListItem>
            <ListItem>{t("APP")}</ListItem>
            <ListItem>{t("IntermediateAssistance")}</ListItem>
            <ListItem>{t("CoverageOf")}</ListItem>
            <ListItem>{t("MandatoryExcess")}</ListItem>
          </List>
          {t("ComingSoon")}...
          <List>
            <ListItem>{t("PortoSegutoAutoWoman")}</ListItem>
            <ListItem>{t("PortoSegutoAutoYoung")}</ListItem>
            <ListItem>{t("PortoSegutoAutoSenior")}</ListItem>
            <ListItem>{t("PortoSegutoAutoPremium")}</ListItem>
          </List>
          <HighlightText>{t("WhoHasPortoSeguro")}</HighlightText>
          <List>
            <ListItem>{t("Wrecker24h")}</ListItem>
            <ListItem>{t("UpTo30")}</ListItem>
            <ListItem>{t("Insurance24h")}</ListItem>
            <ListItem>{t("CreditInTransportApplications")}</ListItem>
            <ListItem>{t("FreeServices")}</ListItem>
          </List>
          <HighlightText color='#00aeef'>{t("Commissioning")}:</HighlightText>
          <List>
            <ListItem>{t("PortoEveryInsuranceSale")}</ListItem>
            <ListItem>{t("BesidesSurprising")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem textColor='#0065A2' italic>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
