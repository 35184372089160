import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSessionContext } from "../../../../../hooks";
import { UploadDocumentRequest } from "../../../../../models";
import {
  FeedbackModal,
  RequestingInputtingFormMolecule,
} from "../../../../molecules";
export const RequestingInputtingOrganism: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const idNumber = isNaN(Number(id)) ? 0 : Number(id);

  const { vehicleContext } = useSessionContext();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<any>();
  const [plateVehicle, setPlateVehicle] =
    useState<UploadDocumentRequest | null>();

  useEffect(() => {
    setPlateVehicle(plateVehicle);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <RequestingInputtingFormMolecule
        setShowErrorModal={setShowErrorModal}
        setShowSuccessModal={setShowSuccessModal}
        setModalMessage={setModalMessage}
        initialValues={plateVehicle}
        apiUserId={idNumber}
        data={{
          fipeId: vehicleContext?.fipeId,
          ituranId: vehicleContext?.ituranId,
          kbbId: vehicleContext?.kbbId,
          brand: vehicleContext?.brand,
          modelYear: vehicleContext?.modelYear,
          version: vehicleContext?.version,
          model: vehicleContext?.model,
          cc: vehicleContext?.cc,
          molicarId: vehicleContext?.molicarId,
        }}
      />

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("api_error")}
      />

      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("Ok")}
        actionButtonText={t("GoToList")}
        actionButtonOnClick={() =>
          console.log("Tela de listagem será desenvolvida...")
        }
      />
    </>
  );
};
