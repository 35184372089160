import React from "react";

import { useTranslation } from "react-i18next";
import { DealershipsReport } from "../../../organisms";

export const DealershipsReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleDealershipsReport")}</h3>
      <DealershipsReport />
    </>
  );
};
