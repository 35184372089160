import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../..";
import { renderViewHelper, StringHelper } from "../../../../../helper";
import { IInsuranceCustomerDetails } from "../../../../../models";

interface IProps {
  customerDetails?: IInsuranceCustomerDetails;
}

export const InsuranceCustomerDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewCustomer = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.customerDetails, key, label, func);

  return !props.customerDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewCustomer("name", t("Name"))}
        {renderViewCustomer(
          "cpfCnpj",
          t("CpfCnpj"),
          StringHelper.formatCpfCnpj
        )}
        {renderViewCustomer("gender", t("Gender"))}
        {renderViewCustomer("maritalStatus", t("MaritalStatus"))}
        {renderViewCustomer("nationalityType", t("NationalityType"))}
        {renderViewCustomer(
          "phoneNumber",
          t("PhoneNumber"),
          StringHelper.formatPhone
        )}
        {renderViewCustomer(
          "income",
          t("Income"),
          (v) => `R$ ${NumberHelper.toFormatString(v, 2, "0,00")}`
        )}
      </Form.Row>
      <DetailsPageSubsection title={t("InsuranceCustomerMailingAddress")}>
        <Form.Row>
          {renderViewCustomer("addressType", t("AddressType"))}
          {renderViewCustomer("street", t("Street"))}
          {renderViewCustomer("number", t("Number"))}
          {renderViewCustomer("complement", t("Complement"))}
          {renderViewCustomer("neighborhood", t("Neighborhood"))}
          {renderViewCustomer(
            "zipCode",
            t("ZipCode"),
            StringHelper.formatZipCodeBR
          )}
          {renderViewCustomer("city", t("City"))}
          {renderViewCustomer("state", t("State"))}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
