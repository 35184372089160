import styled from "styled-components";

const borderRadius = "10px";

export type ICardRoutesProfileTypes = "standard" | "naRotaDoGol" | undefined;

export const WrapperCardRoutesProgram = styled.div`
  position: relative;
  width: 263px;
  height: 272px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${borderRadius};
`;

export const CardRouteItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  height: 100%;
`;

const TitleBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;

  text-align: center;

  color: ${({ theme }) => theme.colors.DarkGray};
`;

export const CardRoutesItemTitle = styled(TitleBase)`
  padding: 0 12px;
  height: 60px;
`;

export const CardRouteLabelTitle = styled(TitleBase)<{
  profile?: ICardRoutesProfileTypes;
}>`
  font-size: 16px;

  height: 40px;
  border-radius: 0 0 ${borderRadius} ${borderRadius};

  background-color: ${({ theme, profile }) =>
    profile === "naRotaDoGol" ? theme.colors.BlueNavy : "#EDC46C"};
  color: ${({ theme }) => theme.colors.white};
`;

export const CardRoutesCampaignLogo = styled.div`
  position: absolute;
  top: -6px;
  right: -2px;
`;
