import { format, parseISO, parse } from "date-fns";

export class DateHelper {
  static _DECIMAL = ",";
  static _MILHAR = ".";

  static toDateHourFixed(date: Date) {
    var dateReturn = date
      .toISOString()
      .replace(/T\d{2}:\d{2}:\d{2}\.\d+Z/, "T12:00:00.000");
    return new Date(dateReturn);
  }

  static dateFormat(value: string | undefined) {
    if (value) return format(parseISO(value), "dd/MM/yyyy");
    return "";
  }

  static customInputDateFormat(value: string | undefined, inputFormat: string) {
    if (value)
      return format(parse(value, inputFormat, new Date()), "dd/MM/yyyy");
    return "";
  }

  static dateHourFormat(value: string | undefined) {
    if (value) return format(parseISO(value), "dd/MM/yyyy HH:mm:ss");
    return "";
  }

  static dateHourCustomFormat(value: string | undefined, outputFormat: string) {
    if (value) return format(parseISO(value), outputFormat);
    return "";
  }

  static hourFormat(value: string | number | undefined) {
    if (value) return value + ":00";
    return "";
  }
  
}
