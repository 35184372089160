import {
  ButtonPrimary,
  ButtonSecondary,
  DateHelper,
  DownloadFileAtom,
  EnumHelper,
  FormikControlAtom,
  InsuranceCompanyEnum,
  TrackerPartnerEnum,
} from "c4u-web-components";
import React, { useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GetLogReportParams,
  IGenericSearchFormik,
  IGetLogReportParams,
  OriginEnum,
} from "../../../../models";
import { DownloadButtonStyledCol } from "./log-report-search-form.molecule.style";

export const LogReportSearchFormMolecule: React.FC<
  IGenericSearchFormik<IGetLogReportParams, GetLogReportParams>
> = (props) => {
  const { t } = useTranslation();

  const partnerCombo = () =>
    props.reportType === "Insurance"
      ? EnumHelper.getComboFromEnum(InsuranceCompanyEnum, t)
      : EnumHelper.getComboFromEnum(TrackerPartnerEnum, t);

  const defaultProps = {
    translate: t,
    formik: props.formik,
    md: 4,
    lg: 2,
  };

  const getFileParams = useMemo(
    () => new GetLogReportParams(props.formik.values),
    [props.formik.values]
  );

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => props.formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            {...defaultProps}
            lg={4}
            property={"search"}
            label={t("TextSearch")}
          />
          <FormikControlAtom
            {...defaultProps}
            type="date"
            property="startDate"
            label={t("InitialDate")}
          />

          <FormikControlAtom
            {...defaultProps}
            type="date"
            property="endDate"
            label={t("FinalDate")}
          />

          <FormikControlAtom
            {...defaultProps}
            as={"select"}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            {...defaultProps}
            type="select"
            data={partnerCombo()}
            property="partnerEnum"
            label={t("PartnerEnum")}
            placeholder={t("All")}
          />

          <FormikControlAtom
            {...defaultProps}
            type="select"
            data={EnumHelper.getComboFromEnum(OriginEnum, t)}
            property="origin"
            label={t("Origin")}
            placeholder={t("All")}
          />

          <Col sm={5} md={4} lg={3} xl={2} className="pt-4 text-center ml-auto">
            <ButtonSecondary
              sizex="md"
              disabled={props.formik.isSubmitting}
              onClick={props.cleanFiltersCallback}
            >
              {t("CleanFilters")}
            </ButtonSecondary>
          </Col>

          <Col sm={5} md={4} lg={3} xl={2} className="pt-4 text-center">
            <ButtonPrimary
              type="submit"
              sizex="md"
              loading={props.formik.isSubmitting}
            >
              {t("Search")}
            </ButtonPrimary>
          </Col>

          {props.downloadAllResultsCallback && (
            <DownloadButtonStyledCol sm={2} md={1} className="mt-4 text-center">
              <DownloadFileAtom
                getFileParams={getFileParams}
                getFileCallback={props.downloadAllResultsCallback}
                fileName={`${
                  props.tableName ?? "relatório"
                } ${DateHelper.dateHourCustomFormat(
                  new Date(),
                  "dd-MM-yyyy"
                )}.csv`}
                htmlTitle={t("DownloadCsvData")}
                className="d-flex align-items-center h-100 w-100"
              />
            </DownloadButtonStyledCol>
          )}
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
