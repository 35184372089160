import React from "react";

import { C4uInspectionList } from "../../../../organisms";

import { useTranslation } from "react-i18next";

export const C4uInspectionListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleC4uInspectionListPage")}</h3>
      <C4uInspectionList />
    </>
  );
};
