export enum ApprovalStatusEnum {
  Pending,
  Approved,
  Disapproved,
  Incomplete,
}

export enum CoxUserIsActiveBooleanEnum {
  Inactive,
  Active,
}

export enum CoxUserIsMasterBooleanEnum {
  Seller,
  Master,
}

export enum CorporateUserTypeEnum {
  Manager = 1,
  Consultant,
}

export enum AuctionTypeEnum {
  No,
  Yes,
}
