export class RequestError {

  errors: any;

  constructor(apiErrors: any) {
    this.errors = {};

    Object.keys(apiErrors).forEach(prop => {
      const propHandle = prop.trim().replace(/^x?\[\d\]\./i, '');
      const newProp = 
        propHandle.substr(0, 1).toLowerCase() + propHandle.substr(1);
      const value = apiErrors[prop];
      if(value && value.length > 0) {
        const [ message ] = value;
        var regEx = new RegExp(`'${newProp}'`, "ig");
        this.errors[newProp] = { text: message.replace(regEx, '') }; 
      }
    });
  }
}

export interface RequestError {
  message?: string;
  code?: string;
}

export class GeneralRequestMessageError {
  code?: string;
  message?: string;

  constructor(data?: RequestError) {
    this.message = data?.message;
    this.code = data?.code;
  }
}

export class GeneralRequestError {
  
  generalError: string;
  stack?: string;

  constructor(stack?: string) {
    this.stack = stack;
    this.generalError = "api_error";
  }
}
