import { TypesHelper } from "c4u-web-components";

export interface IUpdateUserRequest {
  id?: number;
  name?: string;
  cellPhone?: string;
  email?: string;
}

export class UpdateUserRequest {
  id: number | null;
  name: string | null;
  cellPhone: string | null;
  email: string | null;

  constructor({ id, name, cellPhone, email }: IUpdateUserRequest) {
    this.id = TypesHelper.toNumberNull(id)
    this.name = TypesHelper.toStringNull(name);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
    this.email = TypesHelper.toStringNull(email);
  }
}
