import styled from "styled-components";

import Col from "react-bootstrap/Col";

const ColStyled = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ColStyled };
