import styled from "styled-components";

interface LabelProps {
  for?: string;
}

interface SlideProps {
  id: string;
}

const ContentWrapper = styled.div`
  overflow-x: hidden;
  position: relative;
  left: 32px;
  max-width: 960px;
  margin: 0 auto;
  padding: 100px 0 60px;

  @media (max-width: 1280px) {
    overflow-y: hidden;
  }

  @media (max-width: 1096px) {
    max-width: 860px;
  }

  @media (max-width: 991px) {
    max-width: 600px;
  }

  @media (max-width: 575px) {
    max-width: 510px;
    left: 0px;
    padding-top: 70px;
  }
`;

const Title = styled.div`
  color: #1f3e74;

  padding-bottom: 25px;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;

  @media (max-width: 576px) {
    font-size: 20px;
    padding-bottom: 15px;
  }
`;

const BigCardsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ExtraCardDiv = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 0 0 15px;
  @media (max-width: 991px) {
    margin: 0;
  }

  p {
    max-width: ${(props) => (!props.hasImage ? "unset !important" : "")};
  }

  &.override-disabled-color {
    button:disabled {
      color: #bbb;
    }
  }

  #big-card-image-wrapper {
    width: unset;
  }

  #big-card-description-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    b {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  button {
    width: 264px;
  }
`;

const SmallCardsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 991px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const divHeight = "645px"; // 425px if not scrolling top card

const FixedHeightDiv = styled.div`
  position: relative;
  height: ${divHeight};
`;

const ButtonSrcollDiv = styled.div`
  border-radius: 20px;
  overflow-y: hidden;
  height: ${divHeight};
`;

const TranslucidDivBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0px;
  z-index: 3;

  height: 95px;
  width: 100%;
  border-radius: 20px;

  cursor: pointer;
`;

const TranslucidBottomDiv = styled(TranslucidDivBase)`
  background: linear-gradient(
    rgb(128, 130, 133, 0),
    70%,
    rgb(128, 130, 133, 1)
  );
  bottom: 0px;
`;

const TranslucidTopDiv = styled(TranslucidDivBase)`
  background: linear-gradient(
    to top,
    rgb(128, 130, 133, 0),
    70%,
    rgb(128, 130, 133, 1)
  );
  top: 0px;
`;

const Slider = styled.div`
  width: 100%;
  padding-bottom: 14px;

  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  --webkit-overflow-scrolling: touch;

  transition: all 0.4s;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Slides = styled.div`
  display: flex;
  justify-content: center;

  input {
    display: none;
  }

  /* 
  #radio1:checked ~ .first {
    margin-left: 0;
  }

  #radio2:checked ~ .first {
    margin-left: -29%;
  }

  #radio3:checked ~ .first {
    margin-left: -40%;
  }

  #radio4:checked ~ .first {
    margin-left: -60%;
  }

  #radio5:checked ~ .first {
    margin-left: -80%;
  } */
`;

const Slide = styled.div<SlideProps>`
  scroll-snap-align: start;

  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.4s;
  position: relative;

  transition: 0.4s;

  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const NavigationManual = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 16px;

  width: 100%;
`;

const ManualBtn = styled.label<LabelProps>`
  width: 12px;
  height: 12px;

  background: #dcdcdc;

  border-radius: 10px;

  cursor: pointer;
  transition: 0.4s;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    background: #a9a9a9;
  }

  &:checked {
    background: #5586d4;
  }
`;

const HeadLineDiv = styled.div`
  background: #ffecdb;
  border: 1px solid #eea200;
  border-radius: 4px;
  height: 40px;
  margin: auto;
  margin-bottom: 25px;

  @media (max-width: 991px) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  svg {
    margin-bottom: 2px;
  }
`;

const ManheimWarn = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16;
  font-weight: 400;
  padding-left: 3px;
`;

const ManheimWarnBold = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16;
  font-weight: 700;
`;

const ManheimLink = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16;
  font-weight: 700;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #0585c8;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
`;

const ManheimDismiss = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16;
  font-weight: 700;
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
`;

const LoadingText = styled.p`
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

export {
  ContentWrapper,
  SmallCardsDiv,
  BigCardsDiv,
  ExtraCardDiv,
  FixedHeightDiv,
  ButtonSrcollDiv,
  TranslucidBottomDiv,
  TranslucidTopDiv,
  ManualBtn,
  NavigationManual,
  Slider,
  Slides,
  Slide,
  Title,
  HeadLineDiv,
  ManheimWarnBold,
  ManheimWarn,
  ManheimLink,
  ManheimDismiss,
  LoadingContainer,
  LoadingText,
};
