import React from "react";

const MotorcycleOutlineIcon = () => (
  <svg
    width="70"
    height="45"
    viewBox="0 0 70 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13719_33260)">
      <path
        d="M61.9581 42.9232C67.626 40.5151 70.3174 33.8499 67.9697 28.0361C65.6219 22.2223 59.1241 19.4614 53.4562 21.8696C47.7882 24.2777 45.0966 30.943 47.4443 36.7568C49.792 42.5706 56.2901 45.3314 61.9581 42.9232Z"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1304 37.3423C28.2227 37.61 28.3929 37.8421 28.6182 38.0072C28.8435 38.1722 29.113 38.2622 29.3898 38.2649H43.511C43.7217 38.2587 43.9285 38.2036 44.1154 38.1037C44.3024 38.0039 44.4646 37.8618 44.5903 37.6882C44.7123 37.5135 44.7924 37.3116 44.8236 37.0991C44.8549 36.8867 44.8366 36.6696 44.7704 36.4658C44.3404 35.0546 44.1205 33.585 44.1182 32.1065C44.1196 29.1775 44.9923 26.3186 46.6196 23.9117C48.2469 21.5048 50.5514 19.6644 53.2253 18.6364C55.8745 17.8412 58.6643 17.6678 61.3878 18.129C61.6398 18.1911 61.9039 18.1761 62.1476 18.0859C62.3914 17.9957 62.6041 17.8344 62.7595 17.6216C65.8401 13.4238 66.0198 10.9327 63.4564 7.72669C60.1284 3.57497 57.093 1.45294 54.1248 1.19923C52.9094 1.16011 51.6994 1.38205 50.5726 1.85075C49.4457 2.31945 48.4268 3.02462 47.5812 3.92096C47.4381 4.0487 47.3235 4.20636 47.2449 4.38342C47.1663 4.56047 47.1255 4.75276 47.1255 4.9473C47.1255 5.14185 47.1663 5.33413 47.2449 5.51119C47.3235 5.68825 47.4381 5.84598 47.5812 5.97372L49.8298 7.88816C49.8298 8.28027 49.65 8.67235 49.5825 9.01833C49.4642 9.75806 49.276 10.4842 49.0203 11.1865L48.0983 10.6328C43.601 7.93423 40.0931 5.81225 36.4953 6.82712C35.0226 7.3936 33.6741 8.25407 32.5278 9.35866C31.3814 10.4632 30.4601 11.79 29.8169 13.2623L19.698 10.31L19.0684 8.32633C18.7916 7.35307 18.2281 6.4915 17.4552 5.85969C16.6822 5.22788 15.7378 4.85678 14.7512 4.79738L3.30541 2.81381C3.07054 2.77719 2.83041 2.80481 2.6093 2.89391C2.38818 2.98302 2.19407 3.13044 2.04632 3.32125C1.89266 3.51132 1.79431 3.74195 1.76249 3.9867C1.73068 4.23145 1.76651 4.48044 1.86625 4.70517C4.72202 11.6247 5.80147 13.4699 9.84901 15.5457L19.6755 20.5278C20.6872 20.8962 21.5985 21.5069 22.3337 22.3093C23.069 23.1116 23.607 24.0823 23.903 25.1408L28.1304 37.3423Z"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M48.9753 11.1865L39.9808 16.0763L48.9753 11.1865Z"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M29.9068 13.1699L39.8683 16.0992"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M53.8548 18.4521L55.0016 21.3353"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M16.5302 42.9233C22.1981 40.5152 24.8898 33.8499 22.542 28.0361C20.1943 22.2223 13.6962 19.4615 8.02827 21.8697C2.36034 24.2778 -0.331084 30.943 2.01665 36.7568C4.36438 42.5706 10.8622 45.3315 16.5302 42.9233Z"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
      <path
        d="M26.4664 32.3833H23.4532"
        stroke="#E74A27"
        strokeWidth="1.04"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_13719_33260">
        <rect width="70" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MotorcycleOutlineIcon;
