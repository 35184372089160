import {
  ButtonPrimary,
  DateHelper,
  FormikControlAtom,
  MaskHelper,
  YupHelper,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper, StatusHelper } from "../../../../../helper";
import { ComboOptionsFromEnumAtom } from "../../../../atoms";
import {
  AdStepEnum,
  IPostAdStepRequest,
  PostAdStepRequest,
} from "../../../../../models";

interface IProps {
  enabled: boolean;
  adId: number;
  setException: React.Dispatch<any>;
}
export const AdSetStepMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postAdStep } = useManhein();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDateTimePtBrValid",
    YupHelper.isDateTimePtBrValid
  );

  const validations = Yup.object<IPostAdStepRequest>({
    id: Yup.number().notOneOf([NaN]).required(),
    step: Yup.number().notOneOf([NaN]).required(),
    stepStatus: Yup.number().notOneOf([NaN]).required(),
    createdAt: Yup.string()
      .isDateTimePtBrValid(t("field_invalid_datetime"))
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      id: props.adId,
      createdAt: DateHelper.dateHourFormat(new Date()),
    } as IPostAdStepRequest,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmNewStepStatus"))) {
        try {
          await postAdStep(new PostAdStepRequest(values));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

  useEffect(() => {
    setDisableSaveButton(
      !(
        formik.values.step !== undefined &&
        formik.values.step.toString() !== "" &&
        formik.values.stepStatus !== undefined &&
        formik.values.stepStatus.toString() !== ""
      )
    );
  }, [formik.values.step, formik.values.stepStatus]);

  const getCustomOptionsText = useCallback(
    (v: string, k: string): string => {
      return `${k} - ${t(v)} (${v})`;
    },
    [t]
  );

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          as={"select"}
          md={4}
          property={"step"}
          label={t("AdChooseStep")}
          disabled={!props.enabled}
        >
          <ComboOptionsFromEnumAtom
            propertyKey="step"
            optionsEnum={AdStepEnum}
            includeSelectPlaceholder
            hideOptionAll
            notSorted
            getCustomOptionsText={getCustomOptionsText}
          />
        </FormikControlAtom>

        <FormikControlAtom
          translate={t}
          formik={formik}
          as={"select"}
          md={4}
          property={"stepStatus"}
          label={t("AdChooseStepStatus")}
          disabled={!props.enabled}
        >
          {formik.values.step !== undefined &&
          formik.values.step.toString() !== "" ? (
            <ComboOptionsFromEnumAtom
              propertyKey="stepStatus"
              optionsEnum={StatusHelper.getStepStatusEnum(formik.values.step)}
              includeSelectPlaceholder
              hideOptionAll
              notSorted
              getCustomOptionsText={getCustomOptionsText}
            />
          ) : (
            <option>{t("ChooseStepFirst")}</option>
          )}
        </FormikControlAtom>

        <FormikControlAtom
          translate={t}
          mask={MaskHelper.DateTime}
          formik={formik}
          property="createdAt"
          label={t("CreatedAt")}
          md={4}
        />
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled || disableSaveButton}
          >
            {t("SetStep")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
