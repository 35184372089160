import React from "react";
import Row from "react-bootstrap/Row";
import { RoundedCard } from "c4u-web-components";

import { ColStyled } from "./c4u-brands.molecule.style";
import { LogoDT, LogoKbb, LogoNewManheim } from "../../../atoms";

const C4uBrands = () => (
  <>
    <RoundedCard>
      <Row>
        <ColStyled xs={4} className="c4u-brands-col">
          <LogoKbb />
        </ColStyled>
        <ColStyled xs={4} className="c4u-brands-col p-0">
          <LogoDT />
        </ColStyled>
        <ColStyled xs={4} className="c4u-brands-col">
          <LogoNewManheim />
        </ColStyled>
      </Row>
    </RoundedCard>
  </>
);

export { C4uBrands };
