import { TermsOfUserMolecule } from "../ui/molecules";
import {
  AboutBanksPage,
  AboutPartnersPage,
  AdCustomersReportPage,
  AdDeliveryPage,
  AdDetailPage,
  AdListPage,
  ApiParametersListPage,
  ApiUsageReportPage,
  ApiUserDetailsPage,
  ApiUsersListPage,
  AuthenticatePage,
  C4uInspectionDetailPage,
  C4uInspectionListPage,
  C4uInsuranceDetailPage,
  C4uInsuranceListPage,
  C4uTrackerDetailPage,
  C4uTrackerListPage,
  C4uZapayDetailPage,
  C4uZapayListPage,
  CorporateUserListPage,
  CorporateUserPage,
  CreateDealershipPage,
  CreateUserPage,
  DealershipsReportPage,
  DtMockDiscoverPage,
  EmailTemplateDetailsPage,
  EmailTemplatesListPage,
  FidelizaDataLoadBasePage,
  FidelizaDataLoadResidualBasePage,
  FidelizaLeadsLinkAdsListPage,
  FidelizaUsersListPage,
  GoDealertrackPage,
  InitialPage,
  InspectionReportLinkPage,
  InsuranceLogReports,
  KbbInspectionDetailPage,
  KbbInspectionListPage,
  KbbInsuranceDetailPage,
  KbbInsuranceListPage,
  KbbTrackerDetailPage,
  KbbTrackerListPage,
  KbbZapayDetailPage,
  KbbZapayListPage,
  LenderCreateUserPage,
  LenderOperatorDetailsPage,
  LenderUsersListPage,
  ListRequestsVehicleChangesPage,
  LogoutPage,
  MyEarningsPage,
  OnboardingPage,
  OnboardingsReportPage,
  ProfilePage,
  PromotionalMaterialsPage,
  ProtectedPage,
  RenavePage,
  RequestingInputtingPage,
  RobotsReportPage,
  RoutesProgramPage,
  SearchByPlatePage,
  SideMenu,
  TopBarMenu,
  TrackerLogReports,
  TrainingPage,
  UserAccountDetailsPage,
  UserAccountListPage,
  UserAccountNewDetailsPage,
  UserListPage,
  UserPage,
  UsersReportPage,
} from "../ui/pages";
import { SyncFormPage } from "../ui/pages/backoffice/insurance/sync/sync-form.page";
import {
  BackofficeC4uTemplate,
  BackofficeManheimTemplate,
  BackofficeTemplate,
  EmptyTemplate,
  MainTemplate,
  RegisterTemplate,
  SideMenuTemplate,
  TermsTemplate,
} from "../ui/templates";

import { PermissionEnum } from "c4u-web-components";

const paths = {
  menu: () => "/menu",
  topBarMenu: () => "/topbarmenu",
  home: () => "/",
  authenticate: () => "/authenticate",
  training: () => "/training",
  promotionalMaterials: () => "/promotional-materials",
  routesProgram: () => "/routes-program",
  myEarnings: () => "/my-earnings",
  renave: () => "/renave",
  logout: () => "/logout",
  dealertrack: () => "/dealertrack",
  createDealership: () => "/:createdByPublicId/dealership/create",
  createUser: () => "/user/create",
  protected: () => "/protected/",
  onboarding: () => "/onboarding/:token",
  backoffice: () => "/backoffice",
  aboutBanks: () => "/about-banks",
  aboutPartners: () => "/about-partners",
  backofficeUserAccountListPage: () => "/backoffice/user-accounts",
  backofficeUserAccountDetailsPage: (id: string | number | null = null) =>
    id
      ? `/backoffice/user-account-detail/${id}`
      : "/backoffice/user-account-detail/:id",
  backofficeUserAccountNewDetailsPage: (id: string | number | null = null) =>
    id ? `/backoffice/user-account/${id}` : "/backoffice/user-account/:id",
  backofficeAdListPage: () => "/backoffice/ads",
  backofficeAdDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/ad/${id}` : "/backoffice/ad/:id",
  backofficeAdDeliveryPage: (id: string | number | null = ":id") =>
    `/backoffice/ad-delivery/${id}`,
  resetPassword: () => "/reset-password/:token",
  inspectionReportLink: (filename: string | null = null) =>
    filename ? `/report-link/${filename}` : "/report-link/:filename",
  profile: () => "/profile",
  backofficeEditEmailTemplatePage: (id: string | number | null = null) =>
    id !== null
      ? `/backoffice/settings/email-template/${id}`
      : "/backoffice/settings/email-template/:id",
  backofficeListEmailTemplatesPage: () =>
    "/backoffice/settings/email-templates",
  backofficeAdCustomersReportPage: () => "/backoffice/ad-customers-report",
  backofficeDealershipsReportPage: () => "/backoffice/dealerships-report",
  backofficeUsersReportPage: () => "/backoffice/users-report",
  backofficeOnboardingsReportPage: () => "/backoffice/onboardings-report",
  backofficeC4uInspectionListPage: () => "/backoffice/cpv/inspection-list",
  backofficeC4uZapayListPage: () => "/backoffice/cpv/zapay-list",
  backofficeKbbInspectionListPage: () => "/backoffice/kbb/inspection-list",
  backofficeKbbZapayListPage: () => "/backoffice/kbb/zapay-list",
  backofficeC4uInspectionDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/cpv/inspection/${id}` : "/backoffice/cpv/inspection/:id",
  backofficeC4uZapayDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/cpv/zapay/${id}` : "/backoffice/cpv/zapay/:id",
  backofficeKbbInspectionDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/kbb/inspection/${id}` : "/backoffice/kbb/inspection/:id",
  backofficeKbbZapayDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/kbb/zapay/${id}` : "/backoffice/kbb/zapay/:id",
  backofficeInsuranceListPage: () => "/backoffice/insurance",
  backofficeInsuranceDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/insurance/${id}` : "/backoffice/insurance/:id",
  backofficeKbbInsuranceListPage: () => "/backoffice/kbbinsurance",
  backofficeKbbInsuranceDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/insurance/${id}` : "/backoffice/kbbinsurance/:id",
  backofficeTrackerListPage: () => "/backoffice/tracker",
  backofficeTrackerDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/tracker/${id}` : "/backoffice/tracker/:id",
  backofficeKbbTrackerListPage: () => "/backoffice/kbbtracker",
  backofficeKbbTrackerDetailPage: (id: string | number | null = null) =>
    id ? `/backoffice/tracker/${id}` : "/backoffice/tracker/:id",
  backofficeUserListPage: () => "/backoffice/users",
  backofficeUserPage: (id: string | number | null = ":id") =>
    id ? `/backoffice/users/${id}` : "/backoffice/users/:id",
  backofficeCorporateUserListPage: () => "/backoffice/corporate-users",
  backofficeCorporateUserPage: (id: string | number | null = null) =>
    `/backoffice/corporate-users/${id ? id : ":id"}`,
  backofficeApiUsersListPage: () => "/backoffice/api-users",
  backofficeApiUserDetailsPage: (id: string | number | null = ":id") =>
    id ? `/backoffice/api-users/${id}` : "/backoffice/api-users/:id",
  backofficeParametersListPage: () => "/backoffice/api-parameters",
  backofficeApiUsageReportPage: () => "/backoffice/api-usage-report",
  backofficeSearchByPlatePage: () => "/backoffice/search-by-plate",
  backofficeRequestingInputting: () => "/backoffice/requesting-inputting",
  backofficeListRequestingVehicleChanges: () =>
    "/backoffice/requesting-list-changes",
  backofficeLenderOperatorListUsers: () => "/backoffice/fideliza-users",
  backofficeLenderOperator: (id: string | number | null = ":id") =>
    id
      ? `/backoffice/lender-operator/${id}`
      : "/backoffice/lender-operator/:id",
  backofficeLeadsAdsList: () => "/backoffice/fideliza-leads-ads-list",
  backofficeFidelizaLoadData: () => "/backoffice/fideliza-import-data",
  backofficeFidelizaLoadDataResidual: () =>
    "/backoffice/fideliza-import-residual",
  backofficeLenderListUsers: () => "/backoffice/lender-list-users",
  backofficeLenderUser: (id: string | number | null = ":id") =>
    id ? `/backoffice/lender-user/${id}` : "/backoffice/lender-user/:id",
  backofficeDtMockSync: () => "/backoffice/dt-sync",
  backofficeDtMockDiscover: () => "/backoffice/dt-discover",
  backofficeInsuranceLogReports: () => "/backoffice/log-reports/insurance",
  backofficeTrackerLogReports: () => "/backoffice/log-reports/tracker",
  backofficeRobotLogReports: () => "/backoffice/log-reports/robots",
  termsOfUse: () => "/terms-of-use",
};

interface RouteDeclaration {
  path: string;
  component: React.FC | JSX.Element;
  title: string;
  template: React.FC | JSX.Element;
  protected: boolean | PermissionEnum | PermissionEnum[];
  backTo?: string;
  tenant?: string;
}

const routes: Array<RouteDeclaration> = [
  {
    path: paths.home(),
    component: ProtectedPage,
    backTo: paths.home(),
    title: "HomePage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.authenticate(),
    component: AuthenticatePage,
    backTo: paths.authenticate(),
    title: "AuthenticatePage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.training(),
    component: TrainingPage,
    backTo: paths.training(),
    title: "TrainingPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.promotionalMaterials(),
    component: PromotionalMaterialsPage,
    backTo: paths.promotionalMaterials(),
    title: "PromotionalMaterialsPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.routesProgram(),
    component: RoutesProgramPage,
    backTo: paths.routesProgram(),
    title: "RoutesProgramPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.myEarnings(),
    component: MyEarningsPage,
    backTo: paths.myEarnings(),
    title: "MyEarningsPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.renave(),
    component: RenavePage,
    backTo: paths.renave(),
    title: "RenavePage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.createUser(),
    component: CreateUserPage,
    title: "CreateUser",
    template: EmptyTemplate,
    protected: false,
  },
  {
    path: paths.createDealership(),
    component: CreateDealershipPage,
    title: "CreateDealership",
    template: RegisterTemplate,
    protected: false,
  },
  {
    path: paths.protected(),
    component: ProtectedPage,
    backTo: paths.home(),
    title: "ProtectedPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.onboarding(),
    component: OnboardingPage,
    backTo: paths.home(),
    title: "OnboardingPage",
    template: EmptyTemplate,
    protected: true,
  },
  {
    path: paths.menu(),
    component: SideMenu,
    title: "Cox4UMenu",
    template: SideMenuTemplate,
    protected: true,
  },
  {
    path: paths.topBarMenu(),
    component: TopBarMenu,
    title: "TopBarMenu",
    template: SideMenuTemplate,
    protected: true,
  },
  {
    path: paths.logout(),
    component: LogoutPage,
    title: "Logout",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.dealertrack(),
    component: GoDealertrackPage,
    title: "GoDealertrackPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.backoffice(),
    component: InitialPage,
    backTo: paths.backoffice(),
    title: "Backoffice",
    template: BackofficeTemplate,
    protected: [
      PermissionEnum.BackofficeAds,
      PermissionEnum.BackofficeRegistration,
    ],
  },
  {
    path: paths.backofficeUserAccountListPage(),
    component: UserAccountListPage,
    backTo: paths.backoffice(),
    title: "backofficeUserAccountListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeUserAccountDetailsPage(),
    component: UserAccountDetailsPage,
    backTo: paths.backofficeUserAccountListPage(),
    title: "backofficeUserAccountDetailsPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeUserAccountNewDetailsPage(),
    component: UserAccountNewDetailsPage,
    backTo: paths.backofficeUserAccountListPage(),
    title: "backofficeUserAccountNewDetailsPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeAdListPage(),
    component: AdListPage,
    backTo: paths.backoffice(),
    title: "backofficeAdListPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAds,
    tenant: "manheim",
  },
  {
    path: paths.backofficeUserListPage(),
    component: UserListPage,
    backTo: paths.backoffice(),
    title: "backofficeUsersListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeUsers,
  },
  {
    path: paths.backofficeUserPage(),
    component: UserPage,
    backTo: paths.backoffice(),
    title: "backofficeUser",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeUsers,
  },
  {
    path: paths.backofficeCorporateUserListPage(),
    component: CorporateUserListPage,
    backTo: paths.backoffice(),
    title: "backofficeCorporateUsersListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeCorporateUsers,
  },
  {
    path: paths.backofficeCorporateUserPage(),
    component: CorporateUserPage,
    backTo: paths.backoffice(),
    title: "backofficeCorporateUser",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeCorporateUsers,
  },
  {
    path: paths.backofficeApiUsersListPage(),
    component: ApiUsersListPage,
    backTo: paths.backoffice(),
    title: "backofficeApiUsersListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeApiUserDetailsPage(),
    component: ApiUserDetailsPage,
    backTo: paths.backoffice(),
    title: "backofficeApiUsers",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeParametersListPage(),
    component: ApiParametersListPage,
    backTo: paths.backoffice(),
    title: "backofficeApiParametersListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeApiUsageReportPage(),
    component: ApiUsageReportPage,
    backTo: paths.backoffice(),
    title: "backofficeApiUsageReportPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeSearchByPlatePage(),
    component: SearchByPlatePage,
    backTo: paths.backoffice(),
    title: "backofficeSearchByPlatePage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeRequestingInputting(),
    component: RequestingInputtingPage,
    backTo: paths.backoffice(),
    title: "backofficeRequestingInputting",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeListRequestingVehicleChanges(),
    component: ListRequestsVehicleChangesPage,
    backTo: paths.backoffice(),
    title: "backofficeListRequestingVehicleChanges",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },
  {
    path: paths.backofficeAdDetailPage(),
    component: AdDetailPage,
    backTo: paths.backofficeAdListPage(),
    title: "backofficeAdDetailPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAds,
    tenant: "manheim",
  },
  {
    path: paths.backofficeAdDeliveryPage(),
    component: AdDeliveryPage,
    backTo: paths.backofficeAdListPage(),
    title: "backofficeAdDetailPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAds,
    tenant: "manheim",
  },
  {
    path: paths.resetPassword(),
    component: OnboardingPage,
    backTo: paths.home(),
    title: "ResetPassword",
    template: EmptyTemplate,
    protected: false,
  },
  {
    path: paths.inspectionReportLink(),
    component: InspectionReportLinkPage,
    title: "backofficeInspectionReportLinkPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeProduct,
    tenant: "manheim",
  },
  {
    path: paths.profile(),
    component: ProfilePage,
    backTo: paths.home(),
    title: "ProfilePage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.backofficeAdCustomersReportPage(),
    component: AdCustomersReportPage,
    backTo: paths.backoffice(),
    title: "backofficeAdCustomersReportPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAds,
    tenant: "manheim",
  },
  {
    path: paths.backofficeDealershipsReportPage(),
    component: DealershipsReportPage,
    backTo: paths.backoffice(),
    title: "backofficeDealershipsReportPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeUsersReportPage(),
    component: UsersReportPage,
    backTo: paths.backoffice(),
    title: "backofficeUsersReportPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeOnboardingsReportPage(),
    component: OnboardingsReportPage,
    backTo: paths.backoffice(),
    title: "backofficeOnboardingsReportPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeRegistration,
  },
  {
    path: paths.backofficeC4uInspectionListPage(),
    component: C4uInspectionListPage,
    backTo: paths.backoffice(),
    title: "backofficeC4uInspectionListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeC4uZapayListPage(),
    component: C4uZapayListPage,
    backTo: paths.backoffice(),
    title: "backofficeC4uZapayListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeKbbInspectionListPage(),
    component: KbbInspectionListPage,
    backTo: paths.backoffice(),
    title: "backofficeKbbInspectionListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeKbbZapayListPage(),
    component: KbbZapayListPage,
    backTo: paths.backoffice(),
    title: "backofficeKbbZapayListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeC4uInspectionDetailPage(),
    component: C4uInspectionDetailPage,
    backTo: paths.backofficeC4uInspectionListPage(),
    title: "backofficeC4uInspectionDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeC4uZapayDetailPage(),
    component: C4uZapayDetailPage,
    backTo: paths.backofficeC4uZapayListPage(),
    title: "backofficeC4uZapayDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeKbbInspectionDetailPage(),
    component: KbbInspectionDetailPage,
    backTo: paths.backofficeKbbInspectionListPage(),
    title: "backofficeKbbInspectionDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeKbbZapayDetailPage(),
    component: KbbZapayDetailPage,
    backTo: paths.backofficeKbbZapayListPage(),
    title: "backofficeKbbZapayDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeProduct,
  },
  {
    path: paths.backofficeInsuranceListPage(),
    component: C4uInsuranceListPage,
    backTo: paths.backoffice(),
    title: "backofficeInsuranceListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeInsurance,
  },
  {
    path: paths.backofficeInsuranceDetailPage(),
    component: C4uInsuranceDetailPage,
    backTo: paths.backofficeInsuranceListPage(),
    title: "backofficeInsuranceDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeInsurance,
  },
  {
    path: paths.backofficeKbbInsuranceListPage(),
    component: KbbInsuranceListPage,
    backTo: paths.backoffice(),
    title: "backofficeKbbInsuranceListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeInsurance,
  },
  {
    path: paths.backofficeKbbInsuranceDetailPage(),
    component: KbbInsuranceDetailPage,
    backTo: paths.backofficeKbbInsuranceListPage(),
    title: "backofficeKbbInsuranceDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeInsurance,
  },
  {
    path: paths.backofficeEditEmailTemplatePage(),
    component: EmailTemplateDetailsPage,
    backTo: paths.backoffice(),
    title: "backofficeEmailTemplateDetailPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAdsCommunication,
    tenant: "manheim",
  },
  {
    path: paths.backofficeListEmailTemplatesPage(),
    component: EmailTemplatesListPage,
    backTo: paths.backoffice(),
    title: "backofficeEmailTemplatesListPage",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackofficeAdsCommunication,
    tenant: "manheim",
  },
  {
    path: paths.backofficeTrackerListPage(),
    component: C4uTrackerListPage,
    backTo: paths.backoffice(),
    title: "backofficeTrackerListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeTracker,
  },
  {
    path: paths.backofficeKbbTrackerListPage(),
    component: KbbTrackerListPage,
    backTo: paths.backoffice(),
    title: "backofficeKbbTrackerListPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeTracker,
  },
  {
    path: paths.backofficeKbbTrackerDetailPage(),
    component: KbbTrackerDetailPage,
    backTo: paths.backofficeKbbTrackerListPage(),
    title: "backofficeTrackerDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeTracker,
  },
  {
    path: paths.backofficeTrackerDetailPage(),
    component: C4uTrackerDetailPage,
    backTo: paths.backofficeTrackerListPage(),
    title: "backofficeTrackerDetailPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeTracker,
  },
  {
    path: paths.aboutBanks(),
    component: AboutBanksPage,
    backTo: paths.home(),
    title: "AboutBanksPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.aboutPartners(),
    component: AboutPartnersPage,
    backTo: paths.home(),
    title: "AboutPartnersPage",
    template: MainTemplate,
    protected: true,
  },
  {
    path: paths.backofficeLenderOperatorListUsers(),
    component: FidelizaUsersListPage,
    backTo: paths.backoffice(),
    title: "backofficeLenderOperatorListUsers",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeLeadsAdsList(),
    component: FidelizaLeadsLinkAdsListPage,
    backTo: paths.backoffice(),
    title: "backofficeLeadsAdsList",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeLenderOperator(),
    component: LenderOperatorDetailsPage,
    backTo: paths.backoffice(),
    title: "backofficeLenderOperator",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeLenderListUsers(),
    component: LenderUsersListPage,
    backTo: paths.backoffice(),
    title: "backofficeLenderListUsers",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeLenderUser(),
    component: LenderCreateUserPage,
    backTo: paths.backoffice(),
    title: "backofficeLenderUser",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeFidelizaLoadData(),
    component: FidelizaDataLoadBasePage,
    backTo: paths.backoffice(),
    title: "backofficeFidelizaLoadData",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeFidelizaLoadDataResidual(),
    component: FidelizaDataLoadResidualBasePage,
    backTo: paths.backoffice(),
    title: "backofficeFidelizaLoadDataResidualVehicle",
    template: BackofficeManheimTemplate,
    protected: PermissionEnum.BackOfficeAdsFideliza,
    tenant: "manheim",
  },
  {
    path: paths.backofficeDtMockSync(),
    component: SyncFormPage,
    backTo: paths.backoffice(),
    title: "syncFormDT",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeWebhook,
  },
  {
    path: paths.backofficeDtMockDiscover(),
    component: DtMockDiscoverPage,
    backTo: paths.backoffice(),
    title: "backofficeDtDiscoverPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeWebhook,
  },
  {
    path: paths.backofficeInsuranceLogReports(),
    component: InsuranceLogReports,
    backTo: paths.backoffice(),
    title: "backofficeInsuranceLogReportsPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeQuotesLog,
  },
  {
    path: paths.backofficeTrackerLogReports(),
    component: TrackerLogReports,
    backTo: paths.backoffice(),
    title: "backofficeTrackerLogReportsPage",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeQuotesLog,
  },
  {
    path: paths.backofficeRobotLogReports(),
    component: RobotsReportPage,
    backTo: paths.backoffice(),
    title: "backofficeRobotLogReports",
    template: BackofficeC4uTemplate,
    protected: PermissionEnum.BackofficeKbbApis,
  },

  {
    path: paths.termsOfUse(),
    component: TermsOfUserMolecule,
    backTo: paths.authenticate(),
    title: "termsOfUse",
    template: TermsTemplate,
    protected: true,
  },
];

export { routes as default, paths };
export type { RouteDeclaration };
