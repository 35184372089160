import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutPage = () => {
  const { user, logout } = useAuth0();

  useEffect(() => {
    const returnUrl = process.env.REACT_APP_MASTER_PAGE ?? window.location.origin;
    logout({returnTo: returnUrl});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <span> Adios { user?.name } </span>;
};
