import { DateHelper } from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection, PaginatableTableList } from "../../..";
import { renderViewHelper } from "../../../../../helper";
import { ICarDeliverySellerModel } from "../../../../../models";

interface IProps {
  data: ICarDeliverySellerModel;
}
export const AdCarSellerDeliveryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const writeBoolean = (bool: boolean | undefined) => {
    return bool ? t("Yes") : t("No");
  };

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.data, key, label, func, 3);

  const renderViewPhotoLabel = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.data, key, label, func, 12);

  const stepSeller = (value: any) => {
    switch (value) {
      case 0:
        return t("Login");
      case 1:
        return t("SellerData");
      case 2:
        return t("TimeUpdate");
      case 3:
        return t("Checklist");
      case 4:
        return t("Finished");

      default:
        return value;
    }
  };

  const [hideImage1, setHideImage1] = useState<boolean>(false);
  const [hideImage2, setHideImage2] = useState<boolean>(false);
  const [hideImage3, setHideImage3] = useState<boolean>(false);
  const [hideImage4, setHideImage4] = useState<boolean>(false);

  return (
    <>
      <Form.Row>
        {renderView("sellerId", t("Id"))}
        {renderView("sellerDeliveryStep", t("Status"), stepSeller)}
        {renderView("sellerCheckListObservation", t("Observation"))}
      </Form.Row>

      <DetailsPageSubsection title={t("DeliverySellerChecklistDetails")}>
        <Form.Row className="d-flex align-items-end">
          <Col md={3} sm={6}>
            {props.data.sellerDUTATPVPhoto && (
              <img
                width={"100%"}
                src={props.data.sellerDUTATPVPhoto}
                alt={"sellerDUTATPVPhoto"}
                onError={() => setHideImage1(true)}
                hidden={hideImage1}
              />
            )}
            <Form.Row>
              {renderViewPhotoLabel(
                "sellerCheckListDUTATPV",
                t("DUT/ATPV"),
                writeBoolean
              )}
            </Form.Row>
          </Col>
          <Col md={3} sm={6}>
            {props.data.sellerCRLVPhoto && (
              <img
                width={"100%"}
                src={props.data.sellerCRLVPhoto}
                alt={"sellerCRLVPhoto"}
                onError={() => setHideImage2(true)}
                hidden={hideImage2}
              />
            )}
            <Form.Row>
              {renderViewPhotoLabel(
                "sellerCheckListCRLV",
                t("CRLV"),
                writeBoolean
              )}
            </Form.Row>
          </Col>
          <Col md={3} sm={6}>
            {props.data.sellerBackupKeyPhoto && (
              <img
                width={"100%"}
                src={props.data.sellerBackupKeyPhoto}
                alt={"sellerBackupKeyPhoto"}
                onError={() => setHideImage3(true)}
                hidden={hideImage3}
              />
            )}
            <Form.Row>
              {renderViewPhotoLabel(
                "sellerCheckListBackupKey",
                t("BackupKey"),
                writeBoolean
              )}
            </Form.Row>
          </Col>
          <Col md={3} sm={6}>
            {props.data.sellerVehicleManualPhoto && (
              <img
                width={"100%"}
                src={props.data.sellerVehicleManualPhoto}
                alt={"sellerVehicleManualPhoto"}
                onError={() => setHideImage4(true)}
                hidden={hideImage4}
              />
            )}
            <Form.Row>
              {renderViewPhotoLabel(
                "sellerCheckListVehicleManual",
                t("VehicleManual"),
                writeBoolean
              )}
            </Form.Row>
          </Col>
        </Form.Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("CNHs")}>
        <Row>
          <Col md={6}>{t("Seller CNH")}</Col>
        </Row>
        <Row>
          <Col md={6}>
            {props.data.sellerCNHPhoto && (
              <img
                src={props.data.sellerCNHPhoto}
                alt={t("Seller CNH")}
                style={{ width: "100%" }}
              />
            )}
          </Col>
        </Row>
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliverySellerJourneyStepDetails")}>
        {!props.data.adDeliverySteps.length ? (
          <i>{t("No information")}</i>
        ) : (
          <PaginatableTableList
            tableName={"seller-steps-history"}
            dataIsLoading={false}
            thead={[t("DeliveryStepNumber"), t("DeliveryStepName"), t("Date")]}
            tbody={props.data.adDeliverySteps?.map((m, i) => [
              m.step,
              stepSeller(m.step),
              DateHelper.dateHourFormat(m.date),
            ])}
          />
        )}
      </DetailsPageSubsection>

      <DetailsPageSubsection title={t("DeliverySellerTripUpdateDetails")}>
        {!props.data.adDeliverySchedules.length ? (
          <i>{t("No information")}</i>
        ) : (
          <PaginatableTableList
            tableName={"seller-steps-history"}
            dataIsLoading={false}
            thead={[
              t("DeliveryInitialDate"),
              t("DeliveryEstimatedDate"),
              t("DeliveryScheduleCancelled"),
            ]}
            tbody={props.data.adDeliverySchedules?.map((m, i) => [
              DateHelper.dateHourFormat(m.initialDate),
              DateHelper.dateHourFormat(m.estimateDate),
              writeBoolean(m.cancelSchedule),
            ])}
          />
        )}
      </DetailsPageSubsection>
    </>
  );
};
