import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../../..";
import { renderViewHelper, StringHelper } from "../../../../../../helper";
import { ITrackerDriverAndUsageDetails } from "../../../../../../models";

interface IProps {
  driverAndUsageDetails?: ITrackerDriverAndUsageDetails;
}

export const TrackerDriverAndUserDetailsMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  const renderViewVehicle = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.driverAndUsageDetails, key, label, func);

  const convertYesNo = (v: boolean | string) => {
    if (typeof v === "string") v = Boolean(parseInt(v));
    return v ? t("Yes") : t("No");
  };
  const convertYesNoWork = (v: string) => {
    switch (v) {
      case "0":
        return t("No");
      case "1":
        return t("Yes");
      case "2":
        return t("DontUseForWork");
    }
    return v;
  };
  const convertYesNoSchool = (v: string) => {
    switch (v) {
      case "0":
        return t("No");
      case "1":
        return t("Yes");
      case "2":
        return t("DontUseForSchool");
    }
    return v;
  };

  return !props.driverAndUsageDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewVehicle(
          "overnightZipCode",
          t("OvernightZipCode"),
          StringHelper.formatZipCodeBR
        )}
        {renderViewVehicle(
          "driversBetween18and26",
          t("DriversBetween18and26"),
          convertYesNo
        )}
        {renderViewVehicle(
          "vehicleUseToWork",
          t("VehicleUseToWork"),
          convertYesNo
        )}
        {renderViewVehicle(
          "storedClosedGarage",
          t("StoredClosedGarage"),
          convertYesNo
        )}
        {renderViewVehicle(
          "storedClosedGarageAtHome",
          t("StoredClosedGarageAtHome"),
          convertYesNo
        )}
        {renderViewVehicle(
          "storedClosedGarageAtWork",
          t("StoredClosedGarageAtWork"),
          convertYesNoWork
        )}
        {renderViewVehicle(
          "storedClosedGarageAtSchool",
          t("StoredClosedGarageAtSchool"),
          convertYesNoSchool
        )}
      </Form.Row>

      <DetailsPageSubsection title={t("TrackerMainDriverDetails")}>
        <Form.Row>
          {renderViewVehicle("mainDriver", t("MainDriver"), convertYesNo)}
          {renderViewVehicle("licenseTime", t("LicenseTime"), (v: string) =>
            v.replace(/_/g, " ")
          )}
        </Form.Row>
        {props.driverAndUsageDetails.mainDriver === "0" && (
          <Form.Row>
            {renderViewVehicle("driverName", t("Name"))}
            {renderViewVehicle("driverPersonalDocument", t("Cpf"))}
            {renderViewVehicle("driverBirthdate", t("Birthdate"))}
            {renderViewVehicle("driverGender", t("Gender"))}
            {renderViewVehicle("driverMaritalStatus", t("MaritalStatus"))}
            {renderViewVehicle("driverLink", t("DriverRelationship"))}
          </Form.Row>
        )}
      </DetailsPageSubsection>
    </>
  );
};
