import { Link } from "react-router-dom";
import styled from "styled-components";

const CenterAlignedContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
`;
const LinkBack = styled(Link)`
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 22px !important;
  /* identical to box height, or 157% */

  text-transform: uppercase;

  /* Blue Navy */

  color: #1f3e74 !important;
`;

export { CenterAlignedContent, LinkBack };
