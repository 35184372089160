import React, { useEffect } from "react";

import { ContentWrapper } from "./profile.page.style";

import { EditProfileOrganism } from "../../organisms";
import { Breadcrumb, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useSessionContext } from "../../../hooks";

export const ProfilePage = () => {
  const { t } = useTranslation();

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const { formContext } = useSessionContext();

  const breadcrumb = () => {
    switch (formContext) {
      case "personal":
        return "Personal data";

      case "password":
        return "Personal password";

      case "store":
        return "Data store";

      case "sellers":
        return "Sellers";
    }
  };

  return (
    <>
      <ContentWrapper>
        <Container className={"mb-4"}>
          <Row>
            <Col>
              <Breadcrumb>{`${t("Home")} / ${t("Profile")} / ${t(
                breadcrumb()
              )}`}</Breadcrumb>
            </Col>
          </Row>
          {isLoading ? (
            <div
              className={
                "h-100 d-flex align-items-center justify-content-center"
              }
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <EditProfileOrganism />
          )}
        </Container>
      </ContentWrapper>
    </>
  );
};
