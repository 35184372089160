import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import profilePic from "../../assets/images/profilepic.png";

import {
  Container,
  MenuButton,
  InnerButton,
  ImageWrapper,
  ExpandedButton,
  ButtonText,
  Profile,
  ProfileImg,
  ProfileCamIcon,
  ProfileButtonImg,
  ProfileButtonName,
  ProfileButtonExit,
  ExpandedProfileImgButton,
} from "./menu.page.style";

export const SideMenu = () => {
  const { user } = useAuth0();

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Profile>
        <ProfileImg src={profilePic} />
        <ExpandedProfileImgButton>
          <ProfileCamIcon />
          <ProfileButtonName>
            {user?.name && user?.name.slice(0, 15)}
            <ProfileButtonImg src="/pencil.svg" alt="pencil" />
          </ProfileButtonName>
          <ProfileButtonExit
            onClick={() =>
              window.top &&
              (window.top.location.href = `${process.env.REACT_APP_BASE_URL}#/logout`)
            }
          >
            sair
            <ProfileButtonImg src="exit.svg" alt="exit" />
          </ProfileButtonExit>
        </ExpandedProfileImgButton>
      </Profile>
      <MenuButton>
        <InnerButton>
          <ImageWrapper>
            <svg
              width="23"
              height="17"
              viewBox="0 0 23 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4269 12.375H4.7947V7.21875H2.58397V15.4688C2.58397 16.0392 3.07793 16.5 3.68933 16.5H12.5323C13.1437 16.5 13.6376 16.0392 13.6376 15.4688V7.21875H11.4269V12.375ZM22.2941 4.58262L19.3476 0.457617C19.1403 0.170801 18.7949 0 18.4253 0H4.42855C4.05894 0 3.71352 0.170801 3.50971 0.457617L0.563217 4.58262C0.0727098 5.26904 0.597759 6.1875 1.48205 6.1875H21.3752C22.2561 6.1875 22.7811 5.26904 22.2941 4.58262ZM18.0591 15.9844C18.0591 16.268 18.3078 16.5 18.6118 16.5H19.7172C20.0211 16.5 20.2699 16.268 20.2699 15.9844V7.21875H18.0591V15.9844Z"
                fill="#F9F9F9"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton
            onClick={() =>
              window.top &&
              (window.top.location.href = `${process.env.REACT_APP_BASE_URL}#/user/create`)
            }
          >
            <ButtonText>home</ButtonText>
          </ExpandedButton>
        </InnerButton>

        <InnerButton>
          <ImageWrapper>
            <svg
              width="24"
              height="8"
              viewBox="0 0 24 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.375 8V0H2.02829V3.55252L5.36837 0H7.59137L4.50821 3.11596L7.75893 8H5.61971L3.36879 4.24557L2.02829 5.58254V8H0.375Z"
                fill="#F9F9F9"
              />
              <path
                d="M8.62467 0H11.8977C12.5456 0 13.0278 0.0272851 13.3444 0.0818554C13.6646 0.132788 13.9494 0.241928 14.1989 0.409277C14.4521 0.576626 14.6625 0.800364 14.8301 1.08049C14.9976 1.35698 15.0814 1.66803 15.0814 2.01364C15.0814 2.38836 14.9772 2.73215 14.7686 3.04502C14.5638 3.35789 14.2846 3.59254 13.9308 3.74898C14.4298 3.89086 14.8133 4.13279 15.0814 4.47476C15.3495 4.81673 15.4836 5.21874 15.4836 5.68076C15.4836 6.04457 15.3961 6.39927 15.2211 6.74488C15.0498 7.08686 14.8133 7.36153 14.5117 7.56889C14.2138 7.77262 13.8452 7.89814 13.4058 7.94543C13.1302 7.97453 12.4656 7.99272 11.4118 8H8.62467V0ZM10.278 1.33151V3.18145H11.3615C12.0057 3.18145 12.406 3.17235 12.5624 3.15416C12.8454 3.12142 13.0669 3.02683 13.2271 2.8704C13.3909 2.71032 13.4728 2.50114 13.4728 2.24284C13.4728 1.99545 13.4021 1.79536 13.2606 1.64256C13.1228 1.48613 12.9161 1.39154 12.6406 1.3588C12.4768 1.34061 12.0057 1.33151 11.2275 1.33151H10.278ZM10.278 4.51296V6.65211H11.8084C12.4041 6.65211 12.7821 6.63574 12.9422 6.603C13.188 6.55935 13.3872 6.45384 13.5398 6.28649C13.6962 6.11551 13.7744 5.88813 13.7744 5.60437C13.7744 5.36426 13.7149 5.16053 13.5957 4.99318C13.4765 4.82583 13.3034 4.70396 13.0763 4.62756C12.8528 4.55116 12.365 4.51296 11.6129 4.51296H10.278Z"
                fill="#F9F9F9"
              />
              <path
                d="M16.8911 0H20.1642C20.8121 0 21.2943 0.0272851 21.6108 0.0818554C21.931 0.132788 22.2159 0.241928 22.4654 0.409277C22.7186 0.576626 22.9289 0.800364 23.0965 1.08049C23.2641 1.35698 23.3478 1.66803 23.3478 2.01364C23.3478 2.38836 23.2436 2.73215 23.0351 3.04502C22.8303 3.35789 22.551 3.59254 22.1973 3.74898C22.6962 3.89086 23.0798 4.13279 23.3478 4.47476C23.6159 4.81673 23.75 5.21874 23.75 5.68076C23.75 6.04457 23.6625 6.39927 23.4875 6.74488C23.3162 7.08686 23.0798 7.36153 22.7781 7.56889C22.4802 7.77262 22.1116 7.89814 21.6722 7.94543C21.3967 7.97453 20.732 7.99272 19.6782 8H16.8911V0ZM18.5444 1.33151V3.18145H19.628C20.2721 3.18145 20.6724 3.17235 20.8288 3.15416C21.1118 3.12142 21.3334 3.02683 21.4935 2.8704C21.6573 2.71032 21.7392 2.50114 21.7392 2.24284C21.7392 1.99545 21.6685 1.79536 21.527 1.64256C21.3892 1.48613 21.1826 1.39154 20.907 1.3588C20.7432 1.34061 20.2721 1.33151 19.4939 1.33151H18.5444ZM18.5444 4.51296V6.65211H20.0748C20.6706 6.65211 21.0485 6.63574 21.2086 6.603C21.4544 6.55935 21.6536 6.45384 21.8063 6.28649C21.9627 6.11551 22.0409 5.88813 22.0409 5.60437C22.0409 5.36426 21.9813 5.16053 21.8621 4.99318C21.743 4.82583 21.5698 4.70396 21.3427 4.62756C21.1193 4.55116 20.6315 4.51296 19.8793 4.51296H18.5444Z"
                fill="#F9F9F9"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton>
            <ButtonText>kelley blue book</ButtonText>
          </ExpandedButton>
        </InnerButton>

        <InnerButton>
          <ImageWrapper>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.4"
                x="0.375"
                width="22"
                height="22"
                rx="0.984375"
                fill="#A8AAAC"
              />
              <path
                d="M15.375 15.4993C15.375 15.4093 15.3968 15.3258 15.4405 15.2487C15.4846 15.1716 15.5454 15.111 15.6229 15.0668C15.7008 15.0223 15.7849 15 15.875 15C15.9651 15 16.0489 15.0223 16.1264 15.0668C16.2043 15.111 16.2651 15.1716 16.3088 15.2487C16.3529 15.3258 16.375 15.4093 16.375 15.4993C16.375 15.5902 16.3527 15.6745 16.3081 15.752C16.2635 15.8291 16.2025 15.8897 16.125 15.9338C16.048 15.9779 15.9647 16 15.875 16C15.7844 16 15.7004 15.9777 15.6229 15.9332C15.5459 15.8886 15.4853 15.8278 15.4412 15.7507C15.3971 15.6736 15.375 15.5898 15.375 15.4993ZM16.2884 15.4993C16.2884 15.4254 16.2708 15.3567 16.2356 15.2934C16.2004 15.2297 16.1506 15.1789 16.0863 15.141C16.0219 15.1027 15.9515 15.0836 15.875 15.0836C15.8004 15.0836 15.7309 15.1018 15.6665 15.1384C15.6027 15.1749 15.5525 15.2253 15.5158 15.2894C15.4792 15.3536 15.4609 15.4236 15.4609 15.4993C15.4609 15.5751 15.479 15.6451 15.5151 15.7092C15.5518 15.7734 15.602 15.8242 15.6658 15.8616C15.7302 15.8986 15.7999 15.9171 15.875 15.9171C15.9501 15.9171 16.0198 15.8982 16.0842 15.8603C16.1489 15.8224 16.1992 15.7714 16.2349 15.7072C16.2705 15.643 16.2884 15.5738 16.2884 15.4993ZM15.7736 15.5501V15.7754H15.6673V15.2072H15.8651C15.936 15.2072 15.9919 15.2226 16.0327 15.2533C16.0736 15.2836 16.094 15.3269 16.094 15.383C16.094 15.4329 16.0675 15.4717 16.0144 15.4993C16.0431 15.5131 16.063 15.5325 16.0743 15.5575C16.086 15.582 16.0919 15.6125 16.0919 15.6491C16.0919 15.6856 16.0926 15.7115 16.094 15.7266C16.0959 15.7418 16.0992 15.7545 16.1039 15.7647V15.7754H15.9947C15.9886 15.7602 15.9856 15.717 15.9856 15.6457C15.9856 15.6119 15.9778 15.5876 15.9623 15.5729C15.9468 15.5577 15.921 15.5501 15.8849 15.5501H15.7736ZM15.7736 15.4606H15.8694C15.9041 15.4606 15.9325 15.4534 15.9546 15.4392C15.9771 15.4249 15.9884 15.4062 15.9884 15.383C15.9884 15.3518 15.9799 15.3298 15.963 15.3168C15.9466 15.3035 15.9165 15.2966 15.8729 15.2961H15.7736V15.4606Z"
                fill="#DEE2E5"
              />
              <path
                d="M13.6397 6H11.7868C11.2206 8.42913 10.0985 13.2874 10.1397 13.2874C9.93382 13.9138 9.64216 13.9499 9.52206 13.8897H6.74265L7.36029 11.1192C7.44265 10.7338 7.66912 10.5973 7.77206 10.5772H9.93382L10.3971 8.52951H7.61765C6.46471 8.57769 5.76471 9.91472 5.55882 10.5772L4.375 15.9976H9.93382C10.9221 15.9976 11.5809 14.9135 11.7868 14.3715L13.6397 6Z"
                fill="#F9F9F9"
              />
              <path
                d="M16.3676 6H14.4118C13.6225 9.33253 12.0544 15.9976 12.0956 15.9976C13.4544 16.063 14.2059 14.7802 14.4118 14.1306L16.3676 6Z"
                fill="#F9F9F9"
              />
              <path
                d="M18.375 8.52951H16.5221L15.9559 10.5772H17.9118L18.375 8.52951Z"
                fill="#F9F9F9"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton
            onClick={() =>
              window.top &&
              (window.top.location.href = `${window.location.origin}/#/dealertrack`)
            }
          >
            <ButtonText>dealertrack</ButtonText>
          </ExpandedButton>
        </InnerButton>

        <InnerButton>
          <ImageWrapper>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.156 8.50098L14.7555 15.7914C11.5899 17.8811 8.25381 16.7791 6.98145 15.9669L7.62096 8.77388L10.0591 16.5127H11.6779L14.156 8.50098Z"
                fill="#F9F9F9"
              />
              <path
                d="M10.9144 9.57182L8.9559 3.19755C10.8265 2.74531 12.3067 3.10009 12.813 3.334L10.9144 9.57182Z"
                fill="#F9F9F9"
              />
              <path
                d="M4.15955 12.9247L5.19876 5.49777C4.74577 6.01109 3.78384 7.44709 3.56001 9.08451C3.33618 10.7219 3.86644 12.3269 4.15955 12.9247Z"
                fill="#F9F9F9"
              />
              <path
                d="M17.2592 13.0639L16.364 5.6192C16.807 6.14077 17.7409 7.59425 17.933 9.23551C18.1251 10.8768 17.5638 12.4716 17.2592 13.0639Z"
                fill="#F9F9F9"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5757 1.20858C5.61031 1.20858 1.58508 5.14463 1.58508 10C1.58508 14.8554 5.61031 18.7914 10.5757 18.7914C15.5411 18.7914 19.5663 14.8554 19.5663 10C19.5663 5.14463 15.5411 1.20858 10.5757 1.20858ZM0.349121 10C0.349121 4.47715 4.92771 0 10.5757 0C16.2237 0 20.8022 4.47715 20.8022 10C20.8022 15.5228 16.2237 20 10.5757 20C4.92771 20 0.349121 15.5228 0.349121 10Z"
                fill="#F9F9F9"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton>
            <ButtonText>manheim</ButtonText>
          </ExpandedButton>
        </InnerButton>

        <InnerButton>
          <ImageWrapper>
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.42363 0C3.85771 0 3.39841 0.448 3.39841 1C3.39841 1.552 3.85771 2 4.42363 2H6.47407C7.03999 2 7.49929 1.552 7.49929 1C7.49929 0.448 7.03999 0 6.47407 0H4.42363ZM12.6254 0C12.0595 0 11.6002 0.448 11.6002 1C11.6002 1.552 12.0595 2 12.6254 2H14.6758C15.2417 2 15.701 1.552 15.701 1C15.701 0.448 15.2417 0 14.6758 0H12.6254ZM3.39841 4C2.83249 4 2.37319 4.448 2.37319 5V7C1.24033 7 0.322754 7.895 0.322754 9V17C0.322754 17.552 0.782052 18 1.34797 18H7.49929C8.06521 18 8.52451 17.552 8.52451 17V11H10.5749V17C10.5749 17.552 11.0342 18 11.6002 18H17.7515C18.3174 18 18.7767 17.552 18.7767 17V9C18.7767 7.895 17.8591 7 16.7263 7V5C16.7263 4.448 16.267 4 15.701 4H11.6002C11.0342 4 10.5749 4.448 10.5749 5V7H8.52451V5C8.52451 4.448 8.06521 4 7.49929 4H3.39841Z"
                fill="#F9F9F9"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton>
            <ButtonText>serviços</ButtonText>
          </ExpandedButton>
        </InnerButton>

        <InnerButton>
          <ImageWrapper>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 18.8281H18.8281V7.03125H15.2734V18.8281H14.1016V11.7188H10.5859V18.8281H9.41406V9.375H5.89844V18.8281H4.72656V12.8906H1.17188V18.8281H0V20H20V18.8281Z"
                fill="white"
              />
              <path
                d="M20.0002 4.6875V0H15.2736V1.17188H17.9998L12.3439 6.78863L7.65644 2.10113L0.171875 9.54668L1.00039 10.3752L7.65644 3.75824L12.3439 8.44574L18.8283 2.00043V4.6875H20.0002Z"
                fill="white"
              />
            </svg>
          </ImageWrapper>
          <ExpandedButton>
            <ButtonText>dados de gestão</ButtonText>
          </ExpandedButton>
        </InnerButton>
      </MenuButton>
    </Container>
  );
};
