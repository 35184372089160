import React from "react";
import { DealershipCarBg } from "../../../atoms";
import { WrapperHomeBackoffice } from "./home-backoffice.organism.style";

export const HomeBackoffice: React.FC = () => {

  return (
      <WrapperHomeBackoffice className={'m-5'}>
        <DealershipCarBg />
      </WrapperHomeBackoffice>
  );
};
