import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .col,
  .row {
    margin: 0;
    padding: 0;
  }

  span {
    font-family: "Montserrat";
    color: #4a4a4a;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }

  input[type="checkbox"] {
    height: 0px;
    width: 0px;
    visibility: hidden;
  }

  label {
    position: relative;
    text-indent: 100%;
    width: 41.38px;
    height: 25px;
    background: rgba(168, 170, 172, 0.35);
    display: block;
    border-radius: 100px;
    border: 4px solid ${(props) => props.theme.colors.DarkGray};
    cursor: pointer;
  }

  label:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 17px;
    background: #fff;
    border-radius: 90px;
    transition: 0.2s;
    border-right: 3px solid #babdc0;
  }

  input:checked + label {
    background: #3a5693;
    border: 4px solid #a8aaac;
    opacity: 1px;
  }

  input:checked + label:after {
    left: calc(100% - -1px);
    transform: translateX(-100%);
    border-right: 3px ${(props) => props.theme.colors.DarkGray};
    border-left: 3px solid #1f3e74;
  }
`;
