import { TypesHelper } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  PagedRequestParamsBaseModel,
  DecoderReviewStatusEnum,
} from "..";

export interface ISearchListParams extends IPagedRequestParamsBaseModel {
  search?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  status?: DecoderReviewStatusEnum | string;
}

export class GetSearchList extends PagedRequestParamsBaseModel {
  search: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  status: number | null;

  constructor({
    search,
    createdAt,
    updatedAt,
    status,
    ...baseParams
  }: ISearchListParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.createdAt = TypesHelper.toStringNull(createdAt);
    this.updatedAt = TypesHelper.toStringNull(updatedAt);
    this.status = TypesHelper.toNumberNull(status === "" ? undefined : status);
  }
}
