import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StringHelper } from "../../../../../helper";
import { useC4u } from "../../../../../hooks";

import {
  CoxUserIsActiveBooleanEnum,
  ICreateCorporateUserRequest,
} from "../../../../../models";
import {
  CorporateUserDetailsFormMolecule,
  FeedbackModal,
} from "../../../../molecules";

export const CorporateUserDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const idNumber = !!isNaN(Number(id)) ? 0 : Number(id);

  const { getCorporateUserDetail } = useC4u();

  const [show, setShow] = useState<boolean>();
  const [exception, setException] = useState<any>();
  const [corporateUserDetails, setCorporateUserDetails] =
    useState<ICreateCorporateUserRequest>();

  useEffect(() => {
    if (idNumber === 0) {
      setCorporateUserDetails({
        id: idNumber,
        active: CoxUserIsActiveBooleanEnum.Active.toString(),
      } as ICreateCorporateUserRequest);
    } else {
      getCorporateUserDetail(idNumber).then((t) => {
        setCorporateUserDetails({
          ...t,
          active: StringHelper.getYesNoStringValues(t.active),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!corporateUserDetails ? (
        <SpinnerCenteredAtom />
      ) : (
        <CorporateUserDetailsFormMolecule
          setShow={setShow}
          setException={setException}
          initialValues={corporateUserDetails}
        />
      )}

      <FeedbackModal
        type={"error"}
        show={show !== undefined}
        onHide={() => setShow(undefined)}
        content={show ? t("Data sent successfully") : t("api_error")}
      >
        <ModalBody>{show ? exception : t("Try again error message")}</ModalBody>
      </FeedbackModal>
    </>
  );
};
