import { YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IFormUpdateDealershipModel } from "./user-account-new-details.organism";

export const UserAccountNewDetailsValidationSchema = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPhoneValid",
    YupHelper.isPhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isMobilePhoneValid",
    YupHelper.isMobilePhoneValid
  );

  const msgRequired = t("field_required");
  const msgInvalid = t("field_invalid");

  const validations = Yup.object<IFormUpdateDealershipModel>({
    //procurator_isProcuratorOrOwner: Yup.string().notRequired(),
    procurator_name: Yup.string().nullable().required(msgRequired),
    procurator_email: Yup.string()
      .nullable().required(msgRequired)
      .email(t("field_invalid_email")),
    procurator_cpf: Yup.string().nullable().required(msgRequired).isCpfValid(t("cpf_invalid")),
    procurator_birthDate: Yup.string()
      .isDatePtBrValid(t("field_invalid_date"))
      .nullable().required(msgRequired),
    procurator_cellPhone: Yup.string()
      .nullable().required(msgRequired)
      .isMobilePhoneValid(t("field_invalid_mobile_phone")),
    address_zipCode: Yup.string().nullable().required(msgRequired),
    address_street: Yup.string().nullable().required(msgRequired),
    address_number: Yup.number().nullable().typeError(msgInvalid).required(msgRequired),
    //address_complement: Yup.string().notRequired(),
    address_neighborhood: Yup.string().nullable().required(msgRequired),
    address_city: Yup.string().nullable().required(msgRequired),
    address_state: Yup.string().nullable().required(msgRequired),
    name: Yup.string().nullable().required(msgRequired),
    legalName: Yup.string().nullable().required(msgRequired),
    email: Yup.string().nullable().required(msgRequired).email(t("field_invalid_email")),
    primaryPhone: Yup.string()
      .nullable().required(msgRequired)
      .isPhoneValid(t("field_invalid_phone")),
    secondaryPhone: Yup.string()
      .nullable()
      .isPhoneValid(t("field_invalid_phone")),
    group: Yup.string().nullable().notRequired(),
  });

  return validations;
};
