import React from "react";
import { useTranslation } from "react-i18next";
import {
  CommissionObservation,
  CommissionText,
} from "./my-earnings-commission.molecule.style";
import {
  MyEarningsSectionTitleDiv,
  MyEarningsMoleculeWrapper,
  MyEarningsWhiteBackgroundCard,
} from "../my-earnings-shared.style";

interface IProps {
  text: string;
  observation?: string;
  titleBackgroundColor?: string;
  titleColor?: string;
}

export const MyEarningsCommissionMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <MyEarningsMoleculeWrapper>
      <MyEarningsSectionTitleDiv
        background={props.titleBackgroundColor}
        color={props.titleColor}
      >
        {t("Commission")}
      </MyEarningsSectionTitleDiv>
      <MyEarningsWhiteBackgroundCard>
        <CommissionText>{props.text}</CommissionText>
        <CommissionObservation>{props.observation}</CommissionObservation>
      </MyEarningsWhiteBackgroundCard>
    </MyEarningsMoleculeWrapper>
  );
};
