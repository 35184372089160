import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentV2_27_27,
  DocumentV3_27_27,
  DollarSign_31_31,
  Lightbulb_21_29,
} from "../../assets";
import { MyEarningsObservationMolecule } from "../../molecules";
import { IObservation } from "../../molecules/my-earnings/observation/my-earnings-observation.molecule";
import {
  IIturanPlanData,
  MyEarningsTrackerPlansMolecule,
} from "../../molecules/my-earnings/tracker-plans/my-earnings-tracker-plans.molecule";
import { MyEarningsOrganismWrapper } from "./my-earnings.organism.style";

export const MyEarningsIturanOrganism: React.FC = () => {
  const { t } = useTranslation();

  const planData = useMemo<IIturanPlanData[]>(
    () => [
      {
        plan: "Monitoramento",
        sale: 100,
        renewal: 50,
      },
      {
        plan: "Rastreador + RCF + Acidentes Passageiros",
        sale: 150,
        renewal: 100,
      },
      // {
      //   plan: "Rastreador + Assistência",
      //   sale: 100,
      // },
      {
        plan: "ICS RF",
        sale: 150,
        renewal: 50,
      },
      {
        plan: "ICS RF + PT Colisão",
        sale: 250,
        renewal: 150,
      },
      {
        plan: "ICS RF + RCF",
        sale: 250,
        renewal: 150,
      },
      {
        plan: "ICS RF + RCF + PT Colisão",
        sale: 300,
        renewal: 200,
      },
      {
        plan: "NOVO ICS + PT Colisão + Perda Parcial",
        sale: 250,
        renewal: 150,
      },
      {
        plan: "NOVO ICS + RCF + PT Colisão + Perda Parcial",
        sale: 300,
        renewal: 200,
      },
    ],
    []
  );

  const motoData = useMemo<IIturanPlanData[]>(
    () => [
      {
        plan: "ICS Moto",
        sale: 200,
        renewal: 150,
      },
      {
        plan: "Monitoramento Moto",
        sale: 100,
        renewal: 50,
      },
      {
        plan: "Rastreador Ituran Assit Moto",
        sale: 100,
        renewal: 50,
      },
    ],
    []
  );

  const observationData = useMemo<IObservation[]>(
    () => [
      {
        icon: Lightbulb_21_29,
        iconAltText: "lampada",
        title: "Objeto de Comissão",
        description:
          "Venda de rastreadores, monitoramento e serviços de seguro junto com o financiamento do veículo e também na jornada avulsa (sem financiamento)",
      },
      {
        icon: DollarSign_31_31,
        iconAltText: "cifrão",
        title: "Forma de pagamento da comissão",
        description: [
          "O relatório de comissões é encaminhado no 5º dia útil de cada mês.",
          "O relatório é enviado para o e-mail cadastrado junto a Ituran, por esse motivo, mantenha o cadastro atualizado.",
          "A Ituran não efetua pagamentos em Contas/CNPJ de terceiros.",
          "A alteração de dados bancários deve ser solicitada via e-mail, pelo menos 5 dias antes do envio da Nota. Não informar a alteração no corpo da Nota.",
          "A Nota Fiscal deverá ser emitida e enviada para comissoes.parceiros@ituran.com.br",
          "O prazo para pagamento é de 10 dias úteis após o recebimento da Nota, desde que a mesma esteja emitida corretamente.",
          "Em hipótese alguma, cancelar notas fiscais já enviadas para pagamento, sem aviso prévio.",
          "A NF deve ser emitida no mês vigente, independentemente de quando o serviço foi prestado.",
          "Não serão aceitas Notas emitidas com a informação: ISS RETIDO PELO TOMADOR DE SERVIÇO”. ",
          "Somente serão aceitas Notas emitidas com os códigos de serviços: 14.06 ou 07285.",
          "Não serão aceitas Notas emitidas e enviadas após o dia 20 de cada mês. Notas emitidas fora deste prazo, deverão ser canceladas pelo Parceiro, que poderá reemitir a partir do 1º dia do mês seguinte.",
          "Parceiros optantes pelo simples Nacional, devem informar na Nota Fiscal.",
          "O Informe de Rendimento deve ser solicitado anualmente, pois o mesmo não é enviado automaticamente. ",
        ],
      },
      {
        icon: DocumentV3_27_27,
        iconAltText: "documento",
        title: "Emitir NF em nome de",
        description: [
          "Ituran Serviços  LTDA",
          "Rua Verbo Divino, 1601 - Com. 4º  andar - Chácara Santo Antônio",
          "São Paulo - SP - CEP: 04719-002 - CNPJ: 16.613.061/0001-64",
          "Insc. Est: 142.667.644.116 - Insc. Mun: 4.707.071-4",
          "Enviar NF para: comissoes.parceiros@ituran.com.br",
        ],
      },
      {
        icon: DocumentV2_27_27,
        iconAltText: "documento",
        title: "Legenda",
        description: [
          "RCF = Seguro contra danos a terceiro",
          "PT = Perda Total",
          "ICS = Ituran com Seguro",
        ],
      },
    ],
    []
  );

  return (
    <MyEarningsOrganismWrapper>
      <MyEarningsTrackerPlansMolecule
        planData={planData}
        titleBackgroundColor="#5993BD"
        titleText={t("TrackerVehicleTitle")}
      />
      <MyEarningsTrackerPlansMolecule
        planData={motoData}
        titleBackgroundColor="#5993BD"
        titleText={t("TrackerMotorbikeTitle")}
      />
      <MyEarningsObservationMolecule
        observations={observationData}
        titleBackgroundColor="#5993BD"
      />
    </MyEarningsOrganismWrapper>
  );
};
