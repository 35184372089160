import {
  ButtonPrimary,
  Combo,
  FormikControlAtom,
  MaskHelper,
  PermissionEnum,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../../helper";
import { useC4u, useSessionContext } from "../../../../../hooks";
import {
  ApprovalStatusEnum,
  CoxUserIsActiveBooleanEnum,
  CoxUserIsMasterBooleanEnum,
  CreateUserRequest,
  ICreateUserRequest,
  IDealershipComboData,
} from "../../../../../models";
import {
  CheckBoxOptionsFromEnumAtom,
  ComboOptionsFromEnumAtom,
} from "../../../../atoms";
import { WrapperUserDetails } from "./user-details-form.molecule.style";
import { UserDetailsValidationSchema } from "./user-details-form.molecule.validations";

interface IProps {
  setShow: (v: boolean) => void;
  setException: React.Dispatch<any>;
  initialValues: ICreateUserRequest;
  dealerShipData?: IDealershipComboData | null;
}

export const UserDetailsFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { userAuth0Context, validateUserAuth0Permission } = useSessionContext();

  const hasBackofficeUsersPermissions = useMemo(
    () =>
      validateUserAuth0Permission(
        userAuth0Context,
        PermissionEnum.BackofficeUsersPermissions
      ),
    [userAuth0Context, validateUserAuth0Permission]
  );

  const [daelershipsListCombo, setDealershipListCombo] = useState<Combo[]>();

  const { GetDealerships, PostCreateUsers } = useC4u();

  const formik = useFormik<ICreateUserRequest>({
    initialValues: props.initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await PostCreateUsers(new CreateUserRequest(values));
          window.location.reload();
        } catch (e: any) {
          if (e.message) {
            props.setException(e.message);
            props.setShow(true);
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else {
            props.setException(JSON.stringify(e));
          }
        }
      }
    },
    validationSchema: UserDetailsValidationSchema(
      hasBackofficeUsersPermissions
    ),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const getDealershipNameListAsync = useCallback(async (): Promise<void> => {
    const items = await GetDealerships(ApprovalStatusEnum.Approved);
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setDealershipListCombo(itemsCombo);
  }, [GetDealerships]);

  useEffect(() => {
    if (props.dealerShipData === null || props.initialValues.id === 0)
      getDealershipNameListAsync();
    else if (props.dealerShipData !== undefined)
      setDealershipListCombo([
        new Combo({
          title: props.dealerShipData.name,
          value: props.dealerShipData.id,
        }),
      ]);

    // eslint-disable-next-line
  }, [props.dealerShipData]);

  return (
    <WrapperUserDetails>
      <hr />
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            md={4}
            formik={formik}
            property={"name"}
            label={t("Name")}
            maxLength={100}
            translate={t}
          />

          <FormikControlAtom
            md={4}
            formik={formik}
            property="email"
            label={t("Email")}
            maxLength={100}
            translate={t}
          />

          <FormikControlAtom
            md={3}
            formik={formik}
            mask={MaskHelper.Phone}
            property={"cellPhone"}
            label={t("CellPhone")}
            translate={t}
          />

          <FormikControlAtom
            md={3}
            formik={formik}
            mask={MaskHelper.Cpf}
            property="cpf"
            label={t("CPF")}
            translate={t}
          />

          <FormikControlAtom
            as={"select"}
            md={3}
            formik={formik}
            property={"isMaster"}
            label={t("TypeOfUser")}
            translate={t}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="isMaster"
              optionsEnum={CoxUserIsMasterBooleanEnum}
              hideOptionAll
              includeSelectPlaceholder
            />
          </FormikControlAtom>

          <FormikControlAtom
            type="autocomplete"
            md={3}
            formik={formik}
            data={daelershipsListCombo}
            property={"dealershipId"}
            label={t("Dealership")}
            placeholder={t("Tab here")}
            disabled={!!props.initialValues?.dealershipId}
            translate={t}
            className="overwrite-input-css-attrs"
            borderColor="#ced4da"
            backgroundColor="#fff"
          />

          <FormikControlAtom
            as={"select"}
            md={2}
            formik={formik}
            property={"active"}
            label={t("Status")}
            translate={t}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="active"
              optionsEnum={CoxUserIsActiveBooleanEnum}
              hideOptionAll
              includeSelectPlaceholder
            />
          </FormikControlAtom>
        </Form.Row>

        {hasBackofficeUsersPermissions && (
          <>
            <h4>{t("Permissions")}</h4>
            <hr />
            <CheckBoxOptionsFromEnumAtom
              propertyKey="permissions"
              optionsEnum={PermissionEnum}
              formik={formik}
            />
          </>
        )}

        <Form.Group as={Col} md className={"text-right py-4"}>
          <ButtonPrimary sizex="md" type="submit" loading={formik.isSubmitting}>
            {t("Save")}
          </ButtonPrimary>
        </Form.Group>
      </Form>
    </WrapperUserDetails>
  );
};
