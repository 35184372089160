import React from "react";

interface Props {
  size?: "small" | "tiny" | null;
}

const widthSizes: { [id: string]: string } = {
  tiny: "400",
  small: "500",
  regular: "771",
};

const heightSizes: { [id: string]: string } = {
  tiny: "300",
  small: "400",
  regular: "636",
};

const CarSvg = (args: Props) => {
  const size = args.size ? args.size : "regular";

  const width = widthSizes[size];
  const height = heightSizes[size];

  return size === "tiny" ? tinyCarSvg() : normalCarSvg(width, height);
};

const normalCarSvg = (width: string, height: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 771 636"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M535.164 291.224L652.773 39.1539C658.959 25.8944 649.28 10.6976 634.649 10.6976L528.352 10.6973C520.668 10.6973 513.664 15.099 510.331 22.0221L388.981 274.093C382.589 287.371 392.265 302.768 407.002 302.768L517.04 302.768C524.811 302.768 531.879 298.267 535.164 291.224Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M167.831 366.401L244.404 202.048C250.582 188.79 240.902 173.602 226.276 173.602L165.246 173.602C157.559 173.602 150.552 178.008 147.221 184.937L68.212 349.29C61.8291 362.567 71.5051 377.955 86.2373 377.955L149.702 377.955C157.477 377.955 164.548 373.449 167.831 366.401Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter2_d)">
      <path
        d="M444.668 421.613L620.352 58.6909C626.781 45.4093 617.106 29.9766 602.35 29.9766L270.938 29.9762C263.36 29.9762 256.432 34.2596 253.046 41.0394L71.7742 403.962C65.1324 417.26 74.8025 432.899 89.6665 432.899L426.667 432.899C434.335 432.899 441.327 428.515 444.668 421.613Z"
        fill="#00599A"
      />
    </g>
    <g filter="url(#filter3_d)">
      <path
        d="M669.636 476.282L628.207 565.448C622.048 578.705 631.727 593.875 646.345 593.875L668.585 593.875C676.28 593.875 683.293 589.46 686.619 582.521L729.366 493.355C735.73 480.079 726.054 464.709 711.331 464.709L687.773 464.709C679.991 464.709 672.915 469.224 669.636 476.282Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter4_d)">
      <path
        d="M622.982 366.342L518.52 589.494C512.313 602.756 521.991 617.973 536.634 617.973L628.475 617.974C636.15 617.974 643.147 613.583 646.485 606.672L754.269 383.519C760.683 370.239 751.008 354.821 736.26 354.821L641.095 354.821C633.333 354.821 626.272 359.312 622.982 366.342Z"
        fill="#0799F8"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.174 413.62C145.188 413.62 145.188 409.067 148.174 409.067H598.401C601.338 409.067 601.177 413.62 598.401 413.62H148.174ZM357.566 79.3481C357.566 77.8843 355.323 77.8843 355.323 79.3481C355.323 105.4 341.666 95.9842 341.666 75.1302C341.666 73.6663 339.423 73.6663 339.423 75.1302C339.423 85.4642 336.734 103.105 336.734 83.2808C336.734 81.8169 334.491 81.8169 334.491 83.2808C334.491 100.053 322.11 100.909 322.11 80.353C322.11 78.8891 319.867 78.8891 319.867 80.353C319.879 88.2058 318.318 93.8628 315.17 97.324V177.328L304.475 180.269V159.737L301.389 159.439V156.065L304.475 155.792V123.302L268.882 118.314L259.872 123.302V155.792L262.958 155.904V158.534L259.872 159.737V169.24L238.99 174.376V209.372L231.17 212.313V190.627L224.329 188.27C224.304 186.36 224.267 184.511 224.23 182.725C226.225 182.055 227.663 180.194 227.663 177.936C227.663 175.679 226.139 173.706 224.044 173.086V155.122C224.044 152.306 221.181 152.654 221.181 155.122V173.086C219.087 173.706 217.563 175.679 217.563 177.936C217.563 180.194 219 182.055 220.995 182.725C220.958 184.524 220.934 186.372 220.896 188.27L214.055 190.627V215.253L208.181 218.193V202.4L191.612 200.949L180.123 211.233V258.958L166.665 268.374V404.502C315.963 404.502 449.102 404.502 598.401 404.502L614.56 212.064L593.157 196.892V163.794L588.783 162.653V161.151L593.157 160.854V142.952L581.818 140.012L561.654 142.952V160.854L566.029 161.486V163.149L561.654 163.781V168.496L555.892 165.555V131.688L540.103 118.774L512.59 121.714V152.641L509.703 146.748V82.6108L494.497 79.6707L461.655 82.6108V150.842L458.396 147.902V96.7534L447.378 90.8606V64.8335L440.897 51.5842C439.459 2.20942 437.501 2.20942 436.076 51.5842L429.594 64.8335V90.8606L418.577 96.7534V136.142L413.905 139.082V116.094L385.24 113.154L368.336 116.094V156.735L362.672 150.842V97.324C359.376 93.2798 357.455 87.5483 357.566 79.3481Z"
      fill="#293E6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M707.596 597.148C711.603 597.148 711.603 591.053 707.596 591.053H51.9976C48.0629 591.053 48.2808 597.148 51.9976 597.148H707.596ZM35.5323 591.053H3.71549C-0.291881 591.053 -0.291881 597.148 3.71549 597.148H35.5323C40.0845 597.148 39.8908 591.053 35.5323 591.053ZM639.592 609.325C643.599 609.325 643.599 603.23 639.592 603.23H612.497C608.32 603.23 608.598 609.325 612.497 609.325H639.592ZM595.741 603.242H34.5516C30.5443 603.242 30.5443 609.337 34.5516 609.337H595.741C599.688 609.325 599.869 603.242 595.741 603.242Z"
      fill="#FAFAFB"
    />
    <defs>
      <filter
        id="filter0_d"
        x="372.977"
        y="0.697334"
        width="295.696"
        height="320.071"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="52.2124"
        y="163.602"
        width="208.087"
        height="232.353"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="55.6406"
        y="19.9762"
        width="580.734"
        height="430.923"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d"
        x="612.321"
        y="454.709"
        width="133.035"
        height="157.167"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d"
        x="502.61"
        y="344.821"
        width="267.674"
        height="291.153"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const tinyCarSvg = () => (
  <svg
    width="423"
    height="375"
    viewBox="0 0 423 375"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M370.304 223.66L349.187 269.075C343.053 282.266 352.684 297.365 367.231 297.365C374.886 297.365 381.862 292.975 385.173 286.074L406.921 240.745C413.291 227.468 403.294 212.093 388.568 212.093C380.788 212.093 373.585 216.605 370.304 223.66Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M295.016 194.007L218.3 327.006C210.609 340.34 220.232 356.999 235.625 356.999L299.666 357C306.717 357 313.247 353.287 316.853 347.228L396.009 214.228C403.943 200.897 394.337 184 378.823 184L312.341 184C305.193 184 298.588 187.815 295.016 194.007Z"
        fill="#0799F8"
      />
    </g>
    <g filter="url(#filter2_d)">
      <path
        d="M322.114 191.282L393.471 38.4654C399.663 25.2054 389.984 10.0036 375.349 10.0035L320.044 10.0034C312.363 10.0034 305.36 14.4025 302.026 21.3225L228.399 174.139C222.001 187.418 231.677 202.82 246.417 202.82L303.992 202.82C311.761 202.82 318.827 198.321 322.114 191.282Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter3_d)">
      <path
        d="M79.4145 240.908L123.668 146C129.851 132.741 120.172 117.548 105.542 117.548L80.1415 117.548C72.4562 117.548 65.4507 121.952 62.1188 128.877L16.4571 223.785C10.0688 237.064 19.7449 252.456 34.4798 252.456L61.2882 252.456C69.061 252.456 76.1298 247.953 79.4145 240.908Z"
        fill="#F3BC2A"
      />
    </g>
    <g filter="url(#filter4_d)">
      <path
        d="M262.349 277.449L371.838 51.4509C378.273 38.1689 368.598 22.731 353.839 22.731L149.901 22.7307C142.325 22.7307 135.4 27.0115 132.012 33.7881L19.0401 259.787C12.3928 273.084 22.0627 288.729 36.9295 288.729L244.35 288.729C252.016 288.729 259.007 284.348 262.349 277.449Z"
        fill="#00599A"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5983 276.002C62.625 276.002 62.625 272.996 64.5983 272.996H362.064C364.005 272.996 363.899 276.002 362.064 276.002H64.5983ZM202.944 55.3247C202.944 54.3583 201.462 54.3583 201.462 55.3247C201.462 72.5235 192.439 66.3074 192.439 52.5401C192.439 51.5737 190.957 51.5737 190.957 52.5401C190.957 59.3623 189.18 71.0084 189.18 57.9209C189.18 56.9545 187.698 56.9545 187.698 57.9209C187.698 68.9937 179.518 69.5588 179.518 55.9881C179.518 55.0217 178.036 55.0217 178.036 55.9881C178.044 61.1723 177.013 64.9069 174.933 67.1919V120.009L167.867 121.95V108.395L165.828 108.199V105.971L167.867 105.791V84.3416L144.35 81.0492L138.398 84.3416V105.791L140.436 105.865V107.601L138.398 108.395V114.669L124.601 118.059V141.163L119.434 143.104V128.788L114.914 127.232C114.898 125.971 114.873 124.751 114.849 123.571C116.167 123.129 117.117 121.9 117.117 120.41C117.117 118.919 116.11 117.617 114.726 117.208V105.349C114.726 103.49 112.834 103.719 112.834 105.349V117.208C111.451 117.617 110.443 118.919 110.443 120.41C110.443 121.9 111.393 123.129 112.712 123.571C112.687 124.759 112.671 125.979 112.646 127.232L108.126 128.788V145.045L104.245 146.986V136.56L93.2976 135.602L85.7072 142.392V173.898L76.8149 180.114V269.982C175.457 269.982 191.858 195 290.5 195L372.741 142.94L358.6 132.924V111.073L355.709 110.32V109.329L358.6 109.132V97.3144L351.108 95.3734L337.786 97.3144V109.132L340.676 109.55V110.648L337.786 111.065V114.177L333.978 112.236V89.8779L323.547 81.3522L305.369 83.2933V103.711L303.461 99.8205V57.4787L293.415 55.5377L271.716 57.4787V102.523L269.563 100.582V66.8152L262.283 62.9249V45.7425L258.001 36.9957C257.051 4.39987 255.757 4.39987 254.816 36.9957L250.533 45.7425V62.9249L243.254 66.8152V92.8181L240.167 94.7591V79.5832L221.228 77.6422L210.06 79.5832V106.413L206.318 102.523V67.1919C204.14 64.522 202.871 60.7382 202.944 55.3247Z"
      fill="#293E6B"
    />
    <defs>
      <filter
        id="filter0_d"
        x="333.308"
        y="202.093"
        width="89.5689"
        height="113.272"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="201.595"
        y="174"
        width="211.258"
        height="201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="212.392"
        y="0.00340271"
        width="196.981"
        height="220.817"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d"
        x="0.455078"
        y="107.548"
        width="139.111"
        height="162.908"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d"
        x="2.90381"
        y="12.7307"
        width="384.96"
        height="293.998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export { CarSvg };
