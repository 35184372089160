import { DateHelper as DateHelperComponents } from "c4u-web-components";
import { addHours } from "date-fns/esm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { DateHelper, StatusHelper, StringHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import {
  AdModel,
  AdOriginEnum,
  AdStepEnum,
  AdSubStepAuctionEnum,
} from "../../../../../models";
import { AdListTableSearchOrganism } from "./ads-list-table-search.organism";

export const AdsList: React.FC = () => {
  const { t } = useTranslation();
  const { getAllAds } = useManhein();
  const [dateNow, setDateNow] = useState<Date>(new Date());

  return (
    <AdListTableSearchOrganism
      getDataCallback={getAllAds}
      stepsOptionsEnum={AdStepEnum}
      adOrigin={AdOriginEnum}
      tableHeaders={[
        "#",
        t("Vehicle"),
        t("Origin"),
        t("Plate"),
        t("CPF"),
        t("Customer"),
        t("Status"),
        t("FinancingContract"),
        t("CurrentEndTime"),
        t("Included date"),
        "",
      ]}
      mappingFunction={(m: AdModel, i) => [
        m.id,
        m.vehicleName,
        !m.lenderOperatorId ? "PF" : "Fideliza",
        m.plate,
        StringHelper.formatCpfCnpj(m.customer?.document),
        <>
          {m.customer.name}
          {m.linkedCustomerName && (
            <>
              <br />({m.linkedCustomerName})
            </>
          )}
        </>,
        t(StatusHelper.getStatus(m.step, m.stepStatus, t)),
        m.financingContract,

        <>
          {m.step === AdStepEnum.Auction &&
          m.stepStatus === AdSubStepAuctionEnum.Published &&
          m.biddingEnd &&
          addHours(new Date(m.biddingEnd), m.durationHours) >= dateNow
            ? DateHelperComponents.getCounterTime(
                dateNow,
                new Date(m.biddingEnd)
              ) ?? "N/D"
            : "N/D"}
        </>,

        DateHelper.dateHourFormat(m.includedDate.toString()),
        <>
          <Link key={`Link${i}`} to={paths.backofficeAdDetailPage(m.id)}>
            {t("Details")}
          </Link>
          {m.step === 3 && m.stepStatus === 2 && (
            <>
              <br />
              <Link key={`Link${i}`} to={paths.backofficeAdDeliveryPage(m.id)}>
                {t("Delivery")}
              </Link>
            </>
          )}
        </>,
      ]}
      setDateNow={setDateNow}
    />
  );
};
