import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableList } from "../../..";
import { DateHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import { IReportLogs } from "../../../../../models";

interface IProps {
  reportLogs: IReportLogs[];
}
export const AdInspectionReportHistoryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { getDownloadLink } = useManhein();

  const writeBoolean = (bool: boolean | undefined) => {
    return bool ? t("Yes") : t("No");
  };

  const getOnclickCallback = (fileName: string) => {
    return async () => {
      try {
        const link = await getDownloadLink(fileName);
        window.open(link);
      } catch (error: any) {
        if (error?.message) alert(JSON.stringify(error?.message));
        else alert(JSON.stringify(error));
      }
    }
  };

  const showDownloadButton = (fileName: string | undefined) => {
    if (!fileName) {
      return <></>
    } else {
      return (
        <ButtonPrimary
          type={"buttom"}
          sizex={"sm"}
          onClick={getOnclickCallback(fileName)}
        >
          {t("Download")}
        </ButtonPrimary>
      )
    }
  }

  return (
    <TableList thead={[
      t("Id"),
      t("Date"),
      t("Refunded"),
      t("uniqueCode"),
      t("SellerOwnerTitle"),
      t("IsFinancingFree"),
      t("IsJudicialRestrictionsFree"),
      t("IsAuctionFree"),
      t("Inspection status"),
      t("reportLink"),
      t("dealerReportLink"),
      t("adInspectionId")
    ]}>
      {props.reportLogs?.map((m, i) => (
        <tr key={`tr${i}`}>
          <td>{m.id}</td>
          <td>{DateHelper.dateHourFormat(m.createdAt)}</td>
          <td>{writeBoolean(m.refunded)}</td>
          <td>{m.uniqueCode}</td>
          <td>{writeBoolean(m.sellerOwner)}</td>
          <td>{writeBoolean(m.financingFreeVehicle)}</td>
          <td>{writeBoolean(m.vehicleFreeLegalRestrictions)}</td>
          <td>{writeBoolean(m.freeVehicleAuction)}</td>
          <td>{m.inspectionStatus}</td>
          <td>{showDownloadButton(m.reportLink)}</td>
          <td>{showDownloadButton(m.dealerReportLink)}</td>
          <td>{m.adInspectionId}</td>
        </tr>
      ))}
    </TableList>
  );
};
