import React from "react";

import { KbbInspectionList } from "../../../../organisms";

import { useTranslation } from "react-i18next";

export const KbbInspectionListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleKbbInspectionListPage")}</h3>
      <KbbInspectionList />
    </>
  );
};
