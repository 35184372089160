import React from "react";
import { useTranslation } from "react-i18next";
import { EmailTemplatesListOrganism } from "../../../../organisms";

export const EmailTemplatesListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleEmailTemplatesListPage")}</h3>
      <EmailTemplatesListOrganism />
    </>
  );
};
