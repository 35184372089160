import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  IconDiv,
  ObservationTitle,
  ObservationDescription,
} from "./my-earnings-observation-entry.atom.style";

export interface IProps {
  icon: string;
  iconAltText?: string;
  title: string;
  description: string | string[];
}

export const MyEarningsObservationEntryAtom: React.FC<IProps> = (props) => {
  return (
    <Row>
      <Col md={6} lg={5}>
        <ObservationTitle>
          <IconDiv>
            <img
              src={props.icon}
              alt={props.iconAltText ?? ""}
            />
          </IconDiv>
          {props.title}
        </ObservationTitle>
      </Col>
      <Col md={6} lg={7}>
        <ObservationDescription>
          {typeof props.description === "string"
            ? props.description
            : props.description.length === 1
            ? props.description[0]
            : props.description.map((m) => (
                <>
                  {m}
                  <br />
                </>
              ))}
        </ObservationDescription>
      </Col>
    </Row>
  );
};
