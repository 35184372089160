export enum AdStepEnum {
  Registered,
  Inspection,
  Auction,
  AcceptedOffer,
  Canceled,
}

export enum AdSubStepRegisteredeEnum {
  Registered,
  RegisteredVehicle,
  RegisteredZapyValue,
  RegisteredValue,
  RegisteredPictures,
  RegisteredPersonalData,
}

export enum AdSubStepInspectionEnum {
  Registered,
  PaidOut,
  PaymentDeclined,
  Refused,
  ApprovedAppointments,
  Canceled,
}

export enum AdSubStepAuctionEnum {
  WaitingBackoffice,
  Published,
  FinishedWithoutOffer,
  FinishedWithOffer,
  OfferRefused,
  OfferRefusedByBuyer,
  Inactive,
  WaitingLenderAcceptance,
  WaitingUserAcceptance,
  OfferRefusedByLender,
}

export enum AdSubStepAcceptedOfferEnum {
  PendingPayment,
  ReceivedPayment,
  ScheduledDelivery,
  CarDelivered,
  CarPayd,
}

export enum AdSubStepCanceledEnum {
  Expired,
  ExpiredReport,
  BySeller,
  ReversedInspectionPayment,
  RefusedReport,
}
