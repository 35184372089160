import React from "react";

const TruckOutlineIcon = () => (
  <svg
    width="66"
    height="53"
    viewBox="0 0 66 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.0699 46.8399H61.3876C62.2206 46.8399 63.0199 46.5134 63.6115 45.9313C64.2031 45.3493 64.5391 44.5588 64.5465 43.732V24.5577C64.5524 23.7682 64.4002 22.9854 64.0991 22.2546L59.822 11.6269C59.3525 10.4628 58.5422 9.46514 57.4958 8.76275C56.4494 8.06037 55.2149 7.68547 53.9517 7.68653H42.4625"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M64.5465 29.6357H53.3648C52.9444 29.6286 52.5431 29.4596 52.2458 29.1646C51.9486 28.8695 51.7784 28.4713 51.7712 28.054V17.0933H61.8906"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M53.6722 43.5371C54.3329 44.193 54.7829 45.0285 54.9652 45.9382C55.1475 46.8479 55.0538 47.7908 54.6963 48.6477C54.3387 49.5046 53.7332 50.237 52.9563 50.7523C52.1794 51.2676 51.2659 51.5426 50.3315 51.5426C49.3971 51.5426 48.4839 51.2676 47.707 50.7523C46.9301 50.237 46.3246 49.5046 45.967 48.6477C45.6095 47.7908 45.5158 46.8479 45.6981 45.9382C45.8804 45.0285 46.3304 44.193 46.9911 43.5371C47.8771 42.6577 49.0785 42.1636 50.3315 42.1636C51.5844 42.1636 52.7862 42.6577 53.6722 43.5371Z"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M18.981 43.538C19.6472 44.1971 20.1015 45.0376 20.2862 45.9531C20.4709 46.8686 20.3779 47.8179 20.0187 48.6808C19.6595 49.5437 19.0503 50.2815 18.2685 50.8006C17.4866 51.3196 16.5672 51.5966 15.6266 51.5966C14.686 51.5966 13.7666 51.3196 12.9847 50.8006C12.2029 50.2815 11.5937 49.5437 11.2345 48.6808C10.8753 47.8179 10.7823 46.8686 10.967 45.9531C11.1517 45.0376 11.6056 44.1971 12.2719 43.538V43.538C13.1626 42.6569 14.3691 42.1621 15.6266 42.1621C16.8841 42.1621 18.0902 42.6569 18.981 43.538"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M10.9021 46.8397H4.58437C3.75141 46.8397 2.95246 46.5132 2.36086 45.9311C1.76926 45.3491 1.43289 44.5586 1.42552 43.7318V4.55071C1.43289 3.72392 1.76926 2.93348 2.36086 2.35143C2.95246 1.76938 3.75141 1.44284 4.58437 1.44287H39.3037C40.1366 1.44284 40.9359 1.76938 41.5275 2.35143C42.1191 2.93348 42.4551 3.72392 42.4625 4.55071V32.7434"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
    <path
      d="M42.4625 32.7715H1.45361"
      stroke="#E74A27"
      strokeWidth="1.04"
      strokeMiterlimit="10"
    />
  </svg>
);

export default TruckOutlineIcon;
