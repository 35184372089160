import { ButtonPrimary, ButtonSecondary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikControlAtom } from "../../../atoms";

interface IProps {
  formik: any;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const BasicSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  return (
    <div className="mb-3">
      <Form onSubmit={(e) => formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            as={"select"}
            md={3}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>
          <Col md={3} className="d-none d-md-flex"></Col>
          <Col md={3} className="text-right mt-4">
            <ButtonSecondary
              sizex="md"
              disabled={formik.isSubmitting}
              onClick={props.cleanFiltersCallback}
            >
              {t("CleanFilters")}
            </ButtonSecondary>
          </Col>
          <Col md={3} className="text-right mt-4">
            <ButtonPrimary
              type="submit"
              sizex="md"
              loading={formik.isSubmitting}
            >
              {t("Search")}
            </ButtonPrimary>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
