export enum OriginEnum {
  Cpv,
  Kbb,
  Dt,
}

export enum LogReportTypeEnum {
  QuotesReturned = 1,
  NoQuotesReturned,
  StepsMapping,
}

export enum NoReplyReasonEnum {
  Undefined,
  ApiError,
  PartnerTimeout,
  NoResponseData,
  WrongData,
  ConnectionError,
}
