import React from "react";

interface ItemOption {
  value: number;
  label: number;
}
interface OptionsOrdeByAtom {
  options: ItemOption[];
}

export const OptionsOrderByAtom: React.FC<OptionsOrdeByAtom> = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <option key={option?.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </>
  );
};
