import { Combo, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../../constants";
import { useManhein } from "../../../../../hooks";
import {
  CoxUserIsActiveBooleanEnum,
  ILenderOperatorRequest,
} from "../../../../../models";
import {
  FeedbackModal,
  LenderOperatorDetailsMolecule,
} from "../../../../molecules";

export const LenderOperatorDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const idNumber = isNaN(Number(id)) ? 0 : Number(id);
  const { lenderOperator, getLenders, getLenderOperatorDetails } = useManhein();

  const history = useHistory();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<any>();
  const [lenders, setLenders] = useState<Combo[]>();
  const [lenderOperatorDetails, setLenderOperatorDetails] =
    useState<ILenderOperatorRequest | null>();

  const getLendersAsync = async () => {
    try {
      const response = await getLenders();

      const itemsCombo = response?.map(
        (m) =>
          new Combo({
            title: m.name,
            value: m.id,
          })
      );

      setLenders(itemsCombo);
    } catch (e) {
      setModalMessage(t("GenericApiError"));
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (idNumber) {
      getLenderOperatorDetails(idNumber).then((data) => {
        setLenderOperatorDetails({
          ...data,
          lenderId: data.lender.id,
          isActive: data.isActive
            ? CoxUserIsActiveBooleanEnum.Active.toString()
            : CoxUserIsActiveBooleanEnum.Inactive.toString(),
        });
      });
    } else {
      setLenderOperatorDetails(null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLendersAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {lenders && lenderOperatorDetails !== undefined ? (
        <LenderOperatorDetailsMolecule
          setModalMessage={setModalMessage}
          setShowErrorModal={setShowErrorModal}
          setShowSuccessModal={setShowSuccessModal}
          userId={idNumber}
          onSubmit={lenderOperator}
          lenders={lenders}
          initialValues={lenderOperatorDetails}
        />
      ) : (
        <SpinnerCenteredAtom />
      )}

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("api_error")}
      />

      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("Ok")}
        actionButtonText={t("GoToList")}
        actionButtonOnClick={() =>
          history.push(paths.backofficeLenderOperatorListUsers())
        }
      />
    </>
  );
};
