import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { YupHelper } from "c4u-web-components";
import { INotifyBuyerPaymentFormData } from "../../../../../models";

export const AdNotifyBuyerPaymentValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );

  const validations = Yup.object<INotifyBuyerPaymentFormData>({
    name: Yup.string().required(),
    document: Yup.string().required().isCnpjValid(t("field_invalid_document")),
    email: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string().required(),
    complement: Yup.string().optional(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required(),
  });

  return validations;
};
