import { ButtonTertiary } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { formikHandleErrorHelper } from "../../../../../helper";
import {
  IListCorporateUserPagedResponse,
  IListCorporateUserResponse,
  IListCorporateUsersParams,
  ListCorporateUsersParams,
  ListUsersParams,
  StdEnum,
} from "../../../../../models";
import {
  CorporateUserListSearchForm,
  PaginatableTableList,
} from "../../../../molecules";

interface IProps {
  getDataCallback: (
    values: ListCorporateUsersParams
  ) => Promise<IListCorporateUserPagedResponse>;
  DealershipStatusEnum: StdEnum;
  CoxUserIsActiveBooleanEnum: StdEnum;
  tableHeaders: string[];
  mappingFunction: (m: IListCorporateUserResponse, i: number) => any[];
}

export const CorporateUserListTableSearchOrganism: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();

  const [listData, setListData] = useState<IListCorporateUserResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();

  const initialValues: IListCorporateUsersParams = {
    pageSize: 50,
    page: 1,
    search: "",
    active: "",
  };

  const formik = useFormik<IListCorporateUsersParams>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setListData(undefined);
        const { data, currentPage, totalPages } = await props.getDataCallback(
          new ListCorporateUsersParams(values)
        );
        setListData(data);
        setTotalTablePages(totalPages);
        setCurrentTablePage(currentPage);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          setErrorMessage(t("GenericApiError"));
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const resetFormValues = () => {
    formik.setValues(initialValues);
    formik.submitForm();
  };

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback(
      new ListUsersParams({
        ...formik.values,
        page: targetPage,
      })
    );
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    formik.submitForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CorporateUserListSearchForm
        formik={formik}
        DaelershipStatusOptions={props.DealershipStatusEnum}
        CoxUserStatusOptions={props.CoxUserIsActiveBooleanEnum}
        errorMessage={errorMessage}
        cleanFiltersCallback={resetFormValues}
      />

      <div className="w-100 m-2 d-flex justify-content-center">
        <Link to={paths.backofficeCorporateUserPage(0)}>
          <ButtonTertiary sizex="lg">{t("NewUser")}</ButtonTertiary>
        </Link>
      </div>

      <PaginatableTableList
        tableName={"user-list"}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={formik.isSubmitting}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
