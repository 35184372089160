import {
  ButtonPrimary,
  ButtonSecondary,
  ClipboardHelper,
  CoxIcon,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  AuctionTypeEnum,
  CoxUserIsActiveBooleanEnum,
  CreateApiUserRequest,
  IUpdateApiUserRequest,
  UpdateApiUserRequest,
} from "../../../../models";
import {
  CheckBoxUserDecoderAtom,
  ComboOptionsFromEnumAtom,
  ItemDecoderAtom,
} from "../../../atoms";
import {
  ApiUserDetailsHR,
  ApiUserDetailsSectionTitle,
  ButtonInformation,
  ClicableIconDiv,
  ContainerInformation,
  DataPermissions,
  DflexLabelDiv,
  ItensDecoder,
  ListItemDecoder,
  WrapperApiUserDetails,
  WrapperButton,
  WrapperFormButtons,
} from "./api-user-details-form.molecule.style";
import { ApiUserDetailsValidationSchema } from "./api-user-details-form.molecule.validations";

interface IProps {
  setShowErrorModal: (v: boolean) => void;
  setShowSuccessModal: (v: boolean) => void;
  setModalMessage: React.Dispatch<any>;
  updateApiUserCallback: (request: UpdateApiUserRequest) => Promise<void>;
  createApiUserCallback: (request: CreateApiUserRequest) => Promise<void>;
  initialValues: IUpdateApiUserRequest | null;
  apiUserId: number;
  createdAt?: string;
}

export const ApiUserDetailsFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const initialValues =
    props.initialValues ??
    ({
      id: 0,
      name: "",
      email: "",
      password: "",
      cnpj: "",
      active: "",
      canCallPartner: "",
      numberCallsDayDecoder: 0,
      numberCallsMonthDecoder: 0,
      numberCallsDayBin: 0,
      numberCallsMonthBin: 0,
      numberCallsDayAuction: 0,
      numberCallsMonthAuction: 0,
      numberCallsDayResidualValue: 0,
      numberCallsMonthResidualValue: 0,
      numberCallsDayDealerScore: 0,
      numberCallsMonthDealerScore: 0,
      expirationDate: "",
      minimumData: true,
      vehicleComposition: false,
      complementaryData: false,
      vehicleImportData: false,
      vehicleAdress: false,
      detranVehicleStatus: false,
    } as IUpdateApiUserRequest);

  const isCreateMode = useMemo(() => !props.apiUserId, [props.apiUserId]);

  const formik = useFormik<IUpdateApiUserRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          if (isCreateMode) {
            await props.createApiUserCallback(new CreateApiUserRequest(values));
            formik.setValues(initialValues);
            props.setModalMessage(t("CreateUserSuccessMsg"));
          } else {
            await props.updateApiUserCallback(new UpdateApiUserRequest(values));
            props.setModalMessage(t("UpdateUserSuccessMsg"));
          }
          props.setShowSuccessModal(true);
        } catch (e: any) {
          if (e?.errors) {
            setErrors(formikHandleErrorHelper(e.errors));
          }
          if (e?.message) {
            props.setModalMessage(e.message);
          } else {
            props.setModalMessage(JSON.stringify(e));
          }
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: ApiUserDetailsValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [isPasswordCopied, setIsPasswordCopied] = useState(false);
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [readMoreMinimumData, setReadMoreMinimumData] = useState(false);
  const [readMoreComplementary, setReadMoreComplementary] = useState(false);
  const [readMoreAddress, setReadMoreAddress] = useState(false);
  const [readMoreVehicleComposition, setReadMoreVehicleComposition] =
    useState(false);
  const [readMoreImport, setReadMoreImport] = useState(false);
  const [readMoreVehicleStatus, setReadMoreVehicleStatus] = useState(false);

  const handleCopyClick = (
    value: string,
    setIsCopied: (v: boolean) => void
  ) => {
    ClipboardHelper.copyTextToClipboard(value)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVisibilityToggle = () => {
    setIsVisible(!isVisible);
  };

  const passwordLabel = (
    <DflexLabelDiv>
      {t("Password")}
      <ClicableIconDiv onClick={handleVisibilityToggle}>
        <CoxIcon
          name={isVisible ? "visibility" : "visibility-off"}
          height={21}
        />
      </ClicableIconDiv>

      <ClicableIconDiv
        onClick={() =>
          handleCopyClick(formik.values.password, setIsPasswordCopied)
        }
      >
        <CoxIcon name="copy-v2" height={21} />
      </ClicableIconDiv>
      {isPasswordCopied && t("Copied")}
    </DflexLabelDiv>
  );

  const loginLabel = (
    <DflexLabelDiv>
      {t("Email")}
      <ClicableIconDiv
        onClick={() => handleCopyClick(formik.values.email, setIsEmailCopied)}
      >
        <CoxIcon name="copy-v2" height={21} />
      </ClicableIconDiv>
      {isEmailCopied && t("Copied")}
    </DflexLabelDiv>
  );

  const handleInputNegativeNumber = useCallback(
    (t: number) =>
      isNaN(parseInt(t?.toString())) ? null : Math.abs(t).toString(),
    []
  );

  useEffect(() => {
    if (
      formik.values.numberCallsDayDecoder === 0 &&
      formik.values.numberCallsMonthDecoder === 0
    ) {
      formik.setFieldValue("vehicleComposition", false);
      formik.setFieldValue("complementaryData", false);
      formik.setFieldValue("vehicleImportData", false);
      formik.setFieldValue("vehicleAdress", false);
      formik.setFieldValue("detranVehicleStatus", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.numberCallsDayDecoder,
    formik.values.numberCallsMonthDecoder,
  ]);

  return (
    <WrapperApiUserDetails>
      <ApiUserDetailsHR />
      <Form onSubmit={(e: any) => formik.handleSubmit(e)} autoComplete="off">
        <input type="hidden" value="disable-autocomplete-chrome" />

        <Form.Row>
          <FormikControlAtom
            md={6}
            formik={formik}
            property={"name"}
            label={t("Name")}
            maxLength={100}
            translate={t}
          />
          <FormikControlAtom
            sm={6}
            md={3}
            formik={formik}
            mask={MaskHelper.Cnpj}
            property="cnpj"
            label={t("Cnpj")}
            translate={t}
          />
          <FormikControlAtom
            sm={6}
            md={3}
            formik={formik}
            property={"email"}
            label={loginLabel}
            translate={t}
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            type={isVisible ? "text" : "password"}
            sm={6}
            md={3}
            formik={formik}
            property="password"
            label={passwordLabel}
            translate={t}
            func={(v) => v.trim()}
            autoComplete="new-password"
          />
          <FormikControlAtom
            sm={6}
            md={2}
            formik={formik}
            mask={MaskHelper.Date}
            property="expirationDate"
            label={t("ExpirationDate")}
            translate={t}
          />
          <FormikControlAtom
            as={"select"}
            sm={6}
            md={2}
            formik={formik}
            property={"active"}
            label={t("Status")}
            translate={t}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="active"
              optionsEnum={CoxUserIsActiveBooleanEnum}
              hideOptionAll
              includeSelectPlaceholder
            />
          </FormikControlAtom>
          <FormikControlAtom
            as={"select"}
            sm={6}
            md={2}
            formik={formik}
            property={"canCallPartner"}
            label={t("PartnerConsultation")}
            translate={t}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="canCallPartner"
              optionsEnum={AuctionTypeEnum}
              hideOptionAll
              includeSelectPlaceholder
              reversedOrder
            />
          </FormikControlAtom>
        </Form.Row>

        <h3 className="mt-4">{t("ServiceCallsLimitTitle")}</h3>
        <ApiUserDetailsHR />

        <Form.Row>
          <Col sm={4} md={4} xl={4}>
            <ApiUserDetailsSectionTitle>
              {t("Decoder")}
            </ApiUserDetailsSectionTitle>
            <ApiUserDetailsHR />
            <Form.Row>
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property={"numberCallsDayDecoder"}
                label={t("NumberCallsDayDecoder")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property="numberCallsMonthDecoder"
                label={t("NumberCallsMonthDecoder")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
            </Form.Row>
            <ApiUserDetailsSectionTitle>
              {t("ResidualValue")}
            </ApiUserDetailsSectionTitle>
            <ApiUserDetailsHR />
            <Form.Row>
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property={"numberCallsDayResidualValue"}
                label={t("NumberCallsDayResidualValue")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property="numberCallsMonthResidualValue"
                label={t("NumberCallsMonthResidualValue")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
            </Form.Row>
          </Col>
          <Col sm={4} md={4} xl={4}>
            <ApiUserDetailsSectionTitle>{t("Bin")}</ApiUserDetailsSectionTitle>
            <ApiUserDetailsHR />
            <Form.Row>
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property={"numberCallsDayBin"}
                label={t("NumberCallsDayBin")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property="numberCallsMonthBin"
                label={t("NumberCallsMonthBin")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
            </Form.Row>

            <ApiUserDetailsSectionTitle>
              {t("DealerScore")}
            </ApiUserDetailsSectionTitle>
            <ApiUserDetailsHR />
            <Form.Row>
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property={"numberCallsDayDealerScore"}
                label={t("NumberCallsDayDealerScore")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property="numberCallsMonthDealerScore"
                label={t("NumberCallsMonthDealerScore")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
            </Form.Row>
          </Col>

          <Col sm={4} md={4} xl={4}>
            <ApiUserDetailsSectionTitle>
              {t("Auction")}
            </ApiUserDetailsSectionTitle>
            <ApiUserDetailsHR />
            <Form.Row>
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property={"numberCallsDayAuction"}
                label={t("NumberCallsDayAuction")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
              <FormikControlAtom
                type="number"
                sm={12}
                formik={formik}
                property="numberCallsMonthAuction"
                label={t("NumberCallsMonthAuction")}
                translate={t}
                min={0}
                func={handleInputNegativeNumber}
              />
            </Form.Row>
          </Col>
        </Form.Row>

        {(!!formik.values.numberCallsDayDecoder ||
          !!formik.values.numberCallsMonthDecoder) && (
          <div>
            <h3 className="mt-4">{"Permissões"}</h3>
            <ApiUserDetailsHR />
            <DataPermissions>
              <ItensDecoder>
                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("MinimumData")}
                      formik={formik}
                      propertyKey="minimumData"
                      defaultChecked={true}
                      disabled={true}
                    />

                    {readMoreMinimumData ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreMinimumData(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreMinimumData(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>
                  {readMoreMinimumData && (
                    <ListItemDecoder>
                      <ItemDecoderAtom item={t("Plate")} />
                      <ItemDecoderAtom item={t("Vin")} />
                      <ItemDecoderAtom item={t("ModelYear")} />
                      <ItemDecoderAtom item={t("ManufactureYear")} />
                      <ItemDecoderAtom item={t("MolicarId")} />
                    </ListItemDecoder>
                  )}
                </ContainerInformation>

                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("VehicleComposition")}
                      formik={formik}
                      propertyKey="vehicleComposition"
                      defaultChecked={formik.values.vehicleComposition}
                    />
                    {readMoreVehicleComposition ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreVehicleComposition(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreVehicleComposition(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>

                  {readMoreVehicleComposition && (
                    <ListItemDecoder>
                      <ItemDecoderAtom item={t("VehicleTypeCode")} />
                      <ItemDecoderAtom item={t("VehicleType")} />
                      <ItemDecoderAtom item={t("VehicleSpeciesCode")} />
                      <ItemDecoderAtom item={t("VehicleSpecies")} />
                      <ItemDecoderAtom item={t("BodyCode")} />
                      <ItemDecoderAtom item={t("BodyType")} />
                      <ItemDecoderAtom item={t("VehicleColorCode")} />
                      <ItemDecoderAtom item={t("VehicleColor")} />
                      <ItemDecoderAtom item={t("Potency")} />
                      <ItemDecoderAtom item={t("EngineCapacity")} />
                      <ItemDecoderAtom item={t("MaximumTractionCapacity")} />
                      <ItemDecoderAtom item={t("NumberOfAxles")} />
                      <ItemDecoderAtom item={t("TotalGrossWeight")} />
                      <ItemDecoderAtom item={t("NumberOfPassengers")} />
                      <ItemDecoderAtom item={t("FuelName")} />
                    </ListItemDecoder>
                  )}
                </ContainerInformation>
              </ItensDecoder>

              <ItensDecoder>
                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("ComplementaryData")}
                      formik={formik}
                      propertyKey="complementaryData"
                      defaultChecked={formik.values.complementaryData}
                    />
                    {readMoreComplementary ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreComplementary(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreComplementary(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>

                  {readMoreComplementary && (
                    <ListItemDecoder>
                      <ItemDecoderAtom item={t("IturanId")} />
                      <ItemDecoderAtom item={t("KbbID")} />
                    </ListItemDecoder>
                  )}
                </ContainerInformation>

                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("VehicleImportData")}
                      formik={formik}
                      propertyKey="vehicleImportData"
                      defaultChecked={formik.values.vehicleImportData}
                    />
                    {readMoreImport ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreImport(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreImport(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>

                  {readMoreImport && (
                    <ListItemDecoder>
                      <ItemDecoderAtom item={t("NationalityType")} />
                      <ItemDecoderAtom item={t("ImportDocumentCode")} />
                      <ItemDecoderAtom item={t("ImporterIdentityNumber")} />
                      <ItemDecoderAtom item={t("FederalRevenueCode")} />
                    </ListItemDecoder>
                  )}
                </ContainerInformation>
              </ItensDecoder>

              <ItensDecoder>
                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("TrackerCustomerAddress")}
                      formik={formik}
                      propertyKey="vehicleAdress"
                      defaultChecked={formik.values.vehicleAdress}
                    />
                    {readMoreAddress ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreAddress(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreAddress(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>

                  {readMoreAddress && (
                    <ListItemDecoder>
                      <ItemDecoderAtom item={t("OriginUF")} />
                      <ItemDecoderAtom item={t("CurrentUF")} />
                      <ItemDecoderAtom item={t("CurrentCityCode")} />
                      <ItemDecoderAtom item={t("NameCurrentMunicipality")} />
                    </ListItemDecoder>
                  )}
                </ContainerInformation>

                <ContainerInformation>
                  <WrapperButton>
                    <CheckBoxUserDecoderAtom
                      name={t("DetranVehicleStatus")}
                      formik={formik}
                      propertyKey="detranVehicleStatus"
                      defaultChecked={formik.values.detranVehicleStatus}
                    />

                    {readMoreVehicleStatus ? (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreVehicleStatus(false)}
                      >
                        -
                      </ButtonInformation>
                    ) : (
                      <ButtonInformation
                        type="button"
                        onClick={() => setReadMoreVehicleStatus(true)}
                      >
                        +
                      </ButtonInformation>
                    )}
                  </WrapperButton>

                  <div>
                    {readMoreVehicleStatus && (
                      <ListItemDecoder>
                        <ItemDecoderAtom item={t("VehicleStatusCode")} />
                        <ItemDecoderAtom item={t("VinSituation")} />
                        <ItemDecoderAtom item={t("RestrictionCodeOne")} />
                        <ItemDecoderAtom item={t("RestrictionCodeTwo")} />
                        <ItemDecoderAtom item={t("RestrictionCodeThree")} />
                        <ItemDecoderAtom item={t("RestrictionCodeFour")} />
                        <ItemDecoderAtom item={t("TaxDeadline")} />
                      </ListItemDecoder>
                    )}
                  </div>
                </ContainerInformation>
              </ItensDecoder>
            </DataPermissions>
          </div>
        )}

        <Form.Row>
          <Form.Group as={Col}>
            <WrapperFormButtons>
              {isCreateMode && (
                <div>
                  <ButtonSecondary
                    sizex="md"
                    onClick={() => formik.setValues(initialValues)}
                    disabled={formik.isSubmitting}
                  >
                    {t("ClearForm")}
                  </ButtonSecondary>
                </div>
              )}
              <div>
                <ButtonPrimary
                  sizex="md"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {t("Save")}
                </ButtonPrimary>
              </div>
            </WrapperFormButtons>
          </Form.Group>
        </Form.Row>
      </Form>
    </WrapperApiUserDetails>
  );
};
