import {
  ButtonPrimary,
  ButtonSecondary,
  EnumHelper,
  FormikControlAtom,
} from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GetSearchList,
  IGenericSearchFormik,
  ISearchListParams,
  DecoderReviewStatusEnum,
} from "../../../../models";
export const ListRequestSearchFormMolecule: React.FC<
  IGenericSearchFormik<ISearchListParams, GetSearchList>
> = (props) => {
  const { t } = useTranslation();

  const defaultProps = {
    translate: t,
    formik: props.formik,
    md: 4,
    lg: 2,
  };

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => props.formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            {...defaultProps}
            lg={4}
            property={"search"}
            label={t("TextSearch")}
          />
          <FormikControlAtom
            {...defaultProps}
            type="date"
            property="createdAt"
            label={t("DateRequesting")}
          />

          <FormikControlAtom
            {...defaultProps}
            as={"select"}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            {...defaultProps}
            type="select"
            data={EnumHelper.getComboFromEnum(DecoderReviewStatusEnum, t)}
            property="status"
            label={t("Status")}
            placeholder={t("All")}
          />

          <Col sm={5} md={4} lg={3} xl={2} className="pt-4 text-center ml-auto">
            <ButtonSecondary
              sizex="md"
              disabled={props.formik.isSubmitting}
              onClick={props.cleanFiltersCallback}
              data-cyid="cleanFilters"
            >
              {t("CleanFilters")}
            </ButtonSecondary>
          </Col>

          <Col sm={5} md={4} lg={3} xl={2} className="pt-4 text-center">
            <ButtonPrimary
              type="submit"
              sizex="md"
              loading={props.formik.isSubmitting}
              data-cyid="search"
            >
              {t("Search")}
            </ButtonPrimary>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
