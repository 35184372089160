import React from "react";
import { useTranslation } from "react-i18next";
import { IVehicleDebitDetailsViewModel } from "../../../../../models";
import { PaginatableTableList } from "../../..";
import { NumberHelper } from "c4u-web-components";
import { TotalDebtsDiv } from "./zapay-details.molecule.style";

interface IProps {
  debtsPaidDetails?: IVehicleDebitDetailsViewModel[];
  totalDebts?: string;
}

export const ZapayDebtsPaidDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return !props.debtsPaidDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <PaginatableTableList
        tableName={"debts-details"}
        dataIsLoading={false}
        thead={[t("DebitType"), t("DebitDescription"), t("DebitValue")]}
        tbody={props.debtsPaidDetails?.map(
          (m: IVehicleDebitDetailsViewModel, i) => [
            m.title,
            m.description,
            <div className="text-right">
              {NumberHelper.toFormatString(m.value, 2)}
            </div>,
          ]
        )}
      />
      {props.totalDebts && (
        <TotalDebtsDiv className="text-right">
          {t("TotalDebts")}: R$ {props.totalDebts}
        </TotalDebtsDiv>
      )}
    </>
  );
};
