import styled from "styled-components";

export const BaseTextStyle = styled.div`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const Title = styled(BaseTextStyle)`
  margin-bottom: 50px;

  color: #ff6e00;

  font-weight: bold;
  font-size: 30px;
`;

export const Content = styled(BaseTextStyle)``;

export const Link = styled.a`
  color: #4a4a4a;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  text-decoration-line: underline;

  :hover {
    text-decoration-line: underline !important;
  }
`;

export const HighlightText = styled(BaseTextStyle)`
  margin: 50px 0;

  color: #ff6e00;

  font-weight: bold;
`;

export const NoteText = styled(BaseTextStyle)`
  font-size: 16px;
`;

export const ButtonContent = styled.div`
  margin-top: 50px;

  button {
    color: #ffffff;
    background-color: #ff6e00;

    :hover {
      color: #ffffff;
      background-color: #ff6e00;
    }
  }
`;

export const BenefitsContent = styled.div`
  width: 700px;
  margin: 50px 0;

  color: #4a4a4a;

  * {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.15px;
  }

  div {
    & + div {
      margin-top: 16px;
    }
  }
`;
