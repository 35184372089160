import styled from "styled-components";

interface IProps {
  background?: string;
}

export const MyEarningsMoleculeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const MyEarningsSectionTitleDiv = styled.div<IProps>`
  background: ${(props) => props.background ?? "#3a5693"};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 12px;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.color ?? "#fffdfd"};
`;

export const MyEarningsWhiteBackgroundCard = styled.div`
  background: #fff;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 25px;
`;
