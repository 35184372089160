import React, { useCallback, useEffect, useState } from "react";
import {
  AdModel,
  AdVehiclePicture,
  PicturePosition,
} from "../../../../../models";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";

import { Col, Row } from "react-bootstrap";
import { AdDetailsAutoAvaliarMolecule } from "../ad-autoavaliar-photos/ad-details-autoavaliar-photos.molecule";
import { AdStampsButtons } from "../../..";

interface IProps {
  ad: AdModel;
  adInspectionId?: number;
  showReportPhotos?: boolean;
}

interface IPhotoGalery {
  original: string;
  thumbnail: string;
  thumbnailLabel: string;
  originalAlt: string;
  description: string;
}

export const AdDetailsPhotosMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<IPhotoGalery[]>([]);

  const random = useCallback(() => {
    const min = Math.ceil(1000);
    const max = Math.floor(9999);
    return Math.floor(Math.random() * (max - min)) + min;
  }, []);

  useEffect(() => {
    setPhotos(
      props.ad.adVehiclePictures.map((p: AdVehiclePicture) => {
        let imageUrl = p.partnerCatalogId;
        let imageName = t(PicturePosition[p.picturePosition]);
        return {
          original: `${imageUrl}?nocache=${random()}`,
          thumbnail: `${imageUrl}?nocache=${random()}`,
          thumbnailLabel: imageName,
          originalAlt: `Foto ${imageName} - veículo ${props.ad.vehicleName}`,
          description: imageName,
        };
      })
    );
  }, [props.ad, t, random]);

  const onPhotoClick = (e: any) => {
    if (e.target.src) window.open(e.target.src, "_blank");
  };

  return (
    <>
      <ImageGallery
        items={photos}
        lazyLoad={true}
        showBullets={true}
        showIndex={true}
        showPlayButton={false}
        showFullscreenButton={true}
        thumbnailPosition={"top"}
        onClick={onPhotoClick}
      />

      <Row className={"mt-4 text-center"}>
        <AdStampsButtons adId={props.ad.id} />
      </Row>

      {props.adInspectionId && props.showReportPhotos && (
        <Row className="mt-5">
          <Col>
            <AdDetailsAutoAvaliarMolecule
              adId={props.ad.id}
              adInspectionId={props.adInspectionId}
              adPicturesLength={props.ad.adVehiclePictures.length}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
