import { DateHelper, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../../../constants";
import { useKbbBackoffice } from "../../../../../../hooks";
import {
  AuctionTypeEnum,
  CoxUserIsActiveBooleanEnum,
  IUpdateApiUserRequest,
} from "../../../../../../models";
import {
  ApiUserDetailsFormMolecule,
  FeedbackModal,
} from "../../../../../molecules";

export const ApiUserDetailsOrganism: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getApiUserDetails, updateApiUser, createApiUser } =
    useKbbBackoffice();

  const { id } = useParams<{ id: string }>();
  const idNumber = isNaN(Number(id)) ? 0 : Number(id);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<any>();
  const [createdAt, setCreatedAt] = useState<string>();
  const [apiUserDetails, setApiUserDetails] =
    useState<IUpdateApiUserRequest | null>();

  useEffect(() => {
    if (idNumber) {
      getApiUserDetails(idNumber).then((data) => {
        const { createdAt, ...otherData } = data;
        setCreatedAt(createdAt);
        setApiUserDetails({
          ...otherData,
          password: "",
          active: otherData.active
            ? CoxUserIsActiveBooleanEnum.Active.toString()
            : CoxUserIsActiveBooleanEnum.Inactive.toString(),
          canCallPartner: otherData.canCallPartner
            ? AuctionTypeEnum.Yes.toString()
            : AuctionTypeEnum.No.toString(),
          expirationDate: DateHelper.dateFormat(otherData.expirationDate),
        });
      });
    } else {
      setApiUserDetails(null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {apiUserDetails === undefined ? (
        <SpinnerCenteredAtom />
      ) : (
        <ApiUserDetailsFormMolecule
          setShowErrorModal={setShowErrorModal}
          setShowSuccessModal={setShowSuccessModal}
          setModalMessage={setModalMessage}
          updateApiUserCallback={updateApiUser}
          createApiUserCallback={createApiUser}
          initialValues={apiUserDetails}
          apiUserId={idNumber}
          createdAt={createdAt}
        />
      )}

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("api_error")}
      />

      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("Ok")}
        actionButtonText={t("GoToList")}
        actionButtonOnClick={() =>
          history.push(paths.backofficeApiUsersListPage())
        }
      />
    </>
  );
};
