import React from "react";
import {
  ButtonPrimary,
  FormikControlAtom,
  InspectionCompanyEnum,
} from "c4u-web-components";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import { Combo } from "c4u-web-components";
import * as Yup from "yup";
import {
  IAdInspectionModel,
  InspectionStatusEnum,
  IUpdateAdInspection,
  UpdateAdInspectionRequest,
} from "../../../../../models";
import { ComboOptionsFromEnumAtom } from "../../../../atoms";
import { useFormik } from "formik";

interface IProps {
  inspection: IAdInspectionModel;
  statusOptions?: Combo[];
  permission?: boolean;
}
export const AdInspectionReportMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { createFileTemporaryLink, getDownloadLink, updateAdInspection } =
    useManhein();

  const writeBoolean = (bool: boolean | undefined) => {
    return bool ? t("Yes") : t("No");
  };

  const getPartner = (value: number | undefined) => {
    switch (value) {
      case InspectionCompanyEnum.Supervisao:
        return "Supervisão";

      default:
        return "";
    }
  };

  const msgRequired = t("field_required");

  const validations = Yup.object({
    inspectionPlaceName: Yup.string().required(msgRequired),
    inspectionPlaceAddress: Yup.string().required(msgRequired),
    status: Yup.number().required(msgRequired),
  });

  const formik = useFormik({
    initialValues: props.inspection as IUpdateAdInspection,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await updateAdInspection(new UpdateAdInspectionRequest(values));
          window.location.reload();
        } catch (e: any) {
          console.log(e);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleDownload = async () => {
    try {
      const link = await createFileTemporaryLink(props.inspection?.id ?? 0);
      window.open(link);
    } catch (error: any) {
      if (error?.message) alert(JSON.stringify(error?.message));
      else alert(JSON.stringify(error));
    }
  };

  const handleSecondDownload = async () => {
    try {
      const link = await getDownloadLink(
        props.inspection?.ad?.reinspectionReportLink ?? ""
      );
      window.open(link);
    } catch (error: any) {
      if (error?.message) alert(JSON.stringify(error?.message));
      else alert(JSON.stringify(error));
    }
  };

  const renderView = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => {
    const values = props.inspection as any;
    const valueFormated = func ? func(values[key]) : values[key];
    return (
      <Form.Group as={Col} md={4}>
        <Form.Label>{label}</Form.Label>
        <Form.Control value={valueFormated} disabled={true} />
      </Form.Group>
    );
  };

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        {renderView("id", t("Id"))}
        {renderView("partnerId", t("PartnerId"), getPartner)}
        {renderView("inspectionPlanId", t("InspectionPlanId"))}
        {renderView("inspectionPlaceId", t("InspectionPlaceId"))}

        {renderView(
          "inspectionDate",
          t("InspectionDate"),
          DateHelper.dateFormat
        )}
        {renderView(
          "inspectionPeriod",
          t("InspectionPeriod"),
          DateHelper.hourFormat
        )}

        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"inspectionPlaceName"}
          label={t("InspectionPlaceName")}
          disabled={!props.permission}
          md={4}
        />

        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"inspectionPlaceAddress"}
          label={t("InspectionPlaceAddress")}
          disabled={!props.permission}
          md={4}
        />

        <FormikControlAtom
          translate={t}
          formik={formik}
          as={"select"}
          md={4}
          property={"status"}
          label={t("Status")}
          disabled={!props.permission}
        >
          <ComboOptionsFromEnumAtom
            propertyKey="status"
            optionsEnum={InspectionStatusEnum}
            includeSelectPlaceholder
            hideOptionAll
            notSorted
          />
        </FormikControlAtom>

        {renderView("refunded", t("Refunded"), writeBoolean)}
        {renderView("sellerOwner", t("SellerOwner"), writeBoolean)}
        {renderView(
          "financingFreeVehicle",
          t("FinancingFreeVehicle"),
          writeBoolean
        )}
        {renderView(
          "vehicleFreeLegalRestrictions",
          t("VehicleFreeLegalRestrictions"),
          writeBoolean
        )}
        {renderView(
          "freeVehicleAuction",
          t("FreeVehicleAuction"),
          writeBoolean
        )}

        <Form.Group as={Col} md={4} className={"text-center pt-4"}>
          <ButtonPrimary
            sizex={"md"}
            type={"submit"}
            loading={formik.isSubmitting}
            disabled={!props.permission}
          >
            {t("Save")}
          </ButtonPrimary>
        </Form.Group>

        <Form.Group as={Col} md={4} className={"text-center pt-4"}>
          <ButtonPrimary type={"button"} sizex={"md"} onClick={handleDownload}>
            {t("Download report")}
          </ButtonPrimary>
        </Form.Group>

        <Form.Group as={Col} md={4} className={"text-center pt-4"}>
          <ButtonPrimary
            type={"button"}
            sizex={"md"}
            onClick={handleSecondDownload}
            disabled={!props.inspection?.ad?.reinspectionReportLink}
          >
            {t("Download 2nd report")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
