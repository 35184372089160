import React from "react";
import { useTranslation } from "react-i18next";
import { TrackerListOrganism } from "../../../../organisms";

export const C4uTrackerListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleTrackerListPage")}</h3>
      <TrackerListOrganism />
    </>
  );
};
