import React from "react";

export const ArrowAround = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='10' cy='10' r='10' fill='#FFD478' />
    <g clipPath='url(#clip0_12745:33433)'>
      <rect
        width='12'
        height='12'
        transform='translate(4.00195 4.00098)'
        fill='#FFD478'
      />
      <path
        d='M6.50195 10.5013H12.087L9.64695 12.9413C9.45195 13.1363 9.45195 13.4563 9.64695 13.6513C9.84195 13.8463 10.157 13.8463 10.352 13.6513L13.647 10.3563C13.842 10.1613 13.842 9.84633 13.647 9.65133L10.357 6.35133C10.162 6.15633 9.84695 6.15633 9.65195 6.35133C9.45695 6.54633 9.45695 6.86133 9.65195 7.05633L12.087 9.50133H6.50195C6.22695 9.50133 6.00195 9.72633 6.00195 10.0013C6.00195 10.2763 6.22695 10.5013 6.50195 10.5013Z'
        fill='#1F3E74'
      />
    </g>
    <defs>
      <clipPath id='clip0_12745:33433'>
        <rect
          width='12'
          height='12'
          fill='white'
          transform='translate(4.00195 4.00098)'
        />
      </clipPath>
    </defs>
  </svg>
);
