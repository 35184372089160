import styled from "styled-components";

interface CardLeftProps {
  backgroundColor: string;
}

interface ArrowWrapperProps {
  disabled?: boolean;
}

const WrapperRegistrationHomeSmallCard = styled.div`
  display: flex;

  min-height: 95px;

  background: #fff;
  box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.25);
  border-radius: 20px;
  border: 1px solid transparent;

  transition: border 0.2s;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  :hover {
    border: 1px solid
      ${(props) => (props.onClick ? "rgba(41, 62, 107, 0.3)" : "transparent")};
  }

  @media (max-width: 991px) {
    width: 290px;
  }

  @media (max-width: 768px) {
    width: 334px;
    height: 100%;
    min-height: 125px;
  }
`;

const CardLeft = styled.div<CardLeftProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 85px;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 20px 0px 0px 20px;

  @media (max-width: 991px) {
    min-width: 72px;
    img {
      max-width: 58px;
    }
  }
`;

const CardRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  margin: 13px;
  @media (max-width: 767px) {
    margin: 21px 13px;
  }

  p {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #808285;

    padding-right: 30px;
    margin-bottom: 0;

    @media (max-width: 1096px) {
      font-size: 11px;
      padding-right: 10px;
    }

    @media (max-width: 991px) {
      line-height: 14px;
      padding-right: 22px;
    }
  }
`;

const SmallCardTitle = styled.div`
  display: flex;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #1f3e74;
`;

const ArrowWrapper = styled.div<ArrowWrapperProps>`
  position: absolute;
  right: 0px;
  bottom: 0px;

  transition: opacity 0.2s;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
`;

const Badge = styled.div`
  width: 65px;
  height: 18px;

  margin-left: 8px;

  color: #fff;
  background: #0799f8;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;

  text-align: center;
`;

export {
  WrapperRegistrationHomeSmallCard,
  CardLeft,
  CardRight,
  SmallCardTitle,
  ArrowWrapper,
  Badge,
};
