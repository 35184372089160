import { ButtonPrimary } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";

import { FeedbackModal } from "../../../../molecules";

interface ILoadFileBorrower {
  file: string | Blob;
}

interface IProps {
  setReloadList: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadFileResidualValuesFormOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postDataLoadResidualValues } = useManhein();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [file, setFile] = useState<string>();
  const formik = useFormik<ILoadFileBorrower>({
    initialValues: {} as ILoadFileBorrower,
    onSubmit: async (values, { setErrors }) => {
      if (file === undefined) {
        window.alert(t("SendFileReport"));
      } else {
        if (window.confirm(t("ConfirmAction"))) {
          try {
            await postDataLoadResidualValues(file);
            window.location.reload();
          } catch (e: any) {
            if (e) {
              setModalMessage(t("FileInvalidMessage"));
            }
            setShowWarningModal(true);
          }
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const selectFile = (event: any, setFile: (v: any) => void) => {
    const files = event.target.files;
    setFile(files[0]);
  };

  return (
    <>
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Row>
          <Form.Group as={Col} md={6} controlId="file">
            <Form.Label>{t("File")}</Form.Label>
            <Form.File
              onChange={(ev: any) => selectFile(ev, setFile)}
              feedback={<>{t("field_required")}</>}
              feedbackTooltip={true}
              title={t("UploadInspectionReportHelpMsg")}
            />
          </Form.Group>
          <Form.Group as={Col} md className={"text-right pt-2"}>
            <ButtonPrimary
              type={"submit"}
              sizex={"md"}
              loading={formik.isSubmitting}
            >
              {t("SendFile")}
            </ButtonPrimary>
          </Form.Group>
        </Row>
      </Form>
      <FeedbackModal
        type={"warning"}
        show={showWarningModal}
        onHide={() => {
          props.setReloadList(true);
          setShowWarningModal(false);
        }}
        content={modalMessage ? modalMessage : t("Try again error message")}
      />
    </>
  );
};
