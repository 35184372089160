import styled from "styled-components";

const FooterLine = styled.div`
  position: relative;
  display: block;
  width: calc(80%);
  margin-left: calc(10%);
  height: 0px;
  opacity: 0.2;
  border: 1px solid #ffffff;
`;

const C4uTrademark = styled.div`
  height: 24px;
  margin-top: 14px;

  /* Text 14 Regular */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  align-items: center;

  /* KBB White */
  color: #ffffff;
  opacity: 0.7;
`;

export { FooterLine, C4uTrademark };
