import {
  ButtonPrimary,
  ButtonSecondary,
  EnumHelper,
  FormikControlAtom,
  ModalDefault,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import {
  DeleteDiscoverMockRequest,
  EncodeLenderCodeEnum,
  IDeleteDiscoverMockRequest,
} from "../../../../models";
import {
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  id?: number;
  show: boolean;
  data?: IDeleteDiscoverMockRequest;
  onHide: () => void;
  onDeleteCallback: (request: DeleteDiscoverMockRequest) => Promise<void>;
  onDeleteSuccess: () => void;
  setException: (v: any) => void;
  setShowErrorModal: (v: boolean) => void;
}

export const DtMockDiscoverDeleteModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const initialValues = {
    publicPersonId: "",
    encodeLenderCode: "",
  };

  const validations = Yup.object<IDeleteDiscoverMockRequest>({
    publicPersonId: Yup.string().required(),
    encodeLenderCode: Yup.string().required(),
  });

  const formik = useFormik<IDeleteDiscoverMockRequest>({
    initialValues: props.data ?? initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onDeleteCallback(new DeleteDiscoverMockRequest(values));
        props.onDeleteSuccess();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          props.onHide();
          props.setException(t("GenericApiError"));
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (props.data) formik.setValues(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <ModalDefault
      show={props.show}
      onHide={props.onHide}
      title={t("Delete Credit Application #{id}", { id: props.id })}
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={props.onHide}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={() => {
              const bt = document.getElementById("submit-delete");
              if (bt) bt.click();
            }}
            loading={formik.isSubmitting}
          >
            {t("Delete")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="publicPersonId"
              label={t("PublicPersonId")}
              disabled
            />
          </Form.Row>

          <Form.Row>
            <FormikControlAtom
              type="select"
              data={EnumHelper.getComboFromEnum(
                EncodeLenderCodeEnum,
                t,
                (k, v) => `${k} (${v})`
              )}
              translate={t}
              formik={formik}
              property="encodeLenderCode"
              label={t("LenderCode")}
              disabled
            />
          </Form.Row>

          <button type="submit" id="submit-delete" className="d-none"></button>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
