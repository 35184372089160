import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Col } from "react-bootstrap";
import { ComboOptionsFromEnumAtom } from "../../../atoms";
import { StdEnum } from "../../../../models";

interface IProps {
  formik: any;
  DaelershipStatusOptions: StdEnum;
  CoxUserStatusOptions: StdEnum;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const CorporateUserListSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const defaultProps = {
    translate: t,
    formik: props.formik,
  };

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => props.formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            {...defaultProps}
            md={4}
            property={"search"}
            label={t("TextSearch")}
          />

          <FormikControlAtom
            {...defaultProps}
            as={"select"}
            md={4}
            lg={2}
            property={"active"}
            label={t("UserStatus")}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="active"
              optionsEnum={props.CoxUserStatusOptions}
            />
          </FormikControlAtom>

          <FormikControlAtom
            {...defaultProps}
            as={"select"}
            md={4}
            lg={2}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>

          <Col sm={6} md={4} lg={3} xl={2} className="pt-4 text-center ml-auto">
            <ButtonPrimary
              type="submit"
              sizex="md"
              loading={props.formik.isSubmitting}
            >
              {t("Search")}
            </ButtonPrimary>
          </Col>

          <Col sm={6} md={4} lg={3} xl={2} className="pt-4 text-center ml-auto">
            <ButtonSecondary
              sizex="md"
              disabled={props.formik.isSubmitting}
              onClick={props.cleanFiltersCallback}
            >
              {t("CleanFilters")}
            </ButtonSecondary>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
