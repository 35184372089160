import { Combo, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../../constants";
import { useManhein } from "../../../../../hooks";
import { FeedbackModal, LenderCreateUserMolecule } from "../../../../molecules";

export const LenderCreateUserOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const idNumber = isNaN(Number(id)) ? 0 : Number(id);
  const { postCustomer, getLenders, postChangePasswordCustomer } = useManhein();

  const history = useHistory();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<any>();
  const [lenders, setLenders] = useState<Combo[]>();

  const getLendersAsync = async () => {
    try {
      const response = await getLenders();

      const itemsCombo = response?.map(
        (m) =>
          new Combo({
            title: m.name,
            value: m.id,
          })
      );

      setLenders(itemsCombo);
    } catch (e) {
      setModalMessage(t("GenericApiError"));
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    getLendersAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {lenders !== undefined ? (
        <LenderCreateUserMolecule
          setModalMessage={setModalMessage}
          setShowErrorModal={setShowErrorModal}
          setShowSuccessModal={setShowSuccessModal}
          userId={idNumber}
          onSubmit={postCustomer}
          lenders={lenders}
          onChangePassword={postChangePasswordCustomer}
        />
      ) : (
        <SpinnerCenteredAtom />
      )}

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("api_error")}
      />

      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setModalMessage(undefined);
        }}
        content={modalMessage ? modalMessage : t("Ok")}
        actionButtonText={t("GoToList")}
        actionButtonOnClick={() =>
          history.push(paths.backofficeLenderListUsers())
        }
      />
    </>
  );
};
