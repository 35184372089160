import { NumberHelper } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useProducts } from "../../../../../hooks";
import {
  IKbbUserData,
  IVehicleDebitDetailsViewModel,
  IZapayTransactionData,
  IZapayVehicleData,
} from "../../../../../models";
import {
  KbbUserDetailsMolecule,
  ZapayDebtsPaidDetailsMolecule,
  DetailsPageSection,
  ZapayTransactionDetailsMolecule,
  ZapayVehicleDetailsMolecule,
} from "../../../../molecules";
import { WrapperDetails } from "../../../shared/shared.style";

export const DetailsKbbZapayOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getDebtsDetails } = useProducts();

  const [userDetails, setUserDetails] = useState<IKbbUserData>();
  const [vehicleDetails, setVehicleDetails] = useState<IZapayVehicleData>();
  const [debtsPaidDetails, setDebtsPaidDetails] =
    useState<IVehicleDebitDetailsViewModel[]>();
  const [transactionDetails, setTransactionDetails] =
    useState<IZapayTransactionData>();
  const [totalDebts, setTotalDebts] = useState<string>();

  const getDebtsDetailsAsync = useCallback(() => {
    getDebtsDetails(id).then((data) => {
      const {
        renavam,
        plate,
        detranUf,
        debitsProtocol,
        totalDebts,
        status,
        paymentDate,
        vehicleDebtDetails,
        customer,
      } = data;
      setUserDetails(customer);
      setVehicleDetails({
        detranUf,
        plate,
        renavam,
        clientDocument: customer.document,
      });
      setDebtsPaidDetails(vehicleDebtDetails);
      setTransactionDetails({
        debitsProtocol,
        status,
        paymentDate,
      });
      setTotalDebts(NumberHelper.toFormatString(totalDebts, 2, "0,00"));
    });
  }, [getDebtsDetails, id]);

  useEffect(() => {
    getDebtsDetailsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperDetails>
      <DetailsPageSection
        title={t("UserInformationDetails")}
        classNames={["user-data"]}
      >
        <KbbUserDetailsMolecule
          userDetails={userDetails}
          userMayHaveCnpj={true}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("VehicleInformationDetais")}
        classNames={["vehicle-data"]}
      >
        <ZapayVehicleDetailsMolecule vehicleDetails={vehicleDetails} />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("DebtsPaidInformationDetails")}
        classNames={["debts-paid-data"]}
      >
        <ZapayDebtsPaidDetailsMolecule
          debtsPaidDetails={debtsPaidDetails}
          totalDebts={totalDebts}
        />
      </DetailsPageSection>

      <DetailsPageSection
        title={t("TransactionInformationDetais")}
        classNames={["transaction-data"]}
      >
        <ZapayTransactionDetailsMolecule
          id={Number(id)}
          transactionDetails={transactionDetails}
        />
      </DetailsPageSection>
    </WrapperDetails>
  );
};
