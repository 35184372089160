const inputErrorHelper = (errors: any, t: any) => {
  if (errors) {
    if (errors.key) return t(errors.key, errors.values);
    if (errors.text) return t(errors.text, errors.values);
    if (typeof errors === "string") return t(errors);
  }

  return null;
};

export { inputErrorHelper };
