import axios from 'axios';

const KbbHttp = axios.create({
  baseURL: process.env.REACT_APP_API_KBB_URL,
});

const kbbClientConfig = {
  domain: process.env.REACT_APP_AUTH0_C4U_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_C4U_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_C4U_AUDIENCE,
  scope: process.env.REACT_APP_AUTH0_C4U_SCOPE
};

export { KbbHttp, kbbClientConfig };
