import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  padding: 15px;

  .overwrite-input-css-attrs input {
    height: unset;
  }
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
`;

export const DefaultModalText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: 600;
  font-size: 14px;
`;
