import styled from "styled-components";

export const WrapperApiUserDetails = styled.div``;

export const ApiUserDetailsSectionTitle = styled.h4`
  margin: 8px 0 0;
`;

export const DflexLabelDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 21px;
`;

export const ClicableIconDiv = styled.div`
  cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
`;

export const ApiUserDetailsHR = styled.hr`
  margin: 0.5rem 0;
`;

export const WrapperFormButtons = styled.div`
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 0;
`;

export const ListItemDecoder = styled.ul`
  list-style-type: circle;
`;

export const ItensPermissionsDecoder = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DataPermissions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
`;

export const ItensDecoder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const ButtonInformation = styled.button`
  border: none !important;
  background-color: #f8f9fa !important;
  color: rgb(20, 40, 76);
  font-size: 20px;
`;

export const ContainerInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
`;
