import {
  ButtonPrimary,
  Combo,
  MaskHelper,
  YupHelper,
} from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../../helper";
import { FormikControlAtom } from "../../../../atoms";
import { addDays } from "date-fns";

interface IProps {
  onSubmit: (values: IInspectionSchedule) => Promise<number | undefined>;
  data: IInspectionSchedule;
  periods: Combo[];
  setException: React.Dispatch<any>;
  disabled: boolean;
}

export interface IInspectionSchedule {
  inspectionDate?: string;
  inspectionPeriod?: number;
  justification: string;
}

export const AdInspectionScheduleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "greaterThanDatePtBr",
    YupHelper.greaterThanDatePtBr
  );

  const validations = Yup.object<IInspectionSchedule>({
    inspectionDate: Yup.string()
      .required()
      .greaterThanDatePtBr(addDays(new Date(), -1), "date_invalid"),
    inspectionPeriod: Yup.number().required(),
    justification: Yup.string().required(),
  });

  const formik = useFormik<IInspectionSchedule>({
    initialValues: props.data,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await props.onSubmit(values);
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"inspectionDate"}
          mask={MaskHelper.Date}
          disabled={props.disabled}
        />
        <FormikControlAtom
          formik={formik}
          md={6}
          property={"inspectionPeriod"}
          as={"select"}
          disabled={props.disabled}
        >
          {props.periods.map((m, i) => (
            <option value={m.value} key={i}>
              {m.title}
            </option>
          ))}
        </FormikControlAtom>
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          md={12}
          as={"textarea"}
          property={"justification"}
          disabled={props.disabled}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={props.disabled}
          >
            {t("Change schedule date")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
