import React from "react";
import { useTranslation } from "react-i18next";
import { PaginatableTableList } from "../../../..";
import { DateHelper } from "../../../../../../helper";
import {
  IGetTrackerDetailStepsLog,
  TrackerStepEnum,
} from "../../../../../../models";

interface IProps {
  stepsHistoryDetails?: IGetTrackerDetailStepsLog[];
}

export const TrackerStepsHistoryDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return !props.stepsHistoryDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <PaginatableTableList
      tableName={"coverage-details"}
      dataIsLoading={false}
      thead={[t("TrackerStepNumber"), t("TrackerStepName"), t("Date")]}
      tbody={props.stepsHistoryDetails?.map((m, i) => [
        m.step + 1,
        t(TrackerStepEnum[m.step]),
        DateHelper.dateHourFormat(m.createdAt),
      ])}
    />
  );
};
