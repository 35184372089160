import styled from "styled-components";

export const Wrapper = styled.div`
  .row > .col {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
  }
`;
