import { YupHelper } from "c4u-web-components";
import * as Yup from "yup";
import { IDtInformation } from "../../../../models";

export const DtMockSyncValidationSchema = () => {
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPasswordValid",
    YupHelper.isPasswordValid
  );

  const validations = Yup.object<IDtInformation>({
    creditApp: Yup.object({
      publicPersonId: Yup.string().required(),
      encodeLenderCode: Yup.string().required(),
      step: Yup.string().required(),
      expiryDate: Yup.date().required(),
    }).required(),
    customer: Yup.object({
      completeName: Yup.string().required(),
      cpf: Yup.string().required(),
      typeId: Yup.string().required(),
      idNumber: Yup.string().required(),
      expeditorCode: Yup.string().required(),
      expeditionDate: Yup.date().required(),
      birthDate: Yup.date().required(),
      gender: Yup.string().required(),
      email: Yup.string().required(),
      maritalStatus: Yup.string().required(),
      revenue: Yup.number().required(),
      professionalActivity: Yup.string().required(),
      profession: Yup.string().required(),
      countryOfBorn: Yup.string().required(),
      phonenumber: Yup.string().required(),
      cellphonenumber: Yup.string().required(),
      address: Yup.object({
        zipCode: Yup.string().required(),
        address: Yup.string().required(),
        homeNro: Yup.string().required(),
        district: Yup.string().required(),
        complement: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
      }),
    }).required(),
    dealership: Yup.object({
      businessNumber: Yup.string().required(),
      legalName: Yup.string().required(),
      name: Yup.string().required(),
    }).required(),
    vehicle: Yup.object({
      manufactureYear: Yup.string().required(),
      modelYear: Yup.string().required(),
      licensePlate: Yup.string().required(),
      vinNmber: Yup.string().required(),
      renavam: Yup.string().required(),
      color: Yup.string().required(),
      price: Yup.number().required(),
      isNew: Yup.string().required(),
      molicarId: Yup.string().required(),
      licensePlateUF: Yup.string().required(),
    }).required(),
  });

  return validations;
};
