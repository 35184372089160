import React, {
  createContext,
  FunctionComponent,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { StepperItem } from "c4u-web-components"

import { CreateDealershipFormFields } from "../ui/organisms/home/create-dealership-form/create-dealership-form.organism";
import { CreateCompleteUserFormFields } from "../ui/organisms/home/create-users-form/create-users-form.organism";

export interface IRegistrationStepsProviderProps {
  stepsContext: Array<StepperItem>;
  setStepsContext: React.Dispatch<React.SetStateAction<Array<StepperItem>>>;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
}

export const createDealershipStep :Array<StepperItem> = [];

export const createUsersStep :Array<StepperItem> = [];

const RegistrationStepsContext = createContext({} as IRegistrationStepsProviderProps);

const RegistrationStepsProvider: FunctionComponent = (props) => {

  const { t } = useTranslation();

  if (createDealershipStep.length === 0) {
    createDealershipStep.push({
      key: "dealership",
      title: t("Dealership Data"),
      content: <CreateDealershipFormFields />,
      $concluded: true,
      disabled: false,
    });
    createDealershipStep.push({
      key: "users",
      title: t("Register Users"),
      content: <CreateCompleteUserFormFields />,
      $concluded: false,
      disabled: true,
    });
  }
  
  if (createUsersStep.length === 0) {
    createUsersStep.push({
      key: "dealership",
      title: t("Dealership Data"),
      content: <CreateDealershipFormFields />,
      $concluded: true,
      disabled: false,
    });
    createUsersStep.push({
      key: "users",
      title: t("Register Users"),
      content: <CreateCompleteUserFormFields />,
      $concluded: true,
      disabled: false,
    });
  }

  const [stepsContext, setStepsContext] = useState<Array<StepperItem>>(createDealershipStep);
  const [currentStep, setCurrentStep] = useState<string>('');

  return (
    <RegistrationStepsContext.Provider
      value={
        {
          stepsContext,
          setStepsContext,
          currentStep,
          setCurrentStep,
        } as IRegistrationStepsProviderProps
      }
    >
      {props.children}
    </RegistrationStepsContext.Provider>
  );
};

export { RegistrationStepsContext, RegistrationStepsProvider };
