import { CoxIcon } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKbbBackoffice } from "../../../../../hooks";
import { IGetParametersResponse, IParametersModel } from "../../../../../models";
import {
  FeedbackModal,
  PaginatableTableList,
  ParameterFormsModalMolecule,
} from "../../../../molecules";
import { ClicableDiv } from "../../../shared/shared.style";

export const ApiParametersListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getParameters, updateParameters } = useKbbBackoffice();

  const [parametersList, setParametersList] =
    useState<IGetParametersResponse>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>();
  const [currentParameter, setCurrentParameter] = useState<IParametersModel>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exception, setException] = useState<any>();

  const reloadList = useCallback(() => {
    setParametersList(undefined);
    setDataIsLoading(true);
    getParameters()
      .then((t) => setParametersList(t))
      .catch(() => {
        setParametersList([]);
        setException(t("GenericApiErrorFetchingData"));
        setShowErrorModal(true);
      })
      .finally(() => setDataIsLoading(false));
  }, [getParameters, t]);

  useEffect(() => {
    reloadList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getEditButton = useCallback(
    (id: number, i: number) => (
      <ClicableDiv
        className="text-center w-100"
        key={`link${i}`}
        onClick={
          !parametersList
            ? undefined
            : () => {
                setCurrentParameter(parametersList.find((f) => f.id === id));
                setShowEditModal(true);
              }
        }
        title={t("Edit")}
      >
        <CoxIcon
          name="pencil-edit"
          width={16}
          height={16}
          fillColor="#1F3E74"
        />
      </ClicableDiv>
    ),
    [parametersList, t]
  );

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
    setCurrentParameter(undefined);
  }, []);

  return (
    <div className="mt-3 mx-lg-5 px-md-5">
      <PaginatableTableList
        tableName="api-parameters-list"
        dataIsLoading={dataIsLoading}
        thead={["#", t("Key"), t("Value"), ""]}
        tbody={parametersList?.map((m, i) => [
          m.id,
          m.key,
          m.value,
          getEditButton(m.id, i),
        ])}
      />

      {currentParameter && (
        <ParameterFormsModalMolecule
          show={showEditModal}
          data={currentParameter}
          onHide={onCloseEditModal}
          onSaveCallback={updateParameters}
          onSaveSuccess={() => {
            onCloseEditModal();
            reloadList();
          }}
          setException={setException}
          setShowErrorModal={setShowErrorModal}
        />
      )}

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setException(undefined);
        }}
        content={exception ? exception : t("api_error")}
      />
    </div>
  );
};
