import { DownloadFileAtom } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { StringHelper } from "../../../../../helper";
import { useKbbBackoffice } from "../../../../../hooks";
import {
  IGetConsumptionReportResponse,
  IGetConsuptionDetailsParams,
  MonthNamesEnum,
  ParametersEnum,
} from "../../../../../models";
import { ApiUsageReportTableSearchOrganism } from "./api-usage-report-table-search.organism";

export const ApiUsageReportOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getConsumptionReport, getDetailedConsumptionReport } =
    useKbbBackoffice();

  return (
    <ApiUsageReportTableSearchOrganism
      getDataCallback={getConsumptionReport}
      parametersEnum={ParametersEnum}
      monthNamesEnum={MonthNamesEnum}
      orderByOptions={["Date", "Name", "Cnpj", "Calls", "MonthlyCallsLimit"]}
      tableHeaders={[
        t("ReferenceMonth"),
        t("ApiUserId"),
        t("Name"),
        t("Cnpj"),
        t("ServiceUsed"),
        t("Calls"),
        t("MonthlyCallsLimit"),
        t("RemainingCalls"),
        t("Csv"),
      ]}
      mappingFunction={(m: IGetConsumptionReportResponse, i) => [
        `${t(MonthNamesEnum[m.month])}/${m.year}`,
        m.apiUserId,
        m.name,
        StringHelper.formatCpfCnpj(m.cnpj),
        t(ParametersEnum[m.serviceUsed]),
        m.calls,
        m.monthlyCallsLimit,
        m.remainingCalls,
        <DownloadFileAtom<IGetConsuptionDetailsParams>
          getFileParams={{
            year: m.year,
            month: m.month,
            apiId: m.apiUserId,
            kbbApi: m.serviceUsed,
          }}
          getFileCallback={getDetailedConsumptionReport}
          fileName={`${t("ApiUsageReportFileBaseName")} - ${m.name} ${
            m.year
          }-${m.month.toString().padStart(2, "0")} ${
            ParametersEnum[m.serviceUsed]
          }.csv`}
        />,
      ]}
    />
  );
};
