import React, { useEffect } from "react";

import { FormikControlAtom } from "c4u-web-components";

import { ContentWrapper } from "./training.page.style";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  LogoAzulSeguradora_150_112,
  LogoData_125_146,
  LogoDealertrack_218_33,
  LogoIturan_134_69,
  LogoKbb_175_61,
  LogoLiberty_164_89,
  LogoManheimExpress_163_53,
  LogoPortoSeguro_164_53,
  LogoSupervisao_220_95,
} from "../../assets";

import { CardPartnerMolecule } from "../../molecules";
import { useSessionContext } from "../../../hooks";

export const TrainingPage = () => {
  const { t } = useTranslation();

  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("NotActive");
    // eslint-disable-next-line
  }, []);

  const items = [
    { image: LogoDealertrack_218_33, title: "Dealertrack" },
    { image: LogoKbb_175_61, title: "KBB" },
    { image: LogoManheimExpress_163_53, title: "Manheim" },
    { image: LogoIturan_134_69, title: "Ituran" },
    { image: LogoLiberty_164_89, title: "Liberty" },
    { image: LogoPortoSeguro_164_53, title: "Porto Seguro" },
    { image: LogoSupervisao_220_95, title: "Supervisão" },
    { image: LogoAzulSeguradora_150_112, title: "Azul Seguros" },
    { image: LogoData_125_146, title: "Dados" },
  ];

  return (
    <>
      <ContentWrapper>
        <Container className={"mb-4"}>
          <Row>
            <Col>
              <h4>{t("TrainingTitle")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>{t("TrainingSubTitle")}</h6>
            </Col>
          </Row>

          <Row className={"form-default pb-4"}>
            <FormikControlAtom
              type={"select"}
              formik={{ values: { data: "" } }}
              property={"data"}
              label={t("Date")}
              translate={t}
              md={3}
            />
            <FormikControlAtom
              type={"select"}
              formik={{ values: { data: "" } }}
              property={"data"}
              label={t("Training type")}
              translate={t}
              md={3}
            />
            <FormikControlAtom
              type={"select"}
              formik={{ values: { data: "" } }}
              property={"data"}
              label={t("Subject")}
              translate={t}
              md={3}
            />
          </Row>

          <Row className={"pt-4"}>
            {items.map((m, i) => (
              <Col sm={6} lg={4} key={i}>
                <CardPartnerMolecule
                  img={m.image}
                  labelDate={"--/--"}
                  imgTitle={m.title}
                  labelBox={t("More details")}
                  labelBottomBox={t("Register")}
                  tooltipComingSoon={t("ComingSoon")}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </ContentWrapper>
    </>
  );
};
