import React from "react";

import { ButtonTertiary, SideMenu, TopBarMenu } from "c4u-web-components";

import { ContentWrapper, Title } from "./onboarding.page.style";

import { OnboardingFormFields } from "../../organisms";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const OnboardingPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopBarMenu />
      <SideMenu />
      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <Breadcrumb>{`${t("Home")} / ${t("Profile")} / ${t(
                "Change password"
              )}`}</Breadcrumb>
            </Col>
          </Row>
          <Row className={"my-5"}>
            <Col md={"auto"}>
              <Title>{t("Change register data")}</Title>
            </Col>
          </Row>
          <Row>
            <Col md={"auto"}>
              <ButtonTertiary sizex={"md"}>
                {t("Personal password")}
              </ButtonTertiary>
            </Col>
            <Col>
              <OnboardingFormFields />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    </>
  );
};
