import styled from 'styled-components';
import { RegistrationHomeContainerProps } from './registration-home-svg.atom';

const RegistrationHomeContainerStyled = styled.div<RegistrationHomeContainerProps>`
  display: inline-block;
  padding: 5px 20px 5px 20px;
  width: ${(props) => props.width ? `${props.width}px` : '100%'};
  background: ${(props) => props.color ? props.color : '#FAFAFB'};;
  border-radius: 20px;
  overflow-y: auto;

  @media (min-width: 576px) {
    height: ${(props) => props.height ? `${props.height}px` : '554px'};
    min-height: ${(props) => props.height ? `${props.height}px` : '554px'};
  }

`;

export { RegistrationHomeContainerStyled };
