import { ButtonSecondary } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";

interface IProps {
  adId: number;
}

export const AdStampsButtons: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { adId } = props;

  const { stampAsDamaged, stampAsFleet, clearStamps } = useManhein();

  const [stampAsDamagedLoad, setStampAsDamagedLoad] = useState(false);
  const stampAsDamagedClick = async () => {
    if (window.confirm(t("ConfirmPhotoStamp"))) setStampAsDamagedLoad(true);
  };
  useEffect(() => {
    if (stampAsDamagedLoad)
      stampAsDamaged(adId).finally(() => window.location.reload());
    // eslint-disable-next-line
  }, [stampAsDamagedLoad]);

  const [asFleetClickLoad, setAsFleetClickLoad] = useState(false);
  const setAsFleetClick = async () => {
    if (window.confirm(t("ConfirmPhotoStamp"))) setAsFleetClickLoad(true);
  };
  useEffect(() => {
    if (asFleetClickLoad)
      stampAsFleet(adId).finally(() => window.location.reload());
    // eslint-disable-next-line
  }, [asFleetClickLoad]);

  const [clearStampsLoad, setClearStampsLoad] = useState(false);
  const clearStampsClick = async () => {
    if (window.confirm(t("ConfirmPhotoStamp"))) setClearStampsLoad(true);
  };
  useEffect(() => {
    if (clearStampsLoad)
      clearStamps(adId).finally(() => window.location.reload());
    // eslint-disable-next-line
  }, [clearStampsLoad]);

  return (
    <>
      <Col sm={4}>
        <ButtonSecondary
          sizex={"md"}
          onClick={clearStampsClick}
          profile="manheim"
          disabled={stampAsDamagedLoad || asFleetClickLoad}
          loading={clearStampsLoad}
        >
          {t("Clear stamps")}
        </ButtonSecondary>
      </Col>
      <Col sm={4}>
        <ButtonSecondary
          sizex={"md"}
          onClick={setAsFleetClick}
          profile="manheim"
          disabled={stampAsDamagedLoad || clearStampsLoad}
          loading={asFleetClickLoad}
        >
          {t("Stamp as fleet")}
        </ButtonSecondary>
      </Col>
      <Col sm={4}>
        <ButtonSecondary
          onClick={stampAsDamagedClick}
          sizex={"md"}
          profile="danger"
          loading={stampAsDamagedLoad}
          disabled={asFleetClickLoad || clearStampsLoad}
        >
          {t("Stamp as damaged")}
        </ButtonSecondary>
      </Col>
    </>
  );
};
