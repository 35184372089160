import styled from "styled-components";
import { Form } from "react-bootstrap";

export const Wrapper = styled.div``;

export const ContainerDecoder = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FormDecoder = styled(Form.Group)`
  margin: 0 auto;
`;
