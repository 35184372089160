import {
  CorporateUserDealershipLogRequest,
  HttpHelper,
  ICoxUser,
} from "c4u-web-components";
import {
  ApplicationTermEnum,
  ApprovalStatusEnum,
  CreateCorporateUserRequest,
  CreateUserRequest,
  DealerList,
  DealershipFilterModel,
  DealershipReportPagedParams,
  DealershipUpdateByUserRequest,
  IAuth0User,
  ICorporateUserDetailsResponse,
  IDealershipReportPagedResponse,
  IListCorporateUserPagedResponse,
  IListDealershipsPagedResponse,
  IListUserPagedResponse,
  IUpdateDealershipModel,
  IUserDetailsResponse,
  ListCorporateUsersParams,
  ListDealershipsPagedParams,
  ListUsersParams,
  ResetPasswordRequest,
  TypeTermEnum,
  UpdateDataUserRequest,
  UpdateDealershipRequest,
  UpdateUserRequest,
} from "../../../models";
import {
  AcceptTermsOfUseRequest,
  ITermsOfUseResponse,
} from "../../../models/apis/c4u/terms-of-use.request";

import { C4uClient, C4uClientConfig, useRequest } from "../config";
import {
  ICompleteUser,
  IDealership,
  IDefaultResponse,
  IGetDealershipsViewModel,
  IOnboardingCompletion,
  IRetryDealertrackIntegration,
  IRetryIntegration,
  IUser,
  IUserOnboarding,
  IUserViewModel,
} from "./interfaces/interfaces";

export const useC4u = () => {
  const { get, post, put, del } = useRequest(C4uClient, "", C4uClientConfig);

  const GetUsersCreatedBy = async (
    createdById: string
  ): Promise<Array<ICompleteUser>> => {
    const { data } = await get(`User/${createdById}/users`);
    return data;
  };

  const CreateUser = async (user: IUser): Promise<IUser> => {
    const { data } = await post("User", JSON.stringify(user));
    return data;
  };

  const CreateCompleteUser = async (
    id: string,
    users: Array<ICompleteUser>
  ): Promise<any> => {
    const { data } = await post(
      `User/${id}/CreateBatch`,
      JSON.stringify(users)
    );
    return data;
  };

  const CreateDealership = async (
    dealership: IDealership
  ): Promise<IDealership> => {
    const { data } = await post("Dealership", JSON.stringify(dealership));
    return data;
  };

  const Authenticate = async (): Promise<void> => {
    const { data } = await post("LoginLog", {});
    return data;
  };

  const postSelectDealership = async (
    request: CorporateUserDealershipLogRequest
  ): Promise<void> => {
    await post("LoginLog/createCorporateUserDealershipLog", request);
  };

  const ApproveOnboarding = async (
    onboarding: IOnboardingCompletion
  ): Promise<boolean> => {
    const { data } = await post(
      "Onboarding/Approve",
      JSON.stringify(onboarding)
    );
    return data !== null && data !== undefined;
  };

  const DeclineOnboarding = async (
    onboarding: IOnboardingCompletion
  ): Promise<boolean> => {
    const { data } = await post(
      "Onboarding/Decline",
      JSON.stringify(onboarding)
    );
    return data !== null && data !== undefined;
  };

  const RetryWithAutoAvaliar = async (
    retryAutoAvaliar: IRetryIntegration
  ): Promise<boolean> => {
    const { data } = await post(
      "Onboarding/SendToAutoAvaliar",
      JSON.stringify(retryAutoAvaliar)
    );
    return data !== null && data !== undefined;
  };

  const RetryWithIdp = async (
    retryIdp: IRetryIntegration
  ): Promise<boolean> => {
    const { data } = await post(
      "Onboarding/SendToIdp",
      JSON.stringify(retryIdp)
    );
    return data !== null && data !== undefined;
  };

  const RetryWithDealertrack = async (
    retryDt: IRetryDealertrackIntegration
  ): Promise<boolean> => {
    const { data } = await post(
      "Onboarding/SendToDealertrack",
      JSON.stringify(retryDt)
    );
    return data !== null && data !== undefined;
  };

  const StartUserOnboarding = async (
    userToken: string
  ): Promise<IUserOnboarding> => {
    const { data } = await get(`Onboarding/Start/${userToken}`);
    return data;
  };

  const SubmitUserOnboarding = async (
    onboarding: IUserOnboarding
  ): Promise<IUserOnboarding> => {
    const { data } = await post(
      "Onboarding/Submit",
      JSON.stringify(onboarding)
    );
    return data;
  };

  const GetAllDealers = async (): Promise<DealerList[]> => {
    const { data } = await get("Dealership");
    return data;
  };

  const GetFilteredDealers = async (
    filter: DealershipFilterModel
  ): Promise<DealerList[]> => {
    const { data } = await post("Dealership/filter", filter);
    return data;
  };

  const GetOnboarding = async (id: number): Promise<IOnboardingCompletion> => {
    const { data } = await get(`Onboarding/${id}`);
    return data;
  };

  const GetDealer = async (id: number): Promise<IDealership> => {
    const { data } = await get(`Dealership/${id}`);
    return data;
  };

  const GetDealerCreatedBy = async (id: string): Promise<IDealership> => {
    const { data } = await get(`User/${id}/Dealership`);
    return data;
  };

  const FeedUserFromDealertrack = async (
    dtUserId: string,
    email: string
  ): Promise<any> => {
    const { data } = await post(`User/Feed`, {
      UserId: dtUserId,
      Email: email,
    });

    return data;
  };

  const GetUser = async (): Promise<ICoxUser> => {
    const { data } = await get(`Admin/User`);
    return data;
  };

  const GetAuth0User = async (): Promise<IAuth0User> => {
    const { data } = await get(`Admin/Auth0User`);
    return data;
  };

  const UpdateDataUser = async (
    request: UpdateDataUserRequest
  ): Promise<ICoxUser> => {
    const { data } = await put(`Admin/UpdateDataUser`, request);
    return data;
  };

  const ResetPassword = async (
    request: ResetPasswordRequest
  ): Promise<IDefaultResponse> => {
    const { data } = await put(`Admin/ResetPassword`, request);
    return data;
  };

  const UpdateDealershipByUser = async (
    request: DealershipUpdateByUserRequest
  ): Promise<IDealership> => {
    const { data } = await put(`Dealership/UpdateByUser`, request);
    return data;
  };

  const UpdateUser = async (
    request: UpdateUserRequest
  ): Promise<ICompleteUser> => {
    const { data } = await post(`Admin/editBatch`, request);
    return data;
  };

  const RemoveUser = async (userId: number): Promise<void> => {
    const { data } = await del(`Admin/deleteUser/${userId}`);
    return data;
  };

  const GetDealershipsReport = async (
    params: DealershipReportPagedParams
  ): Promise<IDealershipReportPagedResponse> => {
    const { data } = await get(
      `Backoffice/dealerships/${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const GetUsersReport = async (): Promise<any[]> => {
    const { data } = await get("Backoffice/users");
    return data;
  };

  const GetOnboardingsReport = async (): Promise<any[]> => {
    const { data } = await get("Backoffice/onboardings");
    return data;
  };

  const GetDealershipByUser = async (email: string): Promise<IDealership> => {
    const { data } = await get(`Backoffice/GetDealershipByUser/${email}`);
    return data;
  };

  const GetDealerships = async (
    approvalStatus?: ApprovalStatusEnum
  ): Promise<IGetDealershipsViewModel[]> => {
    const { data } = await get(
      `Backoffice/GetDealerships/${HttpHelper.BuildQueryString({
        approvalStatus: approvalStatus ?? null,
      })}`
    );
    return data;
  };

  const GetByDealershipId = async (id: number): Promise<IUserViewModel[]> => {
    const { data } = await get(`Backoffice/GetByDealershipId/${id}`);
    return data;
  };

  const InactivateDealer = async (
    id: number,
    active: boolean
  ): Promise<void> => {
    await put(`Backoffice/InactivateDealer`, { dealerId: id, active: active });
  };

  const GetAllUsers = async (
    params: ListUsersParams
  ): Promise<IListUserPagedResponse> => {
    const { data } = await get(
      `Backoffice/listUsers${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const GetUserDetail = async (id: number): Promise<IUserDetailsResponse> => {
    const { data } = await get(`Backoffice/getUserDetail/${id}`);
    return data;
  };

  const PostCreateUsers = async (request: CreateUserRequest): Promise<void> => {
    await post(`Backoffice/createUser`, request);
  };

  const updateDealership = async (
    id: string,
    request: UpdateDealershipRequest
  ): Promise<void> => {
    await put(`Backoffice/updateDealership/${id}`, request);
  };

  const getDealership = async (id: string): Promise<IUpdateDealershipModel> => {
    const { data } = await get(`Backoffice/getDealership/${id}`);
    return data;
  };

  const getCorporateUsers = async (
    params: ListCorporateUsersParams
  ): Promise<IListCorporateUserPagedResponse> => {
    const { data } = await get(
      `Backoffice/listCorporateUsers${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const getCorporateUserDetail = async (
    id: number
  ): Promise<ICorporateUserDetailsResponse> => {
    const { data } = await get(`Backoffice/getCorporateUserDetail/${id}`);
    return data;
  };

  const postCreateCorporateUser = async (
    request: CreateCorporateUserRequest
  ): Promise<{ id: number }> => {
    const { data } = await post("Backoffice/createCorporateUser", request);
    return data;
  };

  const getListDealerships = async (
    params: ListDealershipsPagedParams
  ): Promise<IListDealershipsPagedResponse> => {
    const { data } = await get(
      `Backoffice/listDealerships${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const getListMyDealerships = async (
    params: ListDealershipsPagedParams
  ): Promise<IListDealershipsPagedResponse> => {
    const { data } = await get(
      `Admin/listMyDealerships${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const postAcceptTerms = async (
    request: AcceptTermsOfUseRequest
  ): Promise<void> => {
    await post("TermsOfUse/acceptTerms", request);
  };

  const getCustomerTerms = async (
    applicationId: ApplicationTermEnum,
    documentId: TypeTermEnum,
    customerId: string
  ): Promise<ITermsOfUseResponse> => {
    const { data } = await get(
      `TermsOfUse/customerTerms/${applicationId}/${documentId}${HttpHelper.BuildQueryString(
        { customerId }
      )}`
    );

    return data;
  };

  return {
    GetByDealershipId,
    GetAuth0User,
    RemoveUser,
    UpdateUser,
    UpdateDealershipByUser,
    ResetPassword,
    UpdateDataUser,
    GetUser,
    CreateCompleteUser,
    CreateDealership,
    CreateUser,
    GetAllDealers,
    GetFilteredDealers,
    GetDealer,
    GetDealerCreatedBy,
    GetUsersCreatedBy,
    FeedUserFromDealertrack,
    ApproveOnboarding,
    DeclineOnboarding,
    GetOnboarding,
    StartUserOnboarding,
    SubmitUserOnboarding,
    RetryWithAutoAvaliar,
    RetryWithIdp,
    RetryWithDealertrack,
    GetDealershipsReport,
    GetUsersReport,
    GetOnboardingsReport,
    GetDealershipByUser,
    GetDealerships,
    GetAllUsers,
    GetUserDetail,
    InactivateDealer,
    PostCreateUsers,
    updateDealership,
    getDealership,
    Authenticate,
    postSelectDealership,
    getCorporateUsers,
    getCorporateUserDetail,
    postCreateCorporateUser,
    getListDealerships,
    getListMyDealerships,
    postAcceptTerms,
    getCustomerTerms,
  };
};
