import {
  ButtonPrimary,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import { jsPDF } from "jspdf";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useKbbBackoffice, useSessionContext } from "../../../../hooks";
import {
  IFindByPlateResponse,
  IFoundVehicles,
  DecoderReviewStatusEnum,
  UploadDocumentRequest,
} from "../../../../models";
import { FeedbackModal, VehicleDetailsMolecule } from "../../../molecules";
import { UploadDocumentMolecule } from "../upload-document/upload-document.molecule";
import {
  ContainerSearch,
  RequestingInputtingHR,
  WrapperFormButtons,
  WrapperInputting,
} from "./requesting-inputting-form.molecule.style";

interface ImageDimension {
  width: number;
  height: number;
}

interface IProps {
  setShowErrorModal: (v: boolean) => void;
  setShowSuccessModal: (v: boolean) => void;
  setModalMessage: React.Dispatch<any>;
  initialValues?: UploadDocumentRequest | null;
  apiUserId: number;
  data: IFoundVehicles;
}

export const RequestingInputtingFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { getFindByPlate } = useKbbBackoffice();
  const { updateDecoderReview } = useKbbBackoffice();

  const {
    vehicleContext,
    setVehicleContext,
    documentContext,
    setDocumentContext,
  } = useSessionContext();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [errorShowModal, setShowErrorModal] = useState(false);
  const [show, setShow] = useState<boolean>(false);

  const [dataVehicle, setDataVehicle] = useState<IFindByPlateResponse>();

  const initialValues =
    props.initialValues ??
    ({
      plate: "",
    } as UploadDocumentRequest);

  const getVehicleAsync = useCallback(
    async (plate: string): Promise<void> => {
      const vehicle = await getFindByPlate(plate, false);
      setVehicleContext(vehicle.vehicles[0]);
      setDataVehicle(vehicle);
    },
    [getFindByPlate, setVehicleContext]
  );

  const formik = useFormik<UploadDocumentRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        if (values.plate) {
          await values;
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const uploadDocumentAsync = async (filePdf: File) => {
    updateDecoderReview(
      new UploadDocumentRequest({
        plate: formik.values.plate ?? "",
        fipeId: vehicleContext?.fipeId,
        ituranId: vehicleContext?.ituranId,
        kbbId: vehicleContext?.kbbId,
        molicarId: vehicleContext?.molicarId,
        brandName: vehicleContext?.brand,
        modelName: vehicleContext?.model,
        versionName: vehicleContext?.version,
        modelYear: vehicleContext?.modelYear,
        cc: vehicleContext?.cc,
        observation: formik.values.observation ?? "",
        decoderReviewStatus: DecoderReviewStatusEnum.Pending,
        file: filePdf,
      })
    )
      .then(() => {
        setShow(true);
      })
      .catch((error) => {
        setShow(true);
        console.log("Error: " + error);
      });
  };

  const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
  };

  const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

  const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;

    if (isLandscapeImage) {
      return {
        width: A4_PAPER_DIMENSIONS.width,
        height:
          A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
      };
    }

    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
      const imageScaleFactor =
        (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

      const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      };
    }

    return {
      width:
        A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
      height: A4_PAPER_DIMENSIONS.height,
    };
  };

  const generatePdf = async () => {
    if (documentContext?.file.length === 2) {
      const doc = new jsPDF();

      doc.deletePage(1);

      documentContext.file.forEach((image) => {
        const imageDimensions = imageDimensionsOnA4({
          width: image.width ?? 0,
          height: image.height ?? 0,
        });

        doc.addPage();
        doc.addImage(
          URL.createObjectURL(image.file),
          image.file.type,
          (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          imageDimensions.width,
          imageDimensions.height
        );
      });

      const pdfBlob = doc.output("blob");
      const file = new File([pdfBlob], "pdf-file", {
        type: pdfBlob.type,
      });

      await uploadDocumentAsync(file);
    } else {
      const fileBlob = documentContext?.file[0].file;

      const file = new File([fileBlob], "pdf-file", {
        type: fileBlob?.type,
      });

      await uploadDocumentAsync(file);
    }
  };

  const cleanData = useCallback(() => {
    setDataLoaded(false);
    setVehicleContext(undefined);
    formik.setFieldValue("plate", "");
    window.location.reload();
  }, [formik, setVehicleContext]);

  const handleSearchVehicle = useCallback(() => {
    if (formik?.values?.plate?.replaceAll(/[_-]/gi, "").length === 7)
      getVehicleAsync(formik.values.plate)
        .then(() => {
          setDataLoaded(true);
        })
        .catch((err) => {
          setShowErrorModal(true);
          setDataLoaded(false);
          console.log(err);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.plate]);

  useEffect(() => {
    if (formik?.values?.plate?.replaceAll(/[_-]/gi, "").length === 7) {
      setIsValid(true);
    } else {
      setDataLoaded(false);
      setIsValid(false);
      setVehicleContext(undefined);
      setDocumentContext(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.plate]);

  return (
    <WrapperInputting>
      <RequestingInputtingHR />
      <Form onSubmit={(e: any) => formik.handleSubmit(e)} autoComplete="off">
        <input type="hidden" value="disable-autocomplete-chrome" />

        <ContainerSearch>
          <Form.Row className="align-items-center">
            <FormikControlAtom
              md={3}
              formik={formik}
              property={"plate"}
              label={t("Plate")}
              translate={t}
              func={(v: string) => v?.toUpperCase()}
              mask={MaskHelper.PlateBr}
            />
            <span
              className="ml-2 pt-2"
              style={{
                color: "red",
              }}
            >
              {t("EnterPlate")}
            </span>

            <ButtonPrimary
              sizex="md"
              type="submit"
              loading={formik.isSubmitting}
              disabled={!isValid}
              onClick={handleSearchVehicle}
              data-cyid="button-search"
            >
              {t("Buscar")}
            </ButtonPrimary>
          </Form.Row>
        </ContainerSearch>

        {dataLoaded && (
          <>
            <RequestingInputtingHR />
            {dataVehicle?.vehicles.map((m, i) => (
              <VehicleDetailsMolecule
                key={`cardados-details-${i}`}
                data={m}
                showInitial={dataVehicle?.vehicles.length === 1 ? true : false}
              />
            ))}
            <RequestingInputtingHR />
            <UploadDocumentMolecule />
            <RequestingInputtingHR />
            &nbsp;
            <FormikControlAtom
              formik={formik}
              md={12}
              as={"textarea"}
              property={"observation"}
              translate={t}
              placeholder={t("Observation")}
              label={t("InputObservation")}
            />
            <WrapperFormButtons>
              <ButtonPrimary
                sizex="md"
                type="submit"
                loading={formik.isSubmitting}
                disabled={!documentContext?.file.length}
                onClick={() => {
                  generatePdf();
                }}
                data-cyid="button-save"
              >
                {t("Save")}
              </ButtonPrimary>
            </WrapperFormButtons>
            <>
              {show && (
                <FeedbackModal
                  type="success"
                  show={show}
                  onHide={() => setShow(false)}
                  content={t("PleaseWaitAndYouWillBeAnsweredShortly")}
                  title={t("success")}
                  onClickButton={cleanData}
                />
              )}
            </>
          </>
        )}
      </Form>

      <FeedbackModal
        type="error"
        show={errorShowModal}
        onHide={() => setShowErrorModal(false)}
        content={t("VehicleDidNotReturnData")}
        title={t("error")}
        onClickButton={cleanData}
      />
    </WrapperInputting>
  );
};
