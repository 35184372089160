import React from "react";
import { useTranslation } from "react-i18next";

import { DataLoadBorrowersOrganism } from "../../../organisms";

export const FidelizaDataLoadBasePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("LoadBase")}</h3>
      <hr />
      <DataLoadBorrowersOrganism />
    </>
  );
};
