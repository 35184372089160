import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-zapay.organism.style";

export const AboutZapay = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("Zapay")}</Title>
      <Content>
        <Trans>
          {t("TheZapay")}
          {t("ForUseZapay")}
          <HighlightText>{t("Commissioning")}:</HighlightText>
          <List>
            <ListItem>{t("AllPayments")}</ListItem>
            <ListItem>{t("BesidesSurprisingTransation")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem textColor='#0A205A' italic semiBold>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
