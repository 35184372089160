import React, { useCallback, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { EditOfferModalMolecule, TableList } from "../../..";
import {
  NumberHelper,
  TypesHelper,
  CoxIcon,
  DateHelper,
} from "c4u-web-components";
import {
  EditAdOfferRequest,
  IDetailedAdOffer,
  IEditAdOfferProps,
} from "../../../../../models";
import { StringHelper } from "../../../../../helper";
import { useManhein } from "../../../../../hooks";
import {
  AdOfferActionButtonDiv,
  AdOfferActionButtonsWrapper,
  DealerMessageSpan,
} from "./ad-offers-list.molecule.style";

interface IProps {
  allOffers: IDetailedAdOffer[];
  hasEditPermission?: boolean;
}

export const AdOffersListMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { rejectAdOffer, updateAdOffer } = useManhein();

  const [showEditModal, setShowEditModal] = useState(false);
  const [initialValues, setItinialValues] = useState<IEditAdOfferProps>();

  const refuseOffer = useCallback(
    (id: number) => {
      if (window.confirm(t("ConfirmDeleteOffer"))) {
        rejectAdOffer(id)
          .then(() => window.location.reload())
          .catch((error) => {
            if (error?.message) alert(JSON.stringify(error?.message));
            else alert(JSON.stringify(error));
          });
      }
    },
    [rejectAdOffer, t]
  );

  const onSubmitUpdateAdOffer = useCallback(
    async (values: IEditAdOfferProps) => {
      try {
        await updateAdOffer(new EditAdOfferRequest(values));
        window.location.reload();
      } catch (e: any) {
        console.log(e);
      }
    },
    [updateAdOffer]
  );

  const getActionButtons = useCallback(
    (m: IDetailedAdOffer) =>
      !m.id ? (
        <></>
      ) : (
        <AdOfferActionButtonsWrapper>
          <AdOfferActionButtonDiv
            onClick={
              !props.hasEditPermission ? undefined : () => refuseOffer(m.id)
            }
          >
            <CoxIcon name="trash" width={20} height={20} fillColor="#C4390A" />
          </AdOfferActionButtonDiv>
          <AdOfferActionButtonDiv
            onClick={
              !props.hasEditPermission
                ? undefined
                : () => {
                    const { offerDate, ...other } = m;
                    setItinialValues({
                      offerDate: DateHelper.dateHourFormat(offerDate),
                      ...other,
                    });
                    setShowEditModal(true);
                  }
            }
          >
            <CoxIcon
              name="pencil-edit"
              width={16}
              height={16}
              fillColor="#1F3E74"
            />
          </AdOfferActionButtonDiv>
        </AdOfferActionButtonsWrapper>
      ),
    [refuseOffer, props.hasEditPermission]
  );

  return (
    <>
      <TableList
        thead={[
          "#",
          t("Date"),
          t("ValueR$"),
          t("Cnpj"),
          t("Dealer"),
          t("Name"),
          t("E-mail"),
          t("CellPhone"),
          t("PhoneNumber"),
          t("Actions"),
        ]}
      >
        {props.allOffers?.map((m, i) => (
          <tr key={`tr${i}`}>
            <td>{m.id}</td>
            <td>
              {format(
                TypesHelper.toDateTimeNull(m.offerDate) ?? new Date(1, 1, 1),
                "dd/MM/yyyy HH:mm:ss"
              )}
            </td>
            <td>{NumberHelper.toFormatString(m.value, 2)}</td>
            <td>
              {StringHelper.formatCpfCnpj(m.cnpj)}
              {m.dealershipMessage && (
                <>
                  <br />
                  <DealerMessageSpan>{m.dealershipMessage}</DealerMessageSpan>
                </>
              )}
            </td>
            <td>{m.entity}</td>
            <td>{m.bidder}</td>
            <td>{m.email}</td>
            <td>{StringHelper.formatPhone(m.cellPhone)}</td>
            <td>{StringHelper.formatPhone(m.phoneNumber)}</td>
            <td>{getActionButtons(m)}</td>
          </tr>
        ))}
      </TableList>
      {initialValues && (
        <EditOfferModalMolecule
          showModal={showEditModal}
          initialValues={initialValues}
          onSubmit={onSubmitUpdateAdOffer}
          onCloseModal={() => {
            setShowEditModal(false);
            setItinialValues(undefined);
          }}
        />
      )}
    </>
  );
};
