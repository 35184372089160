import { ButtonSecondary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import {
  IGetReportPicturesResponse,
  IReportPictureModel,
  PostRemoveSelectedRepostPicturesRequest,
  PostSelectedRepostPicturesRequest,
} from "../../../../../models";

import { useTranslation } from "react-i18next";
import {
  Collapse,
  ContentWrapper,
  MainWrapper,
  Wrapper,
} from "./ad-details-autoavaliar-photos.molecule.style.";
import { ButtonArrow } from "../../../../atoms";
import { useManhein } from "../../../../../hooks";
import { AdStampsButtons, FeedbackModal } from "../../..";

interface IPhotoGalery {
  original: string;
  thumbnail: string;
  adInspectionReportLogPictureId: number;
  checked: boolean;
}

interface IProps {
  adPicturesLength: number;
  adId: number;
  adInspectionId: number;
}

export const AdDetailsAutoAvaliarMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { adInspectionId, adPicturesLength, adId } = props;
  const {
    getReportPictures,
    postSendSelectedReportPictures,
    postRemoveSelectedReportPictures,
  } = useManhein();

  const [isOpen, setIsOpen] = useState(true);

  const [photos, setPhotos] = useState<IPhotoGalery[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSubmittingMark, setIsSubmittingMark] = useState(false);
  const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

  const getPictures = useCallback(async () => {
    getReportPictures(adInspectionId)
      .then((pictures) => {
        setPhotos(
          pictures.map((picture: IGetReportPicturesResponse) => {
            return {
              original: `${picture.imageUrl}`,
              thumbnail: `${picture.imageUrl}`,
              adInspectionReportLogPictureId:
                picture.adInspectionReportLogPictureId,
              checked: picture.selected,
            };
          })
        );
      })
      .catch(() => {
        setShowErrorModal(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReportPictures]);

  useEffect(() => {
    if (!!photos.length) {
      photos.map((photo, index) => {
        let picturePosition = adPicturesLength + index;

        let inputElement = document.createElement("input");
        inputElement.type = "checkbox";
        inputElement.id = `${photo.adInspectionReportLogPictureId}`;
        inputElement.className = `check-${index}`;
        inputElement.value = photo.adInspectionReportLogPictureId.toString();
        inputElement.setAttribute(
          "data-picture-position",
          picturePosition.toString()
        );

        let thumbNailPhotos = document.querySelectorAll(
          ".image-gallery-photos .image-gallery-thumbnail"
        );

        return thumbNailPhotos[index].appendChild(inputElement);
      });
    }
  }, [adPicturesLength, photos]);

  useEffect(() => {
    photos.forEach((photo) => {
      if (photo.checked) {
        let checkBoxElement = document.getElementById(
          photo.adInspectionReportLogPictureId.toString()
        ) as HTMLInputElement;
        checkBoxElement.checked = true;
      }
    });
  }, [photos]);

  const sendRemoveSelectedPictures = useCallback(async () => {
    let photosChecked: number[] = [];

    photos.map((_, index) => {
      let checkBoxElement = document.querySelector(
        `.check-${index}`
      ) as HTMLInputElement;

      return (
        checkBoxElement.checked &&
        photosChecked.push(Number(checkBoxElement.id))
      );
    });

    if (window.confirm(t("ConfirmRemoveAdPhotos"))) {
      setIsSubmittingRemove(true);
      postRemoveSelectedReportPictures(
        new PostRemoveSelectedRepostPicturesRequest({
          adInspectionId: adInspectionId,
          AdInspectionReportLogPictureIds: photosChecked,
        })
      )
        .then(() => window.location.reload())
        .catch(() => {
          setShowErrorModal(true);
        })
        .finally(() => {
          setIsSubmittingRemove(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const sendSelectedPictures = useCallback(
    async () => {
      let photosChecked: IReportPictureModel[] = [];

      photos.map((_, index) => {
        let checkBoxElement = document.querySelector(
          `.check-${index}`
        ) as HTMLInputElement;

        return (
          checkBoxElement.checked &&
          photosChecked.push({
            reportLogPictureId: Number(checkBoxElement.value),
            picturePosition: Number(
              checkBoxElement.getAttribute("data-picture-position")
            ),
          })
        );
      });

      if (window.confirm(t("ConfirmAddAdPhotos"))) {
        setIsSubmittingMark(true);
        postSendSelectedReportPictures(
          new PostSelectedRepostPicturesRequest({
            adInspectionId: adInspectionId,
            reportLogPictures: photosChecked,
          })
        )
          .then(() => window.location.reload())
          .catch(() => {
            setShowErrorModal(true);
          })
          .finally(() => {
            setIsSubmittingMark(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [photos]
  );

  useEffect(() => {
    getPictures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeActionAllPhotos = (typeAction: "checked" | "unchecked") => {
    photos.map((_, index) => {
      let checkBoxElement = document.querySelector(
        `.check-${index}`
      ) as HTMLInputElement;
      return (checkBoxElement.checked =
        typeAction === "checked" ? true : false);
    });
  };

  const onPhotoClick = (e: any) => {
    if (e.target.src) window.open(e.target.src, "_blank");
  };

  return (
    <>
      <FeedbackModal
        title={t("error")}
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
        }}
        content={t("Try again error message")}
      />

      <Wrapper>
        <MainWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <h3>{t("Auto Avaliar")}</h3>
          <ButtonArrow collapse isOpen={isOpen} />
        </MainWrapper>
      </Wrapper>

      {!photos.length ? (
        <h4>{t("No information")}</h4>
      ) : (
        <ContentWrapper className="border" isOpen={isOpen}>
          <Collapse in={isOpen}>
            <div>
              <Row>
                <Col className="p-0 image-gallery-photos">
                  <ImageGallery
                    showNav={true}
                    items={photos}
                    lazyLoad={true}
                    showBullets={true}
                    showIndex={true}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    thumbnailPosition={"top"}
                    onClick={onPhotoClick}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="mb-2">
                  <Row className="justify-content-between mt-3">
                    <Col md={6} className="border pb-2 pt-1">
                      <h4 className="border-bottom pb-1">{t("Selection")}</h4>
                      <ButtonSecondary
                        sizex={"md"}
                        className="mr-2"
                        onClick={() => typeActionAllPhotos("checked")}
                      >
                        {t("Select All")}
                      </ButtonSecondary>

                      <ButtonSecondary
                        sizex={"md"}
                        className="ml-2"
                        onClick={() => typeActionAllPhotos("unchecked")}
                      >
                        {t("Deselect All")}
                      </ButtonSecondary>
                    </Col>

                    <Col md={6} className="border pb-2 pt-1">
                      <h4 className="border-bottom pb-1">{t("Sending")}</h4>
                      <ButtonSecondary
                        sizex={"md"}
                        className="mr-2"
                        onClick={() => sendRemoveSelectedPictures()}
                        loading={isSubmittingRemove}
                      >
                        {t("Remove selected")}
                      </ButtonSecondary>

                      <ButtonSecondary
                        sizex={"md"}
                        className="ml-2"
                        onClick={() => sendSelectedPictures()}
                        loading={isSubmittingMark}
                      >
                        {t("Send selected")}
                      </ButtonSecondary>
                    </Col>

                    <Col md={12} className="border pb-2 pt-1 mt-2">
                      <h4 className="border-bottom pb-1">
                        {t("Stamp Photos")}
                      </h4>
                      <Row className="text-center">
                        <AdStampsButtons adId={adId} />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Collapse>
        </ContentWrapper>
      )}
    </>
  );
};
