import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-supervisao.organism.style";

export const AboutSupervisao = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("Supervisao")}</Title>
      <Content>
        <Trans>
          {t("TheSupervisao")}
          {t("Currently")}
          {t("AccessingCoxPraVocêPlatform")}
          {t("MainServices")}
          <List>
            <ListItem>{t("CerticarInspection")}</ListItem>
            <ListItem>{t("PreliminaryInspection")}</ListItem>
            <ListItem>{t("TransferReport")}</ListItem>
          </List>
          <HighlightText>{t("Commissioning")}:</HighlightText>
          <List noSpace>
            <ListItem>{t("SupervisaoEveryInsuranceSale")}</ListItem>
            <ListItem>{t("BesidesSurprisingInspecting")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem textColor='#0A205A' italic semiBold>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
