import { YupHelper } from "c4u-web-components";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IUpdateApiUserRequest } from "../../../../models";

export const ApiUserDetailsValidationSchema = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPasswordValid",
    YupHelper.isPasswordValid
  );

  const msgRequired = t("field_required");
  const msgNoNegativeNumbers = t("field_not_negative");

  const validations = Yup.object<IUpdateApiUserRequest>({
    id: Yup.number().required(),
    name: Yup.string().required(msgRequired),
    cnpj: Yup.string()
      .nullable()
      .required(msgRequired)
      .isCnpjValid(t("cnpj_invalid")),
    email: Yup.string()
      .required(msgRequired)
      .trim()
      .email(t("field_invalid_email")),
    password: Yup.string()
      .when("id", {
        is: (v) => v === 0,
        then: Yup.string().required(msgRequired),
        otherwise: Yup.string().notRequired(),
      })
      .isPasswordValid(
        {
          lowerCase: { msg: t("min_lowcase"), min: 1 },
          upperCase: { msg: t("min_uppercase"), min: 1 },
          numbers: { msg: t("min_numbers"), min: 1 },
          specialChars: { msg: t("min_special_chars"), min: 1 },
          minLength: { msg: t("min_total_chars"), min: 8 },
        },
        t("PasswordShouldHave")
      ),
    active: Yup.string().required(msgRequired),
    canCallPartner: Yup.string().required(msgRequired),
    numberCallsDayDecoder: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers)
      .test({
        name: "decoderCallsDayLimit",
        message: t("DailyLimitExceeded"),
        test: function (value) {
          const monthlyValue = this.parent.numberCallsMonthDecoder;
          return monthlyValue < 0 || value <= parseInt(monthlyValue);
        },
      }),
    numberCallsMonthDecoder: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers),
    numberCallsDayBin: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers)
      .test({
        name: "binCallsDayLimit",
        message: t("DailyLimitExceeded"),
        test: function (value) {
          const monthlyValue = this.parent.numberCallsMonthBin;
          return monthlyValue < 0 || value <= parseInt(monthlyValue);
        },
      }),
    numberCallsMonthBin: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers),
    numberCallsDayAuction: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers)
      .test({
        name: "auctionCallsDayLimit",
        message: t("DailyLimitExceeded"),
        test: function (value) {
          const monthlyValue = this.parent.numberCallsMonthAuction;
          return monthlyValue < 0 || value <= parseInt(monthlyValue);
        },
      }),
    numberCallsMonthAuction: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers),
    numberCallsDayResidualValue: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers)
      .test({
        name: "residualValueCallsDayLimit",
        message: t("DailyLimitExceeded"),
        test: function (value) {
          const monthlyValue = this.parent.numberCallsMonthResidualValue;
          return monthlyValue < 0 || value <= parseInt(monthlyValue);
        },
      }),
    numberCallsMonthResidualValue: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers),
    numberCallsDayDealerScore: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers)
      .test({
        name: "dealerScoreCallsDayLimit",
        message: t("DailyLimitExceeded"),
        test: function (value) {
          const monthlyValue = this.parent.numberCallsMonthDealerScore;
          return monthlyValue < 0 || value <= parseInt(monthlyValue);
        },
      }),
    numberCallsMonthDealerScore: Yup.number()
      .required(msgRequired)
      .min(0, msgNoNegativeNumbers),
    expirationDate: Yup.string()
      .isDatePtBrValid(t("field_invalid_date"))
      .required(msgRequired)
      .test(
        "date-greater-than",
        t("ExpirationMessageCannotBeEarlierThanToday"),
        function (value) {
          const formatDate = value?.split("/");
          return (
            formatDate !== undefined &&
            differenceInDays(
              new Date(`${formatDate[2]}/${formatDate[1]}/${formatDate[0]}`),
              new Date()
            ) >= 0
          );
        }
      ),
  });

  return validations;
};
