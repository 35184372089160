import styled from "styled-components";

const CarEnv = styled.div`
  position: absolute;

  @media (max-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 575.98px) {
    left: calc(-15%);
    top: calc(15%);
  }
  @media screen and (min-width: 991.98px) {
    left: calc(1%);
    top: calc(50% - 607.9px / 1.9);
  }
  @media screen and (min-width: 1199.98px) {
    left: calc(08%);
    top: calc(10%);
  }
  @media screen and (min-width: 1366px) {
    left: calc(9.5%);
    top: calc(50% - 607.9px / 1.9);
  } ;
`;

const CarImg = styled.img`
  position: absolute;
  /* transform: matrix(1, 0.03, -0.02, 1, 0, 0); */

  @media (max-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 575.98px) {
    width: calc(1073.25px / 1.5);
    height: calc(607.9px / 1.5);
    left: calc(-2%);
    top: calc(15%);
  };
  @media screen and (min-width: 991.98px) {
    /* width: calc(1073.25px);
    height: calc(607.9px); */
    left: calc(0%);
    top: calc(65% - 607.9px / 2);
  };
`;

export { CarEnv, CarImg };
