import styled from "styled-components";

const LoadingDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const LoadingText = styled.p`
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

const NoEntriesText = styled.p`
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
`;

export { LoadingDiv, LoadingText, NoEntriesText };