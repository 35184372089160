import { TypesHelper } from "c4u-web-components";
import { CorporateUserTypeEnum, IDealershipComboData } from "../..";

export interface ICorporateUserDetailsResponse {
  id: number;
  name?: string;
  email?: string;
  cpf?: string;
  cellPhone?: string;
  active?: boolean;
  corporateUserType?: CorporateUserTypeEnum;
  corporateDealerships?: IDealershipComboData[];
}

export interface ICreateCorporateUserRequest
  extends Omit<ICorporateUserDetailsResponse, "active"> {
  active: boolean | "1" | "0" | "";
  currentDealershipSelection?: number;
}

export class CreateCorporateUserRequest {
  id: number | null;
  name: string | null;
  email: string | null;
  cpf: string | null;
  cellPhone: string | null;
  active: boolean | null;
  corporateUserType: number | null;
  corporateDealerships: number[];

  constructor({
    id,
    name,
    email,
    cpf,
    cellPhone,
    active,
    corporateUserType,
    corporateDealerships,
  }: ICreateCorporateUserRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.cellPhone = TypesHelper.toStringOnlyNumbersNull(cellPhone);
    this.active = TypesHelper.toBooleanNull(active);
    this.corporateUserType = TypesHelper.toNumberNull(corporateUserType);
    this.corporateDealerships = corporateDealerships?.map((m) => m.id) ?? [];
  }
}
