import React, { useEffect, useState } from "react";
import { IAdVehicleRatedItems, RatingEnum } from "../../../../../models";
import { TableList } from "../../..";
import { useTranslation } from "react-i18next";

interface IProps {
  items: IAdVehicleRatedItems[];
}

export const AdDetailsChecklistMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [adVehicleRatedItems, setAdVehicleRatedItems] = useState(props.items);

  useEffect(() => setAdVehicleRatedItems(props.items), [props.items]);

  const getRatingName = (rating: RatingEnum) => {
    switch (rating) {
      case RatingEnum.Bad:
        return t("Bad");

      case RatingEnum.Good:
        return t("Good");

      case RatingEnum.Excellent:
        return t("Excellent");
    }
  };

  return (
    <TableList thead={[t("Item"), t("Condition"), t("ProblemDescription")]}>
      {adVehicleRatedItems.map((m, i) => (
        <tr key={`adVehicleRatedItems-tr${i}`}>
          <td>{m.partnerName}</td>
          <td>{getRatingName(m.rating)}</td>
          <td>{m.problemDescription}</td>
        </tr>
      ))}
    </TableList>
  );
};
