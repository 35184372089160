import { ButtonPrimary } from "c4u-web-components";
import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { AdBidding, AdModel, IAdBidding } from "../../../../../models";
import * as Yup from "yup";
import { useManhein } from "../../../../../hooks";
import { formikHandleErrorHelper } from "../../../../../helper";
import { FormikControlAtom } from "../../../../atoms";

interface IProps {
  enabled: boolean;
  ad: AdModel;
  setException: React.Dispatch<any>;
}
export const AdEventMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { startBidding } = useManhein();

  const validations = Yup.object<IAdBidding>({
    minimumValue: Yup.number().required().moreThan(0),
    adId: Yup.number().required(),
    durationHours: Yup.number().required().moreThan(0),
    minimumStepValue: Yup.number().required().moreThan(0),
  });

  const formik = useFormik<IAdBidding>({
    initialValues: {} as IAdBidding,
    onSubmit: async (values, { setErrors }) => {
      if (window.confirm(t("ConfirmAction"))) {
        try {
          await startBidding(new AdBidding(values));
          window.location.reload();
        } catch (e: any) {
          if (e?.errors) {
            const errorFormik = formikHandleErrorHelper(e.errors);
            setErrors(errorFormik);
          } else if (e?.message) {
            props.setException(e.message);
          } else {
            props.setException(e);
          }
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setValues({
      adId: props.ad?.id ?? 0,
      minimumStepValue: props.ad?.minimumStepValue,
      durationHours: props.ad?.durationHours,
      minimumValue: props.ad?.minimumValue,
    } as IAdBidding);
    // eslint-disable-next-line
  }, [props]);

  return (
    <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
      <Form.Row className={"mt-5"}>
        <FormikControlAtom
          formik={formik}
          property={"minimumValue"}
          type={"money"}
          decimalScale={0}
          disabled={!props.enabled}
          md={3}
        />
        <FormikControlAtom
          formik={formik}
          property={"minimumStepValue"}
          type={"money"}
          decimalScale={0}
          disabled={!props.enabled}
          md={3}
        />
        <FormikControlAtom
          formik={formik}
          property={"durationHours"}
          type={"money"}
          disabled={!props.enabled}
          decimalScale={0}
          md={3}
          moneyPrefix={""}
        />
        <Form.Group as={Col} md={3} className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
            disabled={!props.enabled}
          >
            {t("Put in event")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
