import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../constants";
import { useC4u, useInsurance } from "../../../../hooks";
import { DateHelper, StringHelper } from "../../../../helper";
import {
  IGetCustomerReportResponse,
  InsuranceCompanyEnum,
  ProposalStepEnum,
} from "../../../../models";
import { Link } from "react-router-dom";
import { InsuranceListTableSearchOrganism } from "./insurance-list-table-search.organism";
import { Combo, NumberHelper } from "c4u-web-components";
import { IGetDealershipsViewModel } from "../../../../hooks/apis/use-c4u/interfaces/interfaces";

export const KbbInsuranceListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getInsuranceReportKbb } = useInsurance();
  const { GetDealerships } = useC4u();

  const [dealerships, setDealerships] = useState<IGetDealershipsViewModel[]>();

  const [dealershipsCombo, setDealershipsCombo] = useState<Combo[]>([
    new Combo({ title: t("Loading") + "...", value: "" }),
  ]);

  useEffect(() => {
    GetDealerships().then((data) => setDealerships(data));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dealerships)
      setDealershipsCombo(
        dealerships.map((m) => new Combo({ title: m.name, value: m.cnpj }))
      );
  }, [dealerships]);

  return (
    <>
      <InsuranceListTableSearchOrganism
        getDataCallback={getInsuranceReportKbb}
        statusOptionsEnum={ProposalStepEnum}
        dealershipOptions={dealershipsCombo}
        insuranceCompanyOptionsEnum={InsuranceCompanyEnum}
        tableHeaders={[
          "#",
          t("Name"),
          t("CpfCnpj"),
          t("Plate"),
          t("InsurancePolicy"),
          `${t("InsurancePremium")} (R$)`,
          t("Dealership"),
          t("DealershipCnpj"),
          t("InsuranceCompany"),
          t("Date"),
          t("LastUpdated"),
          t("Status"),
          t("Bank"),
          "",
        ]}
        mappingFunction={(m: IGetCustomerReportResponse, i) => [
          m.id,
          m.name,
          StringHelper.formatCpfCnpj(m.cpfCnpj),
          // m.vehicle?.name,
          m.vehicle?.plate,
          m.policy,
          NumberHelper.toFormatString(m.premium, 2, "0,00"),
          dealerships?.find((f) => f.cnpj === m.dealership.cnpj)?.name,
          StringHelper.formatCpfCnpj(m.dealership.cnpj),
          t(InsuranceCompanyEnum[m.company]),
          DateHelper.dateHourFormat(m.createdAt),
          DateHelper.dateHourFormat(m.updatedAt),
          // m.origin,
          t(ProposalStepEnum[m.status]),
          m.lenderCode,
          <Link key={`link${i}`} to={paths.backofficeInsuranceDetailPage(m.id)}>
            {t("Details")}
          </Link>,
        ]}
      />
    </>
  );
};
