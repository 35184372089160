import { TypesHelper } from "c4u-web-components";

export interface IPostRemoveSelectedRepostPicturesRequest {
  adInspectionId: number;
  AdInspectionReportLogPictureIds: number[];
}

export class PostRemoveSelectedRepostPicturesRequest {
  adInspectionId: number | null;
  AdInspectionReportLogPictureIds: number[] | null;
  constructor({
    adInspectionId,
    AdInspectionReportLogPictureIds,
  }: IPostRemoveSelectedRepostPicturesRequest) {
    this.adInspectionId = TypesHelper.toNumberNull(adInspectionId);
    this.AdInspectionReportLogPictureIds = AdInspectionReportLogPictureIds
      ? AdInspectionReportLogPictureIds
      : null;
  }
}
