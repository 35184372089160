import { TypesHelper } from "c4u-web-components";
import {
  IDealershipComboData,
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";

export interface IListDealershipsPagedParams
  extends IPagedRequestParamsBaseModel {
  userId?: number;
  search?: string;
}

export class ListDealershipsPagedParams extends PagedRequestParamsBaseModel {
  userId: number | null;
  search: string | null;

  constructor({ userId, search, ...baseParams }: IListDealershipsPagedParams) {
    super(baseParams);
    this.userId = TypesHelper.toNumberNull(userId);
    this.search = TypesHelper.toStringNull(search);
  }
}

export interface IListDealershipsPagedResponse extends IPagedResponseBaseModel {
  data: IDealershipComboData[];
}
