import React from "react";

import { OnboardingsReport } from "../../../organisms";

import { useTranslation } from "react-i18next";

export const OnboardingsReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleOnboardingsReport")}</h3>
      <OnboardingsReport />
    </>
  );
};
