import { TypesHelper } from "c4u-web-components";
import { IAdInspectionScheduleLogModel } from "./ad-inspection-schedule-log.model";

export class AdInspectionScheduleLogRequest {
    adInspectionId: number | null;
    adId: number | null;
    inspectionDate: Date | null;
    inspectionPeriod: number | null;
    justification: string | null;
    constructor({
        adInspectionId,
        adId,
        inspectionDate,
        inspectionPeriod,
        justification
    }: IAdInspectionScheduleLogModel) {
      this.adId = TypesHelper.toNumberNull(adId);
      this.adInspectionId = TypesHelper.toNumberNull(adInspectionId);
      this.inspectionDate = TypesHelper.toDateNull(inspectionDate);
      this.inspectionPeriod = TypesHelper.toNumberNull(inspectionPeriod);
      this.justification = TypesHelper.toStringNull(justification);
    }
  }