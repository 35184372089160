import React from "react";
import Form from "react-bootstrap/Form";

import { LabelDefault } from "c4u-web-components";

import { InputStyled } from "./input.molecule.style";

interface InputProps<T extends string | number | string[] | undefined> {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  autocomplete?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  value?: T;
  isInvalid?: boolean;
  dataCyid?: string;
  errors?: () => JSX.Element | null;
}

function Input<T extends string | number | string[] | undefined>(
  props: InputProps<T>
): JSX.Element {
  return (
    <>
      <LabelDefault lblWeight="regular" lblColor="black">
        {props.label} {props.required ? "*" : null}
      </LabelDefault>
      <InputStyled
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        isInvalid={props.isInvalid}
        autocomplete={props.autocomplete}
        data-cyid={props.dataCyid ?? ""}
      />
      {props.errors && (
        <Form.Control.Feedback type="invalid">
          {props.errors()}
        </Form.Control.Feedback>
      )}
    </>
  );
}

export { Input };
