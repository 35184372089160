import { PasswordLevel } from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikControlAtom } from "../../../atoms";
import { FormResetPasswordValidation } from "./form-reset-password.molecule.validation";
import { FontDefined } from "./form-reset-password.molecule.style";

interface IProps {
  formId: string;
  onSubmit: (props: IFormResetPassword, errors: any) => Promise<void>;
}

export interface IFormResetPassword {
  //actualPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const FormResetPassword: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormResetPassword>({
    initialValues: {} as IFormResetPassword,
    onSubmit: async (values, { setErrors }) => {
      await props.onSubmit(values, setErrors);
      formik.setSubmitting(false);
    },
    validationSchema: FormResetPasswordValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <>
      <Form
        id={props.formId}
        onSubmit={(e: any) => formik.handleSubmit(e)}
        className={"form-default"}
      >
        <Form.Row>
          <Form.Group as={Col} md={4} controlId={"newPassword"}>
            <Form.Label>{t("NewPassword")}</Form.Label>
            <Form.Control
              type={"password"}
              placeholder={t("Tab here")}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors?.newPassword}
            />

            {formik.errors?.newPassword !== t("field_invalid_pwd_weak") && (
              <Form.Control.Feedback type="invalid">
                {formik.errors?.newPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <FormikControlAtom
            formik={formik}
            md={4}
            property={"confirmPassword"}
            type={"password"}
            resetPadding={true}
          />
        </Form.Row>
      </Form>
      <FontDefined>
        <PasswordLevel
          translate={t}
          content={formik?.values?.newPassword ?? ""}
          error={formik?.errors?.newPassword === t("field_invalid_pwd_weak")}
        />
      </FontDefined>
    </>
  );
};
