import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Col } from "react-bootstrap";
import { SearchButtonsDiv } from "./ad-customers-report-search-form.molecule.style";
import { OptionsOrderByAtom } from "../../../atoms";

interface IProps {
  formik: any;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const AdCustomersReportSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <Col xl={10}>
            <Form.Row>
              <FormikControlAtom
                translate={t}
                formik={formik}
                as={"select"}
                md={3}
                property={"pageSize"}
                label={t("RecordsPerPage")}
              >
                <OptionsOrderByAtom
                  options={[
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                    { label: 1000, value: 1000 },
                    { label: 2000, value: 2000 },
                  ]}
                />
              </FormikControlAtom>
            </Form.Row>
          </Col>

          <Col xl={2} className="pt-1 pt-xl-4">
            <SearchButtonsDiv>
              <div>
                <ButtonSecondary
                  sizex="md"
                  disabled={formik.isSubmitting}
                  onClick={props.cleanFiltersCallback}
                >
                  {t("CleanFilters")}
                </ButtonSecondary>
              </div>
              <div>
                <ButtonPrimary
                  type="submit"
                  sizex="md"
                  loading={formik.isSubmitting}
                >
                  {t("Search")}
                </ButtonPrimary>
              </div>
            </SearchButtonsDiv>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
