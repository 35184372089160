import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../..";
import { DateHelper, renderViewHelper } from "../../../../../helper";
import { IVehicleReportDetail } from "../../../../../models";

interface IProps {
  vehicleDetails?: IVehicleReportDetail;
}

export const InsuranceVehicleDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewVehicle = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.vehicleDetails, key, label, func);

  const renderViewInvoice = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.vehicleDetails?.invoice, key, label, func);

  return !props.vehicleDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewVehicle("name", t("Name"))}
        {renderViewVehicle("modelYear", t("ModelYear"))}
        {renderViewVehicle("molicarCode", t("MolicarCode"))}
        {renderViewVehicle("fipeCode", t("FipeCode"))}
        {renderViewVehicle("plate", t("Plate"))}
        {renderViewVehicle("licenseUf", t("LicenseUf"))}
        {renderViewVehicle("color", t("Color"))}
        {renderViewVehicle("vin", t("Vin"))}
        {renderViewVehicle("renavam", t("Renavam"))}
        {renderViewVehicle("isNew", t("IsNew"), (v) => (v ? "Sim" : "Não"))}
      </Form.Row>
      {props.vehicleDetails.isNew && (
        <DetailsPageSubsection title={t("InsuranceVehicleInvoice")}>
          <Form.Row>
            {renderViewInvoice("number", t("InvoiceNumber"))}
            {renderViewInvoice(
              "value",
              t("Value"),
              (v) => `R$ ${NumberHelper.toFormatString(v, 2, "0,00")}`
            )}
            {renderViewInvoice(
              "issueDate",
              t("InvoiceIssueDate"),
              DateHelper.dateHourFormat
            )}
            {renderViewInvoice("dealershipCode", t("InvoiceDealershipCode"))}
            {renderViewInvoice(
              "dealershipLeaveDate",
              t("InvoiceDealershipLeaveDate"),
              DateHelper.dateHourFormat
            )}
          </Form.Row>
        </DetailsPageSubsection>
      )}
    </>
  );
};
