import styled from "styled-components";

const CarEnv = styled.div`
  position: absolute;

  @media (max-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 991.98px) {
    display: none;
  }
  @media screen and (min-width: 1199.98px) {
    display: block;
    left: calc(22%);
    top: calc(5%);
  }
  @media screen and (min-width: 1366px) {
    display: block;
    left: calc(22%);
    top: calc(5%);
  } ;
`;

const CarImg = styled.img`
  position: absolute;
  z-index: 1;
  /* transform: matrix(1, 0.03, -0.02, 1, 0, 0); */

  @media (max-width: 575.97px) {
    display: none;
  }
  @media screen and (min-width: 575.98px) {
    display: none;
  }
  @media screen and (min-width: 991.98px) {
    display: block;
    width: calc(1073.25px / 2.2);
    height: calc(607.9px / 2.2);
    left: calc(-10%);
    top: calc(1%);
  }
  @media screen and (min-width: 1199.98px) {
    display: block;
    /* width: calc(1073.25px / 1.75);
    height: calc(607.9px / 1.75); */
    left: calc(-2.5%);
    top: calc(17%);
  } ;
`;

export { CarEnv, CarImg };
