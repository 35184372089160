import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #f9f9f9;
  position: absolute;
  top: 62px;
  margin-left: 74px;
  padding-right: 10px;
  max-width: calc(100% - 74px);
  min-width: calc(100% - 74px);
  overflow-x: hidden;
  padding-top: 50px;

  @media (max-width: 576px) {
    margin: 0 32px;
    max-width: calc(100% - 64px);
    min-width: calc(100% - 64px);
  }

  .breadcrumb {
    background-color: transparent;
    font-size: 10px;
    line-height: 16px;
  }
`;

export const PageTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;

  display: flex;
  align-items: center;
  text-align: center;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const PartnersSelectionDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 21px;

  flex-wrap: wrap;
`;

export const MyEarningsInitialDescriptionText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.Dark};
`;
