import { TypesHelper } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
  ServiceRobotsEnum,
} from "../..";

export interface IGetRobotsReportParams extends IPagedRequestParamsBaseModel {
  serviceUpdated?: ServiceRobotsEnum | number;
}

export class GetRobotsReportRequest extends PagedRequestParamsBaseModel {
  serviceUpdated: ServiceRobotsEnum | null;
  constructor({ serviceUpdated, ...baseParams }: IGetRobotsReportParams) {
    super(baseParams);
    this.serviceUpdated = TypesHelper.toNumberNotZeroNull(serviceUpdated);
  }
}

export interface IGetRobotsReportResponse {
  serviceUpdated: ServiceRobotsEnum;
  message?: string;
  fileName: string;
  executionDate: string;
  error: boolean;
}

export interface IGetRobotsReportResponsePagedResponseModel
  extends IPagedResponseBaseModel {
  items?: IGetRobotsReportResponse[];
}
