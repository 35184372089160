import { IGetShowHeadlineResponse } from "../../../models";
import { C4uClient, C4uClientConfig, useRequest } from "../config";

export const useBuyerDelivery = () => {
  const { get } = useRequest(C4uClient, "buyerDelivery", C4uClientConfig);

  const GetShowHeadline = async (): Promise<IGetShowHeadlineResponse> => {
    const { data } = await get(`getShowHeadline`);
    return data;
  };

  return {
    GetShowHeadline,
  };
};
