import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { LoginHelper } from "../../../helper";
import { PermissionEnum } from "c4u-web-components";
import { BackofficeBaseTemplate } from "./backoffice-base.template";

interface IProps {
  protected?: PermissionEnum | PermissionEnum[];
}

export const BackofficeManheimTemplate: React.FC<IProps> = (props) => {
  const { isLoading, isAuthenticated, loginWithPopup } = useAuth0();

  useEffect(() => {
    LoginHelper.setCookieLogin();
    if (!isLoading && !isAuthenticated) {
      loginWithPopup({
        connection: process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION ?? "",
        domain: process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE ?? "",
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  return (
    <BackofficeBaseTemplate
      isAuthenticated={isAuthenticated}
      isLoading={isLoading}
      permission={props.protected}
    >
      {props.children}
    </BackofficeBaseTemplate>
  );
};
