import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IGetDataLoadBorrowersHistoryResponse } from "../../../../../models";
import { PaginatableTableList } from "../../../../molecules";

interface IProps {
  getDataCallback: (values: any) => any;
  tableName: string;
  tableHeaders: string[];
  mappingFunction: (m: any, i: number) => any[];
  setFinishReload: React.Dispatch<React.SetStateAction<boolean>>;
  reloadList: boolean;
}

export const DataLoadResidualValuesTableOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [listData, setListData] =
    useState<IGetDataLoadBorrowersHistoryResponse[]>();
  const [totalTablePages, setTotalTablePages] = useState<number>(1);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const getCallbackBorrowersList = useCallback(async () => {
    try {
      setDataIsLoading(true);
      setListData(undefined);
      const { data, currentPage, totalPages } = await props.getDataCallback({
        pageSize: 50,
        page: 1,
      });

      setListData(data);
      setTotalTablePages(totalPages);
      setCurrentTablePage(currentPage);
      setDataIsLoading(false);
    } catch (e: any) {
      console.log(e);

      setErrorMessage(t("GenericApiError"));
    }
  }, [props, t]);

  useEffect(() => {
    getCallbackBorrowersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewPage = async (targetPage: number) => {
    const { data, currentPage, totalPages } = await props.getDataCallback({
      page: targetPage,
      pageSize: 50,
    });
    setListData(data);
    setTotalTablePages(totalPages);
    setCurrentTablePage(currentPage);
  };

  useEffect(() => {
    if (props.reloadList) {
      getCallbackBorrowersList();
      props.setFinishReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadList]);

  return (
    <>
      <PaginatableTableList
        tableName={props.tableName}
        pagination={{
          currentPage: currentTablePage,
          lastPage: totalTablePages,
          getPageCallback: getNewPage,
        }}
        dataIsLoading={dataIsLoading && !errorMessage}
        thead={props.tableHeaders}
        tbody={listData?.map(props.mappingFunction)}
      />
    </>
  );
};
