import React from "react";
import { useTranslation } from "react-i18next";
import { KbbTrackerListOrganism } from "../../../../organisms";

export const KbbTrackerListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("TitleTrackerListPage")}</h3>
      <KbbTrackerListOrganism />
    </>
  );
};
