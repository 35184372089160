import { TypesHelper } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  IPagedResponseBaseModel,
  PagedRequestParamsBaseModel,
} from "../..";

export interface IGetApiUserRequestParams extends IPagedRequestParamsBaseModel {
  search?: string;
  active?: string;
}

export class GetApiUserRequest extends PagedRequestParamsBaseModel {
  search: string | null;
  active: boolean | null;

  constructor({ search, active, ...baseParams }: IGetApiUserRequestParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.active = TypesHelper.toBooleanNull(active, [""]);
  }
}

export interface IGetApiUserResponse {
  id: number;
  name: string;
  cnpj: string;
  email: string;
  active: string;
  canCallPartner: string;
  numberCallsDayDecoder?: number;
  numberCallsMonthDecoder?: number;
  numberCallsDayBin?: number;
  numberCallsMonthBin?: number;
  numberCallsDayAuction?: number;
  numberCallsMonthAuction?: number;
  numberCallsDayResidualValue?: number;
  numberCallsMonthResidualValue?: number;
  numberCallsDayDealerScore?: number;
  numberCallsMonthDealerScore?: number;
  expirationDate: string;
  createdAt: string;
  minimumData?: boolean;
  vehicleComposition?: boolean;
  complementaryData?: boolean;
  vehicleImportData?: boolean;
  vehicleAdress?: boolean;
  detranVehicleStatus?: boolean;
}

export interface IGetApiUserResponsePagedResponseModel
  extends IPagedResponseBaseModel {
  data?: IGetApiUserResponse[];
}

export interface IUpdateApiUserRequest
  extends Omit<IGetApiUserResponse, "createdAt"> {
  password: string;
}

export interface ICreateApiUserRequest
  extends Omit<IUpdateApiUserRequest, "id"> {}

export class CreateApiUserRequest {
  name: string | null;
  cnpj: string | null;
  email: string | null;
  password: string | null;
  active: boolean | null;
  canCallPartner: boolean | null;
  numberCallsDayDecoder: number | null;
  numberCallsMonthDecoder: number | null;
  numberCallsDayBin: number | null;
  numberCallsMonthBin: number | null;
  numberCallsDayAuction: number | null;
  numberCallsMonthAuction: number | null;
  numberCallsDayResidualValue: number | null;
  numberCallsMonthResidualValue: number | null;
  numberCallsDayDealerScore: number | null;
  numberCallsMonthDealerScore: number | null;
  expirationDate: Date | null;
  minimumData: boolean | null;
  vehicleComposition: boolean | null;
  complementaryData: boolean | null;
  vehicleImportData: boolean | null;
  vehicleAdress: boolean | null;
  detranVehicleStatus: boolean | null;
  constructor({
    name,
    cnpj,
    email,
    password,
    active,
    canCallPartner,
    numberCallsDayDecoder,
    numberCallsMonthDecoder,
    numberCallsDayBin,
    numberCallsMonthBin,
    numberCallsDayAuction,
    numberCallsMonthAuction,
    numberCallsDayResidualValue,
    numberCallsMonthResidualValue,
    numberCallsDayDealerScore,
    numberCallsMonthDealerScore,
    expirationDate,
    minimumData,
    vehicleComposition,
    complementaryData,
    vehicleImportData,
    vehicleAdress,
    detranVehicleStatus,
  }: ICreateApiUserRequest) {
    this.name = TypesHelper.toStringNull(name);
    this.cnpj = TypesHelper.toStringOnlyNumbersNull(cnpj);
    this.email = TypesHelper.toStringNull(email);
    this.password = TypesHelper.toStringNull(password);
    this.active = TypesHelper.toBooleanNull(active);
    this.canCallPartner = TypesHelper.toBooleanNull(canCallPartner);
    this.numberCallsDayDecoder = TypesHelper.toNumberNull(
      numberCallsDayDecoder
    );
    this.numberCallsMonthDecoder = TypesHelper.toNumberNull(
      numberCallsMonthDecoder
    );
    this.numberCallsDayBin = TypesHelper.toNumberNull(numberCallsDayBin);
    this.numberCallsMonthBin = TypesHelper.toNumberNull(numberCallsMonthBin);
    this.numberCallsDayAuction = TypesHelper.toNumberNull(
      numberCallsDayAuction
    );
    this.numberCallsMonthAuction = TypesHelper.toNumberNull(
      numberCallsMonthAuction
    );
    this.numberCallsDayResidualValue = TypesHelper.toNumberNull(
      numberCallsDayResidualValue
    );
    this.numberCallsMonthResidualValue = TypesHelper.toNumberNull(
      numberCallsMonthResidualValue
    );
    this.numberCallsDayDealerScore = TypesHelper.toNumberNull(
      numberCallsDayDealerScore
    );
    this.numberCallsMonthDealerScore = TypesHelper.toNumberNull(
      numberCallsMonthDealerScore
    );
    this.expirationDate = TypesHelper.toDateNull(expirationDate);
    this.minimumData = TypesHelper.toBooleanNull(minimumData);
    this.vehicleComposition = TypesHelper.toBooleanNull(vehicleComposition);
    this.complementaryData = TypesHelper.toBooleanNull(complementaryData);
    this.vehicleImportData = TypesHelper.toBooleanNull(vehicleImportData);
    this.vehicleAdress = TypesHelper.toBooleanNull(vehicleAdress);
    this.detranVehicleStatus = TypesHelper.toBooleanNull(detranVehicleStatus);
  }
}

export class UpdateApiUserRequest extends CreateApiUserRequest {
  id: number | null;
  constructor({ id, ...rest }: IUpdateApiUserRequest) {
    super(rest);
    this.id = TypesHelper.toNumberNull(id);
  }
}
