import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { YupHelper } from "c4u-web-components";
import { IFormPersonalDataMolecule } from "./form-personal-data.molecule";

export const FormPersonalDataMoleculeValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  const msgRequired = t("field_required");

  return Yup.object<IFormPersonalDataMolecule>({
    name: Yup.string().nullable().required(msgRequired),
    email: Yup.string().nullable().required(msgRequired).email(t("field_invalid_email")),
    cpf: Yup.string().nullable().required(msgRequired).isCpfCnpjValid(t("cpf_invalid")),
    cellPhone: Yup.string().nullable().required(msgRequired),
  });
};
