import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: #f0f0f0;
  position: absolute;
  top: 62px;
  margin-left: 74px;
  padding-right: 10px;
  max-width: calc(100% - 74px);
  min-width: calc(100% - 74px);
  overflow-x: hidden;
  padding-top: 50px;
  img {
    width: 100%;
  }
`;

export const BoxTitle = styled.div`
  background: #01a89d;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fffdfd;
  text-transform: uppercase;
  height: 54px;
`;

interface IProps {
  heigthAuto: boolean;
}
export const BoxWhiteShadow = styled.div<IProps>`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-size: 16px;
  line-height: 30px;
  color: #4a4a4a;
  height: ${(p) => (p.heigthAuto ? "auto" : "448px")};

  /* or 150% */
  .description {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 30px 150px;
  }

  .cardInfo {
    padding: 30px 17px 30px 30px;
  }

  ul {
    list-style: initial;
    padding: 7px 0px 0px 20px;
  }
  li {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-size: 15px;
    line-height: 30px;
  }

  h6 {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-weight: bold;
    color: #02384f !important;
    font-size: 20px;
    line-height: 30px;
  }

  h5 {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-weight: bold;
    color: #02384f !important;
    line-height: 30px;
    font-size: 18px;
  }
`;
