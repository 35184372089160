import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import {
  useFormik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FieldMetaProps,
} from "formik";

import Form from "react-bootstrap/Form";
import {
  ButtonPrimary,
  ButtonLink,
  CoxIcon,
  InputIcon,
  LabelDefault,
} from "c4u-web-components";

import {
  CenterAlignedContent,
  LinkBack,
} from "./create-user-form.organism.style";
import {
  CreateUserForm,
  CreateUserFormValidationSchema,
} from "./create-user-form.organism.validations";
import { GeneralError } from "../../../atoms/";

import { useC4u } from "../../../../hooks/";
import { IUser } from "../../../../hooks/apis/use-c4u/interfaces/interfaces";
import { Col, Row } from "react-bootstrap";

const CreateUserFormRender = (
  formikBag: FormikProps<CreateUserForm>,
  isDt: boolean
) => {
  const {
    isSubmitting,
    getFieldMeta,
    setErrors,
    errors,
  }: {
    isSubmitting: boolean;
    getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
    setErrors: (errors: FormikErrors<any>) => void;
    errors: any;
  } = formikBag;
  const { t } = useTranslation();
  const { isAuthenticated, loginWithPopup, logout } = useAuth0();

  const resetFlow = () => {
    const returnUrl =
      process.env.REACT_APP_MASTER_PAGE ?? window.location.origin;
    if (isAuthenticated) logout({ returnTo: returnUrl });
    else window.location.reload();
  };

  const isDealertrackUserChallenge = () => {
    loginWithPopup({
      connection: process.env.REACT_APP_AUTH0_C4U_DT_CONN,
    });
  };

  if (isDt && !isAuthenticated)
    return (
      <CenterAlignedContent>
        <CoxIcon
          name="warning-color"
          fillColor="#FDBA4B"
          width={37}
          height={37}
        />
        <br />
        <LabelDefault lblWeight="title" lblColor="blue">
          {t("Registration.IsDealertrackUser.Info")}
        </LabelDefault>
        <br />
        <ButtonPrimary sizex="lg" onClick={isDealertrackUserChallenge}>
          {t("Access With Dealertrack")}
        </ButtonPrimary>
        <ButtonLink sizex="md" onClick={resetFlow}>
          {t("Return")}
        </ButtonLink>
      </CenterAlignedContent>
    );

  if (isDt && isAuthenticated)
    return (
      <CenterAlignedContent>
        <LabelDefault lblWeight="title" lblColor="blue">
          {t("Loading")}
        </LabelDefault>
        <GeneralError
          errorProp={getFieldMeta("general")}
          errorCode={getFieldMeta("errorCode")}
          setErrors={setErrors}
        />
        <ButtonLink sizex="md" onClick={resetFlow}>
          {t("Return")}
        </ButtonLink>
      </CenterAlignedContent>
    );

  return (
    <Form
      noValidate
      onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
        formikBag.handleSubmit(e)
      }
    >
      <Form.Group controlId="formName">
        <InputIcon
          name="name"
          type="text"
          iconName="user"
          onChange={formikBag.handleChange}
          placeholder={t("Full Name")}
          value={formikBag.values?.name}
          data-cyid={"formName"}
        />
        <span className="text-danger">
          {errors && errors.name && errors.name.key && t(errors.name.key)}
          {errors && errors.name && errors.name.text}
        </span>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <InputIcon
          name="email"
          type="text"
          iconName="user"
          placeholder={t("Email")}
          onChange={(e) => {
            const value = e.target.value || "";
            formikBag.setFieldValue("email", value.toLowerCase());
          }}
          value={formikBag.values?.email}
          data-cyid={"formEmail"}
        />
        <span className="text-danger">
          {errors && errors.email && errors.email.key && t(errors.email.key)}
          {errors && errors.email && errors.email.text}
        </span>
      </Form.Group>

      <GeneralError
        errorProp={getFieldMeta("general")}
        errorCode={getFieldMeta("errorCode")}
        setErrors={setErrors}
      />

      <div className="container-buttons-mobile">
        <div className="content-buttons">
          <Row>
            <Col className={"text-center"}>
              <ButtonPrimary type="submit" sizex="md" disabled={isSubmitting}>
                {t("Next")}
              </ButtonPrimary>
            </Col>
          </Row>
          <Row>
            <Col className={"button-return text-center m-2"}>
              <LinkBack to={"/"}>{t("Return")}</LinkBack>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};

const CreateUserFormOnSubmit = (
  values: CreateUserForm,
  actions: FormikHelpers<CreateUserForm>,
  onSubmit: (user: IUser, actions: FormikHelpers<CreateUserForm>) => void
) => {
  actions.setSubmitting(true);
  onSubmit(values, actions);
};

const CreateUserFormFields: React.FC = () => {
  const history = useHistory();
  const { CreateUser, FeedUserFromDealertrack } = useC4u();
  const { isAuthenticated, user } = useAuth0();

  const [isDt, setIsDt] = useState(false);
  const [createdUser, setCreatedUser] = useState({} as IUser);

  const navigateToCreateUsers = (usr: IUser) => {
    history.push(`/${usr.publicId}/dealership/create`);
  };

  const validateIsDealertrackUser = (usr: IUser) => {
    console.log("dealertrack user found.", usr);
    setIsDt(true);
    setCreatedUser(usr);
  };

  const feedUserDataFromDealertrack = async (email: string) => {
    console.log("feeding data from DT...");
    const [src, idp, userId] = `${user?.sub}`.split("|", 3);
    if (src === "oidc" && idp.includes("ServiceSentry")) {
      try {
        const data = await FeedUserFromDealertrack(userId, email);
        if (data.Result) navigateToCreateUsers(createdUser);
      } catch (e: any) {
        console.error("something wrong on feed from dealertrack process...");
        throw e;
      }
    } else {
      console.log("oh... you are authenticated already, please logout first!");
      throw new Error("Usuário já logado, saindo...");
    }
  };

  const initialValues: CreateUserForm = { name: "", email: "" };

  const createUser = (
    values: IUser,
    actions: FormikHelpers<CreateUserForm>
  ) => {
    CreateUser({
      name: values.name.trim(),
      email: values.email.trim(),
    })
      .then((newUser: IUser) => {
        if (newUser.isDt) {
          validateIsDealertrackUser(newUser);
        } else {
          navigateToCreateUsers(newUser);
        }
      })
      .catch((err: any) => {
        console.log("catch ", err);

        if (err.errors) {
          console.error(err.errors);
          actions.setErrors(err.errors);
        }

        if (err.message) {
          actions.setFieldError("general", err.message);
        }

        if (err.generalError) {
          actions.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik<CreateUserForm>({
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      CreateUserFormOnSubmit(values, actions, createUser);
    },
    validationSchema: CreateUserFormValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    console.log("[create-user-form] evaluating effect");
    console.log(JSON.stringify(createdUser));
    if (isAuthenticated && createdUser.id) {
      console.log("user authenticated ... now trying to feed data!");
      feedUserDataFromDealertrack(createdUser.email)
        .then((res) => {
          navigateToCreateUsers(createdUser);
        })
        .catch((err) => {
          console.log(err);

          if (err.message) {
            formik.setFieldError("general", err.message);
            formik.setFieldError("errorCode", err.code);
          }

          if (err.generalError) {
            formik.setFieldError("general", err.generalError);
          }
        });
    } else {
      console.log("user not logged in :( (yet)");
    }
    // eslint-disable-next-line
  }, [createdUser, isAuthenticated]);

  return CreateUserFormRender(formik, isDt);
};

export { CreateUserFormFields };
