import React from "react";
import { MyEarningsTextSectionMolecule } from "../../molecules";

import { MyEarningsOrganismWrapper } from "./my-earnings.organism.style";

export const MyEarningsSupervisaoOrganism: React.FC = () => {
  return (
    <MyEarningsOrganismWrapper>
      <MyEarningsTextSectionMolecule
        title="Supervisão"
        titleBackgroundColor="#3A5693"
        text="Os produtos da Supervisão não possuem comissão ao lojista porque os preços ofertados na plataforma CoxPraVocê são os menores preços praticados no mercado. O baixo preço dos produtos é uma inciativa desta parceria para incentivar a contratação de inspeções e laudos veiculares, uma vez que este produto traz segurança, transparência e proteção para as negociações e transações entre vendedores e compradores. Use à vontade!"
      />
    </MyEarningsOrganismWrapper>
  );
};
