import styled from "styled-components";

const WrapperUsers = styled.div`
  .spaces {
    margin-bottom: 44px;
  }
`;
const WrapperBackground = styled.div`
  position: relative;
  background: rgba(168, 170, 172, 0.15);
  border-radius: 4px;
  padding: 20px 50px;
`;
const IconTrash = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export { WrapperUsers, WrapperBackground, IconTrash };
