import { Combo } from "c4u-web-components";

export class StatesConstant {
  static readonly STATES = [
    { title: "Acre", value: "AC" } as Combo,
    { title: "Alagoas", value: "AL" } as Combo,
    { title: "Amapá", value: "AP" } as Combo,
    { title: "Amazonas", value: "AM" } as Combo,
    { title: "Bahia", value: "BA" } as Combo,
    { title: "Ceará", value: "CE" } as Combo,
    { title: "Distrito Federal", value: "DF" } as Combo,
    { title: "Espírito Santo", value: "ES" } as Combo,
    { title: "Goiás", value: "GO" } as Combo,
    { title: "Maranhão", value: "MA" } as Combo,
    { title: "Mato Grosso", value: "MT" } as Combo,
    { title: "Mato Grosso do Sul", value: "MS" } as Combo,
    { title: "Minas Gerais", value: "MG" } as Combo,
    { title: "Pará", value: "PA" } as Combo,
    { title: "Paraíba", value: "PB" } as Combo,
    { title: "Paraná", value: "PR" } as Combo,
    { title: "Pernambuco", value: "PE" } as Combo,
    { title: "Piauí", value: "PI" } as Combo,
    { title: "Rio de Janeiro", value: "RJ" } as Combo,
    { title: "Rio Grande do Norte", value: "RN" } as Combo,
    { title: "Rio Grande do Sul", value: "RS" } as Combo,
    { title: "Rondônia", value: "RO" } as Combo,
    { title: "Roraima", value: "RR" } as Combo,
    { title: "Santa Catarina", value: "SC" } as Combo,
    { title: "São Paulo", value: "SP" } as Combo,
    { title: "Sergipe", value: "SE" } as Combo,
    { title: "Tocantins", value: "TO" } as Combo,
  ];
}
