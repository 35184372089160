import React, { useEffect } from "react";

import { ContentWrapper } from "./promotional-materials.page.style";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  MaterialsNotebookPP_147_196,
  MaterialsKbbCap_137_83,
  MaterialsCardCell_157_209,
  MaterialsWhiteHardcoverNotebook_127_196,
  MaterialsMetalPen_147_195,
  MaterialsPremiumPen_150_200,
} from "../../assets";

import { CardPartnerMolecule } from "../../molecules";
import { useSessionContext } from "../../../hooks";

export const PromotionalMaterialsPage = () => {
  const { t } = useTranslation();
  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("NotActive");
    // eslint-disable-next-line
  }, []);

  const items = [
    { image: MaterialsKbbCap_137_83, title: "Boné KBB" },
    { image: MaterialsNotebookPP_147_196, title: "Bloco de notas PP" },
    { image: MaterialsCardCell_157_209, title: "Porta cartão para celular" },
    {
      image: MaterialsWhiteHardcoverNotebook_127_196,
      title: "Bloco de notas capa dura branca P",
    },
    { image: MaterialsMetalPen_147_195, title: "Caneta metal fosca" },
    { image: MaterialsPremiumPen_150_200, title: "Caneta metal premium" },
  ];

  return (
    <>
      <ContentWrapper>
        <Container className={"mb-4"}>
          <Row>
            <Col>
              <h4>{t("PromotionalMaterialsTitle")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>{t("PromotionalMaterialsSubTitle")}</h6>
            </Col>
          </Row>

          <Row className={"pt-4"}>
            {items.map((m, i) => (
              <Col sm={4} key={i}>
                <CardPartnerMolecule
                  img={m.image}
                  imgTitle={m.title}
                  labelBox={t("NUMBER_units", { NUMBER: 100 })}
                  labelBottomBox={t("Request")}
                  tooltipComingSoon={t("ComingSoon")}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </ContentWrapper>
    </>
  );
};
