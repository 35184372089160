import { HttpHelper } from "c4u-web-components";
import {
  GetLogReportParams,
  IGetAllDebtsQueryParams,
  IGetAllInspectionsQueryParams,
  IGetInspectionReportLinks,
  IGetTrackerDetailResponse,
  IGetTrackerListPagedResponseModel,
  IGetTrackerListParams,
  IGetTrackerQuotesErrorsLogResponse,
  IGetTrackerQuotesLogResponse,
  IGetTrackerStepsLogResponse,
  IInspectionViewModel,
  IIspectionViewModelPagedResponseModel,
  IPagedResponseGenericModel,
  IVehicleDebtViewModel,
  IVehicleDebtViewModelPagedResponseModel,
  UpdateVehicleDebtRequest
} from "../../../models";
import { C4uClientConfig, ProductsHttp, useRequest } from "../config";

export const useProducts = () => {
  const { get, post, put } = useRequest(
    ProductsHttp,
    "Backoffice",
    C4uClientConfig
  );

  const getAllInspectionsC4u = async (
    params: IGetAllInspectionsQueryParams
  ): Promise<IIspectionViewModelPagedResponseModel> => {
    const c4uInspectionParams = {
      ...params,
      passwordless: false,
    };
    const { data } = await get(
      `GetAllInspections${HttpHelper.BuildQueryString(c4uInspectionParams)}`
    );
    return data;
  };

  const getAllInspectionsKbb = async (
    params: IGetAllInspectionsQueryParams
  ): Promise<IIspectionViewModelPagedResponseModel> => {
    const kbbInspectionParams = {
      ...params,
      passwordless: true,
    };
    const { data } = await get(
      `GetAllInspections${HttpHelper.BuildQueryString(kbbInspectionParams)}`
    );
    return data;
  };

  const getAllDebtsC4u = async (
    params: IGetAllDebtsQueryParams
  ): Promise<IVehicleDebtViewModelPagedResponseModel> => {
    const c4uDebtsParams = {
      ...params,
      passwordless: false,
    };
    const { data } = await get(
      `GetAllDebts${HttpHelper.BuildQueryString(c4uDebtsParams)}`
    );
    return data;
  };

  const getAllDebtsKbb = async (
    params: IGetAllDebtsQueryParams
  ): Promise<IVehicleDebtViewModelPagedResponseModel> => {
    const kbbDebtsParams = {
      ...params,
      passwordless: true,
    };
    const { data } = await get(
      `GetAllDebts${HttpHelper.BuildQueryString(kbbDebtsParams)}`
    );
    return data;
  };

  const getInspectionDetails = async (
    id: string
  ): Promise<IInspectionViewModel> => {
    const { data } = await get(`GetInspectionDetails/${id}`);
    return data;
  };

  const getDebtsDetails = async (
    id: string
  ): Promise<IVehicleDebtViewModel> => {
    const { data } = await get(`GetDebtsDetailsViewModel/${id}`);
    return data;
  };

  const getTrackerList = async (
    params: IGetTrackerListParams
  ): Promise<IGetTrackerListPagedResponseModel> => {
    const { data } = await get(
      `GetTracker${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const getTrackerListKbb = async (
    params: IGetTrackerListParams
  ): Promise<IGetTrackerListPagedResponseModel> => {
    const kbbTrackerParams = {
      ...params,
      passwordless: true,
    };
    const { data } = await get(
      `GetTracker${HttpHelper.BuildQueryString(kbbTrackerParams)}`
    );
    return data;
  };

  const getTrackerDetail = async (
    id: string
  ): Promise<IGetTrackerDetailResponse> => {
    const { data } = await get(`GetTrackerDetail/${id}`);
    return data;
  };

  const getInspectionReportLinks = async (
    id: string
  ): Promise<IGetInspectionReportLinks> => {
    const { data } = await get(`downloadInspectionReport/${id}`);
    return data;
  };

  const putVehicleDebtStatus = async (
    request: UpdateVehicleDebtRequest
  ): Promise<void> => {
    await put("updateVehicleDebt", request);
  };

  const getTrackerQuotesLogs = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetTrackerQuotesLogResponse>
    >(`trackerQuotesLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const getTrackerQuotesErrorsLog = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetTrackerQuotesErrorsLogResponse>
    >(`trackerQuotesErrorsLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const getTrackerStepsLog = async (request: GetLogReportParams) => {
    const { data } = await get<
      IPagedResponseGenericModel<IGetTrackerStepsLogResponse>
    >(`trackerStepsLog${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const downloadTrackerStepsLog = async (request: GetLogReportParams) => {
    const response = await post<BlobPart>("downloadTrackerStepsLog", request);
    return response;
  };

  const downloadTrackerQuotesLog = async (request: GetLogReportParams) => {
    const response = await post<BlobPart>("downloadTrackerQuotesLog", request);
    return response;
  };

  const downloadTrackerQuotesErrorsLog = async (
    request: GetLogReportParams
  ) => {
    const response = await post<BlobPart>(
      "downloadTrackerQuotesErrorsLog",
      request
    );
    return response;
  };

  return {
    getAllInspectionsC4u,
    getAllInspectionsKbb,
    getAllDebtsC4u,
    getAllDebtsKbb,
    getDebtsDetails,
    getInspectionDetails,
    getTrackerDetail,
    getTrackerList,
    getTrackerListKbb,
    getInspectionReportLinks,
    putVehicleDebtStatus,
    getTrackerQuotesLogs,
    getTrackerQuotesErrorsLog,
    getTrackerStepsLog,
    downloadTrackerStepsLog,
    downloadTrackerQuotesLog,
    downloadTrackerQuotesErrorsLog,
  };
};
