import styled from "styled-components";

export const ContentText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  
  text-align: center;
  
  color: #4a4a4a;
`;
