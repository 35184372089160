import { ButtonPrimary, ButtonSecondary } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StdEnum } from "../../../../models";
import { ComboOptionsFromEnumAtom, FormikControlAtom } from "../../../atoms";

interface IProps {
  formik: any;
  UserActive: StdEnum;
  errorMessage?: string;
  cleanFiltersCallback?: () => void;
}

export const ApiUserListSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik;

  return (
    <div className="mb-3">
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            md={6}
            property={"search"}
            label={t("TextSearch")}
          />

          <FormikControlAtom
            formik={formik}
            as={"select"}
            md={3}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>

          <Col md={3} className="pt-4 text-center">
            <ButtonSecondary
              sizex="md"
              disabled={formik.isSubmitting}
              onClick={props.cleanFiltersCallback}
            >
              {t("CleanFilters")}
            </ButtonSecondary>
          </Col>
        </Form.Row>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            as={"select"}
            md={3}
            property={"active"}
            label={t("UserStatus")}
          >
            <ComboOptionsFromEnumAtom
              propertyKey="active"
              optionsEnum={props.UserActive}
            />
          </FormikControlAtom>

          <Col md={3}></Col>
          <Col md={{ span: 3, offset: 3 }} className="pt-4 text-center">
            <Form.Row>
              <ButtonPrimary
                type="submit"
                sizex="md"
                loading={formik.isSubmitting}
              >
                {t("Search")}
              </ButtonPrimary>
            </Form.Row>
          </Col>
        </Form.Row>
      </Form>
      {props.errorMessage && <div>{props.errorMessage}</div>}
    </div>
  );
};
