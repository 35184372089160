import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { IFormResetPassword } from "./form-reset-password.molecule";

export const FormResetPasswordValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t("field_required");

  return Yup.object<IFormResetPassword>({
    //actualPassword: Yup.string().required(msgRequired),
    newPassword: Yup.string()
      .nullable()
      .required(msgRequired)
      .test("samePassword", t("passwords_must_match"), function (value) {
        const confirmPass = this.resolve(Yup.ref("confirmPassword"));
        return value === confirmPass;
      })
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#,.]{8,}$/,
        t("field_invalid_pwd_weak")
      ),
    confirmPassword: Yup.string().nullable().required(msgRequired),
  });
};
