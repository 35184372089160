import React from "react";
import { useTranslation } from "react-i18next";
import { DtMockSyncOrganism } from "../../../../organisms/backoffice/dt-mock/dt-mock-sync.organism";

export const SyncFormPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("SyncDT")}</h3>
     <DtMockSyncOrganism />
    </>
  );
};
