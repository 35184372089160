import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection } from "../../..";
import { DateHelper, renderViewHelper, StringHelper } from "../../../../../helper";
import { IInsuranceProposalDetails } from "../../../../../models";

interface IProps {
  proposalDetails?: IInsuranceProposalDetails;
}

export const InsuranceProposalDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewDealership = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.proposalDetails, key, label, func);

  return !props.proposalDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewDealership("dealershipName", t("Dealership"))}
        {renderViewDealership(
          "dealershipCnpj",
          t("DealershipCnpj"),
          StringHelper.formatCpfCnpj
        )}
        {renderViewDealership(
          "createdAt",
          t("CreatedAt"),
          DateHelper.dateHourFormat
        )}
      </Form.Row>
      <DetailsPageSubsection title={t("InsuranceSellerData")}>
        <Form.Row>
          {renderViewDealership("name", t("Name"))}
          {renderViewDealership("email", t("Email"))}
          {renderViewDealership(
            "cellphone",
            t("PhoneNumber"),
            StringHelper.formatPhone
          )}
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
