import styled from "styled-components";

const HorizontalScrollDiv = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const FeedbackModalContent = styled.div`
  max-width: 450px;
  overflow-x: auto;

  margin: 35px 0 !important;

  display 
`;

export { HorizontalScrollDiv, FeedbackModalContent };
