import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DetailsPageSubsection, PaginatableTableList } from "../../..";
import { renderViewHelper } from "../../../../../helper";
import { IInsuranceInfoDetails } from "../../../../../models";

interface IProps {
  infoDetails?: IInsuranceInfoDetails;
}

export const InsuranceInfoDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewInsuranceInfo = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.infoDetails, key, label, func);

  const formatStringMoney = (v: number) =>
    `R$ ${NumberHelper.toFormatString(v, 2, "0,00")}`;

  return !props.infoDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewInsuranceInfo("company", t("InsuranceCompany"))}
        {renderViewInsuranceInfo("policy", t("InsurancePolicy"))}
        {renderViewInsuranceInfo(
          "premium",
          t("InsurancePremium"),
          formatStringMoney
        )}
        {renderViewInsuranceInfo("numberPlots", t("InsuranceNumberPlots"))}
        {renderViewInsuranceInfo(
          "firstPlotPrice",
          t("InsuranceFirstPlot"),
          formatStringMoney
        )}
        {renderViewInsuranceInfo(
          "othersPlotsPrice",
          t("InsuranceOtherPlots"),
          formatStringMoney
        )}
        {renderViewInsuranceInfo(
          "interestPrice",
          t("InsuranceInterest"),
          formatStringMoney
        )}
        {renderViewInsuranceInfo(
          "deductible",
          t("InsuranceDeductible"),
          formatStringMoney
        )}
      </Form.Row>
      <DetailsPageSubsection title={t("InsuranceCoverages")}>
        <Form.Row>
          <PaginatableTableList
            tableName={"coverage-details"}
            dataIsLoading={false}
            thead={[
              t("InsuranceCoverageTitle"),
              t("InsuranceCoverageDescription"),
              t("InsuranceCoverageValue"),
            ]}
            tbody={props.infoDetails.coverages?.map((m, i) => [
              m.title,
              m.description,
              m.value,
            ])}
          />
        </Form.Row>
      </DetailsPageSubsection>
    </>
  );
};
