import { TypesHelper } from "c4u-web-components";

export interface IParametersModel {
  id: number;
  key: string;
  value: string;
}

export type IGetParametersResponse = IParametersModel[]

export class UpdateParametersRequest {
  id: number | null;
  key: string | null;
  value: string | null;
  constructor({ id, key, value }: IParametersModel) {
    this.id = TypesHelper.toNumberNull(id);
    this.key = TypesHelper.toStringNull(key);
    this.value = TypesHelper.toStringNull(value);
  }
}
