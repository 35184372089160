import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { conformToMask } from "react-text-mask-legacy";

import {
  FieldMetaProps,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  useFormik
} from "formik";

import {
  ButtonLink,
  ButtonPrimary,
  CoxIcon,
  LabelDefault,
  MaskHelper,
  ModalDefault,
  ScrollHelper
} from "c4u-web-components";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  AlertBox,
  LoginAction,
  LoginActionButton,
  LoginReturnButton
} from "./create-dealership-form.organism.style";
import {
  CreateDealershipForm,
  CreateDealershipFormValidationSchema
} from "./create-dealership-form.organism.validations";

import { GeneralError } from "../../../atoms/";
import { Input, InputWithMask } from "../../../molecules";

import { createUsersStep } from "../../../../contexts/registration-steps.context";
import { inputErrorHelper } from "../../../../helper";
import {
  useC4u,
  useSessionContext,
  useZipcodeAddress
} from "../../../../hooks";
import { useRegistrationStepsContext } from "../../../../hooks/";
import {
  ICompleteUser,
  IDealership
} from "../../../../hooks/apis/use-c4u/interfaces/interfaces";
import { ZipcodeAddress } from "../../../../models";

const CreateDealershipFormRender = (
  formikBag: FormikProps<CreateDealershipForm>,
  creator: ICompleteUser
) => {
  const {
    isSubmitting,
    getFieldMeta,
    setErrors,
    handleChange,
    errors,
  }: {
    isSubmitting: boolean;
    getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
    setErrors: (errors: FormikErrors<any>) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    errors: any;
  } = formikBag;
  const { t } = useTranslation();
  const history = useHistory();

  const [showOwnerNotice, setShowOwnerNotice] = useState(false);
  const handleCloseOwnerNotice = () => setShowOwnerNotice(false);
  const handleShowOwnerNotice = () => setShowOwnerNotice(true);

  const { getAddressByZipcode } = useZipcodeAddress();
  const { handleEventGTM } = useSessionContext();

  const [addressZipcode, setAddressZipcode] = useState<ZipcodeAddress>();

  const ZipcodeHandler = async (cep: string) => {
    if (getAddressByZipcode) {
      const address = await getAddressByZipcode(cep);
      var dealership_address_street =
        address.logradouro === ""
          ? formikBag.values.dealership_address_street
          : address.logradouro;
      var dealership_address_neighborhood =
        address.bairro === ""
          ? formikBag.values.dealership_address_neighborhood
          : address.bairro;
      if (address) {
        formikBag.setValues({
          ...formikBag.values,
          dealership_address_city: address.localidade,
          dealership_address_state: address.uf,
          dealership_address_street: dealership_address_street,
          dealership_address_neighborhood: dealership_address_neighborhood,
        });

        setAddressZipcode(address);
      }
    }
  };

  useEffect(() => {
    if (
      formikBag?.values?.dealership_zip_code &&
      formikBag.values.dealership_zip_code.replace(/[^\d]/g, "").length === 8
    ) {
      ZipcodeHandler(formikBag.values.dealership_zip_code);
    }
  }, [formikBag?.values?.dealership_zip_code]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      formikBag.handleSubmit(e);
      setTimeout(() => {
        ScrollHelper.scrollToError(document);
      }, 300);
    },
    // eslint-disable-next-line
    [formikBag]
  );

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <br />
        <LabelDefault lblColor="black" lblWeight="small">
          {t("Mandatory.Fields.Notice")}
        </LabelDefault>
        <GeneralError
          errorProp={getFieldMeta("general")}
          errorCode={getFieldMeta("errorCode")}
          setErrors={setErrors}
        />
        <br />
        <Row>
          <Form.Group as={Col} md={12} controlId="formLegalOrOwner">
            <LabelDefault lblColor="black" lblWeight="regular">
              {t("Are you the company's procurator?")}*
            </LabelDefault>
          </Form.Group>
          <Form.Group
            as={Col}
            md={12}
            controlId="formLegalOrOwnerFields"
            data-cyid={"formLegalOrOwnerFields"}
          >
            <Form.Check
              type="radio"
              name="legal_representative_or_owner"
              label={t("Yes")}
              onChange={(e: React.ChangeEvent<any>) => {
                handleShowOwnerNotice();
                handleChange(e);
                formikBag.setFieldValue(
                  "legal_representative_name",
                  creator.name
                );
                formikBag.setFieldValue(
                  "legal_representative_email",
                  creator.email
                );
              }}
              value="yes"
              checked={
                formikBag.values?.legal_representative_or_owner === "yes"
              }
              inline
            />
            <Form.Check
              type="radio"
              name="legal_representative_or_owner"
              label={t("No")}
              onChange={(e: React.ChangeEvent<any>) => {
                handleShowOwnerNotice();
                handleChange(e);
                formikBag.setFieldValue("legal_representative_name", "");
                formikBag.setFieldValue("legal_representative_email", "");
              }}
              checked={formikBag.values?.legal_representative_or_owner === "no"}
              value="no"
              inline
            />
          </Form.Group>
        </Row>

        <LabelDefault lblWeight="title" lblColor="blue">
          {t("Company's Procurator Data")}
        </LabelDefault>
        <br />

        <Form.Group as={Row} controlId="formGridName">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="legal_representative_name"
              placeholder={t("Name")}
              label={t("Full Name")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.legal_representative_name}
              dataCyid={"name"}
              isInvalid={
                errors.legal_representative_name !== null &&
                errors.legal_representative_name !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.legal_representative_name, t)
              }
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="legal_representative_email"
              placeholder={t("Email")}
              label={t("Email")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.legal_representative_email}
              dataCyid={"email"}
              isInvalid={
                errors.legal_representative_email !== null &&
                errors.legal_representative_email !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.legal_representative_email, t)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridPersonalInfo">
          <Col sm={12} md={3}>
            <InputWithMask
              mask={MaskHelper.Cpf}
              type="text"
              name="legal_representative_cpf"
              placeholder={t("CPF")}
              label={t("CPF")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.legal_representative_cpf}
              dataCyid={"cpf"}
              isInvalid={
                errors.legal_representative_cpf !== null &&
                errors.legal_representative_cpf !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.legal_representative_cpf, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <InputWithMask
              mask={MaskHelper.Date}
              type="text"
              name="legal_representative_birth_date"
              placeholder={t("Birthdate")}
              label={t("Birthdate")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.legal_representative_birth_date}
              dataCyid={"birthdate"}
              isInvalid={
                errors.legal_representative_birth_date !== null &&
                errors.legal_representative_birth_date !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.legal_representative_birth_date, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <InputWithMask
              mask={MaskHelper.Cellphone}
              type="text"
              name="legal_representative_cellphone"
              placeholder={t("Cell Phone")}
              label={t("Cell Phone")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.legal_representative_cellphone}
              dataCyid={"cellphone"}
              isInvalid={
                errors.legal_representative_cellphone !== null &&
                errors.legal_representative_cellphone !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.legal_representative_cellphone, t)
              }
            />
          </Col>
        </Form.Group>
        <br />
        <hr />
        <br />

        <LabelDefault lblWeight="title" lblColor="blue">
          {t("Dealership Data")}
        </LabelDefault>
        <br />

        <Form.Group as={Row} controlId="formGridCnpj">
          <Col sm={12} md={6}>
            <InputWithMask
              mask={MaskHelper.Cnpj}
              type="text"
              name="dealership_cnpj"
              placeholder={t("CNPJ")}
              label={t("CNPJ")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_cnpj}
              dataCyid={"cnpj"}
              isInvalid={
                errors.dealership_cnpj !== null &&
                errors.dealership_cnpj !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_cnpj, t)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridDealershipName">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_name"
              placeholder={t("Dealership Name")}
              label={t("Dealership Name")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_name}
              dataCyid={"dealershipName"}
              isInvalid={
                errors.dealership_name !== null &&
                errors.dealership_name !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_name, t)}
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_legal_name"
              placeholder={t("Dealership Legal Name")}
              label={t("LegalNameAsInCnpj")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_legal_name}
              dataCyid={"dealershipLegalName"}
              isInvalid={
                errors.dealership_legal_name !== null &&
                errors.dealership_legal_name !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_legal_name, t)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridDealershipContact">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_email"
              placeholder={t("Dealership Email")}
              label={t("Dealership Email")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_email}
              dataCyid={"dealershipEmail"}
              isInvalid={
                errors.dealership_email !== null &&
                errors.dealership_email !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_email, t)}
            />
          </Col>
          <Col sm={12} md={3}>
            <InputWithMask
              mask={MaskHelper.Cellphone}
              type="text"
              name="dealership_phone_primary"
              placeholder={t("Dealership Phone Primary")}
              label={t("Dealership Phone Primary")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_phone_primary}
              dataCyid={"dealershipPhonePrimary"}
              isInvalid={
                errors.dealership_phone_primary !== null &&
                errors.dealership_phone_primary !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_phone_primary, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <InputWithMask
              mask={MaskHelper.Phone}
              type="text"
              name="dealership_phone_second"
              placeholder={t("Dealership Phone Secondary")}
              label={t("Dealership Phone Secondary")}
              onChange={handleChange}
              required={false}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_phone_second}
              dataCyid={"dealershipPhoneSecond"}
              isInvalid={
                errors.dealership_phone_second !== null &&
                errors.dealership_phone_second !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_phone_second, t)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridDealershipGroup">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_group"
              placeholder={t("Dealership Group")}
              label={t("Dealership Group")}
              onChange={handleChange}
              required={true}
              disabled={
                !formikBag.values?.legal_representative_or_owner ||
                formikBag.values?.no_group?.includes("yes")
              }
              value={
                formikBag.values?.no_group?.includes("yes")
                  ? ""
                  : formikBag.values?.dealership_group
              }
              isInvalid={
                errors.dealership_group !== null &&
                errors.dealership_group !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_group, t)}
              dataCyid={"dealershipGroup"}
            />
          </Col>
          <Form.Group as={Col} sm={12} md={6} controlId="formNoGroup">
            <br />
            <Form.Check
              type="checkbox"
              name="no_group"
              label={t("I don't belong to any group")}
              onChange={handleChange}
              value="yes"
              disabled={!formikBag.values?.legal_representative_or_owner}
              checked={formikBag.values?.no_group?.includes("yes")}
              inline
              data-cyid={"noGroup"}
            />
          </Form.Group>
        </Form.Group>
        <br />
        <hr />
        <br />

        <Form.Group as={Row} controlId="formGridZipCode">
          <Col sm={12} md={6}>
            <InputWithMask
              mask={MaskHelper.Cep}
              type="text"
              name="dealership_zip_code"
              placeholder={t("Zip Code")}
              label={t("Zip Code")}
              onChange={handleChange}
              onBlur={() => {
                // formikBag.setFieldValue("legal_representative_name", creator.name);
              }}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_zip_code}
              dataCyid={"dealershipZipCode"}
              isInvalid={
                errors.dealership_zip_code !== null &&
                errors.dealership_zip_code !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_zip_code, t)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridAddress">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_address_street"
              placeholder={t("Street")}
              label={t("Street")}
              onChange={handleChange}
              required={false}
              disabled={
                !formikBag.values?.legal_representative_or_owner ||
                !!addressZipcode?.logradouro
              }
              value={formikBag.values?.dealership_address_street}
              dataCyid={"dealershipAddressStreet"}
              isInvalid={
                errors.dealership_address_street !== null &&
                errors.dealership_address_street !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_address_street, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              type="text"
              name="dealership_address_number"
              placeholder={t("Number")}
              label={t("Number")}
              onChange={handleChange}
              required={true}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_address_number}
              dataCyid={"dealershipAddressNumber"}
              isInvalid={
                errors.dealership_address_number !== null &&
                errors.dealership_address_number !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_address_number, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              type="text"
              name="dealership_address_complement"
              placeholder={t("Complement")}
              label={t("Complement")}
              onChange={handleChange}
              required={false}
              disabled={!formikBag.values?.legal_representative_or_owner}
              value={formikBag.values?.dealership_address_complement}
              dataCyid={"dealershipAddressComplement"}
              isInvalid={
                errors.dealership_address_complement !== null &&
                errors.dealership_address_complement !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_address_complement, t)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridAddressArea">
          <Col sm={12} md={6}>
            <Input
              type="text"
              name="dealership_address_neighborhood"
              placeholder={t("Neighborhood")}
              label={t("Neighborhood")}
              onChange={handleChange}
              required={false}
              disabled={
                !formikBag.values?.legal_representative_or_owner ||
                !!addressZipcode?.bairro
              }
              value={formikBag.values?.dealership_address_neighborhood}
              dataCyid={"dealershipAddressNeighborhood"}
              isInvalid={
                errors.dealership_address_neighborhood !== null &&
                errors.dealership_address_neighborhood !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_address_neighborhood, t)
              }
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              type="text"
              name="dealership_address_city"
              placeholder={t("City")}
              label={t("City")}
              onChange={handleChange}
              required={true}
              disabled={
                !formikBag.values?.legal_representative_or_owner ||
                !!addressZipcode?.localidade
              }
              value={formikBag.values?.dealership_address_city}
              dataCyid={"dealershipAddressCity"}
              isInvalid={
                errors.dealership_address_city !== null &&
                errors.dealership_address_city !== undefined
              }
              errors={() => inputErrorHelper(errors.dealership_address_city, t)}
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              type="text"
              name="dealership_address_state"
              placeholder={t("State")}
              label={t("State")}
              onChange={handleChange}
              required={false}
              disabled={
                !formikBag.values?.legal_representative_or_owner ||
                !!addressZipcode?.uf
              }
              value={formikBag.values?.dealership_address_state}
              dataCyid={"dealershipAddressState"}
              isInvalid={
                errors.dealership_address_state !== null &&
                errors.dealership_address_state !== undefined
              }
              errors={() =>
                inputErrorHelper(errors.dealership_address_state, t)
              }
            />
          </Col>
        </Form.Group>
        <br />
        <hr />
        <br />

        <LoginAction>
          <Row>
            <Col sm={12} md={6}>
              <LoginReturnButton>
                <ButtonLink
                  sizex="sm"
                  onClick={() => history.push("/user/create")}
                >
                  {t("Return")}
                </ButtonLink>
              </LoginReturnButton>
            </Col>
            <Col sm={12} md={6}>
              <LoginActionButton>
                <ButtonPrimary
                  type="submit"
                  sizex="md"
                  disabled={
                    !formikBag.values?.legal_representative_or_owner ||
                    isSubmitting
                  }
                >
                  {t("Next")}
                </ButtonPrimary>
              </LoginActionButton>
            </Col>
          </Row>
        </LoginAction>
      </Form>
      <ModalDefault
        show={showOwnerNotice}
        onHide={handleCloseOwnerNotice}
        title={t("Alert")}
        animation={true}
        size="lg"
        footer={
          <ButtonPrimary
            sizex="sm"
            sizey="normal"
            onClick={() => {
              handleEventGTM({
                event: "alertInformation",
                alertMessage: "alerta-aprovacao-cadastro",
              });
              handleCloseOwnerNotice();
            }}
          >
            {t("Ok")}
          </ButtonPrimary>
        }
      >
        <AlertBox>
          <CoxIcon
            name="info"
            width={32}
            height={32}
            viewBox="0 0 24 24"
            fillColor="#fdba4b"
          />
          <br />
          <br />
          <LabelDefault lblColor="blue" lblWeight="title">
            {t("Registration Approval")}
          </LabelDefault>
          <br />
          <br />
          <div>
            <LabelDefault lblColor="black" lblWeight="regular">
              {t("Registraton.Approval.P1")}
            </LabelDefault>
          </div>
          <div>
            <LabelDefault lblColor="black" lblWeight="regular">
              {t("Registraton.Approval.P2")}
            </LabelDefault>
          </div>
          <div>
            <LabelDefault lblColor="black" lblWeight="regular">
              {t("Registraton.Approval.P3")}
            </LabelDefault>
          </div>
        </AlertBox>
      </ModalDefault>
    </>
  );
};

const buildDealershipRequest = (
  creatorId: number,
  request: CreateDealershipForm
): IDealership => {
  return {
    procurator: {
      isProcuratorOrOwner: request.legal_representative_or_owner === "yes",
      cpf: request.legal_representative_cpf
        .replace(/\./g, "")
        .replace(/_/g, "")
        .replace(/-/g, ""),
      name: request.legal_representative_name,
      email: request.legal_representative_email.trim(),
      birthDate: request.legal_representative_birth_date,
      cellPhone: request.legal_representative_cellphone
        .replace("(", "")
        .replace(")", "")
        .replace(/-/g, "")
        .replace(/_/g, "")
        .replace(" ", ""),
    },
    address: {
      zipCode: request.dealership_zip_code,
      street: request.dealership_address_street,
      number: request.dealership_address_number,
      complement: request.dealership_address_complement,
      neighborhood: request.dealership_address_neighborhood,
      city: request.dealership_address_city,
      state: request.dealership_address_state,
    },
    id: request.dealership_id,
    cnpj: request.dealership_cnpj
      .replace(/\./g, "")
      .replace("-", "")
      .replace(/_/g, "")
      .replace("/", ""),
    name: request.dealership_name,
    legalName: request.dealership_legal_name,
    email: request.dealership_email?.trim(),
    primaryPhone: request.dealership_phone_primary
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(/_/g, "")
      .replace(" ", ""),
    secondaryPhone: request.dealership_phone_second
      ?.replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(/_/g, "")
      .replace(" ", ""),
    group: request.dealership_group,
    createdById: parseInt(creatorId.toString(), 10),
  };
};

const buildDealershipFromAPI = (
  dealership: IDealership
): CreateDealershipForm => {
  return {
    legal_representative_or_owner: dealership.procurator?.isProcuratorOrOwner
      ? "yes"
      : "no",
    legal_representative_cpf: conformToMask(
      dealership.procurator?.cpf ?? "",
      MaskHelper.Cpf,
      {}
    )?.conformedValue,
    legal_representative_name: dealership.procurator?.name ?? "",
    legal_representative_email: dealership.procurator?.email ?? "",
    legal_representative_birth_date:
      conformToMask(dealership.procurator?.birthDate ?? "", MaskHelper.Date, {})
        ?.conformedValue ?? "",
    legal_representative_cellphone:
      conformToMask(
        dealership.procurator?.cellPhone ?? "",
        MaskHelper.Cellphone,
        {}
      )?.conformedValue ?? "",

    dealership_zip_code:
      conformToMask(dealership.address?.zipCode ?? "", MaskHelper.Cep, {})
        ?.conformedValue ?? "",
    dealership_address_street: dealership.address?.street ?? "",
    dealership_address_number: dealership.address?.number ?? "",
    dealership_address_complement: dealership.address?.complement ?? "",
    dealership_address_neighborhood: dealership.address?.neighborhood ?? "",
    dealership_address_city: dealership.address?.city ?? "",
    dealership_address_state: dealership.address?.state ?? "",

    dealership_id: dealership.id ?? "",
    dealership_cnpj:
      conformToMask(dealership.cnpj ?? "", MaskHelper.Cnpj, {})
        ?.conformedValue ?? "",
    dealership_name: dealership.name ?? "",
    dealership_legal_name: dealership.legalName ?? "",
    dealership_email: dealership.email ?? "",
    dealership_phone_primary: dealership.primaryPhone
      ? conformToMask(dealership.primaryPhone, MaskHelper.Phone, {})
          .conformedValue
      : "",
    dealership_phone_second: dealership.secondaryPhone
      ? conformToMask(dealership.secondaryPhone, MaskHelper.Phone, {})
          .conformedValue
      : "",
    dealership_group: dealership.group ?? "",
    no_group: dealership.group ? "no" : "yes",
    created_by_id: dealership.createdById,
  };
};

// We may find a better way to do this mappings (from flatten to obj and the oposite)
// for now, lets keep doing case-by-case
const parseErrorsFromAPI = (errors: any): FormikErrors<any> => {
  const formikErrors: FormikErrors<any> = {};
  Object.keys(errors).forEach((k) => {
    console.log("evaluating key : ", k);
    if (k === "procurator.BirthDate")
      formikErrors.legal_representative_birth_date = errors[k];
    if (k === "procurator.Cpf")
      formikErrors.legal_representative_cpf = errors[k];
    if (k === "procurator.CellPhone")
      formikErrors.legal_representative_cellphone = errors[k];
    if (k === "cnpj") formikErrors.dealership_cnpj = errors[k];
    if (k === "primaryPhone") formikErrors.dealership_phone_primary = errors[k];
    if (k === "secondaryPhone")
      formikErrors.dealership_phone_second = errors[k];
    if (k === "email") formikErrors.dealership_email = errors[k];
  });

  return formikErrors;
};

const CreateDealershipFormOnSubmit = (
  values: CreateDealershipForm,
  actions: FormikHelpers<CreateDealershipForm>,
  createdById: number,
  onSubmit: (
    request: IDealership,
    actions: FormikHelpers<CreateDealershipForm>
  ) => void
) => {
  console.log("starting to build the dealership request...");
  actions.setSubmitting(true);

  const request = buildDealershipRequest(createdById, values);
  onSubmit(request, actions);
};

const CreateDealershipFormFields: React.FC = (props: any) => {
  const { isLoading } = useAuth0();
  const { createdByPublicId } = useParams() as any;
  const { GetUsersCreatedBy, GetDealerCreatedBy, CreateDealership } = useC4u();
  const [creator, setCreator] = useState({} as ICompleteUser);

  const { setStepsContext, setCurrentStep, currentStep } =
    useRegistrationStepsContext();
  const setStepsContextToUsers = () => {
    setStepsContext(createUsersStep);
    setCurrentStep("users");
  };

  const initialValues: CreateDealershipForm = {
    legal_representative_or_owner: "",
    legal_representative_name: "",
    legal_representative_email: "",
    legal_representative_cpf: "",
    legal_representative_birth_date: "",
    legal_representative_cellphone: "",

    dealership_cnpj: "",
    dealership_name: "",
    dealership_legal_name: "",
    dealership_email: "",
    dealership_phone_primary: "",
    dealership_phone_second: "",
    dealership_group: "",
    no_group: "",

    dealership_zip_code: "",
    dealership_address_street: "",
    dealership_address_number: "",
    dealership_address_complement: "",
    dealership_address_neighborhood: "",
    dealership_address_city: "",
    dealership_address_state: "",
    created_by_id: -1,
  };

  const submitForm = (
    request: IDealership,
    actions: FormikHelpers<CreateDealershipForm>
  ) => {
    CreateDealership(request)
      .then((createdDealership: any) => {
        setStepsContextToUsers();
      })
      .catch((err: any) => {
        console.log("catch ", err);
        if (err.errors) {
          console.error("API handled errors : ", err.errors);
          actions.setErrors(parseErrorsFromAPI(err.errors));
        }

        if (err.generalError) {
          actions.setFieldError("general", err.generalError);
        }

        if (err.message) {
          actions.setFieldError("general", err.message);
        }

        // navigate to errors after form is submitted
        setTimeout(() => {
          ScrollHelper.scrollToError(document);
        }, 200);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik<CreateDealershipForm>({
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      CreateDealershipFormOnSubmit(
        values,
        actions,
        creator.id ?? values.created_by_id,
        submitForm
      );
    },
    validationSchema: CreateDealershipFormValidationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (currentStep === "") setCurrentStep("dealership");
  });

  useEffect(() => {
    if (isLoading) return;

    GetDealerCreatedBy(createdByPublicId)
      .then((dealership: IDealership) => {
        if (dealership)
          formik.setValues(buildDealershipFromAPI(dealership), false);
      })
      .catch((err: any) => {
        if (err.message) {
          formik.setFieldError("general", err.message);
          formik.setFieldError("errorCode", err.code);
        }

        if (err.generalError) {
          formik.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        console.log(
          "This suppose to be the last loading time for create dealership flow..."
        );
      });

    GetUsersCreatedBy(createdByPublicId)
      .then((users: Array<ICompleteUser>) => {
        if (users.length) setCreator(users[0]);
      })
      .catch((err: any) => {
        if (err.message) {
          formik.setFieldError("general", err.message);
          formik.setFieldError("errorCode", err.code);
        }

        if (err.generalError) {
          formik.setFieldError("general", err.generalError);
        }
      })
      .finally(() => {
        console.log(
          "This suppose to be the last loading users time for create dealership flow..."
        );
      });
  }, [createdByPublicId, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return CreateDealershipFormRender(formik, creator);
};

export { CreateDealershipFormFields };

