import styled from "styled-components";

const PageControlItemBase = styled.button`
  color: #005ba8;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border: none;
  background-color: inherit;
  padding: 6px 6px;
`;

export const PageControlItem = styled(PageControlItemBase)`
  &:disabled {
    opacity: 0.5;
  }
`;

export const PageNumber = styled(PageControlItemBase)`
  cursor: default !important;
`;

export const PageControls = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;