import React from "react";
import { Col, Form } from "react-bootstrap";

const renderViewHelper = (
  values: any,
  key: string,
  label: string,
  func?: (attr: any) => string,
  md: number = 4,
  sm: number = 6,
  enable?: boolean
) => {
  const value = values[key];
  const valueFormated = [undefined, null, ""].includes(value)
    ? "-"
    : func
    ? func(value)
    : value;
  return (
    <Form.Group as={Col} md={md} sm={sm}>
      <Form.Label>{label}</Form.Label>
      <Form.Control value={valueFormated} disabled={!enable} />
    </Form.Group>
  );
};

export { renderViewHelper };
