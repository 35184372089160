import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { TitleWithBackButtonAtom } from "../../../atoms";
import { LenderCreateUserOrganism } from "../../../organisms";

export const LenderCreateUserPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <TitleWithBackButtonAtom
        title={t("FinancialUsers")}
        onClick={() => push(paths.backofficeLenderListUsers())}
      />

      <LenderCreateUserOrganism />
    </>
  );
};
