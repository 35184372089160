import styled from "styled-components";

interface CardPartnersProps {
  selected: boolean;
}

export const ContentWrapper = styled.div`
  overflow-x: hidden;
  position: relative;
  left: 32px;
  padding: 110px 0 75px;
  .img-hq {
    width: 100%;
  }
  @media (max-width: 1280px) {
    overflow-y: hidden;
  }

  @media (max-width: 576px) {
    left: 0px;
    padding-top: 70px;
  }
`;

export const Title = styled.div`
  padding-bottom: 40px;

  color: #1f3e74;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;

export const CardPartnersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 70px;

  @media (max-width: 1199px) {
    margin-bottom: 40px;
  }

  @media (max-width: 991px) {
    width: 100%;

    display: flex;

    overflow-x: auto;
    overflow-y: hidden;

    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    --webkit-overflow-scrolling: touch;

    transition: all 0.4s;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CardPartners = styled.div<CardPartnersProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 168px;
  height: 108px;

  background: #ffffff;
  border: 1px solid ${({ selected }) => (selected ? "#1f3e74" : "#ffffff")};
  filter: ${({ selected }) =>
    selected ? "drop-shadow(0px 0px 5px rgba(204, 204, 204, 0.25));" : "none"};
  box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.25);
  border-radius: 10px;
  transition: all 0.2s;

  cursor: pointer;

  :hover {
    border: 1px solid #1f3e74;
    filter: ;
  }

  @media (max-width: 1199px) {
    width: 138px;
    height: 78px;
  }

  @media (max-width: 991px) {
    width: 168px;
    height: 108px;

    scroll-snap-align: start;

    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.4s;
    position: relative;

    transition: 0.4s;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

export const SlideWrapper = styled.div`
  width: 500%;

  display: flex;
  justify-content: center;
`;
