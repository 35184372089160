import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  LogoCreditas_141_74,
  LogoDaycoval_45_49,
  LogoItau_45_49,
  LogoOmni_97_92,
} from "../../../assets";
import {
  AboutItau,
  AboutCreditas,
  AboutDaycoval,
  AboutOmni,
} from "../../../organisms";
import {
  BankName,
  CardBanks,
  CardBanksWrapper,
  ContentWrapper,
  SlideWrapper,
  Title,
} from "./about-banks.page.style";

import { Container } from "react-bootstrap";
import { useSessionContext } from "../../../../hooks";

export const AboutBanksPage = () => {
  const [bankSelected, setBankSelected] = useState<string>("itau");
  const { setActualPageContext } = useSessionContext();

  useEffect(() => {
    setActualPageContext("NotActive");
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();

  function handleBankSelected() {
    if (bankSelected) {
      switch (bankSelected) {
        case "creditas": {
          return <AboutCreditas />;
        }
        case "daycoval": {
          return <AboutDaycoval />;
        }
        case "omni": {
          return <AboutOmni />;
        }

        default: {
          return <AboutItau />;
        }
      }
    }
  }

  return (
    <>
      <Container>
        <ContentWrapper className="px-md-5 px-xl-0">
          <Title>{t("banks")}:</Title>
          <CardBanksWrapper className="d-flex d-lg-none">
            <SlideWrapper>
              <CardBanks
                selected={bankSelected === "itau"}
                onClick={() => setBankSelected("itau")}
              >
                <img alt="Itau Logo" src={LogoItau_45_49} />
                <BankName>{t("ItauBank")}</BankName>
              </CardBanks>
              <CardBanks
                selected={bankSelected === "creditas"}
                onClick={() => setBankSelected("creditas")}
              >
                <img alt="Itau Creditas" src={LogoCreditas_141_74} />
              </CardBanks>
              <CardBanks
                selected={bankSelected === "daycoval"}
                onClick={() => setBankSelected("daycoval")}
              >
                <img alt="Itau Daycoval" src={LogoDaycoval_45_49} />
                <BankName>{t("DaycovalBank")}</BankName>
              </CardBanks>
              <CardBanks
                selected={bankSelected === "omni"}
                onClick={() => setBankSelected("omni")}
              >
                <img alt="Itau Omni" src={LogoOmni_97_92} />
              </CardBanks>
            </SlideWrapper>
          </CardBanksWrapper>

          <CardBanksWrapper className="d-none d-lg-flex">
            <CardBanks
              selected={bankSelected === "itau"}
              onClick={() => setBankSelected("itau")}
            >
              <img alt="Itau Logo" src={LogoItau_45_49} />
              <BankName>{t("ItauBank")}</BankName>
            </CardBanks>
            <CardBanks
              selected={bankSelected === "creditas"}
              onClick={() => setBankSelected("creditas")}
            >
              <img alt="Itau Creditas" src={LogoCreditas_141_74} />
            </CardBanks>
            <CardBanks
              selected={bankSelected === "daycoval"}
              onClick={() => setBankSelected("daycoval")}
            >
              <img alt="Itau Daycoval" src={LogoDaycoval_45_49} />
              <BankName>{t("DaycovalBank")}</BankName>
            </CardBanks>
            <CardBanks
              selected={bankSelected === "omni"}
              onClick={() => setBankSelected("omni")}
            >
              <img alt="Itau Omni" src={LogoOmni_97_92} />
            </CardBanks>
          </CardBanksWrapper>

          {handleBankSelected()}
        </ContentWrapper>
      </Container>
    </>
  );
};
