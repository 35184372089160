import { Tooltip } from "c4u-web-components";
import React from "react";
import {
  BottomBox,
  Content,
  MainBox,
  RoundedHighlight,
  SecondaryBox,
  WrapperCardPartner,
} from "./card-partner.molecule.style";

interface IProps {
  img: string;
  imgTitle: string;
  labelBox: string;
  labelBottomBox: string;
  labelDate?: string;
  labelDateType?: "normal" | "medium" | "high";
  tooltipComingSoon?: string;
}

export const CardPartnerMolecule: React.FC<IProps> = (props) => {
  return (
    <>
      <WrapperCardPartner>
        <Content>
          <div>
            <img alt={props.imgTitle} src={props.img} />
          </div>
          <span>{props.imgTitle}</span>
        </Content>
        <BottomBox>
          {props.tooltipComingSoon ? (
            <Tooltip content={props.tooltipComingSoon} placement={"bottom"}>
              <MainBox>{props.labelBox}</MainBox>
              <SecondaryBox>{props.labelBottomBox}</SecondaryBox>
            </Tooltip>
          ) : (
            <>
              <MainBox>{props.labelBox}</MainBox>
              <SecondaryBox>{props.labelBottomBox}</SecondaryBox>
            </>
          )}
        </BottomBox>

        {props.labelDate && (
          <RoundedHighlight
            backgroudType={props.labelDateType?.toString() ?? ""}
          >
            {props.labelDate}
          </RoundedHighlight>
        )}
      </WrapperCardPartner>
    </>
  );
};
