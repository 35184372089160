import { TypesHelper } from "c4u-web-components";
import {
  IPagedRequestParamsBaseModel,
  PagedRequestParamsBaseModel,
  TrackerStepEnum,
} from "../..";

import { IPagedResponseBaseModel } from "../..";

export interface IGetTrackerListParams extends IPagedRequestParamsBaseModel {
  search: string;
  steps?: number | null;
}

export class GetTrackerListParams extends PagedRequestParamsBaseModel {
  search: string | null;
  steps?: string | null;
  constructor({ search, steps, ...baseParams }: IGetTrackerListParams) {
    super(baseParams);
    this.search = TypesHelper.toStringNull(search);
    this.steps = TypesHelper.toStringNull(steps);
  }
}

export interface IGetTrackerListResponse {
  id: number;
  customerId?: number;
  name?: string;
  document?: string;
  createdAt: string;
  vehicleName?: string;
  plate?: string;
  step: TrackerStepEnum;
  partnerQuoteItemId?: number;
  businessNumber?: string;
}

export interface IGetTrackerListPagedResponseModel
  extends IPagedResponseBaseModel {
  data?: IGetTrackerListResponse;
}
