import { ButtonPrimary, MaskHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Col } from "react-bootstrap";
import { FormikControlAtom } from "../../../atoms";
import { StdEnum } from "../../../../models";

interface IProps {
  formik: any;
  statusEnum: StdEnum;
  errorMessage?: string;
}

export const PaginatedTableSearchForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = props.formik

  return (
    <>
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            as={"select"}
            md={4}
            property={"status"}
            label={t("Status")}
          >
            <option value={""}>{t("All")}</option>
            {Object.entries(props.statusEnum)
              .filter(([k, _]) => !isNaN(parseInt(k)))
              .map(([k, v], i) => (
                <option value={k} key={`Status${i}`}>{t(String(v))}</option>
              ))}
          </FormikControlAtom>
          <FormikControlAtom
            formik={formik}
            md={2}
            property={"initialDate"}
            label={t("InitialDate")}
            mask={MaskHelper.Date}
          />
          <FormikControlAtom
            formik={formik}
            md={2}
            property={"finalDate"}
            label={t("FinalDate")}
            mask={MaskHelper.Date}
          />
        </Form.Row>
        <Form.Row>
          <FormikControlAtom
            formik={formik}
            md={4}
            property={"textSearch"}
            label={t("TextSearch")}
          />
          <FormikControlAtom
            formik={formik}
            as={"select"}
            md={2}
            property={"pageSize"}
            label={t("RecordsPerPage")}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormikControlAtom>
          {/* <FormikControlAtom
            formik={formik}
            md={3}
            property={"orderBy"}
            label={t("OrderBy")}
          /> */}
          <Form.Group as={Col} md className={"text-right pt-4"}>
            <ButtonPrimary
              type={"submit"}
              sizex={"md"}
              loading={formik.isSubmitting}
            >
              {t("Search")}
            </ButtonPrimary>
          </Form.Group>
        </Form.Row>
      </Form>
      {props.errorMessage && (
        <div>{props.errorMessage}</div>
      )}
    </>
  );
};
