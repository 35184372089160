import React from "react";

import { CorporateUserListTableSearchOrganism } from "./corporate-user-list-table-search.organism";

import { useTranslation } from "react-i18next";
import { useC4u } from "../../../../../hooks";
import {
  ApprovalStatusEnum,
  CorporateUserTypeEnum,
  CoxUserIsActiveBooleanEnum,
  IListCorporateUserResponse,
} from "../../../../../models";
import { Link } from "react-router-dom";
import { paths } from "../../../../../constants";
import { CoxIcon } from "c4u-web-components";
import { StringHelper } from "../../../../../helper";

export const CorporateUserListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getCorporateUsers } = useC4u();

  return (
    <CorporateUserListTableSearchOrganism
      getDataCallback={getCorporateUsers}
      DealershipStatusEnum={ApprovalStatusEnum}
      CoxUserIsActiveBooleanEnum={CoxUserIsActiveBooleanEnum}
      tableHeaders={[
        "#",
        t("UserStatus"),
        t("TypeOfUser"),
        t("CoxUserName"),
        t("CoxUserEmail"),
        t("Cpf"),
        "",
      ]}
      mappingFunction={(m: IListCorporateUserResponse, i) => [
        m.coxUserId,
        m.coxUserActive ? t("Active") : t("Inactive"),
        m.corporateUserType
          ? t(CorporateUserTypeEnum[m.corporateUserType])
          : "-",
        m.coxUserName,
        m.coxUserEmail,
        StringHelper.formatCpfCnpj(m.coxUserCpf),
        <Link
          key={`link${i}`}
          to={paths.backofficeCorporateUserPage(m.coxUserId)}
          className="d-flex"
        >
          <span className="pr-2">{t("Edit")}</span>
          <CoxIcon
            name="pencil-edit"
            width={16}
            height={16}
            fillColor="#1F3E74"
          />
        </Link>,
      ]}
    />
  );
};
