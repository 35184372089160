import React, { useMemo } from "react";
import {
  MyEarningsCommissionMolecule,
  MyEarningsObservationMolecule,
} from "../../molecules";
import {
  DocumentV3_27_27,
  DollarSign_31_31,
  Lightbulb_21_29,
} from "../../assets";
import { IObservation } from "../../molecules/my-earnings/observation/my-earnings-observation.molecule";
import { MyEarningsOrganismWrapper } from "./my-earnings.organism.style";

export const MyEarningsPortoOrganism: React.FC = () => {
  const commissionData = useMemo(
    () => ({
      text: "3,22% do prêmio do seguro* ou R$ 50,00 (o que for maior)",
      observation:
        "*Descontado cancelamentos que ocorrerem na carteira. IOF não compõe o prêmio do seguro",
    }),
    []
  );

  const observationData = useMemo<IObservation[]>(
    () => [
      {
        icon: Lightbulb_21_29,
        iconAltText: "lampada",
        title: "Objeto de Comissão",
        description:
          "Venda de seguros Auto junto com o financiamento do veículo e também na jornada avulsa (sem financiamento)",
      },
      {
        icon: DollarSign_31_31,
        iconAltText: "cifrão",
        title: "Forma de pagamento da comissão",
        description:
          "Apuração da comissão é feita até o 5º dia útil do mês subsequente e o pagamento em até 20 dias após o recebimento da Nota Fiscal",
      },
      {
        icon: DocumentV3_27_27,
        iconAltText: "documento",
        title: "Emitir NF em nome de",
        description: [
          "Evora News Corr Segs Ltda",
          "Avenida Ibirapuera, 2332, Torre 2, 14º andar",
          "Indianópolis - São Paulo - SP",
          "CEP: 04028-002 - CNPJ: 09.569.094/0001-99",
        ],
      },
    ],
    []
  );

  return (
    <MyEarningsOrganismWrapper>
      <MyEarningsCommissionMolecule
        text={commissionData.text}
        observation={commissionData.observation}
        titleBackgroundColor="#3a5693"
      />
      <MyEarningsObservationMolecule observations={observationData} />
    </MyEarningsOrganismWrapper>
  );
};
