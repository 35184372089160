import { YupHelper } from "c4u-web-components";
import * as Yup from "yup";
import { setLocale } from "yup";

setLocale({
  mixed: {
    default: (err) => ({ key: "field_invalid", field: err.path }),
    required: (err) => ({ key: "field_required", field: err.path }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: "field_too_small", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
  string: {
    email: (err) => ({ key: "field_invalid_email", field: err.path }),
  },
  array: {
    min: ({ min }) => ({ key: "at_least_n_users", values: { min } }),
  },
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "isMobilePhoneValid",
  YupHelper.isMobilePhoneValid
);

export const CreateCompleteUserFormFormValidationSchema = Yup.object()
  .shape({
    users: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional(),
          publicId: Yup.string().optional(),
          name: Yup.string().required().max(100),
          email: Yup.string().required().trim().email().max(320), //according to RFC 3696
          cellPhone: Yup.string()
            .required()
            .isMobilePhoneValid("field_invalid_mobile_phone"),
          isMaster: Yup.boolean().required(),
        })
      )
      .required()
      .min(1),
  })
  .test(
    "atLeastOneMasterValidator",
    "at least one master must be selected",
    (obj) => {
      if (obj?.users.filter((x) => x?.isMaster)?.length) {
        return true; // everything is fine, at least one was selected
      }

      return new Yup.ValidationError("at_least_1_user_master", null, "general");
    }
  )
  .test(
    "noRepeatedEmails",
    "no repeated e-mails are allowed on the list",
    (obj) => {
      const groups = obj?.users.reduce((group, current) => {
        if (!current?.email) return group;

        group[current?.email] = group[current?.email]
          ? group[current?.email] + 1
          : 1;

        return group;
      }, {} as Record<string, number>);

      if (!groups) return true;

      if (Object.values(groups).filter((x) => x > 1).length > 0) {
        return new Yup.ValidationError("duplicated_emails", null, "general");
      }

      return true; // everything is fine, at least one was selected
    }
  )
  .defined();

export type CreateCompleteUserForm = Yup.InferType<
  typeof CreateCompleteUserFormFormValidationSchema
>;
