import styled from "styled-components";

export const WrapperHomeBackoffice = styled.div`
  width: 100%;

  #navbar-nav-wrapper-div {
    margin: 0 auto;

    @media (max-width: 991px) {
      align-items: flex-end;
      margin-right: 12px;
    }
  }
`;

export const BackOfficeText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.15px;
`;
