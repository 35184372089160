import styled, { css } from "styled-components";

interface Props {
  isOpen: boolean;
  collapse: any;
}

const Wrapper = styled.button<Props>`
  background-color: transparent;
  border-radius: 100px;

  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isOpen && "transform: rotate(180deg);"}

  :first-child {
    margin-right: 16px;
  }

  ${({ collapse, theme }) =>
    collapse &&
    css`
      border: 0;
      :disabled,
      :hover:not(:disabled),
      :first-child {
        border: 0;
        text-decoration: none;
        margin: 0;
      }

      transform: rotate(90deg);

      :focus {
        outline: none;
      }
    `}

  ${(props) =>
    props.isOpen ? "transform: rotate(90deg);" : "transform: rotate(270deg);"}
`;

export { Wrapper };
