import React from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Content,
  HighlightText,
  List,
  ListItem,
  Title,
} from "./about-ituran.organism.style";

export const AboutIturan = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("Ituran")}</Title>
      <Content>
        <Trans>
          {t("InBrazilIturan")}
          {t("IturanTracker")}
          {t("OnThePlatform")}
          {t("AsOf")}
          <List>
            <ListItem>{t("FreeHomeInstallation")}</ListItem>
            <ListItem>{t("Indemnity")}</ListItem>
            <ListItem>{t("WeAccept")}</ListItem>
            <ListItem>{t("AccessTo")}</ListItem>
            <ListItem>{t("Installments")}</ListItem>
          </List>
          <HighlightText>{t("Commissioning")}:</HighlightText>
          <List>
            <ListItem>{t("IturanEveryInsuranceSale")}</ListItem>
            <ListItem>{t("BesidesSurprisingPlan")}</ListItem>
            <ListItem>{t("BesidesCashEarnings")}</ListItem>
          </List>
          <List>
            <ListItem italic semiBold>
              {t("SeeTheInsurance")}
            </ListItem>
          </List>
        </Trans>
      </Content>
    </React.Fragment>
  );
};
