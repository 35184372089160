import styled from "styled-components";

export const WrapperCardPartner = styled.div`
  width: 263px;
  height: 302px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
  margin: 20px 20px 40px 0;
`;

interface IProps {
  backgroudType: string;
}

export const RoundedHighlight = styled.div<IProps>`
  width: 52px;
  height: 52px;
  right: -21px;
  top: -21px;
  position: absolute;
  border-radius: 52px;

  background: ${(p) =>
    p.backgroudType === "high"
      ? "#F3BC2A"
      : p.backgroudType === "medium"
      ? "#233971"
      : "#5993bd"};

  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  padding: 4px;

  /* or 90% */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;

  color: #ffffff;
`;

export const Content = styled.div`
  height: 232px;
  div {
    height: 187px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    img {
      max-width: 240px;
      max-height: 140px;
    }
  }
  span {
    font-family: ${(p) => p.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    /* or 167% */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.15px;

    color: #233971;
  }
`;

export const BottomBox = styled.div`
  overflow: hidden;
`;

export const MainBox = styled.div`
  background: #a8aaac;
  border-radius: 0px 0px 10px 10px;
  height: 40px;
  box-shadow: 0px 1px 3px #93979b;
  position: relative;
  margin: 0 0 -10px 0;

  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;

  /* or 167% */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;

  color: #ffffff;

  text-transform: uppercase;
`;

export const SecondaryBox = styled.div`
  background: #a8aaac;
  height: 40px;

  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;

  /* or 214% */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;

  padding: 10px 0 0 0;
  color: #ffffff;
  border-radius: 0 0 10px 10px;

  text-transform: uppercase;
`;
