import { TypesHelper } from "c4u-web-components";

export interface IReportPictureModel {
  reportLogPictureId: number;
  picturePosition: number;
}

export interface IPostSelectedRepostPicturesRequest {
  adInspectionId: number;
  reportLogPictures: IReportPictureModel[];
}

export class PostSelectedRepostPicturesRequest {
  adInspectionId: number | null;
  reportLogPictures: IReportPictureModel[] | null;
  constructor({
    adInspectionId,
    reportLogPictures,
  }: IPostSelectedRepostPicturesRequest) {
    this.adInspectionId = TypesHelper.toNumberNull(adInspectionId);
    this.reportLogPictures = reportLogPictures ? reportLogPictures : null;
  }
}
