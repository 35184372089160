import { TypesHelper } from "c4u-web-components";

export interface IDataLoadBorrowersRequest {
  file: any | string | Blob;
  lenderId: number;
}

export class DataLoadBorrowersRequest {
  file: any | string | Blob | null;
  lenderId: number | null;
  constructor({ file, lenderId }: IDataLoadBorrowersRequest) {
    this.file = file;
    this.lenderId = TypesHelper.toNumberNull(lenderId);
  }
}
