import React from "react";
import { useTranslation } from "react-i18next";

import { DataLoadResidualValuesOrganism } from "../../../organisms";

export const FidelizaDataLoadResidualBasePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("LoadBaseResidualValues")}</h3>
      <hr />
      <DataLoadResidualValuesOrganism />
    </>
  );
};
