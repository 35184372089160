import { DateHelper } from "c4u-web-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useManhein } from "../../../../../hooks";
import { IGetDataLoadBorrowersHistoryResponse } from "../../../../../models";
import { DataLoadBorrowersTableOrganism } from "./data-load-borrowers-list.organism";
import { LoadFileBorrowerFormOrganism } from "./load-file-borrower-form.organism";

export const DataLoadBorrowersOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getDataLoadBorrowersHistory } = useManhein();
  const [reloadList, setReloadList] = useState(false);

  return (
    <>
      <LoadFileBorrowerFormOrganism setReloadList={setReloadList} />

      <h3 className="mt-4">{t("LoadHistory")}</h3>
      <hr />

      <DataLoadBorrowersTableOrganism
        getDataCallback={getDataLoadBorrowersHistory}
        tableName="borrowers-data-load-history"
        reloadList={reloadList}
        setFinishReload={setReloadList}
        tableHeaders={[
          t("Id"),
          t("Filename"),
          t("Bank"),
          t("CreatedAt"),
          t("isSuccess"),
        ]}
        mappingFunction={(m: IGetDataLoadBorrowersHistoryResponse, i) => [
          m.id,
          m.fileName,
          m?.lender?.name,
          DateHelper.dateHourFormat(m.createdAt),
          m.isSuccess ? t("Yes") : t("No"),
        ]}
      />
    </>
  );
};
