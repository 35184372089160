import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { YupHelper } from "c4u-web-components";
import { IFormDealershipMolecule } from "./form-dealership.molecule";

export const FormDealershipMoleculeValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  const msgRequired = t("field_required");

  return Yup.object<IFormDealershipMolecule>({
    name: Yup.string().nullable().required(msgRequired),
    email: Yup.string().nullable().required(msgRequired).email(t("field_invalid_email")),
    city: Yup.string().nullable().required(msgRequired),
    cnpj: Yup.string().nullable().required(msgRequired),
    complement: Yup.string().nullable().notRequired(),
    legalName: Yup.string().nullable().required(msgRequired),
    neighborhood: Yup.string().nullable().required(msgRequired),
    number: Yup.string().nullable().required(msgRequired),
    primaryPhone: Yup.string().nullable().required(msgRequired),
    secondaryPhone: Yup.string().nullable().notRequired(),
    state: Yup.string().nullable().required(msgRequired),
    street: Yup.string().nullable().required(msgRequired),
    zipCode: Yup.string().nullable().required(msgRequired),
  });
};
