import { ButtonPrimary } from "c4u-web-components";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateHelper } from "../../../../../helper";
import { renderViewHelper } from "../../../../../helper/render-view.helper";
import {
  IGetInspectionReportLinks,
  IInspectionDetailsData,
} from "../../../../../models";

interface IProps {
  inspectionDetails?: IInspectionDetailsData;
  getInspectionReportLinks: (id: string) => Promise<IGetInspectionReportLinks>;
  id: string;
}

export const InspectionDetailsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const renderViewInspection = (
    key: string,
    label: string,
    func?: (attr: any) => string
  ) => renderViewHelper(props.inspectionDetails, key, label, func);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);
    props
      .getInspectionReportLinks(props.id)
      .then(({ links }) =>
        links.map((m, i) => setTimeout(() => window.open(m), 500 * i))
      )
      .catch((error) => {
        if (error?.message) alert(JSON.stringify(error?.message));
        else alert(JSON.stringify(error));
      })
      .finally(() => setIsDownloading(false));
  };

  const dateHourFormat = (value?: string) =>
    DateHelper.dateHourCustomFormat(value, "dd/MM/yyyy HH:mm");

  return !props.inspectionDetails ? (
    <i>{t("No information")}</i>
  ) : (
    <>
      <Form.Row>
        {renderViewInspection("partner", t("InspectionPartner"))}
        {renderViewInspection("reportType", t("InspectionReportType"))}
        {renderViewInspection("inspectionPlaceName", t("InspectionPlaceName"))}
        {renderViewInspection(
          "inspectionPlaceAddress",
          t("InspectionPlaceAddress")
        )}
        {renderViewInspection(
          "inspectionDate",
          t("InspectionDateTime"),
          dateHourFormat
        )}

        <Form.Group as={Col} md={4} className={"text-center pt-4"}>
          <ButtonPrimary
            type={"button"}
            sizex={"md"}
            onClick={handleDownload}
            loading={isDownloading}
            disabled={!props.inspectionDetails.reportLink.length}
          >
            {t("Download report")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </>
  );
};
