import {
  ButtonPrimary,
  FormikControlAtom,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useKbbBackoffice } from "../../../../../hooks";
import {
  IFindByPlateResponse,
  IFindHistoryByPlate,
} from "../../../../../models";
import { IsCacheMolecule, VehicleDetailsMolecule } from "../../../../molecules";
import { Wrapper } from "../../../../molecules/backoffice/search-by-plate/details.molecule.style";
import { VehicleHistoryOrganism } from "./vehicle-history.organism";

interface ISearchByPlate {
  plate: string;
  theftDecoder?: any;
  theftHistory?: any;
  fetchBucket?: any;
}

export const SearchByPlateOrganism: React.FC = () => {
  const [decoderData, setDecoderData] = useState<IFindByPlateResponse>();
  const [errorFetchDecoder, setErrorFetchDecoder] = useState<boolean>(false);
  const [binData, setBinData] = useState<IFindHistoryByPlate>();
  const [errorFetchBin, setErrorFetchBin] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);

  const { getFindByPlate, getFindHistoryByPlate } = useKbbBackoffice();

  const { t } = useTranslation();

  const validations = Yup.object<ISearchByPlate>({
    plate: Yup.string()
      .required(t("field_required"))
      .test("plate", t("field_invalid_plate"), function (value) {
        return value?.length === 7 && /([A-Z]{3}\d[A-Z0-9]\d\d)/.test(value);
      }),
  });

  const clearVehicle = useCallback(() => {
    setBinData(undefined);
    setDecoderData(undefined);
  }, [setBinData, setDecoderData]);

  const formik = useFormik<ISearchByPlate>({
    initialValues: {
      plate: "",
      theftDecoder: false,
      theftHistory: false,
      fetchBucket: false,
    },
    onSubmit: async (values) => {
      clearVehicle();

      if (values.theftDecoder) {
        try {
          const data = await getFindByPlate(values.plate, values.fetchBucket);
          setDecoderData(data);
        } catch (err) {
          setErrorFetchDecoder(true);
        }
      }

      if (values.theftHistory) {
        try {
          const data = await getFindHistoryByPlate(
            values.plate,
            values.fetchBucket
          );

          data ? setBinData(data) : setErrorFetchBin(true);
        } catch (err) {
          setErrorFetchBin(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (!formik?.values?.theftHistory) {
      formik.setFieldValue("fetchBucket", false);
    }
    // eslint-disable-next-line
  }, [formik?.values?.theftHistory]);

  useEffect(() => {
    setIsValid(
      formik.values.plate.length !== 7 ||
        (!formik.values.theftDecoder && !formik.values.theftHistory)
    );
  }, [
    formik.values.theftDecoder,
    formik.values.theftHistory,
    formik.values.plate,
  ]);

  useEffect(() => {
    clearVehicle();
    // eslint-disable-next-line
  }, [formik.values.plate]);

  return (
    <>
      <Form onSubmit={(e: any) => formik.handleSubmit(e)}>
        <Form.Row className="align-items-center">
          <FormikControlAtom
            formik={formik}
            translate={t}
            property="plate"
            label={t("Plate")}
            md={2}
            xs={5}
            maxLength={7}
            func={(v: string) => v.toUpperCase()}
          />
          {isValid && (
            <span
              className="ml-2 pt-2"
              style={{
                color: "red",
              }}
            >
              {t("MinimumRequirementsForPlateSearch")}
            </span>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={3} controlId="theftDecoder">
            <Form.Check
              type="checkbox"
              label={t("DecoderAndFipe")}
              onChange={formik.handleChange}
              checked={formik.values?.theftDecoder}
              className="mt-4"
            />
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="theftHistory">
            <Form.Check
              type="checkbox"
              label={t("BINAndTheftHistory")}
              onChange={formik.handleChange}
              checked={formik.values?.theftHistory}
              className="mt-4"
            />
          </Form.Group>

          <Form.Group as={Col} md={3} controlId="fetchBucket">
            <Form.Check
              type="checkbox"
              label={t("NotSearchInCache")}
              onChange={formik.handleChange}
              checked={formik.values?.fetchBucket}
              className="mt-4"
            />
          </Form.Group>

          <Form.Group as={Col} md className={"text-rigth"}>
            <ButtonPrimary
              sizex="md"
              type="submit"
              loading={formik.isSubmitting}
              disabled={isValid}
            >
              {t("Buscar")}
            </ButtonPrimary>
          </Form.Group>
        </Form.Row>
        <hr />

        {formik.values.theftDecoder && formik.isSubmitting ? (
          <div className="d-flex justify-content-center">
            <h4 className="mr-3">{t("SearchingDecoderAndTheft")}</h4>
            <SpinnerCenteredAtom />
          </div>
        ) : decoderData ? (
          <>
            <h3 className="mt-5 mb-3">{t("Decoder")}</h3>
            <Wrapper>
              <IsCacheMolecule
                data={{
                  isCache: decoderData.isCache ? t("Yes") : t("No"),
                  createdAt: decoderData.createdAt,
                }}
              />
              {decoderData.vehicles.map((m, i) => (
                <VehicleDetailsMolecule
                  key={`cardados-details-${i}`}
                  data={m}
                  showInitial={decoderData.vehicles.length === 1 ? true : false}
                />
              ))}
            </Wrapper>
          </>
        ) : (
          errorFetchDecoder && (
            <>
              <h3 className="mt-5 mb-3">{t("Decoder")}</h3>
              <i>{t("VehicleDidNotReturnData")}</i>
            </>
          )
        )}

        {formik.values.theftHistory && formik.isSubmitting ? (
          <div className="d-flex justify-content-center mt-5">
            <h4 className="mr-3">{t("SearchingHistoricAndTheft")}</h4>
            <SpinnerCenteredAtom />
          </div>
        ) : binData ? (
          <>
            <h3 className="mt-5 mb-3">{t("BIN")}</h3>
            <Wrapper>
              <IsCacheMolecule
                data={{
                  isCache: binData.isCache ? t("Yes") : t("No"),
                  createdAt: binData.createdAt,
                }}
              />
              <VehicleHistoryOrganism
                hiscoricsRF={binData?.dataSet?.historicsRF}
                nationalBase={binData?.dataSet?.nationalBase}
                returnData={binData.dataSet?.return}
              />
            </Wrapper>
          </>
        ) : (
          errorFetchBin && (
            <>
              <h3 className="mt-5 mb-3">{t("BIN")}</h3>
              <i>{t("VehicleDidNotReturnData")}</i>
            </>
          )
        )}
      </Form>
    </>
  );
};
